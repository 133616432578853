 

export function WinHelpConta( {children} )
{
    return (
        <div>{children}</div>
    );
}

export function WinHelpElem( {tit, text} )
{
    let xxx;
    
    if ( typeof(text) === 'string' ) xxx = text;
    else
    {
        xxx = [];
        
        for (let x = 0; x < text.length; x ++ )
        {
            xxx.push( <div style={{marginBottom: '6px'}}key={x}>{text[x]}</div> );
        }
    }
    
    return (
        <>
            <div style={{color: '#A0A0FF', fontWeight: 800}}>{tit}</div>
            <div style={{paddingLeft: '10px', paddingBottom: '10px'}}>{xxx}</div>
        </>
    );
}
