import React from 'react';
import { Win2 } from './win2.js';

import { LayVert2 } from './layout.js';

import { DaButton } from './but.js';

import { OverlayLoading } from './ovr_loading.js';

import { BottomButtonsPanel } from '../cust_card/bottom_pan.js';

import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import CheckIcon from '@mui/icons-material/Check';




//--------------------------------------------------------


function MyBody( {onClose, text} )
{
    return (
                <LayVert2 rows='1fr max-content' sx={gSt1}>
                    <div style={{padding: '10px'}}>
                        {text}
                    </div>
                    
                    <div style={{display: 'flex', gap: '30px', justifyContent: 'center', alignItems: 'center'}}>
                        <DaButton onClick={onClose}  title='OK' icon={ CheckIcon } />
                    </div>
                
                </LayVert2>
                
    );
}


function Win2AlertImp( {visible, busy, text, title, onClose} )
{
    return ( <Win2 visible={visible} title={title} width={500} height={200} >
    
                <MyBody onClose={onClose} text={text} />
    
            </Win2> );

}


export const Win2Alert = React.memo( Win2AlertImp );



const gSt1 = {padding: '20px', gap: '10px'};
