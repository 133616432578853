import React from 'react';

import * as COM_DEFS from '../com_defs.mjs';

import { FaAddressCard } from "react-icons/fa";
import { CommonReducer, ShowErr, ShowMsg } from "../misc_glob";

import { CmCachedCustLink } from '../desktop/ca_cust_link';

import { WinCustomerSearch } from '../desktop/win_cust_search';

import { DaToolTip } from '../misc/tooltip';

import { MAX_CARD_SLOTS, useCuCaMgrState } from './hk_cuca_mgr_state';

import { WinCustCard } from '../cust_card/cc_main';

import { PicBase } from '../desktop/pics';

//------------------------------- CUST CARD MANAGER ---

//--------------------------------------------



function CuCaSingleCardIcon( {cuId, vis, ind, onClick} )
{
    const TP = vis === 0 ? 'ОТКРЫТЬ НОВУЮ КАРТОЧКУ КЛИЕНТА' : <CmCachedCustLink rid={cuId} plain />;

    function cb_clk()
    {
        onClick( ind, cuId, vis );
    }

    return (
        <DaToolTip title={TP} placement='top'>
            <div onClick={cb_clk}>
                
                { vis === 0 ? <EmptyIcon /> :  <MiniCustPic rid={cuId} isOpen={vis===2} />
                }
            
            </div>
        </DaToolTip>
    );
}


function EmptyIcon()
{
    return (

        <div className='fl_center' style={{width: '32px', height: '32px'}}>
            <FaAddressCard  style={{color: '#606060'}} />
        </div>

    );
}


//---------------------------------------

function MiniCustPicImp( {isOpen, rid} )
{
    const CLA = isOpen ? 'cust_mini_icon act' : 'cust_mini_icon';
    return (   <PicBase picType={COM_DEFS.PIC_TYPE_CUSTOMER_MINI} rid={rid}  className={CLA} /> )
}

const MiniCustPic = React.memo( MiniCustPicImp );

//----------------------------------------------------





// содержит окна КАРТОЧЕК КЛИЕНТА
export function CuCaMgrCardWindowsContainer()
{
    const CCM = useCuCaMgrState();

    function cb_close_card( cuId )
    {
        CCM.setVisForCuId( cuId, 0 );
    }

    function cb_mini_card( cuId )
    {
        CCM.setVisForCuId( cuId, 1 );
    }

    const arCards = [];
    for (let x = 0; x < MAX_CARD_SLOTS; x ++ )
    {
        const cuId  = CCM.sta['cuId'+x];

        const VIS   = CCM.sta['vis'+x];

        arCards.push( <WinCustCard key={x} cuId={cuId} visible={VIS==2}  onMini={cb_mini_card} onClose={cb_close_card} /> );
    }

    return (
        <>
        {arCards}
        </>
    );
}


// плашка с карточками на менюшка (минимиз)
export function CuCaMgrPanel()
{
    const CCM = useCuCaMgrState();

    
    function cb_clk( i, cuId, vis )
    {
        console.log('CLICK SLOT ', i, ' -- ', cuId);

        if (vis === 0)    // empty slot.. show cust search !
        {
            CCM.showSearch( true, i );
        }
        else if (vis > 0 && cuId !== -1)
        {
            CCM.setVisForCuId( cuId, 2 );
        }
    }

    function cb_win_search_ok(cuId)
    {
        //console.log('WIN... ', e);

        const slot = CCM.findCustInSlot( cuId );

        if (slot !== -1) 
        {
            ShowErr('КАРТОЧКА ЭТОГО КЛИЕНТА УЖЕ ОТКРЫТА');
            return;
        }
        
        CCM.setCard(CCM.sta.searchingForSlot, cuId);
        CCM.showSearch( false, CCM.sta.searchingForSlot );
    }

    function cb_win_close()
    {
        CCM.showSearch( false );
    }



    const arIcons = [];
    for (let x = 0; x < MAX_CARD_SLOTS; x ++ )
    {
        const cuId = CCM.sta['cuId'+x];

        const VIS = CCM.sta['vis'+x];

        arIcons.push( <CuCaSingleCardIcon key={x} vis={VIS} cuId={cuId} ind={x} onClick={cb_clk} /> );
    }

    return (
        <>
        <div style={{paddingLeft: '4px', paddingRight: '4px', display: 'flex', alignItems: 'center', justifyContent: 'space-around', width: '100%', height: '38px' }}>
             {arIcons}
        </div>

        <WinCustomerSearch visible={CCM.sta.showCustSearch}
                                    onOk={cb_win_search_ok}
                                    onClose={cb_win_close}
                                    hideNewCust title='ОТКРЫТЬ АНКЕТУ КЛИЕНТА' />

        </>
    );
}
