 


import React from 'react';

import * as DT from '../dt.js';

import * as DEFS from '../defs.js';

import { PayFormVis, usePayFormState } from './pay_form4.js';

import { CmCustomerSearch } from '../misc/search_cut.js';

import { DaSwitcherNumber } from '../misc/sw_number.js';
import { DaDateField } from '../misc/date_field.js';

import { DaButton } from '../misc/but.js';
import { DaTextField } from '../misc/text_field.js';
import { CommonReducer, ShowMsg, ShowErr } from '../misc_glob.js';

import { OverlayLoading } from '../misc/ovr_loading.js';

import { DaOutlinedShit } from '../misc/outlined.js';

import {DaList2} from '../misc/list2.js';

import { NuPost, NuPost2 } from '../api.js';

import { LayVert2, LayHoriz2, LaySpace } from '../misc/layout.js';

import { Win2 } from '../misc/win2.js';

import SellIcon from '@mui/icons-material/Sell';
import { DaNumericField } from '../misc/numeric.js';
import { DaComboTrainerLocals } from './combo_trainer.js';
import { BottomButtonsPanel } from '../cust_card/bottom_pan.js';

//-------------------


const gSTA = {

    uid:    -1,
    amo:    0,
    busy:   false,

    i_nam:  '',
    i_cnt:  0,
    i_whe: '',
    i_paa: 0,
};

function MyJuice( {rid, onOk} )
{
    const [sta, dispatch] = React.useReducer( CommonReducer, gSTA );


    React.useEffect( () => {

        if (rid === -1) return; // wtf ?

        function __ok(e)
        {
            //console.log(e);
            dispatch( {type: 'vals', dct: {busy:false, amo:e.amo, uid:e.uid, i_paa:e.i_paa,i_whe:e.i_whe, i_cnt:e.i_cnt, i_nam:e.i_nam} } );
       
            if (!e.exists)  // no records yet.. fill in AMOUNT.. 
            {
                const CCC = e.i_paa * e.i_cnt;

                dispatch( {type: 'vals', dct: {amo: CCC} } );
            }
        }

        function __fail(e)
        {
            ShowErr(e.m);
            dispatch( {type: 'vals', dct: {busy:false} } );
        }



        dispatch( {type: 'vals', dct: {busy:true} } );

        NuPost2( 4226, {rid:rid}, __ok, __fail );

    }, [] );
    
    const cb_chg = React.useCallback( (k,v) => {
        dispatch( {type: 'val', k:k, v:v} );
    }, [] );

    function cb_save()
    {
        dispatch( {type: 'vals', dct: {busy:true} } );

        function __ok(e)
        {
            dispatch( {type: 'vals', dct: {busy:false} } );
            ShowMsg('УСПЕХ !');
            onOk();
        }

        function __fail(e)
        {
            ShowErr( e.m );
            dispatch( {type: 'vals', dct: {busy:false} } );
        }


        NuPost2( 4225, {rid:rid,uid:sta.uid, amo:sta.amo}, __ok, __fail );
    }

    const DTDT = DT.DateTimeStrLocHum(  DT.DateTimeParseIso(sta.i_whe) );

    return (

        <LayVert2 rows='max-content 1fr max-content' gap='10px' >
            <LayVert2 rows='28px 28px 28px 10px max-content max-content' padding='16px' gap='16px'>
                <div className="tx_faded">{DTDT } </div>
                <div>{sta.i_nam} <span className="tx_good_cnt"> {sta.i_cnt} шт.</span></div>
                <div className="tx_faded">бонус за продажу (из настроек): {sta.i_cnt} x {sta.i_paa} = {sta.i_paa*sta.i_cnt}</div>
                <LaySpace />
                <DaComboTrainerLocals name='uid' value={sta.uid} label='ТРЕНЕР' onChange={cb_chg}/>
                <DaNumericField name='amo' value={sta.amo} min={0} max={99999} label='СКОЛЬКО ПОЛУЧИТ' onChange={cb_chg} />
            </LayVert2>
            <LaySpace />
            <BottomButtonsPanel>
                <DaButton title='СОХРАНИТЬ' onClick={cb_save}/>
            </BottomButtonsPanel>
            <OverlayLoading active={sta.busy} />
        </LayVert2>
    );
}


// ништяки тренеру за продажу товара - мини окно
export function WinSellGoodTraBonus( {visible, rid, onOk, onClose} )
{
    const TIT = `ЗАКРЕПИТЬ ТРЕНЕРА ЗА ПРОДАЖЕЙ #${rid}`;

    return (
    
        <Win2 visible={visible} title={TIT} onClose={onClose} width={460} height={390}  >
            <MyJuice rid={rid} onOk={onOk} />
        </Win2>
    
    );
}

