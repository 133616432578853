import React from 'react';

import * as COM_DEFS from '../com_defs.mjs';
import * as HLP from '../helpers.js';

import {DaList2} from '../misc/list2.js';

import {DaToolTip} from '../misc/tooltip.js';

import { NuPost2 } from '../api.js';



import { useApiQueries2 } from '../api_stuff.js';

import StarIcon from '@mui/icons-material/Star';

import { CmCachedCustLink } from '../desktop/ca_cust_link.js';

// список тренеров в окне события


// array sort
function __fnc_sort(aa, bb)
{
	//console.log('SORT ', aa, ' -vs- ', bb);
    let w0 = Number(aa.v_works) + Number(aa.v_skill) + Number(!aa.busy);
    let w1 = Number(bb.v_works) + Number(bb.v_skill) + Number(!bb.busy);
    
    if (aa.u_sub_role !== COM_DEFS.TRAINER_SUB_ROLE_NORMAL) w0 -= 300;
    if (bb.u_sub_role !== COM_DEFS.TRAINER_SUB_ROLE_NORMAL) w1 -= 300;
    
    if (w0 < w1) return 1;
    if (w0 > w1) return -1;
    return 0;
}

//------------------------------------------------------------


function ItemImp( {kk, vv, row, isCur, ext} )
{
    const all_cust_ids = ext;
    
	let qs = [];
	
	for (let x = 0; x < all_cust_ids.length; x ++ )
	{
		qs.push( [ 967, {id:all_cust_ids[x]} ] );
	}
	
	let resu = useApiQueries2( qs );
	
    
	let prim;
	
	if (row.u_sub_role>0)
	{
		prim = <i style={{color: '#5070F0'}}>{row.u_name}</i>;
	}
	else
	{
		prim = <b>{row.u_name}</b>;
	}
	
	
	let p_ic;
	
	let cla = null;
	let info = null;
	
	let ar_info = [];
	
	if (row.busy === true) 		ar_info.push('уже забит');
	if (row.v_works === false) 	ar_info.push('не работает');
	if (row.v_skill === false) 	ar_info.push('не умеет');
	
	if (ar_info.length > 0)
	{
		info = <i className="red_shit">{ '( ' + ar_info.join(', ') + ') ' }</i>;
		cla = 'faint';
	}
	
	
	
	
	let loc_favs = [];
	
	for (let x = 0; x < resu.length; x ++ )
	{
		let t = resu[x];
		if (t.data && t.data.v_fav && HLP.CsvTestID(t.data.v_fav, row.u_id))
		{
			loc_favs.push( t.data.row.cu_id );
		}
	}
	
	if (loc_favs.length > 0)
	{
		let t0 = 0;
		
		const ttt = <>
						<div className="faint">ЛЮБИМЫЙ ТРЕНЕР У:</div>
						{loc_favs.map( (e) => { t0++; return <CmCachedCustLink bullet key={e} rid={e} no_com shorten plain /> } ) }
					</>;
		
		p_ic = <DaToolTip title={ttt} offy={-70} offx={50} >
					<StarIcon sx={{fontSize: '20px', color: '#7080FF'}} />
				</DaToolTip>;
	}
	else
	{
		p_ic = null;
	}


	prim = <div style={{display: 'flex', alignItems: 'center', gap: '8px'}} className={cla}>{p_ic}{prim} {info}</div>;
	
    
    
    
    return (<div className='yy_list_itj_default' >
                {prim}
            </div>);
}

export const Item = React.memo( ItemImp );





export function TrainersList( {curId, name, onChange, act, ts_id, dt, all_cust_ids, disabled} )
{
	//console.log('TrainersList  all: ', all_cust_ids);
	
	const [busy, setBusy] = React.useState( false );
	const [lst, setLst] = React.useState( [] );
	
	function on_chg(k, v)
	{
		onChange(name,v);
	}
	
	React.useEffect( () => {
		
		const on_fail = (e) =>
		{
			setBusy(false);
		};
		
		const on_ok = (e) =>
		{
			e.users.sort( __fnc_sort );
        
			setBusy(false);
			setLst(e.users);
		};
		
		setBusy(true);
		
		//console.log('request dudes...');
		
		NuPost2( 822, {ts: dt, ts_id: ts_id, act: act}, on_ok, on_fail );
		
	}, [act, dt] );
	

	
	
	return (	<div className="zz_com_border" style={{height: '100%', width: '100%'}}>
					<DaList2 disabled={disabled} value={curId}  src={lst}  fld_k='u_id' fld_v='u_name'
											onChange={on_chg}  itemCmp={Item} ext={all_cust_ids}
											 />
				</div>
	)
}

