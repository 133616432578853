import React from 'react';
import { DaCombo } from './misc/combo.js';
import { useApiQuery, useApiQuery2 } from './api_stuff.js';

import * as HLP from './helpers.js';

function CmComboCustSrcImp(pp)
{
	const { isFetching, isLoading, isError, data, error } = useApiQuery2( 976, {} );
	
	if (isError) return <div className="yy_error">{error}</div>;
	
	if (isLoading || isFetching)
	{
		return <DaCombo disabled  />;
	}
	
	const cb_chg = (k,v) => {
		//console.log('cust src chg.. ', v);
		pp.onChange( pp.name, v );
	};
	
	let vv = pp.value;

	
	return <DaCombo label={pp.label} value={vv} src={data.rows} fld_k='lso_id' fld_v='lso_val' onChange={cb_chg} />;
}


export const CmComboCustSrc = React.memo( CmComboCustSrcImp );

//---------------------------------------------------------------




export function CmComboCustSrcText( {value} )
{
	const { isFetching, isLoading, isError, data, error } = useApiQuery2( 976, {} );
	
	if (isError) return '???';
	
	if (isLoading || isFetching)
	{
		return '???';
	}
    
    const e = HLP.ArrayOfDictsFindByPropValue( data.rows, 'lso_id', value );
    if (e === null) return '???';
    
    return e.lso_val;
}
