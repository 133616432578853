import React from 'react';


import { EvActDispNameCSV } from '../desktop/ev_act.js';


// ОПИСАНИЕ что в продукте
function CmProdDescriptionImp( {da_list, amounts, extra} )
{
    //console.log('DESCR ', p);

    const lll = da_list;
    const amo = amounts;
    
    let prt_content = null;
    
    if (lll.length > 0)
    {
        let price = 0;
        let tot_trains = 0;
        
        let dct = {};
        
        for (let x = 0; x < lll.length; x ++ )
        {
            const e = lll[x];
            
            const my_am = amo[x];
            
            price += e.pr_price * my_am;
            
            for (let i = 0; i < e.trains.length; i ++ )
            {
                const tra = e.trains[i];
                
                const k = tra.ps_t_acts_csv;
                
                if (dct[k] === undefined)
                {
                    dct[k] = 0;
                }
                
                dct[k] += tra.ps_t_count * my_am;
                
                tot_trains += tra.ps_t_count * my_am;
            }
        }
        
        const prt_service = [];
        
        for (let k in dct)
        {
            const s_cnt = (dct[k] > 0) ? ('x' + dct[k]) : '∞';
            
            prt_service.push(
            
                   <div key={k}>• <span style={{color: '#404060', fontWeight: 500, fontSize: '18px'}}>{s_cnt}</span> <EvActDispNameCSV csv={k} /></div>
                    
                    );
        }
        
        const s_tot = tot_trains > 0 ? (<div style={{marginTop: '10px'}}>Всего тренировок: <b>{tot_trains}</b></div>) : null;
        

        prt_content = (<>
                            
                            <div style={{color: '#7060E0', padding: '6px', margin: '6px'}}>
                            {prt_service}
                            </div>
                            {s_tot}
							{extra}

                       </>);
    }
    
    return prt_content;
}


export const CmProdDescription = React.memo( CmProdDescriptionImp );

