import React from 'react';

import * as DEFS from '../defs.js';
import * as HLP from '../helpers.js';

import { NuPost, NuPost2 } from '../api.js';


import { WinHelpConta, WinHelpElem } from '../misc/win_help.js';


import { CmComboPayMethod } from './combo_pay_method.js';

import { CommonReducer, ShowMsg, ShowErr } from '../misc_glob.js';

import {DaIconBtn} from '../misc/icon_btn.js'; 
import { LayVert2, LayHoriz2, LaySpace } from '../misc/layout.js';
import { OverlayLoading } from '../misc/ovr_loading.js';
import { DaVertScrollFrame, DaVertScrollJuice} from '../misc/vscr.js';
import { Win2 } from '../misc/win2.js';

import { DaNumericField } from '../misc/numeric.js';

import { DaButton } from '../misc/but.js';
import { DaTextField } from '../misc/text_field.js';

import Collapse from '@mui/material/Collapse';


import CheckIcon from '@mui/icons-material/Check';

import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';

import CloseIcon from '@mui/icons-material/Close';

import { EvActDispNameCSV } from '../desktop/ev_act.js';

import { ViReturnForm } from './ret_form.js';
import { TrpAllowedHoursInfo } from '../desktop/allowed_hours.js';





//-----------------------------

const gRstate = {

	mon0_amount:	0,
	mon1_amount:	0,

	mon0_method:	0,
	mon1_method:	0,

	to_bonus:		0,

	comm:			'',

};


function __packSV( sta )
{
	const ents = [];

	if (sta.mon0_amount > 0)
	{
		ents.push( {kind: DEFS.PAYFORM_KIND_MONEY, method: sta.mon0_method, amount: sta.mon0_amount} );
	}

	if (sta.mon1_amount > 0)
	{
		ents.push( {kind: DEFS.PAYFORM_KIND_MONEY, method: sta.mon1_method, amount: sta.mon1_amount} );
	}

	if (sta.to_bonus > 0)
	{
		ents.push( {kind: DEFS.PAYFORM_KIND_BONUS, amount: sta.to_bonus} );
	}

	return { ents: ents, comm: sta.comm };
}

//--------------------------------------

export function useReturnFormSate( )
{
	const [sta, dispatch] = React.useReducer( CommonReducer, gRstate );

	const cb_chg = React.useCallback( (k,v) =>
    {
        dispatch( {type: 'val', k:k, v:v} );
    }, [] );


    return {
        cb_chg:         cb_chg,
        sta:            sta,

		packSV:			() => { return __packSV(sta) },

    };
}

export function ViReturnForm2( {hxState} )
{
	const s 	= hxState.sta;
	const chg 	= hxState.cb_chg;

	const SUM = s.mon0_amount + s.mon1_amount + s.to_bonus;

	return (

		<LayVert2 sx={{height: undefined, background: '#F4F8FF', border: '1px solid #00000020' }} rows='max-content max-content max-content max-content max-content' padding='10px' gap='16px'>

			<DaTextField name='comm' value={s.comm} onChange={chg} label='КОММЕНТ / ПРИЧИНА' />

			<LayHoriz2 cols='1fr 140px' gap='6px' >
				<DaNumericField  	name='mon0_amount' 	value={s.mon0_amount}    onChange={chg} label='ОТДАЁМ ДЕНЬГИ'  max={99999999} min={0}  />
				<CmComboPayMethod  	name='mon0_method'   value={s.mon0_method}   onChange={chg} />
			</LayHoriz2>

			<LayHoriz2 cols='1fr 140px' gap='6px' >
				<DaNumericField  	name='mon1_amount' 	value={s.mon1_amount}    onChange={chg} label='ОТДАЁМ ДЕНЬГИ'  max={99999999} min={0}  />
				<CmComboPayMethod  	name='mon1_method'   value={s.mon1_method}   onChange={chg} />
			</LayHoriz2>


			<DaNumericField  	name='to_bonus' 	value={s.to_bonus}    onChange={chg} label='ЗАЧИСЛИТЬ КАК БОНУСЫ'  max={99999999} min={0}  />

			<div style={{color: '#A0A0A0', fontWeight: 800}}>ВСЕГО: {SUM}</div>

		</LayVert2>
	);
}

