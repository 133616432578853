import React from 'react';



// Элемент списка - имя клиента и телеф
// cu_name  cu_phone

function ListItemCustNameAndPhoneImp( {kk, vv, row, isCur, ext} )
{
    return (<div className="list_it_cust_na_and_pho" >
                <div>{row.cu_name}</div>
                <div className="pho">{row.cu_phone}</div>
            </div>);
}

export const ListItemCustNameAndPhone = React.memo( ListItemCustNameAndPhoneImp );

