import * as React from 'react';


import * as DEFS from '../defs.js';
import * as HLP from '../helpers.js';


import { WinHelpConta, WinHelpElem } from '../misc/win_help.js'



import { CommonReducer, ShowMsg, ShowErr } from '../misc_glob.js';



import { DaTextField } from '../misc/text_field.js';
import { DaColorPicker } from '../misc/clr_picker.js';

import {STO_InvaCustOrigins} from '../store.js';



import { LayHoriz2, LayVert2 } from '../misc/layout.js'
import { NuPost } from '../api.js';

import { DaButton } from '../misc/but.js';
import { OverlayLoading } from '../misc/ovr_loading.js';
import { Win2 } from '../misc/win2.js';

import { BottomButtonsPanel } from '../cust_card/bottom_pan.js';
import CheckIcon from '@mui/icons-material/Check';

//-----------------------------------------------


const gSta99 = {
  
        nam:    '',
        clr:    '#FF0000',
        busy:   false,
        
        ref:        '',
        utm_src:    '',
};

function MyBody( {rid, onOk} )
{
    const [sta, dispatch] = React.useReducer( CommonReducer, gSta99 );
    
    const ERR_TIT = sta.nam.trim() === '';
    const DIS_SUBMIT = ERR_TIT;
    
    const cb_chg = React.useCallback( (k,v) => {
        dispatch( {type: 'val', k, v} );
    }, [] );
    
    function cb_submit()
    {
            const cb_ok = (e) => {
                ShowMsg('УСПЕХ !');
                dispatch( {type:'val', k:'busy',v:false} );
                dispatch( {type:'val', k:'pop',v:0} );
                STO_InvaCustOrigins();
                onOk();
            };
            
            const cb_fail = (e) => {
                dispatch( {type:'val', k:'busy',v:false} );
                ShowErr(e.m);
            };
            
            dispatch( {type:'val', k:'busy',v:true} );
            
            NuPost(3012, {rid: rid, val: sta.nam, clr: sta.clr, ref:sta.ref, utm_src:sta.utm_src}, cb_ok, cb_fail);
    }
    
    
    React.useEffect( () => {
        
        if (rid === -1) return;
        
            const cb_ok = (e) => {
                dispatch( {type:'val', k:'busy',v:false} );
                
                dispatch( {type:'val', k:'nam',v:e.row.lso_val} );
                dispatch( {type:'val', k:'clr',v:e.row.lso_clr} );
                dispatch( {type:'val', k:'ref',v:e.row.lso_referer} );
                dispatch( {type:'val', k:'utm_src',v:e.row.lso_utm_source} );
            };
            
            const cb_fail = (e) => {
                dispatch( {type:'val', k:'busy',v:false} );
                ShowErr(e.m);
            };
            
            dispatch( {type:'val', k:'busy',v:true} );
            
            NuPost(3011, {rid: rid}, cb_ok, cb_fail);
        
    }, [] );
    
    return (
        <>
        <LayVert2 rows='1fr max-content' sx={{gap: '16px'}}>
        
            <LayVert2 rows='max-content max-content max-content 1fr' sx={{padding: '16px', gap: '16px'}} >
                <DaTextField error={ERR_TIT} name='nam' value={sta.nam}  onChange={cb_chg} label='НАЗВАНИЕ' />
                <DaColorPicker name='clr' value={sta.clr} onChange={cb_chg} label='ЦВЕТ'  />
                <DaTextField name='utm_src' value={sta.utm_src}  onChange={cb_chg} label='UTM_SOURCE' />
                <DaTextField name='ref' value={sta.ref}  onChange={cb_chg} label='REFERER' multiline />
            </LayVert2>
            
            <BottomButtonsPanel>
                <DaButton disabled={DIS_SUBMIT} onClick={cb_submit}  icon={CheckIcon}  title={rid===-1?'НОВЫЙ':'СОХРАНИТЬ'} />
            </BottomButtonsPanel>
            
        </LayVert2 >
    
        <OverlayLoading active={sta.busy} />
    
        </>
    
    );
}


const gHelp1 = (<WinHelpConta>
                    <WinHelpElem tit='REFERER' text={ ['Каждая строка: правило.', '+ включить', '- исплючить'] } />

                </WinHelpConta>);
                


// окно - редактор источ клиентов
export function WinEditCustSrc( {rid, onClose, onOk, visible} )
{
    return (
    
			<Win2 visible={visible} help={gHelp1} title={rid===-1?'НОВЫЙ ИСТОЧНИК':'РЕДАКТИРОВАТЬ ИСТОЧНИК'} width={500} height={370}  onClose={onClose} >
                <MyBody rid={rid} onOk={onOk} />
            </Win2>
    
    );
}

 
