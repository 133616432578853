import React from 'react';

import { NuPost2 } from '../api.js';

import Autocomplete from '@mui/material/Autocomplete';

import TextField from '@mui/material/TextField';



/*

function HACKY_PopperOnTop(pp)
{
	pp.style.zIndex = 9999999;
	return <Popper {...pp} />;
}
*/

function __RendOpt(props, option)
{
	return (<li {...props}  style={{fontSize: '14px', display: 'flex', flexDirection: 'column', alignItems: 'start'}} >
				<div >{option.cu_name}</div>
				<div style={{color: '#506080', fontWeight: 800}}>{option.cu_phone}</div>
			</li>
        );
}




function __SearchEq(op, val)
{
	return op.cu_id===val.cu_id;
}


// PopperComponent={ HACKY_PopperOnTop }

// TODO: не удалось замутить input ADORNMENT на текст поле.. (конфликт со списком)
// value =  {cu_id: 100, cu_name: 'name'}
// onChange( value )
export function CmCustomerSearch( props )
{
	const [inputValue, setInputValue] 	= React.useState('');
	const [options, setOptions] 		= React.useState([]);


	React.useEffect(() => {
		let active = true;
	
		//console.log('eff !');
	
		if (inputValue === '')
		{
			setOptions(props.value ? [props.value] : []);
			return undefined;
		}
	
		let on_fail = (e) =>
		{
			//ShowErr('FAIL: ', e.m);
		};
		
		let on_ok = (e) =>
		{
			setOptions( e.rows );
		};
	
		NuPost2( 780, {q: inputValue}, on_ok, on_fail );

		return () => {
			active = false;
		};
  }, [props.value, inputValue]);
  
  

  return (
    <Autocomplete size='small'  disabled={props.disabled}
      sx={props.sx}
      filterOptions={(x) => x}
      getOptionLabel={(option) => {
			return typeof option === 'string' ? option : option.cu_name;
		}
      }
      
		isOptionEqualToValue= { __SearchEq }
	  
	  
	  
      options={options}
      noOptionsText='Нет результатов !'
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={props.value}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);

        props.onChange( newValue );
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      
      renderInput={(params) => (
        <TextField {...params} label={props.title} fullWidth />
      )}
      
      
      renderOption={ __RendOpt }

    />
  );
}
