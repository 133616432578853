import React from 'react';


import * as DT from '../dt.js';


import { DaSwitcher } from './switcher.js';


// value - int year
function DaSwitcherYearImp( {value, name, onChange, label, disabled} )
{
	const cb_chg = (k,v) =>
	{
		onChange(name, value+v);
	};
	
	return <DaSwitcher value={ ''+value } label={label} onChange={ cb_chg } disabled={disabled} />;
}


export const DaSwitcherYear = React.memo( DaSwitcherYearImp );
