import React from 'react';
 
import { DaCombo } from '../misc/combo.js';

import { useGlobalShit } from '../v3/hk_global_shit.js';


//---------------------------------------------


function ComboWorkZonesImp(pp)
{
	const GS = useGlobalShit();

	const WZ = GS.workZones;
    
	const src = React.useMemo( () => {
		
		let t = [];
		
		for (let x = 1; x <= WZ; x ++ )
		{
			t.push( { k:x, v:x  } );
		}
		
		return t;
		
	}, [] );

	return (
			<DaCombo label='РАБОЧИХ ЗОН' fld_k='k' fld_v='v'  value={pp.value}  src={src}  name={pp.name}  onChange={pp.onChange} /> 
				);
}

export const ComboWorkZones = React.memo( ComboWorkZonesImp );
