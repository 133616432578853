import React from 'react';
import { CmComColCellDateTime, CmComGoodName } from '../cm_table_cell_com.js';

import * as DEFS from '../defs.js';
import * as HLP from '../helpers.js';

import { Grid3 } from '../grid3.js';

import { LayVert2} from '../misc/layout.js';

import * as DT from '../dt.js';


const gColz = [
  //{ filterable: false, sortable: true,  headerAlign: 'center', align: 'center', field: 'par_id', headerName: 'ID', width: 100},

  { sort: true, align: 'center', 	fld: 'pgs_dt_sell',         name: 'Покупка',    width: 140, renderCell: CmComColCellDateTime  },
  { sort: true, align: 'left', 	    fld: 'pgs_item_id',         name: 'Товар',      width: 250, renderCell: CmComGoodName },
  { sort: true, align: 'center', 	fld: 'pgs_count',           name: 'Количество', width: 120 },
  { sort: true, align: 'center', 	fld: 'pgs_price_fact',      name: 'Цена',       width: 130 },
  { sort: true, align: 'center', 	fld: 'pgs_discount_mon',    name: 'Скидка',     width: 130 },
];





// карточка клиента - ПОКУПКИ товаров



export function ViCustomerCardGoods( {cu_id} )
{
    const flt = { cu_id: cu_id };
    
    return (<LayVert2 rows='1fr' padding='20px' >
    
                <Grid3      isNuApi	sort_field='pgs_dt_sell'	uniq='pgs_id'  
                                sort_dir='desc' cols={gColz}  flt={flt}
                                apiCmd={1019}   code='cc_goods' />
                        
            </LayVert2>
    
    );
}
