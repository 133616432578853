import React from 'react';


import * as COM_DEFS from '../com_defs.mjs';

import { Win2Confirm } from '../misc/win2_confirm.js';

import {  NuPost2 } from '../api.js';
import { ShowMsg, ShowErr, CommonReducer} from '../misc_glob.js';

import { Grid3 } from '../grid3.js';

import { LayVert2, LayHoriz2, LaySpace } from '../misc/layout.js';

import { DaButton } from '../misc/but.js';

import {WinProductEdit} from '../desktop/win_prod_abon_edit.js';

import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';


import { ClubListText } from './guides/club_list_text.js';

import { AbonFeatIcon } from '../desktop/abon_feat_icon.js';

import { CmHelpIcon } from '../misc/help_icon.js';

import { TrpAllowedHoursInfo } from '../desktop/allowed_hours.js';



import { useGlobalShit } from '../v3/hk_global_shit.js';
import { CmComColCellDateFromDateTime } from '../cm_table_cell_com.js';
import { DaCheck } from '../misc/check.js';

//-----------------------------------------------------

function MyColCnt(p)
{
    let v = p.value;
    if (v < 1) return (<span className="faint">{v}</span>);
    return (<b style={{color: '#6050E0'}}>{v}</b>);
}


function MyColStarter(pp)
{
	if (pp.value > 0) return <CheckIcon sx={{color: '#30A030'}}/>;
	return '';
}


function MyColLogic(pp)
{
	return COM_DEFS.gAbonLogicDisp[ pp.value ];
}
   
function MyColClubs(pp)
{
    const v = pp.value;
    if (v === '') return <span className="faint">ВСЕ</span>;
    return <ClubListText csv={v} />;
}





function MyColName(pp)
{
    const ROW = pp.row;

    let n     = pp.value;

    if (ROW.pr_inactive)
    {
        n = <span style={{color: '#D04040'}}>{n}</span>;
    }

    const j     = ROW.pr_allowed_hours_json;
    const ff    = ROW.v_got_feats > 0 ? <AbonFeatIcon /> : null;
    return <>{n}<TrpAllowedHoursInfo jsonStr={j}/>{ff}</>;
}


const gState1 = {
  
    showInact: false,

    flt_ty: -1,
    flt_di: -1,
    selected: [],
    busy: false,
    ser: 0,
    
    pop: 0,
    
    dct_pop_prod: {},
    dct_pop_conf: {},
    
};

//----------------------------------



export function TabProductsAbons()
{
    return ( <MyView isAtBoss={false} /> );
}



// REMOVE
export function TabProductsAbonsBOSS()
{
    return ( <MyView isAtBoss={true} /> );
}






function MyView( {isAtBoss} )
{
    const [sta, dispatch] = React.useReducer( CommonReducer, gState1 );
    
    const sss = useGlobalShit();
	
	const cb_chg_sel = (nu) => {
        dispatch( {type: 'val', k:'selected', v:nu} );
	};
	
	const cb_chg = (k,v) => {
        dispatch( {type: 'val', k,v} );
	};
	
    
	function cb_close()
	{
		dispatch( {type: 'val', k:'pop', v:0} );
	}
	
	function cb_new()
	{
		function __ok()
		{
            dispatch( {type: 'val', k:'ser', v: sta.ser+1} );
            dispatch( {type: 'val', k:'pop', v: 0} );
            dispatch( {type: 'val', k:'selected', v: []} );
		}
        
        const QQQ = { onOk: __ok, onClose:cb_close, rid: -1 };
        
        dispatch( {type: 'val', k:'dct_pop_prod', v: QQQ} );
        dispatch( {type: 'val', k:'pop', v: 2} );
	}
	
	function cb_row_dbl(kk)
	{
        //onOk={cb_ok} onClose={cb_close} rid={ID}
        
        if (!sss.userHasAbil( 501 ))
        {
            console.warn('no');
            return;
        }
        
        const ID =  kk
        
		function __ok()
		{
            dispatch( {type: 'val', k:'ser', v: sta.ser+1} );
            dispatch( {type: 'val', k:'pop', v: 0} );
		}
        
        const QQQ = { onOk: __ok, onClose:cb_close, rid: ID };
        
        dispatch( {type: 'val', k:'dct_pop_prod', v: QQQ} );
        dispatch( {type: 'val', k:'pop', v: 2} );
	}



	
	
	function cb_del()
	{
        
		const ID = sta.selected[0];
		
		function cb_submit()
		{
			function __ok(e)
			{
				ShowMsg('УДАЛЕНО !');
				dispatch( {type: 'val', k:'busy', v: false} );
				dispatch( {type: 'val', k:'ser', v: sta.ser+1} );
				dispatch( {type: 'val', k:'pop', v: 0} );
                dispatch( {type: 'val', k:'selected', v: []} );
			}
			
			function __fail(e)
			{
				ShowErr(e.m);
				dispatch( {type: 'val', k:'busy', v: false} );
			}
			
			dispatch( {type: 'val', k:'busy', v: true} );
			
			NuPost2( 731, {pr_id: ID, v: 1}, __ok, __fail);
		}
        
        
        const QQQ = { title: 'ДЕАКТИВИРОВАТЬ ПРОДУКТ ?', onOk: cb_submit, onClose:cb_close };
        
        dispatch( {type: 'val', k:'dct_pop_conf', v: QQQ} );
        dispatch( {type: 'val', k:'pop', v: 1} );
        
	}
	
	
	
	const cols = React.useMemo( () => {
		
		//console.log('recalc COLS..');
		
		let t = [];
		
                
        t.push(     {fld: 'pr_id',         name: 'ID',                 align: 'center',    sort: true, width: 100},  
                    {fld: 'pr_name',       name: 'Название',           align: 'left',      sort: true, width: 280, renderCell: MyColName},  

                );

   
				
	
		t.push( 	{ sort: true,    align: 'center',   fld: 'v_num_tra',   name: 'Тр. общ', width: 110 },
					{ sort: true,    align: 'center',     fld: 'pr_price',    name: 'Цена', width: 120 },
					{ sort: true,    align: 'center',   fld: 'pr_is_starter_bonus', name: 'Новым кл.', width: 170, renderCell: MyColStarter },
					{ sort: true,    align: 'center',     fld: 'pr_logic', name: 'LOGIC', width: 190, renderCell: MyColLogic },

				);
                
                
        if (!isAtBoss)
        {
            //  non master
            
            t.push( { sort: true,    align: 'center',     fld: 'v_cnt_non_closed', name: 'Действующих', width: 190, renderCell: MyColCnt } );
        }
        else
        {
            // master
            t.push( { sort:false,    align: 'left',     fld: 'pr_clubs_csv',    name: 'Клубы', width: 200, renderCell: MyColClubs }  );
        }
		
        t.push( 	{ sort: false,    align: 'left',   fld: 'pr_desc',   name: 'Описание', width: 130 } );

        t.push( 	{ sort: true,    align: 'center',   fld: 'pr_dt_created',   name: 'Создан',  width: 130, renderCell: CmComColCellDateFromDateTime } );
        t.push( 	{ sort: true,    align: 'center',   fld: 'pr_dt_changed',   name: 'Изменён', width: 130, renderCell: CmComColCellDateFromDateTime} );

		return t;
		
	}, [] );
	
	


    const FLT = React.useMemo( () => {
        return { showInact: sta.showInact };
    }, [sta.ser, sta.showInact] );


    
    const DIS_ADD   = (!sss.userHasAbil( 500 ));
    
	const DIS_DEL   = (!sss.userHasAbil( 502 )) || sta.selected.length < 1;
	
	return (

			<>
                <LayVert2 rows='max-content 1fr' padding='20px' gap='10px'>
            
					<LayHoriz2 cols='max-content max-content 30px max-content 1fr max-content' gap='10px' >
                        <DaButton  icon={AddIcon}    disabled={DIS_ADD}		title='НОВЫЙ'               onClick={cb_new} />
                        <DaButton  icon={RemoveIcon} disabled={DIS_DEL} 	title='ДЕАКТИВИРОВАТЬ'	    onClick={cb_del } />
                        <LaySpace />
                        <DaCheck name='showInact' value={sta.showInact} label='Показывать неактивные' onChange={cb_chg} />
                        <LaySpace />
                        <CmHelpIcon juice="Абонементы - общие для всех клубов. Колонка 'ДЕЙСТВУЮЩИХ' - для текущего клуба." />
                    </LayHoriz2>

		
      
        
                    <Grid3 singleSel onDblClick={cb_row_dbl} onChangeSel={cb_chg_sel} 
                            selected={sta.selected}  cols={cols} apiCmd={799}  isNuApi
                            uniq='pr_id'  sort_field='pr_id' sort_dir='asc' flt={ FLT }  code='prods_abons' />
                            
		
            
				</LayVert2>
       
       
                <Win2Confirm visible={sta.pop===1} busy={sta.busy}  {...sta.dct_pop_conf} />
                
                <WinProductEdit visible={sta.pop===2} {...sta.dct_pop_prod}  /> 

            </>
	
	);
}


