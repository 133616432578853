
import RedeemIcon from '@mui/icons-material/Redeem';

import { DaToolTip } from '../misc/tooltip';

//---------------------------------------------------


const gSS1 = {color: '#50A040', fontSize: '20px', marginLeft: '4px'};
const gSS2 = {color: '#B0B0B0', fontSize: '20px', marginLeft: '4px'};

export function AbonFeatIcon( { numUsed, numTot })
{
    let t;
    let sx = gSS1;
    if (numUsed !== undefined && numTot !== undefined)
    {
        t = `ДОП УСЛУГ ИСПОЛЬЗОВАНО: ${numUsed} ИЗ ${numTot}`;

        if (numUsed >= numTot) sx = gSS2;
    }
    else
    {
        t = 'ЕСТЬ ДОП УСЛУГИ';
    }

    return (<DaToolTip title={t}><RedeemIcon  sx={sx} /></DaToolTip>);
}