 
import React from 'react';


import * as COM_DEFS from '../com_defs.mjs';

import { LayVert2, LaySpace } from '../misc/layout.js';
import { OverlayLoading } from '../misc/ovr_loading.js';
import { DaButton } from '../misc/but.js';
import { DaCombo } from '../misc/combo.js';

import { NuPost2 } from '../api.js';

import { BottomButtonsPanel } from '../cust_card/bottom_pan.js';

import {  ShowErr, ShowMsg, CommonReducer } from '../misc_glob.js';

import { DaTextField } from '../misc/text_field.js';

import { DaColorPicker } from '../misc/clr_picker.js';
        

import CheckIcon from '@mui/icons-material/Check';

import { useGlobalShit } from '../v3/hk_global_shit.js';
//-----------------------------------------------------


const gUserRolesLocal = [

	{ k: COM_DEFS.USER_ROLE_TRAINER,  	    v: 'ТРЕНЕР'},
	{ k: COM_DEFS.USER_ROLE_ADMIN,  		v: 'АДМИН'},

];


const gUserRolesMaster = [

	{ k: COM_DEFS.USER_ROLE_SUPER,  		v: 'SUPER'},
	{ k: COM_DEFS.USER_ROLE_TRAINER,  	    v: 'ТРЕНЕР'},
	{ k: COM_DEFS.USER_ROLE_ADMIN,  		v: 'АДМИН'},
    { k: COM_DEFS.USER_ROLE_TOP_ADMIN,      v: 'УПРАВЛЯЮЩИЙ'},

];

//---

const gStaD = {
  
    busy:   false,
    nam:    '',
    pho:    '',
    r:      COM_DEFS.USER_ROLE_TRAINER,
    sr:     0,
    clr:    '#000000',
    
};

// Редакт юзера - тэб ОСНОВНОЕ
export function ViUserDetails( {rid, onOk, isMaster} )
{
    const [sta, dispatch]   = React.useReducer( CommonReducer, gStaD );
    
    const sss_au            = useGlobalShit();
    
    
    React.useEffect( () => {
        
        if (rid === -1) return;
        
        function __ok(e)
        {
            const t = e.row;
            
            dispatch( {type: 'val', k:'nam', v:t.u_name } );
            dispatch( {type: 'val', k:'pho', v:t.u_phone } );
            dispatch( {type: 'val', k:'r', v:t.u_role } );
            dispatch( {type: 'val', k:'sr', v:t.u_sub_role } );
            dispatch( {type: 'val', k:'clr', v:'#'+t.u_disp_rgb } );
            dispatch( {type: 'val', k:'busy', v:false } );

        }
        
        function __fail(e)
        {
            ShowErr(e.m);
            dispatch( {type: 'val', k:'busy', v:false } );
        }
        
        dispatch( {type: 'val', k:'busy', v:true } );
        
        NuPost2(1070, {rid}, __ok, __fail);
        
    }, [] );
    
    
    
    const cb_chg = React.useCallback( (k,v) => {
        dispatch( {type: 'val', k, v } );
    }, [] );
    
    function cb_submit()
    {
        //console.log('SUBMIT !');
        
        function __ok(e)
        {
            dispatch( {type: 'val', k:'busy', v:false } );
            

            if (rid !== -1)
            {
                ShowMsg('СОХРАНЕНО !');
                onOk( true );
            }
            else
            {
                onOk();
            }
            
            //
        }
        
        function __fail(e)
        {
            ShowErr(e.m);
            dispatch( {type: 'val', k:'busy', v:false } );
        }
        
        const oo = {};
        oo.rid = rid;
        oo.n = sta.nam;
        oo.p = sta.pho;
        oo.r = sta.r;
        oo.sr = sta.sr;
        oo.clr = sta.clr;

        dispatch( {type: 'val', k:'busy', v:true } );
            
        NuPost2(1071, oo, __ok, __fail);
    }
    
    
    const ERR_NAM = sta.nam.trim() === '';
    const ERR_PHO = sta.pho.trim() === '';
    
    const DIS_SUBROLE       = sta.r !== COM_DEFS.USER_ROLE_TRAINER;

    const DIS_SUBMIT = sta.busy || !sss_au.userHasAbil(302);
    


    const roleSrc = isMaster ? gUserRolesMaster : gUserRolesLocal;

    return (
        
        <LayVert2 rows='1fr max-content' gap='10px' >
            <LayVert2 rows='max-content max-content max-content max-content max-content 1fr' gap='16px' padding='20px' >
            
                <DaTextField error={ERR_NAM} name='nam' value={sta.nam}  onChange={cb_chg} label='ИМЯ' />
                <DaTextField error={ERR_PHO} name='pho' value={sta.pho}  onChange={cb_chg} label='ТЕЛЕФОН' />
                
                <DaCombo label="РОЛЬ" 		name='r'  onChange={cb_chg} 	src={roleSrc}   fld_k='k' fld_v='v'  value={sta.r}  /> 
                <DaCombo label="ПОДТИП" 	name='sr' onChange={cb_chg}     disabled={DIS_SUBROLE}	src={COM_DEFS.gUserTraRoleDctVis}   fld_k='k' fld_v='v'  value={sta.sr}  /> 
                        
                <DaColorPicker label='ЦВЕТ' name='clr' value={sta.clr} onChange={cb_chg} />
                
                <LaySpace />
            
            </LayVert2 >
            
            <BottomButtonsPanel>
                <DaButton disabled={DIS_SUBMIT} onClick={cb_submit}  icon={CheckIcon}  title={rid===-1 ? 'СОЗДАТЬ' : 'СОХРАНИТЬ'} />
            </BottomButtonsPanel>
            
            <OverlayLoading active={sta.busy} />
        </LayVert2>
    );
}
