import Toastify from 'toastify-js';
import 'toastify-js/src/toastify.css';

export function ShowMsg(message)
{
    Toastify({
      text: message,
      duration: 2000,
      gravity: 'top',
      position: 'center',
      style: {
        background: '#0F0F0F',
        color: '#FFFFFF',
        fontFamily: 'body',
        fontSize: '14px',
        fontWeight: 500,
      },
    }).showToast();
  }
   

  export function ShowErr(message)
  {
      Toastify({
        text: message,
        duration: 2000,
        gravity: 'top',
        position: 'center',
        style: {
          background: '#502020E0',
          color: '#FFD0D0',
          fontFamily: 'body',
          fontSize: '14px',
          fontWeight: 500,
        },
      }).showToast();
    }
     