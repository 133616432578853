import React from 'react';


import * as COM_DEFS from '../../com_defs.mjs';


import { NuPost2 } from '../../api.js';

import { DaTextField } from '../../misc/text_field.js';

import * as DT from '../../dt.js';

import { Grid3 } from '../../grid3.js';

import { CmCachedCustLink } from '../../desktop/ca_cust_link.js';

import { LayVert2, LayHoriz2, LaySpace } from '../../misc/layout.js';

import {  ShowMsg, ShowErr, CommonReducer } from '../../misc_glob.js';

import { Win2Confirm } from '../../misc/win2_confirm.js';

import { DaDateField } from '../../misc/date_field.js';


import { CmHelpIcon } from '../../misc/help_icon.js';


import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';

import AddIcon from '@mui/icons-material/Add';

import { DaButton } from '../../misc/but.js';

import RemoveIcon from '@mui/icons-material/Remove';

import { CmComColCellDateTime } from '../../cm_table_cell_com.js';

import { WinSellGood } from '../../desktop/win_sell_good.js';

import { HowPayedInfo } from '../../desktop/how_payed.js';

import { WinSellGoodTraBonus } from '../../desktop/win_sell_good_tra_bonus.js';
import { DaComboTrainerLocals } from '../../desktop/combo_trainer.js';

import { useGlobalShit } from '../../v3/hk_global_shit.js';

//--------------------------------------------------------------------------------


function ColHow(pp)
{
    const ROW = pp.row;
    return <HowPayedInfo clubId={ROW.v_club_id} bindCtx={COM_DEFS.PAY_OPS_CTX_PR_GOOD} bindId={ROW.pgs_id}/>;
}

function CmBitDiscount(pp)
{
	if (pp.value < 1) return '';
	return pp.value;
}

function CmBitPrice(pp)
{
	if (pp.row.pgs_discount_mon < 1) return pp.value;
	return <b style={{color: '#60A020'}}>{pp.value}</b>;
}
      
      
function MyColForCust(pp)
{
    if (pp.value === 0) return '';
    return <CmCachedCustLink rid={pp.value}  />;
}



export function MyGoodNameAndCnt(pp)
{
	let ccc = pp.row.prg_name;
	if (ccc === null) ccc = (<b className='red_shit'>ТОВАР ({pp.value}) УДАЛЁН</b>);
	return <>{ccc}<b className='tx_good_cnt'>x {pp.row.pgs_count}</b></>;
}

function MyComment(pp)
{
    let cc = pp.row.pgs_comment;

    let TRA_SELL = null;

    if (pp.row.u_name !== null)
    {
        TRA_SELL = <div className="tx_seller"><AssignmentIndIcon sx={gTmp1}/> {pp.row.u_name + ' +' + pp.row.isb_amo}</div>;
    }


    return <div>{TRA_SELL}{cc}</div>;
}

const gTmp1 = {fontSize: '18px'};

const gNuCols = [

    {sort: true,   align: 'left',     fld: 'pgs_item_id',         name: 'Товар',      width: 324, renderCell: MyGoodNameAndCnt},
    {sort: true,   align: 'center',   fld: 'pgs_price_fact',      name: 'Продано за', width: 130, renderCell: CmBitPrice},
    {sort: true,   align: 'left',     fld: 'pgs_dt_sell',         name: 'Продажа',    width: 150, renderCell: CmComColCellDateTime},
    
    {sort:false,   align: 'center',   fld: 'pgs_discount_mon',      name: 'Скидка руб', width: 130, renderCell: CmBitDiscount},
    
    {sort: false,  align: 'left',  fld: 'v_how',                    name: 'Оплата',  width: 170, renderCell: ColHow},

    //{sort: true,   align: 'left',     fld: 'pgs_dt_created',         name: 'Запись созд.',    width: 170, renderCell: CmComColCellDateTime},
    
    {sort: true,   align: 'left',   fld: 'pgs_for_cust_id',      name: 'Покупатель', width: 290, renderCell: MyColForCust},

    {sort: false,  align: 'left',  fld: 'pgs_comment',                    name: 'Инфа / коммент',  width: 300, renderCell: MyComment},
];




const gStaInit = {
  
    ser:        0,
    pop:        0,
    f_dt_f:     null,
    f_dt_t:     null,
    f_name:     '',
    f_seller:   -1,
    selected:   [],
    busy:       false,

    assRid:     -1,
    
};


// раздел  ПРОДАЖИ ТОВАРОВ

export function TabPrSell( { } )
{
    const [sta, dispatch] = React.useReducer( CommonReducer, gStaInit );
    
    const sss = useGlobalShit();
    

    
	const cb_close_pop = () => {
		dispatch( {type: 'val', k:'pop', v:0 } );
	};
    
	const cb_ok = () => {
		dispatch( {type: 'val', k:'selected', v:[] } );
		dispatch( {type: 'val', k:'pop', v:0 } );
        dispatch( {type: 'val', k:'ser', v:sta.ser+1 } );
	};
	
	const cb_new = () => {
        dispatch( {type: 'val', k:'pop', v:1 } );
	};
	
	const cb_del = () => {
		dispatch( {type: 'val', k:'pop', v:2 } );
        
	};

	const cb_ass = () => {

        const ID = sta.selected[0];

		dispatch( {type: 'vals', dct:{pop:3, assRid: ID}   } );
        
	};
	
		
	
    function cb_confirm_ass_tra()
    {
        dispatch( {type: 'val', k:'pop', v:0 } );
        dispatch( {type: 'val', k:'ser', v:sta.ser+1 } );
    }

		
	const cb_confirm_del = () => {
		
        const ID = sta.selected[0];
        
		const __ok = (e) =>
		{
			let t0 = 'ПРОДАЖА УДАЛЕНА !' 
			
			if (e.reverted > 0)
            {
                t0 += ` - ВЕРНУЛОСЬ ${e.reverted} шт. (ВСЕГО ${e.now_stock} шт)`;
            }

			ShowMsg(t0);
			
			dispatch( {type: 'val', k:'selected', v:[] } );
			dispatch( {type: 'val', k:'busy', v:false } );
            dispatch( {type: 'val', k:'pop', v:0 } );
            dispatch( {type: 'val', k:'ser', v:sta.ser+1 } );
		};
		
		const __fail = (e) =>
		{
			ShowErr(e.m);
			dispatch( {type: 'val', k:'busy', v:false } );
		};
		
		dispatch( {type: 'val', k:'busy', v:true } );
		
		
		NuPost2(963, {rid: ID}, __ok, __fail);
	};
		
        
        

    
    const cb_chg = React.useCallback( (k,v) => {
        dispatch( {type: 'val', k:k, v:v } );
    }, [] );
    
	const cb_chg_sel = (nu) => {
		dispatch( {type: 'val', k:'selected', v:nu } );
	};
    
    const FLT_GRID = React.useMemo( () => {
        
        const FF = DT.DateTimeStrIso( sta.f_dt_f );
        const TT = DT.DateTimeStrIso( sta.f_dt_t );

        return { seller: sta.f_seller, dt_from: FF, dt_to: TT, name: sta.f_name.trim()  };
        
    }, [sta.ser, sta.f_name, sta.f_dt_f, sta.f_dt_t, sta.f_seller] );
    
    
    
    const DIS_ADD = (!sss.userHasAbil(600));
	const DIS_DEL = (!sss.userHasAbil(600)) || (sta.selected.length < 1);
	
	const CLA = sta.busy ? 'zz_busy' : null;
    
    const DIS_WHO = sta.selected.length < 1;
    
    return (
    
                    <>	<LayVert2 rows='max-content 1fr' padding='16px' gap='16px' >
				

                            <LayHoriz2 cols='max-content max-content max-content 160px 160px 250px 200px 1fr max-content' gap='10px'>
                                <DaButton icon={AddIcon}     onClick={cb_new} disabled={DIS_ADD} title='НОВАЯ' />
                                <DaButton icon={RemoveIcon } onClick={cb_del} disabled={DIS_DEL} title='УДАЛИТЬ' />
                                <DaButton icon={AssignmentIndIcon } onClick={cb_ass} disabled={DIS_WHO} title='КТО ПРОДАЛ' />
                                <DaDateField  label='ОТ' name='f_dt_f'    onChange={cb_chg}  value={sta.f_dt_f} />				
                                <DaDateField  label='ДО' name='f_dt_t'    onChange={cb_chg}  value={sta.f_dt_t} />
                                <DaTextField label='ПО НАЗВ.' name='f_name' onChange={cb_chg} value={sta.f_name} />
                                <DaComboTrainerLocals name='f_seller' label='ПРОДАЛ ТРЕНЕР' onChange={cb_chg} value={sta.f_seller} />
                                <LaySpace />
                                <CmHelpIcon juice={gMyHelp1} />
                        
                            </LayHoriz2>
            
                      
                                            
                                <Grid3 singleSel  isNuApi onChangeSel={cb_chg_sel}  selected={sta.selected} flt={FLT_GRID}
                                    cols={gNuCols} apiCmd={960} uniq='pgs_id'  sort_field='pgs_dt_created' sort_dir='desc' code='goods_sells' />
                                         
						
					</LayVert2>
					
					<WinSellGood visible={sta.pop === 1} onClose={cb_close_pop} onOk={cb_ok} />
                    
                    
                    <Win2Confirm busy={sta.busy} visible={sta.pop === 2} onClose={cb_close_pop} onOk={cb_confirm_del} title='УДАЛИТЬ ПРОДАЖУ ?' />
                    

                    <WinSellGoodTraBonus busy={sta.busy}  rid={sta.assRid} visible={sta.pop ===3 } onOk={cb_confirm_ass_tra} onClose={cb_close_pop} />

					</>
    
    );
}



const gMyHelp1 = "Чтобы закрепить тренера за продажей: подсвечиваем строчку и нажимаем 'КТО ПРОДАЛ'.";