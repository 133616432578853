import React from 'react';


import * as DT from '../dt.js';

import { NuPost } from '../api.js';

import { Grid3 } from '../grid3.js';

import { LayHoriz2, LayVert2 } from '../misc/layout.js'

import { MyStaticField } from '../cust_card/vi_overview.js';

import { CmComColCellDateTimeHM,  CmComColUser, CmComColTrpStatus  } from '../cm_table_cell_com.js';

//---------------------------------------------------------------





const gColz  = [
  { sort: true,   align: 'center', 	    fld: 'ts_id', 				name: 'TS_ID', 	    width: 80 },
  { sort: true,   align: 'center', 		fld: 'ts_time_start', 		name: 'Старт', 	    width: 150, renderCell: CmComColCellDateTimeHM },
  { sort:false,   align: 'center', 		fld: 'v_status', 		    name: 'Статус', 	width: 150, renderCell: CmComColTrpStatus},
  { sort: true,   align: 'left', 	    fld: 'ts_user_id', 			name: 'Тренер', 	width: 300, renderCell: CmComColUser },
  { sort: false,  align: 'left', 	    fld: 'ts_impo_text', 		name: 'Коммент', 	width: 450 },
  

];


export function GridAbonTrainsMon( {payId} )
{
	const FLT = React.useMemo( () => { return {pay_id: payId} }, [payId] );
    
    return (
		<Grid3  cols={gColz}	uniq='ts_id'   sort_field='ts_time_start' sort_dir='desc'  flt={FLT} apiCmd={999}     />                   
    );
}




// инфа о тренях (месячнй абон)

export function ViTrainsMonthly( {rid} )
{
	const [busy, setBusy] = React.useState( false );
	
	const [row, setRow] = React.useState( null );
	
	React.useEffect( () => {
		
		//console.log('TRAINS MON MOUNT !');
		
		function __ok(e)
		{
			setBusy(false);
			
			//console.log('GOT.. ', e);
			
			setRow( e.row );
		}
		
		function __fail(e)
		{
			setBusy(false);
		}
		
		setBusy(true);
		
		NuPost( 794, {pay_id: rid}, __ok, __fail );
		
	}, []);
	
	//----
	
	let p_top = null;
	
	if (row)
	{
		const ss = DT.DateStrLoc( DT.DateTimeParseIso( row.pay_usable_dt_s ) );
		const ee = DT.DateStrLoc( DT.DateTimeParseIso( row.pay_usable_dt_e ) );
		
		const s_pay = DT.DateStrIsoToLoc( row.pay_pay_time ) || '---';
		const s_sta = DT.DateStrIsoToLoc( row.pay_start_time ) || '---';
		
		p_top = 	(<div style={{paddingBottom: '20px', display: 'flex', gap: '4px'}}>
							<div style={{width: '50%'}}>
								<MyStaticField tit='Действует:'   val={ss + ' - ' + ee}/> 
								<MyStaticField tit='Закрытых трень:'   val={row.v_num_closed}/> 
							</div>
							<div style={{width: '50%'}}>
								<MyStaticField tit='Куплен:'  			val={s_pay} /> 
								<MyStaticField tit='Старт:'  			val={s_sta} /> 
							</div>
						</div>);
	}
	

	return (
		<LayVert2 rows='max-content 1fr' padding='10px' gap='10px' >
		
			<div>
				{p_top}
			</div>
			
            <GridAbonTrainsMon payId={rid} />

		
		</LayVert2>
	);
	
}
