import React from 'react';

import * as COM_DEFS from '../com_defs.mjs';
import * as DEFS from '../defs.js';
import * as HLP from '../helpers.js';

import { CmCachedUserLink } from './ca_user_link.js';

import { useApiQuery2 } from '../api_stuff.js';

import {DaToolTip} from '../misc/tooltip.js';


import { MdOutlineAccessTimeFilled } from "react-icons/md";

import { SmNotifyCounter } from '../menu2/items.js';

//--------------------------

//---------------------------------------------


function Boxy( {clr} )
{
    return <span style={{display: 'inline-block', width: '4px', height: '10px', background: clr}}></span>;
}

//----------------------


function LineSingTaskImp( {txt, uid, trg} )
{
    if ( txt.length > 54 ) txt = txt.substring(0, 54) + '...';

    let fff = null;

    if (uid > 0)
    {
        fff = ( <span style={{color: 'var(--fl-clr-primary-br)', fontSize: '13px', fontWeight: 700}}> - <CmCachedUserLink plain rid={uid}/> </span>  );
    }

    const ttt = trg ? <MdOutlineAccessTimeFilled fontSize='14px' color='#FF8080' /> : null;

    return ( <div style={{display: 'flex', gap: '4px', alignItems: 'center'}}>- {ttt}{txt}{fff}</div> );
}


const LineSingTask = React.memo( LineSingTaskImp );

//----------------------------------------------



export function MeNotifyTasks( {isVert} )
{ 


    const { isFetching, isLoading, isError, data, error } = useApiQuery2( 1060, {}, {refetchIntervalInBackground: true, staleTime: 30000, refetchInterval: 70000} );

    if (isLoading || !data) return <div></div>;
    if (isError) return <div>WTF ?</div>;
    

    const cz = data.data[-1].cnt;
    const c1 = data.data[1].cnt;
    const c3 = data.data[3].cnt;
    const c5 = data.data[5].cnt;

    if (c1+c3+c5+cz < 1) return null;

    const kkk = [1,3,5,-1];

    let li1 = [];

    for (let t = 0; t < kkk.length; t ++ )
    {
        const aaa = kkk[t];

        const LI = data.data[aaa].lst;
        const NUM = LI.length;
        if (NUM > 0)
        {
            if (li1.length > 0) // separator
            {
                li1.push(<div key={'sep'+t} style={{width: '100%', height: '10px'}}></div>);
            }

            const MY_CLR = `var(--fl-task-r${aaa})`;

            const vis = aaa !== -1 ? COM_DEFS.gVisUserRole[aaa] : 'БЕЗ ПРИВЯЗКИ';
            // title  FOR ROLE
            li1.push(<div key={'tit'+t} style={{display: 'flex', alignItems: 'center', gap: '6px', fontSize: '14px', fontWeight: 600}}>
                    <Boxy clr={MY_CLR}/>{vis}:
                </div>);

            // tasks
            for (let x = 0; x < NUM; x ++ )
            {
                const te  = LI[x].t;
                const uid = LI[x].uid;
                const trg = LI[x].trg;
                li1.push( <LineSingTask key={t+'-'+x} txt={te} uid={uid} trg={trg} /> );
            }
        }
    }

    const p_tp = (

        <div style={{padding: '6px', fontSize: '14px'}}>
            {li1}
        </div>

    );

    return (

            <DaToolTip  title={p_tp}>
                <div style={ isVert ? gStyVert : gStyHoriz }>
                    
                    { c1 > 0 && <SmNotifyCounter clr='var(--fl-task-r1)' num={c1} /> }
                    { c3 > 0 && <SmNotifyCounter clr='var(--fl-task-r3)' num={c3} /> }
                    { c5 > 0 && <SmNotifyCounter clr='var(--fl-task-r5)' num={c5} /> }
                    { cz > 0 && <SmNotifyCounter clr='var(--fl-task-r-1)' num={cz} /> }
                        
                </div>
                
            </DaToolTip>


    );


}

const gStyHoriz = {display: 'flex', justifyContent: 'center', gap: '2px'};
const gStyVert = {width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: '6px'};