import React from 'react';
import * as HLP from '../helpers.js';

import { useApiQuery2 } from "../api_stuff";
import { GetBackendBaseURL } from "../api";
import { CmSpinner } from "../cm_base";


function ErrPic(  )
{
    return <div style={{background: '#FF0000'}}>?</div>;
}

function NoPic( {className} )
{
    return <div className={className} style={{userSelect: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#505050', color: '#FFFFFF'}}>?</div>;
}


function PicBaseImp( {picType, rid, className} )
{
    //console.log('PIC BASE ', rid, ' -- ', picType);
	const { isFetching, isLoading, isError, data, error } = useApiQuery2( 4123, {rid: rid, ty: picType} );
	
	if (!data) return ( <CmSpinner sz='16px' />  );
	
	if (isError) return <ErrPic />;
	
	if (data.path === null) return <NoPic className={className} />;
	 
	
	let url = GetBackendBaseURL() + data.path;
	if (data.dt !== null) url += '?'+data.dt;

	return (<div className={className} style={{backgroundImage: 'url('+url+')', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center'}}></div>);
}

export const PicBase = React.memo( PicBaseImp );
