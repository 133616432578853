import React from 'react';


import { useUserOpts, useUserOptsDispatch } from '../hk_user_opts';


import { NuPost2 } from '../api';

//--------------------------------

function CmOptionsAutoSaver( {isAct} )
{
	const [isReq, setIsReq] 	= React.useState(false);
    
    const u_opts = useUserOpts();
    
    //console.log('AUTO SAVER.. act: ', Number(isAct) );

	React.useEffect(() => {
        
        if (!isAct) return;
        
		let interval = null;

		interval = setInterval(() => {
			
			
			if ( !isReq )
			{
				setIsReq(true);
				
				const __ok = () => {
					
					setIsReq(false);
					console.log('SAVED USER OPTIONS...');
				};
				
				const __fail = () => {
					
					setIsReq(false);
					console.log('FAIL !');
				};
				
				//console.warn('SAVE USER SETTINGS   -- TODO');
				
                //console.log(' SAVE !! ');
                
				NuPost2(971, u_opts, __ok, __fail);
			}
			
			
		}, 1000 * 300);
    
		return () => clearInterval(interval);
  }, [isAct, u_opts]);

  return (  <></>   );
}


// <SaveIcon sx={{  fontSize: '16px', color: isReq ? '#70FF70' : '#FFFFFF20' }} /> 


export function UserOptsMgr( )
{
    //console.log('UserOptsMgr()...');
    
    const [isInit, setIsInit] = React.useState( false );
    
    const disp_u = useUserOptsDispatch();
    
    React.useEffect( () => {
        
        //console.log('UserOptsMgr... effect !');
        
        function __ok(e)
        {
            console.log('LOADED USER SETTINGS... ', e.jj);

            disp_u( {type: 'load', e:e.jj} );
            
            setIsInit(true);
        }
        
        function __fail()
        {
            console.warn('OPTIONS LOAD FAIL !');
        }
        
        NuPost2(970, {}, __ok, __fail);
        
    }, [] );
    
    return (<>
                <CmOptionsAutoSaver isAct={isInit} />
            </>);
}

