 
import React from 'react';

import { CmComColCellDateTimeHoursMinutes } from '../cm_table_cell_com.js';

import { CommonReducer } from '../misc_glob.js';

import { OverlayLoading } from '../misc/ovr_loading.js';

import { Grid3 } from '../grid3.js';

import { DaCheck } from '../misc/check.js';

import * as COM_DEFS from '../com_defs.mjs';

import { NuPost2 } from '../api.js';

import { LayVert2, LayHoriz2, LaySpace } from '../misc/layout.js';

import { ShowMsg, ShowErr } from '../v3/toast.js';

import { BottomButtonsPanel } from './bottom_pan.js';
import { DaButton } from '../misc/but.js';

import { MdVpnKey } from "react-icons/md";


import { DaTextField } from '../misc/text_field.js';

const gST = {

    busy:       false,
    codeText:   '',
    selected:   [],
    ser:        0,
};




const gCols = [

    {fld: 'cd_name',           name: 'Акция',            align: 'left',      sort: false, width: 200},

    {fld: 'ci_code',           name: 'Код',              align: 'left',      sort: false, width: 200},
    {fld: 'us_dt_when',        name: 'Применено',        align: 'center',     sort: true,  width: 250, renderCell: CmComColCellDateTimeHoursMinutes},

    
];



export function ViCustCardCerts( {cuId} )
{
    const [sta,dispatch] = React.useReducer( CommonReducer, gST );



    const cb_chg = React.useCallback( (k,v) =>
    {
        dispatch( {type: 'val', k: k, v: v} );
    }, [] );

	const cb_chg_sel = React.useCallback( (nu) => {
        dispatch( { type: 'val', k: 'selected', v: nu } );
	}, [sta.selected] );
	

    function cb_act()
    {
        function __ok(e)
        {
            ShowMsg('КОД АКТИВИРОВАН !');
            dispatch( {type: 'vals', dct:{busy: false, ser: sta.ser+1, codeText: '', selected: []}} );
        }

        function __fail(e)
        {
            ShowErr(e.m);
            dispatch( {type: 'vals', dct:{busy: false}} );
        }

        dispatch( {type: 'vals', dct:{busy: true}} );

        NuPost2( 4253, {v:sta.codeText, cuId:cuId}, __ok, __fail );
    }

    const FLT = React.useMemo( () => {
        return { cuId: cuId };
    }, [sta.ser] );
 
    return (<LayVert2 rows='1fr max-content' gap='10px' >

            <LayHoriz2 cols='450px 1fr' gap='10px' padding='10px'>

                <LayVert2 rows='max-content 1fr' padding='20px' gap='30px' >
            
                        <LayHoriz2 cols='1fr max-content' gap='6px' ac>
                            <DaTextField label='АКТИВИРОВАТЬ КОД' name='codeText' value={sta.codeText} onChange={cb_chg }/>
                            <DaButton onClick={cb_act} icon={MdVpnKey} title='АКТИВИРОВАТЬ'/>
                        </LayHoriz2>

                        <LaySpace />
                </LayVert2>


                <Grid3 singleSel cols={gCols} isNuApi  flt={FLT} selected={sta.selectedInst}
                                onChangeSel={cb_chg_sel}  apiCmd={4252} uniq='us_cert_inst_id' 
                                    sort_field='us_cert_inst_id' sort_dir='asc' code='cc_cert_usage' />

            </LayHoriz2>

            <OverlayLoading active={sta.busy} />

            </LayVert2>);
}


/*
			<BottomButtonsPanel>
					<DaButton disabled={DIS_SAVE} onClick={cb_save} icon={SaveIcon} title='СОХРАНИТЬ'/>
				</BottomButtonsPanel>

*/