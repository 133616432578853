import React from 'react';

import { NuPost2 } from '../../api.js';

import { DaButton } from '../../misc/but.js';

import { DaCheck } from '../../misc/check.js';

import { ComboCats } from './combo_cats.js';

import { CmHelpIcon } from '../../misc/help_icon.js';

import { Win2Confirm }  from '../../misc/win2_confirm.js';

import { LayVert2, LayHoriz2, LaySpace } from '../../misc/layout.js';


import { DaSearchBar } from '../../misc/search_bar.js';


import {  CommonReducer, ShowMsg, ShowErr } from '../../misc_glob.js';


import { Grid3 } from '../../grid3.js';

import { CmComColCellDateTime } from '../../cm_table_cell_com.js';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';

import { WinEditGood } from '../../desktop/win_edit_prod_good.js'

import { WinGoodCats } from '../../desktop/win_good_cats.js';

import { useGlobalShit } from '../../v3/hk_global_shit.js';

//----------------------------------------------------------------------------



function CmTabFmtPrice(p)
{
    return <>{p.value}p</>;
}

function CmTabFmtCat(p)
{
	let ccc = p.row.prgc_name;
	if (ccc === null) return <b className='red_shit'>УДАЛЕНА ({p.value})</b>;
	return <span style={{color: '#304080'}}>{ccc}</span>;
}


function MyColStock(pp)
{
	if (pp.value < 0) return <b className='red_shit'>{pp.value}</b>;
	return pp.value;
}

function MyColName(pp)
{
    if (pp.row.prg_disabled > 0) return <span className="red_shit">{pp.value}</span>;
    return pp.value;
}


//------------------------





const gStaInit = {
    
    flt_nam:    '',
    flt_cat:    -1,
    flt_hide_inactive:  1,
    ser:        0,
    
    busy:       false,
    selected:   [],
    
    //pop:        null,
    
    
    pop_mode:       0,
    
    pop_dct_good:   {},
    pop_dct_conf:   {},
    pop_dct_cats:   {},
    
};



function MyView( {isAtBoss} )
{
    const [sta, dispatch] = React.useReducer( CommonReducer, gStaInit );
    
    const sss = useGlobalShit();
    
    const DIS_CATS = !sss.userHasAbil( 510 );
    const DIS_ADD  = !sss.userHasAbil( 510 );
    const DIS_DEL  = (!sss.userHasAbil( 510 )) || (sta.selected.length < 1);
    
	const cb_close = () => {
		dispatch( {type: 'val', k:'pop_mode', v:0} );
	};
	
    
	const cb_open_cats = () => {
		
		const __ok = () => {
			
			dispatch( {type: 'val', k:'ser', v:sta.ser+1} );
			dispatch( {type: 'val', k:'pop_mode', v:0} );
		};
        
        const TTT = { onClose: cb_close, onOk: __ok };
        
		dispatch( {type: 'val', k:'pop_mode', v:3} );
        dispatch( {type: 'val', k:'pop_dct_cats', v:TTT} );
	};
    

  

	const cb_row_dbl = (kk) => {
		
        if (!sss.userHasAbil( 510 )) return;
        
        const ID    =  kk;

        const __ok = () => {
            dispatch( {type: 'val', k:'ser', v:sta.ser+1} );
            dispatch( {type: 'val', k:'pop_mode', v:0} );
        };
            
        const TTT = { rid: ID, onClose: cb_close, onOk: __ok };
        
		dispatch( {type: 'val', k:'pop_mode', v:1} );
        dispatch( {type: 'val', k:'pop_dct_good', v:TTT} );
	};
	
    
	const cb_open_new = () => {

        const __ok = () => {
            dispatch( {type: 'val', k:'ser', v:sta.ser+1} );
            dispatch( {type: 'val', k:'pop_mode', v:0} );
            dispatch( {type: 'val', k:'selected', v:[]} );
        };
            
        const TTT = { rid: -1, onClose: cb_close, onOk: __ok };
        
		dispatch( {type: 'val', k:'pop_mode', v:1} );
        dispatch( {type: 'val', k:'pop_dct_good', v:TTT} );
	};
    
	const cb_del = () => {
		
		const ID =  sta.selected[0];
		
		const __confirm = () => {
			
			let cb_ok = (e) =>
			{
				ShowMsg('УДАЛЁН !');
				dispatch( {type: 'val', k:'selected', v:[]} );
				dispatch( {type: 'val', k:'ser', v:sta.ser+1} );
				dispatch( {type: 'val', k:'busy', v:false} );
				dispatch( {type: 'val', k:'pop_mode', v:null} );
			};
			
			let cb_fail = (e) =>
			{
				ShowErr(e.m);
				dispatch( {type: 'val', k:'busy', v:false} );
			};
			
			dispatch( {type: 'val', k:'busy', v:true} );
			
			NuPost2(954, {rid: ID}, cb_ok, cb_fail);
		};
		
        const nnn = {title:'ТОЧНО УДАЛИТЬ ?',  onOk:__confirm, onClose:cb_close };
        
        dispatch( {type:'val',  k:'pop_dct_conf', v:nnn} );
		dispatch( {type: 'val', k:'pop_mode', v:2} );
	};




    
	const cb_chg_sel = React.useCallback( (nu) => {
		dispatch( {type: 'val', k:'selected', v:nu} );
	}, [] );
    
	const cb_chg = React.useCallback( (k,v) => {
		dispatch( {type: 'val', k:k, v:v} );
	}, [] );
    
    const FLT_TAB = React.useMemo( () => {
        return { nam: sta.flt_nam, cat: sta.flt_cat, hide_inact: sta.flt_hide_inactive  };
    }, [sta.ser, sta.flt_nam, sta.flt_cat, sta.flt_hide_inactive] );
    
    
    
    
    
    const colz = React.useMemo( () => {
        
        const rr = [
        
            {fld: 'prg_id',        name: 'ID',                align: 'center',      sort: true, width: 70},
            {fld: 'prg_name',      name: 'Товар',             align: 'left',        sort: true, width: 300, renderCell: MyColName },
            {fld: 'prg_cat_id',    name: 'Категория',         align: 'center',       sort: true, width: 210, renderCell: CmTabFmtCat},
            {fld: 'prg_price',     name: 'Цена',              align: 'center',       sort: true, width: 150, renderCell: CmTabFmtPrice },
            {fld: 'prg_dt_change', name: 'Изменено',          align: 'center',       sort: true, width: 150, renderCell: CmComColCellDateTime },
        ];
        
        if (!isAtBoss)
        {
            rr.push( {fld: 'v_stock',       name: 'В наличии',         align: 'center',       sort: true, width: 130, renderCell: MyColStock} );
        }
        
        return rr;
        
    }, [] );
    

    const TAB_CODE = isAtBoss ? 'prod_goods_b' : 'prod_goods_c';

    return (<>
            <LayVert2 rows='max-content 1fr' padding='20px' gap='10px'>
        
                <LayHoriz2 cols='max-content 20px max-content max-content 20px 240px 220px 240px 1fr max-content' gap='8px' >
                
                        <DaButton disabled={DIS_CATS} onClick={cb_open_cats} title='КАТЕГОРИИ' icon={ PlaylistAddCheckIcon } />

                        <LaySpace />

                        <DaButton disabled={DIS_ADD} onClick={cb_open_new}   title='НОВЫЙ' icon={ AddIcon } />
                        <DaButton disabled={DIS_DEL} onClick={cb_del}        title='УДАЛИТЬ' icon={ RemoveIcon } />
                        
                        <LaySpace />
                        
                        <DaSearchBar name='flt_nam' value={sta.flt_nam} onChange={cb_chg} label='ПО НАЗВАНИЮ' />
                        
                        <ComboCats name='flt_cat' value={sta.flt_cat} onChange={cb_chg} label='КАТЕГОРИЯ'  />
                        
                        <DaCheck name='flt_hide_inactive' value={sta.flt_hide_inactive} onChange={cb_chg}  label='СКРЫВАТЬ НЕАКТИВНЫЕ'/>
                        
                        <LaySpace />
                        
                        <CmHelpIcon juice="Описания товаров общие для всех клубов. Колонка 'В НАЛИЧИИ' - количество в текущем клубе." />
                        
 
                </LayHoriz2>

                <Grid3 singleSel onDblClick={cb_row_dbl} onChangeSel={cb_chg_sel} 
                            selected={sta.selected}  cols={colz} apiCmd={950}
                            uniq='prg_id'  sort_field='prg_id' sort_dir='asc' flt={ FLT_TAB } code={TAB_CODE} />
        
        
            </LayVert2 >
            

            <WinEditGood visible={sta.pop_mode===1}   {...sta.pop_dct_good} />
            
            <Win2Confirm visible={sta.pop_mode===2} busy={sta.busy} {...sta.pop_dct_conf} />
            
            <WinGoodCats visible={sta.pop_mode===3}  {...sta.pop_dct_cats} />
            


            </>
    );
}





// ТОВАРЫ (в клубе)
export function TabPrGoods( )
{
    return (<MyView isAtBoss={false} />);
}


// ТОВАРЫ (мастер панель)
export function TabPrGoodsBOSS( )
{
    return (<MyView isAtBoss={true} />);
}

