import React from 'react';


import { ShowMsg, ShowErr } from '../misc_glob.js';

import { ListItemTrainer } from '../desktop/li_trainer.js';

import {DaCombo} from '../misc/combo.js';

import { NuPost2 } from '../api.js';

    
//------------------------------

const ELEM_ALL = [ {u_id:-1, u_name: '---'} ];

// комбо с тренерами для текущего клуба
export function DaComboTrainerLocals( {name, disabled, value, onChange, label='ТРЕНЕР'} )
{
	const [lst, setLst] 		= React.useState( ELEM_ALL );
	const [isBusy, setBusy] 	= React.useState(false);
	
 	React.useEffect( () => {
		
		const cb_ok = (e) =>
		{
			setBusy(false);
			setLst( ELEM_ALL.concat( e.rows) );
		};
		
		const cb_fail = (e) =>
		{
			ShowErr('FAIL !');
			setBusy(false);
		};

		NuPost2(884, {}, cb_ok, cb_fail);
		
		setBusy(true);
		
	}, [] );
	
	
	return <DaCombo itemCmp={ListItemTrainer} label={label} fld_k='u_id' fld_v='u_name' name={name} src={lst} disabled={isBusy || disabled} value={value} onChange={onChange} />
}
 
 
