import React from 'react';


import { EvActDispNameCSV } from '../desktop/ev_act.js';

import * as DEFS from '../defs.js';
import * as COM_DEFS from '../com_defs.mjs';
import * as HLP from '../helpers.js';

import { ShortName } from '../desktop/short_name.js';

import { NuPost2 } from '../api.js';

import * as DT from '../dt.js';


//--


// инфа о абонементе в плашке с подсветкой выбранной трени
export class PaymentInfo extends React.Component
{
    constructor(props)
    {
        super(props);
        
        this.RqOk   = this.RqOk.bind(this);
        this.RqFail = this.RqFail.bind(this);
        
        this.state = { row: null, busy: false, is_del: false };
    }
    
    RqOk(e)
    {
        this.setState( {busy: false, row: e.row, is_del: e.is_del} );
        
        //console.log( 'GOT PAYMENT ', e );
    }
    
    RqFail(e)
    {
        this.setState( {busy: false} );
    }
    
    ReqShit()
    {
        this.setState( {busy: true} );
        NuPost2( 851, {pay_id: this.props.pay_id}, this.RqOk, this.RqFail );
    }
    
    componentDidMount()
    {
        this.ReqShit();
    }
    
    componentDidUpdate(pp)
    {
        //console.log('PP.. ', pp.trp_serial, ' -- ', this.props.trp_serial);

        if ( pp.trp_serial !== this.props.trp_serial )
        {
            console.log('PAY TIME CHANGED SHIT !!!!');
            this.ReqShit();
        }
    }
    
    GenItems(a_cur_trp_id, a_is_closed)
    {
        // compact..
        
        let num_tot = 0;
        let num_used = 0;
        
        let dct = {};
        
        //console.log('ITER...');
        
        for (let x = 0; x < this.state.row.list_trps.length; x ++ )
        {
            const trp = this.state.row.list_trps[x];
            
            //console.log('ITER IN PAY ', trp);
            
            let k   = trp.trp_acts_csv; //trp.trp_type + '-' + trp.trp_dirs;
            
            //console.log('K: ', k);
            
            if (dct[k] === undefined)
            {
                dct[k] = { cnt_tot: 0, cnt_used: 0, cur: false };
            }
            
            dct[k].cnt_tot ++;
            
            num_tot ++;
            
            
            let is_cur = (trp.trp_id == a_cur_trp_id);
            
            
            let sta = trp.trp_status;
            
            if (trp.trsb_sched_id === this.props.ts_id)
            {
                //console.log('override status in pay...');
                sta = 0;    // override status
            }
            
            if (sta !== 0 && !is_cur)
            {
                dct[k].cnt_used ++;
                num_used ++;
            }
            
            
            
            //console.log('  CUR: ', is_cur);
            
            if (dct[k].cur === false && is_cur) dct[k].cur = true;
            
            if (is_cur && a_is_closed)
            {
                dct[k].cnt_used ++;
                num_used ++;
            } 
            
            
        }
        
        
        //console.log('DCT..', dct);
        
        
        let p = [];
        
        if (this.state.row.pay_logic === COM_DEFS.ABON_LOGIC_NORMAL)
        {
			for (let k in dct)
			{
				let o = dct[k];

				let v = <EvActDispNameCSV csv={k} />;
				
				let clrclr = o.cur ? '#404040FF' : '#20304050';
				let bgbgbg = o.cur ? '#FFFFFF' : null;
				
				p.push( <div style={{fontWeight: 600, fontSize: '14px', color: clrclr}} key={k}>• {v} - {o.cnt_used} / {o.cnt_tot}</div>  );
			}
		}
        
        let s_pay_used_cnt = null;
        
        let left = (num_tot - num_used);
        
        let p_bot = null;
        
        if (a_cur_trp_id !== undefined && this.props.cur_trp_status !== DEFS.TPR_STATUS_BURNED)
        {
            if (a_is_closed)
            {
                p_bot = 'ТРЕНИРОВОК ОСТАЛОСЬ: ' + left;
            }
            else
            {
                p_bot = <>ТРЕНИРОВОК ОСТАНЕТСЯ: <b className="warning_text_lite">{left-1}</b></>;
            }
        }
        
        {
            s_pay_used_cnt = ( <div>- {num_used} / {num_tot}</div> );
        }
        
        
        if (this.state.row.pay_logic === COM_DEFS.ABON_LOGIC_NORMAL)
        {
        
			return (<div>
                        <div style={{padding: '10px', display: 'flex', gap: '10px', alignItems: 'center'}}>
                            <ShortName ctx={DEFS.SHORT_NAME_ABON} id={this.props.pay_id} />{s_pay_used_cnt}
                        </div>
                        <div style={{paddingLeft: '16px'}}>
                         {p}
                        </div>
                    <div align="center" style={{textAlign: 'right', fontSize: '12px', color: '#404040', padding: '8px'}}>{p_bot}</div>
                </div>);
		}
		
		// mon
		
		const usa_s = DT.DateStrLoc( DT.DateTimeParseIso( this.state.row.pay_usable_dt_s ) );
		const usa_e = DT.DateStrLoc( DT.DateTimeParseIso( this.state.row.pay_usable_dt_e ) );
		
		return (<div style={{marginTop: '10px', display: 'flex', gap: '30px'}}>
                    <div className='zz_text_secondary'>ДЕЙСТВУЕТ: <b>{usa_s} - {usa_e}</b></div>
                </div>)
            
    }
    
    render()
    {
        let s = this.state;
        
        if (s.is_del)
        {

            return <div>
                        <div style={{padding: '16px 6px'}}>
                        <div className="red_shit">АБОНЕМЕНТ УДАЛЁН ({this.props.pay_id})</div>
                        <EvActDispNameCSV csv={this.props.cur_trp_acts} />
                        </div>
                    </div>;
        }
        
        if (s.row === null) return null;
        
        
        /*
        let dr_book = CA_DirGetBySpecial(2);
        
        if (HLP.CsvTestID( this.props.cur_trp_dirs, parseInt(dr_book.dr_id) ))
        {
            return <CmFramedShit >
                        <div style={{padding: '16px 6px'}}>
                            <div style={{fontSize: '16px'}}>БРОНЬ</div>
                        </div>
                    </CmFramedShit >;
        }
        */
        

        
        const trp_is_closed = (this.props.cur_trp_status === DEFS.TRP_STATUS_CLOSED);   // !
        
        //console.log('REND PAY .. closed: ', trp_is_closed);
        
        const p_itms = this.GenItems(this.props.cur_trp_id, trp_is_closed);


        
        return <div >

                    {p_itms}

                                
                </div>;
    }
}

