 
import React from 'react';


import { DaOutlinedShit } from './outlined.js';
import { DaIconBtn } from './icon_btn.js';

import * as DT from '../dt.js';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ClearIcon from '@mui/icons-material/Clear';

import { DateView } from './date_view.js';

import { Win2 } from './win2.js';


//-----------------------


function __redu(sta, act)
{
	switch(act.type)
	{
		case 'val':
		{
			const r = { ...sta, [act.k]: act.v };
            return r;
		}
        
        case 'foc':
        {
            const r = { ...sta };
            
            r.is_edit = true;
            
            if (!act.value)
            {
                //console.log('val is null.. empty texT !');
                r.tmp_txt = '';
            }
            else
            {
                //console.log('conv to date..');
                r.tmp_txt = DT.DateStrLoc( act.value );
            }
            
            return r;
        }
        
        
        case 'lost':
        {
            const r = { ...sta };
            
            r.is_edit = false;
            
            return r;
        }

	}
	
	return sta;
}


const gSta1 = {
  
    is_edit:  false,
    is_pop:   false,
    tmp_txt:  '',
    
};


// очко с выбором даты
// value - Date
export function DaDateField( {name, label, nonull, error, value, onChange, keepHMS} )
{
    const [sta, dispatch] = React.useReducer( __redu, gSta1 );
    
    // inp ref
    let myRef = React.createRef();
    
    
    function cb_foc()
    {
        //console.log('ED !');
        
        dispatch( { type: 'foc',  value: value } );
        
    }
    
    function cb_blur()
    {
        //console.log('lost');
        
        let nu_dt = DT.DateParseLoc( sta.tmp_txt );

        dispatch( { type: 'lost' } );
        
        if (nu_dt)
        {
            //console.log('GOT JUICE ', nu_dt);
            if (!DT.DateIsSame(nu_dt, value))
            {
                if (keepHMS)
                {
                    nu_dt = DT.DateTransferHMS(nu_dt, value);
                }
                
                onChange( name, nu_dt );
            }
        }
        else
        {
            //console.log('failed !');
            
            if (nonull)
            {
                let t = new Date();
                
                if (keepHMS)
                {
                    t = DT.DateTransferHMS(t, value);
                }
                onChange( name, t);
            }
            else
            {   
            
                onChange( name, null );
            }
        }
        
    }
    
    function cb_kd(e)
    {
		if (sta.is_edit === false) return;
		if (e.code === 'Enter')
		{
			//console.log('BLUR ON ESC !');
			myRef.current.blur();
		}
    }
    
    function cb_chg_inp(e)
    {
        const v = e.target.value;
        
        if (sta.is_edit)
        {
            dispatch( { type: 'val', k: 'tmp_txt', v: v } );
        }
    }
    
    function cb_pop()
    {
        //console.log('POP !');
        
        dispatch( { type: 'val', k: 'is_pop', v: true } );
    }
    
    function cb_clear()
    {
        //console.log('CLEAR !');
        
        onChange( name, null );
    }
    
    function cb_sel_pop(dt)
    {
        dispatch( { type: 'val', k: 'is_pop', v: false } );
        
        if (keepHMS)
        {
            //console.log('keep hms !');
            dt = DT.DateTransferHMS(dt, value);
        }
        
        onChange( name, dt );
    }
    
    function cb_clo()
    {
        dispatch( { type: 'val', k: 'is_pop', v: false } );
    }
    
    let inp_val;
    
    if (sta.is_edit)
    {
        inp_val = sta.tmp_txt;
    }
    else
    {
        inp_val = value ? DT.DateStrLoc(value) : '';    // 'ДД.ММ.ГГГГ';
    }
    
    
    
    const p_juice = (<div style={{position: 'relative', display: 'flex', justifyContent: 'space-between', padding: '4px', alignItems: 'center', height: '100%'}}>
                                <input ref={myRef} onKeyDown={ cb_kd } onChange={cb_chg_inp} onBlur={cb_blur} onFocus={cb_foc} className={sta.is_edit ? "oi_input_shit act" : "oi_input_shit"}  placeholder='ДД.ММ.ГГГГ' style={{paddingLeft: '4px' }} type='text' value={inp_val}  />
                                <div style={{display: 'flex'}}>
                                    { nonull ? null : <DaIconBtn disabled={sta.is_edit} icon={ClearIcon} onClick={cb_clear}   />  }
                                    <DaIconBtn disabled={sta.is_edit} icon={CalendarMonthIcon} onClick={cb_pop}   />
                                </div>
                            </div>);
    
    return (
        <>
        <div style={{height: '36px', width: '100%'}}>
            <DaOutlinedShit error={error} labelIsFloat={true} labelVal={label} borderIsFoc={sta.is_edit} juice={p_juice} />
        </div>
        
        <MyPopCalen visible={sta.is_pop} onSel={cb_sel_pop} cur={value} onClose={cb_clo} />
        
        </>
    );
}



export function MyPopCalen( {visible, title, onSel, cur, onClose} )
{

    return (
      <Win2 title='Выбор даты'  visible={visible} onClose={onClose} width={480} height={540} >
            <div style={{padding: '6px'}}>
                <DateView initDt={cur ? cur : new Date()} onSel={onSel} cur={cur} />
            </div>
      </Win2>
    );
}


//------------------------------------------------------------


