import React from 'react';


import "./fader.css"
 


function DaFaderTopImp( {dark} )
{
    return (<div className={"fader_top" + (dark ? ' dark' : '')} ></div>);
}


function DaFaderBottomImp( {dark} )
{
    return (<div className={"fader_bot" + (dark ? ' dark' : '')} ></div>);
}


export const DaFaderTop        = React.memo( DaFaderTopImp );
export const DaFaderBottom     = React.memo( DaFaderBottomImp );
