import React from 'react';

import { LayVert2, LayHoriz2, LaySpace } from '../misc/layout.js';
import { Grid3 } from '../grid3.js';
import { ShowMsg,  ShowErr } from '../misc_glob.js';
import { DaSearchBar } from '../misc/search_bar.js';
import CheckIcon from '@mui/icons-material/Check';
import { CmHelpIcon } from '../misc/help_icon.js';
import { NuPost2 } from '../api.js';


function MyCellCheckImp(pp)
{
    if (pp.value < 1) return '';
    return <CheckIcon style={{color: '#306030'}}/>;
}

const MyCellCheck = React.memo( MyCellCheckImp );

//---------------------------


    
const gNuCols = [

    {fld: 'urad_id',        name: 'ID',                 align: 'center',    sort: true, width: 90},
    {fld: 'urad_descr',     name: 'Описание',           align: 'left',      sort: false, width: 290},
    {fld: 'r0',             name: 'Super',              align: 'center',      sort: false, width: 120 , renderCell: MyCellCheck},
    {fld: 'r5',             name: 'Управ',              align: 'center',      sort: false, width: 120 , renderCell: MyCellCheck},
    {fld: 'r3',             name: 'Админ',              align: 'center',      sort: false, width: 120 , renderCell: MyCellCheck},
    {fld: 'r1',             name: 'Тренер',             align: 'center',      sort: false, width: 120 , renderCell: MyCellCheck},

    ];





// РАЗДЕЛ - возможности юзеров в CRM
export function TabUserRoleAbility(pp)
{

    const [sea, setSea] = React.useState('');
    const [ser, setSer] = React.useState(0);
    
    const chg_flt = React.useCallback( (k,v) =>
    {
        setSea(v);
    }, [] );
    
    
    function cb_dbl(kk, this_row, cc)
    {
        if (!cc) return;

        const fld = cc.fld;

        //console.log('CELL', fld, ' ---  ID ', kk);

        const role = parseInt(fld.substring(1));  // TODO: dirty

        if (isNaN(role)) return;

        const abil = parseInt(kk);

        const nu_val = 2;   // toggle

        console.log(' ROLE: ', role);
        

        function __ok(e)
        {
            ShowMsg('УСПЕХ !');
            
            setSer(ser+1);
        }
        
        function __fail(e)
        {
            ShowErr(e.m);
        }
        
        NuPost2( 1022, { role: role, abil: abil, val: nu_val }, __ok, __fail );
    }
    
    
    const flt = React.useMemo( () =>
    {
        return { search: sea };
    }
    , [sea, ser] );
    
    
    
    return (
            <LayVert2 rows='max-content 1fr' padding='20px' gap='20px'>

                <LayHoriz2 cols='320px 1fr max-content'>
                    <DaSearchBar name='srch' value={sea} onChange={chg_flt} label='поиск по описанию...'  />
                    <LaySpace />
                    <CmHelpIcon juice="Тут можно менять доступы к действиям/разделам для разных ролей. Двойной клик на ячейке - поменять." />

                </LayHoriz2>
                
                
                <Grid3 cols={gNuCols}  isNuApi apiCmd={1021} uniq='urad_id'  onDblClick={cb_dbl}
                        sort_field='urad_id' sort_dir='asc' flt={ flt } code='role_abil' />
        
            
            </LayVert2>

    
    );
}

















