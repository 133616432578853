import React from 'react';
import * as DEFS from '../../defs.js';
import * as HLP from '../../helpers.js';

import { NuPost2 } from '../../api.js';

import { DaCombo } from '../../misc/combo.js';


//---------------------------------------------


const gNonSel = { prgc_id: -1, prgc_name: 'ВСЕ'  };


// dynamic
export function ComboCats( {name, value, onChange, disabled} )
{
	//console.log('TrainersList  all: ', all_cust_ids);
	
	const [busy, setBusy]   = React.useState( false );
	const [lst, setLst]     = React.useState( [ gNonSel ] );
	
	function cb_chg(k, v)
	{
		onChange(name,v);
	}
	
	React.useEffect( () => {
		
		const on_fail = (e) =>
		{
			setBusy(false);
		};
		
		const on_ok = (e) =>
		{
			setBusy(false);
			setLst( [ gNonSel, ...e.rows ] );
		};
		
		setBusy(true);
		
		NuPost2( 951, {}, on_ok, on_fail );
		
	}, [] );
	

	return (	<DaCombo  label='КАТЕГОРИЯ' onChange={cb_chg} src={lst}    fld_k='prgc_id' fld_v='prgc_name'  value={value}  /> );
}

