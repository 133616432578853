import * as React from 'react';



import * as DEFS from '../defs.js';
import * as HLP from '../helpers.js';

import { DaList2 } from './list2.js';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

//------------------

const gSx1 = {color: '#553CC3'};

function MyItemImp( {kk, vv, row, isCur, ext} )
{
    const ar_csv = HLP.CsvSplit( ext );
    
    const is_check = ar_csv.indexOf( kk ) !== -1;
    
    return (<div className='yy_list_itj_default' >
                {is_check ? <CheckBoxIcon sx={gSx1} />: <CheckBoxOutlineBlankIcon sx={gSx1} /> }{vv}
            </div>);
}


const MyItem = React.memo( MyItemImp );

//-------------------------------------------------------

export function DaListCsv( {name, src, fld_k, fld_v, value_csv, onChange} )
{
    const cb_chg = React.useCallback( (kk,v) =>
    {
        v = parseInt(v);
        const nu_csv    = HLP.CsvToggleID( value_csv, v );
        if (onChange) onChange( name, nu_csv );
    }, [value_csv] );
    
    return (
        <DaList2 src={src} ext={value_csv} itemCmp={MyItem} fld_k={fld_k} fld_v={fld_v} value={null} onChange={cb_chg} /> 
        );

}
