 
import * as DEFS from './defs.js';
import * as HLP from './helpers.js';


import { CmCachedUserLink }   from './desktop/ca_user_link.js';

import * as DT from './dt.js';


export function CmComColCellBalanceAct(pp)
{
    return DEFS.gBalanceActDisp[ pp.value ];
}



//---------------------------------------

export function CmComFaded( pp )
{
    return ( <span className="tx_faded">{pp.value}</span> );
}


export function CmComColCellDateTime(pp)
{
    if (pp.value === null) return <div className="faint">---</div>;
    return <div>{ DT.DateTimeStrLoc( DT.DateTimeParseIso( pp.value ) ) }</div>;
}


export function CmComColCellDateTimeHM(pp)
{
    if (pp.value === null) return <div className="faint">---</div>;
    return <div>{ DT.DateTimeStrLocHum( DT.DateTimeParseIso( pp.value ) ) }</div>;
}

export function CmComColCellDateTimeHoursMinutes(pp)
{
    if (pp.value === null) return <div className="faint">---</div>;
    return <div>{ DT.DateTimeStrLocHoursMinutes( DT.DateTimeParseIso( pp.value ) ) }</div>;
}





export function CmComColCellDate(pp)
{
    if (pp.value === null) return <div className="faint">---</div>;
    return <div>{ DT.DateStrLoc( DT.DateParseIso(pp.value) )  }</div>;
}


export function CmComColCellDateFromDateTime(pp)
{
    if (pp.value === null) return <div className="faint">---</div>;
    return <div>{ DT.DateStrLoc( DT.DateTimeParseIso(pp.value) )  }</div>;
}







export function CmComColUser(p)
{
    if (p.value === null) return <span className="faint">---</span>;
    return <CmCachedUserLink rid={p.value} />;
}





const gPayStatusText = [
'Оплачен',
'Активен',
'Закрыт',
];

const gPayStatusClr = [
'#A09000',
'#20A020',
'#809090',
];

export function CmComColPayStatus(pp)
{
    return <div style={{color: gPayStatusClr[pp.value]}}>{gPayStatusText[pp.value]}</div>;
}


export function CmComColTrpStatus(pp)
{
    const t = pp.value;

    if (t === 99) return <b className="red_shit">НЕ ПРИШЁЛ</b>;
    if (t === 98) return <b className="faint">---</b>;
    
    return <div style={{fontWeight: 500, fontSize: '13px', color: DEFS.gTrpStatusClr[t]}}>{DEFS.gTrpStatusDisp[t]}</div>
}


//------------------------


export function CmComGoodName(pp)
{
	let ccc = pp.row.prg_name;
	if (ccc === null) return <b className='red_shit'>ТОВАР ({pp.value}) УДАЛЁН</b>;
	return ccc;
}

//----------------



export function CmComBonusAct(pp)
{
    const v = pp.value;
    return DEFS.gBonusActVis[ v ];
}

export function CmComBonusAmount(pp)
{
    const v = pp.value;


    const is_diff = pp.row.cbh2_amount_init !== v;

    let r;

    if (v > 0)
    {
        r = <span style={{fontWeight: 800, color: '#407030'}}>+{v}</span>
    }
    else if (v < 0)
    {
        r = <span style={{fontWeight: 800, color: '#704040'}}>{v}</span>
    }
    else
    {
        r = v;
    }

    if (!is_diff) return r;
    return <><span style={{fontWeight: 800, color: '#FF1010', textDecorationThickness: '1px', textDecoration: 'line-through', paddingRight: '10px'}}>{pp.row.cbh2_amount_init}</span>{r}</>;
}

