import React from 'react';

import { NuPost } from '../api.js';

import { CmSpinner } from '../cm_base.js';

import * as DEFS from '../defs.js';

import * as DT from '../dt.js';




export function MonthlyEventList( {pr_id, dt_s, dt_e} )
{
	const [lst, setLst] = React.useState( null );
	
	React.useEffect( () => {
		
		console.log('REQ FOR ', dt_s, dt_e);

		function __ok(e)
		{
			setLst( e.rows );
		}
		
		function __fail(e)
		{
		}
		
		const ss = DT.DateTimeStrIso(dt_s);
		const ee = DT.DateTimeStrIso(dt_e);

		NuPost( 996, {pr_id:pr_id,dt_s:ss,dt_e:ee}, __ok, __fail);
		
	}, [pr_id, dt_s, dt_e] );
	
	
	let p_juice;
	
	if (lst === null)
	{
		p_juice = <CmSpinner sz={16} />;
	}
	else
	{
		let ar = [];
		
		for (let x = 0; x < lst.length; x ++ )
		{
			const e = lst[x];
			
			const da = DT.DateTimeStrLoc( DT.DateTimeParseIso(e.ts_time_start) );
			
			//const sta = (<span>{DEFS.gSchStatusDisp[e.ts_status] }</span>);
			
			ar.push( <div style={{color: '#7060E0', padding: '6px', margin: '6px'}} key={x}>• {da}</div> );
		}
		
		p_juice = <><div>Открытые события в календаре ({lst.length}):</div>{ar}</>
	}
	
	
	
	return (<div>
			
			
		{p_juice}
	
	</div>);
}

