import React from 'react';

import { DaOutlinedShit } from './outlined.js';

//-----------------------------


function DaColorPickerImp( {name, value, label, onChange} )
{
    //console.log('DaColorPicker -> ', value);
    
	const cb_chg = (e) =>
	{
		onChange( name, e.target.value );
	};
    
    const p_juice = <div style={{paddingLeft: '4px', paddingRight: '4px', height: '100%', display: 'flex', alignItems: 'center'}}>
                        <input onChange={cb_chg} type='color' value={value} style={{width: '100%', height: '24px', border: 'none', outline: 'none' }} />
                    </div>;
    
	return ( <div style={{height: '36px'}}><DaOutlinedShit labelIsFloat={true} labelVal={label} juice={p_juice} /></div> );
}

export const DaColorPicker = React.memo( DaColorPickerImp );

