import React from 'react';

import { NuPost } from '../api.js';

import { DaListCsv } from '../misc/list_csv.js';

import { OverlayLoading } from '../misc/ovr_loading.js';
import { DaButton } from '../misc/but.js';

import { ShowErr, ShowMsg  } from '../misc_glob.js';

import { LayVert2, LayHoriz2 } from '../misc/layout.js';

//-------------------------------------


// 

// список - выбор клубов (галочки)
export function ClubCsvList( {name, valCsv, onChange} )
{
    const [lst, setLst] = React.useState( null );
    
    
    React.useEffect( () => {
        
        //console.log('CLUBS LIST START !');
        
        function __ok(e)
        {
            //console.log('GOT LIST !');
            setLst(e.rows);
        }
        
        function __fail(e)
        {
            ShowErr(e.m);
        }
        
        NuPost( 3001, {}, __ok, __fail );
        
    }, [] );
    
    
    const cb_on_chg = React.useCallback( (k,v) => {
        onChange( name, v );
    }, [valCsv, name] );
    
    
    if (lst === null)
    {
        return <div style={{position: 'relative', width: '100%', height: '100%'}}>
                    <OverlayLoading active={true} />
                </div>;
    }

    return (
                <DaListCsv src={lst} fld_k='c_id' fld_v='c_vis_name'  value_csv={valCsv} onChange={cb_on_chg} />
        );
}
