import React from 'react';
import ReactDOM from 'react-dom/client';



import { Root3 } from './v3/root3';

import { GlobalShitProvider } from './v3/hk_global_shit';

import { UserOptsProvider } from './hk_user_opts.js';

import { CuCaMgrProvider } from './menu2/hk_cuca_mgr_state'; 

import "./fonts.css";
import './zz.css';




const ee = document.getElementById('r_root');
const rr = ReactDOM.createRoot(ee);


//  <Root />

rr.render( <>
				<GlobalShitProvider>

                        <UserOptsProvider>
							<CuCaMgrProvider>
				
                           <Root3 />


						   </CuCaMgrProvider>
                        </UserOptsProvider>

				</GlobalShitProvider>
                </>  );
                    
    

/*


ReactDOM.render(     <React.Fragment>
           

							<ThemeProvider theme={gThemeForMUI}> 

								<Provider store={gDaStore}>
								
									<QueryClientProvider client={gQueryClient}>
	
									<FlRoot />
									
									<CustCardContainer />
								
									</QueryClientProvider>
								
									<CmNotify ref={gRefSnk} />
								
								</Provider>
						
							</ThemeProvider>
                           
                            
                      </React.Fragment>
                    
                    , document.getElementById('r_root') );


*/

