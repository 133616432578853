import React from 'react';


import { useApiQuery2 } from '../api_stuff.js';
import { CmSpinner } from '../cm_base.js';

import { NuPost, NuPost2 } from '../api.js';
import { ShowMsg, ShowErr } from '../misc_glob.js';

import { usePicEditorTriggetState } from '../desktop/pic_edit_trig.js';

import { ShortName } from '../desktop/short_name';

import { DaIconBtn } from '../misc/icon_btn.js';

import {LayVert2, LaySpace, LayHoriz2} from '../misc/layout.js';

import { GetBackendBaseURL } from '../api.js';

import { CmUserMessages } from '../misc/user_messages.js';


import Rating from '@mui/material/Rating';

import * as HLP from '../helpers.js';
import * as DEFS from '../defs.js';
import * as COM_DEFS from '../com_defs.mjs';

import * as DT from '../dt.js';

import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import EditIcon from '@mui/icons-material/Edit';

import { Win2PopTextLineEdit } from '../misc/win2_pop_line_edit.js';

import { STO_InvaCustomerCardOverview, STO_InvaCustLink, STO_InvaPic } from '../store.js';

import { PicEditorTrigger } from '../desktop/pic_edit_trig.js';
import { DaVertScrollFrame, DaVertScrollJuice } from '../misc/vscr.js';
import { CmCachedUserLink } from '../desktop/ca_user_link.js';

import { UnkPic, CmRawPic3 } from '../desktop/vi_user_edit_pic.js';

import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import { OverlayLoading } from '../misc/ovr_loading.js';

//-------------------------


function MyStaticFieldImp( {tit, val} )
{
	return <div className="yy_static_field_pv">
				<div style={{textAlign: 'right', color: '#404040'}}>{tit}</div>
				<div style={{textAlign: 'left',  color: 'var(--fl-clr-primary)', fontWeight: 500, overflow: 'hidden', whiteSpace: 'nowrap',  textOverflow: 'ellipsis'}}>{val}</div>
			</div>
}

export const MyStaticField = React.memo( MyStaticFieldImp );




//--------------------------------------------


function CmCustPicImp(pp)
{
	const RID = pp.rid;
	
	const { isFetching, isLoading, isError, data, error } = useApiQuery2( 4123, {rid: RID, ty: 2} );
	
	if (isFetching || !data) return ( <CmSpinner sx='32px' />  );
	
	if (isError) return ( <div className="yy_error">{error}</div> );
	
	if (data.path === null) return <UnkPic />;
	
	
	const url = GetBackendBaseURL() + data.path + '?' + HLP.Now();		// TODO think !

	
	return <CmRawPic3 src={ url } sty={{outline: '1px solid #FFFFFF60', outlineOffset: '-1px', borderRadius: '10px', }} />;
}

const CmCustPic = React.memo( CmCustPicImp );


//-------------------------------------------


//------------------------------

function MyOrig(pp)
{
	const { isFetching, isLoading, isError, data, error } = useApiQuery2( 976, {} );
	
	if (isError) return <div className="yy_error">{error}</div>;
	
	if (isLoading || isFetching) return null;

	let vv = pp.value;
	vv = parseInt(vv);
	
	let f = HLP.ArrayOfDictsFindByPropValue(data.rows, 'lso_id', vv);
	if (f === null) return <div className="yy_error">УДАЛЁН {vv}</div>;
	
	return f.lso_val;
}


function MyFieldHowFar(pp)
{
	const R = pp.row_val;
	const E = pp.ex_val;
	
	let s;
	
	
	if (E !== '')
	{
		s = E;
	}
	else
	{
		if (R > 0) s = 'Рядом';
		else s = '???';
	}

	return <div style={{fontSize: '14px'}}>{s}</div>;
}





function MyStatRows(pp)
{
	const { isFetching, isLoading, isError, data, error } = useApiQuery2( 978, {} );
	
	if (isFetching || !data) return null;
	
	if (isError) return ( <div className="yy_error">{error}</div> );

	const sta 		= pp.sta;

	function getValStaWrap(k)
	{
		let v = sta[k];
		if (v === undefined) v = 0;
		return v;
	}


    let ar_stat_fields = [];
    
    for (let x = 0; x < data.rows.length; x ++ )
    {
		let e = data.rows[x];
		const ID = e.st_id;
		const t = <Rating size='small' sx={{color: '#8070D0'}} value={getValStaWrap(ID)} max={DEFS.FL_CUST_MAX_POINTS} readOnly={true} />;
		ar_stat_fields.push( <MyStaticField key={''+ID} tit={e.st_name}  tit_clr='#505050'	val={t} />  );
	}
	
	return ( <>{ar_stat_fields}</>);
}


//--------------------------------



const gLstK 	= [ COM_DEFS.CU_EX_FIELD_DRPR_FUNC, COM_DEFS.CU_EX_FIELD_DRPR_BOX, COM_DEFS.CU_EX_FIELD_DRPR_KICKBOX, COM_DEFS.CU_EX_FIELD_DRPR_MMA, COM_DEFS.CU_EX_FIELD_DRPR_THAI ];
const gLstDsp 	= ['Функционал', 'Бокс', 'Кикбоксинг', 'ММА', 'Тайский'];

function MyPrefsImp(pp)
{
	const ex = pp.ex;
	
	let ar = [];
	
	for (let x = 0; x < gLstK.length; x ++ )
	{
		let k = gLstK[x];
		let v = ex[k];
		if (v === undefined || v === '') continue;
		v = parseInt(v);
		if (v !== 1) continue;
		ar.push( <div key={k} className="cust_pref_item" >{gLstDsp[x]}</div> );
		
	}
	
	if (ar.length < 1) return <LaySpace />;
	
	return 	<div style={{fontSize: '14px', paddingRight: '16px', userSelect: 'none', display: 'flex', gap: '6px', justifyContent: 'flex-end', alignItems: 'center'}}>
				<div>ПРЕДПОЧТЕНИЯ:</div>
				{ar}
			</div>;
}


const MyPrefs = React.memo( MyPrefsImp );

//-----------------------------

function CmImpoComment( {txt} )
{
	if (txt === '') return <div style={{width: '100%', height: '1px'}}></div>;

	return (
		
		<LayHoriz2 cols='max-content 1fr' sx={{alignItems: 'center', background: '#FFF3F3', padding: '4px'}}>
			<PriorityHighIcon sx={{color: '#A03030'}}/>
			<div style={{width: '100%', fontWeight: 800, fontSize: '16px', color: '#A03030'}}>{txt}</div>
		</LayHoriz2>
	);
}

//-------------------------------------------------


// КАРТОЧКА КЛИЕНТА / ПЕРВАЯ СТРАНИЦА
export function ViCustomerCardOverview( {rid, isMaster} )
{
	const RID = rid;

	const ctl = usePicEditorTriggetState();
	
	const [valPop, setValPop] = React.useState( 0 );

	const [valPopDct, setPopDct] = React.useState( {} );
	
	const { isFetching, isLoading, isError, data, error } = useApiQuery2( 981, {rid: RID}, {staleTime: 1} );
	
	if (isFetching || !data) return ( <CmSpinner sx='32px' />  );
	
	if (isError) return ( <div className="yy_error">{error}</div> );
	
	const rrr = data.row;
	const eee = data.ex;
	const mmm = data.more;
	const sta = data.sta;
	
	const s_age = DT.YearsDiff( DT.DateParseIso(rrr.cu_dob), new Date() ); 
	
	let s_medi   = eee[COM_DEFS.CU_EX_FIELD_MED_RESTR] || '';
	const s_desi = eee[COM_DEFS.CU_EX_FIELD_DESIRE] || '';
	const s_expi = eee[COM_DEFS.CU_EX_FIELD_SPORT_BG] || '';
	const s_comm = eee[COM_DEFS.CU_EX_FIELD_COMMENT] || '';
	const s_far  = eee[COM_DEFS.CU_EX_FIELD_HOW_FAR] || '';
	
	if (s_medi === DEFS.CUST_FIELD_VAL_NONE) s_medi = '';
	
	const s_reg_ago = DT.DaysDiff( DT.DateTimeParseIso(rrr.cu_reg_date), new Date() );
	




    const p_reg_date = ( <div>Рег: { HLP.DateIsoToOur( rrr.cu_reg_date ) } ({s_reg_ago} дней назад)</div> );
                        
    const p_numbers = mmm ? ( ( <div>Трень: {mmm.v_num_trains} / Абонементов: {mmm.v_bought_pays}</div> ) )  :  null;
    
	const p_first_club = <div>Первый клуб: <ShortName ctx={DEFS.SHORT_NAME_CLUB} id={rrr.cu_init_club_id} /></div>;

	const p_contract = (rrr.cu_contract_dt === null) ? 
							( <div style={{color: '#FF3030'}}>договор не подписан</div> ) 
							: ( <div>договор подписан: {DT.DateStrLoc( DT.DateTimeParseIso( rrr.cu_contract_dt ) )}</div> );

    

	function cb_changed_pic()
	{
		STO_InvaCustomerCardOverview(RID);

		STO_InvaPic( COM_DEFS.PIC_TYPE_CUSTOMER_SQ, RID );
		STO_InvaPic( COM_DEFS.PIC_TYPE_CUSTOMER_MINI, RID );
	}
    
    function cb_close_pop()
    {
		setValPop( 0 );
	}
    
    function cb_edit_comm()
    {

		const cb_submit = (v) => {
			
			const __ok = (ee) => {
				STO_InvaCustomerCardOverview(RID);
				STO_InvaCustLink(RID);
				setValPop( 0 );
			};
	
			const __fail = (ee) => {
				
				ShowErr(ee.m);
			};
			
			NuPost( 754, {cu_id: RID, f: COM_DEFS.CU_EX_FIELD_COMMENT, v: v}, __ok, __fail );
		};


		const DDD = { title:'КОММЕНТ', initial: s_comm, allowEmpty: true, onOk: cb_submit, onClose: cb_close_pop };

		setValPop( 1 );
		setPopDct( DDD );
	}
    

	return (<>	<div style={{width: '100%', height: '100%', display: 'grid', gap: '2px', gridTemplateRows: '1fr', gridTemplateColumns: 'minmax(250px, 300px) minmax(520px, 1fr) minmax(200px, 380px)'  }}>
	
					<div >
						<div style={{position: 'relative', display: 'flex', justifyContent: 'center', marginTop: '10px', marginLeft: 'auto', marginRight: 'auto', width: '210px', height: '210px'}}>
							<CmCustPic rid={RID} />
							
							<div style={{position: 'absolute', right: '6px', bottom: '6px'}}>
								<PicEditorTrigger ctl={ctl} rid={RID} ctx={COM_DEFS.PIC_EDIT_CTX_CUST} onOk={cb_changed_pic} />
							</div>

							<OverlayLoading active={ctl.sta.busy} />
						</div>
						
						<div style={{marginTop: '16px'}}>
							<MyStaticField tit='РОСТ:' 		val={rrr.cu_height + ' см'} />
							<MyStaticField tit='ВЕС:' 		val={rrr.cu_weight + ' кг'} />
							<MyStaticField tit='ВОЗРАСТ:' 	val={s_age} />
							<MyStaticField tit='ТЕЛЕФОН:' 	val={rrr.cu_phone} />
							<MyStaticField tit='ИСТОЧНИК:' 	val={<MyOrig value={rrr.cu_origin} /> } />
							<MyStaticField tit='РУКА:' 		val={DEFS.gHandDisp[rrr.cu_hand_pref]} />
							<MyStaticField tit='ЖИВЁТ:'		val={<MyFieldHowFar row_val={rrr.cu_f_walk_reach} ex_val={s_far} />} />
							<MyStaticField tit='TG-BOT:' 	val={rrr.v_has_tg_bot ? 'ДА' : 'НЕТ'} />
							<div style={{height: '18px'}}></div>
							<MyStatRows sta={sta} rid={RID} />
						</div>
						
					</div>
					
					<LayVert2 rows='max-content max-content max-content max-content 1fr' >

						<LayHoriz2 cols='1fr max-content' gap='10px' sx={{alignItems : 'center'}}>
							<div style={{fontFamily: 'body', height: '42px', color: 'var(--fl-clr-primary)', fontWeight: 600, fontSize: '19px', padding: '6px' }}>
								{rrr.cu_name}
							</div>

							<div>
								<DaIconBtn onClick={ cb_edit_comm } clr='#A05050' icon={EditIcon} help='Написать важный комментарий. Будет отображаться рядом с именем в виде красного восклицательного знака.' />
							</div>

						</LayHoriz2>

						<CmImpoComment txt={s_comm} />

						<LayHoriz2 cols='1fr 1fr' padding='6px' >
							<div style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace:'nowrap', fontSize: '13px', display: 'flex', flexDirection: 'column', gap: '4px' }}>
								{ p_numbers }
								{ p_reg_date }
								{ p_first_club }
							</div>

							<div style={{alignItems: 'flex-end', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace:'nowrap', fontSize: '13px', display: 'flex', flexDirection: 'column', gap: '4px' }}>
								<CustClosedFreeTrainInfo cu_id={RID} />
								{ p_contract }
							</div>

						</LayHoriz2>
						
						
						
						
						

						<MyPrefs ex={eee} />
						
						<DaVertScrollFrame faders >
							<DaVertScrollJuice padding='10px'>
								<BigInfo tit='МЕД ОГРАНИЧЕНИЯ' val={s_medi} />

								<BigInfo tit='ОПЫТ В СПОРТЕ' val={s_expi} />

								<BigInfo tit='ЦЕЛИ ТРЕНИРОВОК' val={s_desi} />
								</DaVertScrollJuice>
						</DaVertScrollFrame>
						
					</LayVert2>
					

						<div style={{position: 'relative', height: '100%', width: '100%', background: '#FFFFFF', borderLeft: '1px solid #00000020'}} >
							<CmUserMessages club_id={0} ctx_t={8} ctx_id={RID}  title='ЗАМЕТКИ ПО КЛИЕНТУ' />
						</div>
				

	
				</div>
	
			<Win2PopTextLineEdit visible={valPop === 1} {...valPopDct} />
	
			</>
	);
}


function TempDisabled({text})
{
	return (<div className="wh100perc" style={{color: '#704040', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
		<div><DoDisturbIcon sx={{fontSize: '30px', color: '#704040'}}/></div>
		<div>{text}</div>
	</div>);
}



function BigInfo( {tit, val} )
{
	//console.log('BIG INFO ', tit, ' -- ', val);

	if (val === null || val === '') return null;

	return (

		<div style={{padding: '10px'}}>
			<div style={{padding: '3px', fontSize: '16px', fontWeight: 500, color: 'var(--fl-clr-primary)'}}>{tit}</div>
			<div style={{whiteSpace: 'pre-wrap', wordWrap: 'break-word', paddingLeft: '10px', fontSize: '16px', color: '#404050'}}>{val}</div>
		</div>



	);
}


//----------------------------


function CustClosedFreeTrainInfo( {cu_id} )
{
	const [info, setInfo] = React.useState( null );

	React.useEffect( () => {

		function __ok(e)
		{
			setInfo(e.row);
		}

		function __fail(e)
		{
			ShowErr(e.m);
		}

		NuPost2( 1080, {cu_id:cu_id}, __ok, __fail );

	}, [] );



	if (info === null) return null;


	return (

		<div style={{alignItems: 'center', display: 'flex', gap: '10px'}}>пробник провёл: <CmCachedUserLink rid={info.ts_user_id} /></div>

	);
}
