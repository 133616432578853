 
import React from 'react';


import { Win2 } from '../misc/win2.js';
import { LayVert2, LaySpace } from '../misc/layout.js';
import { DaButton } from '../misc/but.js';

import { DaCombo }      from '../misc/combo.js';
import { DaTextField }  from '../misc/text_field.js';

import { ShowErr, ShowMsg, CommonReducer } from '../misc_glob.js';
 
import { BottomButtonsPanel } from '../cust_card/bottom_pan.js';

import CheckIcon from '@mui/icons-material/Check';



//-----------------------------------------

const gGuiltySrc = [

        {   k: -1,  v: '???' },
        {   k:  0,  v: 'НЕ ВИНОВАТ' },
        {   k:  1,  v: 'ВИНОВАТ' },

];

const gSta = {
  
    com:        '',
    guilty:     -1,
    
};

//-------------------------------------

// ОКНО с полями для НЕПРИШЁЛОВ (окно события)

function MyBody( {onOk} )
{
    const [sta, dispatch] = React.useReducer( CommonReducer, gSta );
    

    const cb_chg = React.useCallback( (k,v) => {
        dispatch( {type: 'val', k, v} );
    }, [] );
    

    function cb_submit()
    {
        onOk( sta.com, sta.guilty );
    }
    
    const ERR_COM = sta.com.trim() === '';
    const ERR_GUI = sta.guilty < 0;
    const DIS_SUB = ERR_COM || ERR_GUI;
    
    return (
    
        <LayVert2 rows='1fr max-content' style={{position: 'relative'}}>
        
            <LayVert2 rows='max-content max-content 1fr' gap='16px' padding='16px' >
                <DaTextField error={ERR_COM} name='com' value={sta.com} label='ЧТО СЛУЧИЛОСЬ ?' onChange={cb_chg}  />
                <DaCombo error={ERR_GUI} name='guilty' value={sta.guilty} src={gGuiltySrc} label='КЛИЕНТ ВИНОВАТ ?' onChange={cb_chg}  />
                <LaySpace />
            </LayVert2>
            
            <BottomButtonsPanel>
                <DaButton  onClick={cb_submit}  disabled={DIS_SUB} title='OK' icon={ CheckIcon } />
            </BottomButtonsPanel>
            
        </LayVert2>
    
    );
}


export function WinAbandon( {visible,  onOk, onClose} )
{
    return (
    
        <Win2 visible={visible} title='ОТМЕНА / НЕПРИШЁЛ' onClose={onClose} width={530} height={240}  >
            <MyBody onOk={onOk} />
        </Win2>
    
    );
}
