import React from 'react';

import * as DEFS from '../defs.js';
import * as HLP from '../helpers.js';

import { CommonReducer } from '../misc_glob.js';

import { OverlayLoading } from '../misc/ovr_loading.js';

import { NuPost2 } from '../api.js';

import { DaButton } from '../misc/but.js';
import { DaTextField } from '../misc/text_field.js';

//----------------------------------------------------



const gSta = {
  
    log: '',
    pwd: '',

    state: 'fill',
    
};


export function AuthScreen3( {onOk, onFail, msg} )
{
    console.log('auth screen... ', msg);

    const [sta, dispatch] = React.useReducer( CommonReducer, gSta );
    
    const cb_chg = React.useCallback( (k,v) => {
        dispatch( {type: 'val', k:k, v:v} );
    }, [] );
    
    function cb_sub()
    {
        function __ok(e)
        {
            console.log(e);
            //ShowMsg('OK !');
            dispatch( {type: 'val', k:'state',  v:'ok'} );
            
            onOk( e );
        }
        
        function __fail(e)
        {
            dispatch( {type: 'val', k:'state', v:'fill'} );
            
            onFail( e.m );
        }
        
        
        dispatch( {type: 'val', k:'state', v:'sent'} );
        
        
        
        NuPost2( 51, {login: sta.log, pass: sta.pwd}, __ok, __fail );
    }
    
    
    const DIS_SUBMIT = sta.log === '' || sta.pwd === '';
    
    const IS_BUSY = sta.state === 'sent';
    
    
    
    
    let p_msg = null;
    
    if (msg !== '') p_msg = (<div style={{fontFamily: 'body', fontSize: '14px', fontWeight: 700, borderRadius: '4px', padding: '10px', height: '40px', width: '100%', background: '#FF6060', color: '#FFFFFF'}}>{msg}</div>);
    else
    {
        p_msg = <div style={{height: '40px', width: '100%'}}></div>
    }
    
    let p_form = null;
    if (sta.state === 'fill' || sta.state === 'sent')
    {
        p_form = (<>    <DaTextField  name='log' error={false} label='ТЕЛЕФОН' value={sta.log}   onChange={cb_chg} />
                        <DaTextField  name='pwd' error={false} label='ПАРОЛЬ'  value={sta.pwd}   onChange={cb_chg} />
                
                        <DaButton  disabled={DIS_SUBMIT}  onClick={cb_sub} title='ВХОД' />
                    
                    </>
                );
    }
    

    
    return (
        <>
     
        

        <div className="au_bg" style={{padding: '30px', position: 'relative', display: 'flex', alignItems: 'flex-start', justifyContent: 'center', width: '100%', height: '100%'}}>

            <div className='zz_com_shadow' style={{position: 'relative', borderRadius: '4px', background: '#FFFFFF', padding: '40px', display: 'flex',  alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: '30px', width: '370px'}}>
            
                <div>
                    <img width="180px" height="180px" src="./img/fl2.svg" />
                </div>
                
                {p_msg}
            
                {p_form}
                
                <OverlayLoading active={IS_BUSY} />
                
            </div>
        
        </div>

        </>
    
    );
    
    
}


 
