import React from 'react';


import * as DEFS from '../defs.js';
import * as HLP from '../helpers.js';

import { NuPost } from '../api.js';

import { ShowMsg, ShowErr} from '../misc_glob.js';

import { DaSwitcherYear }  from '../misc/sw_year.js';

import { OverlayLoading } from '../misc/ovr_loading.js';

import { ClubListCombo } from '../desktop/club_list_combo.js';

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { CommonReducer } from '../misc_glob.js';

import { LayVert2, LaySpace, LayHoriz2 } from '../misc/layout.js';

import { StatsDateRangeSelector, MODE_MONTH } from '../misc/stats_date_range_sel.js';


function FmtTickYW(v)
{
    const q = v.split('.');
    
    return q[0].padStart(2, '0') + '.' + q[1].substring(2,4);
}


//-----------------------------------------

const gSS = {

    busy:   false, 
    data:   null,
    ys:     2023,
    ye:     2024,
    club:   -1,
};


export function TabStatOrigsByMonth()
{
    const [sta, dispatch] = React.useReducer( CommonReducer, gSS );

    function cb_chg(k,v)
    {
        dispatch( {type: 'val', k:k, v:v} );
    }

    
	React.useEffect( () => {
		
		//console.log('ORIGS MON..');
		
		function __ok(e)
		{
            dispatch( {type: 'val', k:'data', v:e.data} );
            dispatch( {type: 'val', k:'busy', v:false} );
		}
		
		function __fail(e)
		{
            dispatch( {type: 'val', k:'busy', v:false} );
			ShowMsg(e.m);
		}
        
        dispatch( {type: 'val', k:'busy', v:true} );
		
		NuPost( 995, {onlyClub:sta.club, ys:sta.ys, ye:sta.ye}, __ok, __fail );
		
	}, [sta.club, sta.ys, sta.ye] );
    
    
    let juice = <div></div>;
    
    if (sta.data)
    {
        const lines = [];
        for (let x = 0; x < sta.data.origs.length; x ++ )
        {
            const e = sta.data.origs[x];
            const KK = 'orig'+x;
            lines.push( <Line connectNulls  key={KK} name={e.lso_val} type="monotone" dataKey={KK} stroke={e.lso_clr}  strokeWidth={3}  /> );
        }
        
        juice = (
        
            <ResponsiveContainer width="100%" height='90%' >
                <LineChart data={sta.data.gra} margin={{left: 30, right: 30}} >
                
                    {lines}

                    <CartesianGrid stroke="#B0B0B0"  strokeDasharray="3 3"  />
                    <XAxis dataKey="t" tickFormatter={FmtTickYW} />
                    <YAxis type="number" scale="linear" />
                    <Tooltip />
                    <Legend layout='vertical' align='left' verticalAlign='top' />
                </LineChart>
            </ResponsiveContainer>
        
        );
    }
    
    return (
    
        <LayVert2 rows='max-content 1fr' padding='10px' >
        
				<LayHoriz2 cols='1fr 300px max-content max-content 1fr' padding='10px' gap='10px'>
                    <LaySpace />
                    <ClubListCombo name='club' label='ТОЛЬКО ПРИШЕДШИЕ В КЛУБ' value={sta.club} onChange={cb_chg}/>
                    <DaSwitcherYear name='ys' value={sta.ys} onChange={cb_chg} label='ОТ' />
                    <DaSwitcherYear name='ye' value={sta.ye} onChange={cb_chg} label='ДО'/>
                    <LaySpace />
                </LayHoriz2>

            {juice}
        
        </LayVert2>
    
    );
}


