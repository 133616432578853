import React from 'react';
import { CmComColCellDateTime } from '../cm_table_cell_com.js';

import { Grid3 } from '../grid3.js';

import { LayVert2} from '../misc/layout.js';

import * as DT from '../dt.js';

import { HowReturned } from '../desktop/how_payed.js';

//---------------------------------------

function MyColHow(pp)
{
    const ROW = pp.row;
    return <HowReturned retId={ROW.par_id}/>;
}


const gCols = [
  //{ filterable: false, sortable: true,  headerAlign: 'center', align: 'center', field: 'par_id', headerName: 'ID', width: 100},

  { sort: true,  align: 'center', fld: 'par_dt',      name: 'Дата', width: 140, renderCell: CmComColCellDateTime  },
  { sort: true,  align: 'center', fld: 'v_bla_bla',   name: 'Фин Оп', width: 140, renderCell: MyColHow},
  { sort: true,  align: 'center', fld: 'v_num_trps',  name: 'Трень списано', width: 170},
  { sort: true,  align: 'center', fld: 'par_pay_id',  name: 'ID-Абон', width: 140},
  { sort:false,  align: 'left',   fld: 'par_comment', name: 'Коммент', width: 350},

];



// карточка клиента - ВОЗВРАТЫ 

export function ViCustomerReturns( {rid} )
{
    const RID = {cu_id: rid};
    
    return (<LayVert2 rows='1fr' padding='20px' >

                <Grid3  isNuApi cols={gCols} apiCmd={1020}
                            uniq='par_id'  sort_field='par_id' sort_dir='desc' flt={ RID }  code='cc_rets' />
	
			</LayVert2>);
}
