 

export function IsCustInAban(ll, a_cust_id)
{
    for (let x = 0; x < ll.length; x ++ )
    {
        if ( ll[x][0] == a_cust_id ) return true;
    }
    
    return false;
}



export function IsCustInList(ll, a_cust_id)
{
    for (let x = 0; x < ll.length; x ++ )
    {
        if ( ll[x][2] == a_cust_id ) return true;
    }
    
    return false;
}


