import React from 'react';

import {DaToolTip} from './tooltip.js';

import useRipple from "./useRipple.tsx";


function DaIconBtnImp( {name, icon, help, disabled, clr, onClick, tp_placement, sz} )
{
    const ref         = React.useRef(null);
    const ripples     = useRipple(ref, 'var(--fl-clr-primary)');

	const clk = React.useCallback( (e) => 
	{
		onClick(e, name);
	}, [onClick] );


	const paramz = { size: sz ? sz : '16px', sx: {fontSize: sz ? sz : '16px', color: disabled ? undefined : clr}  };

	const ee = React.createElement(icon, paramz, null) ;

	let p = ( <button ref={ref} className={"ic_btn" + (disabled ? ' disabled' : '')} onClick={clk}>{ripples}{ee}</button> );

	if (help && !disabled)
		{
			p = (	<DaToolTip placement={tp_placement} title={help}>{p}</DaToolTip> );
		}
		

	return p;
}

/*

function DaIconBtnImp( {name, icon, help, disabled, clr, onClick, tp_placement, sz} )
{
	function clk(e)
	{
		onClick(e, name);
	}

	let p = (	<IconButton disabled={disabled} size='small' onClick={clk} >
					{ React.createElement(icon, { style: {fontSize: '20px'}, size: 'small', sx: {fontSize: sz ? sz : '20px', color: disabled ? undefined : clr}  }, null)   }
				</IconButton>
			);
				
	
	if (help && !disabled)
	{
		p = (	<DaToolTip placement={tp_placement} title={help}>{p}</DaToolTip> );
	}
	
	
	return p;
}

*/



export const DaIconBtn = React.memo( DaIconBtnImp );


