import React from 'react';
import * as HLP from './helpers.js';

import { CmCachedCustLink   } from './desktop/ca_cust_link.js';

import { CmCachedUserLink }   from './desktop/ca_user_link.js';

import * as DEFS from './defs.js';


// table cell formatting utilz------------------




export function CmBitTaskStatus(p)
{
    let clrs = [ '#3060B0', '#30A010', '#A02010' ];
    let tit  = [ 'ОТКРЫТА', 'ЗАКРЫТА', 'УДАЛЕНА' ];
    
    return ( <b style={{color: clrs[p.v]}}>{tit[p.v]}</b> );
}



export function CmBitTableColTaskTrgCustR(p)
{
    if (p.r.tsk_trg_customer == null) return <span className="faint">---</span>;
    return <CmCachedCustLink rid={p.r.tsk_trg_customer} />;
}


export function CmBitTableColTaskStaV(p)
{
    const ttt = ['ОТКРЫТА', 'ЗАКРЫТА', 'УДАЛЕНА'];
    const ccc = ['#000030', '#808080', '#A04010'];
    
    return <b style={{color: ccc[p.v]}}>{ttt[p.v]}</b>
}




export function CmBitTableColUserV(p)
{
    if (p.v == null) return <span className="faint">---</span>;
    return <CmCachedUserLink rid={p.v} />;
}




//----------------------------------------------




export function MAKE_ProdList2(a_csv, a_prods)
{
    //console.log('MAKE_ProdList2... csv: ', a_csv);
    
    let ids = HLP.CsvSplit(a_csv);
    
    if (a_prods.length > 0)
    {
        let ar = [];
        
        for (let p = 0; p < a_prods.length; p ++)
        {
            if (p!=0) ar.push(', ');
            
            let na  = a_prods[p].pr_name;
            let cnt = HLP.ArrCountVal(ids, a_prods[p].pr_id);
            
            let sty = {};
            
            if (a_prods[p].pr_inactive) sty.color = '#FF5050';
            
            if (cnt > 1) na = <span style={sty} key={p}>{na}<b style={{color: '#3040A0'}}> x{cnt}</b></span>;
            else na = <span style={sty} key={p}>{na}</span>;
            
            ar.push( na );
        }
        

        let combo = a_prods.length > 1 ? <span style={{color: '#705090'}}>КОМБО: </span> : null;
        
        return <div>{combo}{ar}</div>;
    }
    
    return <div className="red_shit">НЕТ ПРОДУКТОВ ??</div>;
}


/*
function MAKE_ProdList(a_prod_list)
{
    let r;
    
    let got_inact = 0;
    
    let ar = [];
    
    if (a_prod_list.length == 1)
    {
        r = a_prod_list[0].pr_name;
        
        if (a_prod_list[0].pr_inactive) got_inact = 1;
        
        return <div>{r} {got_inact ? (<b className="zz_red">( УДАЛЁН )</b>) : null } </div>
    }
    else if (a_prod_list.length > 1)
    {
        for (let x = 0; x < a_prod_list.length; x ++ )
        {
            ar.push( a_prod_list[x].pr_name );
            
            if (a_prod_list[x].pr_inactive) got_inact = 1;
        }
    }
    else return <div>???</div>;
    
    
    return <div><span style={{color: '#705090'}}>КОМБО</span> <span className="combo_product">({ ar.join(', ') })</span>{ got_inact ? (<b className="zz_red">( УДАЛЁН )</b>) : null }</div>;
}


*/