import React from 'react';

import * as DT from '../dt.js';

import * as DEFS from '../defs.js';
import * as COM_DEFS from '../com_defs.mjs';

import * as HLP from '../helpers.js';

import {ShortName, ShortName2} from '../desktop/short_name.js';



import { DaCombo } from '../misc/combo.js';

import { Win2Confirm } from '../misc/win2_confirm.js';

import { LayHoriz2, LayVert2, LaySpace } from '../misc/layout.js'

import { DaButton } from '../misc/but.js';
import { DaDateField } from '../misc/date_field.js';
import { NuPost2 } from '../api.js';


import { WinEditPayOp } from '../desktop/win_edit_pay_op.js';

import { Grid3 } from '../grid3.js';

import { CommonReducer, ShowMsg, ShowErr, FmtPayOpBind} from '../misc_glob.js';

import { CmComColCellDateTime, CmComColUser } from '../cm_table_cell_com.js';


import AddIcon from '@mui/icons-material/Add';

import RemoveIcon from '@mui/icons-material/Remove';
import { CmCachedUserLink } from '../desktop/ca_user_link.js';


//------------------------------------


const gSrcFltWhat = [
    {k:-1,      v: '---'},
    {k: 0,	 	v: 'УШЛО'},
    {k: 1, 		v: 'ПРИШЛО'},


];


const gSrcFltCtx = [
    {k:-1,      v: '---'},

    ... COM_DEFS.gPayOpsCtxDct


];





function MyColInfoImp(pp)
{
    const r = pp.row; 
    
    if (r.po_bind_ctx === COM_DEFS.PAY_OPS_CTX_PR_GOOD)
    {
        return <span style={{whiteSpace: 'pre'}}>Продажа товара: <ShortName show_cust ctx={DEFS.SHORT_NAME_GOOD_SELL} id={r.po_bind_id} /></span>;
    }
    else if (r.po_bind_ctx === COM_DEFS.PAY_OPS_CTX_ABON)
    {
        return <span style={{whiteSpace: 'pre'}}>Продажа абон #{r.po_bind_id}: <ShortName show_cust ctx={DEFS.SHORT_NAME_ABON} id={r.po_bind_id} /></span>;
    }
    else if (r.po_bind_ctx === COM_DEFS.PAY_OPS_CTX_BALANCE_DEPO)
    {
        return <span>Депозит на баланс. <ShortName ctx={DEFS.SHORT_NAME_BALANCE_OP_OWNER} id={r.po_bind_id} /></span>;
    }
    
    else if (r.po_bind_ctx === COM_DEFS.PAY_OPS_CTX_MOD_TRP)
    {
        return (<span>Мод трени/доплата: <ShortName2 ctx={DEFS.SHORT_NAME_TRP_MOD} id={r.po_bind_id} /></span>);
    }

    else if (r.po_bind_ctx === COM_DEFS.PAY_OPS_CTX_STAFF_ADVANCE)
    {
        return <div style={{display: 'flex', whiteSpace: 'pre'}}>Аванс: <CmCachedUserLink questionsOnZero rid={r.po_bind_id} /></div>;
    }

    else if (r.po_bind_ctx === COM_DEFS.PAY_OPS_CTX_STAFF_SALARY)
    {
        //  show_clr   ?
        return <div style={{display: 'flex', whiteSpace: 'pre'}}>Зарплата: <CmCachedUserLink questionsOnZero  rid={r.po_bind_id} /></div>;
    }
    
    return FmtPayOpBind(r.po_bind_ctx, r.po_bind_id);
}

const MyColInfo = React.memo( MyColInfoImp );

//------------------------------------------------


function MyColPayMethodImp(pp)
{
    const e = HLP.ArrayOfDictsFindByPropValue(DEFS.PAY_METHOD, 'k', pp.value);
    
    if (!e) return '???';
    
    return e.n;
}

const MyColPayMethod = React.memo( MyColPayMethodImp );

//-------------

function MyCellAmo(pp)
{
    const v = pp.value;
    
    if (v > 0) return <span style={{color:'#40A040'}}>{v}</span>;
    return <span style={{color:'#B04040'}}>{v}</span>;
}




const gNuCols = [

    {fld: 'po_id',        name: 'ID',                align: 'center',       sort: true, width: 80},
    {fld: 'po_dt_when',   name: 'Дата оп',           align: 'center',       sort: true, width: 160, renderCell: CmComColCellDateTime},
    
    {fld: 'po_amount',    name: 'Сумма',             align: 'center',       sort: true, width: 100, renderCell: MyCellAmo},
    
    {fld: 'po_method',    name: 'Метод',             align: 'center',       sort: true, width: 120, renderCell: MyColPayMethod},
    
    {fld: 'po_bind_ctx',  name: 'Привязка',          align: 'left',       sort: true, width: 340, renderCell: MyColInfo},
    
    {fld: 'po_uid',      name: 'Кто делал ?',           align: 'center',        sort: true, width: 170, renderCell: CmComColUser},
    
    {fld: 'po_comment',  name: 'Коммент',            align: 'left',       sort:false, width: 350},
];



function GenSta11( arg )
{
    return  {
  
        selected:   [],
        ser:        0,
        show_edit_rid:  null,
        
        cached_row: null,
        
        del_rid:    null,
        
        busy:       false,
        
        flt_dt_s:   null,
        flt_dt_e:   null,
        flt_what:   -1,
        flt_ctx:    -1,
    };
}


export function TabPayOps( )
{
    const [sta, dispatch] = React.useReducer( CommonReducer, null, GenSta11 );
    
    
	const cb_chg = React.useCallback( (k,v) =>
	{
        dispatch( {type: 'val', k, v} );
	}, [] );
    
    
	const cb_chg_sel = React.useCallback( (nu, this_row) =>
	{
        dispatch( {type: 'val', k:'cached_row', v:this_row} );
		dispatch( {type: 'val', k:'selected', v:nu} );
	}, [sta.selected] );
    
    const cb_close = React.useCallback( () => {
        dispatch( {type: 'val', k:'show_edit_rid', v:null} );
    }, [sta.show_edit_rid] );
    
    const cb_show_new = React.useCallback( () => {
        dispatch( {type: 'val', k:'show_edit_rid', v:-1} );
    }, [sta.show_edit_rid] );
    
	const cb_ok = React.useCallback( () =>
	{
		dispatch( {type: 'val', k:'show_edit_rid', v:null} );
        dispatch( {type: 'val', k:'ser', v:sta.ser+1} );
	}, [sta.ser, sta.show_edit_rid] );
    
    
    const cb_show_del = React.useCallback( () => {
        const RID = sta.selected[0];
        dispatch( {type: 'val', k:'del_rid', v:RID} );
    }, [sta.show_edit_rid, sta.selected] );
    
    const cb_close_pop_del = React.useCallback( () =>
	{
		dispatch( {type: 'val', k:'del_rid', v:null} );
	}, [sta.del_rid] );
    
    
    const cb_del_conf = React.useCallback( () => {
        
        function __ok(e)
        {
            dispatch( {type: 'val', k:'busy', v:false} );
            dispatch( {type: 'val', k:'del_rid', v:null} );
            dispatch( {type: 'val', k:'ser', v:sta.ser+1} );
            dispatch( {type: 'val', k:'selected', v:[]} );
            ShowMsg('УДАЛЕНО !');
        }
        
        function __fail(e)
        {
            dispatch( {type: 'val', k:'busy', v:false} );
            ShowErr(e.m);
        }
        
        dispatch( {type: 'val', k:'busy', v:true} );
        
        NuPost2(1052, {rid:sta.del_rid}, __ok, __fail);
        
    }, [sta.del_rid, sta.ser] );
    
    
    
	function cb_row_dbl(kk, this_row)
	{
        //console.log('PAY DBL.. ', kk, ' -- ', this_row);

       if (this_row.po_bind_ctx > 0 && this_row.po_bind_ctx < 50)
       {
           ShowErr('ОПЕРАЦИЯ ПРИВЯЗАНА');
           return;
       }
        
		dispatch( {type: 'val', k:'show_edit_rid', v:kk} );
	}
    
    
    let DIS_DEL = sta.selected.length < 1;
    
    if (sta.cached_row)
    {
        if (sta.cached_row.po_bind_ctx > 0 && sta.cached_row.po_bind_ctx < 50) DIS_DEL = true;
    }
    
    
    const FLT = React.useMemo( () => {
        return { dt_s: DT.DateStrIso(sta.flt_dt_s),
                 dt_e: DT.DateStrIso(sta.flt_dt_e),
                 what: sta.flt_what,
                 ctx: sta.flt_ctx };
    }, [sta.ser, sta.flt_dt_s, sta.flt_dt_e, sta.flt_what, sta.flt_ctx] );

    return (
        <>
        <LayVert2 rows='max-content 1fr' sx={{padding: '16px', gap: '16px'}}>
        


            <LayHoriz2 cols='max-content max-content 180px 180px 150px 200px 1fr' gap='8px' >
                <DaButton  onClick={cb_show_new} title='НОВАЯ ЗАПИСЬ'  icon={AddIcon} />
                <DaButton  onClick={cb_show_del} title='УДАЛИТЬ'       icon={RemoveIcon} disabled={DIS_DEL} />
                <DaDateField label='ОПЕРАЦИЯ ОТ' name='flt_dt_s'   onChange={cb_chg}  value={sta.flt_dt_s} />				
                <DaDateField label='ОПЕРАЦИЯ ДО' name='flt_dt_e'   onChange={cb_chg}  value={sta.flt_dt_e} />
                <DaCombo  label='ФИЛЬТР' name='flt_what'  onChange={cb_chg} src={gSrcFltWhat}  fld_k='k' fld_v='v'  value={sta.flt_what}   /> 
                <DaCombo  label='ПРИВЯЗКА' name='flt_ctx'  onChange={cb_chg} src={gSrcFltCtx}  fld_k='k' fld_v='v'  value={sta.flt_ctx}   /> 
                <LaySpace />
            </LayHoriz2>
        

            <Grid3 singleSel isNuApi onDblClick={cb_row_dbl} onChangeSel={cb_chg_sel} 
                        selected={sta.selected}  cols={gNuCols} 
                        apiCmd={816} uniq='po_id'  sort_field='po_id' sort_dir='desc' flt={ FLT }  code='pay_ops' />
        
        </LayVert2>
    
    
        <WinEditPayOp visible={sta.show_edit_rid!==null} rid={sta.show_edit_rid} onClose={cb_close} onOk={cb_ok} />
        
        <Win2Confirm busy={sta.busy} visible={sta.del_rid !== null}
                                        title='УДАЛИТЬ ЗАПИСЬ ?'
                                        onClose={cb_close_pop_del}
                                        onOk={cb_del_conf} />
        
    
        </>
    );
}



export default TabPayOps;
