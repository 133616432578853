import React from 'react';

import useRipple from "./useRipple.tsx";

import './but.css';

//---------------------------------------------------
                    // FOR MUI ICONS            for  react-icons...     (REMOVE MUI LATER !)
const gPARAMS = {   sx:{fontSize: '16px'},   fontSize: '18px' };

function DaButtonImp( {title, name, icon, disabled, onClick, lite, act, impo} )
{
    const ref         = React.useRef(null);
    const ripples     = useRipple(ref, '#FFFFFF');
    
    const p_icon = icon ? React.createElement(icon, gPARAMS, null) : null;

    function cb_clk()
    {
        if (onClick) onClick( name );
    }
    
    let CLA = lite ? 'dabut3' : 'dabut2';
    if (disabled) CLA += ' dis';
    if (act) CLA += ' act';

    if (impo) CLA += ' impo';

    let ti = "0";

    if (disabled || lite) ti = "-1";

    return (<button ref={ref} tabIndex={ti} className={CLA} onClick={cb_clk} >
                {p_icon}{title}
                {ripples}
            </button>);
}

export const DaButton = React.memo( DaButtonImp );

