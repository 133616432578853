import React from 'react';

import { CommonReducer, ShowMsg, ShowErr } from '../misc_glob.js';

import { OverlayLoading } from '../misc/ovr_loading.js';

import { DaListCsv } from '../misc/list_csv.js';

import * as COM_DEFS from '../com_defs.mjs';

import { NuPost, NuPost2 } from '../api.js';

import { LayVert2, LayHoriz2, LaySpace } from '../misc/layout.js';


import { BottomButtonsPanel } from './bottom_pan.js';
import { DaButton } from '../misc/but.js';

import SaveIcon from '@mui/icons-material/Save';

import { useGlobalShit } from '../v3/hk_global_shit.js';


const gST = {

    csv:    '',
    ready_csv:  false,
    ready_lst:  false,
    lst:    [],
    dirty:  false,
    busy:   false,
};


export function ViCustomerCardFavTrainer2( {rid} )
{
    const [sta,dispatch] = React.useReducer( CommonReducer, gST );

    const sss = useGlobalShit();



    React.useEffect( () => {

        function __ok(e)
        {
			let v = e.dct[COM_DEFS.CU_EX_FIELD_FAV_TRAINERS_CSV];
			
			if (v !== undefined)
			{
                dispatch( {type: 'val', k:'csv', v:v} );
			}

            dispatch( {type: 'val', k:'ready_csv', v:true} ); 
        }

        function __fail(e)
        {
            ShowErr( e.m );
        }

        NuPost2( 886, {cu_id: rid}, __ok, __fail );

    }, [] );


    React.useEffect( () => {
		
		let on_fail = (e) =>
		{
			ShowErr('FAIL: ', e.m);
		};
		
		let on_ok = (e) =>
		{
            dispatch( {type: 'val', k:'ready_lst', v:true} );
            dispatch( {type: 'val', k:'lst', v:e.rows} );
		};
		
		NuPost2( 885, {}, on_ok, on_fail );
		
	}, [] );
  

    function cb_chg(k,v)
    {
        dispatch( {type: 'val', k:k, v:v} );
        dispatch( {type: 'val', k:'dirty', v:true} );
    }

	function cb_save()
	{
		let on_fail = (e) =>
		{
			ShowErr('SAVE FAIL: ', e.m);
            dispatch( {type: 'val', k:'busy', v:false} );
		};
		
		let on_ok = (e) =>
		{
			ShowMsg('СОХРАНЕНО !');
            dispatch( {type: 'val', k:'dirty', v:false} );
            dispatch( {type: 'val', k:'busy', v:false} );
		};

        dispatch( {type: 'val', k:'busy', v:true} );
		
		NuPost( 887, {cu_id: rid, csv: sta.csv}, on_ok, on_fail );
	}
    

    // 

    if (!sta.ready_lst || !sta.ready_csv)
    {
        return (
            <OverlayLoading active={true} />
        );
    }

    const DIS_LIST = !sss.userHasAbil( 103 );
    const DIS_SAVE = (!sta.dirty) || (!sss.userHasAbil( 103 ));

    return (<LayVert2 rows='1fr max-content' gap='10px' >

                <LayHoriz2 cols='1fr 400px 1fr' padding='20px' >
                    <LaySpace />
                    <DaListCsv disabled={DIS_LIST} name='csv' src={sta.lst} fld_k='u_id' fld_v='u_name'  value_csv={sta.csv} onChange={cb_chg} />
                    <LaySpace />
                </LayHoriz2>

				<BottomButtonsPanel>
					<DaButton disabled={DIS_SAVE} onClick={cb_save} icon={SaveIcon} title='СОХРАНИТЬ'/>
				</BottomButtonsPanel>


                <OverlayLoading active={sta.busy} />

            </LayVert2>);
}
