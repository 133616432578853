 

import {  CmSpinner } from '../cm_base.js';

import Fade from '@mui/material/Fade';

export function OverlayLoading( {active, noBg} )
{

    return (<Fade in={active} timeout={250} >
                <div className={ noBg ? "yy_load_over" : "yy_load_over with_bg"} >
                    <CmSpinner sz='64px' />
                </div>
            </Fade>)
    
}
