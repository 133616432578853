import React from 'react';
import { CmComColCellBalanceAct, CmComColCellDateTime  } from '../cm_table_cell_com.js';

import * as DEFS from '../defs.js';
import * as HLP from '../helpers.js';

import { NuPost, NuPost2 } from '../api.js';

import { Win2Confirm } from '../misc/win2_confirm.js';

import { CommonReducer, ShowMsg, ShowErr, FmtPayOpBind } from '../misc_glob.js';

import { OverlayLoading } from '../misc/ovr_loading.js';

import { Grid3 } from '../grid3.js';

import { CustBalancePlain } from '../tier2/cust_balance.js';

import * as DT from '../dt.js';
import { LayVert2, LayHoriz2, LaySpace } from '../misc/layout.js';

import { DaButton } from '../misc/but.js';
import { DaNumericField } from '../misc/numeric.js';
import { Win2 } from '../misc/win2.js';
import { DaTextField } from '../misc/text_field.js';

import { STO_InvaCustBalance } from '../store.js';

import AddCardIcon from '@mui/icons-material/AddCard';
import RemoveIcon from '@mui/icons-material/Remove';
import CheckIcon from '@mui/icons-material/Check';

import { WinCustDeposit } from '../desktop/win_cust_deposit.js';

//------------------------



function MyColInfo(pp)
{
    const r = pp.row;
    return FmtPayOpBind( r.cbh_bind_ctx, r.cbh_bind_id );
}


const gColz = [
  { sort: true,  align: 'center',   fld: 'cbh_id',              name: 'ID', width: 80},
  { sort: true,  align: 'left', 	fld: 'cbh_dt_created',      name: 'Создано', width: 140, renderCell: CmComColCellDateTime },
  { sort: true,  align: 'left', 	fld: 'cbh_action',          name: 'Действие', width: 160, renderCell: CmComColCellBalanceAct },
  { sort: true,  align: 'left', 	fld: 'cbh_amount',          name: 'Сумма', width: 110 },
  { sort: false, align: 'left', 	fld: 'v_info',              name: 'Привязка', width: 150, renderCell: MyColInfo },
  { sort: true,  align: 'left', 	fld: 'cbh_comment',         name: 'Коммент', width: 200 },

];




//-----------------------------------------------------------

export function ViCustomerBalance( {cu_id} )
{
    const [ser, setSer] = React.useState( 0 );
    const [selected, setSelected] = React.useState( [] );
    const [pop, setPop] = React.useState( 0 );
    const [busy, setBusy] = React.useState( false );
    const [caRow, setCaRow] = React.useState( null );

    const [popRid, setPopRid] = React.useState( -1 );
    
    const flt = React.useMemo( () => { return {cu_id: cu_id} }, [cu_id, ser] );
    
    const cb_chg_sel = React.useCallback( (nu, this_row) => {
        
        if (nu.length > 0)
        {
            setCaRow(this_row);
            //console.log('CA ROW.. ', this_row);
        }
        else 
        {
            setCaRow(null);
        }
        
        setSelected( nu );
        
    }, [selected] );
    
    
    function cb_deposit()
    {
        setPop(1);
    }
    
    function cb_deposit_conf(amo, com)
    {
        ShowMsg('УСПЕХ !');
        setBusy(false);
        setPop(0);
        setSelected([]);
        setSer( ser + 1);
        setPopRid(-1);
        STO_InvaCustBalance( cu_id );
    }
    
    function cb_del()
    {
        setPop(2);
    }

    function cb_dbl(e1, rr)
    {
        if (rr.cbh_action !== DEFS.BALANCE_ACT_DEPOSIT)
        {
            ShowErr('НЕЛЬЗЯ РЕДАКТИРОВАТЬ ЭТУ ЗАПИСЬ !');
            return;
        }

        setPopRid( rr.cbh_id );
        setPop(1);
    }
    
    function cb_del_conf()
    {
        const RID = selected[0];
        
        function __ok(e)
        {
            ShowMsg('УДАЛЕНО !');
            setBusy(false);
            setPop(0);
            setSelected([]);
            setSer( ser + 1);
            STO_InvaCustBalance( cu_id );
        }
        
        function __fail(e)
        {
            ShowErr(e.m);
            setBusy(false);
        }
        
        setBusy( true );
        
        NuPost(1045, {rid: RID}, __ok, __fail);
    }
    
    function cb_close()
    {
        setPop(0);
    }
    
    
    let DIS_DEL = false;
    
    if (caRow)
    {
        if (caRow.cbh_action !== DEFS.BALANCE_ACT_DEPOSIT) DIS_DEL = true;
    }
    else
    {
        DIS_DEL = true;
    }

    
    return (
    
        <>
        <LayHoriz2 cols='340px 1fr' padding='10px' gap='10px'>
    
            <div>
                <div style={{fontFamily: 'body', padding: '10px', fontSize: '22px', textAlign: 'center', color: 'var(--fl-clr-primary)'}}>БАЛАНС: <CustBalancePlain cu_id={cu_id}/></div>
            </div>
    
    
            <LayVert2 rows='max-content 1fr' gap='10px' >
    
                <LayHoriz2 cols='max-content max-content 1fr' gap='10px' >
                    <DaButton   onClick={cb_deposit} title='ВНЕСТИ'            icon={ AddCardIcon }  />
                    <DaButton   onClick={cb_del}     title='УДАИТЬ ЗАПИСЬ'     icon={ RemoveIcon }   disabled={DIS_DEL} />
                    <LaySpace />
                </LayHoriz2>
    
                <Grid3 	singleSel selected={selected}  onChangeSel={cb_chg_sel}  sort_field='cbh_id'  isNuApi	uniq='cbh_id'   
                            sort_dir='desc' cols={gColz}  flt={flt}  onDblClick={cb_dbl}
                            apiCmd={1018}  code='cc_balance' />
                        
            </LayVert2>
                        
        </LayHoriz2>
        
        <WinCustDeposit busy={busy} visible={pop === 1} cuId={cu_id} rid={popRid} onClose={cb_close}  onOk={cb_deposit_conf} />
        
        <Win2Confirm visible={pop === 2}  busy={busy} title='УДАЛИТЬ ЗАПИСЬ ?' onClose={cb_close} onOk={cb_del_conf}   />
        
        </>
    
    );
}
