import React from 'react';
 
import { WinCuReqPicker } from '../desktop/win_cu_req_picker.js';
import {DaIconBtn} from '../misc/icon_btn.js'; 
import { DaOutlinedShit } from '../misc/outlined.js';

import CloseIcon from '@mui/icons-material/Close';
import LinkIcon from '@mui/icons-material/Link';

//---------------

// поле в карточке клиента - связь с заявкой
// value ----> cureq_id  -1 --- none
export function CustFieldLinkWithCureq( {name, value, label, onChange} )
{
    const [pop, setPop] = React.useState( false );
    
    const cb_open = React.useCallback( () => {
        setPop(true);
    }, [] );
    
    const cb_clo = React.useCallback( () => {
        setPop(false);
    }, [] );
    
    const cb_got = (ro) => {
        onChange( name, ro.cre_id );
        setPop(false);
    };
    
    const cb_clear = () => {
        onChange( name, -1 );
    };
    
    const txt = value === -1 ? 'НЕТ' : ('ЗАЯВКА '+value);
    
    const p_juice = <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingLeft: '6px', color: '#505050', height: '100%'}}>
                        <div>{txt}</div>
                        
                        <div style={{display: 'flex', gap: '2px', alignItems: 'center'}}>
                            <DaIconBtn icon={CloseIcon} onClick={cb_clear}      tp_placement='top' help='ОЧИСТИТЬ ПРИВЯЗКУ'  />
                            <DaIconBtn icon={LinkIcon}  onClick={cb_open}       tp_placement='top' help='ВЫБРАТЬ ЗАЯВКУ ДЛЯ СВЯЗИ'  />
                        </div>
                    </div>;
    
    return (
        <>
        <div style={{height: '36px', width: '100%'}}>
            <DaOutlinedShit labelIsFloat={true} labelVal={label} borderIsFoc={false} juice={p_juice} />
        </div>
    
        <WinCuReqPicker visible={pop} onClose={cb_clo} onOk={cb_got}  /> 
    
        </>
    );
}
