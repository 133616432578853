import React from 'react';

import { DaDateField } from './date_field.js';
import { DaCombo } from './combo.js';

import { DaSwitcher } from './switcher.js'

import * as DT from '../dt.js';

//-------------------------





export const MODE_DAY   = 0;
export const MODE_WEEK  = 1;
export const MODE_MONTH = 2;
export const MODE_RANGE = 3;


const src_mode = [

    {k: MODE_DAY,    v: 'ДЕНЬ'},
    {k: MODE_WEEK,   v: 'НЕДЕЛЯ'},
    {k: MODE_MONTH,  v: 'МЕСЯЦ'},
    {k: MODE_RANGE,  v: 'ДИАПАЗОН'},

];


function GetInit( initialMode )
{
    let s = {
        
        mode: MODE_DAY,
        dt_s: new Date(),
        dt_e: new Date(),
        
    };
    
    
    if (initialMode)
    {
        //console.log('GOT INIT MODE.. ', initialMode);
        s = __redu(s, {type: 'mode', v: initialMode} );
    }
    
    return s;
}


function __redu(sta, act)
{
	switch(act.type)
	{
		case 'val':
		{
			const r = { ...sta, [act.k]: act.v };
            return r;
		}
        
        case 'mode':
        {
            const v = act.v;
			const r = { ...sta };
            
            if (r.dt_s === null) r.dt_s = new Date();
            if (r.dt_e === null) r.dt_e = new Date();
            
            if (v === MODE_DAY)
            {
                r.dt_s = DT.DateStartOfDay(r.dt_s);
                r.dt_e = DT.DateStartOfDay(r.dt_s);
            }
            else if (v === MODE_WEEK)
            {
                r.dt_s = DT.DateStartOfWeek(r.dt_s);
                r.dt_e = DT.DateAddDays(r.dt_s, 6);
            }
            else if (v === MODE_MONTH)
            {
                r.dt_s =  DT.DateStartOfMonth(r.dt_s);
                r.dt_e =  DT.DateEndOfMonth(r.dt_s);
                
                //console.log('switch mode month.. ', r.dt_s, ' -- ', r.dt_e);
            }
            
            r.mode = v;
            
            return r;
            
            
        }
    
        case 'switch':
        {
            const v = act.dir;
            
            const r = { ...sta };
            
            if (sta.mode === MODE_DAY)
            {
                const nu_da = DT.DateAddDays(sta.dt_s, v);
                r.dt_s = nu_da;
                r.dt_e = nu_da;
            }
            else if (sta.mode === MODE_WEEK)
            {
                const s = DT.DateAddDays(sta.dt_s, 7 * v);
                const e = DT.DateAddDays(sta.dt_e, 7 * v);
                r.dt_s = s;
                r.dt_e = e;
            }
            else if (sta.mode === MODE_MONTH)
            {
                const t = DT.DateAddMonths(r.dt_s, v);
                
                r.dt_s = DT.DateStartOfMonth(t);
                r.dt_e = DT.DateEndOfMonth(t);
            }
            
            return r;
        }
	}
	
	return sta;
}



// для статы - выбор диапазонов дат..  день / неделя / месяц / диап
export function StatsDateRangeSelector( {onChg, initialMode} )
{
    const [sta, dispatch] = React.useReducer( __redu, GetInit(initialMode) );
    
    
    function cb_switch(k,v)
    {
        dispatch( {type: 'switch', dir:v} );
    }
    
    
    function cb_chg_mode(k,v)
    {
        dispatch( {type: 'mode', v:v } );
    }
    
    const cb_chg_da_rng = (k,v) => {
        
        dispatch( {type: 'val', v:v, k:k } );
    };
	
    
    
    React.useEffect( () => {
        onChg( {s: sta.dt_s, e: sta.dt_e } );
    }, [sta.dt_s, sta.dt_e] );
    
	
    
    
    let p_part;
    
	if (sta.mode === MODE_DAY)
	{
        const ju = <div className="wh100perc" >{DT.DateStrLoc(sta.dt_s) }</div>

        p_part = <DaSwitcher value={ ju} onChange={ cb_switch } />;
	}
	else if (sta.mode === MODE_WEEK)
	{
        const s_val = DT.DateStrLoc(sta.dt_s) + ' - ' + DT.DateStrLoc(sta.dt_e);

        const ju = <div className="wh100perc" >{s_val}</div>;

        p_part = <DaSwitcher value={ ju }  onChange={ cb_switch } />;
	}
	else if (sta.mode === MODE_MONTH)
	{
        const s_val = DT.FmtMonthAndYear(sta.dt_s);
        const ju = <div className="wh100perc" >{s_val}</div>

        p_part = <DaSwitcher value={ ju } onChange={ cb_switch } />;
	}
	else if (sta.mode === MODE_RANGE)
    {
        p_part = (<div style={{display: 'flex', gap: '10px' }}>
                        <DaDateField label='от' name='dt_s'   onChange={cb_chg_da_rng}  value={ sta.dt_s } />				
						<DaDateField label='до' name='dt_e'   onChange={cb_chg_da_rng}  value={ sta.dt_e } />
                </div>);
    }
    else
    {
        p_part = '???';
    }
	
    return (
				<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '20px', height: '40px'}}>

                    <div style={{width: '150px'}}>
                        <DaCombo name='mode'  src={src_mode}   fld_k='k' fld_v='v'  value={sta.mode} onChange={cb_chg_mode} />
                    </div>
                    
                    <div style={{width: '320px'}}>
						{p_part}
                    </div> 
                </div>
                
            );
}
