import React from 'react';


import Tooltip  from 'rc-tooltip';

import "rc-tooltip/assets/bootstrap_white.css";


const gMyST = {
    background: '#001020E0',
    color: '#F0F0FF',
    fontFamily: 'body',
    fontSize: '15px',
    fontWeight: '500',
    pointerEvents: 'none',
};

const gOv = {

    maxWidth: '500px',
    pointerEvents: 'none',
};

export function DaToolTip( {title, placement, children} )
{
    //const myId = 'tp' + React.useId().replaceAll(':', 'x');

  return (

      <Tooltip zIndex={99999} overlay={title}  placement={placement} overlayStyle={gOv} overlayInnerStyle={gMyST}>
        {children}
      </Tooltip>

    /*
    <>
      <div id={myId}>
        {children}
      </div>

    <Tooltip style={{zIndex: '99999'}} anchorSelect={'#'+myId} offset={20} float={true} positionStrategy='fixed' place={placement ? placement : 'right-start'}>
      {title}
    </Tooltip>


    </>

    */
  );


}


/*
import { styled } from '@mui/material/styles';


import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';



export const DaToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props}   enterDelay={70} leaveDelay={2}   placement={props.placement ? props.placement : "right"} followCursor={true}   classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#101030E0',
    color: '#F0F0FF',
    boxShadow: theme.shadows[1],
    fontSize: 13,
    fontWeight: 800,
    padding: '16px'
  },
}));

*/

