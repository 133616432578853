import React from 'react';


import { DaOutlinedShit } from './outlined.js';

import { LayHoriz2 } from './layout.js';



/*
export const DaTextField = React.forwardRef( (props, ref) => {

    const [isFoc, setIsFoc] = React.useState( false );
    
    const {name, value, label, onChange, multiline, input_sx, error, disabled, embRight, onFocus, onBlur} = props;

    //console.log('FLD INP REF ', ref);

    const cb_chg = (e) => {
        if (onChange) onChange(name, e.target.value);
    };
    
    const cb_foc = (e) => {
        
        //console.log('FOC !!');
        setIsFoc( true );
        if (onFocus) onFocus(e);
    };
    
    const cb_blur = (e) => {
        
        //console.log('BLUR !!');
        setIsFoc( false );
        if (onBlur) onBlur(e);
    };
    
    const LAB_IS_FLOAT = isFoc || value.trim() !== '';
    
    let p_juice;
    
    
     
    if (multiline)
    {
        const tf_st = {color: disabled ? '#606060' : '#404060', overflowX:'clip', overflowY: 'scroll', margin: '0px', width: '100%', height: '100%', fontSize: '16px', padding: '6px', outline: 'none', border: 'none', fontFamily: 'Fira Sans Condensed', resize: 'none', ...input_sx};
        
        p_juice = (<textarea ref={ref} autoComplete="off" spellCheck="false" autoCorrect="off" autoCapitalize="off" disabled={disabled} value={value} onFocus={cb_foc} onBlur={cb_blur} onChange={cb_chg} style={tf_st} ></textarea>);
        
        return <DaOutlinedShit error={error} labelIsFloat={LAB_IS_FLOAT} labelVal={label} borderIsFoc={isFoc} juice={p_juice} />;
    }


    p_juice = (<div style={{paddingLeft: '8px', paddingRight: '8px', width: '100%', height: '100%', display: 'flex', alignItems: 'center', gap: '2px'}}>
                    <input ref={ref} className="oi_input_shit"  autoComplete="off"  spellCheck="false" autoCorrect="off" autoCapitalize="off" 
                            disabled={disabled}  onFocus={cb_foc} onBlur={cb_blur} value={value} onChange={cb_chg} style={{color: disabled ? '#A0A0B0' : '#404060', ...input_sx}} />
                   
                    {embRight}
                </div>);

    
    return (
        <div style={{height: '36px', width: '100%'}}>
            <DaOutlinedShit error={error} labelIsFloat={LAB_IS_FLOAT} labelVal={label} borderIsFoc={isFoc} juice={p_juice} />
        </div>
    );
}
 
 
);
*/




//---------------------------------------------

const DaTextFieldImp = React.forwardRef( (props, ref) =>
{
    const {name, error, value, disabled, label, multiline, focusMe, onChange, onFocus, onBlur, embRight, input_sx} = props;

    const [isFoc, setIsFoc] = React.useState( false );
    
    const myRef = React.useRef(null);
    if (ref === null) ref = myRef;

    React.useEffect( () => {

        //console.log('EFF ! ref: ', ref);

        if (focusMe && ref.current)
        {
            ref.current.focus();
        }

    }, [] );

    function cb_chg(e)
    {
        if (onChange) onChange(name, e.target.value);
    }

    function cb_foc(e)
    {
        //console.log('FOC !');
        setIsFoc(true);
        if (onFocus) onFocus(e);
    }

    function cb_blu(e)
    {
        //console.log('BLU !');
        setIsFoc(false);
        if (onBlur) onBlur(e);
    }


    let myHeight;

    const LAB_IS_FLOAT = isFoc || value.trim() !== '';

    let p_juice;
    
    if (!multiline)
    {
        myHeight = '36px';

        let CLA = 'oi_input_shit';
        if (disabled) CLA += ' dis';
        if (isFoc) CLA += ' act';

        p_juice = (<input ref={ref} className={CLA}  value={value} 
                        onFocus={cb_foc} onBlur={cb_blu}
                        tabIndex="0" style={input_sx}
                        autoComplete="off" 
                        spellCheck="false"
                        autoCorrect="off"
                        autoCapitalize="off" readOnly={ disabled ? true : false }
                        onChange={cb_chg} />
        );
    }
    else
    {
        myHeight = undefined;

        let CLA = 'oi_textarea';
        if (disabled) CLA += ' dis';
        if (isFoc) CLA += ' act';

        p_juice = ( <textarea ref={ref} className={CLA}  autoComplete="off" spellCheck="false" autoCorrect="off" autoCapitalize="off"
                    disabled={disabled} value={value} style={input_sx}
                    onFocus={cb_foc} onBlur={cb_blu} onChange={cb_chg}  ></textarea>
        );
    }

    if (embRight)
    {
        p_juice = <LayHoriz2 cols='1fr max-content' gap='6px' sx={gSx33}>{p_juice}{embRight}</LayHoriz2>;
    }

    return (
        <DaOutlinedShit error={error} height={myHeight} isFocused={isFoc} labelVal={label} labelIsFloat={LAB_IS_FLOAT} borderIsFoc={isFoc} juice={p_juice} />
    );
});
	
const gSx33 = {alignItems: 'center'};


export const DaTextField = React.memo( DaTextFieldImp );
