import React from 'react';
import * as DEFS from '../defs.js'

import {DaCombo} from '../misc/combo.js';

function CmComboPayMethodImp( {onChange, value, name, disabled} )
{
	const cb_chg = React.useCallback( (k,v) => {
        onChange( name, v );
	}, [] );
	

	return <DaCombo disabled={disabled} label='ТИП ПЛАТЕЖА' value={value} src={DEFS.PAY_METHOD} fld_k='k' fld_v='n' onChange={cb_chg} />;
}


export const CmComboPayMethod = React.memo( CmComboPayMethodImp );

//---------

export const gVisChange = [

    { k: DEFS.PAYFORM_KIND_BALANCE, n: 'БАЛАНС'     },
    { k: DEFS.PAYFORM_KIND_BONUS, 	n: 'БОНУСЫ'        },
];


function ComboMoneyKindImp( {onChange, value, name, disabled, label} )
{
	const cb_chg = React.useCallback( (k,v) => {
        onChange( name, v );
	}, [] );
	

	return <DaCombo disabled={disabled} label={label} value={value} src={gVisChange} fld_k='k' fld_v='n' onChange={cb_chg} />;
}


export const ComboMoneyKind = React.memo( ComboMoneyKindImp );
