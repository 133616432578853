 
import React from "react";




import * as DT from '../dt.js';


import { DaNumericField } from "../misc/numeric.js";

import { CommonReducer, ShowErr, ShowMsg } from "../misc_glob";

import { WinHelpConta } from "../misc/win_help";

import { NuPost2 } from "../api.js";


import { DaVertScrollFrame, DaVertScrollJuice } from "../misc/vscr.js";
 
import { DaButton } from '../misc/but.js';


import { BottomButtonsPanel } from "../cust_card/bottom_pan.js";


import { DaList2 } from "../misc/list2";

import { LayHoriz2, LaySpace, LayVert2 } from '../misc/layout.js';
import { Win2 } from "../misc/win2";
 
import CheckIcon from '@mui/icons-material/Check';


import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';


import { TrpSelector } from "../event/trp_selector.js";
import { OverlayLoading } from "../misc/ovr_loading.js";

import { useGlobEvActs } from "../misc_glob";


//---


function makeKeyForActAndDudes(act,dudes)
{
    return `${act}:${dudes}`;
}



//----

export function useTrainerRewards()
{
    const [dct, setDct]     = React.useState( {} );
    const [busy, setBusy]   = React.useState( false );
    const [ser, setSer]     = React.useState( 0 );

    React.useEffect( () => {
        
        function __ok(e)
        {
            setBusy( false );
            setDct(e.juice );
        }
        
        function __fail(e)
        {
            setBusy( false );
            ShowErr(e.m);
        }
        
        setBusy( true );
        
        NuPost2(  4150, {}, __ok, __fail );
        
    }, [ser] );
    

    const __get = React.useCallback( (act, dudes) =>
    {
        const kk = makeKeyForActAndDudes(act, dudes);

        let v = dct[kk];
        if (v === undefined) v = 0;

        return v;
    }, [dct] );
    

    function __reload()
    {
        setSer( ser + 1);
    }

    const __isBusy = React.useCallback( () => {
        return busy;
    }, [busy] );
    
    return {    get: __get,
                reload: __reload,
                isBusy:      __isBusy  };
}


//--


export const gSta = {

	busy	    : false,
    dirty       : false,

    cfgJuice    : {},
};

 
export const gHelp1 = (<WinHelpConta>
    Зарплата тренера за событие. В столбцах - количество участников.
</WinHelpConta>);


//--------------------------------------



// окно -РАСКИДАТЬ ПО СОБЫТИЯМ-
export function WinTrainerRewardEditor({ visible, onClose }) {
	return (

		<Win2 visible={visible} title='НАСТРОЙКА ЗП ТРЕНЕРОВ (ВСЕ КЛУБЫ)' width={900} height={500} onClose={onClose} help={gHelp1}>
			<MyJuice1  />
		</Win2>

	);
}


// 	

//----------------------------------


const MAX_DUDES = 9;
const LFT_WIDTH = 220;


function MyTabRow( {act, juice, onChange, hk} )
{
    const E = hk.getById( act );

    const ar = [];

    for (let x = 0; x < MAX_DUDES; x ++ )
    {
        const ND = x+1;

        const NAM = makeKeyForActAndDudes(act, ND);

        let VAL = juice[NAM];
        if (VAL === undefined) VAL = 0;

        const SUPPORT_NUM_DUDES = ND >= E.ea_min_dudes && ND <= E.ea_max_dudes;

        ar.push( SUPPORT_NUM_DUDES ? (
                    <DaNumericField key={x} name={NAM} value={VAL} min={0} max={999999} onChange={onChange} /> ) :
                    <div style={{width: '100%', textAlign: 'center'}}key={x}>-</div> );
    }

    

    return (

        <div style={{fontSize: '14px', padding: '1px', gap: '2px', alignItems: 'center', height: '48px', display: 'grid', gridTemplateRows: 'auto', gridTemplateColumns: `${LFT_WIDTH}px repeat(${MAX_DUDES}, minmax(46px, 1fr))`}}>
            <div style={{color: '#303060', paddingRight: '10px', textAlign: 'right'}}>{E.ea_name}</div>
            {ar}
        </div>

    );
}


//-------------------------

function MyHead()
{
    const ar = [];

    for (let x = 0; x < MAX_DUDES; x ++ )
    {
        ar.push( <div key={x}>{x+1}</div> );
    }

    return (
        <div style={{textAlign: 'center', fontWeight: 600, borderBottom: '1px solid #00000020', fontSize: '14px', color: 'var(--fl-clr-primary)', padding: '1px', gap: '2px', alignItems: 'center', height: '48px', display: 'grid', gridTemplateRows: 'auto', gridTemplateColumns: `${LFT_WIDTH}px repeat(${MAX_DUDES}, minmax(46px, 1fr))`}}>
            <div style={{paddingRight: '10px', textAlign: 'right'}}>СОБЫТИЕ</div>
            {ar}
        </div>
    );
}

//--------------------


function MyJuice1( )
{
	const [sta, dispatch] = React.useReducer(CommonReducer, gSta);


    const hk_act = useGlobEvActs();

	React.useEffect(() => {

		function __ok(e) {
			dispatch({ type: 'vals', dct: { busy: false, cfgJuice: e.juice} });
		}

		function __fail(e) {
			ShowErr(e.m);
			dispatch({ type: 'vals', dct: { busy: false  } });
		}

		dispatch({ type: 'vals', dct: { busy: true  } });

		NuPost2(4150, {}, __ok, __fail);

	}, [] );


	const cb_chg = React.useCallback((k, v) => {
		dispatch({ type: 'val', k: k, v: v });
	}, []);

	function cb_chg_cfg(k,v)
    {

        const nu = { ...sta.cfgJuice };

        nu[ k ] = v;

		dispatch({ type: 'vals', dct: {cfgJuice: nu, dirty: true }});
        //console.log('CFG CHG.. ', k, ' -- ', v);
	}



    const aaa = [];

    for (let x = 0; x < hk_act.rows.length; x++ )
    {
        const r = hk_act.rows[x];

        if (r.ea_use_for_evt < 1) continue;

        aaa.push(  <MyTabRow key={r.ea_id} act={r.ea_id} onChange={cb_chg_cfg} juice={sta.cfgJuice} hk={hk_act} /> );
    }

    function cb_save()
    {
        console.log('SAVING: ', sta.cfgJuice);

		function __ok(e) {
            ShowMsg('СОХРАНЕНО !');
			dispatch({ type: 'vals', dct: { busy: false, dirty: false} });
		}

		function __fail(e) {
			ShowErr(e.m);
			dispatch({ type: 'vals', dct: { busy: false  } });
		}

		dispatch({ type: 'vals', dct: { busy: true  } });

		NuPost2(4151, {cfg:sta.cfgJuice}, __ok, __fail);
    }


	return (

		<>

		<LayVert2 rows='max-content 1fr max-content ' padding='10px'>

            <MyHead />

            <DaVertScrollFrame faders>
                <DaVertScrollJuice padding='10px'>
                   
                    {aaa}
                </DaVertScrollJuice>
            </DaVertScrollFrame>

            <BottomButtonsPanel>
                <DaButton title='СОХРАНИТЬ' icon={CheckIcon} onClick={cb_save} disabled={!sta.dirty} />
            </BottomButtonsPanel>

			<OverlayLoading active={sta.busy} />

		</LayVert2>

		</>
	);
}

//---------------------------------------


