 
import React from 'react';

import * as DT from '../dt.js';

import * as DEFS from '../defs.js';
import * as COM_DEFS from '../com_defs.mjs';

import * as HLP from '../helpers.js';

import { DaButton } from '../misc/but.js';
import SaveIcon from '@mui/icons-material/Save';

import { DaDateField } from '../misc/date_field.js';
import { DaCsvField } from '../misc/csv_field.js';
import { CmComboCustSrc } from '../cm_cust_src.js';
import { CustFieldLinkWithCureq } from '../desktop/cust_field_link_cureq.js';

import { LayHoriz2, LayVert2, LaySpace } from '../misc/layout.js';

import { DaNumericField } from '../misc/numeric.js';

import { BottomButtonsPanel } from './bottom_pan.js';

import { STO_InvaCustLink } from '../store.js';

import { DaCheck } from '../misc/check.js';
import { DaCombo } from '../misc/combo.js';

import { DaTextField } from '../misc/text_field.js';

import { NuPost } from '../api.js';
import { ShowMsg, ShowErr, CommonReducer } from '../misc_glob.js';

import { OverlayLoading } from '../misc/ovr_loading.js';

import { DaVertScrollFrame, DaVertScrollJuice } from '../misc/vscr.js';

import { CmMuiCustExField } from '../desktop/win_new_cust.js'; // !
import { DaOutlinedShit } from '../misc/outlined.js';


import { CmDudeStatsRating } from './vi_details';

import { useApiQuery2 } from '../api_stuff.js';

//------------------------------------

const gSrcHand = [ 
    {k: DEFS.CU_HAND_UNK, 	v: '???'},
    {k: DEFS.CU_HAND_L, 	v: 'ЛЕВАЯ'},
    {k: DEFS.CU_HAND_R, 	v: 'ПРАВАЯ'},
    {k: DEFS.CU_HAND_BOTH,	v: 'АМБИДЕКСТР'},
    
];

const gSrcCanRecord = [ 
        {k: 0, v: 'НЕТ'},
        {k: 2, v: 'ПОКА НЕТ'},
        {k: 1, v: 'ДА'},
        
        {k: 3, v: '???'},
];

//------------

const gAdoTG = <div className="zz_so_tg"></div>;
const gAdoInsta = <div className="zz_so_insta"></div>;
const gAdoVk = <div className="zz_so_vk"></div>;

//-------------------------


function BlkCustFormatImp( {onChange, error, valOpSpa, valOpGrp, valOpPai, valOpSol} )
{
    const p_ju = (<div style={{display: 'flex', flexDirection: 'column', gap: '10px', padding: '10px'}}>
                                <DaCheck  name='op_pai'  value={valOpPai}     	label='Спарринг' onChange={onChange} />
							    <DaCheck  name='op_sol'  value={valOpSol}       label='Группа'   onChange={onChange} />
							    <DaCheck  name='op_grp'  value={valOpGrp}       label='Парные'   onChange={onChange} />
							    <DaCheck  name='op_spa'  value={valOpSpa}    	label='Соло'     onChange={onChange} />
    </div>);

    return (
            <DaOutlinedShit error={error} labelIsFloat labelVal='ФОРМАТ' juice={p_ju} />
    );
}


const BlkCustFormat = React.memo( BlkCustFormatImp );

//------------------------



function BlkCustPrefsImp( {onChange, error, valBox, valKick, valMMA, valThai, valFunc} )
{
    const p_ju = (<div style={{display: 'flex', flexDirection: 'column', gap: '10px', padding: '10px'}}>
                                <DaCheck  name='ex_drp_box'   value={valBox}     	label='Бокс' onChange={onChange} />
							    <DaCheck  name='ex_drp_kick'  value={valKick}       label='Кикбоксинг'   onChange={onChange} />
							    <DaCheck  name='ex_drp_mma'   value={valMMA}        label='ММА'   onChange={onChange} />
							    <DaCheck  name='ex_drp_thai'  value={valThai}    	label='Тайский'     onChange={onChange} />
                                <DaCheck  name='ex_drp_func'  value={valFunc}    	label='Функционал'     onChange={onChange} />
    </div>);

    return (
            <DaOutlinedShit error={error} labelIsFloat labelVal='ПРЕДПОЧТЕНИЯ' juice={p_ju} />
    );
}


const BlkCustPrefs = React.memo( BlkCustPrefsImp );


//-------------------------------------------------


function BlkCustStats2(pp)
{
	const { isFetching, isLoading, isError, data, error } = useApiQuery2( 978, {} );
	
	if (isFetching || !data) return null;

	
	if (isError) return ( <div className="yy_error">{error}</div> );
	
	
	
	const valSta 	= pp.dict;
	const CHG 		= pp.onChange;

    if (!valSta) return null;
	
	function getValStaWrap(k)
	{
		let v = valSta[k];
		if (v === undefined) v = 0;
		return v;
	}


	const p_lst = [];
	
	const rrr = data.rows;
	
	for (let x = 0; x < rrr.length; x ++ )
	{
		const e = rrr[x];
		
		const ID = e.st_id;
		
		p_lst.push( <CmDudeStatsRating  readOnly={pp.disabled}  key={ID} labelWidth='140px' label={e.st_name} name={ID}  value={ getValStaWrap(ID) } onChange={CHG} /> );
	}


    const p_ju = (
        <div style={{display: 'flex', flexDirection: 'column', gap: '20px', padding: '20px'}}>
        {p_lst}
        </div>
    );
	
	
	return (
				<DaOutlinedShit labelIsFloat labelVal='СТАТЫ' juice={p_ju}  />
  
	
	);
}



//--------

function __GenState( pp )
{

    return {

        ready:     false,

        busy:       false,

        nam:        '',
        pho:        '',
        conta:      '',

        walk_reach:  0,
        can_record:  0,
        got_rules:   0,

        hei:      0,
        wei:      0,
        ori:      -1,
        hpref:    -1,

        dob: new Date(),
        gend: 0,

        contract_dt: null,

        op_grp: 0,
        op_sol: 0,
        op_spa: 0,
        op_pai: 0,


        cureq: -1,

        ex_tg: '',
        ex_insta: '',
        ex_vk: '',
        ex_how_far: '',
        ex_pref_conta: '',

        ex_spec_care: 0,

        ex_desire: '',
        ex_bg: '',
        ex_med: '',

        ex_drp_box: 0,
        ex_drp_kick: 0,
        ex_drp_mma: 0,
        ex_drp_thai: 0,
        ex_drp_func: 0,

        stats: null,
    };
}


function __ConvInDct(e)
{
    const R     = e.row;
    const EX    = e.ex;

    return {   
        busy: false, ready: true,
        nam: R.cu_name,
        pho: R.cu_phone,
        conta: R.cu_contact_person,
        walk_reach: R.cu_f_walk_reach,
        can_record: R.cu_f_can_record,
        got_rules: R.cu_got_rules,
        hei: R.cu_height,
        wei: R.cu_weight,
        ori: R.cu_origin,
        hpref: R.cu_hand_pref,
        dob: DT.DateParseIso( R.cu_dob ),
        contract_dt: DT.DateTimeParseIso( R.cu_contract_dt ),
        gend: R.cu_gender,

        cureq: e.cureq,

        stats: e.stats,

        op_grp: R.cu_opt_group,
        op_sol: R.cu_opt_only_solo,
        op_spa: R.cu_opt_sparring,
        op_pai: R.cu_opt_pairs,


        ex_drp_box:    parseInt(EX[COM_DEFS.CU_EX_FIELD_DRPR_BOX]) 	    || 0,
        ex_drp_kick:   parseInt(EX[COM_DEFS.CU_EX_FIELD_DRPR_KICKBOX]) 	|| 0,
        ex_drp_mma:    parseInt(EX[COM_DEFS.CU_EX_FIELD_DRPR_MMA]) 	    || 0,
        ex_drp_thai:   parseInt(EX[COM_DEFS.CU_EX_FIELD_DRPR_THAI]) 	|| 0,
        ex_drp_func:   parseInt(EX[COM_DEFS.CU_EX_FIELD_DRPR_FUNC]) 	|| 0,

        ex_spec_care:  parseInt(EX[COM_DEFS.CU_EX_FIELD_SPECIAL_CARE]) 	|| 0,

        ex_med:      EX[COM_DEFS.CU_EX_FIELD_MED_RESTR] || '',
        ex_desire:   EX[COM_DEFS.CU_EX_FIELD_DESIRE] || '',
        ex_bg:       EX[COM_DEFS.CU_EX_FIELD_SPORT_BG] || '',



        ex_tg:      EX[COM_DEFS.CU_EX_FIELD_SOC_TG] || '',
        ex_insta:   EX[COM_DEFS.CU_EX_FIELD_SOC_INSTA] || '',
        ex_vk:      EX[COM_DEFS.CU_EX_FIELD_SOC_VK] || '',
        ex_how_far: EX[COM_DEFS.CU_EX_FIELD_HOW_FAR] || '',
        ex_pref_conta: EX[COM_DEFS.CU_EX_FIELD_PREF_CONTACT_CSV] 	|| '',
     };
}


export function ViDetails2( {rid} )
{
    const [sta, dispatch] = React.useReducer( CommonReducer, {}, __GenState );


    React.useEffect( () => {

        function __ok(e)
        {
            dispatch( { type: 'vals', dct: __ConvInDct(e) } );
        }

        function __fail(e)
        {
            ShowErr(e.m);
            dispatch( { type: 'val', k:'busy', v:false} );
        }

        dispatch( { type: 'val', k:'busy', v:true} );
        NuPost( 980, {rid:rid}, __ok, __fail );

    }, [] );


    const cb_chg = React.useCallback( (k,v) => {
        dispatch( { type: 'val', k:k, v:v} );
    }, [] );

    const cb_chg_sta = React.useCallback( (k,v) => {
        const nu = {...sta.stats};
        nu[k] = v;
        dispatch( { type: 'val', k:'stats', v: nu} );

    }, [sta.stats] );


    const cb_submit = () =>
	{
        const S = sta;

		const t_row = {
            cu_got_rules: S.got_rules,
			cu_name:	S.nam,
			cu_phone:	S.pho,
			cu_contact_person: S.conta,
			cu_origin: S.ori,
			cu_height: S.hei,
			cu_weight: S.wei,
			cu_gender: S.gend,
			cu_hand_pref: S.hpref,
			cu_f_walk_reach: S.walk_reach,
			cu_f_can_record: S.can_record,
			cu_opt_pairs: S.op_pai,
			cu_opt_only_solo: S.op_sol,
			cu_opt_group: S.op_grp,
			cu_opt_sparring: S.op_spa,

			cu_dob: DT.DateStrIso(S.dob),
			cu_contract_dt: DT.DateTimeStrIso(S.contract_dt),
		};
		
		
		const t_ex = {
			
			[COM_DEFS.CU_EX_FIELD_DESIRE]:		S.ex_desire,
			[COM_DEFS.CU_EX_FIELD_SPORT_BG]:		S.ex_bg,
			[COM_DEFS.CU_EX_FIELD_MED_RESTR]:		S.ex_med,
			[COM_DEFS.CU_EX_FIELD_SOC_TG]:		S.ex_tg,
			[COM_DEFS.CU_EX_FIELD_SOC_VK]:		S.ex_vk,
			[COM_DEFS.CU_EX_FIELD_SOC_INSTA]:		S.ex_insta,
			[COM_DEFS.CU_EX_FIELD_HOW_FAR]:		S.ex_how_far,
			[COM_DEFS.CU_EX_FIELD_DRPR_BOX]:		S.ex_drp_box,
			[COM_DEFS.CU_EX_FIELD_DRPR_KICKBOX]:	S.ex_drp_kick,
			[COM_DEFS.CU_EX_FIELD_DRPR_MMA]:		S.ex_drp_mma,
			[COM_DEFS.CU_EX_FIELD_DRPR_THAI]:		S.ex_drp_thai,
            [COM_DEFS.CU_EX_FIELD_DRPR_FUNC]:		S.ex_drp_func,
            [COM_DEFS.CU_EX_FIELD_SPECIAL_CARE]:	S.ex_spec_care,
            [COM_DEFS.CU_EX_FIELD_PREF_CONTACT_CSV]:		S.ex_pref_conta,
			
		};
		
      
		const __ok = (e) => {
			ShowMsg('СОХРАНЕНО !');
			
			dispatch( {type: 'val', k:'busy', v:false} );
			
			STO_InvaCustLink(rid);
		};
		
		const __fail = (e) => {
			ShowErr(e.m);
			
			dispatch( {type: 'val', k:'busy', v:false} );
		};
		

		dispatch( {type: 'val', k:'busy', v:true} );
		
		NuPost( 711, { row: t_row,
                        ex: t_ex,
                        sta: sta.stats,
                        cureq: sta.cureq,
                        cu_id: rid }, __ok, __fail );

		
	};

    const GOT_MIX_NA = HLP.StrGotMixedAlphaBets(sta.nam);
    const warn_nam = sta.nam.trim() === '' || GOT_MIX_NA;

    const DIS_SUB = warn_nam || (sta.pho.trim()==='');

    

    const warn_st = (sta.op_grp + sta.op_pai + sta.op_sol + sta.op_spa) < 1;
	
	const warn_dr = (sta.ex_drp_box + sta.ex_drp_kick + sta.ex_drp_mma + sta.ex_drp_thai) < 1;

	const warn_height =  sta.hei < 1;
	const warn_weight =  sta.wei < 1;
	const warn_gend = sta.gend === DEFS.CU_GENDER_UNK;
	const warn_hand = sta.hpref === DEFS.CU_HAND_UNK;

    const warn_contract = sta.contract_dt === null;

                
    return (<LayVert2 rows='1fr max-content'  >

                <DaVertScrollFrame faders>

                    <DaVertScrollJuice  padding='40px' hide={!sta.ready} >

                        <LayHoriz2 cols='1fr 1fr' gap='50px'>

                            <LayVert2 rows='repeat(10, max-content) 1fr' gap='20px' >
                                <DaTextField label={GOT_MIX_NA ? 'ФИО - (СМЕШАННЫЙ АЛФАВИТ !)' : 'ФИО'}        name='nam' value={sta.nam} onChange={cb_chg} error={warn_nam} />
                                <DaTextField label='ТЕЛЕФОН'    name='pho' value={sta.pho} onChange={cb_chg} />
                                <DaTextField label='КОНТАКТНОЕ ЛИЦО (ТЕЛЕФОН МАМЫ/ПАПЫ)'    name='conta' value={sta.conta} onChange={cb_chg} />

                                <DaTextField label='TELEGRAM'       name='ex_tg'   value={sta.ex_tg}       onChange={cb_chg} embRight={gAdoTG} />
                                <DaTextField label='INSTA'          name='ex_insta' value={sta.ex_insta}   onChange={cb_chg} embRight={gAdoInsta} />
                                <DaTextField label='VK'             name='ex_vk'    value={sta.ex_vk}      onChange={cb_chg} embRight={gAdoVk}/>

                                <DaCsvField  label='УДОБНЫЙ СПОСОБ СВЯЗИ'  fld_k='k' fld_v='v' name='ex_pref_conta' value_csv={sta.ex_pref_conta} src={DEFS.gSrcCustContactPref} onChange={cb_chg} />

                                

                                <CustFieldLinkWithCureq name='cureq' value={sta.cureq} onChange={cb_chg} label='СВЯЗЬ С ЗАЯВКОЙ' />

                                <DaDateField error={warn_contract} name='contract_dt' value={sta.contract_dt} label='ДОГОВОР ПОДПИСАН' onChange={cb_chg} />

                                <DaCheck name='got_rules' value={sta.got_rules} label='Отправили правила ?' onChange={cb_chg} />

                                <LaySpace />
                            </LayVert2>


                            <LayVert2 rows='repeat(9, max-content)' gap='20px' >

                                <CmComboCustSrc label='ИСТОЧНИК' name='ori' value={ sta.ori } onChange={cb_chg} />

                                <DaNumericField label='РОСТ СМ' name='hei' value={sta.hei} onChange={cb_chg} min={0} max={999} error={warn_height} />
                                <DaNumericField label='ВЕС КГ'  name='wei' value={sta.wei} onChange={cb_chg} min={0} max={999} error={warn_weight} />

                                <DaCombo error={warn_gend} name='gend' label='ПОЛ' fld_k='k' fld_v='v'    value={sta.gend} onChange={cb_chg}  src={DEFS.gSrcGender}  />

                                <DaDateField name='dob' nonull label='ДАТА РОЖДЕНИЯ' onChange={cb_chg}  value={sta.dob} />	

                                <DaCombo error={warn_hand} name='hpref' label='РАБОЧАЯ РУКА' fld_k='k' fld_v='v'    value={sta.hpref} onChange={cb_chg}  src={gSrcHand}   />
                                        

                                <DaCombo name='can_record' label='МОЖНО СНИМАТЬ ?' fld_k='k' fld_v='v'    value={sta.can_record} onChange={cb_chg}  src={gSrcCanRecord} />


                                <DaTextField label="ДОЛГО ДОБИРАТЬСЯ ?"  name='ex_how_far' value={sta.ex_how_far} onChange={cb_chg}  />

                                <DaCheck name='walk_reach' value={sta.walk_reach} label='Живёт в пешей доступности ?' onChange={cb_chg} />
                                <DaCheck name='ex_spec_care' value={sta.ex_spec_care} label='ОСОБЕННЫЙ ребёнок ?' onChange={cb_chg} />


                            </LayVert2>

                        </LayHoriz2>

                        <div style={{width: '100%', height: '60px'}}></div>

                        <LayHoriz2 cols='1fr 1fr 1fr' gap='30px'>
                            <BlkCustStats2 onChange={cb_chg_sta} dict={sta.stats}  />
                                
                            <BlkCustFormat error={warn_st} onChange={cb_chg} valOpGrp={sta.op_grp} valOpSol={sta.op_sol} valOpSpa={sta.op_spa} valOpPai={sta.op_pai}/>
                             
                            <BlkCustPrefs error={warn_dr} onChange={cb_chg}  valBox={sta.ex_drp_box} 
                                                             valKick={sta.ex_drp_kick} 
                                                             valMMA={sta.ex_drp_mma} 
                                                             valThai={sta.ex_drp_thai}
                                                             valFunc={sta.ex_drp_func}  />
                        </LayHoriz2>

                        <div style={{width: '100%', height: '60px'}}></div>

                        <LayVert2 rows='80px 80px 80px' gap='20px'  >
                            <CmMuiCustExField name='ex_med' value={sta.ex_med} label='МЕДИЦИНСКИЕ ПРОТИВОПОКАЗАНИЯ' onChange={cb_chg}  />
                            <DaTextField name='ex_desire' label='ЦЕЛИ ТРЕНИРОВОК'     value={sta.ex_desire}   onChange={cb_chg} multiline />
                            <DaTextField name='ex_bg'     label='ОПЫТ В СПОРТЕ'         value={sta.ex_bg}       onChange={cb_chg} multiline />
                        </LayVert2>

                    </DaVertScrollJuice>

                </DaVertScrollFrame>

                <BottomButtonsPanel>
					<DaButton onClick={cb_submit} disabled={DIS_SUB} icon={SaveIcon} title='СОХРАНИТЬ' />
				</BottomButtonsPanel>


                <OverlayLoading  active={sta.busy || !sta.ready} />
            </LayVert2>);
}

