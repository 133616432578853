import React from 'react';


import * as DEFS from '../defs.js';
import * as HLP from  '../helpers.js';

import { NuPost } from '../api.js';

import {DaCombo} from '../misc/combo.js';

import { ShowErr, ShowMsg  } from '../misc_glob.js';

//-------------------------------------


// 

const ELEM_ALL = [ {c_id:-1, c_vis_name: '---'} ];

// список - выбор клубов (галочки)
export function ClubListCombo( {name, value, disabled, label, error, onChange} )
{
    const [lst, setLst] = React.useState( null );
    
    
    React.useEffect( () => {
        
        function __ok(e)
        {
            setLst( ELEM_ALL.concat(e.rows)  );
        }
        
        function __fail(e)
        {
            ShowErr(e.m);
        }
        
        NuPost( 3001, {}, __ok, __fail );
        
    }, [] );
    
    
    const cb_chg = React.useCallback( (k,v) => {
        onChange( name, v );
    }, [value, name] );
    


	return <DaCombo error={error} disabled={disabled} label={label} value={value} src={lst} fld_k='c_id' fld_v='c_vis_name' onChange={cb_chg} />;
}
