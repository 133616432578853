import React from 'react';

import { CommonReducer, ShowMsg, ShowErr } from '../misc_glob';

//--------------------------


export const MAX_CARD_SLOTS = 5;

const gStCu = {
  
    cuId0:  -1,
    cuId1:  -1,
    cuId2:  -1,
    cuId3:  -1,
    cuId4:  -1,

    vis0:  0,
    vis1:  0,
    vis2:  0,
    vis3:  0,
    vis4:  0,

    showCustSearch: false,
    searchingForSlot: 0,
};


//----------------------------------



export const CuCaMgrContext            = React.createContext( null );
export const CuCaMgrDispatchContext    = React.createContext( null );


export function CuCaMgrProvider( {children} )
{
    const [sta, dispatch] = React.useReducer( CommonReducer, gStCu );

    
    const cb_setCard = (slot, cuId) => {
        dispatch( { type: 'val', k:'cuId'+slot, v:cuId } );
        dispatch( { type: 'val', k:'vis'+slot, v:2 } );
    };

    const cb_setVisForCuId = (cuId, v) => {
        console.log('SET VIS FOR CU ID... ', cuId, ' -- ', v);
        const i = cb_findCustInSlot(cuId);
        if (i === -1)
        {
            console.log('CU-ID not fnd,', cuId);
            return;
        }
        dispatch( { type: 'val', k:'vis'+i, v:v } );
    };

    const cb_findEmpty = () => {
        for (let x = 0; x < MAX_CARD_SLOTS; x ++ )
        {
            if (sta['vis'+x] === 0) return x;
        }
        return -1;
    };

    const cb_findCustInSlot = (cuId) => {
        for (let x = 0; x < MAX_CARD_SLOTS; x ++ )
        {
            if (sta['vis'+x] > 0 && sta['cuId'+x] === cuId) return x;
        }
        return -1;
    };

    const WRP = {
        sta: sta,

        tryOpen: (cuId) => {

            const slo = cb_findCustInSlot(cuId);

            if (slo !== -1)
            {
                //ShowMsg('УЖЕ ОТКРЫТ !');
                cb_setVisForCuId( cuId, 2 );
                return;
            }

            const si = cb_findEmpty();
            if (si === -1)
            {
                ShowErr('НЕТ МЕСТА ДЛЯ ЕЩЁ ОДНОЙ КАРТОЧКИ КЛИЕНТА !');
                return;
            }
            cb_setCard( si, cuId );
        },

        findCustInSlot: cb_findCustInSlot,
        setCard:        cb_setCard,
        setVisForCuId:  cb_setVisForCuId,

        showSearch: (v, forSlot) => {
            dispatch( { type: 'vals', dct: {showCustSearch:v, searchingForSlot: forSlot}  } );
        },
    };

    return (
        <CuCaMgrContext.Provider value={WRP} >
            <CuCaMgrDispatchContext.Provider value={dispatch} >
                {children}
            </CuCaMgrDispatchContext.Provider>
        </CuCaMgrContext.Provider>
    );
}
 
 
//-------------------------------

export function useCuCaMgrState()
{
    return React.useContext( CuCaMgrContext );
}

export function useCuCaMgrStateDispatch()
{
    return React.useContext( CuCaMgrDispatchContext );
}

