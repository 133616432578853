import React from 'react';

import { BottomButtonsPanel } from '../cust_card/bottom_pan.js';

import { DaButton } from '../misc/but.js';

import { Win2 } from '../misc/win2.js';

//-----------------------------------------------
import SaveIcon from '@mui/icons-material/Save';

import AvatarEditor from 'react-avatar-editor'



// окно - кроп аватарки кастомера

function MyJuice( {src, onOk} )
{
    const [crop, setCrop]       = React.useState({});
    const [dim, setDim]         = React.useState([0,0]);
    const [loaded, setLoaded]   = React.useState(false);
    const [valSca, setValSca]   = React.useState(1.0);
    
    const my_ref = React.useRef(null);
    
    const editor = React.useRef(null);
    

    function onComplete(crop, percentCrop)
    {
        //console.log('CMP CRO %s', crop);
    }
    
    function onBtnOk()
    {
        //console.log('CROP: %d %d  %d %d', crop.x, crop.y, crop.width, crop.height);
        //
        
        if (!editor) return;
        
        const orig_w = dim[0];//editor.current.getImage().width;
        const orig_h = dim[1];//editor.current.getImage().height;
        
        //console.log('ORIG IMG  %d %d', orig_w, orig_h);
        
        ///let inv = 1.0 / valSca;
        
        
        
        const WW = orig_w;
        const HH = orig_h;
        
        console.log('AFTER SCA  %.3f  %.3f', WW, HH);
        
        let cr = editor.current.getCroppingRect();
        
        
        cr.x *= WW;
        cr.y *= HH;
        cr.width *= WW;
        cr.height *= HH;
        
        
        cr.x = Math.floor(cr.x);
        cr.y = Math.floor(cr.y);
        
        cr.width = Math.floor(cr.width);
        cr.height = Math.floor(cr.height);
        
        //console.log('CROP FINAL ', cr);
        
		onOk( cr );
    }
    
    let dis_ok = !(crop.width > 0 && crop.height > 0);
    

    let p_loading = loaded ? null : <div className="fl_center" style={{width: '256px', height: '256px' }}>
                                    <span style={{width: '64px', height: '64px' }} className="zz_spinner"></span>;
                                </div>;
                    
                    
    function cb_wheel(e)
    {
		let d = e.deltaY;
		let s = valSca;
		if (d > 0)
		{
			s *= 0.9;
			if (s < 1.0) s = 1.0;
		}
		else if (d < 0)
		{
			s *= 1.1;
			if (s > 3.0) s = 3.0;
		}
		
		setValSca(s);
	}        
	
	
	function succ(eee)
	{
		setDim( [eee.resource.width, eee.resource.height] );
	}    
    
    return (    <>
    
					<div onWheel={cb_wheel}>

						<AvatarEditor  onLoadSuccess={succ}
							ref={editor}
							image={src}
							width={480}
							height={480}
							border={40}
							color={[0, 0, 0, 0.5]} // RGBA
							scale={valSca}
							rotate={0}
										/>
								
                    </div>
								
					<BottomButtonsPanel>
						<DaButton onClick={onBtnOk} icon={SaveIcon} title='ГОТОВО' />
					</BottomButtonsPanel>
                    
                </>
    
    );
}



export function WinCustPicEditor( {visible, src, onOk, onClose} )
{
    return (
        <Win2 visible={visible} title='РЕДАКТИРОВАТЬ ФОТО' width={570} height={650}   onClose={onClose} >
                <MyJuice src={src} onOk={onOk} />
        </Win2>
    );
}
