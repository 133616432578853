 


import { DaFaderTop } from "./fader";
import { DaFaderBottom } from "./fader";

import "./vscr.css";



export function DaVertScrollFrame( {faders, children, dark} )
{
    return (<div className='vscr_fr' >
                <div className={"e1" + (dark ? ' dark' : '')}>{children}</div>
                { faders ? (<>
                                <DaFaderTop dark={dark} />
                                <DaFaderBottom dark={dark} />
                            </>) : null }
            </div>
            );
}

export function DaVertScrollJuice( {hide, children, sx, padding, paddingTop, paddingBottom} )
{
    if (hide) return null;

    const st = {position: 'absolute', width: '100%', ...sx };
    
    if (padding) st.padding = padding;

    if (paddingTop) st.paddingTop = paddingTop;
    if (paddingBottom) st.paddingBottom = paddingBottom;
    
    return <div style={st}>{children}</div>;
}





