 

import { format, parse, isValid,
			addHours, addDays, addMonths,
			differenceInDays,
			differenceInYears,
			setDefaultOptions,
            
            differenceInCalendarDays,
            
            getWeeksInMonth,
			
			endOfMonth,
            
            setHours,
            setMinutes,
            setSeconds,
            
            getHours,
            getMinutes,
            getSeconds,
			
			startOfDay,
			startOfWeek, 
			startOfMonth }  from 'date-fns';

import { ru } from 'date-fns/locale'

import { getUnixTime } from 'date-fns'

setDefaultOptions({ locale: ru })

//-----------------------------------------------

export function DateSetHMS(dt:Date,h:Number,m:Number,s:Number) : Date
{
    dt = setHours(dt,h);
    dt = setMinutes(dt,m);
    dt = setSeconds(dt,s);
    
    return dt;
}

export function DateTransferHMS(dst:Date, from:Date) : Date
{
    if (from === null) return dst;
    
    dst = setHours(dst,     getHours(from) );
    dst = setMinutes(dst,   getMinutes(from) );
    dst = setSeconds(dst,   getSeconds(from) );
    
    return dst;
}



export function UnixTime(dt)
{
    return getUnixTime(dt);
}

export function CalenDaysDiff(lo, hi)
{
    return differenceInCalendarDays(hi, lo);
}

export function DaysDiff(dt_lo:Date, dt_hi:Date)
{
	return differenceInDays(dt_hi, dt_lo);
}

export function YearsDiff(dt_lo:Date, dt_hi:Date)
{
	return differenceInYears(dt_hi, dt_lo);
}


export function DateAddHours(dt:Date, num)
{
	return addHours(dt, num);
}

export function DateAddDays(dt:Date, num)
{
	return addDays(dt, num);
}

export function DateAddMonths(dt:Date, num)
{
	return addMonths(dt, num);
}

export function DateStartOfDay(dt:Date) : Date
{
	return startOfDay(dt);
}

export function NumWeeksInMonth(dt:Date) : Number
{
    return getWeeksInMonth( dt );
}



export function DateStartOfWeek(da:Date) : Date
{
	return startOfWeek(da, { weekStartsOn: 1 });
}




export function DateStartOfMonth(dt)
{
	return startOfMonth(dt);
}

export function DateEndOfMonth(dt) 
{
	return endOfMonth(dt);
}

// zero base
export function DateDayOfWeekInd(dt)
{
	return parseInt( format(dt, 'i') ) - 1;
}

export function DateMonth(dt)
{
	return parseInt( format(dt, 'MM') );
}

export function DateYear(dt)
{
	return parseInt( format(dt, 'yyyy') );
}



//--------------------------------------------

export function FmtDay(dt:Date)
{
	return format(dt, 'dd');
}

export function FmtDayOfWeek(dt:Date)
{
	return format(dt, 'EEEEEE');
}
 
export function FmtMonthAndYear(dt:Date)
{
	return format(dt, 'LLLL yyyy');
}


//----------------------------

export function DateStrIsoToLoc(str_iso)
{
    if (str_iso === null) return null;
	let dt = DateParseIso(str_iso);
	if (dt === null) return null;
	return DateStrLoc(dt);
}

export function DateStrNowIso()
{
	return format(new Date(), 'yyyy-MM-dd');
}

export function DateFmtYesterIso()
{
    return format(addDays(new Date(), -1), 'yyyy-MM-dd');
}


export function DateStrNowLoc()
{
	return format(new Date(), 'dd.MM.yyyy');
}

export function DateStrIso(dt)
{
    if (dt === null) return null;
	return format(dt, 'yyyy-MM-dd');
}

export function DateStrLoc(dt)
{
	//console.log('DateStrLoc ', dt);
	if (dt === null) return null;
	return format(dt, 'dd.MM.yyyy');
}

export function DateTimeStrLoc(dt)
{
	//console.log('DateStrLoc ', dt);
	if (dt === null) return null;
	return format(dt, 'dd.MM.yyyy HH:mm:ss');
}


export function DateTimeStrLocHoursMinutes(dt)
{
	//console.log('DateStrLoc ', dt);
	if (dt === null) return null;
	return format(dt, 'dd.MM.yyyy HH:mm');
}



// as int
export function DateTimeGetHours(dt)
{
	//console.log('DateStrLoc ', dt);
	if (dt === null) return null;
	const s = format(dt, 'HH');
    return parseInt(s);
}

export function DateFmtDayMonth(dt)
{
	//console.log('DateStrLoc ', dt);
	if (dt === null) return null;
	return format(dt, 'dd.MM').toUpperCase();
}



export function DateFmtLocHum(dt)
{
	//console.log('DateStrLoc ', dt);
	if (dt === null) return null;
	return format(dt, 'dd.MM EEEEEEE').toUpperCase();
}


export function DateTimeStrLocHum(dt)
{
	//console.log('DateStrLoc ', dt);
	if (dt === null) return null;
	return format(dt, 'dd.MM.yyyy EEEEEE HH:mm ');
}


export function DateTimeStrLocHumSho(dt)
{
	//console.log('DateStrLoc ', dt);
	if (dt === null) return null;
	return format(dt, 'EEEEEE dd.MM - HH:mm ').toUpperCase();
}



export function DateIsSame(dt1, dt2)
{
    const s1 = DateStrIso(dt1);
    const s2 = DateStrIso(dt2);
    return s1 === s2;
}



export function DateTimeStrIso(dt)
{
	//console.log('DateTimeStrIso ', dt);
	if (dt === null) return null;
	return format(dt, 'yyyy-MM-dd HH:mm:ss');
}






export function DateTimeParseIso(str)
{
	const r = parse(str, 'yyyy-MM-dd HH:mm:ss', new Date());
	if (isValid(r)) return r;
	return null;
}


export function DateParseIso(str)
{
    if (str === null) return null;
	const r = parse(str, 'yyyy-MM-dd', new Date());
	if (isValid(r)) return r;
	return null;
}

export function DateParseLoc(str)
{
	const r = parse(str, 'dd.MM.yyyy', new Date());
	if (isValid(r)) return r;
	return null;
}


export function DateIsoParseYear(str)
{
	const dt = DateParseIso(str);
	const y = parseInt(format(dt, 'yyyy'));
	return y;
}



