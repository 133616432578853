import React from 'react';

import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import { DaIconBtn } from './icon_btn';

//--------------------------

function __clamped(v,min,max)
{
    if (min !== undefined && v < min) v = min;
    if (max !== undefined && v > max) v = max;
    return v;
}



// value - int
function DaSwitcherNumberImp( {value, min, max, name, onChange, sx, disabled, label} )
{
    const [isFoc, setIsFoc] = React.useState( false );
    const [buff, setBuff]   = React.useState( '' );
    
    let myRef = React.createRef();
    
    const cb_left = () =>
    {
        let v = __clamped(value-1,min,max);
        if (isFoc) myRef.current.blur();
        onChange(name,v);
    };
    
    const cb_right = () =>
    {
        let v = __clamped(value+1,min,max);
        if (isFoc) myRef.current.blur();
        onChange(name,v);
    };
    
	const on_kd = (ev) =>
	{
		if (isFoc === false) return;
		if (ev.code == 'Enter')
		{
			//console.log('BLUR ON ESC !');
			myRef.current.blur();
		}
	};
    
    function __finish()
    {
        //console.log(' finish !');
        let v = parseInt( buff );
        //console.log('  parsed: ', v);
        
        if ( isNaN(v) )
        {
            //console.log('its nan !');
            v = 0;
        }
        
        v = __clamped(v,min,max);
        
        onChange( name, v );
    }

    
    const cb_chg_text = (e) => {
    
        setBuff( e.target.value );
    };
    
    const cb_on_foc = () => {
        
        //console.log('FOCS !');  
        setIsFoc( true );
        setBuff( ''+value );
    };
    
    const cb_on_blur = () => {
        
        //console.log('BLUR !');  
        setIsFoc( false );
        
        __finish();
    };
     
    	
    
    
    
    const dis_left  = disabled || (min !== undefined && value <= min);
    const dis_right = disabled || (max !== undefined && value >= max);
    
    const disp_val = isFoc ? buff : ''+value;
    
    
    const p_inp = ( <input disabled={disabled} ref={myRef} style={{textAlign: 'center'}} className='my_inp' type='text' value={disp_val} onChange={cb_chg_text} onFocus={cb_on_foc} onBlur={cb_on_blur} onKeyDown={ on_kd }/> );
    
    let p_juice;
    
    if (label !== undefined)
    {
        p_juice = <div style={{position: 'relative', width: '100%', height: '100%', display: 'grid', gap: '10px', alignItems: 'center', gridTemplateColumns: 'max-content 1fr', gridTemplateRows: '1fr'}}>
                   <div style={{userSelect: 'none', color: '#404050', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{label}</div>
                    {p_inp}
				</div>;
    }
    else
    {
        p_juice = p_inp;
    }
    
    
    return (<div className={isFoc ? "yy_inp_frame focus" : "yy_inp_frame"} style={{...sx, background: '#FFFFFF', padding: '4px', height: '32px', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <DaIconBtn icon={ArrowLeftIcon} disabled={dis_left} onClick={cb_left} />
				{p_juice}
                <DaIconBtn icon={ArrowRightIcon} disabled={dis_right} onClick={cb_right} />
			</div>);
}


export const DaSwitcherNumber = React.memo( DaSwitcherNumberImp );
