import React from 'react';

import * as DEFS from '../defs.js';
import * as HLP from  '../helpers.js';

import {  ShowErr, ShowMsg  } from '../misc_glob.js';

import { Win2Confirm } from '../misc/win2_confirm.js';

import * as DT from '../dt.js';

import { DaCheck } from '../misc/check.js';

import { LayVert2, LayHoriz2 } from '../misc/layout.js';



import { DaButton } from '../misc/but.js';

import { Win2 } from '../misc/win2.js';

import { WinNewCust } from '../desktop//win_new_cust.js';

import { NuPost2 } from '../api.js';

import { CommonReducer } from '../misc_glob.js';

import { DaCombo } from '../misc/combo.js';
import { DaDateField } from '../misc/date_field.js';
import { DaSearchBar } from '../misc/search_bar.js';


import { CmCachedCustLink } from '../desktop/ca_cust_link.js';


import { Grid3 } from '../grid3.js';

import { useApiQuery2 } from '../api_stuff.js';

import Slider from '@mui/material/Slider';
import Badge from '@mui/material/Badge';


import FilterAltIcon from '@mui/icons-material/FilterAlt';

import { ClubListCombo } from '../desktop/club_list_combo.js';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CheckIcon from '@mui/icons-material/Check';

import { useGlobalShit } from '../v3/hk_global_shit.js';

import { useCuCaMgrState } from '../menu2/hk_cuca_mgr_state.js';

//------------------------------------

const gCliGendFlt = [

    {k:-1, v: 'ВСЁ'},
    {k: DEFS.CU_GENDER_MALE, 		v: 'МУЖЧИНА'},
    {k: DEFS.CU_GENDER_FEMALE,	 	v: 'ЖЕНЩИНА'},

];



const gCliStatusFlt = [

    {k:-1, v: 'ВСЁ'},
    {k: 0, v: 'АКТИВЕН'},
    {k: 1, v: 'ОТКАЗ'},

];





function MyColReg(pp)
{
	const v = pp.value;
    if (v === null) return <div className="faint">---</div>;
    return DT.DateStrLoc( DT.DateTimeParseIso(v) )
}


//--------------------------------------------------------



function MyColOrigImp(pp)
{
	
	const { isFetching, isLoading, isError, data, error } = useApiQuery2( 976, {} );
	
	if (isError) return <div className="yy_error">{error}</div>;
	
	if (isLoading || isFetching)
	{
		return null;
	}
	
	let vv = pp.value;
	vv = parseInt(vv);
	
	let f = HLP.ArrayOfDictsFindByPropValue(data.rows, 'lso_id', vv);
	if (f === null) return <div className="yy_error">УДАЛЁН {vv}</div>;
	
	return f.lso_val;
}

const MyColOrig = React.memo( MyColOrigImp );

//--------------------------------------



function MyColCustImp(pp)
{
	return <CmCachedCustLink  plain  plain_clr='#405070' rid={pp.row.cu_id}  />
}

const MyColCust = React.memo( MyColCustImp );

//--------------------------------


function MyColIconsImp(pp)
{
	return <CmCachedCustLink rid={pp.row.cu_id} noname no_com extra_icons={true} />;
}

const MyColIcons = React.memo( MyColIconsImp );

//----------------------------------


function MyColAgeImp(pp)
{
	const dt = DT.DateParseIso( pp.row.cu_dob );
	
	if ( DT.DateYear(dt) < 1901)
	{
		return <div className="faint">???</div>;
	}
	
	const v = DT.YearsDiff(dt, new Date());
	
	return v;
}

const MyColAge = React.memo( MyColAgeImp );




const gNuCols = [

    {fld: 'cu_id',              name: 'ID',                  align: 'center',     sort: true, width: 90},
    {fld: 'cu_reg_date',        name: 'Рег',                 align: 'center',    sort: true, width: 120, renderCell: MyColReg},
    {fld: 'cu_name',            name: 'ФИО',                 align: 'left',     sort: true, width: 350, renderCell: MyColCust},
    {fld: 'cu_phone',           name: 'Телефон',             align: 'left',     sort: true, width: 180},
    {fld: 'cu_origin',          name: 'Источник',            align: 'left',     sort: true, width: 210, renderCell: MyColOrig},
    {fld: 'num_active_payments',           name: 'Акт.абон',             align: 'center',     sort: true, width: 120},
    {fld: 'v_age',           name: 'Возраст',             align: 'center',     sort: true, width: 110,  renderCell: MyColAge},
    {fld: 'v_misc',          name: 'Штучки',              align: 'left',     sort: false, width: 170,  renderCell:  MyColIcons},
];


//----------------------



function WinFltAgeBody( {onOk, min, max} )
{
	const [rng, setRng] = React.useState( [ min, max] );
	const [act, setAct] = React.useState( min === -1 ? 0 : 1 );
	
	function cb_submit()
	{
		if (act)	onOk( rng );
		else		onOk( [-1, -1] );
	}
	
	function cb_chg_age(kk, vv)
	{
		setRng(vv);
	}
	
	function cb_chg_act(k,v)
	{
		//console.log('CHG ACT ', v);
		
		if (v > 0)
		{
			//console.log('RNG NOW ', rng);
			
			if (rng[0] === -1)
			{
				//console.log('setup range...');
				setRng( [3, 60 ] );
			}
		}
		
		setAct( v );
	}
	
	const s_vals = (act > 0) ? ('от ' + rng[0] + ' до ' + rng[1]) : '';
    
    return (
        
        <LayVert2 rows='1fr max-content max-content' padding='10px'>
        
            <div style={{fontSize: '18px', height: '22px'}}>{s_vals}</div>
        
            <LayHoriz2 cols='50px 1fr' padding='20px' gap='10px' >
                <DaCheck value={act} onChange={cb_chg_act} />
            
                <Slider disabled={act === 0} disableSwap min={1} step={1} max={70} value={ rng } onChange={cb_chg_age}  />
        
            </LayHoriz2>
        
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <DaButton icon={CheckIcon} onClick={ cb_submit } title='ПРИМЕНИТЬ' />
            </div>
        </LayVert2 >
    
    );
}


function WinFilterAgeRange( {visible, min, max, onOk, onClose})
{	
	return (
			<Win2 visible={visible} title='ФИЛЬТР ПО ВОЗРАСТУ' width={700} height={200}  onClose={onClose} >
                <WinFltAgeBody onOk={onOk} min={min} max={max} />
            </Win2>
            
            );
}


//--------------------------------------



const gInitSta = {
	
	busy:       false,
    ser:        0,
    selected:   [],
    
    pop_new_cust:       false,
    pop_age_flt:        false,
    pop_del_cust_id:    -1,
    
    flt_club: -1,
    flt_age_min: -1,
    flt_age_max: -1,
    flt_na: '',
    flt_r_dt_f: null,
    flt_r_dt_t: null,
    flt_sta: -1,
    flt_gend: -1,
};



// РАЗДЕЛ КЛИЕНТЫ
export function TabCustomers(pp)
{
    const [sta, dispatch] = React.useReducer( CommonReducer, gInitSta );
    

    const sss = useGlobalShit();
    
    const CCM = useCuCaMgrState();
    
	
	const cb_chg_sel = React.useCallback( (nu) => {
		//console.log('CHG SEL ', nu);
        dispatch( { type: 'val', k: 'selected', v: nu } );
        
	}, [sta.selected] );
	
    
	const cb_chg = React.useCallback( (k,v) => {
		dispatch( { type: 'val', k:k, v:v } );
	}, []);

	
	
	const cb_row_dbl = React.useCallback( (kk) => {
		const ID =  sta.selected[0];
		//CC_Open( ID );

        CCM.tryOpen( ID );
        
	}, [sta.selected] );
	
    
	
	const cb_close_pop_cust = React.useCallback( () => {
		
		dispatch( { type: 'val', k: 'pop_new_cust', v: false } );
		
	}, [] );
    
    const cb_added_cust = React.useCallback( () => {
        
        ShowMsg('СОЗДАН !');
    
        dispatch( { type: 'val', k: 'pop_new_cust', v: false } );
        dispatch( { type: 'val', k: 'ser', v: sta.ser+1 } );
        dispatch( { type: 'val', k: 'selected', v: [] } );
        
    }, [sta.ser, sta.pop_new_cust] );
	
	const cb_add = React.useCallback( () => {
		
        dispatch( {type: 'val', k: 'pop_new_cust', v: true });
		
	}, [] );
	
	
    const cb_close_pop_del = React.useCallback( () => {
        
        dispatch( { type: 'val', k: 'pop_del_cust_id', v: -1 } );
        
    }, [] );
    
    
    const cb_del_conf = React.useCallback( () => {
        
    
        const __ok = (e) => {
            
            ShowMsg('УДАЛЁН !');
            
            dispatch( { type: 'val', k: 'ser', v: sta.ser+1 } );
            dispatch( { type: 'val', k: 'busy', v: false } );
            dispatch( { type: 'val', k: 'pop_del_cust_id', v: -1 } );
            dispatch( { type: 'val', k: 'selected', v: [] } );
            
        };
        
        const __fail = (e) => {
            
            ShowErr(e.m);
            dispatch( { type: 'val', k: 'busy', v: false } );
        };
        
        dispatch( { type: 'val', k: 'busy', v: true } );
        
        NuPost2( 797, {cu_id: sta.pop_del_cust_id}, __ok, __fail ); 

        
        
    }, [sta.pop_del_cust_id, sta.ser] );
    
	const cb_del = React.useCallback( () => {
		
		const ID = sta.selected[0];

        dispatch( { type: 'val', k: 'pop_del_cust_id', v: ID } );
		
	}, [sta.selected, sta.ser] );
	
	
	function cb_show_flt_age()
	{
        dispatch( { type: 'val', k: 'pop_age_flt', v: true } );
	}
	
	function cb_close_pop_flt()
	{
        dispatch( { type: 'val', k: 'pop_age_flt', v: false } );
	}
	
    function cb_flt_age_ok(e)
    {
        dispatch( { type: 'vals', dct: {pop_age_flt: false,
                                        flt_age_min: e[0],
                                        flt_age_max: e[1]  } } );

    }
    


	
	const CLA 		= sta.busy ? 'zz_busy' : null;

    const DIS_ADD = !sss.userHasAbil( 100 );
    const DIS_DEL = ( !sss.userHasAbil( 102 ) ) || sta.selected.length < 1;

    const FLT_GRID = React.useMemo( () => {
         
        const t = {}; 
        HLP.DictCopyFieldsWithPrefix( t, sta, 'flt_' );

        t.r_dt_f = DT.DateStrIso( t.r_dt_f );
        t.r_dt_t = DT.DateStrIso( t.r_dt_t );
        
        return t;
        
    }, [sta.ser, sta.flt_club, sta.flt_age_min, sta.flt_age_max, sta.flt_na, sta.flt_r_dt_f, sta.flt_r_dt_t, sta.flt_sta, sta.flt_gend] );

	return (
				<>
                    <LayVert2 className={CLA}  rows='max-content 1fr' padding='20px' gap='16px' >
				

						<div style={{alignItems: 'center', display: 'flex', gap: '10px' }}>
							<DaButton icon={AddIcon}    onClick={ cb_add } disabled={DIS_ADD} title='НОВЫЙ' />
							<DaButton icon={RemoveIcon} onClick={ cb_del } disabled={DIS_DEL} title='УДАЛИТЬ' />
							
			
                            <div style={{width: '250px', paddingLeft: '10px', paddingRight: '10px'}}>
                                <DaSearchBar name='flt_na' value={sta.flt_na} onChange={cb_chg} label='имя / телефон / #ID'  />
                            </div>
		
                            <div style={{display: 'flex', gap: '6px', width: '340px'}}>
                                <DaDateField name='flt_r_dt_f' label='Регистр от' value={sta.flt_r_dt_f} onChange={ cb_chg }  />
                                <DaDateField name='flt_r_dt_t' label='Регистр до' value={sta.flt_r_dt_t} onChange={ cb_chg }  />
                            </div>
        
                            <div style={{display: 'flex', gap: '6px', width: '260px'}}>
                                <DaCombo  label='СТАТУС' name='flt_sta'  onChange={cb_chg} src={gCliStatusFlt}  fld_k='k' fld_v='v'  value={sta.flt_sta}   /> 
							
                                <DaCombo  label='ПОЛ' name='flt_gend' onChange={cb_chg} src={gCliGendFlt}    fld_k='k' fld_v='v'  value={sta.flt_gend}  /> 
								
                            </div>

                            <div style={{width: '180px'}}>
                               <ClubListCombo  label='КЛУБ' name='flt_club' value={sta.flt_club} onChange={cb_chg} />
                            </div>	

							<Badge size='small' badgeContent="!" color="primary" invisible={sta.flt_age_min === -1} >
								<DaButton onClick={cb_show_flt_age} icon={FilterAltIcon} title='ВОЗРАСТ' />
							</Badge>	
							
						</div>

		
                        <Grid3 singleSel onDblClick={cb_row_dbl} onChangeSel={cb_chg_sel} 
                        selected={sta.selected} flt={FLT_GRID}
                                    cols={gNuCols} isNuApi apiCmd={790} uniq='cu_id' 
                                    sort_field='cu_reg_date' sort_dir='desc' code='cl_custs' />
                                         
				
						
						
					</LayVert2>
					
					<WinNewCust visible={sta.pop_new_cust} onOk={cb_added_cust} onClose={cb_close_pop_cust} />
                    
                    <WinFilterAgeRange visible={sta.pop_age_flt}    min={sta.flt_age_min}
                                                                    max={sta.flt_age_max}     
                                                        onClose={cb_close_pop_flt} onOk={cb_flt_age_ok} />
                                                        
                                                        
                    <Win2Confirm busy={sta.busy} visible={sta.pop_del_cust_id !== -1}
                                        title='УДАЛИТЬ КЛИЕНТА ?'
                                        onClose={cb_close_pop_del}
                                        onOk={cb_del_conf} />
					
					</>
	
	);
}




