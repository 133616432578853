import React from 'react';
import { Win2 } from './win2.js';

import { LayVert2 } from './layout.js';

import { DaButton } from './but.js';

import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import CheckIcon from '@mui/icons-material/Check';

import { OverlayLoading } from './ovr_loading.js';


//--------------------------------------------------------


function MyBody( {onOk, onClose, children} )
{
    return (
                <LayVert2 rows='1fr max-content' sx={gSt1}>
                    <div style={{padding: '10px', fontWeight: 800}}>
                        {children}
                    </div>
                    
                    <div style={{display: 'flex', gap: '30px', justifyContent: 'center', alignItems: 'center'}}>
                        <DaButton onClick={onClose}  title='НЕТ' icon={ DoDisturbIcon } />
                        <DaButton onClick={onOk}  title='ДА'  icon={ CheckIcon } />
                    </div>
                
                </LayVert2>
                
    );
}

// бланк поп -   ДА / НЕТ
function Win2ConfirmImp( {visible, busy, title, onClose, onOk, children} )
{
    const HGT = !children ? 140 : 200;
    
    return ( <Win2 visible={visible} title={title} width={500} height={HGT} >
    
                <MyBody onOk={onOk} onClose={onClose} children={children} />
                <OverlayLoading active={busy} />
                
    
            </Win2> );

}


export const Win2Confirm = React.memo( Win2ConfirmImp );



const gSt1 = {padding: '20px', gap: '10px'};
