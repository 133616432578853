import React from 'react';

import * as DEFS from '../defs.js';
import * as COM_DEFS from '../com_defs.mjs';
import * as HLP from '../helpers.js';


import { ComboProdAbonList } from '../desktop/combo_prod_abon.js';

import { Win2Confirm } from '../misc/win2_confirm.js';

import { CmComColCellDate, CmComColPayStatus, CmComColCellDateTimeHoursMinutes } from '../cm_table_cell_com.js';

import { DaSearchBar } from '../misc/search_bar.js';

import { DaCheck } from '../misc/check.js';

import { CaAbonLeftovers } from '../abons/ca_abon_leftovers.js';

import { DaDateField } from '../misc/date_field.js';
import { DaCombo } from '../misc/combo.js';

import { DaButton } from '../misc/but.js';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import { Grid3 } from '../grid3.js';

import { NuPost2 } from '../api.js';

import { ShortName } from '../desktop/short_name.js';

import { LayVert2, LayHoriz2, LaySpace } from '../misc/layout.js';


import { CmCachedCustLink } from '../desktop/ca_cust_link.js';


import { useGlobalShit } from '../v3/hk_global_shit.js';

import { CommonReducer, ShowMsg, ShowErr } from '../misc_glob.js';

import * as DT from '../dt.js';

import { WinEditAbon } from '../desktop/win_abon.js';


import { useUserOpts, useUserOptsDispatch } from '../hk_user_opts.js';

import { HowPayedInfo } from '../desktop/how_payed.js';

//------------------------------------





const gRowsStatusFlt = [

    {  k: -1,  v: 'ВСЕ' },
    {  k:  0,  v: 'ОПЛАЧЕН' },
    {  k:  1,  v: 'АКТИВЕН' },
    {  k:  2,  v: 'ЗАКРЫТ' },

];




function MyColCust(pp)
{
	return <CmCachedCustLink rid={pp.row.cu_id}  />
}


function MyColProdsImp(pp)
{
    return <ShortName ctx={DEFS.SHORT_NAME_ABON} id={pp.row.pay_id} />;
}

const MyColProds = React.memo( MyColProdsImp );

//--------------------------------------


function MyColLeftImp(pp)
{
	const r = pp.row;
	if (r.pay_logic === COM_DEFS.ABON_LOGIC_MONTHLY) return <div>∞</div>;
    return <CaAbonLeftovers rid={r.pay_id} />
}

const MyColLeft = React.memo( MyColLeftImp );

//--------------------------------

function MyColUsableImp(pp)
{
	const v = pp.value;
	if (v === null) return <div className="faint">---</div>;
	return <div>{ DT.DateStrLoc( DT.DateTimeParseIso(pp.value) ) }</div>;
}

const MyColUsable = React.memo( MyColUsableImp );

//----------


function MyColPriceImp(pp)
{
    if (pp.value < 1) return <div className="faint">{pp.value}</div>;
    return pp.value;
}

const MyColPrice = React.memo( MyColPriceImp );

//---------------------------

function MyMethImp(pp)
{
    const ROW = pp.row;
    return <HowPayedInfo bindCtx={COM_DEFS.PAY_OPS_CTX_ABON} bindId={ROW.pay_id}/>;
}

const MyMeth = React.memo(MyMethImp);

//---

function MyColBoAmo(pp)
{
    if (pp.value < 1) return '';
    return pp.value;
}


const gNuCols = [

    {fld: 'pay_id',         name: 'ID',                 align: 'center',    sort: true, width: 110},
    {fld: 'cu_name',        name: 'Клиент',             align: 'left',      sort: true, width: 290, renderCell: MyColCust},
    {fld: 'v_prods_fake',    name: 'Продукт',            align: 'left',      sort: true, width: 280, renderCell: MyColProds},
    {fld: 'pay_price',      name: 'Итог.Цена',               align: 'left',      sort: true, width: 90, renderCell: MyColPrice},
    {fld: 'v_meth_fake',      name: 'Как платил ?',           align: 'left',      sort:false, width: 160, renderCell: MyMeth},
    
    {fld: 'pay_pay_time',      name: 'Покупка',         align: 'center',    sort:true, width: 130, renderCell: CmComColCellDateTimeHoursMinutes},
    {fld: 'pay_start_time',      name: 'Старт',         align: 'center',    sort:true, width: 120, renderCell: CmComColCellDate},
    
    {fld: 'cnt_left',       name: 'Осталось',           align: 'left',      sort:false, width: 116, renderCell: MyColLeft},
    
    {fld: 'pay_status',       name: 'Статус',           align: 'left',      sort:true, width: 134, renderCell: CmComColPayStatus},
    
    {fld: 'v_bonus_got',       name: 'Бонусов получил',           align: 'center',      sort:false, width: 150, renderCell: MyColBoAmo},
    {fld: 'pay_usable_dt_s',      name: 'Действ. от',         align: 'left',    sort:true, width: 140, renderCell: MyColUsable},
    {fld: 'pay_usable_dt_e',      name: 'Действ. до',         align: 'left',    sort:true, width: 140, renderCell: MyColUsable},
];








function GenInitState( dummy )
{
    const dt = new Date();
    
    return {
        // flt
        name: '', date_from: dt, date_to: dt, status: -1,  prod: -1,
        
        serial: 0,
    
                
        vis_money	: 0,
        pop			: 0,
        busy		: false,
        
        selected	: [],
        
        show_abon_id:   null,
        
        
        dct_pop_conf:   {},
        dct_pop_abon:   {},
    };
}




// РАЗДЕЛ - ПРОДАЖИ АБОНЕМЕНТОВ
export function TabPays(pp)
{
	const [sta, dispatch]	= React.useReducer( CommonReducer, {}, GenInitState );
	
    
    const h_user_opts = useUserOpts();
    const disp_opts2  = useUserOptsDispatch();
    const sss         = useGlobalShit();
	
	
	const cb_chg = React.useCallback( (k,v) =>
	{
		dispatch( {type: 'val', k:k, v:v } );
	}, []);
	
    
    const cb_chg_opt2 = (k,v) => {
      
        disp_opts2( {type: 'val', k:k, v:v} );
    };
	
	
	function cb_open_new()
	{
		//console.log('NE 1');
		dispatch( {type: 'val', k:'pop', v:4} );
        dispatch( {type: 'val', k:'show_abon_id', v:-1} );
	}
    
	function cb_canc_pop()
	{
		dispatch( {type: 'val', k:'pop', v:0} );
	}
	
	
	function cb_del()
	{
        
		const SEL = sta.selected[0];
		
		const cb_confirm_del = () => {
		
            dispatch( {type: 'val', k:'pop', v:0} );
            dispatch( {type: 'val', k:'busy', v:false} );
            
            const __ok = (e) => {
				
				dispatch( {type: 'val', k:'busy', v:false} );
				dispatch( {type: 'val', k:'selected', v:[]} );
				dispatch( {type: 'val', k:'serial', v:sta.serial+1} );
				
				ShowMsg('УДАЛЕНО !');
			};
			
            const __fail = (e) => {
				
				dispatch( {type: 'val', k:'busy', v:false} );
				ShowErr('FAIL ! ', e.m);
			};
			
			dispatch( {type: 'val', k:'busy', v:true} );
            
            NuPost2( 796, {pay_id: SEL}, __ok, __fail);
		};
        
        
        const TTT = {title: 'ТОЧНО УДАЛИТЬ ?', onOk: cb_confirm_del, onClose: cb_canc_pop};
        
        dispatch( {type: 'val', k:'dct_pop_conf', v:TTT} );
		dispatch( {type: 'val', k:'pop', v:3} );
	}
	
	function cb_chg_sel(nu)
	{
		dispatch( {type: 'val', k:'selected', v:nu} );
	}
	
	function cb_created_pay()
	{
		dispatch( {type: 'val', k:'serial', v:sta.serial+1} );
        dispatch( {type: 'val', k:'pop', v:0} );
	}
	
	function cb_edited_pay()
	{
		dispatch( {type: 'val', k:'serial', v:sta.serial+1} );
        dispatch( {type: 'val', k:'pop', v:0} );
	}
	
	function cb_row_dbl(kk)
	{
        if ( !sss.userHasAbil(402) )
        {
            //console.warn('no...');
            return;
        }
		dispatch( {type: 'val', k:'show_abon_id', v:kk} );
        dispatch( {type: 'val', k:'pop', v:4} );
	}
	
    
    function cb_hide_abon_win()
    {
        dispatch( {type: 'val', k:'pop', v:0} );
    }
    



	
	
	const FLT = React.useMemo( () => {
		
		return { no_free: h_user_opts.pays_view_no_free, no_hidden: 1,
			name:       sta.name,
            prod:       sta.prod,
			date_from:  DT.DateStrIso(sta.date_from), 
			date_to:    DT.DateStrIso(sta.date_to),
			status:     sta.status };
		
	}, [sta.prod, sta.name, sta.date_from, sta.date_to, sta.status, sta.serial, h_user_opts.pays_view_no_free] );
	
    
    
    //const p_left_footer = <MyFootInfo money={sta.vis_money} />;
    
    const DIS_NEW = (!sss.userHasAbil(400));
    const DIS_DEL = (!sss.userHasAbil(401)) || (sta.selected.length < 1);
    
    
    const win_cb = sta.show_abon_id === -1 ? cb_created_pay : cb_edited_pay;
    
    
	return (<>	
                <LayVert2 className={sta.busy ? 'zz_busy' : null}  rows='max-content 1fr' padding='20px' gap='16px' >
                
					<LayHoriz2 cols='max-content max-content 14px 260px 160px 160px 20px 110px 180px 1fr' gap='10px'  >
						<DaButton icon={AddIcon}    onClick={ cb_open_new }   disabled={DIS_NEW} title='НОВЫЙ' />
						<DaButton icon={RemoveIcon} onClick={ cb_del }        disabled={DIS_DEL} title='УДАЛИТЬ' />
						
                        <LaySpace />
                        
                        <DaSearchBar name='name' value={sta.name} label='имя клиента / #номер абона' onChange={cb_chg}   />
						
            
                        <DaDateField label='покупка от' name='date_from' onChange={cb_chg}  value={sta.date_from} />				
                        <DaDateField label='покупка до' name='date_to'   onChange={cb_chg}  value={sta.date_to} />
                      
                        <LaySpace />
                      
                        <DaCombo label='СТАТУС' name='status' onChange={cb_chg} src={gRowsStatusFlt}    fld_k='k' fld_v='v'  value={sta.status}  /> 
                        
                        <ComboProdAbonList label='ПРОДУКТ' name='prod'  value={sta.prod} onChange={cb_chg}  />

                        <DaCheck value={h_user_opts.pays_view_no_free} label='БЕЗ БЕСПЛАТНЫХ' onChange={cb_chg_opt2} name='pays_view_no_free' />

					</LayHoriz2>
					

	
                    <Grid3 singleSel onDblClick={cb_row_dbl} onChangeSel={cb_chg_sel}  selected={sta.selected} flt={FLT}
                                    cols={gNuCols} isNuApi apiCmd={795} uniq='pay_id'  sort_field='pay_pay_time' sort_dir='desc' code='cl_payments' />
				
				
				</LayVert2>

                <WinEditAbon rid={sta.show_abon_id} visible={sta.pop === 4} onClose={cb_hide_abon_win} onOk={win_cb} />
				
                <Win2Confirm busy={sta.busy} visible={sta.pop === 3} {...sta.dct_pop_conf} />
                
				</>
			);
}



function MyFootInfoImp( {money} )
{
    return <div style={{fontWeight: 800, marginLeft: '10px', padding: '2px', color: '#309030'}}>КАССА: {money}р</div>;
}

const MyFootInfo = React.memo( MyFootInfoImp );


//-----------------------------------------------------------------



