import React from 'react';

import * as DEFS from '../defs.js';
import * as HLP from '../helpers.js';

import * as DT from '../dt.js';

import { WinHelpConta, WinHelpElem } from '../misc/win_help.js'



import { CmDaSwitcher } from '../cm_mui.js';

import {DaIconBtn} from '../misc/icon_btn.js'; 

import { DaSearchBar } from '../misc/search_bar.js';

import { DaDateField } from '../misc/date_field.js';

import {  ShowMsg, ShowErr } from '../misc_glob.js';

import { NuPost, NuPost2 } from '../api.js';

import { DaButton } from '../misc/but.js';
import { DaTextField } from '../misc/text_field.js';

import { BottomButtonsPanel } from '../cust_card/bottom_pan.js';


import { OverlayLoading } from '../misc/ovr_loading.js';

import { LayVert2, LayHoriz2, LaySpace } from '../misc/layout.js';
import {DaVertScrollFrame, DaVertScrollJuice } from '../misc/vscr.js';

import { Win2 } from '../misc/win2.js';


import Collapse from '@mui/material/Collapse';

import CloseIcon from '@mui/icons-material/Close';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';

//------------------------------------


const gHelp1 = (<WinHelpConta>
                    <WinHelpElem tit='ЧТО ЭТО' text={ ['Выбранные товары будут добавлены в "сток". При удалении - исчезнут.'] } />
                    <WinHelpElem tit='ДАТА' text={ ['К какой дате привязать поставку.'] } />
                    <WinHelpElem tit='ЛЕВЫЙ СТОЛБИК' text={ ['Все доступные позиции товаров.'] } />
                    <WinHelpElem tit='ПРАВЫЙ СТОЛБИК' text={ ['Выбранные товары.'] } />
                </WinHelpConta>);
                


// Редакт поставки товаров
export function WinGoodsIncome( {visible, rid, onClose, onOk} )
{
    return (
        <Win2 help={gHelp1} visible={visible} width={950} height={500} title={rid===-1?'НОВАЯ ПОСТАВКА':'РЕДАКТИРОВАТЬ ПОСТАВКУ'} onClose={onClose} >
            <MyShit rid={rid} onOk={onOk} />
        </Win2>
        );
}

//------------------------

function __gen_state( rid )
{
	return {
		busy: false,
		cats: [],
		prods: [],
		is_init: false,
		got_ro: rid === -1 ? true : false,
		ar_id:	[],
		ar_cnt: [],
		comm: '',
		search: '',
		dt: new Date(),
	};
	
};


function __reducer(sta, act)
{
	switch(act.type)
	{
		case 'val':
		{
			return { ...sta, [act.k]: act.v };
		}
		
		case 'chg':
		{
			const id = act.id;
			const am = act.am;
			const at = sta.ar_id.indexOf(id);
			if (at === -1) return sta;
			let nu = { ...sta };
			nu.ar_cnt = HLP.DeepCopy( nu.ar_cnt );
			nu.ar_cnt[at] = am;
			return nu;
		}
		
		case 'add':
		{
			const id = act.id;
			
			if (sta.ar_id.indexOf(id) !== -1) return sta;
			
			let nu = { ...sta };
			
			nu.ar_id = HLP.DeepCopy( nu.ar_id );
			nu.ar_cnt = HLP.DeepCopy( nu.ar_cnt );
			
			nu.ar_id.push( id );
			nu.ar_cnt.push( 1 );
			
			return nu;
		}
		
		case 'remove':
		{
			const id = act.id;
			
			let i = sta.ar_id.indexOf( id );
			if (i === -1) return sta;
			
			let nu = { ...sta };
			
			nu.ar_id = HLP.DeepCopy( nu.ar_id );
			nu.ar_cnt = HLP.DeepCopy( nu.ar_cnt );
			
			nu.ar_id.splice( i, 1 );
			nu.ar_cnt.splice( i, 1 );
			
			return nu;
		}
		
		case 'got':
		{
			const e = act.e;
            
			let nu = { ...sta };
			
			nu.ar_id  = e.ro.v_ids;
			nu.ar_cnt = e.ro.v_cnt;
			nu.comm   = e.ro.pgi_comment;
			nu.dt     = DT.DateParseIso( e.ro.v_date );
			nu.got_ro = true;
            
			return nu;
		}
		
		case 'init':
		{
			const e = act.e;
			
			//console.log('INIT ! ', e);
			
			let nu = { ...sta };
			
			nu.cats 	= e.r_cats;
			nu.prods 	= e.r_prods;
			nu.busy     = false;
			nu.is_init	= true;
			
			return nu;
		}
	}
	
	return sta;
}


function MyShit( {rid, onOk} )
{
    const [sta, dispatch] = React.useReducer( __reducer,  rid, __gen_state );
    
	const cb_chg = React.useCallback( (k,v) =>
	{
		dispatch( {type: 'val', k:k, v:v} );
	}, [] );
	
	function cb_submit()
	{
		//console.log('SUBMIT ! ', sta);
		
		
		function __ok(e)
		{
			dispatch( {type: 'val', k: 'busy', v: false } );
			ShowMsg('УСПЕХ !');
			onOk();
		}
		
		function __fail(e)
		{
			dispatch( {type: 'val', k: 'busy', v: false } );
			ShowErr( e.m );
		}
		
		
		dispatch( {type: 'val', k: 'busy', v: true } );
		
		NuPost( 1002, { rid:rid,
						 dt: DT.DateTimeStrIso( sta.dt ),
						 comm:sta.comm.trim(), ar_id: sta.ar_id, ar_cnt:sta.ar_cnt, 
						 }, __ok, __fail );
	}
	
    
	React.useEffect( () => {
		
		{
			function __ok(e)
			{
				dispatch( {type: 'init', e: e } );
			}
			
			function __fail(e)
			{
			}
			
			NuPost2( 961, {}, __ok, __fail );
		}
		
		if (rid !== -1)
		{
			function __ok(e)
			{
				//dispatch( {type: 'init', e: e } );
				
				//console.log('GOT SINGLE SHIT.. ', e);
				
				dispatch( {type: 'got', e:e } );
			}
			
			function __fail(e)
			{
				//dispatch( {type: 'val', k: 'busy', v: false } );
			}
			

			NuPost( 1003, {rid:rid}, __ok, __fail );
		}
		
	}, [] );
	
    
    const lst_le = [];
    const lst_ri = [];
    
    
	if (sta.is_init && sta.got_ro)
	{
		
		const tsearch = sta.search.trim().toLowerCase();
		
		for (let x = 0; x < sta.prods.length; x ++ )
		{
			const ro = sta.prods[x];
			const id = ro.prg_id;
			
			//console.log('RO ', ro);
			
			const ind = sta.ar_id.indexOf(id);
			
			const is_added = ind !== -1;
			
			const is_flt = tsearch !== '' && ro.prg_name.toLowerCase().indexOf(tsearch) === -1;
			
		
			
			const show_in_le = !is_added && !is_flt;
			
			
			lst_le.push( <MyEntrySrc key={id} ro={ro} disp={dispatch} is_vis={show_in_le} /> );


			lst_ri.push( <MyEntryDst key={id} rid={id} cnt={sta.ar_cnt[ind]} all_prods={sta.prods} disp={dispatch} is_vis={is_added} /> );
		}
		
    }
		

    const SHOW_CIRC = (!(sta.is_init && sta.got_ro)) || sta.busy;
    
    const DIS_SUBMIT = sta.ar_id.length < 1;
    
    return (
    
            <LayVert2 rows='max-content 1fr max-content' >
            
                <LayHoriz2 cols='300px 1fr 150px minmax(200px,400px)' gap='16px' padding='16px'>
                
                    <DaSearchBar name='search' value={sta.search} label='ФИЛЬТР ПО НАЗВАНИЮ' onChange={cb_chg} />
                
                    <LaySpace />
                
                    <DaDateField nonull label='ДАТА' name='dt' onChange={cb_chg}  value={sta.dt} />	
                    <DaTextField name='comm' value={sta.comm} label='КОММЕНТ (ПО ЖЕЛАНИЮ)' onChange={cb_chg} /> 

                
                </LayHoriz2>
            
                <LayHoriz2 cols='1fr 1fr' gap='16px' >
                   
                    <DaVertScrollFrame>
                        <DaVertScrollJuice padding='10px'>
                            {lst_le}
                        </DaVertScrollJuice>
                    </DaVertScrollFrame>
    
                    <DaVertScrollFrame>
                        <DaVertScrollJuice padding='10px'>
                            {lst_ri}
                        </DaVertScrollJuice>
                    </DaVertScrollFrame>
                
                </LayHoriz2>
            
                <BottomButtonsPanel>
                    <DaButton onClick={cb_submit} disabled={DIS_SUBMIT || sta.busy}        title={rid === -1 ? 'ДОБАВИТЬ' : 'СОХРАНИТЬ'}  />
                </BottomButtonsPanel>
                
                
                <OverlayLoading active={SHOW_CIRC} />
            
            </LayVert2>
    
    );
}


//----------------------------------



function MyEntrySrcImp( {ro, disp, is_vis} )
{
	const cb_add = React.useCallback( () =>
	{
		disp( {type: 'add', id: ro.prg_id} );
		
	}, []);
	
	return (<Collapse in={is_vis} timeout={80}  >
				<div style={{marginBottom: '6px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '14px', padding: '3px', color: '#405090', background: '#FAFAFF', borderRadius: '4px'}} className="zz_com_border" >
					<span className={ro.prg_disabled ? "red_shit" : null} >{ro.prg_name}</span>
					<DaIconBtn  icon={DoubleArrowIcon} onClick={cb_add} clr='#8080A0'  />
				</div>
			</Collapse> );	
}


const MyEntrySrc = React.memo( MyEntrySrcImp );

//-------------------------------


function MyEntryDstImp( {rid, cnt, all_prods, disp, is_vis} )
{
	function cb_rem()
	{
		disp( {type: 'remove', id: rid} );
	}
	
	function cb_chg(k,v)
	{
		disp( {type: 'chg', id: rid, am: cnt+v} );
	}
	
	const ro = HLP.ArrayOfDictsFindByPropValue(all_prods, 'prg_id', rid);
	
	let nam;
	
	if (!ro)
	{
		nam = <div>ТОВАР УДАЛЁН ({rid})</div>;
	}
	else
	{
		nam = <div>{ro.prg_name}</div>;
	}

	return (
			<Collapse in={is_vis} timeout={80}  >
				<div style={{ marginBottom: '6px', display: 'grid', gridTemplateColumns: '1fr minmax(70px, 130px) max-content', gridTemplateRows: '1fr',  gap: '6px', alignItems: 'center', fontSize: '14px', padding: '4px', color: '#408050', background: '#FAFFFA', borderRadius: '4px'}} className="zz_com_border" >
					{nam}
					<CmDaSwitcher  sx={gSty1} value={cnt} onChange={cb_chg} dis_left={cnt<=1} />
					<DaIconBtn  icon={CloseIcon} onClick={cb_rem} clr='#E06060'  />
				</div>
			</Collapse>
			 );
}


const MyEntryDst = React.memo( MyEntryDstImp );



const gSty1 = {background: '#FFFFFF'};



