import React from 'react';

import { CmSpinner2 } from '../cm_base.js';

import { useApiQuery2 } from '../api_stuff.js';

//--------------------------


export function useCustomerBalance( cu_id )
{
    /*
    const [val, setVal] = React.useState( null );
    
    React.useEffect( () => {
        
        console.log('HOOK EF');
        
        if (!cu_id)
        {
            console.log('NO SHIT..');
            setVal(null);
            return;
        }
        
        function __ok(e)
        {
            console.log('OK ! ', e);
            setVal(e.val);
        }
        
        function __fail(e)
        {
            setVal( null );
        }
        
        NuPost( 1040, {cu_id:cu_id}, __ok, __fail );
        
    }, [cu_id] );
    
    
    return val;
    */
    
    const { isLoading, isError, data } = useApiQuery2( 1040, {cu_id:cu_id}, {staleTime: 5000, enabled:cu_id>0} );
    if (isLoading || isError) return null;
    
    if (!data) return null;
    
    return data.val;
}


// баланс клиента
export function CustBalancePlain( {cu_id} )
{
	const { isLoading, isError, data } = useApiQuery2( 1040, {cu_id:cu_id}, {staleTime: 5000} );
	
	if (isLoading) return <CmSpinner2 sz={14} />;
	if (isError) return '???';
    
	
	//console.log( data );
	
	const v = data.val;
	
	
	
	return v;
} 
