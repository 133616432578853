import React from 'react';

import * as DEFS from '../defs.js';
import * as HLP from '../helpers.js';

import {DaCombo} from '../misc/combo.js';

import { useGlobEvActs } from '../misc_glob.js';



//-----------------------------------------------------------------


function VisName( {nam} )
{
    return ( <span>{nam}</span> );
}



// как клиент забит на событие
export function EvBoundHow( {evtAct, trpActsCsv} )
{
    const api = useGlobEvActs();

    const e_ev = api.getById( evtAct );
    if (e_ev === null) return `EVT ${evtAct}`;

    const aa = HLP.CsvSplit( trpActsCsv );
    for (let x = 0; x < aa.length; x ++ )
    {
        const id = aa[x];
        const e = api.getById(id);
        if (!e) continue;

        if (e.ea_special === 1 || e.ea_special === 2)
        {
            return `${e_ev.ea_name} (${e.ea_name})`;
        }
    }

    return e_ev.ea_name;
}


export function EvActDispName( {id} )
{
    const api = useGlobEvActs();
    
	const e = api.getById(id);
    
    if (e === null) return (<div className="zz_red">НЕИЗВЕСТНО {id}</div>);
    
    
    return <VisName nam={e.ea_name} />;
}


export function EvActDispNameCSV( {csv} )
{
	const api = useGlobEvActs();

    if (!csv)
    {
        console.warn('EvActDispNameCSV -> null arg !');
        return null;
    }
	
    const ar = HLP.CsvSplit(csv);
    
    const q = [];
    
    for (let x = 0; x < ar.length; x ++ )
    {
        const i = ar[x];
        const e = api.getById(i);

        if (x>0)
        {
            q.push( <span style={{opacity: '0.5'}}key={'s'+i}> или </span>  );
        }

        if (e)
        {
            q.push( <VisName key={i} nam={e.ea_name} /> );
        }
        else
        {
            q.push( <VisName key={i} nam={'UNK-'+i} />  );
        }
    }
    
    return <span>{ q }</span>;
}



//------------------------------------------------


function __flt_for_evt(e)
{
    console.log('FLT TEST.. ', e.ea_name);

    if (e.ea_use_for_evt < 1)
    {
        console.log('not evt !');
        return false;
    }
    if (e.ea_disabled > 0)
    {
        console.log('is disabled !');
        return false;
    }

    console.log(' TRUE !');

    return true;
}

function __flt_for_spread(e)
{
    return e.ea_show_in_spread>0;
}

//-------------





function MyItemImp( {kk, vv, row, isCur, ext} )
{
    const IS_DIS = row.ea_disabled > 0;
    
    return (<div className='yy_list_itj_default' style={{ opacity: (IS_DIS ? 0.4 : 1.0)}} >
                {vv}
            </div>);
}
 
export const MyItem = React.memo( MyItemImp );


//-----



export function EvActCombo( {name, value, disabled, label, onChange, mode} )
{
    const api = useGlobEvActs();
    
    const my_rows = React.useMemo( () => {
        
        let q = [ {ea_id:-1, ea_name: '---'} ];
        
        let t = HLP.DeepCopy( api.rows );
        
        if (mode === 'event')
        {
            t = t.filter( __flt_for_evt );
        }
        else if (mode === 'massSpread')
        {
            t = t.filter( __flt_for_spread );
        }
        
        
        
        q = q.concat( t );
        return q;
        
    }, [] );
    

    
    
    const cb_chg = React.useCallback( (k,v) => {
        onChange( name, v );
    }, [value, name] );
    


	return <DaCombo itemCmp={MyItem} disabled={disabled} label={label} value={value} src={my_rows}
                        fld_k='ea_id' fld_v='ea_name' onChange={cb_chg} />;
}
