import React from 'react';
import { CmComColCellBalanceAct, CmComColCellDateTime  } from '../cm_table_cell_com.js';

import * as DEFS from '../defs.js';
import * as HLP from '../helpers.js';

import { NuPost } from '../api.js';


import { CommonReducer, ShowMsg, ShowErr, FmtPayOpBind } from '../misc_glob.js';

import { OverlayLoading } from '../misc/ovr_loading.js';

import { BottomButtonsPanel } from '../cust_card/bottom_pan.js';


import * as DT from '../dt.js';
import { LayVert2, LayHoriz2, LaySpace } from '../misc/layout.js';

import { DaButton } from '../misc/but.js';
import { DaNumericField } from '../misc/numeric.js';
import { Win2 } from '../misc/win2.js';
import { DaTextField } from '../misc/text_field.js';

import { STO_InvaCustBalance } from '../store.js';

import AddCardIcon from '@mui/icons-material/AddCard';
import RemoveIcon from '@mui/icons-material/Remove';
import CheckIcon from '@mui/icons-material/Check';

import {CmComboPayMethod} from './combo_pay_method.js';

//------------------------





const gSta = {
  
    amo:    0,
    com:    '',
    pm:     0,

    busy:   false,
};

function MyBody( {rid, cuId, onOk} )
{

    const [sta, dispatch] = React.useReducer( CommonReducer, gSta );
    
    const cb_chg = React.useCallback( (k,v) => {
        dispatch( {type: 'val', k:k, v:v} );
    }, [] );
    

    React.useEffect( () => {

        if (rid === -1) return;

        function __ok(e)
        {
            const R = e.row;
            dispatch( {type: 'vals', dct: { busy: false, pm: R.v_pay_meth, amo: R.cbh_amount, com: R.cbh_comment } } );
        }

        function __fail(e)
        {
            ShowErr(e.m);
            dispatch( {type: 'val', k:'busy', v:false} );
        }

        dispatch( {type: 'val', k:'busy', v:true} );

        NuPost( 1047, {rid:rid}, __ok, __fail );

    }, [] );


    function cb_submit()
    {
        function __ok(e)
        {
            dispatch( {type: 'val', k:'busy', v:false} );
            onOk();
        }

        function __fail(e)
        {
            dispatch( {type: 'val', k:'busy', v:false} );

            ShowErr(e.m);
        }

        dispatch( {type: 'val', k:'busy', v:true} );

        NuPost(1044, {rid:rid, cu_id: cuId, amo:sta.amo, com:sta.com, pm:sta.pm}, __ok, __fail);
    }
    
    const DIS_SUB = sta.com.trim() === '';
    
    const T_SUBM = rid === -1 ? 'ВНЕСТИ' : 'СОХРАНИТЬ';

    return (<>
            <LayVert2 rows='1fr max-content' gap='10px' >

                <LayVert2 rows='max-content max-content max-content 1fr' padding='20px' gap='16px' >
                    <DaNumericField name='amo' label='СУММА'     value={sta.amo} onChange={cb_chg}  min={1} max={99999999} />
                    <DaTextField    name='com' label='КОММЕНТ'   value={sta.com} onChange={cb_chg} />
                    <CmComboPayMethod name='pm'                  value={sta.pm} onChange={cb_chg} />
                    <LaySpace />
                </LayVert2>

                <BottomButtonsPanel >
                    <DaButton  onClick={cb_submit} title={T_SUBM}   disabled={DIS_SUB}         icon={ CheckIcon }  />
                </BottomButtonsPanel >

                
            </LayVert2>
            
            <OverlayLoading active={sta.busy} />
            
            </>
            
            );
}


// окно - внести на баланс / редакт запись
export function WinCustDeposit( {rid, cuId, visible, onClose, onOk} )
{
    return (
        <Win2 visible={visible} title={rid === -1 ? 'ВНЕСТИ НА БАЛАНС' : 'РЕДАКТИРОВАТЬ ЗАПИСЬ'} onClose={onClose} width={530} height={320}  >
            <MyBody cuId={cuId} rid={rid} onOk={onOk}  />
        </Win2>
        
        );
}
