
import { QueryClient, QueryCache } from 'react-query'




// инвалидация при входе в клуб
export function STO_OnClubEntry()
{
    STO_InvaTaskNotify();
    STO_InvaCuReqNotify();
    STO_InvaUnclosedCalenNotify();
}


export function STO_InvaPic( ctx, rid )
{
	console.log('invalidate pic.. ', ctx, ' -- ', rid);
	gQueryClient.invalidateQueries( [ 4123, {rid:rid, ty:ctx} ] );
}



export function STO_InvaUnclosedCalenNotify()
{
	gQueryClient.invalidateQueries( [ 4152, {} ] );
}




export function STO_InvaTaskNotify()
{
	gQueryClient.invalidateQueries( [ 1060, {} ] );
}

export function STO_InvaCuReqNotify()
{
	gQueryClient.invalidateQueries( [ 1061, {} ] );
}

export function STO_InvaCuReqMarks(rid)
{
    //console.log('clear marks ', rid);
	gQueryClient.invalidateQueries( [ 1062, {rid:rid} ] );
}



export function STO_InvaGuideList()
{
	gQueryClient.invalidateQueries( [ 1024, {} ] );
}


export function STO_InvaTaskCalenMarks(dt_iso)
{
    gQueryClient.invalidateQueries( [ 1028, {dt:dt_iso} ] );
}


export function STO_InvaCustBalance(cu_id)
{
	gQueryClient.invalidateQueries( [ 1040, {cu_id:cu_id} ] );
}



export function STO_InvaCustLink(rid)
{
	gQueryClient.invalidateQueries( [ 967, {id:rid} ] );
}

export function STO_InvaCustomerCardOverview(rid)
{
	gQueryClient.invalidateQueries( [ 981, {rid} ] );
}



export function STO_InvaUserMsg(ctx_t, ctx_id)
{
	gQueryClient.invalidateQueries( [ 704, {ctx_t:ctx_t, ctx_id:ctx_id} ] );
}


export function STO_InvaCustOrigins(id)
{
	gQueryClient.invalidateQueries( [ 976, {} ] );
}



export function STO_InvaUser(id)
{
	gQueryClient.invalidateQueries( [ 964, {id:id} ] );
}


// MOVE !

export let gQueryClient = new QueryClient( {  
	
     queryCache: new QueryCache({

    onError: (error) =>

      { console.log('QUERY CLIENT.. ERROR: ', error);;},

  }), });


