 
import React from 'react';

import "./g.css";

import { LayVert2, LayHoriz2, LaySpace } from '../../misc/layout.js';
import { NuPost } from '../../api.js';
import { CommonReducer, ShowMsg, ShowErr } from '../../misc_glob.js';
import { DaButton } from '../../misc/but.js';
import {DaSearchBar} from '../../misc/search_bar.js';


import { Win2Confirm } from '../../misc/win2_confirm.js';

import { GuidesReadOnlyView } from './read_only_view.js';
import { GuideList } from './guide_list';

import { GuidesTextEditor } from './editor.js';

//--------------------------------------------------


const gStG = {

    rid: -1,
    ser: 0,
    srch: '',

    busy: false,
    pop: 0,
    pop_dct_conf: {},
};


// Раздел ГАЙДЫ / ПОДСКАЗКИ
function TabGuidesImp( {isMaster} )
{
    //console.log('GUIDES - MASTER: ', isMaster);

    const [sta, dispatch] = React.useReducer( CommonReducer, gStG );

    const cb_sel_item = React.useCallback( (i) => 
    {
        dispatch( {type: 'val', k:'rid', v:i} );
    }, [] );

    function cb_saved()
    {
        dispatch( {type: 'val', k:'ser', v:sta.ser+1} );
    }

    const cb_chg = React.useCallback( (k,v) => {
        dispatch( {type: 'val', k:k, v:v } );
    }, [] );



    function cb_new_guide()
    {
        function __ok(e)
        {
            dispatch( {type: 'vals', dct: { rid: e.rid,
                                            busy:false,
                                            ser:sta.ser+1}  } );
        }
        
        function __fail(e)
        {
            ShowErr(e.m);
            dispatch( {type: 'val', k:'busy', v:false } );
        }
        
        dispatch( {type: 'val', k:'busy', v:true } );
        
        NuPost(1026, {rid:-1, tit:'Новая плашка', clubs: '', tex:'ТЕКСТ'}, __ok, __fail);
    }

    function cb_clo()
    {
        dispatch( {type: 'val', k:'pop', v:0 } );
    }

    function cb_del()
    {
        const RID = sta.rid;

        function __conf()
        {
            function __ok(e)
            {
                ShowMsg('УДАЛЕНО !');
                dispatch( {type: 'vals', dct: {busy:false, pop: 0, rid:-1, ser:sta.ser+1} } );
            }
            
            function __fail(e)
            {
                ShowErr(e.m);
                dispatch( {type: 'vals', dct: {busy:false} } );
            }
            
            dispatch( {type: 'vals', dct: {busy:true} } );
            
            
            NuPost( 1025, {rid:RID}, __ok, __fail);
        }

        const TTT = { title: 'УДАЛИТЬ ПОДСКАЗКУ ?', onClose: cb_clo, onOk: __conf };

        dispatch( {type: 'vals', dct: {  pop_dct_conf: TTT, pop: 1  } } );
    }

    const DIS_DEL = sta.rid === -1;


    const p_top = isMaster ? (
        <LayHoriz2 cols='1fr max-content max-content' gap='10px'>
            <DaSearchBar name='srch' value={sta.srch} label='ПОИСК ПО ЗАГОЛОВКАМ' onChange={cb_chg} />
            <DaButton title='НОВАЯ' onClick={cb_new_guide} />
            <DaButton title='УДАЛИТЬ' onClick={cb_del} disabled={DIS_DEL} />
        </LayHoriz2>
    ) : (
        <DaSearchBar name='srch' value={sta.srch} label='ПОИСК ПО ЗАГОЛОВКАМ' onChange={cb_chg} />
    );



    const p_view = isMaster ? (
        <GuidesTextEditor rid={sta.rid} onSaved={cb_saved} />
    ) : (
        <GuidesReadOnlyView rid={sta.rid} />

    );


    return ( 
    <>
    <LayHoriz2 cols='1fr minmax(1100px, 1600px) 1fr' padding='20px' sx={{background: '#EAEAEA'}} >

        <LaySpace />

        <LayHoriz2 cols='minmax(400px, 460px) max-content 1fr' padding='10px' gap='6px' sx={{background: '#FFFFFF'}} >

            <LayVert2 rows='max-content 1fr' gap='6px'>
                {p_top}
                <GuideList srch={sta.srch} ser={sta.ser} curRid={sta.rid} onSel={cb_sel_item} showClubs={isMaster} />
            </LayVert2>

            <div style={{width: '1px', background: '#F0F0F0', height: '100%'}}></div>

            {p_view}

        </LayHoriz2>

        <LaySpace />
    
    </LayHoriz2> 
    
    <Win2Confirm busy={sta.busy} visible={sta.pop === 1} {...sta.pop_dct_conf}  />
    
    </>);
}




export function TabGuidesCLUB()
{
    return <TabGuidesImp isMaster={false} />;
}

export function TabGuidesBOSS()
{
    return <TabGuidesImp isMaster={true} />;
}
