 
import React from 'react';

import { LayVert2, LayHoriz2, LaySpace } from '../../misc/layout.js';
import { NuPost2 } from '../../api.js';
import { CommonReducer, ShowMsg, ShowErr } from '../../misc_glob.js';
import { OverlayLoading } from '../../misc/ovr_loading.js';

import * as DT from '../../dt.js';
import { CmCachedUserLink } from '../../desktop/ca_user_link.js';
import { DaVertScrollFrame, DaVertScrollJuice } from '../../misc/vscr.js';


//---------------


    

import * as HLP from '../../helpers.js';
import * as DEFS from '../../defs.js';




//--------------


const gSTRD = {
    tit: '',
    text: '',
    busy: false,
    uid: null,
    dt_touch: null,
};

// ПОДСКАЗКА (нельзя редакт)
export function GuidesReadOnlyView( {rid } )
{
    const [sta, dispatch] = React.useReducer( CommonReducer, gSTRD );

    React.useEffect( () => {

        if (rid === -1) return;  

        function __ok(e)
        {
            const R = e.row; 
            dispatch( {type: 'vals', dct: {
                                                tit: R.tg_title,
                                                text: R.tg_text,
                                                uid: R.tg_who_user_id,
                                                dt_touch: DT.DateTimeParseIso(R.tg_dt_touch),
                                                busy: false} } );
        }

        function __fail(e)
        { 
            ShowErr(e.m);
            dispatch( {type: 'vals', dct: {busy: false} } );
        }

        dispatch( {type: 'vals', dct: {busy: true} } );

        NuPost2( 1023, {rid:rid}, __ok, __fail );

    }, [rid] );

    if (rid === -1) return <LaySpace />;

    return (
        
        <LayVert2 rows='30px 30px 1fr' padding='20px' gap='6px' sx={{position: 'relative'}}>
            <div className="gu_read_tit">{sta.tit}</div>
            <div className="zz_small" >ИЗМЕНЕНО: { DT.DateTimeStrLoc(sta.dt_touch) } / <CmCachedUserLink plain rid={sta.uid} /></div>
            <DaVertScrollFrame>

                <DaVertScrollJuice>
                    <div className="gu_read_txt" >{sta.text}</div>
                </DaVertScrollJuice>

            </DaVertScrollFrame>


            <OverlayLoading active={sta.busy} />

        </LayVert2>

    );
}
