import React from 'react';

import * as DEFS from '../defs.js';
import * as HLP from '../helpers.js';

import { NuPost2 } from '../api.js';

import { WinHelpConta, WinHelpElem } from '../misc/win_help.js'



import { OverlayLoading } from '../misc/ovr_loading.js';

import { LayVert2, LayHoriz2, LaySpace } from '../misc/layout.js';
import {DaCombo} from '../misc/combo.js';

import { DaButton } from '../misc/but.js';
import { DaTextField } from '../misc/text_field.js';
import { DaNumericField } from '../misc/numeric.js';
import { CommonReducer, ShowMsg, ShowErr } from '../misc_glob.js';

import { useGlobalShit } from '../v3/hk_global_shit.js';

import { Win2 } from '../misc/win2.js';

import {ComboGoodCat} from './combo_good_cat.js';

import { BottomButtonsPanel } from '../cust_card/bottom_pan.js';
import CheckIcon from '@mui/icons-material/Check';
import BuildIcon from '@mui/icons-material/Build';

import { WinDevTweakGoodsStock } from './win_dev_tweak_goods_stock.js';


//-----------------------------

const gSrcDisabled = [
    { k:0, v:'АКТИВЕН' },
    { k:1, v:'НЕАКТИВЕН' },
];


const gSt1 = {
  
    nam:        '',
    price:      0,
    userPayBonus:   0,  // бонус к зп тренера если он 'продал'
    disabled:   0,
    cat:        -1,
    
    busy:       false,

    showTweak:  false,
    
};


function Juice( {rid, onOk} )
{
    const [sta, dispatch] = React.useReducer( CommonReducer, gSt1 );

    const sss = useGlobalShit();


    
    const cb_chg = React.useCallback( (k,v) => {
        dispatch( {type: 'val', k:k, v:v} );
    }, [] );
    
    React.useEffect( () => {
        
        if (rid === -1) return;
        
        function __ok(e)
        {
            const ro = e.row;

            const DCT = {busy: false,
                            nam: ro.nam,
                            price: ro.pri,
                            disabled: ro.dis,
                            cat: ro.cat,
                            userPayBonus: ro.userPayBonus};
            

            dispatch( {type: 'vals', dct: DCT } );
        }
        
        function __fail(e)
        {
            ShowErr(e.m);
            dispatch( {type: 'val', k:'busy', v:false} );
        }
        
        dispatch( {type: 'val', k:'busy', v:true} );
        
        NuPost2(955, {rid: rid}, __ok, __fail);
        
    }, [] ); 
    
    const cb_submit = () => {
      
        const RR = { nam: sta.nam, pri: sta.price, cat: sta.cat, dis: sta.disabled, upb: sta.userPayBonus };
        
        function __ok(e)
        {
            ShowMsg('УСПЕХ !');
            dispatch( {type: 'val', k:'busy', v:false} );
            onOk();
        }
        
        function __fail(e)
        {
            ShowErr(e.m);
            dispatch( {type: 'val', k:'busy', v:false} );
        }
        
        dispatch( {type: 'val', k:'busy', v:true} );
        
        NuPost2(953, {rid: rid, row: RR}, __ok, __fail);
    };
    

    
    const ERR_NAM = sta.nam.trim() === '';
    const ERR_PRI = sta.price < 1;
    const ERR_CAT = sta.cat < 1;
    
    const DIS_SUBMIT = ERR_NAM || ERR_PRI || ERR_CAT;

    function cb_show_tweak()
    {
        dispatch( {type: 'vals', dct: {showTweak: true}} );
    }

    function clo_tweak()
    {
        dispatch( {type: 'vals', dct: {showTweak: false}} );
    }

    function cb_tweak_ok()
    {
        ShowMsg('КОЛИЧЕСТВО ИЗМЕНЕНО !');
        onOk();
    }

    // MAD SKILLZ FOR NOW !!! TODO TODO
    const SHOW_TWEAK = sss.userHasAbil(3000) && sss.clubId>0;

    const p_btn_tweak_dev = (

        SHOW_TWEAK ? 

        <DaButton impo  icon={BuildIcon}  title='РЕДАКТ. "В НАЛИЧИИ"' onClick={cb_show_tweak} /> : null

    );
    
    return (
        <>
        <LayVert2 rows='1fr max-content' >
            <LayVert2 rows='max-content max-content max-content max-content max-content 1fr' padding='16px' gap='16px' >
                <DaTextField    label='НАЗВАНИЕ' name='nam' value={sta.nam} onChange={cb_chg} error={ERR_NAM} />
                <DaNumericField label='ЦЕНА' name='price' value={sta.price} onChange={cb_chg}  min={1} max={9999999} error={ERR_PRI} />
                <DaNumericField label='БОНУС К ЗП ТРЕНЕРА' name='userPayBonus' value={sta.userPayBonus} onChange={cb_chg}  min={0} max={9999999} />
                <ComboGoodCat label='КАТЕГОРИЯ' name='cat' value={sta.cat} onChange={cb_chg} error={ERR_CAT}  />
                <DaCombo label='СТАТУС' name='disabled' src={gSrcDisabled} value={sta.disabled} onChange={cb_chg}  />
                <LaySpace />
            </LayVert2>
        
            <BottomButtonsPanel>
                {p_btn_tweak_dev}
                <DaButton disabled={DIS_SUBMIT} onClick={cb_submit}  icon={CheckIcon}  title={rid===-1?'СОЗДАТЬ':'СОХРАНИТЬ'} />
            </BottomButtonsPanel>
        </LayVert2>
        
        <OverlayLoading active={sta.busy} />

        <WinDevTweakGoodsStock rid={rid} visible={sta.showTweak} onOk={cb_tweak_ok} onClose={clo_tweak}/>
        </>
    );
}



const gHelp1 = (<WinHelpConta>
                    <WinHelpElem tit='СТАТУС' text={ ['Активен - когда товар используется.', 'Неактивен - старый или больше неиспользуемый товар, будет скрыт в списках и таблицах.'] } />
                    <WinHelpElem tit='БОНУС К ЗП ТРЕНЕРА' text='Сколько тренер получает за "продажу" этого товара. Тут значение по умолчанию. Запоняется в окне "продажи товара".' />

                </WinHelpConta>);
                



// окно - новый товар / редакт товар
export function WinEditGood( {visible, rid, onOk, onClose} )
{
    return (
    
        <Win2 help={gHelp1} visible={visible} title={rid===-1 ? 'НОВЫЙ ТОВАР' : 'РЕДАКТИРОВАТЬ ТОВАР'} onClose={onClose}
                                                width={440} height={410}  >
            <Juice onOk={onOk} rid={rid} />
        </Win2>
    
    );
}
