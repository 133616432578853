import React from 'react';


import { DaOutlinedShit } from './outlined.js';
import { DaIconBtn } from './icon_btn.js';


import * as DEFS from '../defs.js';
import * as HLP from '../helpers.js';


import RuleIcon from '@mui/icons-material/Rule';


import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';

import Check from '@mui/icons-material/Check';
import MenuItem from '@mui/material/MenuItem';
//--------------------------------------------------------------

export function DaCsvField( {name, value_csv, src, fld_k, fld_v, disabled, error, label, onChange} )
{
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	
	const handleClick = (e) => {
		setAnchorEl(e.currentTarget);
	};
	
	const han_clo = () => {
		setAnchorEl(null);
	};
	
	const handleItem = (e) => {
	  
		const ii = parseInt(e.target.dataset.kk);
		const nnn = HLP.CsvToggleID( value_csv, ii );
		onChange( name, nnn );

	};
	
	const ar = HLP.CsvSplit(value_csv);
	
	let tt = [];
	for (let x = 0; x < ar.length; x ++ )
	{
		const e = HLP.ArrayOfDictsFindByPropValue(src, fld_k, ar[x]);
        if (e) tt.push( e[ fld_v ] );
        else tt.push( 'UNK-' + ar[x] );
		
	}
	
	tt = tt.join(', ');
	
	
	let ar_items = [];
  
	for (let x = 0; x < src.length; x ++ )
	{
		const e = src[x];
		const kk = e[ fld_k ];
		const vv = e[ fld_v ];
		
		let p_chk = HLP.CsvTestID( value_csv, kk ) ?  <Check /> : null;
		
		ar_items.push(  <MenuItem onClick={handleItem} data-kk={kk} key={kk}><ListItemIcon>{p_chk}</ListItemIcon>{vv}</MenuItem>  );
	}
	
    
    const p_txt = tt !== '' ? (<div className="txt">{tt}</div>) : <div style={{color: '#808080'}}>НЕ ВЫБРАНО</div>
    
    const p_juice = <div className="zz_csv_field_juice" >
                        {p_txt}
                        <DaIconBtn  disabled={disabled} icon={RuleIcon} onClick={handleClick}  />
                    </div>;
    
    return (
        <>
        <div style={{height: '36px', width: '100%'}}>
            <DaOutlinedShit error={error} labelIsFloat={true} labelVal={label} borderIsFoc={open} juice={p_juice} />
        </div>
    
		<Menu anchorEl={anchorEl} open={open} onClose={han_clo}  >
			{ar_items}
		</Menu>
        </>
    );
    
}
