import React from 'react';

import * as HLP from '../helpers.js';

import * as DT from '../dt.js';

import { Win2 } from '../misc/win2.js';

import CheckIcon from '@mui/icons-material/Check';

import { LayVert2, LayHoriz2 } from '../misc/layout.js';

import { DaButton } from '../misc/but.js';
import { BottomButtonsPanel } from '../cust_card/bottom_pan.js';

import { DaCombo } from '../misc/combo.js';

import {DaIconBtn} from '../misc/icon_btn.js';

import {DaComboHour} from '../misc/combo_hour.js';


import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

import { DaVertScrollFrame, DaVertScrollJuice } from '../misc/vscr.js';


const gSrcWeekDay = [

    {k: 0, v: 'ПН'},
    {k: 1, v: 'ВТ'},
    {k: 2, v: 'СР'},
    {k: 3, v: 'ЧТ'},
    {k: 4, v: 'ПТ'},
    {k: 5, v: 'СБ'},
    {k: 6, v: 'ВС'},

];




//--------------------------------------------


function SimpleElemImp( {myInd, onRemove, wd, hs, he} )
{
    function __rem()
    {
        onRemove(myInd);
    }
    
    return (
    
                        <div style={{display: 'flex', gap: '4px', alignItems: 'center'}}>
                            <div>{gSrcWeekDay[wd].v}: с {HLP.ZeroPAD2(hs)}:00 до {he}:00</div>
                            <DaIconBtn clr='#A06060' icon={CloseIcon} help='УБРАТЬ' onClick={__rem}  />
                        </div>
    
    );
}


const SimpleElem = React.memo( SimpleElemImp );

//-----------------------------------


// список временных интервалов для (трени / абоны)
export function TrpHourListEditor( {list, onRemove, onAdd} )
{
    const [isEd, setIsEd] = React.useState( false );
    
    const cb_show_ed = React.useCallback( () => {
        setIsEd( true );
    }, [isEd] );
    
    const cb_close_win = React.useCallback( () => {
        setIsEd( false );
    }, [isEd] );
    
    
    const cb_added = (wd, hs, he) => {
        onAdd(wd, hs, he);
        setIsEd(false);
    };

    
    let p_ar;
    
    if (list.length < 1)
    {
        p_ar = <div style={{color: '#60A060'}} >БЕЗ ОГРАНИЧЕНИЙ</div>;
    }
    else
    {
        p_ar = [];
        
        for (let x = 0; x < list.length; x ++ )
        {
            const e = list[x];
            
            const wd = e[0];
            const hs = e[1];
            const he = e[2];
            
            p_ar.push( <SimpleElem key={x} wd={wd} hs={hs} he={he} myInd={x} onRemove={onRemove} /> );
        }
    }
    
    return (
        <>
        <DaVertScrollFrame>
            <DaVertScrollJuice sx={{padding: '16px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '10px'}}>
                {p_ar}
                <DaIconBtn  icon={AddIcon} help='ДОБАВИТЬ' onClick={cb_show_ed}  />
            </DaVertScrollJuice>
        </DaVertScrollFrame>
    
    
        <WinHoursEditor visible={isEd} onClose={cb_close_win} onOk={cb_added}  />
    
        </>
    );
}

//-----------------------------------------



function WinHoursEditor( {visible, onClose, onOk} )
{
    const [wd, setWd] = React.useState(  0 );
    const [hs, setHs] = React.useState( DT.DateSetHMS(new Date(), 9, 0, 0) );
    const [he, setHe] = React.useState( DT.DateSetHMS(new Date(), 9, 0, 0) );
    
    const cb_chg_hs = (k,v) => {
        setHs( v );
    };
    
    const cb_chg_he = (k,v) => {
        setHe( v );
    };
    
    const cb_chg_wd = (k,v) => {
        setWd( v );
    };
    
    function cb_submit()
    {
        onOk( wd, DT.DateTimeGetHours(hs), DT.DateTimeGetHours(he) );
    }
    
    const DIS_SUB = DT.DateTimeGetHours(he)-DT.DateTimeGetHours(hs) < 0;
    
    return (
    
        <Win2 visible={visible} title='ЧАСЫ ДЛЯ ЗАПИСИ' width={560} height={160}  onClose={onClose} >
            <LayVert2 rows='max-content 1fr max-content' >
            
                <div style={{display: 'flex', gap: '10px', padding: '16px'}}>
                    <DaCombo value={wd} src={gSrcWeekDay} onChange={cb_chg_wd} label='ДЕНЬ НЕДЕЛИ' />
                    
                    <DaComboHour name='hs' hmin={9} hmax={22} value={hs} onChange={cb_chg_hs} label='ОТ' />
                    <DaComboHour name='he' hmin={9} hmax={22} value={he} onChange={cb_chg_he} label='ДО (ВКЛЮЧИТЕЛЬНО)' />
                </div>
            
                <BottomButtonsPanel>
                    <DaButton disabled={DIS_SUB} onClick={cb_submit} title='ДОБАВИТЬ' icon={CheckIcon} />
                </BottomButtonsPanel>
            
            </LayVert2>
        </Win2>
    
    );
}
