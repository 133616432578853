import React from 'react';

import { Win2 } from '../misc/win2.js';

import * as DT from '../dt.js';

import * as DEFS from '../defs.js';
import * as COM_DEFS from '../com_defs.mjs';

import { DaCombo } from '../misc/combo.js';

import { LayHoriz2, LayVert2, LaySpace } from '../misc/layout.js'

import { DaButton } from '../misc/but.js';
import { DaDateField } from '../misc/date_field.js';
import { DaTextField } from '../misc/text_field.js';
import { DaNumericField } from '../misc/numeric.js';

import { OverlayLoading } from '../misc/ovr_loading.js';

import { BottomButtonsPanel } from '../cust_card/bottom_pan.js';

import { CmComboPayMethod } from './combo_pay_method.js';

import { NuPost, NuPost2 } from '../api.js';

import { DaComboUsers } from './combo_user.js';

import { CommonReducer, ShowMsg, ShowErr} from '../misc_glob.js';


import CheckIcon from '@mui/icons-material/Check';
//-------------------------------------------

function GenSta()
{
    return {
    
        dt_when:     new Date(),
        com:        '',
        amount:     0,
        meth:       0,
        ctx:        0,
        usr:        0,
        
        busy:       false,
    };
    

}


const gMyComb = [

    {k: COM_DEFS.PAY_OPS_CTX_NULL,            v: '---'},
    {k: COM_DEFS.PAY_OPS_CTX_STAFF_SALARY,    v: 'ЗАРПЛАТА'},
    {k: COM_DEFS.PAY_OPS_CTX_STAFF_ADVANCE,   v: 'АВАНС'},
    {k: COM_DEFS.PAY_OPS_CTX_MAINTAIN,        v: 'ТРАТЫ НА КЛУБ' },

];

//-----------------------------


function MyBody( {rid, onOk} )
{
    const [sta, dispatch] = React.useReducer( CommonReducer, null, GenSta );
    
    
    React.useEffect( () => {
        
        if (rid === -1) return;
        
        function __ok(e)
        {
            const rr = e.row;


            dispatch( {type: 'vals', dct: { busy: false,
                                            dt_when: DT.DateTimeParseIso( rr.po_dt_when ),
                                            amount: rr.po_amount,
                                            meth: rr.po_method,
                                            com: rr.po_comment,
                                            usr: rr.po_bind_id,
                                            ctx: rr.po_bind_ctx }
                                          } );
        }
        
        function __fail(e)
        {
            ShowErr(e.m);
            dispatch( {type: 'val', k:'busy', v:false} );
        }
        
        dispatch( {type: 'val', k:'busy', v:true} );
        
        NuPost2( 1053, { rid:rid}, __ok, __fail );
        
    }, [] );
    
    
	const cb_chg = React.useCallback( (k,v) =>
	{
		dispatch( {type: 'val', k, v} );
	}, [] );
    
    function cb_submit()
    {
        function __ok(e)
        {
            ShowMsg('УСПЕХ !');
            dispatch( {type: 'val', k:'busy', v:false} );
            onOk();
        }
        
        function __fail(e)
        {
            ShowErr(e.m);
            dispatch( {type: 'val', k:'busy', v:false} );
        }
        
        dispatch( {type: 'val', k:'busy', v:true} );
        
        NuPost2( 1051, { rid:rid, 
                        amount:sta.amount,
                        meth:sta.meth,
                        usr:sta.usr,
                        com:sta.com,
                        ctx:sta.ctx,
                        dt_when:DT.DateTimeStrIso(sta.dt_when) }, __ok, __fail );
    }
    
    return (
        <>
        <LayVert2 rows='1fr max-content' >
            <LayVert2 rows='repeat(6, max-content) 1fr'  padding='16px' gap='16px'>
                <DaDateField nonull label='ДАТА ОПЕРАЦИИ' name='dt_when'   onChange={cb_chg}  value={sta.dt_when} />
                <DaNumericField name='amount' value={sta.amount} onChange={cb_chg} label='СУММА'  />
                <CmComboPayMethod   name='meth'   value={sta.meth} onChange={cb_chg} />
                <DaCombo name='ctx' value={sta.ctx} onChange={cb_chg} src={gMyComb} label='ПРИВЯЗКА' />
                <DaComboUsers name='usr' label='СОТРУДНИК (ЗП / АВАНС)' value={sta.usr} onChange={cb_chg} />
                <DaTextField name='com' label='КОММЕНТ'  value={sta.com} onChange={cb_chg}   />

                <LaySpace />
            </LayVert2>
            
            <BottomButtonsPanel>
                <DaButton  onClick={cb_submit} title={rid===-1 ? 'ОК' : 'СОХРАНИТЬ'} icon={ CheckIcon } />
            </BottomButtonsPanel>
            
        </LayVert2>
        
        <OverlayLoading active={sta.busy} />
        
        </>
    
    );
}

//-----------------------------

const gMyHelp = (<div>
                    <div>Сумма больше 0 - пришло</div>
                    <div>Сумма меньше 0 - ушло</div>
                </div>);

export function WinEditPayOp( {visible, rid, onClose, onOk} )
{
    return (
        
        <Win2 visible={visible} help={gMyHelp} title={rid===-1 ? 'НОВАЯ ЗАПИСЬ' : `РЕДАКТИРОВАТЬ ЗАПИСЬ - ${rid}`} onClose={onClose} width={400} height={500}  >
            <MyBody rid={rid} onOk={onOk} />
        </Win2>
    
    
    );
}

