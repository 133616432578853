import React from 'react';

import { LayVert2, LayHoriz2, LaySpace } from '../misc/layout.js';
import { OverlayLoading } from '../misc/ovr_loading.js';

import { BonusCalcInfo, BonusCalcStoredInfo } from './bonus_calc.js';

import { NuPost2 } from '../api.js';

import { TabMods } from './win_abon_tab_mods.js';

import { DaTextField } from '../misc/text_field.js';

import { PayFormVis, usePayFormState } from './pay_form4.js';

import { WinHelpConta, WinHelpElem } from '../misc/win_help.js';

import { CommonReducer, ShowMsg, ShowErr } from '../misc_glob.js';

import {ListItemCustNameAndPhone} from './li_cu_name_and_phone.js';
import { DaAutoComplete } from '../misc/autocomp.js';

import * as DT from '../dt.js';

import { WinTrpTransfer2 } from './win_trp_transfer.js';
import { WinPayRets } from './win_trp_rets.js';


import { CmHelpIcon } from '../misc/help_icon.js';


import {CmProdDescription} from '../abons/prod_descr.js';

import { WinProductBrowser2 } from '../abons/win_prod_browser2.js';

import { MonSpreadResult } from '../abons/mon_spread_result.js';

import { MonthlyEventList } from '../abons/mon_event_list.js';

import { BottomButtonsPanel } from '../cust_card/bottom_pan.js';

import { ViTrainsNormal } from '../abons/vi_trains_info_norm.js';
import { ViTrainsMonthly } from '../abons/vi_trains_info_mon.js';

import * as DEFS from '../defs.js';
import * as COM_DEFS from '../com_defs.mjs';
import * as HLP from '../helpers.js';



import {DaIconBtn} from '../misc/icon_btn.js'; 
import { DaButton } from '../misc/but.js';
import { DaDateField } from '../misc/date_field.js';
import { DaNumericField } from '../misc/numeric.js';


import { Win2 } from '../misc/win2.js';



import CheckIcon from '@mui/icons-material/Check';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import AddIcon from '@mui/icons-material/Add';

import CancelIcon from '@mui/icons-material/Cancel';

import { DaTabs } from '../misc/tabz.js';

import { ProdEntry } from './pla_pay_prod_entry.js';
import { DaVertScrollFrame, DaVertScrollJuice } from '../misc/vscr.js';

import { WinRetAbonMon } from './win_ret_abon_mon.js';
import { CmSpinner2 } from '../cm_base.js';
import { DaCheck } from '../misc/check.js';

//---------------------------------------

function __FlattenProds(rows, cnts)
{
	let r = [];
    for (let x = 0; x < rows.length; x ++ )
    {
        for (let i = 0; i < cnts[x]; i ++ )
        {
            r.push( rows[x].pr_id );
        }
    }
    
    return r;
}




function GenState( args )
{
    return {
        
        tab: 0,
        
        page_ind: 0,    // (tab 0 sub-page)
        bound_list: [], // (after monthly created)
        
        busy: false,
        
        cu_id: args.cu_row ? args.cu_row.cu_id : null,
        cu_row_init: args.cu_row ? args.cu_row : null,
        
        disable_cu_row_edit: !!args.cu_row,
        
        comcom:  '',
        orig:    0,

        no_bonus_reward: false,

        price:  0,
        usable_dt_s:    new Date(),
        usable_dt_e:    new Date(),
        
        pay_dt: new Date(),
        
		prod_rows: [],
		prod_cnts: [],
        
        abon_logic: -1,
        
        pop_brow: false,
        pop_tra:  false,
        pop_rets: false,

        pop_ret_mon: false,
    };
}


//--------------------------------

// TODO.... better way
function TxAbonMonRetWrap( {rid, onClickBtn} )
{
    const [busy, setBusy] = React.useState( true );
    const [gotRet, setGotRet] = React.useState( false );

    // 

    React.useEffect( () => {

        function __ok(e)
        {
            setBusy(false);
            setGotRet(e.result);
        }

        function __fail(e)
        {
            ShowErr(e.m);
        }

        setBusy( true );

        NuPost2( 1111, {pay_id:rid}, __ok, __fail );

    }, [] );

    if (busy) return <CmSpinner2 sz={24} />;

    if (gotRet)
    {
        return <div style={{height: '100%', display: 'flex', alignItems: 'center', fontWeight: 600, color: '#703030'}}>ДЛЯ ЭТОГО АБОНЕМЕНТА ОФОРМЛЕН ВОЗВРАТ !</div>
    }

    return (
        <DaButton onClick={onClickBtn}      title='ВОЗВРАТ...'       icon={CancelIcon} />
    );
}



function TabMain( {rid, onOk, sta, dispatch, hk_pay} )
{
    const IS_EDIT = rid !== -1;
    

    
    const cb_chg = React.useCallback( (k,v) => {
        dispatch( {type: 'val', k:k, v:v} ); 
    }, [] );
    


    
    
    
	function cb_chg_cnt(ind, new_cnt)
	{
		const nu_cnts 	= [...sta.prod_cnts];
		nu_cnts[ind] = new_cnt;

		dispatch( {type: 'val', k: 'prod_cnts', v: nu_cnts } );
	}
	
	
	function cb_remove(ind)
	{
        const nu_prods 	= [...sta.prod_rows];
        const nu_cnts 	= [...sta.prod_cnts];
        
        nu_prods.splice(ind,1);
        nu_cnts.splice(ind,1);
        
        dispatch( {type: 'val', k: 'prod_rows', v: nu_prods } );
		dispatch( {type: 'val', k: 'prod_cnts', v: nu_cnts } );
		
		if (nu_prods.length < 1)
		{
			dispatch( {type: 'val', k: 'abon_logic', v: -1 } );
		}
	}
    
    function cb_show_brow()
    {
        dispatch( {type: 'val', k: 'pop_brow', v: true } );
    }
	
    function cb_close_brow()
    {
        dispatch( {type: 'val', k: 'pop_brow', v: false } );
    }
	
    
	
	function cb_brow_ok(r)
	{
		if (sta.abon_logic === -1)
		{
			dispatch( {type: 'val', k: 'abon_logic', v: r.pr_logic } );
		}
		else
		{
			if (sta.abon_logic !== r.pr_logic)
			{
				ShowErr('НЕЛЬЗЯ СОВМЕЩАТЬ ОБЫЧНЫЙ и МЕСЯЧНЫЙ ПРОДУКТ');
				return;
			}
		}
		
		dispatch( {type: 'val', k: 'pop_brow', v: false } );
		
        const nu_prods 	= [...sta.prod_rows];
        const nu_cnts 	= [...sta.prod_cnts];
        
        let i = HLP.ArrayOfDictsFindByPropValueIndex(nu_prods, 'pr_id', r.pr_id);
        if (i !== -1)
        {
			nu_cnts[i] ++;
		}
		else
		{
			nu_prods.push( r );
			nu_cnts.push( 1 );
		}
        
        
        dispatch( {type: 'val', k: 'prod_rows', v: nu_prods } );
		dispatch( {type: 'val', k: 'prod_cnts', v: nu_cnts } );

	}
    

    function cb_submit_edit()
    {
        //console.log('SUBMIT EDIT !');
        
        function __ok(e)
        {
            //console.log(e);
            ShowMsg('СОХРАНЕНО !');
            dispatch( {type: 'val', k: 'busy', v: false } );
            
            onOk();
            
        }
        
        function __fail(e)
        {
            ShowErr(e.m);
            dispatch( {type: 'val', k: 'busy', v: false } );
        }
        
        dispatch( {type: 'val', k: 'busy', v: true } );


        const pf = hk_pay.packSV();
        
        NuPost2(4112, {  rid: rid,
                        price: sta.price,
                        comment: sta.comcom,
                        no_bonus_reward: sta.no_bonus_reward,
                        pay_date: DT.DateTimeStrIso(sta.pay_dt),
                        usable_dt_s: DT.DateTimeStrIso(sta.usable_dt_s),
                        usable_dt_e: DT.DateTimeStrIso(sta.usable_dt_e),
                        pf:pf
                        }, __ok, __fail );
    }

    
    function cb_submit_new()
    {
        function __ok(e)
        {
            console.log(e);
            ShowMsg('АБОНЕМЕНТ СОЗДАН !');
            dispatch( {type: 'val', k: 'busy', v: false } );
            
            
			if (e.bound.length > 0)
			{
                dispatch( {type: 'val', k: 'bound_list', v: e.bound } );
                dispatch( {type: 'val', k: 'page_ind',   v: 1 } );
			}
			else
			{
				onOk();
			}
            
        }
        
        function __fail(e)
        {
            ShowErr(e.m);
            dispatch( {type: 'val', k: 'busy', v: false } );
        }
        
        dispatch( {type: 'val', k: 'busy', v: true } );
        
        const flat_prod_ids = __FlattenProds( sta.prod_rows, sta.prod_cnts );
        
        // 

        const pf = hk_pay.packSV();

        NuPost2( 4110, {cu_id: sta.cu_id,
                        prods: flat_prod_ids,
                        price: sta.price,
                        pf: pf,
                        no_bonus_reward: sta.no_bonus_reward,
                        comment: sta.comcom,
                        pay_date: DT.DateTimeStrIso( sta.pay_dt ),
                        usable_dt_s: DT.DateTimeStrIso( sta.usable_dt_s ),
                        usable_dt_e: DT.DateTimeStrIso( sta.usable_dt_e )
                 } , __ok, __fail );

    }
	
	function cb_page1_ok()
	{
		onOk();
	}
	
    
    function cb_open_transfer()
    {
        dispatch( {type: 'val', k:'pop_tra', v:true} );
    }
    
    function cb_open_ret()
    {
        dispatch( {type: 'val', k:'pop_rets', v:true} );
    }
    
    function cb_open_ret_mon()
    {
        dispatch( {type: 'val', k:'pop_ret_mon', v:true} );
    }

    function cb_close_ret_mon()
    {
        dispatch( {type: 'val', k:'pop_ret_mon', v:false} );
    }

    function cb_ret_mon_ok()
    {
        dispatch( {type: 'val', k:'pop_ret_mon', v:false} );
        onOk();

        ShowMsg('ВОЗВРАТ ОФОРМЛЕН !');
    }
    
    if (sta.page_ind === 1)
    {
        return (   <LayVert2 rows='1fr max-content' >
                        <MonSpreadResult eventIdList={ sta.bound_list } />
                            <BottomButtonsPanel >

                                <DaButton  onClick={cb_page1_ok} title='OK' icon={ CheckIcon } />
                                
                            </BottomButtonsPanel >
                            
                    </LayVert2>
                    );
    }
    
    

    
    
	let can_add_more = !IS_EDIT;
	
	if (sta.abon_logic === COM_DEFS.ABON_LOGIC_MONTHLY)
	{
		can_add_more = false;
	}
	
	
    
    
	
	let p_prods = [];
	
	for (let x = 0; x < sta.prod_rows.length; x ++ )
	{
		const row = sta.prod_rows[x];
		const cnt = sta.prod_cnts[x];
		p_prods.push( <ProdEntry key={x} ind={x} row={row} cnt={cnt} onChgCnt={cb_chg_cnt} onRemove={IS_EDIT ? null : cb_remove} />);
	}

    if (p_prods.length < 1)
    {
        if (rid === -1)
        {
            p_prods = <div className="fl_center_ho" style={{color: '#505050'}}>продукты не выбраны !</div>;
        }
        else
        {
            p_prods = null; // no products at all (transfer)
        }
    }

	
    
    let p_usable = null;
    
    if (sta.abon_logic === COM_DEFS.ABON_LOGIC_MONTHLY)
    {
        p_usable = (<>    <DaDateField nonull name='usable_dt_s' label='ДЕЙСТВУЕТ ОТ (включительно)' onChange={cb_chg}  value={sta.usable_dt_s} />
                          <DaDateField nonull name='usable_dt_e' label='ДЕЙСТВУЕТ ДО (включительно)' onChange={cb_chg}  value={sta.usable_dt_e} />
                    </>);
    }
	
    
    const DIS_SUBM      = sta.cu_id === null || (rid === -1 && sta.prod_rows.length < 1);
    const DIS_TRANSER   = !IS_EDIT;
    const DIS_RETURN    = !IS_EDIT;
    
    let descr_extra = null;
    

	if (sta.abon_logic === COM_DEFS.ABON_LOGIC_MONTHLY && !IS_EDIT)
	{
		const PR_ID = sta.prod_rows[0].pr_id;
		//console.log('ITS.... ', PR_ID);
		descr_extra = <MonthlyEventList pr_id={PR_ID} dt_s={sta.usable_dt_s} dt_e={sta.usable_dt_e} /> 
	}
	
    
    

    const DIS_CUST_SEARCH = IS_EDIT || sta.disable_cu_row_edit;
    
    // <CmCustomerSearch  disabled={DI} value={sta.cu_row} onChange={cb_chg_row} title='ДЛЯ КЛИЕНТА' />
    

    /*


    const pp_bonus_info = rid === -1 ? (
            <BonusCalcInfo  cu_id={sta.cu_id} paysWithBonus={ hk_pay.hasSpendBonus() } flatProdIdsCsv={ __ProdRowsToProdFlatIds(sta.prod_rows, sta.prod_cnts) }  />
         ) : <BonusCalcStoredInfo payId={rid}/>



         */


    const pp_bonus_info = <LaySpace />;
    


    const s_orig = DEFS.gAbonOriginVis[ sta.orig ];


    return (<>
            <LayVert2 rows='1fr max-content max-content' >
            
                <LayHoriz2 cols='1fr max-content 350px max-content 350px'  padding='16px' gap='6px' >
            
            
                    <LayVert2 rows='max-content max-content max-content 1fr' gap='16px' >
                        
                        
                        <DaAutoComplete label='ДЛЯ КЛИЕНТА' disabled={DIS_CUST_SEARCH} name='cu_id' value={sta.cu_id} initialRow={sta.cu_row_init} onChange={cb_chg} 
                            apiCmd={780}  itemCmp={ListItemCustNameAndPhone}  fld_k='cu_id' fld_v='cu_name' />
                        

                        <div style={{display: 'flex', gap: '10px'}}>
                            <DaNumericField name='price' value={sta.price} onChange={cb_chg} label='ИТОГОВАЯ ЦЕНА' min={0} max={999999}  />
                            <DaDateField nonull name='pay_dt' label='ДАТА ПОКУПКИ' onChange={cb_chg}  value={sta.pay_dt} />
                        </div>

                        <div style={{height: '40px', display: 'flex', gap: '10px'}}>
                            {p_usable}
                        </div>
                        
        
                        <DaVertScrollFrame faders>
                            <DaVertScrollJuice padding='20px' sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                        
                            {p_prods}
                        
                            { can_add_more ? 	(<div style={{display: 'flex', justifyContent: 'center'}}>
                                                    <DaIconBtn  icon={AddIcon} help='ДОБАВИТЬ ПРОДУКТ' onClick={cb_show_brow} clr='#50A050'  />
                                                </div>) 
                                                : null
                                                
                            }
                            </DaVertScrollJuice>
                        </DaVertScrollFrame>

      
                        
                    </LayVert2>

                    <DelimV />
                    
                    <LayVert2 rows='1fr' gap='4px' >
          
                        <DaVertScrollFrame faders>
                            <DaVertScrollJuice padding='16px' sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                             <CmProdDescription da_list={ sta.prod_rows } amounts={ sta.prod_cnts } extra={descr_extra} />

                            { pp_bonus_info }

                            <div style={{width: '100%', height: '1px', background: '#00000020'}}></div>
 
                            <DaCheck label='Не начислять бонусы' name='no_bonus_reward'
                                    value={sta.no_bonus_reward} onChange={cb_chg} />

                             </DaVertScrollJuice>
                        </DaVertScrollFrame>
                      
                    </LayVert2>

                    <DelimV />
                    
                    <LayVert2 rows='max-content max-content 1fr'  gap='30px'>
                     

                        <PayFormVis disabled={sta.price < 1} state={hk_pay} />

                        <LaySpace />
                        
                    </LayVert2>
                
                </LayHoriz2>

                <LayHoriz2 cols='700px max-content 1fr' gap='10px' padding='10px' sx={{borderTop: '1px solid #00000020', alignItems: 'center'}}>
                    <DaTextField label='КОММЕНТ / ЗАМЕТКИ' name='comcom' value={sta.comcom} onChange={cb_chg}/>
                    { rid === -1 ? null : <div style={{color: '#909090'}}>откуда абонемент: {s_orig}</div> }
                    <LaySpace />
                </LayHoriz2>
                
                <BottomButtonsPanel>
                
					{	(sta.abon_logic === COM_DEFS.ABON_LOGIC_NORMAL) ? (<>
							<DaButton disabled={DIS_TRANSER} onClick={cb_open_transfer} title='ПЕРЕНОС ТРЕНЬ...' icon={SwapHorizIcon} />
							<DaButton disabled={DIS_RETURN}  onClick={cb_open_ret}      title='ВОЗВРАТ...'       icon={CancelIcon} />
					
							<div sx={{width: '60px', height: '1px'}}></div>
						</>) : null
					}

                    {
                        (sta.abon_logic === COM_DEFS.ABON_LOGIC_MONTHLY) ? (

                            <TxAbonMonRetWrap onClickBtn={cb_open_ret_mon} rid={rid} />
                        ) : null
                    }
                
                    <DaButton disabled={DIS_SUBM} onClick={ IS_EDIT ? cb_submit_edit : cb_submit_new} title={ IS_EDIT ? 'СОХРАНИТЬ' : 'СОЗДАТЬ'} icon={ CheckIcon } />
                </BottomButtonsPanel>
            
            </LayVert2>
            
            <WinProductBrowser2 visible={sta.pop_brow} onClose={cb_close_brow} onOk={cb_brow_ok} /> 
            
            <WinRetAbonMon visible={sta.pop_ret_mon} rid={rid} onClose={cb_close_ret_mon} onOk={cb_ret_mon_ok} />
            </>
            
    );
}


function __ProdRowsToProdFlatIds( rows, cnts )
{
    const r = [];
    for (let x = 0; x < rows.length; x ++ )
    {
        for (let i = 0; i < cnts[x]; i ++ )
        {
            r.push( rows[x].pr_id );
        }
    }
    return r.join(',');
}





/*
                        <MyBlock tx='СКИДКИ' help='Зафиксировать скидки за этой покупкой.' >
                            <ViDiscountBind bindCtx={1} bindClub={1} bindId={7202} cuId={sta.cu_id} />
                        </MyBlock>
*/

function DelimV()
{
    return <b className="delim_v"></b>;
}


function MyBlock( {tx, help, children} )
{
    return (
        <LayVert2 rows='max-content 1fr' >
            <div style={{background: 'var(--fl-clr-primary)', fontWeight: 800, fontSize: '13px', height: '22px', paddingRight: '6px', paddingLeft: '10px', color: '#F0F0FF', justifyContent: 'space-between', display: 'flex', alignItems: 'center'}}>
                {tx}
                {help ? <CmHelpIcon small juice={help} /> : <LaySpace /> }
            </div>
            {children}
        </LayVert2>
    );
}




function __reducer(sta, act)
{
	switch(act.type)
	{
		case 'val':
		{
			return { ...sta, [act.k]: act.v };
		}
        
        case 'loaded':
        {
            const e = act.e;
            
            const s = { ...sta };
            
            s.busy          = false;
            s.price         = e.pay_price;
            s.cu_id         = e.cu_id;
            s.cu_row_init   = { cu_id: e.cu_id, cu_name: e.cu_name };
            s.pay_dt        = DT.DateTimeParseIso( e.pay_pay_time );
            s.abon_logic    = e.pay_logic;
            s.comcom        = e.pay_comment;
            s.orig          = e.pay_origin;

            s.no_bonus_reward = e.pay_bonus_no_reward;
            
			if (e.pay_logic === COM_DEFS.ABON_LOGIC_MONTHLY)
			{
                s.usable_dt_s = DT.DateTimeParseIso(e.pay_usable_dt_s);
                s.usable_dt_e = DT.DateTimeParseIso(e.pay_usable_dt_e);
			}
			
            
			
			const my_prods  = [];
			const my_amo    = [];
			
			for (let x = 0; x < e.prods.length; x ++ )
			{
				const i = HLP.ArrayOfDictsFindByPropValueIndex(my_prods, 'pr_id', e.prods[x].pr_id);

				if (i === -1)
				{
					my_prods.push( e.prods[x] );
					my_amo.push(1);
				}
				else my_amo[i] ++;
			}
            
            s.prod_rows = my_prods;
            s.prod_cnts = my_amo;
        


            
            
            return s;
        }
    
	}
	
	return sta;
}



function TabRets()
{
    return <div>TODO...</div>;
}


function TabTrans()
{
    return <div>TODO...</div>;
}

//--------------------------


//------------------------------------

function MyContent( {rid, onOk, initCuRow} )
{
    const [sta, dispatch] = React.useReducer( __reducer, {cu_row: initCuRow}, GenState );
    

    const hk_pay = usePayFormState( sta.cu_id, sta.price, sta.pay_dt );

    
    React.useEffect( () => {
        
        //console.log('CONTENT MOUNT ! RID: ', rid);
        if (rid === -1) return;
        
        //console.log('LOAD JUICE !');
        
        function __ok(e)
        {
            console.log('GOT SHIT FOR EDIT ', e);
            dispatch( {type: 'loaded', e:e.info} );

            hk_pay.readEnts( e.info.pf_ents.ents );


        }
        
        function __fail(e)
        {
            ShowErr(e.m);
            dispatch( {type: 'val', k:'busy', v:false} );
        }
        
        dispatch( {type: 'val', k:'busy', v:true} );
        
        NuPost2(4111, {rid:rid}, __ok, __fail);
        
    }, [] );
    
    
    const curTab = sta.tab;
    

	function cb_transfer_ok(nu_pay_id)
	{
		dispatch( {type: 'val', k:'pop_tra', v:false} );
		ShowMsg('ПЕРЕНЕСЕНО ! СОЗДАН АБОНЕМЕНТ ' + nu_pay_id);
		// close me
	}
    
    
	function cb_ret_ok()
	{
		dispatch( {type: 'val', k:'pop_rets', v:false} );
		ShowMsg('УСПЕХ !');
        // close me ?
	}
    
    
    
    
    function cb_close_tra()
    {
        dispatch( {type: 'val', k:'pop_tra', v:false} );
    }
	
    function cb_close_rets()
    {
        dispatch( {type: 'val', k:'pop_rets', v:false} );
    }
	

    

    const disCodes = (rid === -1) ? gDisCodesNu : gDisCodesEmpty;


    //     <TabFeatures tabCode='feats' rid={rid} />

    return (

        <>

        <DaTabs items={gTabItemsMy} initCode='main' arDisCodes={disCodes} >

            <TabMain tabCode='main' rid={rid} onOk={onOk} sta={sta} dispatch={dispatch} hk_pay={hk_pay} />
            { sta.abon_logic === COM_DEFS.ABON_LOGIC_NORMAL ? (<ViTrainsNormal tabCode='trains' rid={rid} />) : (<ViTrainsMonthly tabCode='trains'  rid={rid} />)  }

            <TabTrans tabCode='trans' />
            <TabRets tabCode='rets' />
            <TabMods tabCode='mods' rid={rid}  />
        

        </DaTabs>



        <OverlayLoading active={sta.busy} />

        <WinTrpTransfer2 visible={sta.pop_tra} onClose={cb_close_tra}  onOk={cb_transfer_ok} src_rid={rid} />
        
        
        <WinPayRets visible={sta.pop_rets} onClose={cb_close_rets}  onOk={cb_ret_ok} src_rid={rid}  />
        </>
    );

}


const gDisCodesEmpty    = [];
const gDisCodesNu       = [ 'trans', 'trains', 'rets', 'mods' ];        // 'feats'



const gTabItemsMy = [

    { c:'main',   n:'ОСНОВНОЕ' },
    { c:'trains', n:'ТРЕНИРОВКИ' },
    { c:'mods',   n:'МОДЫ/ДОПЛАТЫ' },
    { c:'trans',  n:'ПЕРЕНОСЫ' },
    { c:'rets',   n:'ВОЗВРАТЫ' },
   // { c:'feats',  n:'ДОП.УСЛУГИ'},

];



const gHelp1 = (<WinHelpConta>
    <WinHelpElem tit='ПЕРИОД' text={ ['Неделя - задача будет создаваться каждую неделю на указанный день недели.',
                                        'Месяц - задача будет создаваться каждый месяц на указанное число месяца.'] } />

    <WinHelpElem tit='ЗА СКОЛЬКО...' text='За сколько дней до целевой даты создавать задачу. 0 - день в день. 1 - за день и т.д' />
</WinHelpConta>);



export function WinEditAbon( {visible, rid, onClose, onOk, initCuRow} )
{

    return (
        
        <Win2 visible={visible} title={rid===-1 ? 'НОВАЯ ПОКУПКА АБОНЕМЕНТА' : `РЕДАКТИРОВАТЬ АБОНЕМЕНТ - ${rid}`} onClose={onClose} width={1220} height={660}  >
            <MyContent rid={rid} onOk={onOk} initCuRow={initCuRow} />
        </Win2>
    
    
    );
}


//---------------------------------------------

