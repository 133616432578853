import React from 'react';
import { useSelector} from 'react-redux'

import { CmComColPayStatus,  CmComColCellDate, CmComColCellDateTimeHoursMinutes } from '../cm_table_cell_com.js';

import { CommonReducer, ShowMsg, ShowErr } from '../misc_glob.js';

import * as DEFS from '../defs.js';
import * as COM_DEFS from '../com_defs.mjs';
import * as HLP from '../helpers.js';

import { Win2Confirm } from '../misc/win2_confirm.js';

import { ShortName } from '../desktop/short_name.js';

import { Grid3 } from '../grid3.js';

import { NuPost2 } from '../api.js';

import * as DT from '../dt.js';

import { DaButton } from '../misc/but.js';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import { WinEditAbon } from '../desktop/win_abon.js';

import { LayVert2, LayHoriz2, LaySpace } from '../misc/layout.js';

import { useGlobalShit } from '../v3/hk_global_shit.js';

import { CaAbonLeftovers } from '../abons/ca_abon_leftovers.js';
//------------------------------------------------------------------

function MyColProdsImp(pp)
{
    return <ShortName ctx={DEFS.SHORT_NAME_ABON} id={pp.row.pay_id} />;
}

const MyColProds = React.memo( MyColProdsImp );

//-------------------------------

function MyColTrpLeft(pp)
{
	
	const r = pp.row;
	
	if (r.pay_logic === COM_DEFS.ABON_LOGIC_MONTHLY)
	{
		return <div>∞</div>;
	}
    
    return <CaAbonLeftovers rid={r.pay_id} />;
}

function MyColUsable(pp)
{
	const v = pp.value;
	if (v === null) return <div className="faint">---</div>;
	return <div>{ DT.DateStrLoc( DT.DateTimeParseIso(pp.value) ) }</div>;
}



const gColPays = [
  { sort: true,  align: 'center',   fld: 'pay_id',          name: 'ID', width: 80},
  { sort: false, align: 'left',     fld: 'v1',              name: 'Продукт', width: 260, renderCell: MyColProds },
  { sort: true,  align: 'center',   fld: 'pay_pay_time',    name: 'Получен', width: 120, renderCell: CmComColCellDateTimeHoursMinutes},
  { sort: true,  align: 'center',   fld: 'pay_start_time',  name: 'Старт', width: 136, renderCell: CmComColCellDate},
  { sort:false,  align: 'left',     fld: 'cnt_left',        name: 'Осталось', width: 120, renderCell: MyColTrpLeft},
  { sort: true,  align: 'center',   fld: 'pay_status',      name: 'Статус', width: 150, renderCell: CmComColPayStatus},
  { sort: true,  align: 'center',   fld: 'pay_price',       name: 'Куплен за', width: 150},
  { sort: true,  align: 'left', 	fld: 'pay_usable_dt_s', name: 'Действует от', width: 150, renderCell: MyColUsable },
  { sort: true,  align: 'left', 	fld: 'pay_usable_dt_e', name: 'Действует до', width: 150, renderCell: MyColUsable },
  
];

const gST11 = {

    busy:  false,
    pop:    0,
    payId:  0,
    ser:    0,
    selected:   [],

    dct_pop_conf: {},
};


export function ViCustomerCardPaysView( {rid} )
{
    const [sta, dispatch] = React.useReducer( CommonReducer, gST11 );

	
	const sss = useGlobalShit();
	

	
	function cb_hide()
	{
		dispatch( {type: 'val', k:'pop', v:0} );
	}
	

	function cb_new()
	{
        dispatch( {type: 'vals', dct: {pop: 1, payId: -1}  } );
	}
    
    function cb_new_conf()
    {
        dispatch( {type: 'vals', dct: {pop: 0, ser:sta.ser+1}  } );
    }

    function cb_row_dbl(aa, bb)
    {
        dispatch( {type: 'vals', dct: {pop: 1, payId: aa}  } );
    }

    function cb_chg_sel(nu)
    {
        dispatch( {type: 'vals', dct: {selected: nu}  } );
    }

    function cb_del()
    {
		const SEL = sta.selected[0];
		
		const cb_confirm_del = () => {
		
            dispatch( {type: 'val', k:'pop', v:0} );
            dispatch( {type: 'val', k:'busy', v:false} );
            
            const __ok = (e) => {
				
				dispatch( {type: 'val', k:'busy', v:false} );
				dispatch( {type: 'val', k:'selected', v:[]} );
				dispatch( {type: 'val', k:'ser', v:sta.ser+1} );
				
				ShowMsg('АБОНЕМЕНТ УДАЛЁН !');
			};
			
            const __fail = (e) => {
				
				dispatch( {type: 'val', k:'busy', v:false} );
				ShowErr('FAIL ! ', e.m);
			};
			
			dispatch( {type: 'val', k:'busy', v:true} );
            
            NuPost2( 796, {pay_id: SEL}, __ok, __fail);

        }

        const TTT = {title: 'ТОЧНО УДАЛИТЬ ?', onOk: cb_confirm_del, onClose: cb_hide};
        
        dispatch( {type: 'val', k:'dct_pop_conf', v:TTT} );
		dispatch( {type: 'val', k:'pop', v:3} );
    }
	
    
    const FLT = React.useMemo( () => {
        return { cu_id: rid };
    }, [sta.ser] );
    
    const DIS_ADD = !sss.userHasAbil( 400 );
    const DIS_DEL = sta.selected.length < 1;
	
	
	return (<>
                <LayVert2 rows='max-content 1fr' padding='20px' gap='10px' >

                    <LayHoriz2 cols='max-content max-content 1fr' gap='10px'>
                        <DaButton onClick={cb_new}          disabled={DIS_ADD} title='НОВЫЙ АБОНЕМЕНТ' icon={AddIcon}  />
						<DaButton onClick={ cb_del }        disabled={DIS_DEL} title='УДАЛИТЬ' icon={RemoveIcon} />
                        <LaySpace />
                    </LayHoriz2>

                    <Grid3 cols={gColPays} isNuApi apiCmd={792}
                                    uniq='pay_id'  singleSel onDblClick={cb_row_dbl} onChangeSel={cb_chg_sel} 
                                    selected={sta.selected}
                                    sort_field='pay_pay_time' sort_dir='desc' flt={ FLT }
                                    code='cc_abons' />
						
                </LayVert2>
               
               <WinEditAbon rid={sta.payId} visible={sta.pop === 1} onClose={cb_hide}
                            onOk={cb_new_conf} initCuRow={ {cu_id: rid, cu_name: 'Этот клиент'} } />	


                <Win2Confirm busy={sta.busy} visible={sta.pop === 3} {...sta.dct_pop_conf} /> 
            </>
			
	);
}
