import React from 'react';

import * as HLP from '../helpers.js';

import Popover from '@mui/material/Popover'

import {DaList2} from './list2.js';

import { DaOutlinedShit } from './outlined.js';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

//-----------------------------------



export function DaCombo( {name, value, label, src, disabled, error, fld_k, fld_v, onChange, itemCmp, listExt} )
{
    const [isEdit, setIsEdit]       = React.useState( false );
    const [wdt, setWdt]             = React.useState( 100 );
    const [anchorEl, setAnchorEl]   = React.useState(null);
	
    
    if (!fld_k) fld_k = 'k';
    if (!fld_v) fld_v = 'v';

    const handleClose = () => {
        setAnchorEl(null);
        setIsEdit( false );
    };

    
    function cb_clk(ee)
    {
        const b = !isEdit;
        
        setIsEdit( b );
        
        if (b)
        {
            setAnchorEl(ee.currentTarget);
            setWdt(ee.currentTarget.clientWidth);
        }
        else setAnchorEl(null);
    }
    
    
    function cb_sel(k, vv)
    {
        //console.log('SEL ', vv);
        
        onChange(name, vv);
        
        setAnchorEl(null);
        setIsEdit( false );
    }

                    
                    
    const sty_wrp = {position: 'relative', cursor: disabled ? undefined : 'pointer', height: '36px', width: '100%'};



    let p_pop = null;
    let p_juice = null;
    
    if ( !disabled )
    {
        p_pop = (<Popover anchorEl={anchorEl}  open={isEdit} transformOrigin={gPopSx1} 
                                                    anchorOrigin={gQQ} onClose={handleClose} >
            
                    <div style={{width: wdt+'px', height: '300px'}}>
                        <DaList2 value={value}  src={src}  fld_k={fld_k} fld_v={fld_v} onChange={cb_sel} itemCmp={itemCmp} ext={listExt}  />
                    </div>
                </Popover>);
    }
    
    
    let t_val = HLP.ArrayOfDictsFindByPropValue(src, fld_k, value);
    if (t_val) t_val = t_val[fld_v];
    else t_val = value;
    
    let cur_click;
    
    if (!disabled)
    {
        cur_click = cb_clk;
        
        p_juice = (<div style={{userSelect: 'none', width: '100%', height: '100%', display: 'grid', gridTemplateRows: `1fr`, gridTemplateColumns: '1fr 26px',  justifyContent: 'center', alignItems: 'center'}}>
                        <div style={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', fontSize: '16px', paddingLeft: '10px', width: '100%'}}>{t_val}</div>
                        { isEdit ? <ArrowDropUpIcon /> : <ArrowDropDownIcon /> }
                    </div>);
    }
    else
    {
        cur_click = null;
        p_juice = (<div style={{userSelect: 'none', width: '100%', height: '100%', display: 'grid', gridTemplateRows: `1fr`, gridTemplateColumns: '1fr 26px',  justifyContent: 'center', alignItems: 'center'}}>
                        <div style={{textOverflow: 'ellipsis',  overflow: 'hidden', whiteSpace: 'nowrap', color: '#A0A0A0', fontSize: '16px', paddingLeft: '10px', width: '100%'}}>{t_val}</div>
                        { <ArrowDropDownIcon sx={{color: '#A0A0A0'}} /> }
                    </div>);
                    
    }

    return (
        <>
        <div onClick={cur_click} style={sty_wrp}>
            <DaOutlinedShit error={error} labelIsFloat={true} labelVal={label} borderIsFoc={isEdit} juice={p_juice} />
        
            
        </div>
        
        
        {p_pop}

        
        </>
        
    );
}




const gQQ = {vertical: 'bottom', horizontal: 'center'};


const gPopSx1 = {vertical: 'top', horizontal: 'center'};