 
import React from 'react';

import * as DEFS from '../defs.js';
import * as HLP from '../helpers.js';

import { NuPost2 } from '../api.js';

import { CmComboPayMethod } from './combo_pay_method.js';

import { ViReturnForm2, useReturnFormSate } from './ret_form2.js';

import { CommonReducer, ShowMsg, ShowErr } from '../misc_glob.js';

import {DaIconBtn} from '../misc/icon_btn.js'; 
import { LayVert2, LayHoriz2, LaySpace } from '../misc/layout.js';
import { OverlayLoading } from '../misc/ovr_loading.js';
import { DaVertScrollFrame, DaVertScrollJuice} from '../misc/vscr.js';
import { Win2 } from '../misc/win2.js';

import { DaNumericField } from '../misc/numeric.js';

import { DaButton } from '../misc/but.js';
import { DaTextField } from '../misc/text_field.js';

import Collapse from '@mui/material/Collapse';


import CheckIcon from '@mui/icons-material/Check';

import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';

import CloseIcon from '@mui/icons-material/Close';

import { ViReturnForm } from './ret_form.js';

import { GridAbonTrainsMon } from '../abons/vi_trains_info_mon.js';

//--------------------------------------------------------


const gSTST = {

    busy:       false,

    fld_mon:    0,
    fld_meth:   0,
    fld_com:    '',

};

//-------------------------------

function MyBody( {rid, onOk} )
{
    const [sta, dispatch] = React.useReducer( CommonReducer, gSTST );

    const hxRet = useReturnFormSate();


    function cb_submit()
    {
		function __ok(e)
		{
			dispatch( {type: 'val', k:'busy', v:false} );
			onOk();
		}
		
		function __fail(e)
		{
			dispatch( {type: 'val', k:'busy', v:false} );
			ShowErr(e.m);
		}
		
		dispatch( {type: 'val', k:'busy', v:true} );
		
        const pf = hxRet.packSV();

		NuPost2(1110, {	pay_id: rid,
						pf: pf
						
						}, __ok, __fail);
    }

    const DIS_SUB = hxRet.sta.comm.trim() === '';

    return (

        <LayHoriz2 cols='1fr 350px' gap='4px' >

            <LayVert2 rows='max-content 1fr' gap='10px' padding='16px' >
                <div>ИСТОРИЯ ТРЕНИРОВОК:</div>
               <GridAbonTrainsMon payId={rid} />

            </LayVert2>

            <LayVert2 rows='max-content 1fr max-content' padding='16px' >
                <ViReturnForm2 hxState={hxRet} />
                <LaySpace />
                <LayHoriz2 cols='1fr max-content'>
                    <LaySpace />
                    <DaButton disabled={DIS_SUB} title='ВОЗВРАТ' onClick={cb_submit} />
                </LayHoriz2>
            </LayVert2>


            <OverlayLoading active={sta.busy} />

        </LayHoriz2>

    );
}



                
// ПОПАП - возврат МЕСЯЧНОГО АБОНА
export function WinRetAbonMon( {visible, rid, onClose, onOk} )
{

    return (
        <Win2 visible={visible} title='ВОЗВРАТ МЕСЯЧНОГО АБОНЕМЕНТА' onClose={onClose} width={1100} height={540}  >
            <MyBody rid={rid} onOk={onOk} />
        </Win2>
        
        );
}



 
