import React from 'react';

import { DaButton } from './but.js';

import { LayVert2, LayHoriz2 } from './layout.js';

import { BottomButtonsPanel } from '../cust_card/bottom_pan.js';

import { DaTextField } from './text_field.js';

import { OverlayLoading } from './ovr_loading.js';

import { Win2 } from './win2.js';

//-------------



// поп ап - редактор текстового поля
export function Win2PopTextLineEdit( {visible, busy, title, initial, allowEmpty, onOk, onClose} )
{    
	return ( <Win2 visible={visible} title={title} width={560} height={168}   onClose={onClose} >
                <MyBody onOk={onOk} busy={busy} initial={initial} allowEmpty={allowEmpty} />
			</Win2>
		);
}


//----------------------------------------------------------


const gSty99 = {gap: '10px', padding: '20px'};

function MyBody( {busy, initial, allowEmpty, onOk} )
{
	const [val, setVal] = React.useState( initial?initial:'');
	
	const cb_chg = (k,v) => {
		setVal(v);
	};
	
	const cb_submit = () => {
		onOk(val);
	};
	
	const DIS = ( (!allowEmpty && val.trim() === '') ) || busy;

    
    return (    <>
                <LayVert2 rows='max-content max-content' sx={gSty99}>
    
                    <DaTextField  focusMe error={DIS}  value={val}   onChange={cb_chg} />
                
                
                    <BottomButtonsPanel>
                        <DaButton onClick={cb_submit} title='OK' disabled={DIS} />
                    </BottomButtonsPanel>
                
                </LayVert2>
                
                <OverlayLoading active={busy} />
                
                </>
    );
}

