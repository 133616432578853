import React from 'react';
import { DaButton } from './but.js';




function VertButtonGrpImp( {src, value, onClick} )
{
	const r = [];
	
	for (let x = 0; x < src.length; x ++ )
	{
		const e = src[x];
		const is_cur = value === e.val;
		r.push( <DaButton key={x} lite title={e.name} act={is_cur} onClick={ () => { onClick(e.val) } } /> );
	}

    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            {r}
        </div>
    );
}


export const VertButtonGrp = React.memo( VertButtonGrpImp );

