import React from 'react';

import * as DEFS from '../defs.js';
import * as COM_DEFS from '../com_defs.mjs';

import * as HLP from '../helpers.js';

import { OverlayLoading } from '../misc/ovr_loading.js';

import { OverlayErrMsg } from '../misc/ovr_err.js';

import { DaVertScrollFrame, DaVertScrollJuice } from '../misc/vscr.js';
import { DaFaderTop, DaFaderBottom } from '../misc/fader.js';

import { useGlobalShit } from '../v3/hk_global_shit.js';

import { Win2 } from '../misc/win2.js';
import { LayVert2, LayHoriz2 } from '../misc/layout.js';
import { DaButton } from '../misc/but.js';
import { DaDateField } from '../misc/date_field.js';

import { IsCustInList, IsCustInAban } from './shared.js';

import {   ShowMsg, ShowErr} from '../misc_glob.js';

import { TrpSelector } from './trp_selector.js';

import { NuPost2 } from '../api.js';

import * as DT from '../dt.js';

import { Win2Alert } from '../misc/win2_alert.js';
import { Win2PopTextLineEdit } from '../misc/win2_pop_line_edit.js';

import {DaToolTip} from '../misc/tooltip.js';
import {DaCheck} from '../misc/check.js';

import {DaComboHour} from '../misc/combo_hour.js';
import {DaTextField} from '../misc/text_field.js';
         
import { ComboWorkZones } from './combo_work_zones.js';
import { ComboMonthlyProducts } from './combo_montly_prods.js';
         

import { WinCustomerSearch } from '../desktop/win_cust_search.js';

import { EvActCombo} from '../desktop/ev_act.js';

import { BottomButtonsPanel } from '../cust_card/bottom_pan.js';

import HelpCenterRoundedIcon from '@mui/icons-material/HelpCenterRounded';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';


import { DudeList } from './dude_list_regular.js';

import { TrainersList } from './trainers.js';

import { Win2Confirm } from '../misc/win2_confirm.js';


import { STO_InvaUnclosedCalenNotify } from '../store.js';


import { useGlobEvActs } from '../misc_glob.js';

import { WinHelpConta, WinHelpElem } from '../misc/win_help.js'

const gHelp1 = (<WinHelpConta>
	<WinHelpElem tit='ПОЛЕ "ДВИЖ"' text='Что будет на событии. Из этого вытекает количество участников и ЗП тренера.' />
	<WinHelpElem tit='ПОЛЕ "ПРОДУКТ"' text='Используется с МЕСЯЧНЫМИ абонементами.' />
	<WinHelpElem tit='СГОРАНИЕ ТРЕНИ' text='Спец статус. Им отмечаем клиентов, потерявших тренировку по своей вине. Не засчитывается как реальный участник.' />
</WinHelpConta>);


//-----------------------------------------------------------

function FindTrpEntryByCustId(ll, a_cust_id)
{
    for (let x = 0; x < ll.length; x ++ )
    {
        if ( ll[x][2] == a_cust_id ) return x;
    }
    
    return -1;
}
    



//--------------------------------------------------



function __TryAutoFillTypes(sta)
{
	//console.log('__TryAutoFillTypes...');
    
    return sta;
    
    /*
    
	
	const aa = sta.lst_trps;
	
	if (aa.length < 1) return sta;
	
	// TODO ! HARDCODED SHIT
	if (sta.ty === 4)	// bypass on monthly
	{
		//console.log('BYPASS MON..');
		return sta;
	}
	
	let nu = { ...sta };
	
	let tt = __GetSuitableTrainTypeForNumDudes( aa.length );
	
	if (tt !== -1)
	{
		//console.log('got suitable ty..');
		nu.ty = tt;
	}
	
    
    for (let x = 0; x < aa.length; x ++ )
    {
        if (aa[x][3] === null) continue;
        
        if (aa.length > 1 && CA_DirsGotSpecial( aa[x][3].trp_dirs ))
        {
            console.log('skip special..');
            continue;
        }
        
        const dirz = HLP.CsvSplit( aa[x][3].trp_dirs );
        
        if (dirz.length === 1)
        {
			nu.di = dirz[0];
            break;
        }
    }

	
	return nu;
    */
}


function __reducer(sta, act)
{
	switch(act.type)
	{
		case 'val':
		{
			const nu = { ...sta };
			
			nu[act.k] = act.v;
			
            /*
			if (act.k === 'mon_prod_id' && act.v > 0)
			{
				//console.log('ITS MON !');
				nu.monthly_mode = true;
			}
            */
			
			return nu;
		}
		
		case 'got_row':
		{
			const q = act.e;
			//console.log('got ro ', q);
			
			let i = FindTrpEntryByCustId(sta.lst_trps, q.trp_customer);
			if (i === -1)
			{
				console.log('got ro but no dude... ');
				break;
			}
			
			let e = sta.lst_trps[i];
			e[3] = q;
			
			let nu = { ...sta };
			nu.lst_trps = HLP.DeepCopy( nu.lst_trps );
			
			// try fill

			nu = __TryAutoFillTypes(nu);
			

			return nu;
		}
		
		case 'remove_dude':
		{
			const cu_id = act.cu_id;
	
			let i = -1;
			
			let ll = sta.lst_trps;
			for (let x = 0; x < ll.length; x ++ )
			{
				let e = ll[x];
				if (e[2] === cu_id)
				{
					i = x;
					break;
				}
			}
			
			if (i === -1)
			{
				console.warn('WTF ? no INDEX !!!');
				return sta;
			}
			
			ll.splice(i, 1);
			
			return { ...sta, lst_trps: HLP.DeepCopy(ll) };
		}

		case 'cancel_burn':
		{
			const trp_id = act.trp_id;

			let ll = sta.lst_trps;
			for (let x = 0; x < ll.length; x ++ )
			{
				let e = ll[x];
				if (e[0] === trp_id)
				{
					e[1] = DEFS.TRP_STATUS_BOUND;
					
					return { ...sta, lst_trps: HLP.DeepCopy(ll) };
				}
			}	

			break;
		}
		
		case 'toggle_sta':
		{
			const trp_id = act.trp_id;
		
			let ll = sta.lst_trps;
			for (let x = 0; x < ll.length; x ++ )
			{
				let e = ll[x];
				if (e[0] === trp_id)
				{
					e[1] = e[1] === 1 ? 2 : 1;
					
					return { ...sta, lst_trps: HLP.DeepCopy(ll) };
				}
			}	
			
			break;
		}

		case 'set_burn':
		{
			const trp_id = act.trp_id;
		
			let ll = sta.lst_trps;
			for (let x = 0; x < ll.length; x ++ )
			{
				let e = ll[x];
				if (e[0] === trp_id)
				{
					e[1] = DEFS.TPR_STATUS_BURNED;
					
					return { ...sta, lst_trps: HLP.DeepCopy(ll) };
				}
			}	

			break;
		}
		
		case 'mon_dudes':
		{
			const e = act.e;
			
			let nu = [];
			
			for (let x = 0; x < e.lst_trps.length; x ++ )
			{
				let q = e.lst_trps[x];
				nu.push( [q.trp_id, DEFS.TRP_STATUS_BOUND, q.cu_id, null ] );
			}
			
			
			return { ...sta, lst_trps: HLP.DeepCopy(nu) };
		}

		case 'mon_dudes_sing':
		{
			const e = act.e;

			//console.log('DUDES SING !!!! ', e);

			let add = [];
			

			for(;;)
			{
				if (FindTrpEntryByCustId(sta.lst_trps, e.cuId) === -1)
				{
					if (IsCustInAban(sta.lst_aban, e.cuId))
					{
						//console.log('  ITS ABAN.. SKIP !');
						break;
					}
					
					add.push( [e.trpId, DEFS.TRP_STATUS_BOUND, e.cuId, null ] );
				}
			
				break;
			}

			if (add.length > 0)
			{
				ShowMsg('ДОБАВЛЕНО !');
			}
			else
			{
				ShowMsg('БЕЗ ИЗМЕНЕНИЙ...');
			}
			
			

			return { ...sta, lst_trps: sta.lst_trps.concat(add)  };
		}
		
		// дополнить клиентов
		case 'mon_dudes_expand':
		{
            //
			
			const e = act.e;

			console.log('expaxnd...', e);
			
			let add = [];
			
			for (let x = 0; x < e.lst_trps.length; x ++ )
			{
				let q = e.lst_trps[x];
				if (FindTrpEntryByCustId(sta.lst_trps, q.cu_id) === -1)
				{
					console.log('CU %d is missing.. add', q.cu_id);
					
					// check if in abandons...888
					
					if (IsCustInAban(sta.lst_aban, q.cu_id))
					{
						//console.log('  ITS ABAN.. SKIP !');
						continue;
					}
					
					add.push( [q.trp_id, DEFS.TRP_STATUS_BOUND, q.cu_id, null ] );
				}
			}
			
			if (add.length > 0)
			{
				ShowMsg('ДОБАВЛЕНО: ' + add.length);
			}
			else
			{
				ShowMsg('БЕЗ ИЗМЕНЕНИЙ');
			}
			
			return { ...sta, lst_trps: sta.lst_trps.concat(add) };
		}
		
		case 'load':
		{
			const e = act.e;
			const rr = e.row;
			
			let nu = { ...sta };
			
			nu.status = rr.ts_status;
			nu.comment = rr.ts_impo_text;
            nu.act = rr.ts_activity;
			nu.date_start = DT.DateTimeParseIso( rr.ts_time_start );
			nu.zones = rr.ts_work_zones;
			nu.is_conc = rr.ts_is_concrete_trainer;
			nu.user_id = rr.ts_user_id;
			nu.mon_prod_id = rr.ts_for_prod_id;
			nu.last_chg = rr.ts_time_last_change;
            nu.created = rr.ts_time_created;
			nu.mon_prod_id = rr.ts_for_prod_id;
			
			
			{
				let t = [];
				for (let x = 0; x < e.trps.length; x ++ )
				{
					const s = e.trps[x];
					t.push( [ s.trp_id, s.trp_status, s.trp_customer, null ]  );
				}
				nu.lst_trps = t;
			}
			
			{
				let t = [];
				for (let x = 0; x < e.abans.length; x ++ )
				{
					const s = e.abans[x];
					t.push( [s.ab_cust_id, s.ab_acts_csv, s.ab_pay_id, s.ab_comment, s.ab_is_guilty ]  );
				}
				nu.lst_aban = t;
			}
			
			return nu;
			
		}
	}
	
	return sta;
}



// STATE
function __GetInit( date_start_iso )
{
    const dd = DT.DateTimeParseIso( date_start_iso );
    
    //console.log('parsed.. dd ', dd);
    
	return { 
		
		status:		COM_DEFS.TS_STATUS_OPEN,
		date_start: dd, // Date
		zones:		1,
		comment:	'',

        act:        -1,
        
		is_conc:	false,
		user_id:	-1,
		
		mon_prod_id	: 0,
		
        lst_trps: [  ], // [ trp_id, trp_status, cust_id, (NULL/row) ]
        lst_aban: [  ], // [cu_id, acts, pay_id, comment, guilty]
        
        trp_serial: 0,
		
		//monthly_mode:false,
		
		last_chg: null,		// ts chg
        created: null,      // ts created
		
		busy:		false,
		
		dirty:		false,
		
		tmp_cust_id:	-1,
		tmp_id:			null,
		tmp_aban:		null,
		
		err:			false,
		errStr:			'',
        
        pop:            0,
        pop_dct_search: {},
        pop_dct_conf:   {},
        pop_dct_alert:  {},
        pop_dct_trp_sel:{},
        pop_dct_line:   {},
	};
}



//---------------------------------------------------



// ОКНО - редакт событие
function WinEventBody( {onOk, rid, date_start_iso} )
{
    //console.log('WinEvent2  RID ', rid, ' - DS ', date_start_iso);
    
	const [sta, dispatch] = React.useReducer( __reducer, date_start_iso, __GetInit );
    
    const hk_act = useGlobEvActs();
	
    const sss = useGlobalShit();

	//console.log('SSS ', sss);

	//console.log('CI: ', CI);
	
	
	React.useEffect( () => {
		
		//console.log('IN event mount ! ', rid);
		
		if (rid !== -1)
		{
			function __ok(e)
			{
				dispatch( {type: 'load', e:e } );
				dispatch( {type: 'val', k:'busy', v:false } );
			}
			
			function __fail(e)
			{
				ShowErr(e.m);
				dispatch( {type: 'val', k:'busy', v:false } );
				dispatch( {type: 'val', k:'errStr', v:e.m } );
				dispatch( {type: 'val', k:'err', v:true } );


			}
			
			NuPost2( 860, {ts_id: rid}, __ok, __fail);
			
			dispatch( {type: 'val', k:'busy', v:true } );
		}
		
	}, [] );
	
	

	function cb_del()
	{
		

		
		function __conf()
		{
			function __ok(e)
			{
				dispatch( {type: 'val', k:'busy', v:false } );
				ShowMsg('СОБЫТИЕ УДАЛЕНО !');
				onOk();
			}
			
			function __fail(e)
			{
				dispatch( {type: 'val', k:'busy', v:false } );
			}
			
			dispatch( {type: 'val', k:'busy', v:true } );

			NuPost2( 870, {sched_id: rid}, __ok, __fail);
		}

		const TTT = { title: 'ТОЧНО УДАЛИТЬ СОБЫТИЕ ?', onOk: __conf, onClose: cb_close_pop };

		dispatch( {type: 'val', k:'pop_dct_conf', v:TTT } );
		dispatch( {type: 'val', k:'pop', v:4 } );
	}
	
	function cb_save()
	{
		//console.log('SAVE !');
		
        const oo = {};
        
        oo.props = {};
        oo.dudes = [];
        oo.abans = [];
        
        //console.log('PREPARE TO SAVE ', sta);
        
        const dt_end = DT.DateAddHours(sta.date_start, 1);
        
        //console.log('END ', dt_end);
        
        oo.props.ts_for_prod_id = sta.mon_prod_id;
        oo.props.ts_work_zones = sta.zones;
        oo.props.ts_activity    = sta.act;
        oo.props.ts_user_id    = sta.user_id;
        oo.props.ts_id         = rid;
        oo.props.ts_impo_text  = sta.comment;
        oo.props.ts_time_start = DT.DateTimeStrIso(sta.date_start);
        oo.props.ts_time_end   = DT.DateTimeStrIso( dt_end );
        oo.props.ts_is_concrete_trainer = sta.is_conc;

        const lll = sta.lst_trps;
        for (let x = 0; x < lll.length; x ++ )
        {
            const e = lll[x];
            oo.dudes.push( {trp_id: e[0], trp_status: e[1]}  );
        }
        

        const aaa = sta.lst_aban;
        for (let x = 0; x < aaa.length; x ++ )
        {
            const e = aaa[x];
            oo.abans.push( {acts: e[1], pay_id: e[2], cu_id: e[0], comm: e[3], guilty: e[4]}  );
        }

		dispatch( {type: 'val', k:'busy', v:true } );
		
		
		function __ok(e)
		{
			dispatch( {type: 'val', k:'busy', v:false } );
			ShowMsg('УСПЕХ !');
			STO_InvaUnclosedCalenNotify();
			onOk();
		}
		
		function __fail(e)
		{
			dispatch( {type: 'val', k:'busy', v:false } );
			
			ShowErr( e.m );
		}
		
        NuPost2( 861, oo, __ok, __fail );

	}
	

	const cb_chg = React.useCallback( (k,v) =>
	{
        //console.log('--- %s %s', k, v);
        
		dispatch( {type: 'val', k:'dirty', v:true } );
		dispatch( {type: 'val', k:k, v:v } );
		
	}, [] );
	
	function cb_close_pop()
	{
		dispatch( {type: 'val', k:'pop', v: 0 } );
	}
	
    function cb_selected_trp(a_trp, a_cust_id, a_was_modified)
    {
        //console.log('GOT TRP: ', a_trp, ' -- CUST: ', a_cust_id);

        if ( !IsCustInList(sta.lst_trps, a_cust_id) )
        {
            let oo = HLP.DeepCopy( sta.lst_trps );
            oo.push( [a_trp, 1, a_cust_id, null] );
            
            //this.TryAutoFillTypes( oo );

            dispatch( {type: 'val', k:'lst_trps', v: oo } );
            dispatch( {type: 'val', k:'pop', v: 0 } );
            
        }
        else
        {
            let i = FindTrpEntryByCustId(sta.lst_trps, a_cust_id);
            if (i === -1)
            {
                console.warn('WTF ?????');
                return;
            }
            
            let oo = HLP.DeepCopy( sta.lst_trps );
            oo[i][0] = a_trp;
            
            //this.TryAutoFillTypes( oo );
            
            

            dispatch( {type: 'val', k:'lst_trps', v: oo } );
            dispatch( {type: 'val', k:'pop', v: 0 } );
            
            if (a_was_modified)
            {
                console.log(' !!! TODO..... FORCE REFRESH !');

                dispatch( {type: 'val', k:'trp_serial', v: sta.trp_serial + 1 } );
				
            }
            
            // swap trp in existing cust
            // duplicate cust.. show ALERT
            
            //this.setState( {mode_pop: 3} );
        }
    }

	

    //const hmin = CI.work_hours_mi_ma[0];
    //const hmax = CI.work_hours_mi_ma[1];
	
	const hmin = sss.workHoursTotMin;
	const hmax = sss.workHoursTotMax;

	console.log('HOURS... ', hmin, ' -- ', hmax);

	const s_tit = rid === -1 ? 'НОВОЕ СОБЫТИЕ' : 'РЕДАКТИРОВАТЬ СОБЫТИЕ ';
	
	
	//const DIS_MON_PRODS = sta.ty !== 4  ||  sta.di < 1 || sta.monthly_mode === true;	// TODO ! HARDCODED
	
    const CUR_ACT = hk_act.getById( sta.act );
    
    let DIS_MON_PRODS = true;
    let IS_MONTHLY_MODE = false;
    
    if (CUR_ACT && (CUR_ACT.ea_for_abon_logic === COM_DEFS.ABON_LOGIC_MONTHLY))
    {
        // monthly_mode
        if (sta.mon_prod_id < 1)
        {
            DIS_MON_PRODS = false;
        }
        IS_MONTHLY_MODE = true;
    }
    
	const DIS_REVERT = true ; //sta.dirty === false;
	
	const DIS_DEL = rid === -1;

	let p_right = ( 
						<DudeList   prod_id={sta.mon_prod_id}
                                        mon_mode={IS_MONTHLY_MODE}
                                        start={ DT.DateTimeStrIso(sta.date_start) }
										lst_trps={sta.lst_trps}
                                        lst_aban={sta.lst_aban}
                                        onSelectedTrp={cb_selected_trp}
										disp={dispatch} rid={rid} 
										trp_serial={sta.trp_serial}  />  );
	

       
       

	const all_cust_ids = React.useMemo( () => {
		
		let r = [];
		
		for (let x = 0; x < sta.lst_trps.length; x ++ )
		{
			r.push( sta.lst_trps[x][2] );
		}
		
		return r;
		
	}, [sta.lst_trps] );
	
	
	let p_ts_sta;
	
	if (rid !== -1)
	{
		const t0 = DT.DateTimeStrLoc( DT.DateTimeParseIso(sta.last_chg) );;
        const t1 = DT.DateTimeStrLoc( DT.DateTimeParseIso(sta.created) );
        
        const p_help = (
                            <div>
                                <div>ID - {rid}</div>
                                <div>СОЗДАНО - {t1}</div>
                                <div>ИЗМЕНЕНО - {t0}</div>
                            </div>
        );
        
		p_ts_sta = (<>
                        <span>{ DEFS.gSchStatusDisp[sta.status] }</span>
                        <DaToolTip title={p_help}>
                            <HelpCenterRoundedIcon sx={{fontSize: '18px', color: '#A0A0B0'}}/>
                        </DaToolTip>
                    </>);
	}
	else
	{
		p_ts_sta = <span>---</span>;
	}
	
	
	
	
	let p_juice = null;
    

    
    const DIS_TIME_PICK = !sss.userHasAbil(250);
	const DIS_CONC_TRA  = !sss.userHasAbil(251);
    const DIS_TRA_SEL   = !sss.userHasAbil(253);


    p_juice = (
                <LayVert2 rows='max-content 1fr max-content' >
                    <div className="zz_text_secondary" style={{display: 'flex', alignItems: 'center', gap: '10px', padding: '10px'}}><span>СТАТУС:</span> {p_ts_sta}</div>
                    
                    <LayHoriz2 cols='minmax(300px,400px) 1fr' >
                    
                        <LayVert2 rows='max-content max-content max-content 1fr max-content 1fr'  padding='12px' gap='12px' >
                            
                            <LayHoriz2 cols='1fr 90px'  gap='10px' >
                                <DaDateField keepHMS disabled={DIS_TIME_PICK} name='date_start' value={sta.date_start}  onChange={cb_chg}  />
                                <DaComboHour name='date_start' hmin={hmin} hmax={hmax}  disabled={DIS_TIME_PICK}  value={sta.date_start} onChange={cb_chg}  />
                            </LayHoriz2>
                        
    
                            
                            <EvActCombo mode='event' disabled={IS_MONTHLY_MODE} label='ДВИЖ'  name='act' value={sta.act} onChange={cb_chg} />
                            
                            <LayHoriz2 cols='1fr 1fr'  gap='10px' >
                                <ComboWorkZones value={sta.zones} name='zones'  onChange={cb_chg} />
                                <ComboMonthlyProducts name='mon_prod_id' value={sta.mon_prod_id} disabled={DIS_MON_PRODS} act={sta.act} onChange={cb_chg} />
                            </LayHoriz2>
    
                            <DaTextField multiline  label='КОММЕНТ' name='comment' value={sta.comment} onChange={cb_chg}  />
                            
                            <DaCheck disabled={DIS_CONC_TRA} value={sta.is_conc} label='К конкретному тренеру' onChange={cb_chg} name='is_conc' />
                            
    
                            <TrainersList disabled={DIS_TRA_SEL} curId={sta.user_id}
                                                name='user_id' all_cust_ids={all_cust_ids}
                                                act={sta.act}  dt={ DT.DateTimeStrIso(sta.date_start) } ts_id={rid}  onChange={cb_chg} />
							
                        
                        </LayVert2>
                    
                        <div style={{width: '100%', height: '100%', position: 'relative'}}>
                            <DaVertScrollFrame>
                                <DaVertScrollJuice sx={gSxJuice}>
                                    {p_right}
                                </DaVertScrollJuice>
                            </DaVertScrollFrame>
                        
                            <DaFaderTop />
                            <DaFaderBottom />

                        </div>
                    
                    </LayHoriz2>
                    
					<BottomButtonsPanel>
						<DaButton title='УДАЛИТЬ'   onClick={cb_del}  disabled={sta.busy || DIS_DEL} icon={DeleteIcon}  />
						<DaButton title='СОХРАНИТЬ' onClick={cb_save} disabled={sta.busy} icon={CheckIcon}   />
					</BottomButtonsPanel>
                
                    <OverlayLoading active={sta.busy} />

					<OverlayErrMsg active={sta.err} msg={sta.errStr}/>
                
                </LayVert2>
    
    
    
    );

	
	return ( <>	
                {p_juice}
	

                <WinCustomerSearch  visible={sta.pop === 1} {...sta.pop_dct_search} />
                
                <Win2Confirm    busy={sta.busy}    visible={sta.pop === 4}  {...sta.pop_dct_conf}  />
                
                <Win2Alert          visible={sta.pop === 3} {...sta.pop_dct_alert} />
                
                <TrpSelector        visible={sta.pop === 2} {...sta.pop_dct_trp_sel} />
                
                <Win2PopTextLineEdit visible={sta.pop === 5}  {...sta.pop_dct_line}  />
				
				</>
			);
}

const gSxJuice = {padding: '12px'};




//---------------------------


//-----------------------

export function WinEvent2( {visible, onClose, onOk, rid, date_start} )
{
    
	return ( <Win2 help={gHelp1} visible={visible} title={ rid===-1 ? 'НОВОЕ СОБЫТИЕ' : `РЕДАКТИРОВАТЬ СОБЫТИЕ #${rid}` } width={1080} height={638}   onClose={onClose} >
                <WinEventBody onOk={onOk} rid={rid} date_start_iso={date_start}  />
			</Win2>
		);
}


