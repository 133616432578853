import React from 'react';

import { CommonReducer, ShowMsg, ShowErr } from '../misc_glob.js';

import { OverlayLoading } from '../misc/ovr_loading.js';

import { DaCheck } from '../misc/check.js';

import * as COM_DEFS from '../com_defs.mjs';

import { NuPost2 } from '../api.js';

import { LayVert2, LayHoriz2, LaySpace } from '../misc/layout.js';


import { BottomButtonsPanel } from './bottom_pan.js';
import { DaButton } from '../misc/but.js';

import SaveIcon from '@mui/icons-material/Save';


import { CustomerCsvList } from '../desktop/cust_list_csv.js';

const gST = {

    [COM_DEFS.CU_EX_FIELD_DISABLE_RESERVE]: 0,
    [COM_DEFS.CU_EX_FIELD_CHILDREN_CSV]: '',

    busy:   false,
};


export function ViCustomerCardMisc1( {rid} )
{
    const [sta,dispatch] = React.useReducer( CommonReducer, gST );


    React.useEffect( () => {

        function __ok(e)
        {
            dispatch( {type: 'val', k: 'busy', v: false} );

            dispatch( {type: 'vals', dct: e.ex} );
        }

        function __fail(e)
        {
            dispatch( {type: 'val', k: 'busy', v: false} );
        }

        dispatch( {type: 'val', k: 'busy', v: true} );

        NuPost2( 4105, {rid:rid}, __ok, __fail );

    }, [] );


    function cb_save()
    {
        function __ok(e)
        {
            ShowMsg('СОХРАНЕНО !');
            //console.log('SAVED  ', e);
            dispatch( {type: 'val', k: 'busy', v: false} );
        }

        function __fail(e) 
        {
            ShowErr(e.m);
            dispatch( {type: 'val', k: 'busy', v: false} );
        }

        dispatch( {type: 'val', k: 'busy', v: true} );

        const dct = { };
        dct[ COM_DEFS.CU_EX_FIELD_DISABLE_RESERVE ] = sta[ COM_DEFS.CU_EX_FIELD_DISABLE_RESERVE ];
        dct[ COM_DEFS.CU_EX_FIELD_CHILDREN_CSV ]    = sta[ COM_DEFS.CU_EX_FIELD_CHILDREN_CSV ];

        NuPost2( 4106, {rid:rid, dct: dct }, __ok, __fail );
    }

    const cb_chg = React.useCallback( (k,v) =>
    {
        dispatch( {type: 'val', k: k, v: v} );
    }, [] );

    const DIS_SAVE = false;

 
    return (<LayVert2 rows='1fr max-content' gap='10px' >

                <LayHoriz2 cols='300px 400px 1fr' padding='20px' gap='30px' >
            
 
         
                    <CustomerCsvList name={COM_DEFS.CU_EX_FIELD_CHILDREN_CSV} label='ДЕТИ (ДЛЯ ТЕЛЕГРАМ БОТА)'
                                    valCsv={sta[COM_DEFS.CU_EX_FIELD_CHILDREN_CSV]} onChange={cb_chg}  />   
  

                    <LayVert2 rows='max-content 1fr' gap='20px'>

                    

                        <DaCheck  name={COM_DEFS.CU_EX_FIELD_DISABLE_RESERVE} 
                                    value={sta[COM_DEFS.CU_EX_FIELD_DISABLE_RESERVE]}    
                                    label='Запретить БРОНЬ (уже заброненые будут висеть)' onChange={cb_chg} />


                        <LaySpace />
                            
                    
                    </LayVert2>
                    <LaySpace />
                </LayHoriz2>

				<BottomButtonsPanel>
					<DaButton disabled={DIS_SAVE} onClick={cb_save} icon={SaveIcon} title='СОХРАНИТЬ'/>
				</BottomButtonsPanel>


                <OverlayLoading active={sta.busy} />

            </LayVert2>);
}
