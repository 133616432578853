 


 
export const FL_VAR_KEY_CALEN_DATE_START = 'fl_calen_ds';
 
export const FL_CUST_MAX_POINTS = 5;

export const FIGHTLIFE_SESSION_COOKIE = 'FL_SESSION';

export const FL_API_VERSION			  = 101;
 
 
export const CUST_FIELD_VAL_NONE = '[NONE]';
 
// TODO: rem
 
export const MONTH_NAMES_ARR = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];


export const MONTH_NAMES_ARR_SH = ['ЯНВ', 'ФЕВ', 'МАР', 'АПР', 'МАЙ', 'ИЮН', 'ИЮЛ', 'АВГ', 'СЕН', 'ОКТ', 'НОЯ', 'ДЕК'];

export const WEEK_DAYS = ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ' ,'ВС' ];


export const TASK_GEN_PERIOD_NULL      = 0;
export const TASK_GEN_PERIOD_WEEK      = 1;
export const TASK_GEN_PERIOD_MONTH     = 2;

export const PAY_METHOD = [

    { k: 0, n: 'НЕИЗВЕСТНО',   },
    { k: 1, n: 'НАЛИЧНЫЕ',     },
    { k: 2, n: 'КАРТА',        },
    { k: 3, n: 'QR КОД',       },
    { k: 4, n: 'ПЕРЕВОД',      },
    { k: 5, n: 'ПО СЧЁТУ',     },
];


//---------------------------------------------------------------



//-------------------------------------------------

export const TASK_STATUS_OPEN		= 0;
export const TASK_STATUS_CLOSED		= 1;
export const TASK_STATUS_DELETED	= 2;

export const gTaskStatusDisp = [ 'ОТКРЫТА', 'ЗАКРЫТА', 'УДАЛЕНА' ];


 

//--------------------------------------------------------------------------

// ТРЕНИ ИЗ ПУЛА

export const TRP_STATUS_UNUSED		= 0;	// незаюзанная
export const TRP_STATUS_BOUND		= 1;	// забита на дату
export const TRP_STATUS_CLOSED		= 2;	// закрыта
export const TRP_STATUS_RETURNED	= 3;	// возврат
export const TPR_STATUS_BURNED      = 4;    // сгорела

export const TRP_STATUS_ABANDON      = 99;   //

export const gTrpStatusDisp = [
    'НЕ ИСП',
    'ЗАБИТА НА ДАТУ',
    'ЗАКРЫТА',
    'ВОЗВРАТ',
    'СГОРЕЛА',
];

export const gTrpStatusClr     = ['#309040', '#4070DF', '#A0B0D0', '#705050', '#A08030'];

//----------------------------------------------------------------------------

export const gSchStatusDisp = [

	'ОТКРЫТА',
	'ЗАКРЫТА',
	'ОТМЕНЕНА',

];

//---

export const gCustTrackCauseVis = [
        'ПРОБНИК',
        'НЕ ХОДИТ (НЕТ ТРЕНЬ)',
        'НЕ-ХОДИТ (ЕСТЬ ТРЕНИ)' ];

export const gCustTrackStatusVis = [ 'В РАБОТЕ', 'ЗАКРЫТО' ];

export const gCustTrackStepTypeVis = [
        'Отправить сообщение',
        'Позвонить',
        'Выставить статус клиента и закрыть'
        ];
        
        
//----

export const BALANCE_ACT_DEPOSIT    = 0;
export const BALANCE_ACT_SPEND      = 1;
export const BALANCE_ACT_CHANGE     = 2;

export const gBalanceActDisp = [
    'ДЕПОЗИТ',
    'ТРАТА',
    'СДАЧА',
];





export const BONUS_ACTION_REWARD  = 0;  // кэшбэк/вознаграждение
export const BONUS_ACTION_SPEND   = 1;  // трата бонусов
export const BONUS_ACTION_CHANGE  = 2;  // сдача
export const BONUS_ACTION_RETURN  = 3;  // возврат на бонусы
export const BONUS_ACTION_DEPOSIT = 4;  // прямо депозит на счёт



export const gBonusActVis = [

    'КЭШ-БЭК',
    'ТРАТА',
    'СДАЧА',
    'ВОЗВРАТ НА БОНУСЫ',
    'ДЕПОЗИТ',

];

export const BONUS_DEF_CTX_ABON    = 0; // нахуй ?







//----------------------------



//----------------------------------------------------

// УДОБНЫЙ способ связи для клиента
//export const CU_CONTACT_PREF_UNK      = 0;
export const CU_CONTACT_PREF_DONT     = 1;
export const CU_CONTACT_PREF_SMS      = 2;
export const CU_CONTACT_PREF_WHATSUP  = 3;
export const CU_CONTACT_PREF_TG       = 4;
export const CU_CONTACT_PREF_VK       = 5;


export const gSrcCustContactPref = [

    //{ k: CU_CONTACT_PREF_UNK,       v: '???' },
    { k: CU_CONTACT_PREF_DONT,      v: 'НЕ ПИСАТЬ' },
    { k: CU_CONTACT_PREF_WHATSUP,   v: 'WHATS UP' },
    { k: CU_CONTACT_PREF_TG,        v: 'TELEGRAM' },
    { k: CU_CONTACT_PREF_VK,        v: 'VK' },
    { k: CU_CONTACT_PREF_SMS,       v: 'SMS' },
];

//---------------------------------------------------

export const CU_GENDER_MALE			= 0;
export const CU_GENDER_FEMALE		= 1;
export const CU_GENDER_UNK			= 2;

export const gGenderDisp = [
	'МУЖЧИНА',
	'ЖЕНЩИНА',
	'???',
];

export const gSrcGender = [  
            {k: CU_GENDER_UNK, 	    v: '???'},
            {k: CU_GENDER_MALE, 	v: 'МУЖЧИНА'},
            {k: CU_GENDER_FEMALE, 	v: 'ЖЕНЩИНА'}
            ];

//------------------------------------------

// рабочая рука
export const CU_HAND_R				= 0;
export const CU_HAND_L 				= 1;
export const CU_HAND_UNK			= 2;
export const CU_HAND_BOTH			= 3;


export const gHandDisp = [
	'ПРАВАЯ',
	'ЛЕВАЯ',
	'???',
	'АМБИДЕКСТР',
];


//---------------------------


// короткое описание (контекст)
export const SHORT_NAME_GOOD_SELL               = 10;   // проданный товар
export const SHORT_NAME_ABON                    = 11;   // купленый абон
export const SHORT_NAME_PROD                    = 12;   // продукт (абон)
export const SHORT_NAME_BALANCE_OP_OWNER        = 13;   // владелец допозита баланса
export const SHORT_NAME_CLUB                    = 14;
export const SHORT_NAME_CUST                    = 15;

export const SHORT_NAME_TRP_MOD                 = 20;   // мод трени ..  id - trp


//--------------------------


export const SUB_PANEL_MASTER      = 0;
export const SUB_PANEL_AD          = 1;
export const SUB_PANEL_CLUB        = 2;


//------------------ tg bot access state ---------

export const TG_BOT_ACC_ST_NONE       = 0;
export const TG_BOT_ACC_ST_WAIT_LINK  = 1;  // ожидаем прохода по ссылке
export const TG_BOT_ACC_ST_WAIT_CODE  = 2;  // ожилаем 4х знач код
export const TG_BOT_ACC_ST_GOOD       = 3;  // можем пользоваться ботом

export const TG_BOT_URL = 'https://t.me/FightLifeBot';






// типы ДЕНЕГ
export const PAYFORM_KIND_MONEY   = 0;
export const PAYFORM_KIND_BALANCE = 1;
export const PAYFORM_KIND_BONUS   = 2;





// ВКУСНЯШКИ/доп услуги в абонементе
export const ABON_FEAT_TYPE_INVALID    = 0;
export const ABON_FEAT_TYPE_CHILD_ABON = 1;        // создаст абон (было решением проблемы со стартом)


// откуда появился абон ?
export const ABON_ORIGIN_UNKNOWN        = 0;
export const ABON_ORIGIN_CREATED        = 1;    // создан руками через гуй
export const ABON_ORIGIN_STARTER        = 2;    // подарок для нового клиента
export const ABON_ORIGIN_FROM_FEATURE   = 3;    // создан из ВКУСНЯШКИ другого абонемента
export const ABON_ORIGIN_GENERATED      = 4;    // сгенерирован автоматом (для брони)
export const ABON_ORIGIN_TRANSFER       = 5;    // перенос трень от другого клиента



export const gAbonOriginVis = [
    '???',
    'СОЗДАН',
    'ПОДАРОК НОВОМУ КЛИЕНТУ',
    'ИЗ ДРУГОГО АБОНА',
    'СГЕНЕРИРОВАН',
    'ПЕРЕНОС ТРЕНЬ',
];

//-------------------
