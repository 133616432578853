import React from 'react';


import * as DEFS from '../defs.js';
import * as HLP from  '../helpers.js';

import { NuPost } from '../api.js';

import {DaCombo} from '../misc/combo.js';

import { ShowErr, ShowMsg  } from '../misc_glob.js';

//-------------------------------------

const ELEM_ALL = [ {tcat_id:-1, tcat_name: '???'} ];

// комбо-бокс  категории техник
export function ComboTechCat( {name, value, disabled, label, error, onChange} )
{
    const [lst, setLst] = React.useState( ELEM_ALL );
    
    
    React.useEffect( () => {
        
        function __ok(e)
        {
            setLst( ELEM_ALL.concat( e.rows) );
        }
        
        function __fail(e)
        {
            ShowErr(e.m);
        }
        
        NuPost( 3022, {}, __ok, __fail );
        
    }, [] );
    
    
    const cb_chg = React.useCallback( (k,v) => {
        onChange( name, v );
    }, [value, name] );
    


	return <DaCombo error={error} disabled={disabled} label={label} value={value} src={lst} fld_k='tcat_id' fld_v='tcat_name' onChange={cb_chg} />;
}
