import React from 'react';
import { NuPost, NuPost2 } from '../api.js';

import { LayVert2, LayHoriz2, LaySpace } from '../misc/layout.js';
import { OverlayLoading } from '../misc/ovr_loading.js';

import { Win2 } from '../misc/win2.js';

import { DaButton } from '../misc/but.js';

import { PayFormVis, usePayFormState } from './pay_form4.js';


import { WinHelpConta, WinHelpElem } from '../misc/win_help.js';


import { DaTextField }  from '../misc/text_field.js';
import { DaCsvField }   from '../misc/csv_field.js';

import { DaNumericField } from '../misc/numeric.js';

import { ShowErr, ShowMsg, CommonReducer, useGlobEvActs } from '../misc_glob.js';

import { ComboProdAbonList } from './combo_prod_abon.js';

import { BottomButtonsPanel } from '../cust_card/bottom_pan.js';

import { EvActDispNameCSV } from '../desktop/ev_act.js';
 
import CheckIcon from '@mui/icons-material/Check';
import { MODE_DAY } from '../misc/stats_date_range_sel.js';


//-----------------------------------------



function __GenStateMy( p )
{
    return {
  
        acts_csv:           '',
        prodId:             -1,

        cuId:           -1,

        totalToPay:     0,

        com:        '',
        busy:       false,
        ready:      false,


        dt: new Date(),
        
    };
}


function __flt_mod_to(e)
{
    return e.ea_allow_mod_to_this > 0;
}

//                 


// 

/*
<div style={{padding: '10px', fontSize: '17px', color: 'var(--fl-clr-primary)'}}>ТЕКУЩЕЕ ЗНАЧЕНИЕ: <b><EvActDispNameCSV csv={was_acts} /></b></div>
*/

function MyBody( {onOk, rid} )
{
    const acts_api = useGlobEvActs();
    
    const [sta, dispatch] = React.useReducer( CommonReducer, {}, __GenStateMy );
    

    /*
    React.useEffect( () => {

        function __ok(e)
        {
            const TRP = e.trp;
            const TRPM = e.trpm;


            dispatch( {type: 'vals', 
                        dct: {  busy:           false,
                                ready:          true,
                                pay_w_money:    e.pay_money,
                                pay_w_method:   e.pay_method,
                                pay_w_bonus:    e.pay_bonus,
                                pay_w_credit:   e.pay_credit,
                                cuId:           TRP.trp_customer, 
                                com:            TRPM ? TRPM.trpm_comment : '',
                                totalToPay:     TRPM ? TRPM.trpm_money_chrg : 0,
                                acts_csv: TRP.trp_acts_csv,
                                prodId: TRP.trp_from_prod_id },    
                    } );
        }

        function __fail(e)
        {
            ShowErr(e.m);
        }

        NuPost( 1091, {trp_id:rid}, __ok, __fail );

    }, [] );
    */



    const payState = usePayFormState( sta.cuId, sta.totalToPay, sta.dt );




    React.useEffect( () => {

        function __ok(e)
        {
            const TRP = e.trp;
            const TRPM = e.trpm;

            const PF = e.pf;

            dispatch( {type: 'vals', 
                        dct: {  busy:           false,
                                ready:          true,

                                acts_csv:       TRP.trp_acts_csv,
                                prodId:         TRP.trp_from_prod_id,
                                com:            TRPM ? TRPM.trpm_comment : '',
                                totalToPay:     TRPM ? TRPM.trpm_money_chrg : 0,

                                cuId:           TRP.trp_customer, 

                            },    
                    } );


            payState.readEnts( PF.ents );
        }
        function __fail(e)
        {
            ShowErr(e.m);
        }

        NuPost2( 4101, {trp_id:rid}, __ok, __fail );

    }, [] );





    function cb_ok()
    {
        //console.log('OK !');

        const pf = payState.packSV();

        const ACTS = sta.acts_csv;
    
		const __ok = (e) => {
			
			dispatch( {type: 'val', k:'busy', v:false} );
			
			if (onOk) onOk( {acts: ACTS} );
		};

		const __fail = (e) => {
			
			ShowErr(e.m);
			dispatch( {type: 'val', k:'busy', v:false} );
		};
		
		dispatch( {type: 'val', k:'busy', v:true} );

        NuPost2(4102, {   trp_id: rid,
            prod_id: sta.prodId, 
            pay_sum: sta.totalToPay,
            pf: pf,
            acts: sta.acts_csv,
            com: sta.com},
            __ok, __fail );
    }
    


    const cb_chg = React.useCallback( (k,v) => {
        dispatch( {type: 'val', k, v} );
    }, [] );
    


    
    
    
    const ERR_COM = sta.com.trim() === '';
    const ERR_PRO = sta.prodId === -1;
    
    const DIS_SUB = ERR_COM || ERR_PRO || sta.acts_csv === '' ;
    

    const my_act_lst = React.useMemo( () => {
        
        return acts_api.rows.filter( __flt_mod_to );
        
    }, [] );
    
    
    return (
    
        <LayVert2 rows='1fr max-content' style={{position: 'relative'}}>
        
            {sta.ready ? (

            <>
            <LayHoriz2 cols='1fr 400px' gap='6px' >
                <LayVert2 rows='max-content max-content max-content 1fr' gap='16px' padding='16px' >
                    
                                    
                    <DaCsvField label='ДВИЖ' fld_k='ea_id' fld_v='ea_name'  name='acts_csv'
                                        value_csv={sta.acts_csv} error={sta.acts_csv===''} src={my_act_lst} onChange={cb_chg} />
                        
                    <ComboProdAbonList error={ERR_PRO} name='prodId' value={sta.prodId} label='ИЗ ПРОДУКТА' onChange={cb_chg} /> 

                    <DaNumericField name='totalToPay' value={sta.totalToPay} onChange={cb_chg} min={0} max={999999} label='СУММА ДОПЛАТЫ' />

                    <DaTextField error={ERR_COM} name='com' value={sta.com} label='ПРИЧИНА ИЗМЕНЕНИЯ' onChange={cb_chg} multiline />
                </LayVert2>

                <LayVert2 rows='max-content 1fr' padding='16px' >

                    <PayFormVis disabled={sta.totalToPay < 1} state={payState} />

                    <LaySpace />
                </LayVert2>

            </LayHoriz2>
            
            <BottomButtonsPanel>
                <DaButton  onClick={cb_ok}  disabled={DIS_SUB} title='ИЗМЕНИТЬ' icon={ CheckIcon } />
            </BottomButtonsPanel>

            </>

            ) : <LaySpace /> }
            
            <OverlayLoading active={sta.busy || !sta.ready} />
        
        </LayVert2>
    
    );
}






const gHelp1 = (<WinHelpConta>

    <WinHelpElem tit='ДВИЖ' text='На какие тренировки сможет вписаться.' />
    <WinHelpElem tit='ПРОДУКТ' text='Подменить продукт.' />
</WinHelpConta>);


export function WinTrpMod3( {visible, rid, onOk, onClose} )
{
    //console.log('WIN TRP MODE... RID: ', rid);

    return (
    
        <Win2 visible={visible} help={gHelp1} title='МОД ТРЕНИ / ДОПЛАТА' onClose={onClose} width={850} height={490}  >
            <MyBody onOk={onOk} onClose={onClose} rid={rid} />
        </Win2>
    
    );
}
