import React from 'react';

import * as DT from '../dt.js';
import * as COM_DEFS from '../com_defs.mjs';
import * as DEFS from '../defs.js';
import * as HLP from '../helpers.js';

import { WinTrpMod3 } from '../desktop/win_trp_mod.js';

import { DaVertScrollFrame, DaVertScrollJuice } from '../misc/vscr.js';
import { DaFaderTop, DaFaderBottom } from '../misc/fader.js';

import { ShortName } from '../desktop/short_name.js';

import useRipple from "../misc/useRipple.tsx";

import { CommonReducer, ShowMsg, ShowErr } from '../misc_glob.js';

import {  NuPost2 } from '../api.js';

import { useGlobEvActs  } from '../misc_glob.js';

import { BottomButtonsPanel } from '../cust_card/bottom_pan.js';

import BuildIcon from '@mui/icons-material/Build';
import CheckIcon from '@mui/icons-material/Check';

import { TrpAllowedHoursInfo } from '../desktop/allowed_hours.js';

import { useGlobalShit } from '../v3/hk_global_shit.js';

import { Win2 } from '../misc/win2.js';
import { LayVert2, LaySpace } from '../misc/layout.js';
import { DaButton } from '../misc/but.js';
import { OverlayLoading } from '../misc/ovr_loading.js';

import { EvActDispNameCSV } from '../desktop/ev_act.js';
//---------------------------------------------------------------




// onlyMatchingAct - подходящие под тип события
export function TrpSelector( {visible, onClose, onOk, cu_id, time_start, sched_id, prev_trp_id, onlyMatchingAct} )
{
	return ( <Win2 visible={visible} title='ВЫБОР ТРЕНИ' width={710} height={644}   onClose={onClose} >
                <MyBody onOk={onOk} cu_id={cu_id}
                            time_start={time_start} onlyMatchingAct={onlyMatchingAct}
                            sched_id={sched_id} prev_trp_id={prev_trp_id} />
			</Win2>
		);
}

//-------------------------


const gSta = {
    
    busy:       false,
    abons:      [],
    name:       '',
    warn_oth:   false,
    cached_cur_trp: null,
    cur_trp:        null,
    modified_trp    : false,
    
    show_mod:       false,
    dct_mod:        {},
    
    
    serial:         0,
};



function __GetTrpObjById( abonz, search_trp_id )
{
    for (let x = 0; x < abonz.length; x ++ )
    {
        const e     = abonz[x];
        const trps  = e.list_trps;

        for (let i = 0; i < trps.length; i ++ )
        {
            const tt = trps[i];
            if (tt.trp_id === search_trp_id) return tt;
        }
    }
    
    return null;
}



function __PrepareAbonsArray(e)
{
    const abonz = [];
    
    // count avail TRPs
    for (let x = 0; x < e.abons.length; x ++ )
    {
        const abo         = e.abons[x];
        
        //console.log('ABO ', abo.pay_logic);
        
        if (abo.pay_logic === COM_DEFS.ABON_LOGIC_MONTHLY)
        {    
            continue;	// skip mon
        }
          
        let cnt_avail   = 0;
        
        for (let i = 0; i < abo.list_trps.length; i ++ )
        {
            let tt = abo.list_trps[i];
            if (tt.trp_status === DEFS.TRP_STATUS_UNUSED) cnt_avail ++;
        }
        
        abo.v_num_avail = cnt_avail;
        
        abo.v_unix_time = DT.UnixTime( DT.DateParseIso(abo.pay_pay_time) );
        
        abonz.push( abo );
    }
    
    abonz.sort( fnc_sort );
    
    return abonz;
}



/*
    //this.setState( {name: e.name, abons: abonz, warn_oth: e.oth > 0} );
    
    //console.log('state selected trp_id : ', this.state.selected_trp_id);
    
    if (selected_trp_id !== null)
    {
        //console.log('UPDATED CA AFTER LOAD...');
        let o_trp = this.GetTrpObjById( this.state.selected_trp_id );
        //this.cached_cur_trp = o_trp;
        
        this.setState( {cached_cur_trp: o_trp} );
    }
    
    // has more trains today ! (alert)
    if (this.state.displayed_oth === false && this.props.check_oth_trains && e.oth > 0)
    {
        this.setState( {pop_mode: 2, displayed_oth: true} );
    }
}
*/



function MyBody( {onOk, cu_id, time_start, sched_id, prev_trp_id, onlyMatchingAct} )
{
    const [sta, dispatch] = React.useReducer( CommonReducer, gSta );
    
    
    const hk_act = useGlobEvActs();
    
    
    React.useEffect( () => {
        
        //console.log('TRP-SELECTOR BODY !  SERIAL: ', sta.serial);
        
        function __ok(e)
        {
            //console.log('GOT TRP JUICE ', e);
            
            const abonz = __PrepareAbonsArray(e, onlyMatchingAct);
            
            if (prev_trp_id !== null)
            {
                
                const fff = __GetTrpObjById( abonz, prev_trp_id );
                //console.log('SET CACHED TRP SHIT --> ', fff);
                dispatch( {type: 'val', k:'cached_cur_trp',             v: fff} );
                dispatch( {type: 'val', k:'cur_trp',                    v: prev_trp_id} );
            }
            
            
            dispatch( {type: 'val', k:'busy',           v: false} );
            dispatch( {type: 'val', k:'name',           v: e.name} );
            dispatch( {type: 'val', k:'warn_oth',       v: e.oth > 0} );
            dispatch( {type: 'val', k:'abons',          v: abonz } );
        }
        
        function __fail(e)
        {
            dispatch( {type: 'val', k:'busy', v:false} );
            ShowErr(e.m);
        }
        
        dispatch( {type: 'val', k:'busy', v:true} );
        
        NuPost2( 840, {cu_id, time_start, sched_id}, __ok, __fail ); 
         
                   
    }, [sta.serial] );
    
    
    const cb_ok = () => {
      
        onOk( sta.cur_trp, sta.cached_cur_trp.trp_customer, sta.modified_trp );
        
    };
    
    const cb_mod = () => {
      
        function __clo()
        {
            dispatch( {type: 'val', k:'show_mod',             v: false} );
        }
      
        function __ok(e)
        {
            console.log('MOD OK ! ', e);
            
            dispatch( {type: 'val', k:'show_mod',                   v: false} );
            dispatch( {type: 'val', k:'modified_trp',               v: true} );
            dispatch( {type: 'val', k:'serial',                     v: sta.serial+1} );
        }
        
        const TRP = sta.cached_cur_trp;
        
        const ddd = { onOk : __ok, onClose: __clo,
                            was_acts: TRP.trp_acts_csv,
                            rid: TRP.trp_id};
      
        //console.log(ddd);
      
        dispatch( {type: 'val', k:'show_mod',            v: true} );
        dispatch( {type: 'val', k:'dct_mod',             v: ddd  } );
    };
    
    
    const cb_sel = (trp_id, row) => {
                dispatch( {type: 'val', k:'cached_cur_trp',             v: row} );
                dispatch( {type: 'val', k:'cur_trp',                    v: trp_id} );
    };
    
    
    const p_ar = [];
    
    for (let x = 0; x < sta.abons.length; x ++ )
    {
        const e = sta.abons[x];
        p_ar.push( <AbonBlock key={e.pay_id} e={e} onlyMatchingAct={onlyMatchingAct} hk_act={hk_act}
                                prev_trp_id={prev_trp_id}
                                cur_trp_id={sta.cur_trp}
                                sched_id={sched_id} onSel={cb_sel} /> );
    }
    
    
    
    let dis_mod = false;
    
    //console.log('SELECTED TRP ID: ', s.selected_trp_id);
    
    if (sta.cur_trp === null || sta.cached_cur_trp === null) dis_mod = true;
    
    //console.log('CACHED: ', s.cached_cur_trp);
    
    
    if (sta.cached_cur_trp && !hk_act.testCanMod(sta.cached_cur_trp.trp_acts_csv))
    {
        console.log('Can not mod some dirs.. disable MOD !');
        dis_mod = true;
    }
    
    
    /*
    if (sta.cached_cur_trp && CA_DirsGotSpecial( sta.cached_cur_trp.trp_dirs ) )
    {
        console.log('its special dirs.. disable mod');
        dis_mod = true;
    }
    */
    
    //console.log('cached_cur_trp: ', s.cached_cur_trp);
    
    // TODO.. HARDCODED - MONTHLY
    /*
    if (!dis_mod && sta.cached_cur_trp && sta.cached_cur_trp.trp_type === 4)
    {
        console.log('disable on mon');
		dis_mod = true;
	}
    */
        

    const p_warn = sta.warn_oth ? <div className="zz_warn">У этого клиента уже есть записи на этот день !</div> : <LaySpace />;
    

    return (
        <>
        <LayVert2 rows='max-content 1fr max-content' style={{position: 'relative'}}>
        
            { p_warn }
                            
            
            <div style={{width: '100%', height: '100%', position: 'relative'}}>
            
                <DaVertScrollFrame>

                    <DaVertScrollJuice sx={{display: 'flex', flexDirection: 'column', gap: '20px', paddingTop: '26px', paddingBottom: '26px', paddingLeft: '16px' ,paddingRight: '16px'}}>
                    {p_ar}
                    </DaVertScrollJuice>
                </DaVertScrollFrame>
            
                <DaFaderTop />
                <DaFaderBottom />
            
            </div>
            
            <BottomPanel dis_mod={dis_mod} dis_ok={sta.cur_trp===null}  onMod={cb_mod} onOk={cb_ok}  />
        
            <OverlayLoading active={sta.busy} />
        
        </LayVert2>
        
        
        <WinTrpMod3 visible={sta.show_mod} {...sta.dct_mod} />
        
        </>
    
    );
}

function BottomPanel( {dis_mod, dis_ok, onMod, onOk} )
{
    const sss = useGlobalShit();
    
    if ( !sss.userHasAbil(104) )
    {
        dis_mod = true;
    }
    
    return  (<BottomButtonsPanel>
                <DaButton onClick={onMod}    disabled={dis_mod}     title='МОД ТРЕНИ' icon={BuildIcon} />
                <DaButton onClick={onOk}     disabled={dis_ok}      title='ВЫБРАТЬ'   icon={CheckIcon} />
            </BottomButtonsPanel> );
}



function SingleTrp( {tt, prev_trp_id, cur_trp_id, sched_id, onSel, payDisabled} )
{
    const ref         = React.useRef(null);
    const ripples     = useRipple(ref);
    
    function cb_click()
    {
        onSel( tt.trp_id, tt );
    }
    
    let s_sta;     
    let s_sta_clr; 
    
    let sta = tt.trp_status;
    
    if (tt.ts_id === sched_id)
    {
        // override status.. avail
        console.log('OVERRIDE TRP STATUS !');
        sta = 0;
    }
    
    if (prev_trp_id !== tt.trp_id)
    {
        s_sta       = DEFS.gTrpStatusDisp[ sta ];
        s_sta_clr   = DEFS.gTrpStatusClr[ sta ];
    }
    else
    {
        s_sta = 'ВЫБРАНО';
        s_sta_clr = '#30A030';
    }
    
    let CLA = cur_trp_id === tt.trp_id ? "trp_board cur" : "trp_board";
    
    
    if (sta !== 0 || payDisabled>0) CLA += ' faint';
    
    
    
    let p_allowed_hours = null;
    
    if (tt.trp_allowed_hours_json !== '')
    {
        p_allowed_hours = ( <TrpAllowedHoursInfo jsonStr={tt.trp_allowed_hours_json} /> );
    }

    //  
    const my_on_click = (sta === 0 && payDisabled===0) ? cb_click : null;
    
    return (
                        <div ref={my_on_click ? ref : null} className={CLA} onClick={my_on_click}  >
                            <div style={{color: s_sta_clr}}>{s_sta}</div>
                            <div style={{gap: '10px', display: 'flex', alignItems: 'center'}}>
                                <EvActDispNameCSV csv={tt.trp_acts_csv} />{p_allowed_hours}
                            </div>
                            {ripples}
                        </div> 
    );
}

//---------------------------------



//----------------------------------------


function AbonBlock( {e, prev_trp_id, cur_trp_id, sched_id, onSel, hk_act, onlyMatchingAct} )
{
    const p_trps = [];
        
    const trps = e.list_trps;
        
        
    for (let i = 0; i < trps.length; i ++ )
    {
        const tt = trps[i];

        let IS_DIS = e.pay_disabled;

        if (onlyMatchingAct && !IS_DIS)
        {
            console.log('MATCH... TEST ', tt.trp_acts_csv, onlyMatchingAct);
            if (!hk_act.testActsCsvFitsEvtId( tt.trp_acts_csv, onlyMatchingAct ))
            {
                IS_DIS = true;
            }
        }


        p_trps.push( <SingleTrp key={tt.trp_id} payDisabled={IS_DIS}  tt={tt}  onSel={onSel} sched_id={sched_id} cur_trp_id={cur_trp_id} prev_trp_id={prev_trp_id} /> );
        
    }
        
        
    const pay_time = DT.DateTimeParseIso(e.pay_pay_time);
    const p_pay_disp = <ShortName ctx={DEFS.SHORT_NAME_ABON} id={e.pay_id} />;
    
    
    return (
                    <div style={{padding: '10px'}} >

                        <div style={{borderRadius: '3px', background: '#F0F0FF', padding: '6px', fontWeight: 800, fontSize: '16px', alignItems: 'center', display: 'flex', gap: '40px', justifyContent: 'space-between'}}>
                            <div>{p_pay_disp}</div>
                            <div className="zz_text_secondary">Куплен/получен: {DT.DateTimeStrLocHoursMinutes(pay_time)}</div>
                                    
                        </div>
                        
                        <div style={{display: 'flex', flexDirection: 'column', gap: '10px', padding: '10px'}}>
                            {p_trps}
                        </div>
                    </div>
    );
}






// sort by avail
/*
function fnc_sort(aa, bb)
{
    if (aa.v_num_avail < bb.v_num_avail) return 1;
    if (aa.v_num_avail > bb.v_num_avail) return -1;
    return 0;
}
*/


// sort by PAY DATE
function fnc_sort(aa, bb)
{
    if (aa.v_unit_time < bb.v_unix_time) return 1;
    if (aa.v_unit_time > bb.v_unix_time) return -1;
    return 0;
}
     
    
