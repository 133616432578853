import React from 'react';

import { MdHelpCenter } from "react-icons/md";

import {DaToolTip} from './tooltip.js';



//---------------------------

const gMySS = {color: 'var(--fl-clr-primary)', fontSize: '32px'};
const gMySS2 = {color: '#FFFFFF', fontSize: '16px'};

function CmHelpIconImp( {juice, small} )
{
    return (
    
                    <DaToolTip  title={juice}>
                        <MdHelpCenter style={ small ? gMySS2 : gMySS} />
                    </DaToolTip>
    
    );
    
    return 
}


export const CmHelpIcon = React.memo( CmHelpIconImp );


