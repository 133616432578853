 

import * as HLP from './helpers.js';
import * as DEFS from './defs.js';

import { decode, encode } from 'cbor-x';



//----------------



export function GetBackendBaseURL()
{
    if (window.location.hostname === 'crmdev.net') return 'http://crmdev.net:9000/fl-back/';
    return '';
}


export function NuPost(a_cmd, a_obj, a_func_ok, a_func_fail)
{
    //console.log('NuPost CMD: ', a_cmd);
    
    function WrapSucc(e)
    {
        //console.log('WRAP NP SUCC', e);
        
        if (e.c !== 1)
        {
            console.log('API ERROR: ', e.m);
            if (a_func_fail) a_func_fail( e );
            return;
        }
        
        //console.log('FUNC OK: ', a_func_ok);
        
        if (a_func_ok) a_func_ok(e);
    }
    
    function WrapErr(e)
    {
        console.log('INSIDE ERR ', e);
        
        if (e.c !== undefined)
        {
            if (a_func_fail) a_func_fail( e );
            return;
        }
        
        let r = { m: 'NETWORK FAIL' };
        
        if (a_func_fail) a_func_fail( r );
    }
    

    
    let my_data = { c: a_cmd,
                    o: a_obj,
                    t: HLP.CookieGet( DEFS.FIGHTLIFE_SESSION_COOKIE ) };
                    
    // 
    
    let trg = GetBackendBaseURL() + 'api_j.php';
    
    fetch(trg, {
        mode: 'cors',
        cache: 'no-store',
        method: 'POST',
        //credentials: "include",
        

        body: JSON.stringify(my_data),
    })
    .then(response => {
        
        //console.log('GOT RESP ', response);
        
        return response.json()
        
        })
    .then(data => {
        
        if (data.c === 1)
        {
            WrapSucc(data);
            return;
        }
        else
        {
            console.warn('REQUEST API ERROR: ', data);
            WrapErr(data);
        }
        
        
    })
    .catch((error) => {
        console.warn( 'REQUEST ERROR ', error );
        WrapErr( error );
    });
    
    return null;    // !
}


//----------------------------------

export function GetBackendBaseURL2()
{
    if (window.location.hostname === 'crmdev.net') return 'http://crmdev.net:10000/';
    return '/nono/';
}


export async function NuPost2(cmd, prm, onOk, onFail)
{
    //console.log('NuPost2 CMD: ', cmd);

    // 

    const DATA = {   c: cmd,
                        o: prm,
                        t: HLP.CookieGet( DEFS.FIGHTLIFE_SESSION_COOKIE ) };

    const TRG = GetBackendBaseURL2() + 'apiOfMyDick';
    
    const resp = await fetch(TRG, {
                                mode: 'cors',
                                cache: 'no-store',
                                method: 'POST',
                                //credentials: "include",
                                headers: {'content-type': 'application/json',
                                            'Access-Control-Allow-Headers': '*'},
                                body: JSON.stringify(DATA),
    });

    const jjj = await resp.json();
     

    //console.log('GOT JUICE JSON: ', jjj);

    if (jjj.c === 1)
    {
        onOk( jjj );
        return;
    }



    if (onFail) onFail( jjj );

}




export async function NuPostBin(cmd, juice, onOk, onFail)
{
    const TRG = GetBackendBaseURL2() + 'apiOfMyDick';
    

    const ccc = encode( { c:cmd,
                          o:juice,
                          t: HLP.CookieGet( DEFS.FIGHTLIFE_SESSION_COOKIE )   }  );

	const CFG = {
		
        mode: 'cors',
        cache: 'no-store',
		method: "POST",
		body: ccc,
		
		headers: {
			"Content-type": "application/octet-stream",
            'Access-Control-Allow-Headers': '*',
		}
	};

    const resp = await fetch(TRG, CFG);

    const jjj = await resp.json();
     

    //console.log('GOT JUICE JSON: ', jjj);

    if (jjj.c === 1)
    {
        onOk( jjj );
        return;
    }



    if (onFail) onFail( jjj );

}

