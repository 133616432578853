import React from 'react';
import { CmComColCellBalanceAct, CmComColCellDateTime  } from '../cm_table_cell_com.js';

import * as DEFS from '../defs.js';
import * as HLP from '../helpers.js';

import { NuPost2 } from '../api.js';


import { CommonReducer, ShowMsg, ShowErr } from '../misc_glob.js';

import { DaCombo } from '../misc/combo.js';

//--------------------------------------------------



const gRowDefault = { pr_id: -1, pr_name: '- НЕ ВЫБРАНО -'  };

// комбо с продуктами-абонами
export function ComboProdAbonList( {name, value, label, disabled, error, onChange} )
{
    const [lst, setLst] = React.useState( [gRowDefault] );

    React.useEffect( () => {

        function __ok(e)
        {
            setLst( [gRowDefault].concat( e.rows ) );
        }

        function __fail(e)
        {
            ShowErr(e.m);
        }

        NuPost2( 1106, {}, __ok, __fail );

    }, [] );

    return (

            <DaCombo fld_k='pr_id' fld_v='pr_name' disabled={disabled || !lst} error={error} 
                        label={label} name={name} value={value} src={lst} onChange={onChange} />
    );
}
