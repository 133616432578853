import React from "react";




import * as DT from '../dt.js';

import { CmCachedUserLink } from "./ca_user_link";

import { CommonReducer, ShowErr, ShowMsg } from "../misc_glob";

import { WinHelpConta } from "../misc/win_help";

import { EvActCombo } from "./ev_act";

import { NuPost2 } from "../api.js";


import {DaIconBtn} from '../misc/icon_btn.js'; 

import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';

import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import CloseIcon from '@mui/icons-material/Close';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import HttpsIcon from '@mui/icons-material/Https';

import { DaVertScrollFrame, DaVertScrollJuice } from "../misc/vscr.js";
 
import { DaButton } from '../misc/but.js';


import { BottomButtonsPanel } from "../cust_card/bottom_pan.js";


import { DaList2 } from "../misc/list2";

import { LayHoriz2, LaySpace, LayVert2 } from '../misc/layout.js';
import { Win2 } from "../misc/win2";
 
import CheckIcon from '@mui/icons-material/Check';


import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';


import { TrpSelector } from "../event/trp_selector.js";
import { OverlayLoading } from "../misc/ovr_loading.js";

//----


export const gMY22 = {

	ser 		: 0,
	forEvtTy	: -1,
	lst			: [],

	pop			: 0,

	busy	    : false,

	pop_dct_trp:  {},

};

 
export const gHelp1 = (<WinHelpConta>
    Выбираем какой тип событий показывать (группы, парные).
	Далее можем вписывать/выписывать клиентов в уже расставленные в календаре события.
</WinHelpConta>);


//--------------------------------------



// окно -РАСКИДАТЬ ПО СОБЫТИЯМ-
export function WinCustMassAssign({ cuId, visible, onClose }) {
	return (

		<Win2 visible={visible} title='РАСКИДАТЬ ПО СОБЫТИЯМ' width={900} height={620} onClose={onClose} help={gHelp1}>
			<MyJuice cuId={cuId} />
		</Win2>

	);
}


// 	

//----------------------------------

function MyJuice({ cuId })
{
	const [sta, dispatch] = React.useReducer(CommonReducer, gMY22);


	React.useEffect(() => {

		function __ok(e) {
			dispatch({ type: 'vals', dct: { busy: false, lst: e.events } });
		}

		function __fail(e) {
			ShowErr(e.m);
			dispatch({ type: 'vals', dct: { busy: false  } });
		}

		dispatch({ type: 'vals', dct: { busy: true  } });

		const dt = DT.DateStrNowIso();

		NuPost2(4141, { cuId: cuId, evAct: sta.forEvtTy, dtStart: dt }, __ok, __fail);

	}, [sta.forEvtTy, sta.ser]);

	const cb_chg = React.useCallback((k, v) => {
		dispatch({ type: 'val', k: k, v: v });
	}, []);




	const txt = `Предстоящих событий показано: ${sta.lst.length}`;

	function cb_clo()
	{
		dispatch( {type: 'vals', dct: {pop: 0}} );
	}

	function cb_click_brow(e, rr)
	{
		function __submit(selTrp)
		{
			console.log('SELECTED TRP : ', selTrp);

			function __ok(ee)
			{
				dispatch( {type: 'vals', dct: {busy: false, ser:sta.ser+1}} );

				console.log('SUBMIT SUCCESS !! ', ee);

				ShowMsg('КЛИЕНТ ВПИСАН');
			}

			function __fail(e)
			{
				ShowErr( e.m );
				dispatch( {type: 'vals', dct: {busy: false}} );
			}

			dispatch( {type: 'vals', dct: {pop: 0, busy: true}} );

			NuPost2( 4142, {	trpId: selTrp,
								schId: rr.ts_id}, __ok, __fail );
		}

		const DDD = {
			time_start: 		rr.ts_time_start,
			sched_id: 			rr.ts_id,
			prev_trp_id: 		rr.v_bound,
			onOk: 				__submit,
		};

		dispatch( {type: 'vals', dct: {pop_dct_trp: DDD, pop: 1}} );
	}

	// TODO: add confirm window !
	function cb_click_rem(e, rr)
	{
		console.log('REM: ', rr);

		const REM_TRP = rr.v_bound;

		function __ok(ee)
		{
			dispatch( {type: 'vals', dct: {busy: false, ser:sta.ser+1}} );
			ShowMsg('УБРАЛИ КЛИЕНТА С СОБЫТИЯ !');
		}

		function __fail(e)
		{
			ShowErr( e.m );
			dispatch( {type: 'vals', dct: {busy: false}} );
		}

		dispatch( {type: 'vals', dct: {pop: 0, busy: true}} );

		NuPost2( 4143, {	trpId: REM_TRP,
							schId: rr.ts_id}, __ok, __fail );
							
	}


	const EXT = {

		brow: cb_click_brow,
		rem: cb_click_rem,
	};

	return (

		<>

		<LayVert2 rows='max-content 1fr' padding='10px'>

			<LayHoriz2 cols='230px max-content 1fr' padding='10px'>
				<EvActCombo name='forEvtTy' value={sta.forEvtTy} label='ПОКАЗЫВАТЬ СОБЫТИЯ' mode='massSpread' onChange={cb_chg} />
				<div style={{ paddingLeft: '30px', color: '#606060', display: 'flex', alignItems: 'center' }}>{txt}</div>
				<LaySpace />
			</LayHoriz2>

			<DaList2 ext={EXT}  name='lst' fld_k='ts_id' fld_v='ts_id' src={sta.lst} itemCmp={ItemJuiceMyMy} />

			<OverlayLoading active={sta.busy} />

		</LayVert2>

		<TrpSelector visible={sta.pop === 1} onlyMatchingAct={sta.forEvtTy}  onClose={cb_clo}  cu_id={cuId}   {...sta.pop_dct_trp} />

		</>
	);
}


function ItemJuiceMyMyImp( {kk, vv, row, isCur, ext} )
{
	//console.log('EXT : ', ext);

	const dt = DT.DateTimeParseIso( row.ts_time_start );
	const TM = DT.DateTimeStrLocHum( dt );

	//const CHECKED = ext.indexOf( row.ts_id ) !== -1;

	const mmm = <DaIconBtn  name={row}  onClick={ext.brow}   icon={SwapHorizIcon} help='ВЫБРАТЬ ТРЕНЮ И ВПИСАТЬ' tp_placement='top' />;   //CHECKED ? (<CheckBoxIcon style={{color: 'var(--fl-clr-primary)'}}/>) : (<CheckBoxOutlineBlankIcon style={{color: 'var(--fl-clr-primary)'}} />);

	const ccc = row.v_bound !== -1 ? <DaIconBtn  name={row}  onClick={ext.rem}  clr='#A06060' icon={CloseIcon} help='УБРАТЬ ИЗ СОБЫТИЯ' tp_placement='top'  /> : null;

	const dudes = <span className='du'>чел: {row.v_trps.length}</span>;

	const CLA_BOUND = row.v_bound !== -1 ? ' bound' : '';

    return (<div className={`mass_assign_item${CLA_BOUND}`} >
				<span className='ts'>{row.ts_id}</span>
				<span>{TM}</span>
				<CmCachedUserLink rid={row.ts_user_id} show_clr/>
				<span className='impo'>{row.ts_impo_text}</span>
				{dudes}
				{mmm}
				{ccc}
            </div>);
}

export const ItemJuiceMyMy = React.memo( ItemJuiceMyMyImp );


//---------------------------------------


