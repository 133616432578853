import * as React from 'react';


import * as DEFS from '../../defs.js';
import * as HLP from '../../helpers.js';

import { CommonReducer, ShowMsg, ShowErr } from '../../misc_glob.js';

import { BottomButtonsPanel } from '../../cust_card/bottom_pan.js';

import { DaTextField } from '../../misc/text_field.js';
import { DaColorPicker } from '../../misc/clr_picker.js';

import {STO_InvaCustOrigins} from '../../store.js';



import { LayHoriz2, LayVert2 } from '../../misc/layout.js'
import { NuPost } from '../../api.js';

import { Grid3 } from '../../grid3.js';


import { DaButton } from '../../misc/but.js';
import { OverlayLoading } from '../../misc/ovr_loading.js';
import { Win2 } from '../../misc/win2.js';

import { WinEditCustSrc } from '../../desktop/win_cust_src_edit.js';

import { Win2Confirm } from '../../misc/win2_confirm.js';

import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';

//---------------------------------



function MyCellText(pp)
{
	if (pp.row.lso_id === 1) return <b style={{color: '#3040B0'}}>{pp.value} (По умолчанию)</b>;
	return pp.value;
}

function MyCellRGB(pp)
{
    return <span style={{display:'inline-block', width: '60px', height: '22px', background: pp.value}}></span>;
}


const gColz  = [
  { sort: true,   align: 'center', fld: 'lso_id',           name: 'ID', width: 80},
  { sort: false,  align: 'left',   fld: 'lso_val',          name: 'Текст', width: 420,  renderCell: MyCellText },
  { sort: false,  align: 'left',   fld: 'lso_clr',          name: 'Цвет', width: 110, renderCell: MyCellRGB},
  { sort: false,  align: 'left',   fld: 'lso_utm_source',   name: 'UTM SOURCE', width: 170},
  { sort: false,  align: 'left',   fld: 'lso_referer',      name: 'REFERER',    width: 170},
];


const gSt = {
  
    selected:   [],
    busy:       false,
    pop:        0,
    popDct:     {},
    ser:        0,
    
};


// Источники клиентов - таблица
export function ViCustSources( )
{
    const [sta, dispatch] = React.useReducer( CommonReducer, gSt );
    
    function cb_close()
    {
        dispatch( {type:'val', k:'pop',v:0} );
    }
    
    function cb_chg_sel(nu)
    {
        dispatch( {type:'val', k:'selected',v:nu} );
    }
    
    function cb_new()
    {
        function __ok()
        {
            dispatch( {type:'val', k:'pop',v:0} );
            dispatch( {type:'val', k:'ser',v:sta.ser+1} );
            dispatch( {type:'val', k:'selected',v:[]} );
        }
        
        dispatch( {type:'val', k:'pop',v:1} );
        dispatch( {type:'val', k:'popDct',v: {rid: -1, onOk: __ok}  } );
    }
    
    function cb_del()
    {
        const ID = sta.selected[0];
        
        function __ok()
        {
            const __ok = (e) => {
				dispatch( {type:'val', k:'selected',v:[]} );
                dispatch( {type:'val', k:'busy',v:false} );
                dispatch( {type:'val', k:'pop',v:0} );
                dispatch( {type:'val', k:'ser',v:sta.ser+1} );

				ShowMsg('УДАЛЕНО !');
				
				STO_InvaCustOrigins();
			};
			
            const __fail = (e) => {
				
                dispatch( {type:'val', k:'busy',v:false} );
				ShowErr('FAIL ! ', e.m);
			};
            
            dispatch( {type:'val', k:'busy',v:true} );
            
            NuPost( 3013, {rid: ID}, __ok, __fail);
        }
        
        dispatch( {type:'val', k:'pop',v:2} );
        dispatch( {type:'val', k:'popDct',v: {onOk: __ok, title: `УДАЛИТЬ ИСТОЧНИК ?`}  } );
    }
    
    function cb_row_dbl(kk)
    {
        function __ok()
        {
            dispatch( {type:'val', k:'pop',v:0} );
            dispatch( {type:'val', k:'ser',v:sta.ser+1} );
        }
        
        const ID = kk;
        
        dispatch( {type:'val', k:'pop',v:1} );
        dispatch( {type:'val', k:'popDct',v: {rid: ID, onOk: __ok}  } );
    }
    
    
    const FLT = React.useMemo( () => {
        return {};
    }, [sta.ser]);
    
    
    
    const DIS_DEL = sta.selected.length < 1 || sta.selected[0] === 1;
    
    return (
        <>
        <LayVert2 rows='max-content 1fr' sx={{gap: '20px'}}>
        
            <div style={{display: 'flex', gap: '10px'}}>
                <DaButton onClick={cb_new} title='НОВЫЙ'         icon={AddIcon} />
                <DaButton onClick={cb_del} title='УДАЛИТЬ'       icon={RemoveIcon} disabled={DIS_DEL} />
            </div>
        
            <Grid3 	sort_field='lso_id'	uniq='lso_id'  onDblClick={cb_row_dbl}
                        sort_dir='desc' cols={gColz}
                        apiCmd={3010}  flt={FLT} singleSel  selected={sta.selected} onChangeSel={cb_chg_sel} code='b_cust_src' />
        
        </LayVert2>
        
        <WinEditCustSrc visible={sta.pop===1} onClose={cb_close}  {...sta.popDct}  />
        
        <Win2Confirm  busy={sta.busy}   visible={sta.pop===2} onClose={cb_close}  {...sta.popDct}  />
        
        </>
    );
}


//------------------------------------
