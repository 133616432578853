import React from 'react';


import { DaOutlinedShit } from '../misc/outlined.js';



export function DaNumericField( {name, label, disabled, value, min,max, error, onChange} )
{
    const [tx, setTx]       = React.useState( '' + value );
    const [isEd, setIsEd]   = React.useState( false );
    
    let myRef = React.createRef();
    
    function cb_foc(e)
    {
        setIsEd(true);
        
        setTx( ''+value );
    }
    
    function cb_blur(e)
    {
        let nv = parseInt(tx);
        if (isNaN(nv)) nv = 0;
        
        if (min !== undefined && nv < min) nv = min;
        if (max !== undefined && nv > max) nv = max;
        
        setIsEd(false);
        
        onChange( name, nv );
    }
    
    function cb_kd(e)
    {
    }
    
    function cb_chg_inp(e)
    {
        setTx(e.target.value);
    }
    
    
    let CLA = disabled ? "oi_input_shit dis" : "oi_input_shit";
    if (isEd) CLA += ' act';
    
    const inp_tx = isEd ? tx : ''+value;
    
    
    const p_juice =  <input ref={myRef} disabled={disabled} onKeyDown={ cb_kd } onChange={cb_chg_inp} onBlur={cb_blur} onFocus={cb_foc} className={CLA}  type='text' value={inp_tx}  />;
    
    return (
        <div style={{height: '36px', width: '100%'}}>
            <DaOutlinedShit error={error} labelIsFloat={true} labelVal={label} borderIsFoc={isEd} juice={p_juice} />
        </div>
        );
}
 
