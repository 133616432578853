import React from 'react';

import { CmComColCellDateTime, CmComColUser } from '../cm_table_cell_com.js';


import { CmCachedCustLink } from '../desktop/ca_cust_link.js';

import { CmComColCellBalanceAct } from '../cm_table_cell_com.js';

import * as DEFS from '../defs.js';

import * as HLP from '../helpers.js';

import { LayVert2 } from '../misc/layout.js'

import { FmtPayOpBind } from '../misc_glob.js';

import { Grid3 } from '../grid3.js';

//------------------------------------


function MyColInfo(pp)
{
    const r = pp.row;
    return FmtPayOpBind( r.cbh_bind_ctx, r.cbh_bind_id );
}


function MyColPayMethodImp(pp)
{
    const e = HLP.ArrayOfDictsFindByPropValue(DEFS.PAY_METHOD, 'k', pp.value);
    if (!e) return '???';
    return e.n;
}

const MyColPayMethod = React.memo( MyColPayMethodImp );

//--------------------------------------

function MyColCustImp(pp)
{
	return <CmCachedCustLink  shorten rid={pp.row.cbh_cu_id}  />
}

const MyColCust = React.memo( MyColCustImp );

const gColz = [
  { sort: true, align: 'center', fld: 'cbh_id',           name: 'ID', width: 80 },
  { sort: true, align: 'center', fld: 'cbh_action',       name: 'Действие', width: 130, renderCell: CmComColCellBalanceAct },
  { sort: true, align: 'left',   fld: 'cbh_cu_id',        name: 'Клиент', width: 180, renderCell: MyColCust },
  { sort: true, align: 'center', fld: 'cbh_amount',       name: 'Сумма', width: 110},
  { sort:false, align: 'left',   fld: 'v_inf',            name: 'Связь', width: 170, renderCell: MyColInfo },
  { sort: true, align: 'left',   fld: 'cbh_user_id',      name: 'Юзер', width: 180, renderCell: CmComColUser },
  { sort: true, align: 'center', fld: 'cbh_dt_created',   name: 'Создано', width: 150, renderCell: CmComColCellDateTime },
  { sort: true, align: 'center', fld: 'cbh_dt_when',      name: 'Дата оп', width: 150, renderCell: CmComColCellDateTime },
  { sort:false, align: 'left',   fld: 'cbh_comment',      name: 'Коммент', width: 300 },
];




export function TabCustBalanceOps( )
{
    return (
        
        <LayVert2 rows='1fr' padding='20px' >
        
            <Grid3 sort_field='cbh_id' sort_dir='desc' cols={gColz} isNuApi apiCmd={817} uniq='cbh_id' code='balance_ops' />
        
        </LayVert2>
    
    );
}

