import React from 'react';

import * as DT from '../dt.js';

import { NuPost2 } from '../api.js';

import { DaButton } from '../misc/but.js';

import { LayCenter, LayVert2, LayHoriz2, LaySpace } from '../misc/layout.js'

import { CommonReducer, ShowErr, ShowMsg  } from '../misc_glob.js';

import { Grid3 } from '../grid3.js';

import { CmComColCellDateTime, CmComColUser } from '../cm_table_cell_com.js';

import { DaCombo } from '../misc/combo.js';
import { DaDateField } from '../misc/date_field.js';


//--------------------------------------------------

const gCpLogEvNames = {
    
  
  100: 'n)Новый клиент {ida}',
  101: 'e)Клиент отредактирован {ida}',
  102: 'Заявка с лендинга {ida}',
  103: 'd)Клиент удалён {ida}',
  104: 'Клиент активирован {ida}',
  105: 'Клиент деактивирован {ida}',
  106: 'e)Анкета клиента отредакт. {ida}',
  107: 'e)Техники клиента отредакт. {ida}',
  108: 'e)Залита аватарка клиента. {ida}',
  109: 'e)Любимые тренереа клиента редакт. {ida}',
  
  116: 'Пополнение баланса клиента {ida}. Сумма: {idc}',
  117: 'd)Удалена запись {ida} баланса клиента.',
  118: 'e)Редакт допозита клиента {ida}. Сумма: {idc}',
  
  200: 'n)Новое событие в календаре {ida}', 
  201: 'e)Событие в календаре редакт. {ida}', 
  202: 'd)Событие в календаре удалено {ida}', 
  203: 'e)Смена тренеров между событиями {ida} {idb}', 
  
  205: 'Время в календаре заблочено', 
  206: 'Время в календаре разблочено', 
  
  300: 'e)График работы отредактирован',
  
  400: 'e)Модицикация трени клиента {ida}',
  
  500: 'n)Новый абонемент {idc} для клиента {ida}',
  501: 'd)Абонемент удалён {ida}',
  502: 'e)Абонемент отредактирован {ida}',
  503: 'n)Перенос трень / новый абон {ida} из {idb}',
  504: 'e)Перенос трень / треня {idc} в абон {ida}',
  505: 'n)Возврат трень из абонемента {ida}',
  506: 'd)Возврат трень удалён {ida}',
  507: 'авто-закрытие мес. абона {ida}',
  
  550: 'n)Новая фин. операция {ida}',
  551: 'e)Редакт фин. операции {ida}',
  552: 'd)Удалить фин. операцию {ida}',
   
  600: 'Юзер ввёл логин/пасс {ida}',
  601: 'e)Юзер отредактирован {ida}',
  602: 'n)Новый юзер {ida}',
  603: 'd)Юзер удалён {ida}',
  604: 'e)Установлен пароль юзера {ida}',
  605: 'e)Права роли:{ida}. действие:{idb}. знач: {idc}',
  
  
  700: 'n)Новый продукт {ida}',
  701: 'e)Продукт отредактирован {ida}',
  702: 'e)Деактивирован продукт {ida}',
  
  800: 'n)Новая задача {ida}',
  //801: 'e)Задача закрыта {ida}',
 // 802: 'e)Задача открыта {ida}',
  
  //804: 'n)Новый коммент {idb} к задаче {ida}',
  //805: 'd)Коммент {idb} к задаче {ida} удалён !',
  //806: 'e)Коммент {idb} к задаче {ida} - обновлён',
  
  810: 'd)Задача удалена {ida}',
  811: 'e)Задача отредактирована {ida}',
  
  820: 'Задача - перенос даты {ida}',
  821: 'Задача выполнена {ida}',

  850: 'n)Шаблон задачи - новый {ida}',
  851: 'd)Шаблон задачи - удалён {ida}',
  852: 'e)Шаблон задачи - редакирован {ida}',
  853: 'n)Созданы задачи из шаблонов',
  
  900: 'n)Новая заявка {ida}',
  901: 'Заявка отклонена {ida}',
  902: 'Заявка принята {ida} - новый клиент {idb}',
  
  1100: 'n)Новое событие в календаре (текст) {ida}',
  1101: 'd)Удалено событие в календаре (текст) {ida}',
  1102: 'e)Редакт. события в календаре {ida}',
  
  1301: 'БОТ - ЗАПУСК',
  1302: 'БОТ - ВЫКЛ',
  1303: 'n)БОТ - ЗАДАЧА {ida}',
  1305: 'n)БОТ - ЗАДАЧА ПО ТРЕКИНГУ КЛИЕНТА {ida}',
  
  1400: 'e)ИЗМ. ТРЕКИНГА КЛИЕНТА {ida}',
  1401: 'd)УДАЛЕНЫ ЗАПИСИ ТРЕКИНГА',
  1402: 'e)ЗАКРЫТЫ ЗАПИСИ ТРЕКИНГА',
  
  1500: 'n)Новое сообщение юзера {ida}',
  
  1600: 'n)Новый товар {ida}',
  1601: 'd)Товар удалён {ida}',
  1602: 'e)Товар редакт {ida}',
  1610: 'e)Категории товаров редакт',
  1620: 'n)Новая продажа {ida}, товар {idb}',
  1621: 'd)Продажа удалена {ida}. товар {idb}. назад в сток: {idc}',
  1630: 'e)Наличие товара {ida}, стало: {idc}',
  1631: 'n)Новая поставка товаров {ida}',
  1632: 'e)Поставка товаров редакт. {ida}',
  1633: 'd)Поставка товаров удалена {ida}',

  1640: 'Продажа товара {ida} - профит тренера',
  
  1700: 'n)Новый источник кл. {ida}',
  1701: 'e)Редакт источник кл. {ida}',
  1702: 'd)Удалён источник кл. {ida}',
  
  1800: 'n)Новая подсказка {ida}',
  1801: 'e)Редакт подсказку {ida}',
  1802: 'd)Удалена подсказка {ida}',
  
  1900: 'Настройки в "РАСЧЁТЕ ТРЕНЕРОВ" сохранены',


};


function __GenSrcForCombo()
{
    let r = [];
    
    r.push( { k: -1, v: '-- ВСЁ --' } );
    
    for (let k in gCpLogEvNames)
    {
        let s = gCpLogEvNames[k];
        
        // TODO... hacky shit
        s = s.replace('n)', '');
        s = s.replace('e)', '');
        s = s.replace('d)', '');
        
        s = s.replace('{ida}', '');
        s = s.replace('{idb}', '');
        s = s.replace('{idc}', '');
        
        s = s.trim();
        
        r.push( {k:k, v:s}  );
    }
    
    return r;
}


//------------------------------------

  

function CmpLogDescr(p)
{
    let r = p.row;
    
    let css_cla = null;
            
    let val_event = gCpLogEvNames[r.clo_ev];
                
    if (val_event != undefined)
    {
        // proc specials
        if (val_event[1] == ')')
        {
            if      (val_event[0] == 'n') css_cla = "log_sty_new";
            else if (val_event[0] == 'e') css_cla = "log_sty_edit";
            else if (val_event[0] == 'd') css_cla = "log_sty_del";
            
            val_event = val_event.substring(2);
        }
        
        val_event = val_event.replaceAll('{ida}', '('+r.clo_ida+')');
        val_event = val_event.replaceAll('{idb}', '('+r.clo_idb+')');
        val_event = val_event.replaceAll('{idc}', '('+r.clo_idc+')');
    }
    else return ( <i className="faint">{r.clo_ev}???</i> );

      
    return <div className={css_cla}>{val_event}</div>;
}



const gNuCols = [

    {fld: 'clo_id',        name: 'ID',           align: 'center',  sort: true,   width: 110},
    {fld: 'clo_dt',        name: 'Время',        align: 'center',  sort: true,   width: 150, renderCell: CmComColCellDateTime  },
    {fld: 'clo_ev',        name: 'Событие',      align: 'left',    sort: false,  width: 380, renderCell: CmpLogDescr  },
    {fld: 'clo_user_id',   name: 'Юзер',         align: 'center',  sort: false,  width: 270, renderCell: CmComColUser  },
    {fld: 'clo_text',      name: 'Доп.Инфа',     align: 'left',    sort: false,  width: 580 },
];



const gSta = {
  
    dt_s    : null,
    dt_e    : null,
    
    type    : -1,
    
};



export function TabLogClub(  )
{
    return <MyView isAtBoss={false} />;
}

export function TabLogBOSS( )
{
    return <MyView isAtBoss={true} />;
}

function MyView( {isAtBoss} )
{
    const [sta, dispatch] = React.useReducer( CommonReducer, gSta );
    

	
	const cb_chg = React.useCallback( (k,v) => {
		dispatch( { type: 'val', k:k, v:v } );
	}, []);
	
    
    const FLT = React.useMemo( () => {
        
        return {    dt_s: sta.dt_s,
                    dt_e: sta.dt_e,
                    type: sta.type, boss: isAtBoss };
        
    }, [sta.dt_s, sta.dt_e, sta.type] );
    
    const src_my = React.useMemo( () => {
        
        return __GenSrcForCombo();
        
    }, [] );




    return (
        <LayVert2 rows='max-content 1fr'  padding='20px' gap='10px'>
        
            <LayHoriz2 cols='170px 170px 300px 1fr' gap='10px' >
                
                <DaDateField name='dt_s' label='ОТ' value={sta.dt_s} onChange={ cb_chg }  />
                <DaDateField name='dt_e' label='ДО' value={sta.dt_e} onChange={ cb_chg }  />
                <DaCombo  label='ТИП СОБЫТИЯ' name='type'  onChange={cb_chg} src={src_my}  fld_k='k' fld_v='v'  value={sta.type}   /> 
						
                <LaySpace />
                
            </LayHoriz2>
            
            
        
            <Grid3 cols={gNuCols} apiCmd={801} uniq='clo_id'  sort_field='clo_id' sort_dir='desc' flt={FLT} code='log_club' />
            
        </LayVert2>
    
    );
}


