import React from 'react';

//import * as DEFS from '../defs.js';
import * as HLP from '../helpers.js';

import { Grid3 } from '../grid3.js';

import { NuPost } from '../api.js';

import { DaNumericField } from '../misc/numeric.js';

import { Win2Confirm } from '../misc/win2_confirm.js';

import { LayHoriz2, LayVert2, LaySpace } from '../misc/layout.js'

import { ComboTechCat } from '../desktop/combo_tech_cat.js';

import { BottomButtonsPanel } from '../cust_card/bottom_pan.js';
import { DaTextField } from '../misc/text_field.js';
import { OverlayLoading } from '../misc/ovr_loading.js';
import { Win2 } from '../misc/win2.js';
import { DaButton } from '../misc/but.js';

import { CommonReducer, ShowMsg, ShowErr } from '../misc_glob.js';

import { useApiQuery } from '../api_stuff.js';


import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

//-------------------------------------------------


function CmTechCatCached(pp)
{
	// isLoading, isError, data, error 
	const qq = useApiQuery( 3022, {}, {staleTime: 30} );
	
	
	if (qq.isLoading || !qq.data) return <div>???</div>;
	
	const rows = qq.data.rows;
	
	const e = HLP.ArrayOfDictsFindByPropValue(rows, 'tcat_id', pp.rid);
    
    if (e === null)  return <div>???</div>;
	
	return <div>{e.tcat_name}</div>;
}



function MyColNameDesc(pp)
{
	return (<div>
				<div>{pp.row.tech_name}</div>
				<div className="zz_text_secondary">{pp.row.tech_desc}</div>
			</div>);
}


function MyColCat(pp)
{
	return <CmTechCatCached rid={pp.value} />;
}




const gColzCats = [
  { sort: true,  align: 'center', fld: 'tcat_id',    name: 'ID',       width: 70  },
  { sort: true,  align: 'left',   fld: 'tcat_name',  name: 'Название', width: 200 },
];


const gColzTechs = [
  { sort: true, align: 'center', fld: 'tech_id',        name: 'ID',             width: 70  },
  { sort: true, align: 'left',   fld: 'tech_name',      name: 'Название',       width: 420, renderCell: MyColNameDesc  },
  { sort: true, align: 'center',   fld: 'tech_max_val',   name: 'Максы',          width: 130  },
  { sort: true, align: 'center',   fld: 'tech_cat_id',    name: 'Категория',      width: 200, renderCell: MyColCat  },
];



function MyTitle( {tit} )
{
    return <div style={{fontFamily: 'body', color: '#6A4CD7', fontSize: '18px', display: 'flex', justifyContent: 'center'}}>{tit}</div>;
}

//---------------------------------------


const gSt22 = {
  
    name: '',
    busy: false,
};

function MyJuice( {rid, onOk} )
{
    const [sta, dispatch] = React.useReducer( CommonReducer, gSt22 );
    
    React.useEffect( () => {
        
        if (rid === -1) return;
        
        function __ok(e)
        {
            const RRR = e.row;
            dispatch( {type: 'val', k:'busy', v:false} );
            dispatch( {type: 'val', k:'name', v:RRR.tcat_name} );
        }
        
        function __fail(e)
        {
            dispatch( {type: 'val', k:'busy', v:false} );
            ShowErr( e.m );
        }
        
        dispatch( {type: 'val', k:'busy', v:true} );
        
        NuPost( 3023, {rid:rid}, __ok, __fail);
        
    }, [] );
    
    
    function cb_submit()
    {
        function __ok(e)
        {
            dispatch( {type: 'val', k:'busy', v:false} );
            onOk();
        }
        
        function __fail(e)
        {
            dispatch( {type: 'val', k:'busy', v:false} );
            ShowErr( e.m );
        }
        
        dispatch( {type: 'val', k:'busy', v:true} );
        
        NuPost( 3024, {rid:rid, name:sta.name}, __ok, __fail);
    }
    
    const cb_chg = React.useCallback( (k,v) => {
        dispatch( {type: 'val', k, v} );
    }, [] );
    
    const DIS_SUBMIT = sta.name.trim() === '';
    
    return (
        <>
        <LayVert2 rows='1fr max-content' >
            <LayVert2 rows='max-content 1fr' padding='20px' >
                <DaTextField name='name' value={sta.name} onChange={cb_chg} label='НАЗВАНИЕ' />
                <LaySpace />
            </LayVert2>
            <BottomButtonsPanel>
                <DaButton title={rid===-1 ? 'СОЗДАТЬ' : 'СОХРАНИТЬ'}   onClick={cb_submit} disabled={DIS_SUBMIT} />
            </BottomButtonsPanel>
        </LayVert2>
        
        <OverlayLoading active={sta.busy} />
        
        </>
    
    );
}

//--------------------------------

function WinTechCatEdit( {visible, rid, onOk, onClose} )
{   
    const TIT = rid === -1 ? 'НОВАЯ КАТЕГОРИЯ' : 'РЕДАКТИРОВАТЬ КАТЕГОРИЮ'; 
    
	return ( <Win2 visible={visible} title={TIT} width={500} height={170}   onClose={onClose} >
                <MyJuice rid={rid} onOk={onOk} />
			</Win2>
		);
}

//-------------------------------------


const gStWT = {
    
    name:   '',
    desc:   '',
    cat:    -1,
    max:    10,
    
    busy:   false,
};

function MyBody22( {rid, onOk} )
{
    const [sta, dispatch] = React.useReducer( CommonReducer, gStWT );
    
    const cb_chg = React.useCallback( (k,v) => {
        dispatch( {type:'val', k, v} );
    }, [] );
    
    
    React.useEffect( () => {
        
        if (rid === -1) return;
        
        function __fail(e)
        {
            ShowErr(e.m);
            dispatch( {type:'val', k:'busy', v:false} );
        }
        
        function __ok(e)
        {
            const rr = e.row;
            
            dispatch( {type:'val', k:'busy', v:false} );
            
            dispatch( {type:'val', k:'name', v:rr.tech_name} );
            dispatch( {type:'val', k:'desc', v:rr.tech_desc} );
            dispatch( {type:'val', k:'max',  v:rr.tech_max_val} );
            dispatch( {type:'val', k:'cat',  v:rr.tech_cat_id} );
        }
        
        dispatch( {type:'val', k:'busy', v:true} );
        
        NuPost( 3027, {rid:rid}, __ok, __fail );
        
    }, [] );
    
    function cb_submit()
    {
        function __ok(e)
        {
            dispatch( {type:'val', k:'busy', v:false} );
            onOk();
        }
        
        function __fail(e)
        {
            dispatch( {type:'val', k:'busy', v:false} );
            ShowErr( e.m );
        }
        
        dispatch( {type:'val', k:'busy', v:true} );
        
        NuPost(3026, {rid:rid, na:sta.name, de:sta.desc, ma:sta.max, ca:sta.cat}, __ok, __fail);
    }
    
    const ERR_NAM  = sta.name.trim() === '';
    const ERR_DESC = sta.desc.trim() === '';
    const ERR_CAT  = sta.cat < 1;
    
    const DIS_SUBMIT = ERR_NAM || ERR_DESC || ERR_CAT;
    
    return (
        <>
        <LayVert2 rows='1fr max-content' >
        
            <LayVert2 rows='max-content 1fr max-content' padding='20px' gap='16px' >
                <DaTextField        name='name'     value={sta.name}     onChange={cb_chg} label='НАЗВАНИЕ' error={ERR_NAM} />
                <DaTextField        name='desc'     value={sta.desc}     onChange={cb_chg} label='ОПИСАНИЕ' multiline error={ERR_DESC}/>
                <ComboTechCat       name='cat'      value={sta.cat}      onChange={cb_chg} label='КАТЕГОРИЯ' error={ERR_CAT} />
                <DaNumericField     name='max'      value={sta.max}      onChange={cb_chg} label='МАКС ЗНАЧЕНИЕ' min={1} max={999}  />
            </LayVert2>
        
            <BottomButtonsPanel>
                <DaButton title={rid===-1 ? 'СОЗДАТЬ' : 'СОХРАНИТЬ'}   onClick={cb_submit} disabled={DIS_SUBMIT} />
            </BottomButtonsPanel>
        </LayVert2>
        
        <OverlayLoading active={sta.busy} />
        
        </>
    );
}

function WinTechEdit( {visible, rid, onOk, onClose} )
{
    const TIT = rid === -1 ? 'НОВАЯ ТЕХНИКА' : 'РЕДАКТИРОВАТЬ ТЕХНИКУ'; 
    
	return ( <Win2 visible={visible} title={TIT} width={500} height={350}   onClose={onClose} >
                <MyBody22 rid={rid} onOk={onOk} />
			</Win2>
		);
}

//----------------------------------------



// КАТЕГОРИИ ТЕХНИК
function ViCats()
{
    const [isEdit, setIsEdit]       = React.useState( false );
    const [edRid, setEdRid]         = React.useState( -1 );
    const [ser, setSer]             = React.useState(0);
    const [selected, setSelected]   = React.useState( [] );
    const [busy, setBusy]           = React.useState( false );
    const [confProps, setConfProps]     = React.useState( null );
    
    function cb_clo()
    {
        setIsEdit(false);
    }
    
    function cb_sel(nu)
    {
        setSelected( nu );
    }
    
    function cb_new(kk)
    {
        setEdRid( -1 );
        setIsEdit( true );
    }
    
    
    function cb_dbl(kk)
    {
        setEdRid( kk );
        setIsEdit( true );
    }
    
    function cb_ok()
    {
        ShowMsg('СОХРАНЕНО !');
        
        setIsEdit(false);
        setSer( ser + 1 );
    }
    
    function cb_del()
    {
        const ID = selected[0];
        
        function __conf(e)
        {
            function __ok(e)
            {
                ShowMsg('УСПЕХ !');
                setBusy(false);
                setSelected( [] );
                setConfProps(null);
                setSer( ser + 1 );
            }
            
            function __fail(e)
            {
                ShowErr(e.m);
                setBusy(false);
            }
            
            setBusy(true);
            NuPost( 3025, {rid:ID}, __ok, __fail);
        }
        
        function __clo(e)
        {
            setConfProps(null);
        }
        
        setConfProps( {onOk: __conf, onClose: __clo} );
    }
    
    const DIS_DEL = selected.length < 1;
    
    const FLT = React.useMemo( () => {
        return {};
    }, [ser] );
    
    return (
        <>
            <LayVert2 rows='max-content max-content 1fr' gap='10px'>
                <MyTitle tit='КАТЕГОРИИ' />
                <LayHoriz2 cols='max-content max-content 1fr' gap='10px' >
                    <DaButton title='НОВАЯ'   icon={AddIcon} onClick={cb_new} />
                    <DaButton title='УДАЛИТЬ' icon={RemoveIcon} onClick={cb_del} disabled={DIS_DEL} />
                    <LaySpace />
                </LayHoriz2>
                <Grid3  cols={gColzCats} flt={FLT}
                         singleSel    selected={selected} onChangeSel={cb_sel}  onDblClick={cb_dbl}
                            uniq='tcat_id'   sort_field='tcat_id' sort_dir='asc'  apiCmd={3020}  code='tech_cats' />
            </LayVert2>
        
            <WinTechCatEdit rid={edRid} visible={isEdit} onOk={cb_ok} onClose={cb_clo} />
            
            <Win2Confirm busy={busy} visible={confProps !== null} title='УДАЛИТЬ КАТЕГОРИЮ ?' {...confProps}/>
        </>
    
    );
}



//--------------------------------------------------------


const gStTe = {
  
    selected:       [],
    busy:           false,
    ser:            0,
    
    pop:            0,
    pop_dct_tech:   {},
    pop_dct_conf:   {},
    
};



// ТЕХНИКИ
function ViTechs()
{
    const [sta, dispatch] = React.useReducer( CommonReducer, gStTe );
    
    function cb_close_pop()
    {
        dispatch( {type:'val', k:'pop', v:0} );
    }
    
    function cb_edited()
    {
        ShowMsg('УСПЕХ !');
        dispatch( {type:'val', k:'pop', v:0} );
        dispatch( {type:'val', k:'ser', v:sta.ser+1} );
    }
    
    function cb_new()
    {
        const TT = { rid: -1, onOk: cb_edited, onClose: cb_close_pop };
        
        dispatch( {type:'val', k:'pop', v:1} );
        dispatch( {type:'val', k:'pop_dct_tech', v:TT} );
    }
    
    function cb_del()
    {
        const ID = sta.selected[0];
        
        function __conf()
        {
            //console.log('CONFIRM !');
            
            dispatch( {type:'val', k:'busy', v:true} );
            
            function __ok(e)
            {
                dispatch( {type:'val', k:'selected', v:[]} );
                dispatch( {type:'val', k:'busy', v:false} );
                dispatch( {type:'val', k:'pop',  v:0} );
                dispatch( {type:'val', k:'ser', v:sta.ser+1} );
                
                ShowMsg('УДАЛЕНО !');
            }
            
            function __fail(e)
            {
                dispatch( {type:'val', k:'busy', v:false} );
                ShowErr( e.m );
            }
            
            NuPost(3028, {rid:ID}, __ok, __fail);
        }
        
        const TT = { onOk: __conf, onClose: cb_close_pop, title: 'УДАЛИТЬ ТЕХНИКУ ?' };
        
        dispatch( {type:'val', k:'pop', v:2} );
        dispatch( {type:'val', k:'pop_dct_conf', v:TT} );
    }
    
    function cb_sel(nu)
    {
        dispatch( {type:'val', k:'selected', v:nu} );
    }
    
    function cb_dbl(kk)
    {
        const TT = { rid: kk, onOk: cb_edited, onClose: cb_close_pop };
        
        dispatch( {type:'val', k:'pop', v:1} );
        dispatch( {type:'val', k:'pop_dct_tech', v:TT} );
    }
    
    const DIS_DEL = sta.selected.length < 1;
    
    
    const FLT = React.useMemo( () => {
        return {};
    }, [sta.ser] );
    
    return (
        <>
            <LayVert2 rows='max-content max-content 1fr' gap='10px'>
                <MyTitle tit='ТЕХНИКИ' />
                    <LayHoriz2 cols='max-content max-content 1fr' gap='10px' >
                        <DaButton title='НОВАЯ'   icon={AddIcon} onClick={cb_new} />
                        <DaButton title='УДАЛИТЬ' icon={RemoveIcon} onClick={cb_del} disabled={DIS_DEL} />
                        <LaySpace />
                    </LayHoriz2>
                <Grid3  singleSel    selected={sta.selected} onChangeSel={cb_sel}  onDblClick={cb_dbl}  flt={FLT}
                            cols={gColzTechs}	uniq='tech_id'   sort_field='tech_id' sort_dir='asc' apiCmd={3021}   code='techs'  />
            </LayVert2>
        
            <WinTechEdit visible={sta.pop===1} {...sta.pop_dct_tech} />
            
            <Win2Confirm busy={sta.busy} visible={sta.pop===2} {...sta.pop_dct_conf} />
        </>
    
    );
}

//--------------------------------


// Раздел с техниками..  редакт  категорий и техник
export function TabTechs()
{
	return (
	
		<>
        <LayHoriz2 cols='1fr minmax(900px, 1300px) 1fr' padding='30px'>
				
            <LaySpace />
                
            <LayHoriz2 cols='350px 1fr' gap='50px'>
                <ViCats />
                <ViTechs />
            </LayHoriz2>

			<LaySpace />		
		
        </LayHoriz2>
					</>
	
	);
}
