import React from 'react';


import * as DEFS from '../defs.js';
import * as HLP from '../helpers.js';

import { NuPost } from '../api.js';

import * as DT from '../dt.js';

import { Grid3 } from '../grid3.js';

import { DaDateField } from '../misc/date_field.js';
import { DaSearchBar } from '../misc/search_bar.js';

import { LayVert2 } from '../misc/layout.js';

import { CmNuFltCheckList  } from '../cm_mui.js';

import { DaComboTrainerLocals } from '../desktop/combo_trainer.js';


import { CommonReducer, ShowMsg, ShowErr } from '../misc_glob.js';



import { CmComColCellDateTime, CmComColTrpStatus, CmComColUser } from '../cm_table_cell_com.js';

import { CmCachedCustLink } from '../desktop/ca_cust_link.js';


import { EvBoundHow } from '../desktop/ev_act.js';
//-----------------------------------------------------

function MyColActs(pp)
{
    const ROW = pp.row;
    return <EvBoundHow evtAct={ROW.v_ts_act} trpActsCsv={ROW.v_acts} />
}

function MyColCust(pp)
{
	return <CmCachedCustLink rid={pp.row.v_cust} />;
}

//--------------------------------------


const gColz = [
	{ sort: true, align: 'center',  fld: 'v_bound', 		name: 'Когда забита', width: 170, renderCell: CmComColCellDateTime  },
	{ sort: true, align: 'center',  fld: 'v_trp_status',    name: 'Статус', width: 120, renderCell: CmComColTrpStatus  },
	{ sort: true, align: 'left',    fld: 'v_acts',          name: 'Движ', width: 210, renderCell: MyColActs  },
	{ sort: true, align: 'left',    fld: 'v_cust',          name: 'Клиент', width: 270, renderCell: MyColCust  },
	{ sort: true, align: 'center',  fld: 'v_start',         name: 'Дата события', width: 150, renderCell: CmComColCellDateTime  },
	{ sort: true, align: 'center',    fld: 'v_pay',           name: 'Абонемент', width: 130 },
	{ sort: true, align: 'center',    fld: 'v_ts',            name: 'Событие', width: 130 },
	{ sort: true, align: 'left',    fld: 'ts_user_id',      name: 'Тренер', width: 190, renderCell: CmComColUser },
];




const gRowStatusFlt = [

    //{  k: -1,  v: '* СТАТУС *' },
    {  k:  0,  v: 'НЕ ИСП' },
    {  k:  1,  v: 'ЗАБИТА' },
    {  k:  2,  v: 'ЗАКРЫТА' },
    {  k: 99,  v: 'НЕ ПРИШЁЛ' },

];









//--------------------------------------------------------------

const gSta11 = {
  
    selected:   [],
    busy:       false,
    ser:        0,
    
    pop:        null,
    
    flt_nam:    '',
    
    flt_sta_csv:    '',
    
    flt_bind_f: null,
    flt_bind_t: null,
    
    flt_evt_f:  null,
    flt_evt_t:  null,
    
    flt_u_id:       -1,
};

export function TabAllTrp( {} )
{
    const [sta, dispatch] = React.useReducer( CommonReducer, gSta11 );
    
    const FLT_TAB = React.useMemo( () => {
        
        return {    na: sta.flt_nam,
                    sta_csv: sta.flt_sta_csv,
					bind_from:      DT.DateStrIso(sta.flt_bind_f),
                    bind_to:        DT.DateStrIso(sta.flt_bind_t),
                    u_id: sta.flt_u_id,
                    evt_from:   DT.DateStrIso(sta.flt_evt_f),
                    evt_to:     DT.DateStrIso(sta.flt_evt_t) 
                    };
        
    }, [sta.ser, sta.flt_u_id, sta.flt_nam, sta.flt_bind_f, sta.flt_bind_t, sta.flt_evt_f, sta.flt_evt_t, sta.flt_sta_csv ] );
    
    
    /*
	const cb_row_dbl = (inf) =>
	{
		//console.log('INF ', );
		const RID = inf.row.v_ts;
		
		function cb_clo()
		{
			dispatch( {type: 'val', k:'pop', v:null} );
		}
		
		function cb_ok()
		{
			dispatch( {type: 'val', k:'pop', v:null} );
            dispatch( {type: 'val', k:'ser', v:sta.ser + 1} );
		}
		
        const T = <WinEvent2  	onOk={ cb_ok }  onClose={ cb_clo }  rid={RID}   date_start={null} /> ;
        
        dispatch( {type: 'val', k:'pop', v:T} );
        
		
	};
    */
	
    
    const cb_sel = React.useCallback( (s) => {
        dispatch( {type: 'val', k:'selected', v:s} );
    }, [] );
    
    const cb_chg = React.useCallback( (k,v) => {
        dispatch( {type: 'val', k:k, v:v} );
    }, [] );
    
    return (    <>
    
            <LayVert2 rows='max-content 1fr' padding='20px' gap='16px'>
                <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
                    <div style={{width: '300px'}}>
                        <DaSearchBar name='flt_nam' value={sta.flt_nam} onChange={cb_chg} label='ПО ИМЕНИ КЛИЕНТА' />
                    </div>
                    
                    <CmNuFltCheckList title='СТАТУСЫ' name='flt_sta_csv' val_csv={sta.flt_sta_csv} onChange={cb_chg}
													src_key='k' src_val='v' src={gRowStatusFlt} />
                                                    
                       
                    <div style={{width: '240px'}}>
                        <DaComboTrainerLocals name='flt_u_id' value={sta.flt_u_id} onChange={cb_chg}  />
                    </div>
                    
					<div style={{display: 'flex', gap: '4px', width: '360px'}}>
						<DaDateField  label='забита от' name='flt_bind_f'   onChange={cb_chg}  value={sta.flt_bind_f} />				
						<DaDateField  label='забита до' name='flt_bind_t'   onChange={cb_chg}  value={sta.flt_bind_t} />
					</div>
					
					<div style={{display: 'flex', gap: '4px', width: '360px'}}>
						<DaDateField  label='событие от' name='flt_evt_f'   onChange={cb_chg}  value={sta.flt_evt_f} />				
						<DaDateField  label='событие до' name='flt_evt_t'   onChange={cb_chg}  value={sta.flt_evt_t} />
					</div>
                </div>
                
        
                <Grid3  cols={gColz}	uniq='v_key'   sort_field='v_bound' sort_dir='desc' apiCmd={811} 
                            flt={FLT_TAB}  selected={sta.selected}     onChangeSel={cb_sel}  code='all_trps' />
          
            
            </LayVert2>
            
            {sta.pop}
                </>
    );
}

//-------------------

