import React from 'react';

import * as DEFS from '../defs.js';

import {DaToolTip} from '../misc/tooltip.js';

import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';



//------------------------------------------------------------------------

const gSS = {paddingLeft: '2px', fontSize: '20px', color: '#B04030'};

function AllowerHoursIconImp()
{
    return ( <AccessAlarmIcon sx={gSS} /> );
}

export const AllowerHoursIcon = React.memo( AllowerHoursIconImp );

//-------------------------



export function TxAllowedHours( {jsonStr} )
{
    if (jsonStr.trim() === '') return null;

    const oo = JSON.parse(jsonStr);

    const LE = oo.length;
    
    const pp = [];
    
    for (let x = 0; x < LE; x ++ )
    {
        const e = oo[x];
        const DW = DEFS.WEEK_DAYS[e[0]];
        const hs = e[1];
        const he = e[2];
        pp.push( <div key={x}>{DW}: с {hs}:00 до {he}:00</div> );
    }
    
    return <>{pp}</>;
}


//------------------

function TrpAllowedHoursInfoImp( {jsonStr} )
{
    if (jsonStr.trim() === '') return null;

    const pp = (<TxAllowedHours jsonStr={jsonStr}/> );

    return (   <DaToolTip title={pp}>
                    <AllowerHoursIcon />
                </DaToolTip> );
}

export const TrpAllowedHoursInfo = React.memo( TrpAllowedHoursInfoImp );

//-----------------------------------

