import React from 'react';


import * as DEFS  from '../defs.js';
import * as DT    from '../dt.js';

import useRipple from "./useRipple.tsx";

import {DaIconBtn} from './icon_btn.js';

import { LayHoriz2, LayVert2, LaySpace } from './layout';

import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';






//------------------------



function CellJuiceDefaultImp( {dt} )
{
    const s_dt  = DT.FmtDay( dt );
    return s_dt;
}

const CellJuiceDefault = React.memo( CellJuiceDefaultImp );

//-----------------------------------------------

function MyCellImp( {onClick, iso, cmpCell, isCur, isToday} )
{
    const ref         = React.useRef(null);
    
    const ripples     = useRipple(ref);
    
    const cl = cmpCell ? cmpCell : CellJuiceDefault;
    
    function cb_click()
    {
        onClick(iso);
    }
    
    let CLA = "yy_mon_calen_cell";
    
    if (isCur) CLA += ' cur';
    if (isToday) CLA += ' yy_mon_calen_cell_today';
    
    return (
             <div ref={ref} onClick={cb_click} className={CLA}>
                { ripples }
                 { React.createElement(cl, {dt:DT.DateParseIso(iso) }, null)   }
             </div>
    );
}

const MyCell = React.memo( MyCellImp );

//-----------------------

export function DateView( {initDt, onSel, cur, markToday, cmpCell} )
{
    const [dt, setDt] = React.useState( initDt );
    
    const MY_MONTH      = DT.DateMonth( dt );
    
    const ISO_TODAY     = DT.DateStrNowIso();
    
    const ISO_CUR = cur ? DT.DateStrIso( cur ) : null;
    
    const cb_click = ( dt_iso ) =>
    {
        const ret_dt = DT.DateParseIso( dt_iso );
        if (onSel) onSel( ret_dt );
        
    };
    
    
    const dt_mon_start = DT.DateStartOfMonth( dt );
    
    //console.log( dt_mon_start );
    
    const dt_wk_first = DT.DateStartOfWeek( dt_mon_start );
    
    //console.log('WEEK FIRST: ', dt_wk_first);
    
    const num_wk = DT.NumWeeksInMonth( dt_mon_start );
    
    
    
    let ar_cells = [];
    
    //console.log('WEEKS ', num_wk);
    
    let i = 0;
    
    let cur_dt = new Date( dt_wk_first );
    
    for (let x = 0; x < 7; x ++ )
    {
        const CLA = x < 5 ? 'yy_mon_calen_hd' : 'yy_mon_calen_hd yy_mon_calen_holiday';
        ar_cells.push( <div className={CLA} key={'hd'+x}>{ DEFS.WEEK_DAYS[x] }</div> );
    }
    
    for (let w = 0; w < num_wk; w ++ )
    {
        for (let x = 0; x < 7; x ++ )
        {
            const cell_mon = DT.DateMonth( cur_dt );
            
            const s_dt  = DT.DateStrIso( cur_dt );
            
            
            const IS_MY = cell_mon === MY_MONTH;
            
            if (IS_MY)
            {
                let CLA = IS_MY ? "yy_mon_calen_cell" : "yy_mon_calen_cell inact";
                
                const isCur = ISO_CUR && s_dt === ISO_CUR;
                const isToday = markToday && s_dt === ISO_TODAY;
                
                ar_cells.push( <MyCell key={s_dt} onClick={cb_click} iso={s_dt} cmpCell={cmpCell} isToday={isToday} isCur={isCur} /> );
            }
            else
            {
                ar_cells.push( <div key={s_dt}></div> );
            }
            
            cur_dt = DT.DateAddDays(cur_dt, 1);
        }
        
    }
    
    const s_tit = DT.FmtMonthAndYear(dt).toUpperCase();
    
    
    const cb_prev = React.useCallback( () => {
        setDt( DT.DateAddMonths(dt, -1) );
    }, [dt] );
    
    const cb_next = React.useCallback( () => {
        setDt( DT.DateAddMonths(dt, 1) );
    }, [dt] );
    
    // {borderBottom: '1px solid #00000020', display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: '46px', padding: '6px', fontWeight: 800, fontSize: '20px', color: '#FF50A0'}
    
    return (
    
        <LayVert2 rows='max-content 1fr'>
    
            <LayHoriz2 cols='max-content 1fr max-content' >
                <DaIconBtn  icon={ ArrowLeftIcon} onClick={cb_prev}   />
                <div className="dateview_tit">{s_tit}</div>
                <DaIconBtn  icon={ ArrowRightIcon} onClick={cb_next}   />
            </LayHoriz2>
        
            <div style={{fontFamily: 'body', userSelect: 'none', fontSize: '16px', gap: '4px', display: 'grid', gridTemplateRows: `50px repeat(${num_wk},60px)`, gridTemplateColumns: 'repeat(7,1fr)'}}>
            
                {ar_cells}
            
            </div>
        
        </LayVert2>
        
    );
}

