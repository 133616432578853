import React from 'react';

import { NuPost2 } from '../api.js';

import { LayVert2, LaySpace, LayHoriz2 } from '../misc/layout.js';

import { CommonReducer } from '../misc_glob.js';

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { ClubListCombo } from '../desktop/club_list_combo.js';
import { DaSwitcherYear } from '../misc/sw_year.js';
 
//------------------------------------------

function FmtTickYW(v)
{
    const q = v.split('.');
    
    return q[0].padStart(2, '0') + '.' + q[1].substring(2,4);
}



const gSS = {

    busy:   false, 
    data:   null,
    ys:     2023,
    ye:     2024,
    club:   -1,
};


export function TabStatsGraphMonths()
{
    const [sta, dispatch] = React.useReducer( CommonReducer, gSS );

    function cb_chg(k,v)
    {
        dispatch( {type: 'val', k:k, v:v} );
    }


    
	React.useEffect( () => {
		
		function __ok(e)
		{
            console.log(e.data);

            dispatch( {type: 'val', k:'data', v:e.data} );
            dispatch( {type: 'val', k:'busy', v:false} );
		}
		
		function __fail(e)
		{
            dispatch( {type: 'val', k:'busy', v:false} );
		}
		
		dispatch( {type: 'val', k:'busy', v:true} );
		
		NuPost2( 920, {ys:sta.ys, ye:sta.ye, club:sta.club}, __ok, __fail );
		
	}, [sta.ys, sta.ye, sta.club] );
    



    
    function cb_click_legend(ee)
    {
        console.log(ee);
    }
    
    let juice = <div></div>;
    
    if (sta.club > 0 && sta.data)
    {
        juice = (
        
            <ResponsiveContainer width="100%" height='90%' >
                <LineChart data={sta.data.gra} margin={{left: 30, right: 30}} >
                    <Line name='Касса с абонов (тыс)' type="monotone" dataKey="money_abon" stroke="#CFA030"  strokeWidth={3}  />
                    <Line name='Покупки абонов' type="monotone" dataKey="cnt_abons"   stroke="#3040FF"  strokeWidth={3} />
                    


                    <Line name='Касса с товаров' type="monotone" dataKey="money_good_sell"   stroke="#70703F"  strokeWidth={3} />
                    <Line name='Покупки товаров' type="monotone" dataKey="cnt_goods"         stroke="#40A070"  strokeWidth={3} />
                    
                    <Line name='Возвраты трень (бабки)' type="monotone" dataKey="money_returns"   stroke="#FF0000"  strokeWidth={3} />
                    
                    <Line name='Пробники' type="monotone" dataKey="cnt_free_trains"   stroke="#40E0E0"  strokeWidth={3} />
                    

                    
                    <Line name='Закрытых трень' type="monotone" dataKey="cnt_closed_ev"   stroke="#303040"  strokeWidth={3} />


                    <Line name='Новые клиенты (Все клубы)' type="monotone" dataKey="cnt_regs"   stroke="#FF20FF"  strokeWidth={3} />
                    
                    <Line name='Реги в тг боте (Все клубы)' type="monotone" dataKey="cnt_tg_reg"   stroke="#102060"  strokeWidth={3} />
                    

                    <CartesianGrid stroke="#B0B0B0"  strokeDasharray="3 3"  />
                    <XAxis dataKey="t" tickFormatter={FmtTickYW} />
                    <YAxis type="number" domain={[0, 'dataMax + 500']} scale="linear" />
                    <Tooltip />
                    <Legend layout='vertical' align='left' verticalAlign='top' onClick={cb_click_legend} />
                </LineChart>
            </ResponsiveContainer>
        
        );
    }
    
    return (
            <LayVert2 rows='max-content 1fr' gap='10px'>
    
                <LayHoriz2 cols='1fr 160px max-content max-content 1fr' padding='14px' gap='10px' >
                    <LaySpace />
                    <ClubListCombo label='КЛУБ' name='club' value={sta.club} onChange={cb_chg}/>
                    <DaSwitcherYear name='ys' value={sta.ys} onChange={cb_chg} label='ОТ' />
                    <DaSwitcherYear name='ye' value={sta.ye} onChange={cb_chg} label='ДО'/>
                    <LaySpace />
                </LayHoriz2>
    
            {juice}
            
            
            </LayVert2>
            );
}

