

import React from 'react';

import { BottomButtonsPanel } from '../cust_card/bottom_pan.js';

import { DaButton } from '../misc/but.js';

import * as DEFS from '../defs.js';
import * as HLP from '../helpers.js';



import { NuPost, NuPost2 } from '../api.js';

import {DaIconBtn} from '../misc/icon_btn.js'; 
import { LayVert2, LayHoriz2 } from '../misc/layout.js';
import { OverlayLoading } from '../misc/ovr_loading.js';
import { DaVertScrollFrame, DaVertScrollJuice} from '../misc/vscr.js';
import { Win2 } from '../misc/win2.js';

import { CommonReducer, ShowMsg, ShowErr } from '../misc_glob.js';

import { CmCachedCustLink } from './ca_cust_link.js';

import { WinCustomerSearch } from '../desktop/win_cust_search.js';

import PersonIcon from '@mui/icons-material/Person';


import Collapse from '@mui/material/Collapse';



import CheckIcon from '@mui/icons-material/Check';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import CloseIcon from '@mui/icons-material/Close';

import { EvActDispNameCSV } from '../desktop/ev_act.js';


//----------------------------------------------------



// текст - СТАТУС ТРЕНИ в абонементе
function CmTrpStatus(pp)
{
	return <div style={{...pp.sx, color: DEFS.gTrpStatusClr[pp.value]}}>{ DEFS.gTrpStatusDisp[pp.value] }</div>;
}


//----------------------------------------------



function MyListItemImp(pp)
{
	const r = pp.row;
	
	return (<div className='yy_transfer_item' >
					<CmTrpStatus sx={{width: '35%'}} value={r.trp_status}/>
					<div style={{width: '45%'}}><EvActDispNameCSV csv={r.trp_acts_csv} /></div>
					{pp.icon}		
					</div> );

}



const MyListItem = React.memo( MyListItemImp );


//----------------------------------------------


const gSta = {
  
    busy:               false,
    dst_cust_id:        null,
    src_cust_id:        null,
    lst_selected_ids:   [],
    is_search_cust:     false,
    rows:               [],
    
};

function MyJuice( {src_rid, onOk} )
{
    const [sta, dispatch] = React.useReducer( CommonReducer, gSta );
    
    
    React.useEffect( () => {
        
        function __ok(e)
        {
            dispatch( {type: 'val', k:'busy', v:false} );
            dispatch( {type: 'val', k:'rows', v:e.rows} );
            dispatch( {type: 'val', k:'src_cust_id', v:e.src_cu_id} );
        }
        
        function __fail(e)
        {
            ShowErr( e.m );
            dispatch( {type: 'val', k:'busy', v:false} );
        }
        
        dispatch( {type: 'val', k:'busy', v:true} );
        
        NuPost2(968, {pay_id: src_rid}, __ok, __fail );
        
    }, [] );
    
    
	const IS_EMPTY_LIST = sta.lst_selected_ids.length < 1;
	const IS_EMPTY_CUST = sta.dst_cust_id === null;
    

    const tt = [];
    
    let p_ar_right = [];
    
    {
		const rows = sta.rows;

		for (let x = 0; x < rows.length; x ++ )
		{
			const r = rows[x];
			
			const IS_ADDED = sta.lst_selected_ids.indexOf(r.trp_id) !== -1;
			
			{
				let p_ic;
				
				if (r.trp_status === DEFS.TRP_STATUS_UNUSED && !IS_ADDED && !IS_EMPTY_CUST)
				{
					const cb_move = () => {
						let nu = HLP.DeepCopy( sta.lst_selected_ids );
						nu.push( r.trp_id );
                        dispatch( {type: 'val', k: 'lst_selected_ids', v: nu} );
						
					};
                    
                    p_ic = <DaIconBtn  clr='#A0A0B0' icon={DoubleArrowIcon} onClick={cb_move}  />;
					
				}
				else
				{
					p_ic = <div style={{width: '10%'}}></div>;
				}
			
			
				tt.push(  <Collapse key={r.trp_id} in={!IS_ADDED} timeout={80}  >
									<div><MyListItem row={r} icon={p_ic} /></div>
								</Collapse> );
			}
			


			{
				// show in RIGHT

				const cb_ret = () => {
					
					const nu = HLP.DeepCopy( sta.lst_selected_ids );
					const at = nu.indexOf(r.trp_id);
					if (at !== -1) nu.splice(at, 1);
					dispatch( {type: 'val', k: 'lst_selected_ids', v: nu} );
					
				};
				
                const p_ic = <DaIconBtn  clr='#A06060' icon={CloseIcon} onClick={cb_ret}  />;
                

				
				p_ar_right.push(  <Collapse key={r.trp_id} in={IS_ADDED} timeout={80}  >
									<MyListItem key={r.trp_id} row={r} icon={p_ic} />
										</Collapse> );
			}
            
		}

		

        
    }
    
    
    
	const cb_submit = (e) => {

		const cb_ok = (e) => {
			dispatch( {type: 'val', k:'busy', v:false} );
			onOk(e.pay_id);
		};
		
		const cb_fail = (e) => {
			dispatch( {type: 'val', k:'busy', v:false} );
			ShowErr('FAIL: ', e.m);
		};
		
		
		dispatch( {type: 'val', k:'busy', v:true} );
		
		NuPost2( 969, {trps: sta.lst_selected_ids, dst_cu_id: sta.dst_cust_id}, cb_ok, cb_fail);
	};
	
	
	const cb_show_search = () => {
        dispatch( {type: 'val', k: 'is_search_cust', v: true} );
	};

	let p_dst_cust = (!IS_EMPTY_CUST) ? <div style={{padding: '6px', display: 'flex', gap: '6px', color: '#707070'}}><div>Кому: </div><CmCachedCustLink shorten rid={sta.dst_cust_id} /></div> :
											(<div style={{display: 'flex', justifyContent: 'center'}}>
												<DaButton  icon={PersonIcon} onClick={cb_show_search} title='КОМУ...' />
											</div>);
											
    
    
    
    const cb_sel = (e) => {
        //console.log('GOT ', e);
        
        if (e !== sta.src_cust_id)
        {
            dispatch( {type: 'val', k: 'is_search_cust', v: false} );
            dispatch( {type: 'val', k: 'dst_cust_id', v: e} );
        }
        else
        {
            ShowErr('ТОТ ЖЕ КЛИЕНТ ?');
        }
    };
    
    const cb_hide = () => {
        dispatch( {type: 'val', k: 'is_search_cust', v: false} );
    };
    
    // 
    
    return (
    
        <>
    
        <LayVert2 rows='max-content 1fr max-content' >
    
            <div className="yy_info" >Создаст новый абонемент (0руб) с выбранными тренировками. Выбрано: {sta.lst_selected_ids.length}.</div>
            
            
            <LayHoriz2 cols='1fr 1fr' >
                <LayVert2 rows='max-content 1fr' sx={{borderRight: '1px solid #00000020'}}>
                    <div style={{padding: '10px', display: 'flex', gap: '6px', color: '#7070A0'}}>
						<div>Владелец абонемента:</div>
						<CmCachedCustLink shorten rid={sta.src_cust_id} />
					</div>
                    <DaVertScrollFrame>
                        <DaVertScrollJuice>
                        { tt }
                        </DaVertScrollJuice>
                    </DaVertScrollFrame>
                </LayVert2>
                
                <LayVert2 rows='max-content 1fr' >
                    
                    {p_dst_cust}
                    
                    <DaVertScrollFrame>
                        <DaVertScrollJuice>
                        { p_ar_right }
                        </DaVertScrollJuice>
                    </DaVertScrollFrame>
                    
                </LayVert2>
            </LayHoriz2>
            
            <BottomButtonsPanel >
                <DaButton  onClick={cb_submit} title='ПЕРЕНЕСТИ' icon={ CheckIcon } disabled={IS_EMPTY_LIST} />
            </BottomButtonsPanel >
        
        </LayVert2 >
        

        
        
        <OverlayLoading active={sta.busy} />
        
        <WinCustomerSearch visible={sta.is_search_cust} hideNewCust onOk={cb_sel}  onClose={cb_hide} />
        
        </>
    );
}



// АБОНЕМЕНТ - попап перенос тренировок

export function WinTrpTransfer2( {visible, src_rid, onClose, onOk } )
{
    return (
    
        <Win2 visible={visible} title='ПЕРЕНОС ТРЕНИРОВОК' onClose={onClose} width={960} height={530}  >
            <MyJuice src_rid={src_rid} onOk={onOk} />
        </Win2>
    
    );
}

