import React from 'react';

import { useApiQuery2 } from '../api_stuff.js';
import useSound from 'use-sound';

 
import { ShowMsg, ShowErr} from '../misc_glob.js';

import {DaToolTip} from '../misc/tooltip.js';


import { SmNotifyCounter } from '../menu2/items.js';


// оповещалка о заявках
export function MeNotifyCureq( )
{
    const { isFetching, isLoading, isError, data, error } = useApiQuery2( 1061, {}, {refetchIntervalInBackground: true, staleTime: 31000, refetchInterval: 72000} );


    

    if (isLoading || !data) return <div></div>;
    if (isError) return <div>WTF ?</div>;
    
    const val = data.val;
    
    
    
    const tp = (<div style={{display: 'flex', gap: '10px', flexDirection: 'column'}}>
                    <div>Активных заявок: {val}</div>
               </div>);
    
    

    return (<DaToolTip title={tp}>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    
                    
                    {   val > 0 ? ( <SmNotifyCounter clr='#60B060' num={val} /> ) : null  }
                    
                    <MyChgTrack val={val}/>
                    
                </div>
                
                
                
            </DaToolTip>
            
    
    );
} 


//-----------------------


function MyChgTrack( {val} )
{
    const [my, setMy] = React.useState( val );
    
    const [play, { stop }] = useSound('/snd/not2.wav');
    
    React.useEffect( () => {
        
        //console.log('chg chg  VAL: ', val, ' MY ', my);
        
        if (val > my)
        {
            play();
        }
        
        setMy(val);
        
    }, [val] );
    
    
    return (null);
}
