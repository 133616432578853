 
import React from 'react';


import * as HLP from '../helpers.js';
import * as DT from '../dt.js';
import * as DEFS from '../defs.js';

import useRipple from "../misc/useRipple.tsx";

import { CommonReducer, ShowErr, ShowMsg} from '../misc_glob.js';

import {DaIconBtn} from '../misc/icon_btn.js';

import { LaySpace, LayVert2, LayHoriz2, LayCenter} from '../misc/layout.js';

import {  NuPost2 } from '../api.js';

import { EvActDispNameCSV } from './ev_act.js';

import { OverlayLoading } from '../misc/ovr_loading.js';

import { Win2 } from '../misc/win2.js';

import { ShortName } from './short_name.js';

import { BottomButtonsPanel } from '../cust_card/bottom_pan.js';
import { DaButton } from '../misc/but.js';

import { DaVertScrollFrame, DaVertScrollJuice } from '../misc/vscr.js';

//----





const gTT = {

    ready: false,
    busy: false,
    trpId: -1,
    cuId: -1,

    lst:    [],

};
 
 

function CmElemImp( {ee, isCur, onClick} )
{
    const ref         = React.useRef(null);
    const ripples     = useRipple(ref);

    function cb_clk()
    {
        onClick( ee.trp_id, ee.cu_id );
    }

    const cssCur = isCur ? ' cur' : '';

    return (<div ref={ref} className={`mon_brow_item${cssCur}`} onClick={cb_clk} >
                <div className="nam">{ee.cu_name}</div>
                <div style={{paddingLeft: '14px'}}><ShortName ctx={DEFS.SHORT_NAME_ABON} id={ee.pay_id} /></div>
                <div style={{paddingLeft: '14px'}}><EvActDispNameCSV csv={ee.trp_acts_csv} /></div>

                {ripples}

            </div>);
}


const CmElem = React.memo( CmElemImp );


//-----------------------



function CmList( {lst, curId, onClick} )
{
    if (lst.length < 1)
    {
        return <LayCenter>Пусто</LayCenter>;
    }

    const ar = [];

    for (let x = 0; x < lst.length; x ++ )
    {
        const e = lst[x];
        ar.push(  <CmElem key={x} ee={e}  isCur={curId===e.trp_id}  onClick={onClick} /> );
    }

    return (

        <DaVertScrollFrame faders >
            <DaVertScrollJuice padding='16px'>
                {ar}
            </DaVertScrollJuice>
        </DaVertScrollFrame>

    );
} 
 

function BrBody( {prodId, dtIso, onOk} )
{
    const [sta, dispatch] = React.useReducer( CommonReducer, gTT );

    React.useEffect( () => {

        if (prodId < 1) return;

        function __ok(e)
        {
            dispatch( {type: 'vals', dct: {lst: e.lst_trps, busy: false, ready: true} } );
            console.log(e);
        }

        function __fail(e)
        {
            dispatch( {type: 'vals', dct: {busy: false, ready: true} } );
            ShowErr( e.m );
        }

        dispatch( {type: 'vals', dct: {busy: true} } );

        NuPost2( 4140, {prodId:prodId, dt:dtIso}, __ok, __fail );

    }, [prodId, dtIso] );

    const cb_clk = React.useCallback( (trpId, cuId) => {
        dispatch( {type: 'vals', dct: {trpId: trpId, cuId: cuId} } );
    }, [] );

    const cb_sub = React.useCallback( () => {
        onOk( sta.trpId, sta.cuId );
    }, [sta.trpId, sta.cuId] );

    const DIS_SUB = (sta.trpId === -1);

	return (

        <LayVert2 rows='1fr max-content'>

            <CmList lst={sta.lst} curId={sta.trpId} onClick={cb_clk} />

            <BottomButtonsPanel >
                <DaButton title='ВПИСАТЬ' disabled={DIS_SUB} onClick={cb_sub} />
            </BottomButtonsPanel >

            <OverlayLoading active={sta.busy || !sta.ready} />

        </LayVert2>
    );
} 
  
//----------------------------------------------

// окно выбора МЕСЯЧНЫХ трень под ПОДУКТ и ДАТУ
export function WinMonTrpsBrowserForEvt( {prodId, dtIso, visible, onOk, onClose} )
{
	return (

        <Win2 visible={visible} title='ВПИСАТЬ НА ТРЕНИРОВКУ' onClose={onClose} width={480} height={650}  >
            <BrBody onOk={onOk} prodId={prodId} dtIso={dtIso}  />
        </Win2>
	);
}
