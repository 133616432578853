import React from 'react';

import { CmComColCellDateTime } from '../cm_table_cell_com.js';

import * as DEFS from '../defs.js';
import * as HLP from '../helpers.js';

import { Grid3 } from '../grid3.js';

import {  NuPost2 } from '../api.js';

import { Win2Confirm } from '../misc/win2_confirm.js';

import { CommonReducer, ShowMsg, ShowErr, FmtPayOpBind } from '../misc_glob.js';


import * as DT from '../dt.js';
import { LayVert2, LayHoriz2, LaySpace } from '../misc/layout.js';

import { DaButton } from '../misc/but.js';
import { DaNumericField } from '../misc/numeric.js';
import { Win2 } from '../misc/win2.js';
import { DaTextField } from '../misc/text_field.js';



import AddCardIcon from '@mui/icons-material/AddCard';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

//-----------------------------------------------------------

const gStaSta = {

    busy: false,

    pop: 0,
    pop_dct_conf: {},

    ser: 0,

    state: DEFS.TG_BOT_ACC_ST_NONE,

    tgId: 0,

    confCode: '',
    digits:   '',
};





function ScrNothing( {onReq1, onReq2} )
{
    const [digits, setDigits] = React.useState( '' );

    function cb_chg_dig(k,v)
    {
        setDigits(v);
    }

    function cb_sub()
    {
        onReq2( digits );
    }

    const DIS_SUB = digits.trim().length < 4;

    return (
    
            <LayHoriz2 cols='1fr 700px 1fr'>

            <LaySpace />

            <div>
            <div style={{padding: '20px', fontSize: '20px', color: '#404040'}}>
            <div>У этого клиента нет доступа к телеграм-боту. Варианты активации:</div>

            <div className="yy_inf_blk">
                <div className="num">1</div>
                <div>Сгенерировать для клиента уникальную ссылку на бота.</div>
                <div>Нажми <span onClick={onReq1} className="lnk">СЮДА</span>.</div>
            </div>

            <div className="yy_inf_blk">
                <div className="num">2</div>
                <div>Клиент добавляет себе бота в телеге (дать ему QR код).</div>
                <div>В чате с ботом он видит кнопку СГЕНЕРИРОВАТЬ 4-х ЗНАЧНЫЙ КОД.</div>
                <div>В чат с ботом придёт 4 цифры. И в течении 2х минут их нужно ввести сюда:</div>
                
                <LayHoriz2 cols='120px 100px 1fr' gap='10px' sx={{paddingTop: '10px', alignItems: 'center'}}>
                    <DaTextField label='4 ЦИФРЫ' name='digits' value={digits}  onChange={cb_chg_dig} />
                    <DaButton disabled={DIS_SUB} title='ПРОВЕРИТЬ' onClick={cb_sub} />
                    <LaySpace />
                </LayHoriz2>
            </div>

            </div>

            </div>

            <LaySpace />

            </LayHoriz2>
    );
}

function ScrActCodeIsSet( {confCode, onClickDel} )
{
    return (
        <LayHoriz2 cols='1fr 800px 1fr' >

            <LaySpace />

            <div className="yy_inf_blk" style={{height: '200px'}}>
                <div>Для клиента сгенерирована персональная ссылка на ТЕЛЕГРАМ-БОТА.</div>
                <div>Ему нужно пройти по ней и нажать кнопку START/НАЧАТЬ (внизу экрана).</div>
                <div style={{paddingTop: '10px', paddingBottom: '30px', fontSize: '22px', color: 'var(--fl-clr-primary)'}}>{DEFS.TG_BOT_URL + '?start=' + confCode}</div>
                <DaButton title='ЗАБЫТЬ / ЛИШИТЬ ДОСТУПА' icon={CloseIcon} onClick={onClickDel} />
            </div>

            <LaySpace />
        </LayHoriz2>
);
}



function MyText( pp )
{
    return <span style={{whiteSpace: 'pre', fontSize: '12px', color: '#404040'}}>{pp.value}</span>;
}

const gStatusShit = [ '???', 'УСПЕХ', 'ОШИБКА' ];
const gStatusShitClr = [ '#000000', '#407040', '#704040' ];

function MyStatus( pp )
{
    const CLR = gStatusShitClr[ pp.value ];
    return <span style={{color: CLR}}>{gStatusShit[ pp.value ]}</span>;
}

const gCols = [

    { sort: true,  align: 'center', fld: 'tbm_id',              name: 'ID',     width: 100   },
    { sort: true,  align: 'center', fld: 'tbm_state',           name: 'Статус', width: 110, renderCell: MyStatus   },
    { sort: true,  align: 'center', fld: 'tbm_dt_last_act',     name: 'Дата',  width: 150, renderCell: CmComColCellDateTime   },
    { sort:false,  align: 'left',   fld: 'tbm_text',            name: 'Текст', width: 240, renderCell: MyText   },
    { sort:false,  align: 'left',   fld: 'tbm_short_code',      name: 'SC',     width: 130   },
];
  

function ScrGood( {onClickDel, cuId} )
{
    const FLT = React.useMemo( () => {
        return {cuId:cuId}
    }, [] );

    return (
        <LayHoriz2 cols='1fr 800px 1fr' >

            <LaySpace />

            <LayVert2 rows='max-content 1fr' gap='10px' padding='10px' >

                <div className="yy_inf_blk" style={{height: '100px'}}>
                    <div style={{paddingBottom: '20px'}}>Может пользоваться телеграм-ботом.</div>
                    <DaButton title='ЗАБЫТЬ / ЛИШИТЬ ДОСТУПА' icon={CloseIcon} onClick={onClickDel} />
                </div>


                <Grid3  isNuApi cols={gCols} apiCmd={4144}
                                uniq='tbm_id'  sort_field='tbm_dt_last_act' sort_dir='desc' flt={ FLT }  code='cc_tg_msgs' />

            </LayVert2>
            <LaySpace />
        </LayHoriz2>
);
}

//------------------------------------------

export function ViTgBot( {cu_id} )
{
    const [sta, dispatch] = React.useReducer( CommonReducer, gStaSta );

    React.useEffect( () => {


        function __ok(e)
        {
            const ROW = e.row;

            const tgu = ROW ? ROW.tba_tg_uid : 0;
            const cf =  ROW ? ROW.tba_confirm_code : '';
            const sta = ROW ? ROW.tba_state : DEFS.TG_BOT_ACC_ST_NONE;
            const dig = ROW ? ROW.tba_4digits : '';

            dispatch( {type: 'vals',
                       dct:  {busy: false, tgId: tgu, confCode: cf, state: sta, digits: dig}
                      } );
        }

        function __fail(e)
        {
            dispatch( {type: 'val', k:'busy', v:false} );
            ShowErr(e.m);
        }

        dispatch( {type: 'val', k:'busy', v:true} );

        NuPost2( 1081, { cu_id: cu_id}, __ok, __fail );

    }, [sta.ser] );


    function cb_req1()
    {
        function __ok(e)
        {
            dispatch( {type: 'val', k:'ser', v:sta.ser+1} );
        }
        function __fail(e)
        {
            ShowErr(e.m);
        }

        NuPost2(1082, {cu_id: cu_id}, __ok, __fail );
    }

    function cb_req2( digits )
    {
        function __ok(e)
        {
            dispatch( {type: 'val', k:'ser', v:sta.ser+1} );
            ShowMsg('УСПЕХ !');
        }
        function __fail(e)
        {
            ShowErr(e.m);
        }

        NuPost2(1083, {cu_id: cu_id, digits: digits}, __ok, __fail );
    }

    function cb_clo_pop()
    {
        dispatch( {type: 'val', k:'pop', v:0} );
    }

    function cb_del()
    {
        console.log('DELETE DUDE ACCESS');

        function __conf()
        {
            function __ok(e)
            {
                dispatch( {type: 'vals', dct:{ ser:sta.ser+1, pop:0  }} );
                ShowMsg('УСПЕХ !');
            }
            function __fail(e)
            {
                ShowErr(e.m);
            }
    
            NuPost2(1084, {cuId: cu_id}, __ok, __fail );
        }

        const DDD = {

            pop: 1,
            pop_dct_conf: { title: 'ТОЧНО ЛИШИТЬ ДОСТУПА ?', onOk: __conf, onClose: cb_clo_pop },
        };

        dispatch( {type: 'vals', dct: DDD} );
    }

    if (sta.busy) return <LaySpace />;


    let p_scr = <div>unk</div>;

    if (sta.state === DEFS.TG_BOT_ACC_ST_NONE)
    {
        p_scr = <ScrNothing onReq1={cb_req1} onReq2={cb_req2} />;
    }
    else if (sta.state === DEFS.TG_BOT_ACC_ST_WAIT_LINK)
    {
        p_scr = <ScrActCodeIsSet confCode={sta.confCode}  onClickDel={cb_del} />;
    }
    else if (sta.state === DEFS.TG_BOT_ACC_ST_GOOD)
    {
        p_scr = <ScrGood onClickDel={cb_del} cuId={cu_id} />;
    }


    return (

        <>

        {p_scr}


        <Win2Confirm busy={sta.busy} visible={sta.pop === 1} {...sta.pop_dct_conf} >
            Клиенту придётся снова проходить активацию.
        </Win2Confirm>
    
        </>
    );
}

