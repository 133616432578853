 


const gAlpLatin = 'abcdefghijklmnopqrstuvwxyz';
const gAlpCyr   = 'абвгдеёжзийклмнопрстуфхцчшщьыъэюя';

function StrHasAnyChar( st, find )
{
    const L2 = find.length;
    for (let x = 0; x < L2; x ++ )
    {
        if (st.includes( find.at(x) ) ) return true;
    }

    return false;
}

// mad skillz - замиксовали алфавиты ?
export function StrGotMixedAlphaBets( st )
{
    if (st.trim() === '') return false;
    st = st.toLowerCase();
    return StrHasAnyChar(st, gAlpLatin) && StrHasAnyChar(st, gAlpCyr);
}

//---------------------------


export function IsNumber(v)
{
	return typeof(value) === 'number' && isFinite(v);
}

export function ToInt(v)
{
	if ( typeof(v) === 'number' && (!isNaN(v))) return v;
	
	if (typeof(v) === 'string')
	{
		let x = parseInt(v);
		if (!isNaN(x)) return x;
	}
	
	
	if (v === true) return 1;
	if (v === false) return 0;
	

	console.warn('WTF ? ', v, 'typeof...', typeof(v));
	
	return v;
}




export function ShallowCopy(oo)
{
    let r = {};
    
    for (let k in oo)
    {
        r[k] = oo[k];
    }
    
    return r;
} 
  
  
// shallow copy .. drops prefix in DST
export function DictCopyFieldsWithPrefix(dst,src,prefix)
{
    const PLEN = prefix.length;

    for (let k in src)
    {
        const i = k.indexOf(prefix);
        if (i !== 0) continue;
        const e = src[k];
        const nk = k.substring(PLEN);
        dst[nk] = e;
    }
} 


 
export function DeepCopy(a_from)
{
    return JSON.parse(JSON.stringify(a_from));
}
 
 
export function PadZ2(a_v)
{
    let r = '';
    if (a_v < 10) r += '0';
    r += a_v;
    return r;
}

export function ZeroPAD2(v)
{
    v = '' + v;
    if (v.length < 2) return '0' + v;
    return v;
}





export function DateIsoToOur(a_str)
{
    if (a_str == null) return '---';
    
    let a = a_str.split('-');
    
    let y = parseInt(a[0]);
    let m = parseInt(a[1]);
    let d = parseInt(a[2]);
    
    if ( isNaN(y) || isNaN(m) || isNaN(d) )
    {
        return '---';
    }
    
    return PadZ2(d) + '.' + PadZ2(m) + '.' + y;
}


export function DateIsoToOurOrNull(a_str)
{
    if (a_str == null) return null;
    
    let a = a_str.split('-');
    
    let y = parseInt(a[0]);
    let m = parseInt(a[1]);
    let d = parseInt(a[2]);
    
    return PadZ2(d) + '.' + PadZ2(m) + '.' + y;
}


 

export function DomAbsToLoc(elem, xx, yy)
{

    var boxRectangle = elem.getBoundingClientRect();
 

	var localX = ( xx - boxRectangle.left );
	var localY = ( yy - boxRectangle.top );
 
	var borderWidth = parseInt( window.getComputedStyle( elem ).borderTopWidth, 10 );
	localX -= borderWidth;
	localY -= borderWidth;
    
    return [localX, localY];
}



export function STRG_Get(a_key)
{
    let r = window.localStorage.getItem(a_key);
    //console.log('STRG_Get ', a_key, ' : ', r);
    return r;
}

export function STRG_Set(a_key, a_val)
{
    //console.log('STRG_Set ', a_key, ' : ', a_val);
    window.localStorage.setItem(a_key, a_val);
}




export function CsvSplit(a_csv)
{
    let r = [];

    if (a_csv === null || a_csv.trim() === '') return r;

    let s = a_csv.split(',');
    for (let x = 0; x < s.length; x ++ )
    {
        let t = parseInt(s[x]);
        if ( isNaN( t ) )
        {
            console.warn('CsvSplit NaN @ ', a_csv);
            continue;
        }
        
        r.push( t );
    }
    
    return r;
}


export function CsvTestID(a_csv, a_id)
{
    //console.log('CsvTestID ', a_csv, ' --> ', a_id);
    if (a_csv.trim() == '') return false;
    let ar = CsvSplit(a_csv);
    if (ar.length < 1) return false;
    return ar.includes(a_id);
}


export function CsvToggleID(a_csv, a_id)
{
    //console.log('CsvToggleID ', a_csv, ' --> ', a_id);
    
    let ar = CsvSplit(a_csv);
    let i = ar.indexOf(a_id);
    
    if (i == -1)
    {
        ar.push( a_id );
    }
    else
    {
        ar.splice(i, 1);
    }
    
    return ar.join(',');
}

//--------------------------------------------



export function IsSameAsJson(o1, o2)
{
    return JSON.stringify(o1) == JSON.stringify(o2);
}


export function ArrayOfDictsFindByPropValue(a_ar, a_prop_name, a_prop_value)
{
    if (!a_ar) return null;
    let l = a_ar.length;
    for (let x = 0; x < l; x ++ )
    {
        let e = a_ar[x];
        if (e[a_prop_name] == a_prop_value) return e;
    }
    
    return null;
}

export function ArrCountVal(a_ar, a_val)
{
    let r = 0;
    for (let x = 0; x < a_ar.length; x ++ )
    {
        if (a_ar[x] == a_val) r ++;
    }
    return r;
}

export function ArrayOfDictsFindByPropValueIndex(a_ar, a_prop_name, a_prop_value)
{
    let l = a_ar.length;
    for (let x = 0; x < l; x ++ )
    {
        let e = a_ar[x];
        if (e[a_prop_name] == a_prop_value) return x;
    }
    
    return -1;
}

export function ArrayIncludeUnique(a_ar, a_val)
{
	let i = a_ar.indexOf(a_val);
	if (i !== -1) return;
	a_ar.push(a_val);
}


export function ArrayDelElemWithKey(a_ar, a_key, a_val)
{
    for (let x = 0; x < a_ar.length; x ++ )
    {
        if (a_ar[x][a_key] != a_val) continue;
        a_ar.splice(x,1);
        return true;
    }
    
    return false;
}

export function MapValue(v, in_min, in_max, out_min, out_max)
{
    return (v - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
}


export function SelMin(aa, bb)
{
    return aa < bb ? aa : bb;
}


export function PickNonNull( ar )
{
    for (let x = 0; x < ar.length; x ++ )
    {
        let e = ar[x];
        if (e !== null) return e;
    }
    
    console.warn('UTIL_PickNonNull -> unexpected !');
    return null;
}


export function Now()
{
    return Date.now();
}




//-----------------------------

export function CookieSet(cname, cvalue)
{
    let exdays = 365;
  const d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;samesite=strict";
}


export function CookieGet(cname)
{
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}






