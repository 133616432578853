import React from 'react';
import * as DT from '../dt.js';
import * as DEFS from '../defs.js';

import { CommonReducer, ShowMsg, ShowErr } from '../misc_glob.js';

import { OverlayLoading } from '../misc/ovr_loading.js';

import { DaNumericField } from '../misc/numeric.js';
import { CmCachedCustLink } from './ca_cust_link.js';
import { CmComboPayMethod } from './combo_pay_method.js';
import {MdPerson} from 'react-icons/md'
import { ComboMoneyKind } from './combo_pay_method.js';

import { NuPost, NuPost2 } from '../api.js';

import { LayVert2, LayHoriz2, LaySpace } from '../misc/layout.js';

//------------------------




const gSX2 = {padding: '8px', alignItems: 'center', color: '#509050', display: 'flex', gap: '6px'};


const gSTF = {

    money0_meth:    0,
    money0_amo:     0,

    money1_meth:    0,
    money1_amo:     0,


    credit_amo:     0,
    bonus_amo:      0,

    busy: false,

    maxBonus:       0,
    maxCredit:      0,


    change_dest:    DEFS.PAYFORM_KIND_BALANCE,

};

const MAX_MONEY_FIELDS = 2;



function __readEnts( dispatch, ents )
{
    //console.log('READING ENTS !! ', ents);

    const dct = {};

    let monIndex = 0;

    for (let x = 0; x < ents.length; x ++ )
    {
        const e = ents[x];

        if (e.what === DEFS.PAYFORM_KIND_MONEY)
        {
            if (monIndex > MAX_MONEY_FIELDS)
            {
                throw 'WTF ??? MONEY FIELD OVERFLOW !!!';
            }

            dct[ `money${monIndex}_amo` ] = e.amount;
            dct[ `money${monIndex}_meth` ] = e.meth;

            monIndex ++;
        }
        else if (e.what === DEFS.PAYFORM_KIND_BALANCE)
        {
            dct.credit_amo = e.amount;
        }
        else if (e.what === DEFS.PAYFORM_KIND_BONUS)
        {
            dct.bonus_amo = e.amount;
        }
    }

    dispatch( {type: 'vals', dct:dct} );
}

function __packForSV( sta, cuId, needToPaySum, dt )
{
    const r =  {

        ents: [],
        cuId: cuId,
        needToPaySum: needToPaySum,
        change_dest: sta.change_dest,
        dtIso: DT.DateTimeStrIso(dt),
    };

    const ents = r.ents;

    if (sta.money0_amo > 0)
    {
        ents.push( { what: DEFS.PAYFORM_KIND_MONEY, meth: sta.money0_meth, amount: sta.money0_amo } );
    }

    if (sta.money1_amo > 0)
    {
        ents.push( { what: DEFS.PAYFORM_KIND_MONEY, meth: sta.money1_meth, amount: sta.money1_amo } );
    }

    if (sta.credit_amo > 0)
    {
        ents.push( { what: DEFS.PAYFORM_KIND_BALANCE, amount: sta.credit_amo } );
    }

    if (sta.bonus_amo > 0)
    {
        ents.push( { what: DEFS.PAYFORM_KIND_BONUS, amount: sta.bonus_amo } );
    }

    return r;
}


export function usePayFormState( cuId, needToPaySum, dt )
{
    //console.log('usePayFormState... ');

    const [sta, dispatch] = React.useReducer( CommonReducer, gSTF );

    React.useEffect( () => {

        function __ok(e)
        {
            //console.log('GOT PAYFORM SHIT !', e);
            dispatch( {type: 'vals', dct: { busy:false, maxBonus: e.bonus, maxCredit: e.credit }   } );
        }

        function __fail(e)
        {
            ShowErr( e.m );

            dispatch( {type: 'val', k:'busy', v:false} );
        }

        dispatch( {type: 'val', k:'busy', v:true} );

        NuPost2( 1093, {cu_id: cuId}, __ok, __fail );

    }, [cuId] );

    const cb_chg = React.useCallback( (k,v) =>
    {
        dispatch( {type: 'val', k:k, v:v} );
    }, [] );


    const valMoney  = sta.money0_amo + sta.money1_amo;
    const valCredit = sta.credit_amo;
    const valBonus  = sta.bonus_amo;

    const TOT = valMoney + valCredit + valBonus;

    let overflow = 0;

    let BAD_TOT = false;

    if (cuId)
    {
        if (valMoney > needToPaySum && valCredit < 1 && valBonus < 1)
        {
            overflow = valMoney - needToPaySum;
        }
        else
        {
            BAD_TOT = TOT !== needToPaySum;
        }
    }
    else
    {
        BAD_TOT = TOT !== needToPaySum;
    }



    return {
        cb_chg:         cb_chg,
        sta:            sta,
        tot:            TOT,
        badTot:         BAD_TOT,
        overflow:       overflow,
        cuId:           cuId,
        needToPaySum:   needToPaySum,

        packSV:  () => { return __packForSV( sta, cuId, needToPaySum, dt ); },
        readEnts: (ents) => {  __readEnts( dispatch, ents ) },

        hasSpendBonus: () => { return valBonus > 0 },

    };
}

//----------------------------------------------------------------------

export function PayFormVis( {disabled, state} )
{
    
    const cb_chg        = state.cb_chg;
    const sta           = state.sta;
    const cuId          = state.cuId;
    const needToPaySum  = state.needToPaySum;
    const overflow      = state.overflow;

    const valCredit = sta.credit_amo;
    const valBonus  = sta.bonus_amo;

    




    const maxCredit = sta.maxCredit;
    const maxBonus = sta.maxBonus;

    

    const DIS_MON = disabled;
    const DIS_CRE = disabled || maxCredit < 1;
    const DIS_BON = disabled || maxBonus < 1;


    const ar = [];
 
    for (let x = 0; x < 2; x ++ )
    {
        const n1 = `money${x}_amo`;
        const n2 = `money${x}_meth`;

        const p = (
            <LayHoriz2  cols='1fr 160px' padding='6px' gap='10px'  key={'mon'+x} sx={{height: undefined}}>
               <DaNumericField  disabled={DIS_MON} name={n1} value={sta[n1]} label='ДЕНЬГАМИ' onChange={cb_chg}/>
               <CmComboPayMethod  disabled={DIS_MON}  name={n2}   value={sta[n2]}   onChange={cb_chg} />
            </LayHoriz2>
        );
        ar.push( p );
    }



    const p_dude = cuId > 0 ? (
        <div style={gSX2}>
        <MdPerson style={{fontSize: '22px'}} />
        <CmCachedCustLink rid={cuId} shorten plain plain_clr='#307030' />
        </div>
    ) : null;


    return (

        <div style={gSX1}>

            {p_dude}

            {ar}

            <LayHoriz2 cols='1fr 160px' padding='6px' gap='10px' sx={{height: undefined}}>
                <DaNumericField  disabled={DIS_CRE} name='credit_amo' value={valCredit}    onChange={cb_chg} label='С БАЛАНСА'  max={maxCredit} min={0}  />
                <TxAvail v={maxCredit} />
            </LayHoriz2>    

            <LayHoriz2 cols='1fr 160px' padding='6px' gap='10px' sx={{height: undefined}}>
                <DaNumericField  disabled={DIS_BON} name='bonus_amo'  value={valBonus}    onChange={cb_chg} label='БОНУСАМИ'  max={maxBonus} min={0}  />
                <TxAvail v={maxBonus} />
            </LayHoriz2>


            <div style={{borderTop: '1px solid #00000020', fontWeight: 800, padding: '6px', fontSize: '16px', color: '#509050'}}>
                <TxBottom tot={state.tot} need={needToPaySum} isBadTot={state.badTot}/>
            </div>


            {   overflow > 0 ? (
                <LayHoriz2  cols='1fr 160px' padding='6px' gap='10px'  sx={{height: undefined}}>
                <DaNumericField  disabled  value={overflow} label='СДАЧА' />
                <ComboMoneyKind disabled={overflow < 1}  name='change_dest'  label='КУДА СДАЧУ ?' value={sta.change_dest}   onChange={cb_chg} />
                </LayHoriz2>
            )
            : <LaySpace />

            }

            <div style={{fontSize: '12px', color: '#509050', fontWeight: 800, padding: '2px', position: 'absolute', top: 0, right: '2px'}}>
                ФОРМА ОПЛАТЫ
            </div>


            { disabled ? (<div style={{background: '#FFFFFF80', width: '100%', height: '100%', position: 'absolute', top:0, left:0}}></div>) : null }

            <OverlayLoading active={sta.busy} />



        </div>
    );
}

/*



*/
//---------------------------

const gSX1 = {position: 'relative', border: '2px solid #00600020',
                background: '#F6FFF4', padding: '10px'};


function TxBottom( {tot, need, isBadTot} )
{
    if (!need) return null;

    return (

        <div style={{display: 'flex', gap: '10px'}}>
            <span style={{ color: isBadTot?'#FF6060':undefined}}>Итого: {tot}</span>
            <span>К оплате: {need}</span>
        </div>
    );

}



function TxAvail( {v} )
{
    if (!v || v < 0) return null;
    else
    {
        v = 'Доступно: ' + v;
    }

    return ( <div style={{display: 'flex', alignItems: 'center', width: '100%', height: '100%',
                        fontWeight: 800, fontSize: '12px', color: '#509050'}}>{v}</div> );
}

