 
import React from 'react';


import * as HLP from '../helpers.js';
import * as DEFS from '../defs.js';

import { GetBackendBaseURL,  NuPost2, NuPostBin } from '../api.js';

import {  ShowMsg, ShowErr  } from '../v3/toast.js';

import {   CommonReducer } from '../misc_glob.js';

import { WinCustPicEditor } from './cust_pic_editor.js';




const gST88 = {
  
    pop:    0,
    path:   '',
    busy:   false,
};


export function usePicEditorTriggetState()
{
    const [sta, dispatch] = React.useReducer( CommonReducer, gST88 );


    return {

        sta: sta,

        disp: dispatch,
    };
}

// инконка-кнопка фотика - нажатие -> редактор фотки
// + uploader
export function PicEditorTrigger( {rid, ctx, onOk, ctl} )
{
    
    const sta      = ctl.sta;
    const dispatch = ctl.disp;
    
    const ref = React.useRef( null );
    
    function cb_clk()
    {
        ref.current.click();
    }
    
    async function cb_chg(e)
    {
        //console.log('CHG.. ', e);

        if (e.target.files.length < 1) return;

        const f0 = e.target.files[0];
        
        //console.log('FILE ', f0);
        
        const AB = await f0.arrayBuffer();

        
        function __ok(tt)
        {
            //console.log('OK ! ', tt);
            ShowMsg('КРОП ФОТО');
            dispatch( {type: 'vals', dct: {busy: false, pop:1, path:tt.path + '?' + HLP.Now()} } );
        }
        
        function __fail(tt)
        {
            console.log('SHIT ! ', tt);
            dispatch( {type: 'vals', dct: {busy: false} } );
            ShowErr( tt );
        }

        dispatch( {type: 'vals', dct: {busy: true} } );
    
        NuPostBin( 4124, { rid: rid, ctx: ctx, fd: AB}, __ok, __fail );
        
    }
    
    function cb_crop(e)
    {
        //console.log('MY CROP.. ', e);
        dispatch( {type: 'vals', dct: {pop:0, path: ''} } );

        function __ok(qq)
        {
            dispatch( {type: 'vals', dct: {busy: false} } );
            onOk( );
        }

        function __fail(qq)
        {
            dispatch( {type: 'vals', dct: {busy: false} } );
            ShowErr( qq.m );
        }

        dispatch( {type: 'vals', dct: {busy: true} } );
        
        NuPost2( 4125, {crop: e, ctx: ctx, cu_id: rid}, __ok, __fail  );
    }
    
    function cb_canc()
    {
        dispatch( {type: 'vals', dct: {busy: false, pop:0} } );
    }
    
    return (
                <>
                    <div className="zz_photo_white" onClick={cb_clk} />
    

                    <input ref={ref} hidden
                                style={{opacity: 0, position: 'absolute', left:0, top: 0}}
                                type="file" name="myfile" accept="image/png, image/jpeg" onChange={cb_chg} />
                    
                    <WinCustPicEditor visible={sta.pop === 1} src={GetBackendBaseURL() + sta.path }
                            onOk={cb_crop}  onClose={cb_canc}/>
                    
                    </>
    );
}

