import * as React from 'react';

import { ViCustSources } from './settings/vi_cust_src.js';


export function TabBossCustSrc()
{
    return (<div style={{width: '100%', height: '100%', padding: '20px'}}>
                <ViCustSources />
            </div>);
}
