import React from 'react';

import * as DEFS from '../defs.js';
import * as HLP from '../helpers.js';

import { useApiQuery, useApiQuery2 } from '../api_stuff.js';

import { AbonFeatIcon } from './abon_feat_icon.js';
//---------------------------------------------



function MyJuiceAbonImp( {orig, prod_ids, prod_names} )
{
    if (prod_ids === null)
    {
        if (orig === DEFS.ABON_ORIGIN_TRANSFER) return 'ПЕРЕНОС ТРЕНИРОВОК';
        
        return '---';
    }
    
    const dct = {};
    
    const ar = HLP.CsvSplit( prod_ids );
    
    const ar_nam = prod_names.split(';;;');

    let uniq = 0;
    
    for (let x = 0; x < ar.length; x ++ )
    {
        const ID = ar[x];
        if (dct[ID] === undefined)
        {
            uniq ++;
            dct[ID] = { id: ID, n: ar_nam[x], c: 1 };
        }
        else dct[ID].c ++;
    }
    
    let p_ar = [];
    
    let flg = false;
    let cmcm;

    for (let k in dct)
    {

        if (!flg && uniq > 1)
        {
            cmcm = ', ';
            flg = true;
        }
        else cmcm = null;

        let e = dct[k];
        
        let p_cnt = null;
        
        if (e.c > 1)
        {
            p_cnt = ' x'+e.c;
        }
        
        p_ar.push( <span style={gSSS1} key={e.id}>{e.n}{p_cnt}{cmcm}</span> );
    }
        
    return <>{p_ar}</>;
}

const gSSS1 = {whiteSpace: 'pre'};

const MyJuiceAbon = React.memo( MyJuiceAbonImp );

//---------------------------


const gSX99 = {display: 'flex', alignItems: 'center', color: 'var(--fl-clr-primary)'};



export function ShortName( {ctx, id, show_cust, show_club, clubId} )
{
	const { isLoading, isError, data, error } = useApiQuery( 1054, {id:id,ctx:ctx,club:clubId}, {staleTime: 20000});
	
	if (isLoading || !data) return '???';
	
    
    
    const row = data.row;
    
    if (row === null) return (<div className="zz_red">УДАЛЕНО {id}</div>);
    
    
    if (ctx === DEFS.SHORT_NAME_GOOD_SELL)
    {
        const cu_name = (show_cust && row.cu_name) ? (' (' + row.cu_name + ')') : null;
        
        return <span style={{color: '#401070'}}>{row.prg_name} {cu_name}</span>
    }
    else if (ctx === DEFS.SHORT_NAME_ABON)
    {
        const cu_name = (show_cust && row.cu_name) ? (' (' + row.cu_name + ')') : null;
        const c_name = (show_club && row.v_club_name) ? (' (' + row.v_club_name + ')') : null;
        
        const MM = row.pay_disabled ? <span style={{paddingLeft: '4px', color: '#FF4040'}}>(ЗАБЛОЧЕНО)</span> : null;

        const numUsedF = row.v_used_feats;
        const numTotF = row.pay_cached_num_feats;

        const XX = numTotF > 0 ? <AbonFeatIcon numUsed={numUsedF} numTot={numTotF} /> : null;

        return <span style={gSX99}><MyJuiceAbon orig={row.pay_origin} prod_ids={row.v_prod_ids} prod_names={row.v_prod_names} />{cu_name}{c_name}{XX}{MM}</span>
    }
    else if (ctx === DEFS.SHORT_NAME_PROD)
    {
        return <span>{row.pr_name}</span>;
    }
    else if (ctx === DEFS.SHORT_NAME_BALANCE_OP_OWNER)
    {
        return <span>{row.cu_name}</span>;
    }
    else if (ctx === DEFS.SHORT_NAME_CLUB)
    {
        return <span>{row.c_vis_name}</span>;
    }
    else if (ctx === DEFS.SHORT_NAME_CUST)
    {
        return <span>{row.cu_name}</span>
    }

    
    return 'UNK-UNK';
}







export function ShortName2( {ctx, id, show_cust, show_club, clubId} )
{
	const { isLoading, isError, data, error } = useApiQuery2( 4121, {id:id,ctx:ctx,club:clubId}, {staleTime: 20000});
	
	if (isLoading || !data) return '???';
	
    
    
    const row = data.row;
    
    if (row === null) return (<div className="zz_red">УДАЛЕНО {id}</div>);
    
    
    if (ctx === DEFS.SHORT_NAME_TRP_MOD)
    {
        return <span>Клиент: {row.cu_name} / абон #{row.trp_payment_id}</span>;
    }
    
    return 'UNK-UNK';
}

