import React from 'react';


import { NuPost } from '../api.js';

import * as HLP from '../helpers.js';

import * as DT from '../dt.js';




import {   ShowMsg, ShowErr } from '../misc_glob.js';

import { StatsDateRangeSelector, MODE_MONTH } from '../misc/stats_date_range_sel.js';


import Grow from '@mui/material/Grow';


//-------------------------------------------------

// плашка на стартовой странице
function CmStartPageBoardImp( {busy, items, delay, title, sx})
{
	//console.log('CmStartPageBoardImp');
	
    const ar = [];
    
    for (let x = 0; x < items.length; x ++ )
    {
        const e = items[x];
        
        let v = e.v;
        if (v === undefined) v = '???';
        
        ar.push( <div key={'kk'+x} className="st_sub">{e.k}: {v}</div>  );
    }
    
    //console.log('BOARD BUSY: ', p.busy);
    
    return  <Grow in={!busy} appear={!busy} timeout={delay}>
				<div style={{width: sx+'px'}} className="st_conta">
                <div className="st_title">{title}</div>
                {ar}
				</div>
            </Grow>;
}

export const CmStartPageBoard = React.memo( CmStartPageBoardImp );

//-------------






function StatBody( {ss, busy} )
{
	const src_trains = [
	
		{k: 'СОЛО',         		v: ss.v_num_bound_solo_trains },
		{k: 'ПАРНЫЕ',       		v: ss.v_num_bound_pair_trains },
		{k: 'ГРУППА',       		v: ss.v_num_bound_group_trains },
		{k: 'ГРУППА-МЕСЯЦ',       	v: ss.v_num_bound_group_mon_trains },
		{k: 'БЕСПЛАТНЫЕ',   		v: ss.v_num_closed_free_trains },
		{k: 'ОТМЕНЕНА',     		v: ss.v_num_failed_trains },
	
	];
	
	const src_cu = [
	
		{k: 'ЗАПИСАЛОСЬ НА ПРОБНУЮ',       v: ss.v_num_bound_free_trains },
		{k: 'ПЕРВАЯ ПЛАТНАЯ ТРЕНЯ',        v: ss.num_first_payed_trains },
	
	];
        

    

	let tot_money = ss.v_total_abon_money + ss.v_total_goods_money - ss.v_total_return_money;
	if ( isNaN(tot_money) ) tot_money = 0;
    
    let tot_money_no_ret = ss.v_total_abon_money + ss.v_total_goods_money;
    if ( isNaN(tot_money_no_ret) ) tot_money_no_ret = 0;
	

	
	let src_prods = [
	
		{k: 'АБОНЕМЕНТЫ',                       v: ss.v_num_pays },
		{k: 'ТОВАРЫ',                           v: ss.v_num_prods_sells },


		
	
	];
	
	
	const DELAY_M = 30;
        
    // <CmStartPageBoard busy={busy} delay={DELAY_M*5} sx={300} title="ЗАДАЧИ"     items={src_tasks} />
        
	return (	<div style={{ display: 'grid', gridTemplateColumns: '1fr', gridTemplateRows: '1fr 1fr 30px' , height: '100%'}}>	
					<div style={{margin: '20px', display: 'flex', gap: '20px', justifyContent: 'center' }}>
						<CmStartPageBoard busy={busy} delay={DELAY_M*1} sx={300} title="ТРЕНИРОВКИ" items={src_trains} />
						<CmStartPageBoard busy={busy} delay={DELAY_M*2} sx={300} title="КЛИЕНТЫ"    items={src_cu} />
						<CmStartPageBoard busy={busy} delay={DELAY_M*3} sx={300} title="ПРОДАЖИ"    items={src_prods} />
						
					</div>
					
					
					<div style={{height: '100%'}}>
					
					</div>
                </div>);
}



//-------------------------------------------------------

// СТАРТОВАЯ СТРАНИЦА со статой
function TabStart( pp )
{
	const [busy, setBusy] 	= React.useState( false );
	const [juice, setJuice] = React.useState( {} );
    
	const [flt, setFlt] = React.useState( {s: DT.DateStrIso(new Date()), e: DT.DateStrIso(new Date())} );
	
    //console.log('F: ', flt);
    
    const cb_sel_date = React.useCallback( (f) =>
    {
        //console.log('CB SEL DATE ', f);
        
        setFlt( {s: DT.DateStrIso(f.s), e: DT.DateStrIso(f.e)} );
        
    }, [] );

	
	const CLA = busy ? 'zz_busy' : null;
	

	
	function req()
	{
		function __ok(e)
		{
			setBusy( false );
			setJuice( e.row );
		}
		
		function __fail(e)
		{
			setBusy( false );
			ShowErr(e.m);
		}
		
        
        setBusy( true );
        
        NuPost(800, { ts: flt.s,  te: flt.e }, __ok, __fail);
	}
    

    function __can_show()
    {
        return flt.s !== null && flt.e !== null;
    }
    
    
	React.useEffect( () => {
		
		if ( !busy && __can_show() )
		{
			req();
		}
		
	}, [flt.s, flt.e] );
	
	

    let p_body;
    
    if (__can_show())
    {
        p_body = <StatBody ss={juice} busy={busy} />;
    }
    else p_body = <div></div>;
	
	
	return (<div className={CLA} style={{ display: 'grid', gridTemplateColumns: '1fr', gridTemplateRows: 'max-content 1fr' , height: '100%'}}>
	
				<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '20px', height: '60px', borderBottom: '1px solid #30407020'}}>
                    <StatsDateRangeSelector onChg={cb_sel_date}  />
                </div>
	
				{p_body}
	
			</div>);
}



export default TabStart;