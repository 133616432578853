import React from 'react';
import { CmComColCellBalanceAct, CmComColCellDateTime  } from '../cm_table_cell_com.js';

import * as DEFS from '../defs.js';
import * as HLP from '../helpers.js';

import { NuPost } from '../api.js';


import { CommonReducer, ShowMsg, ShowErr, FmtPayOpBind } from '../misc_glob.js';

import { OverlayLoading } from '../misc/ovr_loading.js';

import { BottomButtonsPanel } from '../cust_card/bottom_pan.js';

import { WinHelpConta, WinHelpElem } from '../misc/win_help.js';

import * as DT from '../dt.js';
import { LayVert2, LayHoriz2, LaySpace } from '../misc/layout.js';

import { DaButton } from '../misc/but.js';
import { DaNumericField } from '../misc/numeric.js';
import { Win2 } from '../misc/win2.js';
import { DaTextField } from '../misc/text_field.js';

import { STO_InvaCustBalance } from '../store.js';

import AddCardIcon from '@mui/icons-material/AddCard';
import RemoveIcon from '@mui/icons-material/Remove';
import SaveIcon from '@mui/icons-material/Save';



import { ClubCsvList } from './club_list_csv.js';

//-------------------------------------

function MyBody( {initVal, onOk} )
{
    const [csv, setCsv] = React.useState( initVal );

    const cb_chg = React.useCallback( (k,v) => {
        setCsv(v);
    }, [csv] );

    function cb_ok()
    {
        onOk(csv);
    }

    return (

        <LayVert2 rows='1fr max-content' >

            <ClubCsvList valCsv={csv} onChange={cb_chg} />

            <BottomButtonsPanel>
                <DaButton title='СОХРАНИТЬ' onClick={cb_ok} icon={SaveIcon} />
            </BottomButtonsPanel>

        </LayVert2>
    );
}



const gHelp1 = (<WinHelpConta>
    <WinHelpElem text='К каким клубам привязывать. Если галочек не стоит - ВСЕ КЛУБЫ.'/>
</WinHelpConta>);


export function WinClubCsv( {visible, title, initVal, onOk, onClose} )
{
    return (
    <Win2 visible={visible} title={title} help={gHelp1} onClose={onClose} width={500} height={300}  >
        <MyBody onOk={onOk} initVal={initVal} />
    </Win2>
    );
}

// 