import React from 'react';



function BottomButtonsPanelImp( {children, busy} )
{
	return <div className="zz_bottom_pan">
			{ busy ? null : children}
			</div>;
}



export const BottomButtonsPanel = React.memo( BottomButtonsPanelImp );
