import React from 'react';
import './oi.css';



function DaOutlinedShitImp( {isFocused, labelVal, labelIsFloat, borderIsFoc, juice, error, width, height} )
{
    let p_lab;
    
    if (labelVal !== undefined)
    {
        let cla = labelIsFloat ? 'float' : 'inside';
        
        if (borderIsFoc) cla += ' act';

        if (error) cla +=' er';
        
        p_lab = (   <>
                    <div className="hider_base">
                        <div className={"hider " + cla}></div>
                        <span className={"fake_lab " + cla}>{labelVal}</span>
                    </div>
                    
                    <div className={"real_lab " +cla }>{labelVal}</div>
                    </>
        
        );
    }
    else
    {
        p_lab = null;
    }
    
    let cla = 'oi_base';
    
    if (borderIsFoc) cla += ' focused';
    if (error) cla += ' error';

    let sx;

    if (width || height)
    {
        sx = {  };
        if (width) sx.width = width;
        if (height) sx.height = height;
    }
    else
    {
        sx = undefined;
    }
    
    return (    
            
                <div className={cla} style={sx}>
                    {juice}
                    {p_lab}
                </div>
                
    
    );
}



export const DaOutlinedShit = React.memo( DaOutlinedShitImp );
