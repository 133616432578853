import React from 'react';


import * as COM_DEFS from '../com_defs.mjs';
import { LayVert2, LayHoriz2, LaySpace } from '../misc/layout.js';

import { DaButton } from '../misc/but.js';

import { UserPicMini } from '../desktop/vi_user_edit_pic.js';

import { ClubListCombo } from '../desktop/club_list_combo.js';

import { WinPopUserEdit2 } from '../desktop/win_user_edit2.js'

import { Win2Confirm }  from '../misc/win2_confirm.js';

import {  CommonReducer, ShowMsg, ShowErr } from '../misc_glob.js';

import { Grid3 } from '../grid3.js';



import { useGlobalShit } from '../v3/hk_global_shit.js';


import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CheckIcon from '@mui/icons-material/Check';


import { NuPost2 } from '../api.js';


//------------------------------------


function MyDel(pp)
{
	if (pp.value < 1) return <CheckIcon sx={{color: '#30A030'}}/>;
	return '';
}


function MyColHasAccess(pp)
{
	if (pp.value > 0) return <CheckIcon sx={{color: '#30A030'}}/>;
	return '';
}

function MyCellRole(pp)
{
    const ro = pp.row.u_role;
    
	if ( COM_DEFS.IsTrainerRole(ro) )
	{
		return COM_DEFS.gTrainerSubRoleVisFull[pp.row.u_sub_role];
	}
	
    return COM_DEFS.USER_ROLES[pp.value];
}

function MyCellRGB(pp)
{
    return <span style={{display:'inline-block', width: '30px', height: '22px', background: '#'+pp.value}}></span>;
}


/*
function MyCellEvents(pp)
{
	if ( !DEFS.IsTrainerRole(pp.row.u_role) ) return <span className="faint">---</span>;
	return pp.value;
}
*/


function MyName(pp)
{
    const r = pp.row;

    let q;
	
	if ( r.u_role === COM_DEFS.USER_ROLE_TRAINER && r.u_sub_role === COM_DEFS.TRAINER_SUB_ROLE_DELETED)
	{
		q = <div className="faint">{r.u_name}</div>;
	}
    else q = r.u_name;

    return q;
}

function MyColPic(pp)
{
    return <UserPicMini  rid={pp.row.u_id} />;
}

function MyColClubs(pp)
{
    const r = pp.row;
    if (r.v_list === null) return (<div className="faint">---</div>);
    
    const ar = r.v_list.split('|||');
    
    let qq = [];
    
    for (let x = 0; x < ar.length; x ++ )
    {
        qq.push( <span style={{marginRight: '4px', background: '#E0E0FF', paddingLeft: '4px', paddingRight: '4px', borderRadius: '2px'}} key={x}>{ar[x]}</span> );
    }
    
    return qq;
}

//-------------------------------------------------






const gSta99 = {
  
    selected:   [],
    pop:        0,

    dct_pop_conf:   {},
    dct_pop_usr:    {},
    
    flt_club:       -1,
    
    ser:            0,
    
};

// РАЗДЕЛ - персонал.  (мастер)
export function TabMasterUsers( )
{
    return <TabUsersInternal isMaster={true} />;
}

// РАЗДЕЛ - персонал.  (для клуба)
export function TabUsers( )
{
    return <TabUsersInternal isMaster={false} />;
}



function TabUsersInternal( {isMaster} )
{
    console.log('TAB USER INTERNAL .. mast: ', isMaster);
    
    const [sta, dispatch] = React.useReducer( CommonReducer, gSta99 );
    
    const sss = useGlobalShit();
    
	const cb_chg_sel = React.useCallback( (nu) => {
		dispatch( {type:'val', k:'selected', v:nu} );
	}, [sta.selected] );
	
	const cb_chg = React.useCallback( (k,v) => {
		dispatch( {type:'val', k:k, v:v} );
	}, [] );
	
    


    
    
	const cb_hide_pop = () => {
        dispatch( {type:'val', k:'pop', v:0} );
	};
    
    
	const cb_act_del = () => {
		
        const ID = sta.selected[0];
        
        const __confirm = () => {
            
            const __ok = (e) => {
                
                ShowMsg('УДАЛЁН !');
                
                dispatch( {type:'val', k:'ser', v:sta.ser+1} );
                dispatch( {type:'val', k:'pop', v:0} );
                dispatch( {type:'val', k:'selected', v:[]} );
            };
            
            const __fail = (e) => {
                
                ShowErr(e.m);
            };
            
            NuPost2( 730, {u_id: ID}, __ok, __fail );
        };
        
        const nnn = {title:'ТОЧНО УДАЛИТЬ ?',  onOk:__confirm, onClose:cb_hide_pop };
        
        dispatch( {type:'val', k:'pop', v:2} );
        dispatch( {type:'val', k:'dct_pop_conf', v:nnn} );

	};
    
    
    
	const cb_act_new = () => {
		
		const on_ok = () => {
			
			ShowMsg('ДОБАВЛЕН !');
			
            dispatch( {type:'val', k:'ser', v:sta.ser+1} );
            dispatch( {type:'val', k:'pop', v:0} );
            dispatch( {type:'val', k:'selected', v:[]} );
		};
		
        const nnn = {rid:-1,  onOk:on_ok,  onClose:cb_hide_pop }
        
        dispatch( {type:'val', k:'pop', v:1} );
        dispatch( {type:'val', k:'dct_pop_usr', v:nnn} );
	};
    
    
    
    
	
	const cb_row_dbl = (kk, row) => {
		
        if (! sss.userHasAbil(302) ) return;

        
		const on_ok = (dontClose) => {
			
			ShowMsg('СОХРАНЕНО');
            dispatch( {type:'val', k:'ser', v:sta.ser+1} );

            if (dontClose !== true)
            {
                 dispatch( {type:'val', k:'pop', v:0} );
            }
		};

        const nnn = {rid:kk,  onOk:on_ok,  onClose:cb_hide_pop };

        dispatch( {type:'val', k:'pop', v:1} );
        dispatch( {type:'val', k:'dct_pop_usr', v:nnn} );
	};
	
    
    

	let prt_top;
	
    {
        const DIS_ADD = (!sss.userHasAbil( 300 ));
        const DIS_DEL = (!sss.userHasAbil( 301 )) || sta.selected.length < 1;

		prt_top = ( <LayHoriz2 cols='max-content max-content 30px 290px 1fr' gap='10px' sx={{alignItems: 'center'}}>
   
                        <DaButton  icon={AddIcon}    disabled={DIS_ADD}		    onClick={cb_act_new} title='НОВЫЙ'/>
                        <DaButton  icon={RemoveIcon} disabled={DIS_DEL} 		onClick={cb_act_del} title='УДАЛИТЬ' />
                   
                        <LaySpace />

                        { isMaster ? <ClubListCombo name='flt_club' label='СОТРУДНИК В КЛУБЕ'  onChange={cb_chg} value={sta.flt_club} />
                                     : <div style={{fontFamily: 'body'}}>Админы и тренера клуба.</div>
                        }

                        <LaySpace />
				</LayHoriz2>);
    }
    
    
    
    const FLT = React.useMemo( () =>
    {
        return {club: sta.flt_club};
        
    }, [sta.ser, sta.flt_club] );
    
    
    
    

    
    
    const my_cols = React.useMemo( () => {
        
        const r = [
            { sort: true,  align: 'center', fld: 'u_id',            name: 'ID', width: 80},
            { sort: true,  align: 'center', fld: 'u_deleted',       name: 'Актив ?', width: 90, renderCell: MyDel},
            { sort: true,  align: 'left',   fld: 'u_name',          name: 'Имя', width: 250, renderCell: MyName },
            { sort: true,  align: 'left',   fld: 'u_phone',         name: 'Телефон / Логин', width: 180 },
            { sort: true,  align: 'left',   fld: 'u_role',          name: 'Роль', width: 200, renderCell: MyCellRole },
            { sort: false, align: 'center', fld: 'u_disp_rgb',      name: 'Цвет', width: 80, renderCell: MyCellRGB },
            { sort: false, align: 'center', fld: 'v_pic',           name: 'PIC',  width: 80, renderCell: MyColPic },
            
            { sort: true,  align: 'center', fld: 'v_has_access',    name: 'Доступ ?', width: 110, renderCell: MyColHasAccess},
        
        
        ];
        
        if (isMaster)
        {
            //console.log('is master.. add CLUBS');
            r.push( { sort: false, align: 'left', fld: 'v_list', name: 'Клубы', width: 370, renderCell: MyColClubs} );
            
        }

        return r;
        
    }, [isMaster] );
    
    
    
    const GRID_CODE = isMaster ? 'mst-users' : 'cl_users';
    
	return ( <>
        
					<LayVert2 rows='max-content 1fr'  padding='20px' gap='16px' >
				
						{prt_top}
				
                        <Grid3 	singleSel isNuApi  sort_field='u_id' sort_dir='asc'  flt={FLT}  
                                        cols={my_cols} apiCmd={isMaster?786:785} uniq='u_id' 
											onDblClick={cb_row_dbl}  selected={sta.selected} 
                                            onChangeSel={cb_chg_sel} code={GRID_CODE}  />
						
					</LayVert2>

            
            
					<WinPopUserEdit2     visible={sta.pop===1}   {...sta.dct_pop_usr}  isMaster={isMaster} /> 
                    
                    <Win2Confirm        visible={sta.pop===2}   {...sta.dct_pop_conf} />
				</>
                
                );
}


