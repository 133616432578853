import React from 'react';

import * as HLP from '../helpers.js';

import * as DEFS from '../defs.js';


import { DaButton } from '../misc/but.js';

import { NuPost } from '../api.js';

import { DaVertScrollFrame, DaVertScrollJuice } from '../misc/vscr.js';

import { CommonReducer, ShowMsg,  ShowErr} from '../misc_glob.js';

import {  CmAmountBar, CmFramedShit  } from '../cm_base.js';

import { LayVert2, LayHoriz2, LaySpace } from '../misc/layout.js';

import { BottomButtonsPanel } from './bottom_pan.js';


import SaveIcon from '@mui/icons-material/Save';

//-------------------------------------


const gSt = {
  
    busy:           false,

    cats:           [],
    all:            [],
    
    vals:           {},
    
    vals_initial:   {},
    
};


export function ViCustomerCardTechs( {rid} )
{
    const [sta, dispatch] = React.useReducer( CommonReducer, gSt );
    
    
    React.useEffect( () => {
        
        function __ok(e)
        {
            dispatch( {type:'val',k:'busy', v:false} );
            
            
            dispatch( {type:'val',k:'cats', v:e.cats} );
            dispatch( {type:'val',k:'all',  v:e.all} );
            
            dispatch( {type:'val',k:'vals',             v:e.techs} );
            dispatch( {type:'val',k:'vals_initial',     v:e.techs} );
            
            console.log('GOT JUICE ', e);
        }
        
        function __fail(e)
        {
            dispatch( {type:'val',k:'busy', v:false} );
            ShowErr(e.m);
        }
        
        dispatch( {type:'val',k:'busy', v:true} );
        
        NuPost( 782, {cu_id:rid}, __ok, __fail );
        
    }, [] );
    
    const cb_revert = () => {
        dispatch( {type:'val',k:'vals', v: {...sta.vals_initial} } );
    };
    
    const cb_save = () => {
        console.log('SAVE ! ', sta.vals);
        
        function __ok(e)
        {
            ShowMsg('СОХРАНЕНО !');
            dispatch( {type:'val',k:'busy', v:false} );
        }
        
        function __fail(e)
        {
            ShowErr(e.m);
            dispatch( {type:'val',k:'busy', v:false} );
        }
        
        dispatch( {type:'val',k:'busy', v:true} );
        
        NuPost( 783, {cu_id:rid, vals:sta.vals}, __ok, __fail );
    };
    
    const cb_chg_st = React.useCallback( (v,k) => {
        const nu = {...sta.vals};
        nu[k] = v;
        dispatch( {type:'val',k:'vals', v:nu} );
    }, [sta.vals] );
    
    
    let ar_cats = [];
    
    for (let x = 0; x < sta.cats.length; x ++ )
    {
        const e = sta.cats[x];
        
        let my_rows = []
        
        for (let i = 0; i < sta.all.length; i ++ )
        {
            const te = sta.all[i];
            
            if (te.tech_cat_id !== e.tcat_id) continue;
            
            let my_val = sta.vals[ te.tech_id ];
            if (my_val === undefined) my_val = 0;
            
            my_rows.push( 
                            <div className="zz_tech_board" key={'t'+te.tech_id}>
                                <div style={{padding: '2px', fontSize: '14px', color: '#304070' }}>{te.tech_name}</div>
                                <CmAmountBar    min={1}
                                                max={te.tech_max_val}
                                                val={my_val}
                                                my_key={te.tech_id}
                                                on_change={ cb_chg_st }
                                                />
                            </div> );
        }
        
        ar_cats.push(
                        <div key={'cat'+e.tcat_id} >
        
                        <CmFramedShit title={e.tcat_name} >
                            {my_rows}
                        </CmFramedShit>
                        
                        </div>
        
            );
    }
    
    
    return (
        
        <LayVert2 rows='1fr max-content'>
            
            <DaVertScrollFrame>
                <DaVertScrollJuice sx={gSx11}>
            
                <div style={{display: 'grid', gap: '10px', gridTemplateRows: 'masonry', gridTemplateColumns: 'repeat(auto-fill, minmax(500px, 1fr)'}}>
                {ar_cats}
                </div>
            
                </DaVertScrollJuice>
            </DaVertScrollFrame>
            
            <BottomButtonsPanel>
                <DaButton onClick={cb_revert } icon={SaveIcon} title='ОТКАТИТЬ'  />
                <DaButton onClick={cb_save } icon={SaveIcon} title='СОХРАНИТЬ' />
            </BottomButtonsPanel>
        
        </LayVert2>
    
    );
}

const gSx11 = {padding: '30px'};

//---------------------------------------------

// cust card - techs view
export class ViCustomerCardTechs2 extends React.Component
{
    constructor(props)
    {
        super(props);

        this.RqOk = this.RqOk.bind(this);
        this.RqGot = this.RqGot.bind(this);
        this.RqFail = this.RqFail.bind(this);
        
        this.OnAct = this.OnAct.bind(this);
        this.OnVal = this.OnVal.bind(this);
        
        this.state = { rid: this.props.rid, busy: false, cats: null, all: null, vals: {}, vals_snap: {} };
    }
    
    OnVal(v, my_key)
    {
        let o = HLP.DeepCopy( this.state.vals );
        o[my_key] = v;
        this.setState( {vals: o} );
    }
    
    OnAct(m)
    {
		let s = this.state;
		
        if (m == 0)
        {
            // revert
            
            this.setState( {vals: HLP.DeepCopy( s.vals_snap ) } );
        }
        else if (m == 1)
        {
            // save
            
            NuPost(783, {cu_id: s.rid, vals: s.vals}, this.RqOk, this.RqFail);
        
            this.setState( { busy: true } );
        }
    }
    
    RqOk(e)
    {
        this.setState( { busy: false, vals_snap: HLP.DeepCopy(this.state.vals) } );
        
        ShowMsg('СОХРАНЕНО !');
    }
    
    RqGot(e)
    {
        this.setState( { busy: false,
                        cats: e.cats,
                        all: e.all,
                        vals: e.techs,
                        vals_snap: HLP.DeepCopy(e.techs) } );
    }
    
    RqFail(e)
    {
        this.setState( { busy: false } );
    }
    
    componentDidMount()
    {
        //console.log('TECHS CARD MOUNT !');
        
        NuPost(782, {cu_id: this.state.rid}, this.RqGot, this.RqFail);
        
        this.setState( { busy: true } );
    }
    
    
    render()
    {
        let s = this.state;
        
        if (s.cats === null) return <div></div>;
        
        let ar_cats = [];
        
        
        for (let x = 0; x < s.cats.length; x ++ )
        {
            let e = s.cats[x];
            
            let my_rows = []
            
            for (let i = 0; i < s.all.length; i ++ )
            {
                let te = s.all[i];
                
                if (te.tech_cat_id != e.tcat_id) continue;
                
                let my_val = s.vals[ te.tech_id ];
                if (my_val == undefined) my_val = 0;
                
                my_rows.push( 
                                <div className="zz_tech_board" key={'t'+te.tech_id}>
                                    <div style={{padding: '2px', fontSize: '14px', color: '#304070' }}>{te.tech_name}</div>
                                    <CmAmountBar    min={1}
                                                    max={te.tech_max_val}
                                                    val={my_val}
                                                    my_key={te.tech_id}
                                                    on_change={ this.OnVal }
                                                    />
                                </div> );
            }
            
            ar_cats.push(
                            <div key={'cat'+e.tcat_id}  style={{width: '32%'}}>
            
                            <CmFramedShit title={e.tcat_name} >
                                {my_rows}
                            </CmFramedShit>
                            
                            </div>
            
                );
        }
        
        let cla = s.busy ? 'zz_busy' : null;
        
        return  <div className={cla}  style={{height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'space-between'}}>
        
                    <div style={{zIndex: 1, height: '400px', overflowY: 'scroll', justifyContent: 'center', display: 'flex', flexWrap: 'wrap', gap: '4px', flexGrow: 1}}>
                
                        {ar_cats}
                
                    </div>
                    
                    
					<BottomButtonsPanel>
						<DaButton onClick={  (e) => this.OnAct(0)  } icon={SaveIcon} title='ОТКАТИТЬ'  />
						<DaButton onClick={  (e) => this.OnAct(1)  } icon={SaveIcon} title='СОХРАНИТЬ' />
					</BottomButtonsPanel>
					
        
                </div>;
    }
    
    
}

