import React from 'react';
import { CmComColCellDateTime, CmComColCellDateTimeHM, CmComFaded, CmComBonusAct, CmComBonusAmount  } from '../cm_table_cell_com.js';

import * as DEFS from '../defs.js';
import * as COM_DEFS from '../com_defs.mjs';
import * as HLP from '../helpers.js';

import { CommonReducer, ShowMsg, ShowErr } from '../misc_glob.js';

import { DaButton } from '../misc/but.js';

import { NuPost2 } from '../api.js';

import { Win2Confirm } from '../misc/win2_confirm.js';

import { CmHelpIcon } from '../misc/help_icon.js';

import { Grid3 } from '../grid3.js';

import * as DT from '../dt.js';
import { LayVert2, LayHoriz2, LaySpace } from '../misc/layout.js';

import { CustBonusState } from '../tier2/cust_bonus_state.js';

import { ShortName } from '../desktop/short_name.js';

import { WinCustBonusDeposit } from '../desktop/win_cust_bonus_deposit.js';

import AddCardIcon from '@mui/icons-material/AddCard';
import CloseIcon from '@mui/icons-material/Close';

//------------------------



const gSty11    = {paddingRight: '6px', whiteSpace: 'pre'};

const gST8      = {color: '#505050', fontSize: '14px'};

function MyBindCol(pp)
{
    const R     = pp.row;

    // show comment for DEPOSIT
    if (R.cbh2_action === DEFS.BONUS_ACTION_DEPOSIT)
    {
        return <span style={gST8}>{R.cbh2_comment}</span>;
    }

    const ID    = R.cbh2_bind_id;
    const CTX   = R.cbh2_bind_ctx;

    if (CTX === COM_DEFS.PAY_OPS_CTX_ABON)
    {
        return <span style={gSty11}>Покупка абона #{ID}: <ShortName ctx={DEFS.SHORT_NAME_ABON} id={ID}  clubId={R.cbh2_bind_club} show_club /></span>;
    }
    else if (CTX === COM_DEFS.PAY_OPS_CTX_PR_GOOD)
    {
        return <span style={gSty11}>Покупка товара #{ID}: <ShortName ctx={DEFS.SHORT_NAME_GOOD_SELL} id={ID}  clubId={R.cbh2_bind_club} show_club /></span>;
    }
    else if (CTX === COM_DEFS.PAY_OPS_CTX_MOD_TRP)
    {
        return <span style={gSty11}>Мод трени / доплата #{ID}</span>;
    }
    else if (CTX === COM_DEFS.PAY_OPS_CTX_RET_TRP)
    {
        return <span style={gSty11}>Возврат трень #{ID}</span>;
    }

    return '???';
}


const gColz = [
  { sort: true,  align: 'center',   fld: 'cbh2_id',              name: 'ID', width: 80, renderCell: CmComFaded},
  { sort: true,  align: 'center', 	fld: 'cbh2_dt',              name: 'Создано', width: 140, renderCell: CmComColCellDateTimeHM },
  { sort: true,  align: 'center', 	fld: 'cbh2_action',          name: 'Действие', width: 160, renderCell: CmComBonusAct },
  { sort: true,  align: 'center', 	fld: 'cbh2_amount',          name: 'N', width: 110, renderCell: CmComBonusAmount },
  { sort:false,  align: 'left',     fld: 'cbh2_bind_ctx',        name: 'Привязка / Коммент', width: 220, renderCell: MyBindCol},
];




//-----------------------------------------------------------


const gMySta = {

    busy:       false,
    pop:        0,
    selected:   [],
    ser:        0,

    pop_dct_depo: {},
    pop_dct_conf: {},

    selRowAct:    -1,
};


export function ViBonuses( {cu_id} )
{
    const [sta, dispatch] = React.useReducer( CommonReducer, gMySta );

    function cb_btn(k)
    {
        if (k === 'new')
        {
            function __conf()
            {
                dispatch( {type: 'vals', dct: {pop:0, selected: [], selRowAct: -1, ser:sta.ser+1 }} );
            }

            const DDD = {rid:-1, onOk: __conf};

            dispatch( {type: 'vals', dct: {pop:1, pop_dct_depo: DDD} } );
        }
        else if (k === 'del')
        {
            function __conf()
            {
                function __ok(e)
                {
                    dispatch( {type: 'vals', dct: {pop: 0, busy: false, selected: [], selRowAct: -1, ser:sta.ser+1} } );
                    ShowMsg('УСПЕХ !');
                }

                function __fail(e)
                {
                    dispatch( {type: 'vals', dct: {busy: false} } );
                    ShowErr( e.m );
                }

                dispatch( {type: 'vals', dct: {busy: true} } );

                NuPost2( 4171, {rid: sta.selected[0]}, __ok, __fail );
            }

            const DDD = {rid:-1, onOk: __conf};

            dispatch( {type: 'vals', dct: {pop:2, pop_dct_conf: DDD} } );
        }
    }

    // double click - edit
    function cb_dbl(e1, rr)
    {
        if (rr.cbh2_action !== DEFS.BONUS_ACTION_DEPOSIT)
        {
            ShowErr('НЕЛЬЗЯ РЕДАКТИРОВАТЬ ЭТУ ЗАПИСЬ !');
            return;
        }

        function __conf()
        {
            dispatch( {type: 'vals', dct: {pop:0, selected: [], selRowAct: -1, ser:sta.ser+1 }} );
        }

        const DDD = {rid:rr.cbh2_id, onOk: __conf};

        dispatch( {type: 'vals', dct: {pop:1, pop_dct_depo: DDD} } );
    }

    function cb_clo()
    {
        dispatch( {type: 'vals', dct: {pop:0} } );
    }

    function cb_chg_sel(nu,rr)
    {
        const ACT = rr.cbh2_action;
        dispatch( {type: 'vals', dct: {selected: nu, selRowAct: ACT} } );
    }

    const DIS_DEL = sta.selected.length < 1 || sta.selRowAct !== DEFS.BONUS_ACTION_DEPOSIT;
    
    const FLT = React.useMemo( () => { return {cu_id: cu_id} }, [cu_id, sta.ser] );

    return (
        <>
        <LayHoriz2 cols='280px 1fr' padding='10px' gap='10px'>
    
            <div>
                <CustBonusState cuId={cu_id} ser={sta.ser} />
            </div>

            <LayVert2 rows='max-content 1fr' gap='10px' >

                <LayHoriz2 cols='max-content max-content 1fr max-content' gap='10px'>
                    <DaButton name='new' title='ДЕПОЗИТ' onClick={cb_btn} icon={AddCardIcon} />
                    <DaButton name='del' title='УДАЛИТЬ' onClick={cb_btn} icon={CloseIcon} disabled={DIS_DEL} />
                    <LaySpace />
                    <CmHelpIcon juice='Депозит - накинуть бонусы клиенту. Удалять/редактировать можно только записи типа "ДЕПОЗИТ".' />
                </LayHoriz2>
            
    
                <Grid3 uniq='cbh2_id'  isNuApi  apiCmd={1103}  sort_field='cbh2_dt' sort_dir='desc'
                             cols={gColz}  flt={FLT}    onDblClick={cb_dbl}
                             singleSel selected={sta.selected}  onChangeSel={cb_chg_sel} 
                             code='cc_bonus' />

            </LayVert2>
                        
        </LayHoriz2>
        

        <WinCustBonusDeposit visible={sta.pop === 1}  {...sta.pop_dct_depo} cuId={cu_id} onClose={cb_clo} />

        <Win2Confirm visible={sta.pop === 2} {...sta.pop_dct_conf} busy={sta.busy} title='УДАЛИТЬ ЗАПИСЬ ?' onClose={cb_clo}   />
        
        </>
    
    );
}

