 
import React from 'react';

import * as DEFS from '../defs.js';
import * as HLP from '../helpers.js';

import { NuPost2 } from '../api.js';

import { CommonReducer, ShowErr, ShowMsg } from '../misc_glob.js';

import * as DT from '../dt.js';

import {DaVertScrollFrame, DaVertScrollJuice } from '../misc/vscr.js';

import { CmCheckBox } from '../cm_mui.js';

import {DaCombo} from '../misc/combo.js';



import { BottomButtonsPanel } from '../cust_card/bottom_pan.js';


import { DaButton } from '../misc/but.js';

import { OverlayLoading } from '../misc/ovr_loading.js';


import CheckIcon from '@mui/icons-material/Check';


import { DaTextField } from '../misc/text_field.js';

import { LayVert2, LayHoriz2, LaySpace } from '../misc/layout.js';

import {DaToolTip} from '../misc/tooltip.js';
import { DaNumericField } from '../misc/numeric.js';


//-------------------------------------------


const gSt11 = {

    busy: false,
    txt: '',
    sc: '',
    cuId: 614,
};


// 

export function TabBroadcast( )
{
    const [sta, dispatch] = React.useReducer( CommonReducer, gSt11 );
    

    
    const cb_chg = React.useCallback( (k,v) => {
        dispatch( {type: 'val', k, v} );
    }, [] );


    
    function cb_submit()
    {
        console.log('!');

        function __ok(e)
        {
            ShowMsg('УСПЕХ. СООБЩЕНИЙ В ОЧЕРЕДИ: ' + e.numSucc);
            dispatch( {type: 'vals', dct: {busy: false} } );
        }

        function __fail(e)
        {
            ShowErr( e.m );
            dispatch( {type: 'vals', dct: {busy: false} } );
        }

        dispatch( {type: 'vals', dct: {busy: true} } );

        NuPost2( 4221, {txt: sta.txt, cuId: sta.cuId, sc: sta.sc}, __ok, __fail );
    }

    
    const ERR_TX = sta.txt.trim() === '';

    const DIS_SEND = ERR_TX;

    return (

            <LayHoriz2 cols='1fr 600px 1fr' sx={{position: 'relative'}}>

                <LaySpace />

                <LayVert2 rows='max-content max-content max-content 1fr max-content' padding='10px' gap='10px' >
                    <div>РАССЫЛКА В ТГ БОТ (ТЕСТ !)</div>
              
                    <DaNumericField name='cuId' value={sta.cuId} label='КЛИЕНТУ (-1 : ВСЕМ)' onChange={cb_chg} min={-1} />

                    <DaTextField name='sc' value={sta.sc} label='SHORT-CODE' onChange={cb_chg} />

                    <DaTextField error={ERR_TX} name='txt' value={sta.txt} label='ТЕКСТ' onChange={cb_chg} multiline />
            
                    <LayHoriz2 cols='1fr max-content'>
                        <LaySpace />
                        <DaButton title='ОТПРАВИТЬ !' onClick={cb_submit} disabled={DIS_SEND} />
                    </LayHoriz2>
    
                </LayVert2>

                <LaySpace />

                <OverlayLoading active={sta.busy} />

            </LayHoriz2>
            
        );
}
