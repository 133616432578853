import React from 'react';


import * as DEFS  from '../defs.js';

import { LayHoriz2, LayVert2, LaySpace } from '../misc/layout.js'

import { DaNumericField } from '../misc/numeric.js';

import { NuPost2 } from '../api.js';

import { ShortName } from './short_name.js';

import { ShowMsg, ShowErr, CommonReducer } from '../misc_glob.js';


import { OverlayLoading } from '../misc/ovr_loading.js';

import { DaButton } from '../misc/but.js';

import { Win2 } from '../misc/win2.js';
import { BottomButtonsPanel } from '../cust_card/bottom_pan.js';

import { DaCombo } from '../misc/combo.js';

import CheckIcon from '@mui/icons-material/Check';

import { useGlobalShit } from '../v3/hk_global_shit.js';

//------------


const gMySta = {

    amount:     0,

    busy:       false,

};

//-----------------------------------------

function MyBody( {onOk, rid} )
{
    const [sta, dispatch] = React.useReducer( CommonReducer, gMySta );



    const sss = useGlobalShit();
    
    //console.log('SSS: ', sss);


    React.useEffect( () => {

        function __ok(e)
        {
            dispatch( {type: 'vals', dct:{busy: false, amount: e.amo}  } );
        }

        function __fail(e)
        {
            //dispatch( {type: 'val', k:'busy', v:false} );
            ShowErr( e.m );
        }

        dispatch( {type: 'val', k:'busy', v:true} );

        NuPost2( 4210, {rid:rid}, __ok, __fail );

    }, [] );


    const cb_chg = React.useCallback( (k,v) => {
        dispatch( {type: 'val', k:k, v:v} );
    }, [] );

    function cb_submit()
    {

        function __ok(e)
        {
            dispatch( {type: 'val', k:'busy', v:false} );
            onOk();
        }

        function __fail(e)
        {
            dispatch( {type: 'val', k:'busy', v:false} );
        }

        dispatch( {type: 'val', k:'busy', v:true} );

        NuPost2( 4211, {rid:rid, amo:sta.amount}, __ok, __fail );
    
    }


    const DIS_SUB = false;


    return (

        <LayVert2 rows='1fr max-content' >

            <LayVert2 rows='max-content max-content 1fr' padding='16px' gap='16px' >
                <LayHoriz2 rows='300px 1fr'  >


                    <DaNumericField min={0} max={9999} label='В НАЛИЧИИ СЕЙЧАС' name='amount' value={sta.amount} onChange={cb_chg}/>

                    <LaySpace />
                </LayHoriz2>

                <span>Текущий клуб: <ShortName ctx={DEFS.SHORT_NAME_CLUB} id={sss.clubId} /></span>
                <LaySpace />
            </LayVert2>

            <BottomButtonsPanel>
                <DaButton title='ПРИМЕНИТЬ' onClick={cb_submit} disabled={DIS_SUB} />
            </BottomButtonsPanel>

            <OverlayLoading active={sta.busy} />

        </LayVert2>


    );
}





export function WinDevTweakGoodsStock( {visible, rid, onOk, onClose} )
{
    //console.log('STOCK.. rid', rid);

    return ( 
    
                <Win2 visible={visible}  title='!!! КОЛИЧЕСТВО ТОВАРА (НАПРЯМУЮ)' width={410} height={240} onClose={onClose} > 
                    <MyBody  rid={rid} onOk={onOk} />
                </Win2> );
}
