 
import React from 'react';
import { ShowErr } from '../../misc_glob.js';
import * as HLP from '../../helpers.js';
import { NuPost } from '../../api.js';



export function ClubListText( {csv} )
{
    const [lst, setLst] = React.useState( null );

    React.useEffect( () => {

        function __ok(e)
        {
            setLst(e.rows);
        }

        function __fail(e)
        {
            ShowErr(e.m);
        }

        NuPost( 3001, {}, __ok, __fail );

    }, [] );

    if (csv === '') return 'ВСЕ ВЛУБЫ';
    if (lst === null) return '???';

    let r = [];

    const tt = HLP.CsvSplit(csv);

    for (let x = 0; x < tt.length; x ++ )
    {
        const e = HLP.ArrayOfDictsFindByPropValue( lst, 'c_id', tt[x] );
        r.push( e ? e.c_vis_name : '???' );
    }

    return r.join(', ');
}
