import React from 'react';


import * as DEFS from '../defs.js';




import Rating from '@mui/material/Rating';


//-------------------------------------------------------


            
const gSrcHand = [ 
                            {k: DEFS.CU_HAND_UNK, 	v: '???'},
                            {k: DEFS.CU_HAND_L, 	v: 'ЛЕВАЯ'},
                            {k: DEFS.CU_HAND_R, 	v: 'ПРАВАЯ'},
                            {k: DEFS.CU_HAND_BOTH,	v: 'АМБИДЕКСТР'},
                            
                        ];

const gSrcCanRecord = [ 
                                {k: 0, v: 'НЕТ'},
                                {k: 2, v: 'ПОКА НЕТ'},
                                {k: 1, v: 'ДА'},
                                
                                {k: 3, v: '???'},
                        ];
                        
                   
						





						/*
function BlkCustStatsImp(pp)
{
	const { isFetching, isLoading, isError, data, error } = useApiQuery( 978, {} );
	
	if (isFetching || !data) return null;
	
	if (isError) return ( <div className="yy_error">{error}</div> );
	
	
	
	const valSta 	= pp.dict;
	const CHG 		= pp.onChange;
	
	function getValStaWrap(k)
	{
		let v = valSta[k];
		if (v === undefined) v = 0;
		return v;
	}


	const p_lst = [];
	
	const rrr = data.rows;
	
	for (let x = 0; x < rrr.length; x ++ )
	{
		const e = rrr[x];
		
		const ID = e.st_id;
		
		p_lst.push( <CmDudeStatsRating  readOnly={pp.disabled}  key={ID} labelWidth='140px' label={e.st_name} name={ID}  value={ getValStaWrap(ID) } onChange={CHG} /> );
	}

	
	
	return (
				<CmFramedShit title='СТАТЫ:'>
					<div style={{display: 'flex', flexDirection: 'column', gap: '20px', padding: '20px'}}>
						{p_lst}
					</div>
				</CmFramedShit>
	
	);
}


const BlkCustStats = React.memo( BlkCustStatsImp );



const gAdoTG = <div className="zz_so_tg"></div>;
const gAdoInsta = <div className="zz_so_insta"></div>;
const gAdoVk = <div className="zz_so_vk"></div>;

const gAdoCM = <div>СМ</div>;
const gAdoKG = <div>KG</div>;

const gDefaultDOB = '1901-01-01';


// КАРТОЧКА КЛИЕНТА / ДЕТАЛИ
export function ViCustomerCardDetails(pp)
{
	const RID = pp.rid;
	
	const [busy,  setBusy]		= React.useState( false );
	
	const [valSta, setValSta]   = React.useState( null );
	
	const [valName, 	setValName]		= React.useState( '' );
	const [valPhone, 	setValPhone]	= React.useState( '' );
	const [valHeight, 	setValHeight]	= React.useState( 0 );
	const [valWeight, 	setValWeight]	= React.useState( 0 );
	const [valOrigin, 	setValOrigin]	= React.useState( 0 );
	const [valDob, 		setValDob]		= React.useState( DT.DateParseIso(gDefaultDOB) );
	
	
	
	const [valConta, 	setValConta]		= React.useState( '' );
	
    const [valRules, 	setValRules]	= React.useState( 0 );
    
    const [valContract, 	setValContract]	= React.useState( null );

	const [valGend, 	setValGend]	= React.useState( 0 );
	const [valHand, 	setValHand]	= React.useState( 0 );
	const [valReco, 	setValReco]	= React.useState( 0 );
	const [valReach, 	setValReach]	= React.useState( 0 );
    
    const [valCuReq, 	setValCuReq]	= React.useState( -1 );

	
	const [valExTg,		setValExTg]		= React.useState( '' );
	const [valExIn,		setValExIn]		= React.useState( '' );
	const [valExVk,		setValExVk]		= React.useState( '' );
	const [valExFar,		setValExFar]		= React.useState( '' );
	const [valExMed,		setValExMed]		= React.useState( '' );
	
	const [valExWant,		setValExWant]		= React.useState( '' );
	const [valExBg,			setValExBg]			= React.useState( '' );
    
    const [valPrefConta, 	setValPrefConta]	= React.useState( '' );
	
	const [valOpSpa, 	setValOpSpa]	= React.useState( 0 );
	const [valOpGrp, 	setValOpGrp]	= React.useState( 0 );
	const [valOpPai, 	setValOpPai]	= React.useState( 0 );
	const [valOpSol, 	setValOpSol]	= React.useState( 0 );
	
	const [valExDrBox,		setValExDrBox]		= React.useState( 0 );
	const [valExDrKik,		setValExDrKik]		= React.useState( 0 );
	const [valExDrMMA,		setValExDrMMA]		= React.useState( 0 );
	const [valExDrTha,		setValExDrTha]		= React.useState( 0 );

    const sss = useSelector( selectDictAuth );
	
	
	const { isFetching, isLoading, isError, data, error } = useApiQuery( 980, {rid:RID}, {staleTime: 1} );
	
	
	
	React.useEffect( () => {
		
		//console.log('DATA CHANGED !!', data);
		
		if (data)
		{
			const qq = data.row;
			const tt = data.ex;
			
			setValSta( data.stats );
            
            setValCuReq( data.cureq );
            
            setValRules( qq.cu_got_rules );

			
			setValOpSpa( qq.cu_opt_sparring );
			setValOpGrp( qq.cu_opt_group );
			setValOpPai( qq.cu_opt_pairs );
			setValOpSol( qq.cu_opt_only_solo );
			
			setValExDrBox( parseInt(tt[DEFS.CU_EX_FIELD_DRPR_BOX]) 	|| 0 );
			setValExDrKik( parseInt(tt[DEFS.CU_EX_FIELD_DRPR_KICKBOX]) 	|| 0 );
			setValExDrMMA( parseInt(tt[DEFS.CU_EX_FIELD_DRPR_MMA]) 	|| 0 );
			setValExDrTha( parseInt(tt[DEFS.CU_EX_FIELD_DRPR_THAI]) 	|| 0 );
			
			
			setValExTg( tt[DEFS.CU_EX_FIELD_SOC_TG] 	|| '' );
			setValExIn( tt[DEFS.CU_EX_FIELD_SOC_INSTA] 	|| '' );
			setValExVk( tt[DEFS.CU_EX_FIELD_SOC_VK] 	|| '' );
			setValExFar( tt[DEFS.CU_EX_FIELD_HOW_FAR] 	|| '' );
			setValExMed( tt[DEFS.CU_EX_FIELD_MED_RESTR] 	|| '' );
			setValExWant( tt[DEFS.CU_EX_FIELD_DESIRE] 	|| '' );
			setValExBg( tt[DEFS.CU_EX_FIELD_SPORT_BG] 	|| '' );
            setValPrefConta( tt[DEFS.CU_EX_FIELD_PREF_CONTACT_CSV] 	|| '' );
		
			
			
			setValName(   qq.cu_name );
			setValPhone(  qq.cu_phone );
			setValHeight( qq.cu_height );
			setValWeight( qq.cu_weight );
			setValOrigin( qq.cu_origin );
			setValConta(   qq.cu_contact_person );
			setValDob(   DT.DateParseIso( qq.cu_dob) );

			setValContract( DT.DateTimeParseIso(qq.cu_contract_dt) );
			
			setValGend( qq.cu_gender );
			setValHand( qq.cu_hand_pref );
			
			setValReco( qq.cu_f_can_record );

			setValReach( qq.cu_f_walk_reach );
		}
		
	}, [data] );
	
	

	const cb_chg_ex_want = React.useCallback( (k,v) => { setValExWant(v); }, [valExWant] );
	const cb_chg_ex_bg   = React.useCallback( (k,v) => { setValExBg(v); }, [valExBg] );
	const cb_chg_ex_dr_box = React.useCallback( (k,v) => { setValExDrBox(v); }, [valExDrBox] );
	const cb_chg_ex_dr_kik = React.useCallback( (k,v) => { setValExDrKik(v); }, [valExDrKik] );
	const cb_chg_ex_dr_mma = React.useCallback( (k,v) => { setValExDrMMA(v); }, [valExDrMMA] );
	const cb_chg_ex_dr_tha = React.useCallback( (k,v) => { setValExDrTha(v); }, [valExDrTha] );
	const cb_chg_ex_med = React.useCallback( (k,v) => { setValExMed(v); }, [valExMed] );
	const cb_chg_ex_far = React.useCallback( (k,v) => { setValExFar(v); }, [valExFar] );
	const cb_chg_ex_tg  = React.useCallback( (k,v) => { setValExTg(v); }, [valExTg] );
	const cb_chg_ex_in  = React.useCallback( (k,v) => { setValExIn(v); }, [valExIn] );
	const cb_chg_ex_vk  = React.useCallback( (k,v) => { setValExVk(v); }, [valExVk] );
    const cb_chg_pref_conta  = React.useCallback( (k,v) => { setValPrefConta(v); }, [valPrefConta] );
	
    const cb_chg_rules  = React.useCallback( (k,v) => { setValRules(v); }, [valRules] );
	const cb_chg_contract = React.useCallback( (k,v) => { setValContract(v); }, [valContract] );
    
    
	const cb_chg_conta  = React.useCallback( (k,v) => { setValConta(v); }, [valConta] );
	const cb_chg_reach  = React.useCallback( (k,v) => { setValReach(v); }, [valReach] );
	const cb_chg_reco   = React.useCallback( (k,v) => { setValReco(v); }, [valReco] );
	const cb_chg_gend   = React.useCallback( (k,v) => { setValGend(v); }, [valGend] );
	const cb_chg_hand   = React.useCallback( (k,v) => { setValHand(v); }, [valHand] );
	const cb_chg_dob     = React.useCallback( (k,v) => { setValDob(v); }, [valDob] );
	const cb_chg_height  = React.useCallback( (k,v) => { setValHeight(v); }, [valHeight] );
	const cb_chg_weight  = React.useCallback( (k,v) => { setValWeight(v); }, [valWeight] );
	const cb_chg_name    = React.useCallback( (k,v) => { setValName(v); },   [valName] );
	const cb_chg_origin  = React.useCallback( (k,v) => { setValOrigin(v); },   [valOrigin] );
	
	
	const cb_chg_op_spa  = React.useCallback( (k,v) => { setValOpSpa(v); }, [valOpSpa] );
	const cb_chg_op_grp  = React.useCallback( (k,v) => { setValOpGrp(v); }, [valOpGrp] );
	const cb_chg_op_pai  = React.useCallback( (k,v) => { setValOpPai(v); }, [valOpPai] );
	const cb_chg_op_sol  = React.useCallback( (k,v) => { setValOpSol(v); }, [valOpSol] );
	
	const cb_chg_cureq  = React.useCallback( (k,v) => { setValCuReq(v); }, [valCuReq] );
	
    
	const cb_chg_phone = React.useCallback( (k,v) =>
	{
		setValPhone(v);
	}, [valPhone] );
	
	
	
	const  cb_chg_sta = React.useCallback( (k,v) =>
	{
		setValSta( {...valSta, [k]: v } );
		
	}, [valSta] );
	
	
	
	const cb_submit = () =>
	{
		//console.log('SUBMIT !');
		
		const t_row = {
            cu_got_rules: valRules,
			cu_name:	valName,
			cu_phone:	valPhone,
			cu_contact_person: valConta,
			cu_origin: valOrigin,
			cu_height: valHeight,
			cu_weight: valWeight,
			cu_gender: valGend,
			cu_hand_pref: valHand,
			cu_f_walk_reach: valReach,
			cu_f_can_record: valReco,
			cu_opt_pairs: valOpPai,
			cu_opt_only_solo: valOpSol,
			cu_opt_group: valOpGrp,
			cu_opt_sparring: valOpSpa,

			cu_dob: DT.DateStrIso(valDob),
			cu_contract_dt: DT.DateTimeStrIso(valContract),
		};
		
		
		
		const t_ex = {
			
			[DEFS.CU_EX_FIELD_DESIRE]:		valExWant,
			[DEFS.CU_EX_FIELD_SPORT_BG]:		valExBg,
			[DEFS.CU_EX_FIELD_MED_RESTR]:		valExMed,
			[DEFS.CU_EX_FIELD_SOC_TG]:		valExTg,
			[DEFS.CU_EX_FIELD_SOC_VK]:		valExVk,
			[DEFS.CU_EX_FIELD_SOC_INSTA]:		valExIn,
			[DEFS.CU_EX_FIELD_HOW_FAR]:		valExFar,
			[DEFS.CU_EX_FIELD_DRPR_BOX]:		valExDrBox,
			[DEFS.CU_EX_FIELD_DRPR_KICKBOX]:	valExDrKik,
			[DEFS.CU_EX_FIELD_DRPR_MMA]:		valExDrMMA,
			[DEFS.CU_EX_FIELD_DRPR_THAI]:		valExDrTha,
            [DEFS.CU_EX_FIELD_PREF_CONTACT_CSV]:		valPrefConta,
			
		};
		

		const __ok = (e) => {
			ShowMsg('СОХРАНЕНО !');
			
			setBusy(false);
			
			STO_InvaCustLink(RID);
		};
		
		const __fail = (e) => {
			ShowErr(e.m);
			
			setBusy(false);
		};
		
		//console.log('ROW ', t_row);
		//console.log('EX', t_ex);
		
		setBusy(true);
		
		NuPost( 711, { row: t_row,
                        ex: t_ex,
                        sta: valSta,
                        cureq: valCuReq,
                        cu_id: RID }, __ok, __fail );
		
	};

	
	
	
	if (isLoading || isFetching || !valSta) return ( <CmSpinner sx='64px' />  );
	if (isError) return ( <div className="yy_error">{error}</div> );
	
	


	const wrn_st = (valOpSpa + valOpGrp + valOpPai + valOpSol) < 1;
	
	const warn_dr = (valExDrBox + valExDrKik + valExDrMMA + valExDrTha) < 1;

	const warn_height = HLP.ToInt( valHeight ) < 1;
	const warn_weight = HLP.ToInt( valWeight ) < 1;
	const warn_gend = valGend === DEFS.CU_GENDER_UNK;
	const warn_hand = valHand === DEFS.CU_HAND_UNK;


	const CLA = busy ? 'zz_busy' : null;
    
    const DIS_STATS = sss.abilityArray.indexOf(101) === -1;

	const DIS_SUB = (valName.trim()==='') || (valPhone.trim()==='') || (valDob===null);

	return ( <div className={CLA} style={{position: 'absolute', height: '100%', width: '100%', display: 'grid', gridTemplateColumns: '1fr', gridTemplateRows: '1fr max-content' }}>
	
				<div style={{overflowY: 'scroll', width: '100%', height: '100%', padding: '20px'}}>
	
	
					<div style={{display: 'flex', gap: '20px'}}>
				
						<div style={{width: '50%', display: 'flex', flexDirection: 'column', gap: '24px'}}>
						
			
							<CmMuiInp hint='ФИО' 				value={valName}  onChange={cb_chg_name} />
							<CmMuiInp hint='ТЕЛЕФОН' 			value={valPhone} onChange={cb_chg_phone} />
							<CmMuiInp hint='КОНТАКТНОЕ ЛИЦО (МАМА/ПАПА)' 	value={valConta} onChange={cb_chg_conta} />
						
							<CmMuiInp hint='TELEGRAM' 				value={valExTg}  		onChange={cb_chg_ex_tg}  ado={gAdoTG} />
							<CmMuiInp hint='INSTAGRAM' 			 	value={valExIn}  		onChange={cb_chg_ex_in}  ado={gAdoInsta} />
							<CmMuiInp hint='VK' 					value={valExVk}  		onChange={cb_chg_ex_vk}  ado={gAdoVk} />
                            
                            <CustFieldLinkWithCureq name='cureq' value={valCuReq} onChange={cb_chg_cureq} label='СВЯЗЬ С ЗАЯВКОЙ' />
						

                            <DaCsvField  label='УДОБНЫЙ СПОСОБ СВЯЗИ'  fld_k='k' fld_v='v' value_csv={valPrefConta} src={DEFS.gSrcCustContactPref} onChange={cb_chg_pref_conta} />
					

							<DaDateField value={valContract} label='ДОГОВОР ПОДПИСАН' onChange={cb_chg_contract} />
                        
                            <CmCheckBox value={valRules} label='Отправили правила ?' onChange={cb_chg_rules} />

							
                        
						</div>
						
						<div style={{width: '50%', display: 'flex', flexDirection: 'column', gap: '24px'}}>
							<CmComboCustSrc label='ИСТОЧНИК'  value={ valOrigin } onChange={cb_chg_origin} />
							
							<CmMuiCombo4 label='ПОЛ' fld_k='k' fld_v='v'    value={valGend} onChange={cb_chg_gend}  src={DEFS.gSrcGender}  error={warn_gend} />

                            <DaDateField nonull label='ДАТА РОЖДЕНИЯ' onChange={cb_chg_dob}  value={valDob} />	
                            
							<CmMuiInp hint='РОСТ' 		value={''+valHeight} onChange={cb_chg_height}  ado={gAdoCM} error={warn_height}/>
							<CmMuiInp hint='ВЕС' 		value={''+valWeight} onChange={cb_chg_weight}  ado={gAdoKG} error={warn_weight} />
							
							<CmMuiCombo4 label='РАБОЧАЯ РУКА' fld_k='k' fld_v='v'    value={valHand} onChange={cb_chg_hand}  src={gSrcHand}  error={warn_hand} />
							
							<CmMuiCombo4 label='МОЖНО СНИМАТЬ ?' fld_k='k' fld_v='v'    value={valReco} onChange={cb_chg_reco}  src={gSrcCanRecord} />

							<CmCheckBox value={valReach} label='Живёт в пешей доступности ?' onChange={cb_chg_reach} />
							
							<CmMuiInp value={valExFar} onChange={cb_chg_ex_far} 		hint="ДОЛГО ДОБИРАТЬСЯ ?"  />
							

						</div>

					</div>
					
					
					<div style={{display: 'flex', gap: '10px', marginTop: '50px'}}>
					
						<BlkCustStats onChange={cb_chg_sta} dict={valSta} disabled={DIS_STATS} />
						
						<CmFramedShit sx={{width: '33%'}} title="ФОРМАТ" warn={wrn_st} >
							<CmCheckBox    value={valOpSpa}     	label='Спарринг' onChange={cb_chg_op_spa} />
							<CmCheckBox    value={valOpGrp}        	label='Группа'   onChange={cb_chg_op_grp} />
							<CmCheckBox    value={valOpPai}        	label='Парные'   onChange={cb_chg_op_pai} />
							<CmCheckBox    value={valOpSol}    		label='Соло'     onChange={cb_chg_op_sol} />
						</CmFramedShit>
						
	
						<CmFramedShit sx={{width: '33%'}} title="ПРЕДПОЧТЕНИЯ" warn={warn_dr} >
							<CmCheckBox 	value={valExDrBox}     		label='Бокс' 		onChange={cb_chg_ex_dr_box} />
							<CmCheckBox 	value={valExDrKik}     		label='Кикбоксинг' 		onChange={cb_chg_ex_dr_kik} />
							<CmCheckBox 	value={valExDrMMA}     		label='ММА' 		onChange={cb_chg_ex_dr_mma} />
							<CmCheckBox 	value={valExDrTha}     		label='Тайский' 		onChange={cb_chg_ex_dr_tha} />
						</CmFramedShit>
									
                    </div>
					
					
					<div style={{marginTop: '40px', display: 'flex', flexDirection: 'column', gap: '24px'}}>
					
						<CmMuiCustExField value={valExMed}  onChange={cb_chg_ex_med} label='МЕДИЦИНСКИЕ ПРОТИВОПОКАЗАНИЯ' />
						
						
                        <CmMuiText 	value={valExWant} onChange={cb_chg_ex_want} 		hint="ПОЖЕЛАНИЯ" multiline rows={4}  />
                        
						<CmMuiText  value={valExBg} onChange={cb_chg_ex_bg}    			hint="УРОВЕНЬ / ОПЫТ В СПОРТЕ" multiline rows={4} />					
					
					</div>
	
				</div>
				
				
				<BottomButtonsPanel>
					<DaButton onClick={cb_submit} disabled={DIS_SUB} icon={SaveIcon} title='СОХРАНИТЬ' />
				</BottomButtonsPanel>


			</div>
			
	);
}



*/

//--------------------------------------------------



function CmDudeStatsRatingImp(pp)
{
    function chg(e,v)
    {
        pp.onChange(pp.name,v);
    }

    return (
				<div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
					<div style={{width: pp.labelWidth, color: '#303050'}}>{pp.label}</div>
					<Rating size={pp.small ? 'small' : 'medium'} sx={{color: '#8070D0'}} value={pp.value} onChange={chg} max={DEFS.FL_CUST_MAX_POINTS} readOnly={pp.readOnly} />
				</div>
    );
}


export const CmDudeStatsRating = React.memo( CmDudeStatsRatingImp );
