import React from 'react';


import { CmCachedCustLink } from '../desktop/ca_cust_link.js';

import { Grid3 } from '../grid3.js';

import { LayVert2, LayHoriz2, LaySpace } from '../misc/layout.js';

import { ClubListCombo } from '../desktop/club_list_combo.js';

//---------------------------


function CmpDude(pp)
{
	//console.log(pp);
	return <CmCachedCustLink rid={pp.value} />;
}



const gNuCols = [

    {fld: 'cu_id',        name: 'Клиент',           sort: false, width: 330, renderCell: CmpDude},
    {fld: 'v_tot_money',  name: 'Занёс',            sort: true,  width: 150  },
    {fld: 'v_num_pays',   name: 'Покупок',          sort: true,  width: 150  },

];



export function TabStatsTopPayers(pp)
{
    const [club, setClub] = React.useState( -1 );

    function cb_chg(k,v)
    {
        setClub(v);
    }

    const FLT = React.useMemo( () => {
        return { club: club };
    }, [club] );

    return (
    
        <LayVert2 rows='max-content 1fr 30px' gap='10px'>

            <LayHoriz2 cols='1fr 300px 1fr' padding='10px' >
                <LaySpace />
                <ClubListCombo label='КЛУБ' value={club} onChange={cb_chg}/>
                <LaySpace />
              </LayHoriz2>

            <LayHoriz2 cols='1fr 700px 1fr' >
                <LaySpace />
                <Grid3 cols={gNuCols} flt={FLT} apiCmd={966} uniq='cu_id'  sort_field='v_tot_money' sort_dir='desc' />
                <div></div>
            </LayHoriz2>
            <LaySpace />
        </LayVert2>
    
    );


}




















