import React from 'react';

import ruLocale from 'date-fns/locale/ru';

import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';

import * as DEFS from './defs.js';
import * as HLP from './helpers.js';

import Badge from '@mui/material/Badge';
import Checkbox from '@mui/material/Checkbox';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import FilterAltIcon from '@mui/icons-material/FilterAlt';


import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import Check from '@mui/icons-material/Check';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';

import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';


import { CmSpinner } from './cm_base.js';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ClearIcon from '@mui/icons-material/Clear';
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
import Dialog from '@mui/material/Dialog';

import moment from 'moment';

import MenuItem from '@mui/material/MenuItem';
import LinearProgress from '@mui/material/LinearProgress';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


function DateIsValid(js_dt)
{
	if (js_dt === null) return false;
	
    const d = js_dt.getDate();
    const m = js_dt.getMonth() + 1; //Month from 0 to 11
    const y = js_dt.getFullYear();
    
    if (isNaN(d) || isNaN(m) || isNaN(y)) return false;
    
    return true;
}

export function DateFromYYYYMMDD(st)
{
	if (st === null) return null;
	
	st = st.trim();
	
	st = st.replaceAll(' ');
	
	let aa = st.split('-');
	if (aa.length != 3) return null;
	
	if (aa[0].length != 4) return null;
	if (aa[1].length != 2) return null;
	if (aa[2].length != 2) return null;
	
	let vd = parseInt(aa[2]);
	let vm = parseInt(aa[1]);
	let vy = parseInt(aa[0]);
	
	if (isNaN(vd) || isNaN(vm) || isNaN(vy)) return null;
	
	if (vd < 1 || vm < 1) return null;
	if (vd > 32 || vm > 12) return null;
	
	let dt = new Date();
	
	dt.setDate(vd);
	dt.setMonth(vm-1);
	dt.setFullYear(vy);
	
	if (!DateIsValid(dt)) return null;
	
	return dt;
}


function DateFromDDMMYYYY(st)
{
	if (st === null) return null;
	
	st = st.trim();
	
	st = st.replaceAll(' ');
	
	let aa = st.split('.');
	if (aa.length != 3) return null;
	
	if (aa[0].length != 2) return null;
	if (aa[1].length != 2) return null;
	if (aa[2].length != 4) return null;
	
	let vd = parseInt(aa[0]);
	let vm = parseInt(aa[1]);
	let vy = parseInt(aa[2]);
	
	if (isNaN(vd) || isNaN(vm) || isNaN(vy)) return null;
	
	if (vd < 1 || vm < 1) return null;
	if (vd > 32 || vm > 12) return null;
	
	const dt = new Date();
	
	dt.setDate(vd);
	dt.setMonth(vm-1);
	dt.setFullYear(vy);
	
	if (!DateIsValid(dt)) return null;
	
	return dt;
}


//-------------------------------------
        
function CmNuDatePicker2Imp(pp)
{
	const [textVal, setTextVal] 	= React.useState('');
	const [isfoc,   setIsFoc]	 	= React.useState(false);
	const [ispop,   setIsPop]	 	= React.useState(false);
	
	let myRef = React.createRef();
	

    const myTryParseVal = (v) =>
    {
        v = v.replaceAll(' ', '');
        v = v.trim();

        let mo = moment(v, 'DD.MM.YYYY', true);
        if (!mo.isValid())
        {
            if (pp.nonull)
            {
                pp.onChange( pp.name, moment().format('YYYY-MM-DD') );
            }
            else
            {
                pp.onChange( pp.name, null );
            }
            return;
        }
        
        pp.onChange( pp.name, mo.format('YYYY-MM-DD')  );
    }
	
	
	function onChgText(ev)
	{
		let t0 = ev.target.value;
		setTextVal(t0);
		myTryParseVal( t0 );
	}
	
	function onBlur(ev)
	{
        let init_te = '';
        if (pp.val !== null)
        {
			//console.log('blur.. but got val ! ', pp.val);
			init_te = moment(pp.val).format('DD.MM.YYYY');
		}
        setTextVal( init_te );
        setIsFoc(false);
	}

	function onFoc(ev)
	{
        let init_te = '';
        
        if (pp.val !== null) init_te = moment(pp.val).format('DD.MM.YYYY');
        
        setIsFoc(true);
        setTextVal( init_te );
	}
	

	const onKD = (ev) =>
	{
		if (isfoc === false) return;
		if (ev.code == 'Enter')
		{
			//console.log('BLUR ON ESC !');
			myRef.current.blur();
		}
	};
  
	const onClickCalen = () =>
	{
		//console.log('SHOW CALEN !');
		
		setIsPop(true);
	};
	
	const onClickClear = () =>
	{
		//console.log('CLEAR !');
		setTextVal('');
		myRef.current.blur();
		pp.onChange( pp.name, null );
	};
	
	
	function onPopChg(ev)
	{
		let mm = moment(ev).format('YYYY-MM-DD');
		
		//console.log('---', mm);
		
		pp.onChange( pp.name, mm );
		
		setIsPop(false);
	}
	

	// rend
	
	let sss = {  };
	

	
	let inp_val;
	
    if (isfoc)
    {
        inp_val = textVal;
        sss.color = '#30306F';
    }
    else 
    {
        if (pp.val !== null)
        {
            sss.color = '#000000';
            
            const mo = moment(pp.val);
    
            inp_val = mo.format('DD.MM.YYYY');
        }
        else
        {
            //sss.color = '#707070';
            
            inp_val = '';
            
            //if (this.props.hint) inp_val = this.props.hint;
        }
    }
    
    let di_btn_clear = pp.nonull ? null : <IconButton onClick={onClickClear} size='small'><ClearIcon sx={{fontSize: 'medium'}}/></IconButton>;

	let dt_for_disp = DateFromYYYYMMDD(pp.val);
	if (dt_for_disp === null)
	{
		//console.log('use default date !');
		dt_for_disp = null;
	}

	const my_ador = pp.disabled ? null : (<>	{di_btn_clear}
								<IconButton onClick={onClickCalen} size='small'><CalendarTodayIcon sx={{fontSize: 'medium'}} /></IconButton></>);

	return (    <>
		<TextField fullWidth size='small'   inputRef={myRef}   sx={ pp.sx }  disabled={pp.disabled}
           variant="outlined"
           placeholder='ДД.ММ.ГГГГ'
           label={pp.label}
           value={inp_val}
           onFocus={ onFoc }
           onBlur={ onBlur }
           onChange={ onChgText }
           onKeyDown={ onKD }
           InputProps={{	sx: sss,  
               endAdornment: my_ador
               }}
           />
           
           <Dialog style={{zIndex: 9999999, position: 'fixed'}} transitionDuration={50} open={ispop} onClose={ () => setIsPop(false) }  PaperProps={{ sx: {boxShadow: 'none'} }}  >
				<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ruLocale}>
					<CalendarPicker  reduceAnimations={true} openTo='day' date={ dt_for_disp } onChange={onPopChg}  />
					
				</LocalizationProvider>
			</Dialog>
           
           </>
    
  );
}


export const CmNuDatePicker2 = React.memo( CmNuDatePicker2Imp );

//--------------------------------




export function MyLittleHorizProgress( {active} )
{
	return <div style={{height: '4px'}}>
					{ active ? <LinearProgress /> : null  }
			</div>;
}



//--------------------------------------------------

function CmMuiCombo4Imp(pp)
{
	let p_ar = [];
	
	if (pp.src)
	{
		for (let x = 0; x < pp.src.length; x ++ )
		{
			const e = pp.src[x];
			
			const my_kk = e[pp.fld_k];
			const my_vv = e[pp.fld_v];
			
			p_ar.push( <MenuItem key={my_kk} value={my_kk}>{my_vv}</MenuItem> );
		}
	}
	
	function onChg(e)
	{
		if (pp.onChange) pp.onChange( e.target.name, e.target.value );
	}
	

	return ( <FormControl fullWidth size='small' sx={pp.sx}>
        <InputLabel size='small' id="x1">{pp.label}</InputLabel>
        <Select error={pp.error} size='small' disabled={pp.disabled}  sx={{height: '34px'}} MenuProps={{ sx: {zIndex:9999999}  }} 
			labelId="x1"
          label={pp.label}
           name={pp.name}
          value={pp.value}
          renderValue={ pp.isLoading ? __ComboRendValLoading : undefined}
          onChange={onChg}
        >
          {p_ar}
        </Select>
      </FormControl>);
}

function __ComboRendValLoading(pp)
{
	return <CmSpinner sz={22} />;
}


export const CmMuiCombo4 = React.memo( CmMuiCombo4Imp );

//-----------------------------

function CmCheckBoxImp(pp)
{
	function onChg(e, e2)
	{
		pp.onChange(pp.name, e2 ? 1 : 0);
	}
	
  return (
    <FormGroup>
      <FormControlLabel control={<Checkbox disabled={pp.disabled} size='small' onChange={onChg} checked={pp.value ? true : false} />} label={pp.label} />
    </FormGroup>
  );
}

export const CmCheckBox = React.memo(  CmCheckBoxImp );

//---------------------------------------------------


//-------------------------------------------------

// multiline
function CmMuiTextImp(pp)
{
	function onChg(e)
	{
		const k = e.target.name;
		const v = e.target.value;
		
		pp.onChange(k,v);
	};
	
	return 	<TextField sx={pp.sx} disabled={pp.disabled} fullWidth multiline size='small' autoComplete="off"
                          InputProps={{
    style: {
      height: "100%",
    },  }}   name={pp.name} value={pp.value} onChange={onChg} label={pp.hint}  rows={pp.rows} maxRows={pp.maxRows} />;
}

export const CmMuiText = React.memo( CmMuiTextImp );


//--------------------------

const gAdoEmpty = {};

function CmMuiInpImp(pp)
{
	function onChg(e)
	{
		const k = e.target.name;
		const v = e.target.value;
		
		pp.onChange(k,v);
	}
	
	let ado_props;
	
	if (pp.ado)
	{
		ado_props = { InputProps:{ endAdornment: <InputAdornment position="end">{pp.ado}</InputAdornment> } };
	}
	else
	{
		ado_props = gAdoEmpty;
	}
	
	
	return 	<TextField sx={pp.sx} autoFocus={pp.autoFocus} error={pp.error} {...ado_props}  disabled={pp.disabled} fullWidth size='small'
                        name={pp.name} value={pp.value} onChange={onChg} label={pp.hint} />;
}

export const CmMuiInp = React.memo( CmMuiInpImp );


//---------------------------------------------------------









function CmDaSwitcherImp(pp)
{
	const nam = pp.name;
	
	const cb_left = () =>
	{
		pp.onChange(nam, -1);
	};
	
	const cb_right = () =>
	{
		pp.onChange(nam, 1);
	};
	
	const dis_left  = pp.disabled || pp.dis_left;
	const dis_right = pp.disabled || pp.dis_right;
	
	return <div className="yy_inp_frame" style={{...pp.sx, height: '32px', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
				<IconButton size='small' disabled={dis_left} onClick={cb_left} ><ArrowLeftIcon /></IconButton>
				<div style={{userSelect: 'none', color: pp.disabled ? '#A0A0A0' : '#404040', fontWeight: 800}}>{pp.value}</div>
				<IconButton size='small' disabled={dis_right} onClick={cb_right} ><ArrowRightIcon /></IconButton>
			</div>;
}



export const CmDaSwitcher = React.memo( CmDaSwitcherImp );





//--------------



export function CmNuFltCheckList(props)
{

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	
	const nam = props.name;
	
	const handleClick = (e) => {
		setAnchorEl(e.currentTarget);
	};
	
	const han_clo = () => {
		setAnchorEl(null);
	};
  
	const handleItem = (e) => {
	  
	  const ii = parseInt(e.target.dataset.kk);

	  const nnn = HLP.CsvToggleID( props.val_csv, ii );
	  
	  props.onChange( nam, nnn );

	};
  
	let ar_items = [];
  
	for (let x = 0; x < props.src.length; x ++ )
	{
		let e = props.src[x];
		let kk = e[ props.src_key ];
		let vv = e[ props.src_val ];
		
		let p_chk = HLP.CsvTestID( props.val_csv, kk ) ?  <Check /> : null;
		
		ar_items.push(  <MenuItem onClick={handleItem} data-kk={kk} key={kk}><ListItemIcon>{p_chk}</ListItemIcon>{vv}</MenuItem>  );
	}
  
	const tit = props.title;
  
	const le = HLP.CsvSplit( props.val_csv ).length;
  

  return (
    <div style={{padding: '0px 10px'}}>
    
		<Badge badgeContent={ le>0 ? le : null } color="primary">

			<Button variant="outlined" size="small" startIcon={<FilterAltIcon />} onClick={handleClick}>
				{tit}
			</Button>
      
		</Badge>
		
		<Menu anchorEl={anchorEl} open={open} onClose={han_clo}  >
		
			{ar_items}
		</Menu>


    </div>
  );
}



