import React from 'react';

import { useApiQuery2 } from '../api_stuff.js';

import * as HLP from '../helpers.js';
import * as DEFS from '../defs.js';

import { CmSpinner } from '../cm_base.js';

import CheckIcon from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import {DaToolTip} from '../misc/tooltip.js';

//----------------------------------------------


function CmCachedUserLinkImp(pp)
{
	const rid = pp.rid;
	const questionsOnZero = pp.questionsOnZero;
	
	const { isLoading, isError, data, error } = useApiQuery2( 964, {id:rid}, {enabled:!!rid} );

	if ( rid === 0 && questionsOnZero ) return '???';

	if (isLoading) return <div className="zz_user_link_normal"><CmSpinner sz='16px' /></div>;
	if (isError) return <div className="yy_error">{error}</div>;
	
	if (!pp.rid) return '';

	
	
	const row = data.row;
					
		
	if (row === null) return <div className="red_shit">{'НЕ СУЩЕСТВУЕТ (' + rid + ')'}</div>;
	
	if (pp.plain) return row.u_name;

	const p_sq = pp.show_clr ? <div className="yy_user_sq" style={{background: '#'+row.u_disp_rgb}}></div> : null;
    
    const p_chk = pp.show_check ? <DaToolTip title='КОНКРЕТНО К ЭТОМУ ТРЕНЕРУ'><CheckCircleIcon sx={gIco} size='small' /></DaToolTip> : null;
	
	return <div className="zz_user_link_normal">{p_sq}{pp.bullet ? '• ' : null}{ row.u_name }{p_chk}</div>;
}







export const CmCachedUserLink = React.memo( CmCachedUserLinkImp );

const gIco = {fontSize: '18px', color: '#50A060'};
