import React from 'react';

import moment from 'moment';


import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';




export function CmMiniBtn(p)
{
    let cla = p.disabled ? "zz_mini_btn disabled" : "zz_mini_btn";
    
    let sty = {};
    
    if (p.sx !== undefined) sty.width = p.sx + 'px';
    
    return <div className={cla} style={sty} onClick={p.on_click}>
                        <div className={p.cla}>{p.text}</div>
                        </div>;
}




// циферка
/*
export class CmNumberSpn extends React.Component
{
    constructor(props)
    {
        super(props);
        
        this.OnLeft     = this.OnLeft.bind(this);
        this.OnRight    = this.OnRight.bind(this);
        
        this.OnFoc      = this.OnFoc.bind(this);
        this.OnBlu      = this.OnBlu.bind(this);
        this.OnChg      = this.OnChg.bind(this);
        this.OnKD       = this.OnKD.bind(this);
        
        this.myRef      = React.createRef();
        
        this.state = { edit: false, text: '' };
    }
    
    
    OnFoc(e)
    {
        //console.log('FOC !');
        
        this.setState( {text: this.props.val, edit: true} );
    }
    
    ClampedVal(v)
    {
        if      (v < this.props.min) v = this.props.min;
        else if (v > this.props.max) v = this.props.max;
        
        return v;
    }
    
    OnBlu(e)
    {
        //console.log('BLU !');
        
        let v = parseInt(this.state.text);
        if (isNaN(v)) v = this.props.min;   // fallback
        
        v = this.ClampedVal(v);
        
        this.setState( {edit: false} );
        
        this.props.on_change( v, this.props.my_key );
    }
              
    OnChg(e)
    {
        this.setState( {text: e.target.value} );
    }
    
    OnKD(e)
    {
        if (this.state.edit === false) return;
        //console.log('KD ', e);
        
        if (e.code === 'Enter')
        {
            this.myRef.current.blur();
        }
    }
    
    OnLeft()
    {
        let v = this.props.val;
        v --;
        
        v = this.ClampedVal(v);
        
        this.setState( {edit: false} );
        
        this.props.on_change( v, this.props.my_key );
    }
    
    OnRight()
    {
        let v = this.props.val;
        v ++;
        v = this.ClampedVal(v);
        
        this.setState( {edit: false} );
        
        this.props.on_change( v, this.props.my_key  );
    }
    

    render()
    {
        let v = this.state.edit ? this.state.text : this.props.val;
        
        let dis_l = this.props.disabled || v <= this.props.min;
        let dis_r = this.props.disabled || v >= this.props.max;
        
        let cla = this.props.disabled ? 'zz_num_spn_body disabled' : 'zz_num_spn_body';
        
        if (this.state.edit) cla +=' edit';
        
        return <div style={{width: this.props.sx, display: 'flex', alignItems: 'center', background: '#00000020', padding: '2px', borderRadius: '4px'}}>
        
            <CmMiniBtn disabled={dis_l} cla="icon_left16" on_click={this.OnLeft} />
            
            <div className={cla}>
            
                <input  ref={this.myRef}    onChange={ (e) => this.OnChg(e) }  onKeyDown={ this.OnKD }
                                            onFocus={ (e) => this.OnFoc(e) }
                                            onBlur={ (e) => this.OnBlu(e) } type='text' value={v} style={{textAlign: 'center', height: '100%', width: '100%', border: 'none' }} />
            </div>
            
            <CmMiniBtn disabled={dis_r} cla="icon_right16" on_click={this.OnRight}  />
            
        </div>;
    }
}

*/


// БАР для стат кастомера (статика)
/*
export class CmMiniBarDisp extends React.PureComponent
{

    render()
    {
        let p = this.props;
        
        let jw = Math.floor(p.rate * 100.0) + '%';
        
        let clr = p.clr;
        if (clr == undefined) clr = '#7080E0';
    
		let ppp;
		
		if (p.cmp_rate === undefined || p.cmp_rate === null)
		{
			ppp = <div className="zz_mini_bar_content" style={{width: jw, background: clr}}></div>;
		}
		else
		{
			let ow = Math.floor( (p.cmp_rate) * 100.0) + '%';
			
			if (p.cmp_rate > p.rate)
			{
				// <div className="zz_mini_bar_content" style={{width: '10%', background: clr}}></div>
				
				ppp = 	<>
							<div className="zz_mini_bar_content" style={{width: ow, background: '#E07070'}}></div>
							<div className="zz_mini_bar_content" style={{width: jw, background: clr}}></div>
						</>;
			}
			else
			{
				ppp = <>
							<div className="zz_mini_bar_content" style={{width: jw, background: '#90D0B9'}}></div>
							<div className="zz_mini_bar_content" style={{width: ow, background: clr}}></div>
						</>
			}
		}
    
        return  <div style={{padding: '4px', position: 'relative', height: '12px', width: '100%', background: '#10203030'}}>
                {ppp}
            </div>;
            
    }
}

*/



export class CmText extends React.Component
{
    constructor(props)
    {
        super(props);
        this.OnChg  = this.OnChg.bind(this);
    }
    
    OnChg(e)
    {
        this.props.on_change( e.target.value );
    }
    
    render()
    {
        let sy = this.props.sy;
        let cla = this.props.disabled ? 'zz_textarea disabled' : 'zz_textarea';
        
        return (<textarea readOnly={this.props.disabled}  className={cla} style={{height: sy+'px'}} value={this.props.val} onChange={this.OnChg} ></textarea>);
    }
    
}


export class CmTextLine extends React.Component
{
    constructor(props)
    {
        super(props);
        this.OnChg  = this.OnChg.bind(this);
    }
    
    OnChg(e)
    {
        this.props.on_change( e.target.value );
    }
    
    render()
    {
        //let sy = this.props.sy;
        
        let cla = this.props.disabled ? 'disabled' : '';
        
        return (<div className={cla} style={{margin: '4px'}}>
            <input placeholder={this.props.hint} readOnly={this.props.disabled}  type='text' className='zz_textinp' value={this.props.val} onChange={this.OnChg} ></input>
            </div>);
    }
    
}



export class CmCheck extends React.Component
{
    constructor(props)
    {
        super(props);
        this.OnChg  = this.OnChg.bind(this);
    }
    
    OnChg(e)
    {
        if (this.props.on_change)
        {
            let v = 1 - this.props.val;
            this.props.on_change( v, this.props.my_key );
        }
    }
    
    render()
    {
        let cla = this.props.val == 1 ? 'bdy zz_flash' : '';
        
        let cla_conta = this.props.disabled ? 'zz_checkbox disabled' : 'zz_checkbox';
        
        return (<div onClick={this.OnChg} className={cla_conta}>
                    <div className={cla}></div>
                </div>);
    }
    
}



export class DaBtn2 extends React.Component
{
    constructor(props)
    {
        super(props);
        
        this.OnClick = this.OnClick.bind(this);
    }
    
    OnClick(e)
    {
        if (this.props.cb_click && !this.props.disabled) this.props.cb_click( this.props.my_key );
    };
        
    
    render()
    {
        let cla = this.props.disabled ? 'my_little_btn disabled' : 'my_little_btn';
        
        let sx = this.props.sx;
        let sy = this.props.sy;
        
        let sty = {};
        
        if (sx == undefined)    sty.minWidth = '120px';
        else                    sty.width = sx + 'px';

        if (sy != undefined)
        {
            sty.height = sy + 'px';
        }
        
        let p_cnt = this.props.busy ? <CmSpinner sz='16px' /> : this.props.name;
        
        let clk = this.props.busy ? null : this.OnClick;

        return (
        
            <div style={sty} onClick={ clk } className={cla}>{p_cnt}</div>
        );
        
    }
}










/*
// горизонтальная выбиралка  - (выбор типов)
export class CmHorizSelector extends React.Component
{
    constructor(props)
    {
        super(props);
        
        this.MyClick = this.MyClick.bind(this);
    }
    
    MyClick(e)
    {
        //console.log('CLICK ! horiz ', e.target.dataset.kkk);
        
        this.props.on_change( e.target.dataset.kkk );
    }
    
    render()
    {
        let src = this.props.src;
        let k   = this.props.fld_key;
        let v   = this.props.fld_val;
        
        let ar = [];
        
        for (let x = 0; x < src.length; x ++ )
        {
            let it = src[x];
            let cla = it[k] == this.props.val ? "zz_selectable cur" : "zz_selectable";
            ar.push( <div data-kkk={it[k]} onClick={this.MyClick} className={cla} key={x}>{it[v]}</div> );
        }
        
        
        let sty = {padding: '2px', background: '#20107020', borderRadius: '4px', display: 'flex', justifyContent: 'space-between', gap: '1px', margin: '4px' };
        
        if (this.props.sx != undefined)
        {
            sty.width = this.props.sx + 'px';
        }
        
        let cla = this.props.disabled ?  'zz_selectable_cont disabled' : 'zz_selectable_cont';
        
        return (
            <div className={cla} style={sty}>
            
                {ar}
            
            </div>
        
        );
    }
    
}

*/




/*
// много плашек wrapped
export class CmManySelector extends React.PureComponent
{
    constructor(props)
    {
        super(props);
        
        this.MyClick = this.MyClick.bind(this);
    }
    
    MyClick(e)
    {
        let kk = parseInt(e.target.dataset.kkk);
        //console.log('CLICK ', kk );
        let nu_csv = HLP.CsvToggleID(this.props.val, kk);
        this.props.on_change( nu_csv );
    }
    
    render()
    {
        let src = this.props.src;
        let k   = this.props.fld_key;
        let v   = this.props.fld_val;
        
        let ar = [];
        
        //console.log('FILL MANY.. src: ', src);
        
        for (let x = 0; x < src.length; x ++ )
        {
            let it = src[x];
            let va = it[v];
            //console.log(' MY K: ', it[k]);
            let is_on = HLP.CsvTestID(this.props.val, it[k]);
            let cla = is_on ? "zz_selectable cur" : "zz_selectable";
            
            let da_sty = {minWidth: '100px', height: '26px' };
            
            if (this.props.ta) da_sty.textAlign = this.props.ta;
            
            ar.push( <div style={da_sty} data-kkk={it[k]} onClick={this.MyClick} className={cla} key={x}>{it[v]}</div> );
        }
        
        
        let da_sty = {  padding: '2px',
                        background: '#20107020',
                        borderRadius: '4px', display: 'flex',
                        justifyContent: 'space-between', gap: '1px', margin: '4px' };
                        
        if (!this.props.no_wrap)
        {
            da_sty.flexWrap = 'wrap';
        }
        else
        {
            da_sty.flexDirection = 'column';
        }
        
        return (
        
            <div style={da_sty}>
            
                {ar}
            
            </div>
        
        );
    }
    
}



*/

export class CmPropGroup extends React.Component
{
    constructor(props)
    {
        super(props);
    }
    
    render()
    {
        let lsx = this.props.label_sx;
        if (lsx == undefined) lsx = 110;
        
        
        let p_icon = this.props.icon ? <div className={this.props.icon}></div> : null;
        
        let cla_t = this.props.warn ? 'warning_text' : null;
        
        return (
            <div style={{display: 'flex', background: '#0010600A', margin: '6px', padding: '6px' }}>
            
                <div style={{display: 'flex', justifyContent: 'flex-end', padding: '4px', color: '#304070', width: lsx+'px'}}>
                    <div className={cla_t} style={{alignSelf: 'center', marginRight: '4px'}}>{p_icon}
                        {this.props.title}
                    </div>
                </div>
                <div style={{flexGrow:1}}>{this.props.children}</div>
            
            </div>
        
        );
    }
    
}




function ParsePx(xx)
{
    xx = parseInt(xx, 10);
    if (isNaN(xx)) return 0;
    return xx;
}



export class CmModalOverlay extends React.Component
{
    constructor(props)
    {
        super(props);
    }
    
    render()
    {
        return (
            <div className='zz_modal'>
                <div className='zz_modal_co'>
                    {this.props.children}
                </div>
            </div>
        
        );
    }
    
}



      
      


// БАЗА для попапа
export class CmPopUp extends React.Component
{
    constructor(props)
    {
        super(props);
        
        this.ClickClose     = this.ClickClose.bind(this);
        
        this.OnMouse        = this.OnMouse.bind(this);
        
        this.ref = React.createRef();
        
        this.st_dt = [0, 0];
        
        this.handleMouseUp = this.handleMouseUp.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
    }
    
    ClickClose()
    {
        if (this.props.cb_close) this.props.cb_close();
    }
    
    OnMouse(e)
    {
        //console.log('POP MOUSE ', e);
        
        if (e.type == 'mousedown')
        {
            //console.log('  MOUSE DOWN !');
            
            //
            
            this.st_dt = [e.clientX - ParsePx(this.ref.current.style.left),
                          e.clientY - ParsePx(this.ref.current.style.top)];
            
            window.addEventListener('mouseup', this.handleMouseUp, true);
            window.addEventListener('mousemove', this.handleMouseMove, true);
        }
    }
    
    handleMouseUp(e)
    {
        window.removeEventListener('mouseup', this.handleMouseUp, true);
        window.removeEventListener('mousemove', this.handleMouseMove, true);
    }
    
    handleMouseMove(e)
    {
        //console.log('MOVE ', e);
        
        let ox = (e.clientX) - this.st_dt[0];
        let oy = (e.clientY) - this.st_dt[1];
        
        this.ref.current.style.left = (ox) + 'px';
        this.ref.current.style.top  = (oy) + 'px';
    }
    
    componentWillUnmount()
    {
        window.removeEventListener('mouseup', this.handleMouseUp, true);
        window.removeEventListener('mousemove', this.handleMouseMove, true);
    }
    
    render()
    {
        
        let sx = this.props.sx;
        let sy = this.props.sy;
        

        
        let prt_close = (this.props.cb_close != undefined) ?  <span onClick={this.ClickClose} className="zz_win_close"><DisabledByDefaultIcon sx={{ color: '#FF4040', width: '24px', height: '24px'}}/></span>
															: null;

        
        return (
            <CmModalOverlay>

                    <div ref={this.ref} className='zz_window' style={{width: sx+'px', height: sy+'px' }}>
                    
                        <div onMouseDown={this.OnMouse}  onMouseUp={this.OnMouse}  className='zz_window_head' >
                            <div style={{userSelect: 'none', paddingLeft: '6px'}}>{this.props.title}</div>
                            {prt_close}
                        </div>
                        
                        <div className='zz_window_body'  >
                                {this.props.children}
                        </div>
                        
                    </div>
                
                
                
            </CmModalOverlay>
        
        );
    }
    
}








export class CmAmountBar extends React.PureComponent
{
    constructor(props)
    {
        super(props);
    }
    

    render()
    {
        //console.log('BAR RENDER !');
        
        let mi  = this.props.min;
        let ma  = this.props.max;
        let kkk = this.props.my_key;
        
        let num = ma - mi;
        
        
        let p_cnt = [];
        
        for (let x = mi; x <= ma; x ++ )
        {
            let cla = x <= this.props.val ? 'zz_amount_bar_it' : 'zz_amount_bar_it inact';
            p_cnt.push( <div key={x} onClick={ (e) => this.props.on_change( x, kkk ) } className={cla} ></div> );
        }

        return (  <div style={{padding: '1px', justifyItems: 'flex-start', display: 'flex', height: '18px', background: '#00203090', borderRadius: '4px'}}>
                        {p_cnt}
                </div>
        
        );
    }
}


  


// статика - рамка с титулом
export class CmFramedShit extends React.PureComponent
{
    constructor(props)
    {
        super(props);
    }
    
    
    render()
    {
        let p_tit = null;
        
        if (this.props.title)
        {
			if (!this.props.need_check)
			{
				
				p_tit = <div className="zz_framed_shit_tit">
                {this.props.title}
				</div>;
			}
			else
			{
				
				
				p_tit = <div style={{display: 'flex', alignItems: 'center', gap: '4px', color: '#6070A0', paddingLeft: '4px', paddingRight: '4px', fontSize: '13px', background: '#FFFFFF', position: 'absolute', top: '-12px', left: '30px'}}>
                <CmCheck my_key={this.props.my_key} on_change={this.props.on_chg} val={this.props.chk_val} /><div>{this.props.title}</div>
				</div>;
			}
			

        }
        
        return (<div className={this.props.warn ? "zz_framed_shit warn" : "zz_framed_shit"} style={this.props.sx}>
                    {p_tit}
                    {this.props.children}
                </div>);
    }
    
}




// плашка с месяцами
export class CmDateSelector extends React.Component
{
    constructor(props)
    {
        super(props);
        
        this.ClickSwYear   = this.ClickSwYear.bind(this);
        this.ClickSw   = this.ClickSw.bind(this);
        this.ClickCell = this.ClickCell.bind(this);
        this.ClickSel  = this.ClickSel.bind(this);
        
        let iii = this.props.val;//'2022-01-17 18:29:00';
        
        this.state = { date : iii, view_month: moment(iii).startOf('month').format('YYYY-MM-DD') }; 
        
    }
    
    ClickSel(m)
    {
        console.log(m);
        
        if      (m == 0) this.props.on_cancel();
        else if (m == 1) this.props.on_ok(this.state.date);
        
    }
    
    ClickCell(e)
    {
        let sel = moment(e.target.dataset.kkk);
        
        let was = moment(this.state.date);
        was.set('year', sel.year());
        was.set('month', sel.month() );
        was.set('date', sel.date() );
        
        this.setState( {date: was.format('YYYY-MM-DD HH:mm:ss')} );
    }
    
    ClickSw(a_dir)
    {
        let mo = moment(this.state.view_month);
        
        if (a_dir > 0)
        {
            mo.endOf('month').add(1, 'days');
        }
        else if (a_dir < 0)
        {
            mo.startOf('month').add(-1, 'days');
        }
        
        this.setState( { view_month: mo.format('YYYY-MM-DD') } );
    }
    
    ClickSwYear(a_dir)
    {
        let mo = moment(this.state.view_month);
        
        if (a_dir > 0)
        {
            mo.endOf('month').add(1, 'years');
        }
        else if (a_dir < 0)
        {
            mo.startOf('month').add(-1, 'years');
        }
        
        this.setState( { view_month: mo.format('YYYY-MM-DD') } );
    }
    
    render()
    {
        let s = this.state;
        
        let mo_selected = moment( s.date );
        
        let mo_view = moment(s.view_month);
        
        let first_d = mo_view.clone().startOf('month').format('YYYY-MM-DD');
        let last_d  = mo_view.clone().endOf('month').format('YYYY-MM-DD');

        let w_first = moment( first_d ).startOf('week');
        let w_last  = moment( last_d ).startOf('week');

        let month_name = mo_view.format('MMMM').toUpperCase();

        let mo_cu = moment(w_first);
    
        //console.log(w_first, ' -- ', w_last);
        
        let qq = [];
        

        for (let w = 0; w < 10; w ++ )
        {
            
            let q_d = [];
            
            for (let d = 0; d < 7; d ++ )
            {
                let fff = mo_cu.format('D');
                
                let is_in = mo_cu.isBetween(first_d, last_d, undefined, '[]');
                
                let clacla = 'zz_ca_cell';
                
                if (!is_in) clacla += ' off';
                
                let s_cu_date = mo_cu.format('YYYY-MM-DD');
                
                if (s_cu_date == mo_selected.format('YYYY-MM-DD')) clacla += ' cur_day';
                
                q_d.push( 
                
                <div key={'d' + d} data-kkk={s_cu_date} onClick={this.ClickCell} className={clacla} >{fff}</div>
                
                );
                
                mo_cu.add(1, 'days');
            }
            
            qq.push( <div style={{display: 'flex', justifyContent: 'center' }} key={'w'+w}>
                    
                        {q_d}
            
                    </div> );
                    
            if (  mo_cu.startOf('week').isSame(moment( last_d ).add(1, 'week').startOf('week') ) ) break;
        }
        
        let sel_date_disp = 'ВЫБРАНО: ' + mo_selected.format('DD.MM.YYYY');
        
        return (  <div className="zz_ca_base" >
        
                
                
                <div className="zz_ca_head">
                    <DaBtn2 sx={30} name="<<" cb_click={ (e) => this.ClickSwYear(-1) }/>
                    <DaBtn2 sx={30} name="<" cb_click={ (e) => this.ClickSw(-1) }/>
                        <div style={{width: '120px'}}>{month_name} {mo_view.format('YYYY')}</div>
                    <DaBtn2 sx={30} name=">" cb_click={ (e) => this.ClickSw(1) }/>
                    <DaBtn2 sx={30} name=">>" cb_click={ (e) => this.ClickSwYear(1) }/>
                </div>
                
                <div style={{minHeight: '120px'}}>
                { qq }
                </div>
                
                <div style={{marginTop: '10px', textAlign: 'center' }}>{sel_date_disp}</div>
                
                <div style={{display: 'flex', justifyContent: 'center', gap: '30px', marginTop: '20px', marginBottom: '10px' }}>
                    <DaBtn2 sx={100} name="ОТМЕНА"  cb_click={ (e) => this.ClickSel(0)  }/>
                    <DaBtn2 sx={100} name="ВЫБРАТЬ" cb_click={ (e) => this.ClickSel(1)  }/>
                </div>
                
                </div>);
    }
    
}







//-------------------------------------------------------

export class CmDateSelSeeker extends React.PureComponent
{
    constructor(props)
    {
        super(props);
        
        this.PreM = this.PreM.bind(this);
        this.NexM = this.NexM.bind(this);
        this.PreY = this.PreY.bind(this);
        this.NexY = this.NexY.bind(this);
    }
    
    
    PreY()
    {
        let mo = moment(this.props.dt);
        mo.startOf('month').add(-1, 'years');
        this.props.on_chg( mo.format('YYYY-MM-DD') );
    }
    
    NexY()
    {
        let mo = moment(this.props.dt);
        mo.endOf('month').add(1, 'years');
        this.props.on_chg( mo.format('YYYY-MM-DD') );
    }
    
    PreM()
    {
        let mo = moment(this.props.dt);
        mo.startOf('month').add(-1, 'days');
        this.props.on_chg( mo.format('YYYY-MM-DD') );
    }
    
    NexM()
    {
        let mo = moment(this.props.dt);
        mo.endOf('month').add(1, 'days');
        this.props.on_chg( mo.format('YYYY-MM-DD') );
    }
    
    render()
    {
        let dt = this.props.dt;
        
        let mo = moment(dt);
        
        let s_month = mo.format('MMMM').toUpperCase();
        let s_year  = mo.format('YYYY');
        
        return  <div className="zz_ca_head">
                    <DaBtn2 sx={30} name="<<" cb_click={ this.PreY }/>
                    <DaBtn2 sx={30} name="<"  cb_click={ this.PreM }/>
                        <div style={{width: '120px'}}>{s_month} {s_year}</div>
                    <DaBtn2 sx={30} name=">"  cb_click={ this.NexM }/>
                    <DaBtn2 sx={30} name=">>" cb_click={ this.NexY }/>
                </div>;
    }
}





export class CmMonthView extends React.PureComponent
{
    constructor(props)
    {
        super(props);
        
        this.ClickCell = this.ClickCell.bind(this);
    }
    
    ClickCell(e)
    {
        let kk = e.target.dataset.kkk;
        this.props.on_sel( kk );
    }
    
    render()
    {
        let mo_view = moment(this.props.view_date).startOf('month');
        
        let first_d = mo_view.clone().startOf('month').format('YYYY-MM-DD');
        let last_d  = mo_view.clone().endOf('month').format('YYYY-MM-DD');

        let w_first = moment( first_d ).startOf('week');
        let w_last  = moment( last_d ).startOf('week');
        
        let mo_cu = moment(w_first);
        
        let qq = [];
        
        

        for (let w = 0; w < 10; w ++ )
        {
            let q_d = [];
            
            for (let d = 0; d < 7; d ++ )
            {
                let fff = mo_cu.format('D');
                
                let is_in = mo_cu.isBetween(first_d, last_d, undefined, '[]');
                
                let clacla = 'zz_ca_cell';
                
                if (!is_in) clacla += ' off';
                
                let s_cu_date = mo_cu.format('YYYY-MM-DD');
                
                if      (s_cu_date == this.props.sel_date_a) clacla += ' cur_day_a';
                else if (s_cu_date == this.props.sel_date_b) clacla += ' cur_day_b';
                
                else if (this.props.sel_date_a && this.props.sel_date_b)
                {
                    // 
                    if ( mo_cu.isBetween(this.props.sel_date_a, this.props.sel_date_b, undefined, '[]') )
                    {
                        clacla += ' region_lite';
                    }
                }
                else
                {
                    if (this.props.sel_date_a &&  mo_cu.isAfter(this.props.sel_date_a, 'day'))
                    {
                       clacla += ' region_lite';
                    }
                    else if (this.props.sel_date_b &&  mo_cu.isBefore(this.props.sel_date_b, 'day'))
                    {
                       clacla += ' region_lite';
                    }
                }
                
                q_d.push(  <div key={'d' + d} data-kkk={s_cu_date} onClick={this.ClickCell} className={clacla} >{fff}</div> );
                
                mo_cu.add(1, 'days');
            }
            
            qq.push( <div style={{display: 'flex', justifyContent: 'center' }} key={'w'+w}>
                    
                        {q_d}
            
                    </div> );
                    
            if (  mo_cu.startOf('week').isSame(moment( last_d ).add(1, 'week').startOf('week') ) ) break;
        }
        
        return  <div style={{minHeight: '120px'}}>
                    { qq }
                </div>;
        
        
    }
}




/*
export class CmDateSelector2 extends React.Component
{
    
    constructor(props)
    {
        super(props);
        
        this.ClickCell  = this.ClickCell.bind(this);
        this.ClickStep  = this.ClickStep.bind(this);
        this.ClickClear = this.ClickClear.bind(this);
        
        this.OnCancel   = this.OnCancel.bind(this);
        this.OnOk       = this.OnOk.bind(this);
        
        this.OnChg      = this.OnChg.bind(this);
        
        let aaa = this.props.dt_a ? this.props.dt_a : null;
        let bbb = this.props.dt_b ? this.props.dt_b : null;
        
        let vvv = HLP.PickNonNull( [aaa, bbb, moment().format('YYYY-MM-DD')] );
        
        this.state = { dt_view: vvv,
                        dt_a: aaa,
                        dt_b: bbb,
                        step: 0 };
        
    }
    
    OnCancel()
    {
        this.props.on_cancel();
    }
    
    OnOk()
    {
        console.log('SELECTOR 2.. ON OK  %s %s', this.state.dt_a, this.state.dt_b);
        this.props.on_ok( this.state.dt_a, this.state.dt_b );
    }
    
    ClickStep(e)
    {
        let kkk = parseInt( e.target.dataset.kkk );
        this.setState( {step: kkk} );
    }
    
    ClickClear(e)
    {
        let kkk = parseInt( e.target.dataset.kkk );
        if (kkk === 0)
        {
            this.setState( {dt_a: null, step: 0} );
        }
        else if (kkk === 1)
        {
            this.setState( {dt_b: null, step: 1} );
        }
    }
    
    ClickCell(e)
    {
        if (this.state.step == 0)
        {
            
            if (this.state.dt_b === null)
            {
                this.setState( {step: 1} );
            }
            
            this.setState( {dt_a: e} );
            
            if (this.state.dt_b !== null && moment(this.state.dt_b).isBefore(e, 'day'))
            {
                console.log('clamped B (1)');
                this.setState( {dt_b: e} );
            }
        }
        else if (this.state.step == 1)
        {
            
            if (this.state.dt_a === null)
            {
                this.setState( {step: 0} );
            }
            else
            {
                if (moment(e).isBefore(this.state.dt_a, 'day'))
                {
                    e = this.state.dt_a;
                }
            }
            
            this.setState( {dt_b: e} );
        }
    }
    
    OnChg(e)
    {
        this.setState( {dt_view: e} );
    }
    
    render()
    {
        let s = this.state;
        
        let month_name = 'huy';
        
        let mo_view = moment(s.dt_view);
        
        let p_msg = [];
        
        let na = ["ОТ", "ДО"];
        
        for (let x = 0; x < 2; x ++ )
        {
            let d = x == 0 ? s.dt_a : s.dt_b;
            
            let cla = "zz_ca_info1";
            if (this.state.step == x) cla += ' cur';
            
            p_msg.push( <div key={x} >
                            <div className="zz_ca_info_bg">
                                <div onClick={this.ClickStep} data-kkk={x} className={cla}>
                                    {na[x]}: {d ? HLP.DateIsoToOur(d) : 'не указано'}
                                </div>
                                <div onClick={this.ClickClear} data-kkk={x} className="icon_cross10" style={{cursor: 'pointer', width: '10px', height: '10px'}}>
                                </div>
                            </div>
                        </div> );
        }
        
        
        
        return  <CmPopUp title="ДИАПАЗОН ДАТ" sx="500" sy="330"  on_cancel={this.OnCancel} >
                    <div className="zz_ca_base" >
        
                        <CmDateSelSeeker dt={s.dt_view} on_chg={this.OnChg} />
        
                        <CmMonthView
                                view_date={ s.dt_view}  on_sel={this.ClickCell}
                                sel_date_a={s.dt_a}
                                sel_date_b={s.dt_b}  />
                        
                        <div style={{marginTop: '10px', display: 'flex', justifyContent: 'center', gap: '6px' }}>
                            {p_msg}
                        </div>
                        
                        <div style={{display: 'flex', justifyContent: 'center', gap: '30px', marginTop: '20px', marginBottom: '10px' }}>
                            <DaBtn2 sx={100} name="ОТМЕНА"  cb_click={this.OnCancel}/>
                            <DaBtn2 sx={100} name="ОК"      cb_click={this.OnOk}/>
                        </div>
                    
                    </div>
                </CmPopUp>;
    }
    
    
}

*/



/*



export class CmListItem extends React.Component
{
    constructor(props)
    {
        super(props);
        this.OnSel = this.OnSel.bind( this );
    }
    
    OnSel(e)
    {
        this.props.on_sel( this.props.row );
    }
    
    render()
    {
        let p       = this.props;
        let fld_k   = p.fld_k;
        let fld_v   = p.fld_v;
        
        let cla = p.is_cur ? "zz_combo_item cur" : "zz_combo_item";
        
        // passed component / plain text
        let prt = p.cmp_item ? React.createElement(p.cmp_item, p.row, null) : p.row[fld_v];
        
        return (    <div className={cla} onClick={this.OnSel} >
                        { prt }
                    </div> );
    }
}


*/



/*

export class CmNewCombo extends React.Component
{
    constructor(props)
    {
        super(props);
        
        this.OnAct              = this.OnAct.bind(this);
        this.OnItemSel          = this.OnItemSel.bind(this);
        this.Clk                = this.Clk.bind(this);
        
        this.state = { opn: false };
    }
    
    OnItemSel(row)
    {
        let nu_val = row[ this.props.fld_k ];
        
        //console.log('ON ITEM SEL.. val: ', nu_val);
        
        this.setState( { opn: false } );
        
        if (this.props.on_sel) this.props.on_sel( row );
        else console.warn('COMBO on_sel -> not set');
    }
    
    OnAct()
    {
        //console.log('CLICK COMBO !');
        this.setState( { opn: !this.state.opn } );
    }
    
    
    
    componentDidMount() {
        document.addEventListener('click', this.Clk, true);
    }
    
    componentWillUnmount() {
        document.removeEventListener('click', this.Clk, true);
    }
    
    
    Clk(e)
    {
        const n = ReactDOM.findDOMNode(this);

        if (!n || !n.contains(e.target))
        {
            if (this.state.opn == true) // wtf ?
            {
                //console.log('set state open false..');
                this.setState( {opn: false} );
            }
        }
    }
    
    
    render()
    {
        //console.log('COMBO RENDER !', this.state, this.props);
        
        let s       = this.state;

                                
        let src     = this.props.src;
        let fld_k   = this.props.fld_k;
        let fld_v   = this.props.fld_v;
        
        
        let sel_str = '';
        
        let prts = [];
        
        for (let x = 0; x < src.length; x ++ )
        {
            let e = src[x];
            
            let vv = e[fld_v];
            let kk = e[fld_k];
            
            //console.log('KEY : ', kk);
            
            let is_cur = this.props.val == kk;
            
            if (is_cur) sel_str = vv;
            
            prts.push(  <CmListItem key={kk} row={ e } on_sel={this.OnItemSel}
                                             is_cur={is_cur}
                                             fld_k={this.props.fld_k}
                                             fld_v={this.props.fld_v} />  );
        }
        
        
        let prt_list;
        
        if (s.opn)
        {
            prt_list = ( <div className="zz_list">
                            {prts}
                         </div> );
        }
        else
        {
            prt_list = null;
        }

                 
        let clacla = this.props.disabled ? 'zz_combo disabled' : 'zz_combo';
        if (s.opn == false) clacla += ' zz_flash';
        
        
        let sty = { margin: '4px', minWidth: '130px', position: 'relative' };
        
        if (this.props.max_w) sty.maxWidth = this.props.max_w + 'px';
                 
        return (
        
            <div   style={sty}>
        
            <div onClick={this.OnAct} className={clacla} >
                <div style={{paddingLeft: '5px', flexGrow: 1}}>{sel_str}</div>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center',   height: '100%', width: '24px'}}>
                    <div className='zz_ic_ar2'></div>
                </div>
            </div>
        
            { prt_list }
        
            </div>
        
            );
    }
    
}


*/






export class CmSpinner extends React.PureComponent
{
    constructor(p)
    {
        super(p);
    }
    
    render()
    {
        let sz = this.props.sz;
        
        return  <div className="fl_center">
                    <span style={{width: sz, height: sz }} className="zz_spinner"></span>
                </div>;
    }
}


export function CmSpinner2(pp)
{
	const sz = pp.sz+'px';
	return <span style={{width: sz, height: sz }} className="zz_spinner"></span>;
}







/*


export class CmStatBar2 extends React.PureComponent
{
    constructor(props)
    {
        super(props);
    }
    
    
    
    render()
    {
		let va = this.props.val;
		let ma = this.props.max;
		let co = this.props.comp_val;
		
		let ww = Math.floor( (1.0 / ma) * 100 ) + '%';
		
		let p_ar = [];
		
		if (!co || co === va)
		{
			for (let x = 0; x < va; x ++ )
			{
				p_ar.push( <div key={x} className="elem" style={{width: ww}}></div> );
										
			}
		}
		else
		{
			if (co < va)
			{
				for (let x = 0; x < co; x ++ )
				{
					p_ar.push( <div key={x} className="elem" style={{width: ww}}></div> );
											
				}
				
				for (let x = co; x < va; x ++ )
				{
					p_ar.push( <div key={x} className="elem more" style={{width: ww}}></div> );
											
				}

			}
			else
			{
				for (let x = 0; x < va; x ++ )
				{
					p_ar.push( <div key={x} className="elem" style={{width: ww}}></div> );
											
				}
				
				for (let x = va; x < co; x ++ )
				{
					p_ar.push( <div key={x} className="elem less" style={{width: ww}}></div> );
											
				}
			}
		}
		
		return (
		
			<div className="zz_stat_bar2_base">
				{p_ar}
			</div>
		
		);
	}
	
	
}


*/


