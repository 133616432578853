import React from 'react';

import {DaIconBtn} from './icon_btn.js'; 

import { useApiQuery2 } from '../api_stuff.js';

import { NuPost2  } from '../api.js';
import { CommonReducer, ShowMsg, ShowErr } from '../misc_glob.js';

import { OverlayLoading } from './ovr_loading.js';

import DeleteIcon from '@mui/icons-material/Delete';

import { Win2PopTextLineEdit } from './win2_pop_line_edit.js';

import { CmCachedUserLink } from '../desktop/ca_user_link.js';

import { Win2Confirm } from '../misc/win2_confirm.js';


import * as HLP from '../helpers.js';
import * as DEFS from '../defs.js';

import * as DT from '../dt.js';

import { motion, useAnimate, stagger } from "framer-motion";


import { LayCenter, LayVert2 } from './layout.js'
import { DaVertScrollFrame, DaVertScrollJuice } from './vscr.js';
import { DaFaderTop, DaFaderBottom } from './fader.js';


import { STO_InvaUserMsg} from '../store.js';


import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';


import Popover from '@mui/material/Popover';

//-------------------------------------------------------


const gAN1 = {
    scale: [0.0, 1.0],
  };

  const gSpring = {
    type: "spring",
    damping: 20,
    stiffness: 300,
    delay: stagger(0.1),
  }




// ОДНО СООБЩЕНИЕ
function MyMsgImp(pp)
{

    const [anchorEl, setAnchorEl] = React.useState(null);


    
	
	const dt = DT.DateTimeStrLocHum(DT.DateTimeParseIso(pp.dt_cha))
	
	const RID = pp.rid;
	const TXT = pp.text;
    const DISABLED = pp.disabled;
	
	function cb_del()
	{
		//console.log('DEL ', RID);
		pp.onReqDel( RID );
	}
	
	function cb_edit()
	{
		pp.onReqEdit( RID, TXT );
	}
    
    
    
    function cb_show_pop(e)
    {
        setAnchorEl(e.currentTarget);
    }
	
    function cb_clo_pop()
    {
        setAnchorEl(null);
    }
    

    
    const open = null !== anchorEl;
    
    // 

	return 	(
        <>

            <motion.div className="zz_usr_msg"  transition={gSpring} animate={gAN1}   >
           
                                
                    <div style={{marginBottom: '6px', width: '100%', height: '16px', display: 'flex', justifyContent: 'space-between'}}>
                        <span style={{fontWeight: 600, color: 'var(--fl-clr-primary)'}}>
                            <CmCachedUserLink plain rid={pp.uid} />
                        </span>
                        
                        <div style={{display: 'flex', gap: '6px', color: '#606090', fontSize: '11px'}}>
                            <div>{dt}</div>
                            <DaIconBtn disabled={DISABLED} icon={MoreHorizIcon} tp_placement='top'  onClick={cb_show_pop} />
                        </div>
                    </div>
                    <div style={{whiteSpace: 'pre-wrap', wordWrap: 'break-word', padding: '6px'}}>{TXT}</div>

        
            </motion.div>

        <Popover open={open} anchorEl={anchorEl} onClose={cb_clo_pop} anchorOrigin={gOrg1} transformOrigin={gOrg2} >
                <div style={{padding: '16px', display: 'flex', gap: '10px', justifyContent: 'center'}}>
                    <DaIconBtn clr='var(--fl-clr-primary)' icon={EditIcon}      onClick={cb_edit}  tp_placement='top'  help='РЕДАКТИРОВАТЬ' />
                    <DaIconBtn clr='var(--fl-clr-primary)' icon={DeleteIcon}    onClick={cb_del}   tp_placement='top'  help='УДАЛИТЬ' />
                </div>
        </Popover>
        
        </>
        );
}


const gOrg1 = {vertical: 'bottom', horizontal: 'center'};
const gOrg2 = {vertical: 'top', horizontal: 'center'};


const MyMsg = React.memo( MyMsgImp );




//---------------------------------

const gSta1 = {
    
    pop_msg:        false,
    pop_conf:       false,
    
    dct_pop_conf:   {},
    dct_pop_msg:    {},
    
    busy:           false,
};

export function CmUserMessages( {ctx_t, ctx_id, club_id, disabled, title } )
{
    const [sta, dispatch] = React.useReducer( CommonReducer, gSta1 );
    

    
    
    const cb_clo_msg = React.useCallback( () =>
    {
        dispatch( {type: 'val', k: 'pop_msg', v: false} );
    }, [] );
    
    const cb_ok = React.useCallback( (ee) =>
    {
        const __ok = (ee) => {
            STO_InvaUserMsg(ctx_t, ctx_id);
            dispatch( {type: 'vals',  dct: {pop_msg: false, busy: false}} );
        };
    
        const __fail = (ee) => {
            
            ShowErr(ee.m);
            dispatch( {type: 'vals',  dct: {pop_msg: false, busy: false}} );
        };
        
        dispatch( {type: 'val', k: 'busy', v: true} );
        
        NuPost2( 705, {club_id, ctx_t, ctx_id, msg: ee}, __ok, __fail );
    }, [] );
    
    const cb_new_msg = React.useCallback( () =>
    {
        const ddd = { title: 'НОВОЕ СООБЩЕНИЕ', allowEmpty: false,  onOk: cb_ok,  onClose: cb_clo_msg };
        
        dispatch( {type: 'val', k: 'pop_msg', v: true} );
        dispatch( {type: 'val', k: 'dct_pop_msg', v: ddd} );
    }, [] );
    
    
	const cb_req_del = React.useCallback( (e) =>
	{
        const UMS_ID = e;
        
        const cb_close_pop = () =>
        {
            dispatch( {type: 'val', k: 'pop_conf', v: false} );
        };
        

		const cb_submit = (v) => {
			
			const __ok = (ee) => {
                dispatch( {type: 'val', k: 'busy', v: false} );
				STO_InvaUserMsg(ctx_t, ctx_id);
				dispatch( {type: 'val', k: 'pop_conf', v: false} );
			};

			const __fail = (ee) => {
                dispatch( {type: 'val', k: 'busy', v: false} );
				ShowErr(ee.m);
			};
            
            dispatch( {type: 'val', k: 'busy', v: true} );
			
			NuPost2( 706, {club_id, ctx_t, ctx_id, ums_id: UMS_ID}, __ok, __fail );
		};
        
        
        
        const ddd = {title: 'УДАЛИТЬ СООБЩЕНИЕ ?', onOk:cb_submit, onClose:cb_close_pop };
        
        dispatch( {type: 'val', k: 'pop_conf', v: true} );
        dispatch( {type: 'val', k: 'dct_pop_conf', v: ddd} );
        
	}, [] );
    
    
    
    
	const cb_req_edit = React.useCallback( (e,mm) => 
	{
		const UMS_ID = e;
		
		const cb_submit = (v) => {
			
			const __ok = (ee) => {
				STO_InvaUserMsg(ctx_t, ctx_id);
				dispatch( {type: 'val', k: 'pop_msg', v: false} );
			};

			const __fail = (ee) => {
				
				ShowErr(ee.m);
                dispatch( {type: 'val', k: 'pop_msg', v: false} );
			};
			
			NuPost2( 707, {club_id, ctx_t, ctx_id, msg: v, ums_id: UMS_ID}, __ok, __fail );
		};
        
        
        const ddd = { title: 'РЕДАКТИРОВАТЬ СООБЩЕНИЕ', onClose: cb_clo_msg, onOk: cb_submit, initial:mm };
        
        dispatch( {type: 'val', k: 'pop_msg', v: true} );
        dispatch( {type: 'val', k: 'dct_pop_msg', v: ddd} );
        

	
    }, [] );
	
    
    
    
    
	const { isFetching, isLoading, isError, data, error } = useApiQuery2( 704, {club_id, ctx_t, ctx_id}, {staleTime: 1000} );
	

	if (isError) return ( <div className="yy_error">{error}</div> );




    
    
    
	let ar = [];
	
	if (data)
	{
		for (let x = 0; x < data.rows.length; x ++ )
		{
			const e = data.rows[x];
			ar.push( <MyMsg key={e.ums_id}  disabled={disabled} rid={e.ums_id} uid={e.ums_user_id} 
                            text={e.ums_text} 
                            dt_cha={e.ums_dt_changed}
                            onReqEdit={cb_req_edit}
                            onReqDel={cb_req_del} /> );
		}
	}
    
    
    
    return (    <>
                <LayVert2 rows='50px 1fr' sx={{position: 'relative'}} >
                
                    <LayCenter>
                            {title ? <div className="zz_text_secondary">{title}</div> : null}
                            <DaIconBtn  disabled={disabled} icon={AddIcon} help='НОВОЕ СООБЩЕНИЕ' onClick={cb_new_msg}  tp_placement='top' />
                    </LayCenter> 
                
                    <div style={{width: '100%', height: '100%', position: 'relative'}}>
                        <DaVertScrollFrame>
                            <DaVertScrollJuice padding='12px'>
                            
                                {ar}
                              
                            </DaVertScrollJuice>
                        </DaVertScrollFrame>
                    
                        <DaFaderTop />
                        <DaFaderBottom />

                    </div>
    
    
                    <OverlayLoading active={!data || sta.busy} />
                
                </LayVert2>
                
                
                <Win2Confirm visible={sta.pop_conf} busy={sta.busy}  {...sta.dct_pop_conf}  />
                
                <Win2PopTextLineEdit visible={sta.pop_msg} busy={sta.busy}  {...sta.dct_pop_msg}  />
                </>  );
}




