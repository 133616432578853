import React from 'react';


import {DaIconBtn} from './icon_btn.js'
import {LayHoriz2} from './layout.js';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';


// value supported --->  true/false   0/1
function DaCheckImp( {disabled, name, label, value, onChange} )
{
    function cb_clk()
    {
        let nv;
        
        if      (value === false)   nv = true;
        else if (value === true)    nv = false;
        else if (value === 0)       nv = 1;
        else nv = 0;
        
        onChange( name, nv );
    }
    
    const cla = (value === true || value > 0) ? CheckBoxIcon : CheckBoxOutlineBlankIcon;
    
    const p_vis = <DaIconBtn onClick={cb_clk} disabled={disabled} icon={cla} clr='#6A4CD7' />;
    
    return <LayHoriz2 cols='max-content 1fr' gap='6px' sx={gSx9}>{p_vis}<span>{label}</span></LayHoriz2>;
}


const gSx9 = {height: '24px', alignItems: 'center'};

//-----------------

export const DaCheck = React.memo( DaCheckImp );
