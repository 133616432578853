import React from 'react';

import * as DEFS from '../defs.js';
import * as HLP from '../helpers.js'; 
import * as DT from '../dt.js';

import { Grid3 } from '../grid3.js';

import { DaButton } from '../misc/but.js';

import { WinTrpMod3 } from '../desktop/win_trp_mod.js';

import { LayHoriz2, LayVert2 } from '../misc/layout.js'

import { MAKE_ProdList2 } from '../cm_table_col_fmt.js';
import { MyStaticField } from '../cust_card/vi_overview.js';

import {  ShowMsg, ShowErr  } from '../v3/toast.js';

import { CmComColCellDateTime, CmComColUser, CmComColTrpStatus  } from '../cm_table_cell_com.js';

import { CmSpinner } from '../cm_base.js';

import { useApiQuery } from '../api_stuff.js';

import { useGlobalShit } from '../v3/hk_global_shit.js';

import CheckIcon from '@mui/icons-material/Check';

import { TrpAllowedHoursInfo } from '../desktop/allowed_hours.js';


import { EvActDispNameCSV } from '../desktop/ev_act.js';

import { useGlobEvActs  } from '../misc_glob.js';



//-------------------------

export function MyColTypes(pp)
{
    return ( <>
					<EvActDispNameCSV csv={pp.value} /> 
					<TrpAllowedHoursInfo jsonStr={pp.row.trp_allowed_hours_json} />
			</>);
}



const gColz  = [
  { sort: true,     align: 'center', 	fld: 'trp_id', 			name: 'ID', 		width: 90 },
  { sort: true,     align: 'center', 	fld: 'trp_status', 		name: 'Статус', 	width: 130, renderCell: CmComColTrpStatus },
  { sort: true,  	align: 'left', 		fld: 'trp_acts_csv', 	name: 'Движ', 	    width: 180, renderCell: MyColTypes },
  { sort: true,  	align: 'left', 		fld: 'ts_time_start', 	name: 'Дата', 	    width: 150, renderCell: CmComColCellDateTime },
  { sort: true,  	align: 'left', 		fld: 'ts_user_id', 		name: 'Тренер', 	width: 230, renderCell: CmComColUser  },
  { sort: false, 	align: 'left', 		fld: 'ts_impo_text', 	name: 'Коммент', 	width: 350 },
];





// раздел ТРЕНИ в окне абонемента (обычка)
export function ViTrainsNormal( {rid} )
{
	const { isFetching, isLoading, isError, data, error } = useApiQuery( 794, {pay_id: rid}, {staleTime: 5} );
	
	const [selected, setSelected]	= React.useState( [] );
	
	const [cachedRow, setCachedRow] = React.useState( null );
	
	const [pop, setPop] = React.useState( 0 );
	
	const [flt, setFlt] = React.useState( { pay_id:rid } );
    

	const gs    = useGlobalShit();

    
    const hk_act = useGlobEvActs();
    
	
	const cb_chg_sel = (nu, this_row) => {
		console.log('NU SEL.. ', nu, ' -- ', this_row);
		
		if (nu.length > 0)
		{
			setCachedRow( this_row );
		}
		else
		{
			setCachedRow( null );
		}
		
		setSelected(nu);
	};
	
	
	function cb_open_mod()
	{
		setPop(1);
	}
	
	function cb_hide_pop()
	{
		setPop(0);
	}
	
	

	
	
	
	let p_pop = null;
	
	if (pop === 1)
	{
		const __submit = (e) => {
			
			setPop(0);
			
			setFlt( {...flt} );
			
			setSelected( [] );
			
			//console.log('SUBMIT ! ', e);

			ShowMsg('УСПЕХ !');
			
		};
		
		//console.log('OPEN POP: ', cachedRow);
		
		p_pop = ( <WinTrpMod3  visible={true}   rid={cachedRow.trp_id} onOk={ __submit } onClose={ cb_hide_pop }/> );
	}
	
	
	let p_juice;
	
	if (isLoading || isFetching)
	{
		p_juice = <CmSpinner sz='64' />;
	}
	else if (isError)
	{
		// TODO...
		p_juice = <div className="yy_error" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>{error}</div>;   
	}
	else
	{
		const rr = data.row;
		
		const t_pro = MAKE_ProdList2(rr.v_prods_csv, rr.v_prods) ;

		let DIS_MOD = true;
		
		// HARDCODED SHIT !
		if ( cachedRow && cachedRow.trp_status === DEFS.TRP_STATUS_UNUSED && hk_act.testCanMod(cachedRow.trp_acts_csv) )
		{
			DIS_MOD = false;
		}
        
		/*
        if (sss.abilityArray.indexOf(104) === -1)
        {
            DIS_MOD = true;
        }
		*/

		if (!gs.userHasAbil(104))
		{
			DIS_MOD = true;
		}
		

		console.log('START ', rr.pay_start_time);

		const s_pay = DT.DateTimeStrLocHoursMinutes( DT.DateTimeParseIso( rr.pay_pay_time) ) || '---';
		const s_sta = DT.DateStrLoc( DT.DateParseIso( rr.pay_start_time) ) || '---';

		p_juice = (<>
		
					<LayVert2 rows='max-content 1fr max-content' padding='10px' gap='10px' >
		
						<div style={{paddingBottom: '20px', display: 'flex', gap: '4px'}}>
							<div style={{width: '50%'}}>
								<MyStaticField tit='Продукт:'  			val={t_pro} /> 
								<MyStaticField tit='Трень осталось:'  	val={rr.v_num_left} /> 
							</div>
							<div style={{width: '50%'}}>
								<MyStaticField tit='Куплен:'  			val={s_pay} /> 
								<MyStaticField tit='Старт:'  			val={s_sta} /> 
							</div>
						</div>

		
						<Grid3  singleSel cols={gColz}	uniq='trp_id'   sort_field='ts_time_start' sort_dir='desc'
								apiCmd={793}  flt={flt}  selected={selected}  onChangeSel={cb_chg_sel}    />
				
						<div style={{paddingTop: '6px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
							<DaButton disabled={DIS_MOD} onClick={cb_open_mod} icon={CheckIcon} title='МОД ТРЕНИ'/>
						</div>
				
					</LayVert2>

					{ p_pop  }

					</>);
					
	}
	
	
	return p_juice;
}

