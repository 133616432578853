import React from 'react';



// элемент списка - тренер с цветным квадратиком


function ListItemTrainerImp( {kk, vv, row, isCur, ext} )
{
    return (<div style={{padding: '6px', alignItems: 'center', display: 'flex', gap: '6px', flexDirection: 'row', fontSize: '16px'}} >
                <div style={{background: '#'+row.u_disp_rgb, width: '10px', height: '10px'}}></div>
                <div>{row.u_name}</div>
            </div>);
}

export const ListItemTrainer = React.memo( ListItemTrainerImp );

