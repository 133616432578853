import React from 'react';

import * as DEFS from '../defs.js';

import { ViCustomerCardFavTrainer2 } from './vi_fav_tra.js';
import { ViCustomerCardPaysView } from './vi_abons.js';
import { ViCustomerCardTrainsView } from './vi_trains.js';
//import { ViCustomerCardDetails } from './vi_details.js';
import { ViCustomerCardOverview } from './vi_overview.js';
//import { ViCustomerCardPairs2 } from './vi_pairs.js';
import { ViCustomerCardTechs } from './vi_techs.js';
import { ViCustomerReturns } from './vi_returns.js';
import { ViCustomerCardGoods } from './vi_goods.js';
import { ViCustomerBalance } from './vi_balance.js';
import { ViDetails2 } from './vi_details2.js';
import { ViTgBot } from './vi_tgbot.js';

import { ViCustCardCerts } from './vi_certs.js';

import { ViCustomerCardMisc1 } from './vi_misc.js';

import { ViBonuses } from './vi_bonus.js';



import { useGlobalShit } from '../v3/hk_global_shit.js';
//------------------------


import { ShowErr, ShowMsg } from '../misc_glob.js';

import { DaTabs } from '../misc/tabz.js';

import { Win2 } from '../misc/win2.js';
import { ShortName } from '../desktop/short_name.js';

//------------------------------




const gTabItemsCC = [

    { c:'main', 		n:'ГЛАВНАЯ' }, 
    { c:'abons', 		n:'АБОНЫ' },
	{ c:'trains', 		n:'ТРЕНИ' },
	{ c:'deta', 		n:'ДЕТАЛИ' },
	{ c:'favt', 		n:'ЛЮБ.ТРЕНЕР' },
	{ c:'techs', 		n:'ТЕХНИКИ' },
	{ c:'rets', 		n:'ВОЗВРАТЫ' },
    { c:'goods', 		n:'ТОВАРЫ' },
    { c:'balance', 		n:'БАЛАНС' },
	{ c:'bonu',		    n:'БОНУСЫ' },
	{ c:'tgbot',		n:'TG-BOT' },
	{ c:'misc1',		n:'РАЗНОЕ' },
	{ c:'certs',		n:'АКЦИИ' },
];


//-----------------------------------------------


export function WinCustCard( {visible, cuId, onClose, onMini} )
{
	const p_tit = <><span>КАРТОЧКА - #{cuId} - </span> <ShortName ctx={DEFS.SHORT_NAME_CUST} id={cuId} /></>

	function cb_clo()
	{
		onClose( cuId );
	}

	function cb_mini()
	{
		onMini( cuId );
	}

	return (
		<Win2 visible={visible} title={p_tit} width={1230} height={630}   onClose={cb_clo}  onMini={cb_mini} >
			<CustCardNuJuice rid={cuId}/>
		</Win2>
	);
}




// КАРТОЧКА КЛИЕНТА - все тэбы
function CustCardNuJuice( {rid} )
{
	const sss = useGlobalShit();

	const IS_MAST = sss.clubId < 1;

	

	if (rid === -1)
	{
		return null;
		//console.warn('CUST CARD RID -1 ?');
	}
	


	const dis = IS_MAST ? [ 'abons', 'trains', 'favt', 'rets', 'goods', 'balance' ] : [];

	
	return (

		<DaTabs items={gTabItemsCC} initCode='main' arDisCodes={dis}  >

			<ViCustomerCardOverview tabCode='main' rid={rid} isMaster={IS_MAST}/>  
			<ViCustomerCardPaysView tabCode='abons' rid={rid} />   
			<ViCustomerCardTrainsView tabCode='trains' rid={rid} />  
			<ViDetails2 tabCode='deta' rid={rid} />
			<ViCustomerCardFavTrainer2 tabCode='favt' rid={rid} />
			<ViCustomerCardTechs tabCode='techs' rid={rid} />
			<ViCustomerReturns tabCode='rets' rid={rid} />
			<ViCustomerCardGoods tabCode='goods' cu_id={rid} />
			<ViCustomerBalance tabCode='balance' cu_id={rid} />
			
			<ViBonuses tabCode='bonu' cu_id={rid} />

			<ViTgBot tabCode='tgbot' cu_id={rid} />

			<ViCustomerCardMisc1 tabCode='misc1' rid={rid} />

			<ViCustCardCerts tabCode='certs' cuId={rid} />

		</DaTabs>

	);
}

// 

/*



				<Tab label="ПАРЫ" />
                
                
				<MyTabPanel value={curTab} index={5}>
					<ViCustomerCardPairs2 rid={RID} />
				</MyTabPanel>
*/
