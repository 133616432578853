import React from 'react';

import * as DEFS from '../defs.js';

import * as COM_DEFS from '../com_defs.mjs';

import { ShowErr, ShowMsg } from '../misc_glob.js';
import { NuPost2 } from '../api.js';

import { EvBoundHow } from '../desktop/ev_act.js';

import { DaIconBtn } from '../misc/icon_btn.js';

import { CommonReducer } from '../misc_glob.js';

import { CmCachedUserLink } from '../desktop/ca_user_link.js';
 
import { DaButton } from '../misc/but.js';

import { Grid3 } from '../grid3.js';

import { CustNameShorten } from '../misc_glob.js';

import { WinEvent2 } from '../event/win_ev.js';

import { DaToolTip } from '../misc/tooltip.js';

import { LayHoriz2, LaySpace, LayVert2 } from '../misc/layout.js';


import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import CancelIcon from '@mui/icons-material/Cancel';

import { Win2Confirm } from '../misc/win2_confirm.js';

import * as DT from '../dt.js';

import { WinCustMassAssign } from '../desktop/win_cust_mass_assign.js';

//--------------------------------------

const gSM1 = {fontSize: '13px', color: '#607090'};


function MyCellComImp(p)
{
	let t = p.value;
	
	let num_bound = p.row.bound.length;
	
	//return num_bound;

	if (t.length < 1 && num_bound < 1) return null;
	
	let rr = [];
	
	if (num_bound > 0)
	{
		rr.push(<span key='xxx'>вместе с: </span>);
		
		for (let x = 0; x < p.row.bound.length; x ++ )
		{
			if (x !== 0) rr.push(<span key={'dv'+x}>, </span>);
			
			let e = p.row.bound[x];
			rr.push( <span key={e.cu_id}  >{CustNameShorten(e.cu_name)}</span>  );
		}
	}
	
	return (<div style={{display: 'flex', flexDirection: 'column'}}>
				<DaToolTip title={t}>
					<div style={{overflow:'hidden', whiteSpace:'nowrap'}}>{t}</div>
				</DaToolTip>
				
				<div style={gSM1} >{rr}</div>
				
			</div>);

}



const MyCellCom = React.memo( MyCellComImp );

//--------------------------------------------

function MyActsImp(pp)
{ 
	const ROW = pp.row;
	return <EvBoundHow evtAct={ROW.v_ts_act} trpActsCsv={ROW.v_acts} />;
}

const MyActs = React.memo( MyActsImp );

//---------

function MyColStartImp(pp)
{
    if (pp.value === null) return <div className="faint">---</div>;
    
    const dt 	= DT.DateTimeParseIso(pp.value);
    const s 	= DT.DateTimeStrLocHumSho(dt);
    const tit 	= DT.DateTimeStrLoc(dt);
    
    return <DaToolTip title={tit} >
				<div>{s}</div>
			</DaToolTip>;
}

const MyColStart = React.memo( MyColStartImp );

//---------


function MyColUser( pp )
{
    const SHOW_CHECK = pp.row.v_conc_tra > 0;
    return <CmCachedUserLink show_clr  show_check={SHOW_CHECK}  rid={pp.value} />;
}

//----

export function MyStatusImp(pp)
{
	//console.log('PP: ', pp);

	const NUM_B = pp.row.bound.length;

    const t = pp.value;

    if (t === 99) return <b className="red_shit">НЕ ПРИШЁЛ</b>;
    if (t === 98) return <b className="faint">---</b>;

	let ex = null;

	
	if (t === COM_DEFS.TRP_STATUS_BOUND && NUM_B === 0)
	{
		function __opn()
		{
			const dt 	= DT.DateTimeParseIso(pp.row.v_start);
			const s 	= DT.DateTimeStrLocHoursMinutes(dt);

			pp.ex.opn(pp.row.ts_id, s);
		}

		ex = <DaIconBtn onClick={__opn} clr='#E07070' sz={14} icon={CancelIcon} help='УДАЛИТЬ СОБЫТИЕ (КЛИЕНТ ТАМ ОДИН)' />;
	}
	
    
    return <div className='zz_trp_status99' style={{color: DEFS.gTrpStatusClr[t]}}>{DEFS.gTrpStatusDisp[t]}{ex}</div>
}


const MyStatus = React.memo( MyStatusImp );



//---------------------------------------

const gColTrains = [

  { sort: true,  align: 'center', fld: 'v_status', 	    name: 'Статус',         width: 150, renderCell: MyStatus},
  { sort: true,  align: 'center', fld: 'v_start', 	    name: 'Старт',          width: 130, renderCell: MyColStart},
  { sort: true,  align: 'left',   fld: 'v_acts', 		name: 'Движ',           width: 200, renderCell: MyActs},
  { sort: true,  align: 'left',   fld: 'v_user', 		name: 'Тренер',         width: 200, renderCell: MyColUser},
  { sort: false, align: 'left',   fld: 'v_comm', 		name: 'Коммент',        width: 350, renderCell: MyCellCom},
  { sort: true,  align: 'left',   fld: 'v_pay', 		name: 'Абон',           width: 260},
];



//----------------------------------------------------


const gSSS = {

	pop:	0,
	tsId:   0,
	infoStr: undefined,

	ser: 0,
	busy: false,

	seleted: [],

};

function useTrainsState()
{
	//console.log('useTrainsState()');

	const [sta, dispatch] = React.useReducer( CommonReducer, gSSS );

	function cb_opn(ts, dt)
	{
		//console.log('CB OP... ', ts);
		const DDD = {pop:1, tsId: ts, infoStr: 'Событие на ' + dt + ' будет удалено из календаря!'};
		dispatch( {type: 'vals', dct: DDD } );
	}

	function cb_clo()
	{
		dispatch( {type: 'vals', dct: {pop:0} } );
	}

	function cb_chg(nu)
	{
		dispatch( {type: 'val', k:'selected', v: nu } );
	}

	function cb_dbl(kk, rr)
	{
		dispatch( {type: 'val', k:'pop', v: 2 } );
		dispatch( {type: 'val', k:'tsId', v: rr.ts_id } );
	}

	function cb_ok()
	{
		dispatch( {type: 'val', k:'pop', v: 0} );
		dispatch( {type: 'val', k:'selected', v: []} );
		dispatch( {type: 'val', k:'ser', v: sta.ser + 1} );
	}

	function cb_mass()
	{
		dispatch( {type: 'val', k:'pop', v: 3 } );
	}

	function cb_del_evt()
	{
		//console.log('DEL EVT ! ', sta.tsId);

		dispatch( {type: 'vals', dct: {busy:true} } );

		function __ok(e)
		{
			dispatch( {type: 'vals', dct: {busy:false, pop: 0, ser: sta.ser+1} } );
			ShowMsg('УДАЛЕНО !');
		}

		function __fail(e)
		{
			ShowErr( e.m );
			dispatch( {type: 'vals', dct: {busy:false} } );
		}

		NuPost2( 4178, {tsId: sta.tsId}, __ok, __fail );
	}

	return {

		opn: cb_opn,
		clo: cb_clo,
		dbl: cb_dbl,
		chg: cb_chg,
		okok: cb_ok,
		delEvt: cb_del_evt,

		massAssign: cb_mass,

		ser : sta.ser,

		selected: sta.selected,

		tsId: sta.tsId,
		pop: sta.pop,
		busy: sta.busy,
		infoStr: sta.infoStr,
	};
}

//---------------------------------------



export function ViCustomerCardTrainsView( {rid} )
{


	const myCtl = useTrainsState( );


	const FLT = React.useMemo( () => {
        return { cu_id: rid };
    }, [rid, myCtl.ser] );

	

	return (<>
            <LayVert2 rows='max-content 1fr' padding='20px' gap='10px' >
			

				<LayHoriz2 cols='max-content 1fr' >
					<DaButton title='РАСКИДАТЬ ПО СОБЫТИЯМ' onClick={myCtl.massAssign} icon={EditCalendarIcon} />
					<LaySpace />
				</LayHoriz2>
	

                <Grid3 cols={gColTrains} apiCmd={791}  isNuApi   ex={myCtl}  onDblClick={myCtl.dbl} onChangeSel={myCtl.chg}  singleSel 
						 selected={myCtl.selected} 
                            uniq='v_key'  sort_field='v_start' sort_dir='desc' flt={ FLT }  code='cc_trains' />
                        
            </LayVert2>


			<Win2Confirm  busy={myCtl.busy} visible={myCtl.pop === 1}  title='ТОЧНО ?' onClose={ myCtl.clo } onOk={myCtl.delEvt}  >
				<div>{myCtl.infoStr}</div>
			</Win2Confirm>


			<WinEvent2 visible={myCtl.pop === 2} rid={myCtl.tsId}  onClose={myCtl.clo} onOk={myCtl.okok} />


			<WinCustMassAssign visible={myCtl.pop === 3} cuId={rid} onClose={myCtl.clo} />

			</>
			
	);
}


/*

	
*/