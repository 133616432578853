import React from 'react';

import { NuPost } from '../../api.js';

import { Grid3 } from '../../grid3.js';

import { DaButton } from '../../misc/but.js';

import { Win2Confirm } from '../../misc/win2_confirm.js';

import { LayVert2, LayHoriz2, LaySpace } from '../../misc/layout.js';

import { CmComColCellDateTime, CmComColCellDateFromDateTime, CmComColUser } from '../../cm_table_cell_com.js';

import {  ShowMsg, ShowErr, CommonReducer } from '../../misc_glob.js';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';


import { useGlobalShit } from '../../v3/hk_global_shit.js';
import { WinGoodsIncome } from '../../desktop/win_ingome_goods.js';

//----------------------------



function MyColEnts(pp)
{
	const v 	= pp.value;
	const num 	= v.length;
	
	if (num < 1)
	{
		return <div className="faint">ПУСТО</div>;
	}
	
	let cnt = 0;
	
	//let qq = [];
	
	for (let x = 0; x < num; x ++ )
	{
		const e = v[x];
		
		cnt += e.pgie_amount;
	}
	
	return <div style={{display: 'flex', gap: '6px'}}>{cnt} шт.</div>;
}

        
const gColz = [
  { sort: true,  align: 'center', fld: 'pgi_id', 		    name: 'ID', 			width: 80 },
  { sort: true,  align: 'center', fld: 'pgi_dt_change', 	name: 'Изменено', 	    width: 140, renderCell: CmComColCellDateTime },
  { sort: true,  align: 'center', fld: 'pgi_dt_when', 	    name: 'Дата', 		    width: 140, renderCell: CmComColCellDateFromDateTime },
  { sort: true,  align: 'left',   fld: 'pgi_user', 		    name: 'Юзер', 		    width: 200, renderCell: CmComColUser },
  { sort: false, align: 'left',   fld: 'v_ents', 			name: 'Инфо', 		    width: 120, renderCell: MyColEnts },
  { sort: false, align: 'left',   fld: 'pgi_comment', 	    name: 'Коммент', 		width: 390 },
];



const gState = {
  
    selected:       [],
    busy:           false,
    pop:            0,
    pop_dct_edit:   {},
    pop_dct_conf:   {},
    ser:            0,
    
};

        
        
// поступления товаров  РАЗДЕЛ
export function TabPrGoodsIncome( )
{
    const [sta, dispatch]   = React.useReducer( CommonReducer, gState );
    
    const sss               = useGlobalShit();
    
    
	const cb_chg_sel = React.useCallback( (nu) => {
		dispatch( {type: 'val', k: 'selected', v:nu} );
	}, [] );
	
    const cb_close = React.useCallback( () => {
        dispatch( {type: 'val', k: 'pop', v:0} );
    }, [] );
    
    function cb_nu()
    {
		function __ok()
		{
			dispatch( {type: 'val', k: 'ser', v:sta.ser+1} );
			dispatch( {type: 'val', k: 'pop', v:0} );
		}
		
		const ttt = { rid:-1, onClose:cb_close, onOk:__ok };
        
        dispatch( {type: 'val', k: 'pop', v:1} );
        dispatch( {type: 'val', k: 'pop_dct_edit', v:ttt} );
    }
    
	function cb_row_dbl(inf)
	{

        if (!sss.userHasAbil(601)) return;
        
		const ID =  sta.selected[0];
		
		function __ok()
		{
			dispatch( {type: 'val', k: 'ser', v:sta.ser+1} );
			dispatch( {type: 'val', k: 'pop', v:0} );
		}
		
		const ttt = { rid:ID, onClose:cb_close, onOk:__ok };
        
        dispatch( {type: 'val', k: 'pop', v:1} );
        dispatch( {type: 'val', k: 'pop_dct_edit', v:ttt} );
	}

	function cb_del()
	{
		const ID =  sta.selected[0];
		
        function __conf()
        {
            dispatch( {type: 'val', k: 'busy', v:true} );
            
			function __ok(e)
			{
				dispatch( {type: 'val', k: 'busy', v:false} );
                dispatch( {type: 'val', k: 'ser', v:sta.ser+1} );
                dispatch( {type: 'val', k: 'pop', v:0} );
                dispatch( {type: 'val', k: 'selected', v:[]} );
				ShowMsg('ПОСТАВКА УДАЛЕНА. ' + e.total_cnt + ' ТОВАРОВ УДАЛЕНО !');
			}
			
			function __fail(e)
			{
				ShowErr(e.m);
				dispatch( {type: 'val', k: 'busy', v:false} );
			}
			
			NuPost( 1004, {rid:ID}, __ok, __fail );
        }
        

		const ttt = { title: 'ТОЧНО УДАЛИТЬ ?', onClose: cb_close, onOk: __conf };
        
        dispatch( {type: 'val', k: 'pop', v:2} );
        dispatch( {type: 'val', k: 'pop_dct_conf', v:ttt} );
	}
    

    
    
    const FLT = React.useMemo( () => {
        return {};
    }, [sta.ser] );
    
    
    const DIS_ADD = (!sss.userHasAbil(601));
	const DIS_DEL = (!sss.userHasAbil(601)) || sta.selected.length < 1;
    
    return (
        <>
        <LayVert2 rows='max-content 1fr' padding='16px' gap='10px' >
        
            <LayHoriz2 cols='max-content max-content 1fr' gap='16px'  >
                <DaButton disabled={DIS_ADD}  title='НОВАЯ ПОСТАВКА'   icon={AddIcon} onClick={cb_nu} />
                <DaButton disabled={DIS_DEL}  title='УДАЛИТЬ'          icon={RemoveIcon} onClick={cb_del} />
                <LaySpace />
            </LayHoriz2>
        

                                
            <Grid3 singleSel onDblClick={cb_row_dbl} onChangeSel={cb_chg_sel} 
                            selected={sta.selected}  cols={gColz} apiCmd={1000}
                            uniq='pgi_id'  sort_field='pgi_dt_change' sort_dir='desc' flt={ FLT }  code='goods_income'/>
        
        </LayVert2>
        
        <WinGoodsIncome                 visible={sta.pop===1} {...sta.pop_dct_edit} />
        
        <Win2Confirm busy={sta.busy}    visible={sta.pop===2} {...sta.pop_dct_conf} >
            <span>Эти товары удалятся из стока !</span>
        </Win2Confirm>
        
        </>
    
    );
}
