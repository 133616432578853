import React from 'react';

import * as DEFS from '../defs.js'
import * as DT    from '../dt.js';
import * as HLP from '../helpers.js';


import { NuPost2 } from '../api.js';


import { WinHelpConta, WinHelpElem } from '../misc/win_help.js'

import { DaButton } from '../misc/but.js';

import { BottomButtonsPanel } from '../cust_card/bottom_pan.js';

import { CommonReducer, ShowMsg, ShowErr } from '../misc_glob.js';

import { Win2 } from '../misc/win2.js';

import { WinCuReqPicker } from './win_cu_req_picker.js';

import { OverlayLoading } from '../misc/ovr_loading.js';

import { DaCombo } from '../misc/combo.js';
import { DaDateField } from '../misc/date_field.js';
import { DaTextField } from '../misc/text_field.js';
import { LayVert2, LayHoriz2 } from '../misc/layout.js';

import { ClubListCombo } from './club_list_combo.js';

import CheckIcon from '@mui/icons-material/Check';

import LinkIcon from '@mui/icons-material/Link';

import { CmComboCustSrc } from '../cm_cust_src.js';

import { VertButtonGrp } from '../misc/vert_btn_grp';

import { useGlobalShit } from '../v3/hk_global_shit.js';


function GenMySta( {fill, club_id} )
{
    const r = {
  
        na:     '',
        ph:     '',
        cta:    '',
        dob:    null,
        oi:     1,
        gend:   DEFS.CU_GENDER_UNK,
        
        desire: '',
        exp:    '',
        med:    '',
        
        busy:   false,

        club:       club_id,
        
        show_req_pick: false,
        link_with_cureq: -1,
    };
    
    
    if (fill)
    {
        r.na = fill.name;
        r.ph = fill.phone;
        r.oi = fill.org;
    }
    
    return r;
}


function __ProcPhone(s)
{
    s = s.replaceAll(' ', '');
    s = s.replaceAll('(', '');
    s = s.replaceAll(')', '');
    s = s.replaceAll('-', '');
    return s;
}




//---------------------------------------------

function MyJuice( {onOk, fill, club_id} )
{
    const [sta, dispatch] = React.useReducer( CommonReducer, {fill, club_id}, GenMySta );


    
    const cb_chg = React.useCallback( (k,v) => {
        
        dispatch( {type: 'val', k:k, v:v} );
        
    }, [] );
    
    
    const cb_submit = () => {
        
        //console.log('GO');
        
        function __ok(e)
        {
            //console.log('ADDED ! ', e);
            dispatch( {type: 'val', k:'busy', v:false} );
            
            const fff = { orig_ind: sta.oi, n: sta.na, p: sta.ph, o: '', row_id: e.row_id };
        
            onOk(fff);
        }
        
        function __fail(e)
        {
            ShowErr(e.m);
            dispatch( {type: 'val', k:'busy', v:false} );
        }
        
        
        
        dispatch( {type: 'val', k:'busy', v:true} );
        
        const o = { nam:        sta.na.trim(),
                    orig_ind:   sta.oi,
                    cta:        sta.cta,
                    clcl:       sta.club,
                    gend:       sta.gend,
                    pho:        sta.ph.trim(),
                    med:        sta.med.trim(),
                    desire:     sta.desire.trim(),
                    exp:        sta.exp.trim(),
                    dob:        DT.DateStrIso(sta.dob),
                    cureq:      sta.link_with_cureq };
        
        NuPost2( 710, o, __ok, __fail );
        
    };
    
    
    const cb_clo_req_pick = React.useCallback( () => {
        dispatch( {type: 'val', k:'show_req_pick', v:false} );
    }, [] );
    
    const cb_show_req_pick = React.useCallback( () => {
        dispatch( {type: 'val', k:'show_req_pick', v:true} );
    }, [] );
    
    const cb_choose_req = (row, copyFields) => {
      
        dispatch( {type: 'val', k:'show_req_pick', v:false} );
      
        if (copyFields)
        {
            dispatch( {type: 'val', k:'na', v:row.cre_name} );
            dispatch( {type: 'val', k:'ph', v:__ProcPhone(row.cre_phone) } );
            dispatch( {type: 'val', k:'oi', v:row.cre_orig} );
        }

        dispatch( {type: 'val', k:'link_with_cureq', v:row.cre_id} );
        
        
    };
    
    
    const GOT_MIX_NA = HLP.StrGotMixedAlphaBets(sta.na);
    
    const WARN_NA = sta.na.trim() === '' || GOT_MIX_NA;
    const WARN_PH = sta.ph.trim() === '';
    const WARN_C  = sta.club < 1;
    
    const DIS_SUBMIT = WARN_NA || WARN_PH || WARN_C;
    
    
    let p_bound_with_cureq;
    
    if (sta.link_with_cureq !== -1)
    {
        p_bound_with_cureq = <div style={{color: '#30A020', fontWeight: 800}}>- Будет связан с заявкой {sta.link_with_cureq}</div>;
    }
    else 
    {
        p_bound_with_cureq = <div></div>;
    }
    
    return (
        
        <>
        
        <LayVert2 rows='1fr max-content'>
            <LayHoriz2 cols='400px 1fr'  padding='16px'  gap='10px'>
                <LayVert2 rows='repeat(7, max-content)' gap='16px' >
                        <DaTextField name='na'  value={sta.na}  onChange={cb_chg} label={GOT_MIX_NA ? 'ФИО - СМЕШАННЫЙ АЛФАВИТ !!' : 'ФИО'}  error={WARN_NA} />
                        <DaDateField name='dob' label='ДАТА РОЖДЕНИЯ' value={sta.dob} onChange={ cb_chg } />
                        <DaTextField name='ph'  value={sta.ph}  onChange={cb_chg} label='ТЕЛЕФОН' error={WARN_PH} />
                        <CmComboCustSrc name='oi' value={sta.oi} onChange={cb_chg} label='ИСТОЧНИК (ОТКУДА ПРИШЁЛ)' />
                        <DaTextField name='cta' value={sta.cta} onChange={cb_chg} label='КОНТАКТНОЕ ЛИЦО (ТЕЛЕФОН МАМАЫ/ПАПЫ)' />
                        <DaCombo name='gend' label='ПОЛ' fld_k='k' fld_v='v'    value={sta.gend} onChange={cb_chg}  src={DEFS.gSrcGender}   />
                        <ClubListCombo name='club' label='СТАРТОВЫЙ КЛУБ' value={sta.club} onChange={cb_chg} error={WARN_C} />
                        {p_bound_with_cureq}
                </LayVert2>
                <LayVert2 rows='1fr 1fr 1fr'   gap='20px'  >
                
                        <DaTextField name='desire'  value={sta.desire}  multiline onChange={cb_chg} label='ЦЕЛИ ТРЕНИРОВОК' />
                        <DaTextField name='exp'     value={sta.exp}     multiline onChange={cb_chg} label='УРОВЕНЬ / ОПЫТ В СПОРТЕ' />
    
                        <CmMuiCustExField value={sta.med} name='med' onChange={cb_chg} label='МЕДИЦИНСКИЕ ПРОТИВОПОКАЗАНИЯ' />
    

                </LayVert2>
            </LayHoriz2>
            
            
            <BottomButtonsPanel>
                <DaButton                       onClick={cb_show_req_pick}  title='СВЯЗАТЬ С ЗАЯВКОЙ' icon={ LinkIcon } />
                <DaButton disabled={DIS_SUBMIT} onClick={cb_submit}  title='СОЗДАТЬ' icon={ CheckIcon } />
            </BottomButtonsPanel>
    
        </LayVert2>
        
        
        <OverlayLoading active={sta.busy} />
        
        <WinCuReqPicker visible={sta.show_req_pick} onClose={cb_clo_req_pick} onOk={cb_choose_req} /> 
        </>
    );
}



const gHelp1 = (<WinHelpConta>
    <WinHelpElem tit='СТАРТОВЫЙ КЛУБ' text='В каком клубе получит ПРОБНИК.' />
    <WinHelpElem tit='СВЯЗАТЬ С ЗАЯВКОЙ' text='Отметить из какой заявки получился клиент. (Можно выставить позже в карточке клиента)' />
</WinHelpConta>);



export function WinNewCust( {visible, onClose, onOk, title, fill} )
{
    const sss = useGlobalShit();

    const CCC = sss.clubId;

    return (
    
            <Win2 visible={visible} title={title ? title : 'НОВЫЙ КЛИЕНТ'} width={930} height={500}  onClose={onClose}  help={gHelp1} >
                <MyJuice onOk={onOk} fill={fill} club_id={CCC} />
            </Win2>
    
    );
}



const __src = [
	
	{val: 0, name: '???'},
	{val: 1, name: 'ОТСУТСТВУЮТ'},
	{val: 2, name: 'ЕСТЬ'},

];




export function CmMuiCustExField( {name, value, label, onChange} )
{
    const [valText, setValText] = React.useState( value );
    const [isFoc, setIsFoc]     = React.useState( false );

	let m;
	let txt;

	
	if (isFoc)
	{
		m = 2;
		txt = valText;
	}
	else
	{
		if (value === DEFS.CUST_FIELD_VAL_NONE)
		{
			m = 1;
			txt = '';
		}
		else if (value === '')
		{
			m = 0;
			txt = '';
		}
		else 
		{
			m = 2;
			txt = value;
		}
	}
	


    const rf = React.useRef(null);

	
	function cb_chg_txt(k,v)
	{
        
		setValText( v );
	}


    function on_btn(ee)
    {
        //console.log('BTN ! ', ee);

        if (ee === 0)
        {
            setValText('');
            onChange(name, '');
            if (rf.current) rf.current.blur();
        }
        else if (ee === 1)
        {
			onChange( name, DEFS.CUST_FIELD_VAL_NONE );
			if (rf.current) rf.current.blur();
        }
        else if (ee === 2)
        {
            if (rf.current)
            {
                rf.current.disabled = false;
                rf.current.focus();
            }
            setValText('');
            onChange(name, '');
            setIsFoc(true);
            m = 2;  // hack shit
        }
    }

	function cb_blur()
	{
		setIsFoc(false);
		onChange( name, valText );
	}
	
	function cb_foc()
	{
		setIsFoc(true);
		onChange( name, txt );
	}

    const p_btns = <VertButtonGrp src={__src}  value={m} onClick={on_btn} />


    const DIS = m<2;

    return (

        <DaTextField ref={rf} disabled={DIS} multiline value={txt} label={label} onFocus={cb_foc} onBlur={cb_blur} onChange={cb_chg_txt} embRight={p_btns} />
    );
}
