import React from 'react';

import { Win2 } from '../misc/win2.js';


import { WinNewCust } from './win_new_cust.js';

import { LayHoriz2, LayVert2 } from '../misc/layout.js'

import { DaButton } from '../misc/but.js';


import { BottomButtonsPanel } from '../cust_card/bottom_pan.js';


import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';


import {ListItemCustNameAndPhone} from './li_cu_name_and_phone.js';
import { DaAutoComplete } from '../misc/autocomp.js';

import { useGlobalShit } from '../v3/hk_global_shit.js';

//---------------------------------------


function MyBody( {onOk, hideNewCust} )
{
    const [val, setVal]         = React.useState( null );
    const [pop, setPop]         = React.useState( false );
    const [initRow, setInitRow] = React.useState( null );
    
    
    const sss = useGlobalShit();
    
    
    function cb_sel()
    {
        onOk( val );
    }
    
    function cb_chg(k, v)
    {
        setVal(v);
    }
    
    function cb_new_cust()
    {
        setPop(true);
    }
    
    function cb_hide_pop()
    {
        setPop(false);
    }
    
    function cb_new_cust_conf( a_oo )
    {
        setPop(false);

        setInitRow( {cu_id: a_oo.row_id, cu_name: a_oo.n, cu_phone: a_oo.p} );
        setVal( a_oo.row_id );
        
    }
    
    //---
    
    
    const DIS_NEW_CUST   = !sss.userHasAbil( 100 );
    
    let p_new = null;
    
    if (!hideNewCust)
    {
        p_new = <DaButton  disabled={DIS_NEW_CUST}  onClick={cb_new_cust} title='НОВЫЙ КЛИЕНТ' icon={ AddIcon } />;
    }
    
    return (
        <>
        <LayVert2 rows='1fr max-content' >
            
            <div style={{padding: '16px'}}>
                <DaAutoComplete label='ПО ИМЕНИ / ТЕЛЕФОНУ' value={val} onChange={cb_chg} initialRow={initRow}
                        apiCmd={780}  itemCmp={ListItemCustNameAndPhone} 
                        fld_k='cu_id' fld_v='cu_name' />
            </div>
            
            <BottomButtonsPanel>
                {p_new}
                <DaButton  disabled={val===null}    onClick={cb_sel} title='ВЫБРАТЬ' icon={ CheckIcon } />
            </BottomButtonsPanel>
        
        </LayVert2>
        
        <WinNewCust visible={pop} onOk={cb_new_cust_conf} onClose={cb_hide_pop} />
        
        </>
    
    );
}

// поп ап - поиск клиента
export function WinCustomerSearch( {visible, onOk, onClose, hideNewCust, title = 'НАЙТИ КЛИЕНТА'} )
{    
	return ( <Win2 visible={visible} title={title} width={560} height={160}   onClose={onClose} >
                <MyBody onOk={onOk} hideNewCust={hideNewCust} />
			</Win2>
		);
}
