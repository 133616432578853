 
import React from 'react';

import { LayVert2, LayHoriz2, LaySpace } from '../misc/layout.js';

import { Grid3 } from '../grid3.js';

import { HowPayedInfo } from './how_payed.js';

import { EvActDispNameCSV } from '../desktop/ev_act.js';
import { ShortName } from './short_name.js';

import { CmComColCellDateTime  } from '../cm_table_cell_com.js';

import * as DEFS from '../defs.js';
import * as COM_DEFS from '../com_defs.mjs';

//-----------------------------------

function MyColWasImp( p )
{
    const ROW = p.row;

    return (
        <div style={{fontSize: '12px'}}>
            <div>Продукт: <ShortName ctx={DEFS.SHORT_NAME_PROD} id={ ROW.trpm_orig_prod_id } /></div>
            <EvActDispNameCSV csv={ROW.trpm_orig_acts} />
        </div>
    );
}

const MyColWas = React.memo( MyColWasImp );

//-----------------------------------------

function MyColHow( p )
{
    const ROW = p.row;
    return <HowPayedInfo bindCtx={COM_DEFS.PAY_OPS_CTX_MOD_TRP} bindId={ROW.trp_id}/>;
}

//---

function MyColNowImp( p )
{
    const ROW = p.row;

    return (
        <div style={{fontSize: '12px'}}>
            <div>Продукт: <ShortName ctx={DEFS.SHORT_NAME_PROD} id={ ROW.trp_from_prod_id } /></div>
            <EvActDispNameCSV csv={ROW.trp_acts_csv} />
        </div>
    );
}

//---------------------------------------

const MyColNow = React.memo( MyColNowImp );


const gColz  = [
    { sort: true,     align: 'center', 	fld: 'trpm_id', 		name: 'ID', 		width: 90 },
    { sort: true,     align: 'center', 	fld: 'trp_dt_changed', 	name: 'Изменено', 		width: 120,  renderCell: CmComColCellDateTime  },
    { sort: false,    align: 'center', 	fld: 'trpm_money_chrg', 		name: 'Доплата', 		width: 160 },
    { sort: false,    align: 'left', 	fld: 'v_how_payed', 		name: 'Оплата', 		width: 200, renderCell: MyColHow },
    { sort: false,    align: 'left', 	fld: 'trpm_orig_acts', 		name: 'Было', 		width: 200, renderCell: MyColWas },
    { sort: false,    align: 'left', 	fld: 'trp_acts_csv', 		name: 'Стало', 		width: 200, renderCell: MyColNow },
    { sort: false, 	  align: 'left', 	fld: 'trpm_comment', 	name: 'Коммент', 	width: 350 },

  ];
  
  

export function TabMods( {rid} )
{
    const FLT = React.useMemo( () => {
        return { pay_id: rid };
    }, [] );

    return (
        <LayVert2 padding='10px' rows='1fr' >
            <Grid3  singleSel cols={gColz}	uniq='trpm_id'   sort_field='trpm_id' sort_dir='asc'  apiCmd={1092}  flt={FLT}   />
        </LayVert2>

    );
}