import React from 'react';

import useRipple from "./useRipple.tsx";
 

//--------------------------------------------------



function ItemJuiceDefaultImp( {kk, vv, row, isCur, ext} )
{
    return (<div className='yy_list_itj_default' >
                {vv}
            </div>);
}

export const ItemJuiceDefault = React.memo( ItemJuiceDefaultImp );

//---------------------------------------------------------


function MyItemContainer( {kk, vv, row, isCur, onClick, itemCmp, ext} )
{
    const ref         = React.useRef(null);

    const ripples     = useRipple(ref);
    
    function on_clk()
    {
        onClick(kk, row);
    }
    
    if (!itemCmp) itemCmp = ItemJuiceDefault; 
    
    const j = React.createElement(itemCmp, {kk:kk, vv:vv, row:row, isCur:isCur, ext:ext}, null);
    
    return (<div ref={ref} onClick={on_clk} className={isCur ? "yy_list_it cur" : "yy_list_it"}>
                {ripples}
                {j}
            </div>);
}

//------------------------------------

const MY_PAD = '8px';

export function DaList2( {disabled, name, value, src, itemCmp, fld_k, fld_v, ext, onChange, noFlex} )
{
    
    const cb_clk = React.useCallback( (k, row) => {
        if (onChange) onChange( name, k, row );
    }, [onChange] );
    
    
    
    if (!fld_k) fld_k = 'k';
    if (!fld_v) fld_v = 'v';
    
    let ar = [];
    
    const NUM = src.length;
    
    for (let x = 0; x < NUM; x ++ )
    {
        const e = src[x];
        const kk = e[fld_k];
        const vv = e[fld_v];
        ar.push( <MyItemContainer key={kk} ext={ext} row={e} kk={kk} vv={vv} isCur={value===kk} itemCmp={itemCmp} onClick={cb_clk} />  );
    }
    
    
    if (noFlex)
    {
        return (			<div style={{padding: MY_PAD}}>
                                {ar}
                            </div>);
    }
    
    return (
		<div style={{ position: 'relative', width: '100%', height: '100%'}}>
			<div style={{padding: MY_PAD, overflowX: 'hidden', overflowY: 'scroll', position: 'absolute', width: '100%', height: '100%'}}>
				{ar}
			</div>
		</div>
    );
}




