 


export function OverlayErrMsg( {active, msg} )
{
	if (!active) return null;
	
	return (

		<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#900000', fontWeight: 800, background: '#FFD0D0A0', left: 0, top: 0, width: '100%', height: '100%', position: 'absolute'}}>
			{msg}
		</div>
	);
}
