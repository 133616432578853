import React from 'react';

import { LayVert2, LayHoriz2, LaySpace } from '../misc/layout.js';

import {DaIconBtn} from '../misc/icon_btn.js'; 



import * as DEFS from '../defs.js';
import * as COM_DEFS from '../com_defs.mjs';
import * as HLP from '../helpers.js'; 

import * as DT from '../dt.js';


import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';


import { DaSwitcherNumber } from '../misc/sw_number.js';


//----------------------------------
 
 
 
// одна плашка с продуктом и количеством
function ProdEntryImp( {row, cnt, ind, onChgCnt, onRemove} )
{
	const nam 			= row.pr_name;
	const can_tweak 	= row.pr_logic === COM_DEFS.ABON_LOGIC_NORMAL;

	
	function cb_switch_cnt(k,v)
	{
		onChgCnt(ind, v);
	}
	
	function cb_remove()
	{
		onRemove(ind);
	}
	
	const p_remove = onRemove ? (<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
									<DaIconBtn  icon={CloseIcon} help='УБРАТЬ ПОЗИЦИЮ' onClick={cb_remove} clr='#A03020' />
								</div> ) : <LaySpace />;
			
			
	const DIS_SWITCH = !onRemove;	// ! TODO
	

    const p_switch = can_tweak ?
                        (<DaSwitcherNumber value={cnt} min={1} max={99} onChange={cb_switch_cnt} disabled={DIS_SWITCH} />)
                        :
                        <LaySpace />;



    


    return (

        <div className="pla_pay_prod_entry">
            <div>{nam}</div>
            {p_switch}
            {p_remove}
        </div>

    );


}
 
 
export const ProdEntry = React.memo( ProdEntryImp );
 
 
