import React from 'react';

import * as DEFS from '../defs.js';

import { useApiQuery2 } from '../api_stuff.js';

import {DaToolTip} from '../misc/tooltip.js';

import { CmComColTrpStatus } from '../cm_table_cell_com.js';

import { AllowerHoursIcon } from '../desktop/allowed_hours.js';

import { EvActDispNameCSV } from '../desktop/ev_act.js';


import { MdQuiz } from "react-icons/md";




//-------------------------------------------


// остатки по абонементу ( в таблах )
export function CaAbonLeftovers( {rid} )
{
	const { isLoading, isError, data, error } = useApiQuery2( 815, {rid:rid} );
	
	if (isLoading) return '';
	if (isError) return '';
	
	const rows = data?.rows;
    
    if (!rows) return '';
    
    let num = 0;
    const TOTAL = rows.length;
    
    
    let ar = [];
    
    // style={{padding: '4px', whiteSpace:'nowrap', alignItems: 'center', display: 'flex', gap: '10px', fontSize: '18px'}} 
    
    for (let x = 0; x < rows.length; x ++ )
    {
        const e = rows[x];
        
        if (e.trp_status === DEFS.TRP_STATUS_UNUSED || e.trp_status === DEFS.TRP_STATUS_BOUND ) num ++;
        

        ar.push( <React.Fragment key={x} >
                        <CmComColTrpStatus value={e.trp_status} />
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <EvActDispNameCSV csv={e.trp_acts_csv} />
                            {e.trp_allowed_hours_json !== '' ? <AllowerHoursIcon /> : null}
                        </div>
                    </React.Fragment> );
        
    }
	

    const p_t = (<div style={gSSS}>{ar}</div>);

    return (
        <>
        <span style={gST99}>{num} / {TOTAL}</span>
        <DaToolTip title={p_t}>
            <MdQuiz style={gIc1}/>
        </DaToolTip>
        

        
        </>
    
    );
}


const gST99 = {whiteSpace: 'nowrap', width: '46px'};

const gIc1 = {color: '#5090F0', fontSize: '19px'};


const gSSS = {color: '#F0F0FF', gap: '6px', display: 'grid', minWidth: '400px', gridTemplateColumns: '120px 350px' };
