import React from 'react';

import * as HLP from '../helpers.js';

import Popover from '@mui/material/Popover'
import { DaTextField } from '../misc/text_field.js';

import {DaList2} from '../misc/list2.js';

import { NuPost2 } from '../api.js';

import {DaIconBtn} from './icon_btn.js'; 

import { useDebouncedCallback } from 'use-debounce';


import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

 
 

//-------------------------------------



export function DaAutoComplete( {name, value, disabled, label, fld_k, fld_v, onChange, itemCmp, initialRow, apiCmd} )
{
    const [txt, setTxt] = React.useState( '' );
    const [wdt, setWdt] = React.useState( 100 );
    
    const [anchorEl, setAnchorEl] = React.useState(null);
    
    const [list, setList] = React.useState( initialRow ? [initialRow] : [] );
    
    //console.log('DA-AUTOCOMPLETE.. initial: ', initialRow);
    
    React.useEffect( () => {
        
        if (!initialRow) return;
        if (list.length === 1 && list[0] === initialRow) return;
        
        setList( [initialRow] );
        
    }, [list, initialRow] );
    
    
    const debouncedReq = useDebouncedCallback( () => {
      
            const on_fail = (e) =>
            {
                //ShowErr('FAIL: ', e.m);
            };
            
            const on_ok = (e) =>
            {
                setList( e.rows );
            };
            
            NuPost2( apiCmd, {q: txt}, on_ok, on_fail );
        
    }, 320 );
    

    
	React.useEffect(() => {

        if (anchorEl === null) return;
		if (txt === '') return;
        debouncedReq();

    }, [txt]);
    
    
    function cb_chg(k,v)
    {
        setTxt(v);
    }
    
    function cb_blur(e)
    {
        const rt = e.relatedTarget;
        if (rt)
        {
            const ff = rt.querySelector("#my-shit");
            if (ff) return;
        }
        
        setAnchorEl(null);
        setTxt('');
    }
    
    function cb_focus(e)
    {
        //console.log('FOC !', e);
        
        setWdt( e.currentTarget.clientWidth );
        setAnchorEl( e.currentTarget );
        
        if (value !== null)
        {
            const e = HLP.ArrayOfDictsFindByPropValue( list, fld_k, value );
            if (e)
            {
                setTxt( e[fld_v] );
            }
        }
    }
    
    function cb_clear()
    {
        onChange( name, null );
        setList( [] );
        setAnchorEl(null);
        setTxt('');
    }
    
    function cb_clo_pop()
    {
        setAnchorEl(null);
    }
    
    function cb_sel(k, v, row)
    {
        onChange( name, v );
        
        setAnchorEl(null);
        setTxt('');
        
        if (v !== null)
        {
            setList( [ row ] );
        }
    }
    
    const open = anchorEl !== null;
    
    let vis_txt;
    
    if (open)
    {
        vis_txt = txt;
    }
    else
    {
        if (value !== null)
        {
            const e = HLP.ArrayOfDictsFindByPropValue( list, fld_k, value );
            if (e)
            {
                vis_txt = e[fld_v];
            }
            else 
            {
                vis_txt = 'cant find.. ' + value;
            }
        }
        else
        {
            vis_txt = '';
        }
    }
    
    
    let p_right = null;
    
    if (!disabled)
    {
        p_right = (   <div style={{display: 'flex', alignItems: 'center'}}>
                            { value !== null ? <DaIconBtn icon={CloseIcon}  onClick={cb_clear} /> : null }
                            <SearchIcon />
                        </div>);
    }
    
    return (
        <>
            <DaTextField disabled={disabled}  value={vis_txt} onChange={cb_chg} label={label} embRight={ p_right }  onFocus={cb_focus} onBlur={cb_blur} />
           
            <Popover open={open && !disabled} onClose={cb_clo_pop} anchorEl={anchorEl} anchorOrigin={gOrg1} transformOrigin={gOrg2} disableRestoreFocus disableAutoFocus  >
                <div id='my-shit' style={{width: wdt+'px', height: '200px'}}>
                    <DaList2 src={list} fld_k={fld_k} fld_v={fld_v} value={value} onChange={cb_sel} itemCmp={itemCmp} />
                </div>
            </Popover>
        </>
    );
}


const gOrg1 = {vertical: 'bottom', horizontal: 'center'};
const gOrg2 = {vertical: 'top', horizontal: 'center'};

