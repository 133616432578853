import React from 'react';


import { Win2 } from '../misc/win2.js';

import { useGlobalShit } from './hk_global_shit.js';

import { DaButton } from '../misc/but.js';


//-----


function MyJuice( {onOk} )
{
    const gs = useGlobalShit(); 

    if (gs.visClubs.length < 1)
    {
        return <div>НЕТ ДОСТУПА</div>;
    }

    const ar = [];

    for (let x = 0; x < gs.visClubs.length; x ++ )
    {
        const ee = gs.visClubs[x];

        ar.push( <DaButton key={ee.c_id} title={ee.c_vis_name } name={ee.c_id} onClick={onOk} /> );
    }

    return (
    
        <div style={{padding: '16px', display: 'flex', flexDirection: 'column', gap: '16px'}}>{ar}</div>

  
    );
}


export function WinClubSelector( {visible, onOk, onClose} )
{   
	return ( <Win2 visible={visible} title='Сменить клуб' width={300} height={300}   onClose={onClose} >
                <MyJuice onOk={onOk} />
			</Win2>
		);
}
