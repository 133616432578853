import React from 'react';

import * as COM_DEFS from '../com_defs.mjs';
import * as HLP from '../helpers.js';

import { LaySpace, LayHoriz2, LayVert2 } from '../misc/layout.js';

import { GetBackendBaseURL } from '../api.js';

import { useApiQuery2 } from '../api_stuff.js';

import { CmSpinner } from '../cm_base.js';

import { PicEditorTrigger, usePicEditorTriggetState } from './pic_edit_trig.js';
import { ShowMsg } from '../misc_glob.js';

import { STO_InvaPic } from '../store.js';
import { OverlayLoading } from '../misc/ovr_loading.js';

//---------------

// move
export function UnkPic()
{
	return ( <div className="zz_unk_pic">?</div> );
}

//------------------------



function CmRawPic3Imp( {src, sty, children} )
{
    return (<div style={{...sty, position: 'relative', width: '100%', height: '100%', backgroundImage: 'url('+src+')', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center'}}>
            {children}</div>);
}


export const CmRawPic3 = React.memo( CmRawPic3Imp );

//-----------------------







function UserPic( {rid, children} )
{


	const { isFetching, isLoading, isError, data, error } = useApiQuery2( 4123, {rid: rid, ty: COM_DEFS.PIC_TYPE_USER_SQ} );
	
	if (!data) return ( <CmSpinner sx='32px' />  );
	
	if (isError) return ( <div className="yy_error">{error}</div> );
	
	if (data.path === null) return <UnkPic />;
	
	
	const url = GetBackendBaseURL() + data.path + '?' + HLP.Now();

	return <CmRawPic3 src={ url } sty={{outline: '1px solid #FFFFFF60', outlineOffset: '-1px', borderRadius: '10px', }} >
                {children}
        </CmRawPic3>;
}




function UserPicMiniImp( {rid, children} )
{
	const { isFetching, isLoading, isError, data, error } = useApiQuery2( 4123, {rid: rid, ty: COM_DEFS.PIC_TYPE_USER_MINI} );
	
	if (!data) return ( <CmSpinner sx='16px' />  );
	
	if (isError) return ( null );
	
	if (data.path === null) return null;
	
	
	const url = GetBackendBaseURL() + data.path + '?' + HLP.Now();

	return (<div style={{border: '1px solid #00000070', position: 'relative', borderRadius: '6px', width: '32px', height: '32px', backgroundImage: 'url('+url+')', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center'}}></div>);
}

export const UserPicMini = React.memo( UserPicMiniImp );


//---------------------------------------------



//---------------------------------------------------------
export function ViUserPic( {rid} )
{

    const ctl = usePicEditorTriggetState();


    function cb_uploaded(e)
    {
        //console.log(e);
        ShowMsg('УСПЕХ !');

        STO_InvaPic( COM_DEFS.PIC_TYPE_USER_SQ, rid );
        STO_InvaPic( COM_DEFS.PIC_TYPE_USER_MINI, rid );
    }


    return (

        <div style={{position: 'relative', padding: '10px', width: '100%', height: '100%'}}>


            <LayHoriz2 cols='1fr max-content 1fr'>

                <LaySpace />

                <div style={{width: '320px', height: '320px', position: 'relative'}}>
                    <UserPic rid={rid}  />
                    <div style={{position: 'absolute', right: '10px', bottom: '10px'}}>
                        <PicEditorTrigger ctl={ctl} rid={rid} ctx={COM_DEFS.PIC_EDIT_CTX_USER} onOk={cb_uploaded} />
                    </div>

                    <OverlayLoading active={ctl.sta.busy} />
                </div>

                <LaySpace />

            </LayHoriz2>


        </div>
    );
}
