import React from 'react';

import * as DT from '../dt.js';

import * as DEFS from '../defs.js';

import { PayFormVis, usePayFormState } from './pay_form4.js';

import { CmCustomerSearch } from '../misc/search_cut.js';

import { DaSwitcherNumber } from '../misc/sw_number.js';
import { DaDateField } from '../misc/date_field.js';

import { DaButton } from '../misc/but.js';
import { DaTextField } from '../misc/text_field.js';
import { CommonReducer, ShowMsg, ShowErr } from '../misc_glob.js';

import { OverlayLoading } from '../misc/ovr_loading.js';

import { DaOutlinedShit } from '../misc/outlined.js';

import {DaList2} from '../misc/list2.js';

import { NuPost, NuPost2 } from '../api.js';

import { LayVert2, LayHoriz2, LaySpace } from '../misc/layout.js';

import { Win2 } from '../misc/win2.js';

import SellIcon from '@mui/icons-material/Sell';

//-------------------


function __CalcDiscPrcFromPrice(fullprice, disc_mon)
{
	if (!disc_mon) return 0.0;
	if (fullprice < 1) return 0.0;
	const inv = fullprice - disc_mon;
	return (disc_mon / fullprice) * 100.0;
}




function __GenSta()
{
    return {
  
    busy:       false,
    
    srch:         '',
    
    dt:             new Date(),
    
    cu_row:         null,
    
    sel_cat_id:     null,
    sel_prod_id:    null,

    comment:    '',
    
    pay_w_money:   0,
    pay_w_credit:  0,
    pay_w_bonus:   0,
    pay_w_method:  1,
    
    item_cnt:      0,
    disc_mon:      0,
    
    r_cats:       [],
    r_prods:      [],
    
    };
    
}

//-------------------------------

/*

function MyJuice( {rid, onOk} )
{
    const [sta, dispatch] = React.useReducer( CommonReducer, null, __GenSta );
    
    React.useEffect( () => {
        
        function __ok(e)
        {
            dispatch( {type: 'val', k:'busy', v:false} );
            
            dispatch( {type: 'val', k:'r_cats',  v:e.r_cats} );
            dispatch( {type: 'val', k:'r_prods', v:e.r_prods} );
        }
        
        function __fail(e)
        {
            dispatch( {type: 'val', k:'busy', v:false} );
            ShowErr(e.m);
        }
        
        dispatch( {type: 'val', k:'busy', v:true} );
        
        NuPost(961, {}, __ok, __fail);
        
    }, [] );
    
    
    const cb_chg = React.useCallback( (k,v) => {
        dispatch( {type: 'val', k:k, v:v} );
    }, [] );
    
    
    const cb_chg_payform = React.useCallback( (k,v) => {
        k = 'pay_w_' + k;
        dispatch( {type: 'val', k:k, v:v} ); 
    }, [] );
    
    
    
    const cb_chg_row = (v) => {
        dispatch( {type: 'val', k:'cu_row', v:v} );  
        
        if (v === null)
        {
            dispatch( {type: 'val', k:'pay_w_credit', v:0} );
        }
    };
    
    
    const __matchSearchFld = (str) =>  {
		let sf = sta.srch.toLowerCase().trim();
		if (sf === '') return true;
		return str.indexOf(sf) !== -1;
	};
	
    
    function cb_sel_cat(kk, v)
    {
        dispatch( {type: 'val', k:'sel_cat_id', v:v} );
        
        dispatch( {type: 'val', k:'disc_mon', v:0} );
        dispatch( {type: 'val', k:'item_cnt', v:0} );
        dispatch( {type: 'val', k:'sel_prod_id', v:null} );
    }
    
    function cb_sel_prod(kk, v)
    {
        dispatch( {type: 'val', k:'sel_prod_id', v:v} );
        dispatch( {type: 'val', k:'disc_mon', v:0} );
        dispatch( {type: 'val', k:'item_cnt', v:1} );
    }
    
	const cb_submit = () => {
		let cb_ok = (e) =>
		{
			ShowMsg('УСПЕХ !');
			dispatch( {type: 'val', k:'busy', v:false} );
			onOk();
		};
		
		let cb_fail = (e) =>
		{
			dispatch( {type: 'val', k:'busy', v:false} );
			ShowErr(e.m);
		};
		
		dispatch( {type: 'val', k:'busy', v:true} );
		
		NuPost(962, {   cu_id:      sta.cu_row ? sta.cu_row.cu_id : 0,
                        prod_id:    sta.sel_prod_id,
                        cnt:        sta.item_cnt,
                        dsc_mon:    sta.disc_mon,
                        pay_mon:    sta.pay_w_money,
                        pay_cre:    sta.pay_w_credit,
                        pay_bon:    sta.pay_w_bonus,
                        pay_met:    sta.pay_w_method,
                        date:       DT.DateTimeStrIso(sta.dt) }, cb_ok, cb_fail);
	};
		
        
    
    // build lists
    
    let single_price = 0;
    let current_prod_max_cnt = 0;
    let left_after = 0;
    
    const show_cat_ids = {};
    const lst_cats  = [];
    const lst_prods = [];
    
    for (let x = 0; x < sta.r_prods.length; x ++ )
    {
        const e = sta.r_prods[x];
        
		// dont show EMPTY
		if (e.v_stock < 1) continue;
        
        if (e.prg_disabled > 0) continue;   // dont show DISABLED
		
		if (!__matchSearchFld(e.prg_name.toLowerCase())) continue;
		
		// its good cat
		show_cat_ids[ e.prg_cat_id ] = true;
        
        
        if (e.prg_cat_id !== sta.sel_cat_id) continue;
        
        
		if (sta.sel_prod_id === e.prg_id)
		{
			single_price            = e.prg_price;
			current_prod_max_cnt 	= e.v_stock;
			left_after 	            = e.v_stock - sta.item_cnt;

		}
        
        
        lst_prods.push( e );
    }
    
    
	// CATS proc
	for (let x = 0; x < sta.r_cats.length; x ++ )
	{
		const e = sta.r_cats[x];
		if (show_cat_ids[e.prgc_id] === undefined) continue;
        lst_cats.push( e );
	}
			
		
    const j_left  = <DaList2 value={sta.sel_cat_id}   src={lst_cats}   fld_k='prgc_id' fld_v='prgc_name' onChange={cb_sel_cat}  />;
    
    const j_prods = <DaList2 value={sta.sel_prod_id}  src={lst_prods}  fld_k='prg_id'  fld_v='prg_name'  onChange={cb_sel_prod} itemCmp={ItemJuiceTwoLines} />;
    
    
    let j_right;
    
    if (sta.sel_prod_id !== null)
    {

        const full_price    = single_price * sta.item_cnt;
        
        const MAX_DISC      = full_price;
        
		let to_pay = Math.floor( full_price );
		to_pay -= sta.disc_mon;
		
		
		let p_discount = null;
		
		if (sta.disc_mon > 0)
		{
			const dp = __CalcDiscPrcFromPrice(full_price, sta.disc_mon).toFixed(2);
			p_discount = <b style={{color: '#30A030'}}>(-{dp} %)</b>;
		}
            
        
        j_right = (
        
            <LayVert2 rows='max-content max-content max-content max-content max-content 1fr' sx={{gap: '20px'}}>
            
                <div style={{display: 'flex', gap: '20px'}}>
                    <DaSwitcherNumber label='Шт:'          min={1} max={current_prod_max_cnt} name='item_cnt' value={sta.item_cnt} onChange={cb_chg} />
            
                    <DaSwitcherNumber label='Скидка руб:'   min={0} max={MAX_DISC} name='disc_mon' value={sta.disc_mon} onChange={cb_chg} />
                </div>
            
                <DaDateField nonull label='ДАТА ПРОДАЖИ' onChange={cb_chg}  name='dt' value={sta.dt} />	
                
                <CmCustomerSearch value={sta.cu_row} onChange={cb_chg_row} title='КТО ПОКУПАЕТ ? (НЕ ОБЯЗ.)' />
                
                <PaymentFormT2 onChange={cb_chg_payform}
                                    meth={sta.pay_w_method} mon={sta.pay_w_money}
                                    cred={sta.pay_w_credit}  bon={sta.pay_w_bonus}  cu_id={sta.cu_row ? sta.cu_row.cu_id : null} 
                                    needToPayPrice={to_pay} />

                <div style={{fontSize: '19px'}}>Останется: <b>{left_after} шт.</b> / К оплате: <b style={{color: '#40A040'}}>{to_pay} руб {p_discount}</b> </div>
                
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <DaButton  onClick={cb_submit} title='ОФОРМИТЬ' icon={ SellIcon } disabled={sta.sel_prod_id === null} />
                </div>
            
            </LayVert2>
        
        );
    }
    else
    {
        j_right = <div></div>;
    }

    
    return (    <>
    
    <LayVert2 rows='max-content 1fr' sx={{padding: '16px', gap: '30px'}} >
    
        <div style={{width: '300px'}}>
            <DaTextField label='БЫСТРЫЙ ПОИСК' name='srch' value={sta.srch} onChange={cb_chg} />
        </div>
    
        <LayHoriz2 cols='200px 1fr 1fr' sx={{gap: '20px'}}>
            <DaOutlinedShit labelVal='КАТЕГОРИИ' labelIsFloat={true} juice={j_left}  />
            <DaOutlinedShit labelVal='ТОВАРЫ'    labelIsFloat={true} juice={j_prods}  />
            
           
            {j_right}
            
        </LayHoriz2>
    
        <OverlayLoading active={sta.busy} />
    
    </LayVert2>
    
                </>
    );
}


export function PaymentFormT2( {onChange, meth, mon, cred, bon, cu_id, needToPayPrice} )
{
    console.log('PF Cu-ID', cu_id);

    const custBal = useCustomerBalance( cu_id );

    const hk_custBonus = useCustBonus( cu_id );


    console.log('HK ', hk_custBonus);
    
    
    const p_balance = custBal !== null ? `БАЛАНС КЛИЕНТА: ${custBal}` : null;
    const p_bonus   = (cu_id !== null) ? `БОНУСЫ КЛИЕНТА: ${hk_custBonus.bonus}` : null;
    
    let p_msg = null;
    
    if (custBal !== null && mon > needToPayPrice)
    {
        const ovr = mon - needToPayPrice;
        p_msg = 'Переплата: ' + ovr + ' (зачислится на балланс)';
    }
    
    
    return (
        
        <div style={{width: '100%', height: '100%', border: '1px solid #00000020', background: '#F2FFEE', borderRadius: '4px'}}>
            
            <div style={{padding: '10px', fontWeight: 800, color: '#508050', fontSize: '14px', height: '20px', width: '100%'}}>{p_balance}</div>
            <div style={{padding: '10px', fontWeight: 800, color: '#508050', fontSize: '14px', height: '20px', width: '100%'}}>{p_bonus}</div>

            <PaymentFormVis onChange={onChange} moneyMethod={meth}
                    valMoney={mon}  valCredit={cred} maxCredit={custBal} bonusMax={hk_custBonus.bonus}  valBonus={bon} />
        
        
            <div style={{paddingLeft: '16px', color: '#508050', fontWeight: 800, fontSize: '16px', height: '32px', width: '100%'}}>{p_msg}</div>
        </div>
    
    );
}

*/

//------------------------------------


function ItemJuiceTwoLinesImp( {kk, vv, row, isCur, ext} )
{
    return (<div style={{paddingLeft: '8px', display: 'flex', alignItems: 'center', fontSize: '16px', color: '#404060', height: '62px'}}>
                <div>
                    <div>{vv} <b className='faint'> ({row.v_stock} шт.)</b></div>
                    <span style={{paddingLeft: '1px', size: '11px', color: '#607F50'}}>{row.prg_price} руб</span>
                </div>
            </div>);
}

export const ItemJuiceTwoLines = React.memo( ItemJuiceTwoLinesImp );


//----------------------------------------------------------

//----------------------------------




/*
export function WinSellGood( {visible, rid, onOk, onClose} )
{
    const payState = usePayFormState( 614, 1500 );

    return (
    
        <Win2 visible={visible} title='ПРОДАЖА ТОВАРА' onClose={onClose} width={900} height={500}  >
            <LayHoriz2 cols='1fr 300px' >

                <div>

                </div>

                <PayFormVis disabled={false} state={payState} />

            </LayHoriz2>
        </Win2>
    
    );
}
*/


function MyJuice2( {onOk} )
{
    

    const [sta, dispatch] = React.useReducer( CommonReducer, null, __GenSta );
    

    React.useEffect( () => {
        
        function __ok(e)
        {
            dispatch( {type: 'val', k:'busy', v:false} );
            
            dispatch( {type: 'val', k:'r_cats',  v:e.r_cats} );
            dispatch( {type: 'val', k:'r_prods', v:e.r_prods} );
        }
        
        function __fail(e)
        {
            dispatch( {type: 'val', k:'busy', v:false} );
            ShowErr(e.m);
        }
        
        dispatch( {type: 'val', k:'busy', v:true} );
        
        NuPost2(961, {}, __ok, __fail);
        
    }, [] );
    
    
    const cb_chg = React.useCallback( (k,v) => {
        dispatch( {type: 'val', k:k, v:v} );
    }, [] );

    const cb_chg_row = (v) => {
        dispatch( {type: 'val', k:'cu_row', v:v} );  
        
        if (v === null)
        {
           // dispatch( {type: 'val', k:'pay_w_credit', v:0} );
        }
    };
    
    
    const __matchSearchFld = (str) =>  {
		let sf = sta.srch.toLowerCase().trim();
		if (sf === '') return true;
		return str.indexOf(sf) !== -1;
	};
	
    
    function cb_sel_cat(kk, v)
    {
        dispatch( {type: 'val', k:'sel_cat_id', v:v} );
        
        dispatch( {type: 'val', k:'disc_mon', v:0} );
        dispatch( {type: 'val', k:'item_cnt', v:0} );
        dispatch( {type: 'val', k:'sel_prod_id', v:null} );
    }
    
    function cb_sel_prod(kk, v)
    {
        dispatch( {type: 'val', k:'sel_prod_id', v:v} );
        dispatch( {type: 'val', k:'disc_mon', v:0} );
        dispatch( {type: 'val', k:'item_cnt', v:1} );
    }




        // build lists
    
        let single_price = 0;
        let current_prod_max_cnt = 0;
        let left_after = 0;
        
        const show_cat_ids = {};
        const lst_cats  = [];
        const lst_prods = [];
        
        for (let x = 0; x < sta.r_prods.length; x ++ )
        {
            const e = sta.r_prods[x];
            
            // dont show EMPTY
            if (e.v_stock < 1) continue;
            
            if (e.prg_disabled > 0) continue;   // dont show DISABLED
            
            if (!__matchSearchFld(e.prg_name.toLowerCase())) continue;
            
            // its good cat
            show_cat_ids[ e.prg_cat_id ] = true;
            
            
            if (e.prg_cat_id !== sta.sel_cat_id) continue;
            
            
            if (sta.sel_prod_id === e.prg_id)
            {
                single_price            = e.prg_price;
                current_prod_max_cnt 	= e.v_stock;
                left_after 	            = e.v_stock - sta.item_cnt;
    
            }
            
            
            lst_prods.push( e );
        }
        
        
        // CATS proc
        for (let x = 0; x < sta.r_cats.length; x ++ )
        {
            const e = sta.r_cats[x];
            if (show_cat_ids[e.prgc_id] === undefined) continue;
            lst_cats.push( e );
        }
                
            
        const j_left  = <DaList2 value={sta.sel_cat_id}   src={lst_cats}   fld_k='prgc_id' fld_v='prgc_name' onChange={cb_sel_cat}  />;
        
        const j_prods = <DaList2 value={sta.sel_prod_id}  src={lst_prods}  fld_k='prg_id'  fld_v='prg_name'  onChange={cb_sel_prod} itemCmp={ItemJuiceTwoLines} />;
        
        
        let j_right;

        let to_pay = 0;

        const full_price    = single_price * sta.item_cnt;

        const MAX_DISC      = full_price;

        if (sta.sel_prod_id !== null)
        {
            to_pay = Math.floor( full_price );
            to_pay -= sta.disc_mon;
        }

        const payState = usePayFormState( sta.cu_row ? sta.cu_row.cu_id : 0, to_pay, sta.dt );

        function cb_save()
        {
            const pf = payState.packSV();

            console.log('SAVE ! PF ', pf);


            let cb_ok = (e) =>
            {
                ShowMsg('УСПЕХ !');
                dispatch( {type: 'val', k:'busy', v:false} );
                onOk();
            };
            
            let cb_fail = (e) =>
            {
                dispatch( {type: 'val', k:'busy', v:false} );
                ShowErr(e.m);
            };
            
            dispatch( {type: 'val', k:'busy', v:true} );
            
            NuPost2(962, {  pf : pf,
                            prod_id:    sta.sel_prod_id,
                            cnt:        sta.item_cnt,
                            dsc_mon:    sta.disc_mon,
                            comment:    sta.comment
                             }, cb_ok, cb_fail);
        }




        
        if (sta.sel_prod_id !== null)
        {

            let p_discount = null;
            
            if (sta.disc_mon > 0)
            {
                const dp = __CalcDiscPrcFromPrice(full_price, sta.disc_mon).toFixed(2);
                p_discount = <b style={{color: '#30A030'}}>(-{dp} %)</b>;
            }
                

            j_right = (

                <LayVert2 rows='max-content max-content max-content max-conent max-content 1fr max-content' gap='10px' padding='6px'>
                
                    <div style={{display: 'flex', gap: '20px'}}>
                        <DaSwitcherNumber label='Шт:'          min={1} max={current_prod_max_cnt} name='item_cnt' value={sta.item_cnt} onChange={cb_chg} />
                
                        <DaSwitcherNumber label='Скидка руб:'   min={0} max={MAX_DISC} name='disc_mon' value={sta.disc_mon} onChange={cb_chg} />
                    </div>
                
                    <DaDateField nonull label='ДАТА ПРОДАЖИ' onChange={cb_chg}  name='dt' value={sta.dt} />	
                    
                    <CmCustomerSearch value={sta.cu_row} onChange={cb_chg_row} title='КТО ПОКУПАЕТ ? (НЕ ОБЯЗ.)' />
                    
                    <DaTextField label='КОММЕНТ (НЕ ОБЯЗ.)' name='comment' value={sta.comment} onChange={cb_chg} />

                    <PayFormVis disabled={false} state={payState} />

                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <DaButton onClick={cb_save}  title='ОФОРМИТЬ' icon={ SellIcon } disabled={sta.sel_prod_id === null} />
                    </div>
                
                </LayVert2>
            
            );
        }
        else
        {
            j_right = <LaySpace />;
        }
    


    


    return (

        <LayHoriz2 cols='1fr 400px' gap='40px' padding='10px' >

            <LayVert2 rows='max-content 1fr'  gap='30px' padding='10px'>
                
                <DaTextField label='БЫСТРЫЙ ПОИСК' name='srch' value={sta.srch} onChange={cb_chg} />

                <LayHoriz2 cols='210px 1fr' gap='20px' >
                    <DaOutlinedShit labelVal='КАТЕГОРИИ' labelIsFloat={true} juice={j_left}  />
                    <DaOutlinedShit labelVal='ТОВАРЫ'    labelIsFloat={true} juice={j_prods}  />
                </LayHoriz2>

                <OverlayLoading active={sta.busy} />

            </LayVert2>

            {j_right}

        </LayHoriz2>

    );
}


// окно - продажа товара
export function WinSellGood( {visible, rid, onOk, onClose} )
{
    return (
    
        <Win2 visible={visible} title='ПРОДАЖА ТОВАРА' onClose={onClose} width={1170} height={650}  >
            <MyJuice2 rid={rid} onOk={onOk} />
        </Win2>
    
    );
}

