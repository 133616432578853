 

import React from 'react';

import { NuPost2 } from '../api.js';

import * as HLP from '../helpers.js';

import { OverlayLoading } from '../misc/ovr_loading.js';

import {Win2} from '../misc/win2.js';

import {  ShowMsg, ShowErr  } from '../v3/toast.js';

import { TrpAllowedHoursInfo } from '../desktop/allowed_hours.js';

import { DaButton } from '../misc/but.js';
import { LayVert2, LayHoriz2, LaySpace } from '../misc/layout.js';

import CheckIcon from '@mui/icons-material/Check';

import { BottomButtonsPanel } from '../cust_card/bottom_pan.js';
import {CmProdDescription} from './prod_descr.js';

import {DaList2} from '../misc/list2.js';

import { useGlobEvActs  } from '../misc_glob.js';
import { DaOutlinedShit } from '../misc/outlined.js';


//------------------------------------


function MyItemCmpShitImp( {kk, vv, row, isCur, ext} )
{
    const MM = row.v_club_match > 0;

    return (<div className={ MM ? 'yy_list_itj_default' : 'yy_list_itj_default disabled' } >
                {vv}<TrpAllowedHoursInfo jsonStr={row.pr_allowed_hours_json} />{MM ? null : ' - Отключен в этом клубе'}
            </div>);


}

export const MyItemCmpShit = React.memo( MyItemCmpShitImp );

//----------------------------------


function MyJuice( {prods, onOk} )
{
    const hk_acts = useGlobEvActs();
    
	const [valProdId, setValProdId] 	= React.useState( -1 );
	const [valProdRow, setValProdRow] 	= React.useState( null );
	
	const cb_list_sel_prod = (kkk, k) =>
	{
		setValProdId( k );
		const e = HLP.ArrayOfDictsFindByPropValue(prods, 'pr_id', k);
		setValProdRow( e );
	};
	

	function cb_submit()
	{
		onOk( valProdRow );
	}
		
	let p_center = null;
	let p_right  = <LaySpace />;
	
	
	
    const p_left = (

        <div className="wh100perc" style={{paddingTop: '10px', paddingBottom: '10px'}}>
        <DaList2 itemCmp={MyItemCmpShit} value={valProdId}  src={prods}  fld_k='pr_id' fld_v='pr_name' onChange={cb_list_sel_prod}  />
        </div>	
    );
	
	if (valProdRow !== null)
	{
		p_right = <div className="wh100perc"><CmProdDescription da_list={ [valProdRow] } amounts={ [1] } /></div>;
	}
	
	
	
	const DIS_SUBMIT = valProdId === -1 || (valProdRow && valProdRow.v_club_match < 1);
        
	
	return (
	
		<LayVert2 rows='1fr max-content' gap='10px'>
		
            <LayHoriz2 cols='360px 1fr' padding='16px' gap='16px' >

             <DaOutlinedShit labelIsFloat labelVal='АБОНЕМЕНТЫ / ПАКЕТЫ' juice={p_left} />
				
				
				{p_right}
				
			</LayHoriz2 >

			<BottomButtonsPanel>
				<DaButton disabled={DIS_SUBMIT} onClick={cb_submit} icon={CheckIcon} title='ВЫБРАТЬ' />
			</BottomButtonsPanel>

		</LayVert2 >
	
	);
}


	
//--------------------------------------------------


function DaBody( {onOk} )
{
    const [prods, setProds] = React.useState( null );
    
    React.useEffect( () => {

        function __ok(e)
        {
            setProds( e.prods );
        }
        
        function __fail(e)
        {
            ShowErr(e.m);
        }
        
        NuPost2( 732, {}, __ok, __fail );
        
    }, [] );
    
    

	const p_juice = (prods === null) ? null : <MyJuice prods={prods} onOk={onOk} />;
    
    return (
            <>
            {p_juice}
            
            <OverlayLoading active={prods===null} />
    
            </>
    );
}



// выбор продукта (создание абонемента)
export function WinProductBrowser2( {visible, onOk, onClose} )
{
	return (	
	
		<Win2  visible={visible} title='ПРОДУКТЫ'   width={870} height={410}   onClose={ onClose }   >
			
			<DaBody onOk={onOk} />

		</Win2>
        
                    );
}



