import React from 'react';
import { DaTextField } from './text_field.js';

import SearchIcon from '@mui/icons-material/Search';

//-----------------------------------------------------

// search bar with delay
function DaSearchBarImp( {name, value, timeout, label, onChange} )
{
	const [val, setVal] = React.useState( value );

	const TIMEOUT = timeout ? timeout : 300;

	const onChg = React.useCallback( (k,v) =>
	{
		setVal(v);
	}, [val] );
	

	React.useEffect(() => {
		const tt = setTimeout(() => {
			onChange(name, val)
		}, TIMEOUT);
		return () => clearTimeout(tt);
	}, [val]);
	
    
    return ( <DaTextField name={name} value={val} onChange={onChg} label={label} embRight={ <SearchIcon /> } /> );
}

export const DaSearchBar = React.memo( DaSearchBarImp );
