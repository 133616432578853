import React from 'react';
import "./sm.css";

import { motion } from "framer-motion";

import { useGlobalShit, useGlobalShitDispatch } from "../v3/hk_global_shit";

import { BiChevronRight } from "react-icons/bi";
import { BiChevronDown } from "react-icons/bi";

import { BiQuestionMark } from "react-icons/bi";

import { IoPersonCircle } from "react-icons/io5";
import { FaLocationDot } from "react-icons/fa6";

import useRipple from "../misc/useRipple.tsx";


//----------------------


export function SmTit( )
{
    return (
        <div className="sm_tit"><img alt='' width="64px" height="64px" src="./img/fl1.svg" /></div>
    );
}


export function SmNotifyCounter( {num, clr} )
{
    return (
            <div className='sm_notify black_glow2' style={{background: clr}}>
                {num}
            </div>
    );
}



export function SmItem( {tit, code, dis, isCur, isExpand, onClick, children, ex, ic} )
{
    const ref         = React.useRef(null);
    const ripples     = useRipple(ref, '#FFFFFF');


    const GOT_CH = children && children.length > 0;

    const CLR = isCur ? '#FFFFFF' : '#707070';

    let p_exp = null;

    if (GOT_CH)
    {
        p_exp = isExpand ? <BiChevronDown style={{fontSize: '24px', color: CLR}} /> : <BiChevronRight style={{fontSize: '24px', color: CLR}} />;
    }

    let cla = 'sm_item';
    if (dis) cla += ' dis';
    if (isCur) cla += ' cur';


    function cb_clk()
    {
        onClick( code );
    }

    let ex_inst;

    if (ex)
    {
        ex_inst = React.createElement( ex );
    }
    else ex_inst = null;


    if (!ic) ic = BiQuestionMark;

    let p_ic = React.createElement( ic, {fontSize: '14px',
                                        color: isCur ? '0xFFFFFF' : undefined,
                                        className: (!isCur) ? 'zz_clr_prim' : undefined} );

    return ( 
        <>
        <div ref={ref} className={cla} onClick={cb_clk} > 
            {ripples}
                    {p_ic}
                    <div style={{display: 'flex', gap: '8px',  height: '100%', alignItems: 'center'}}>{tit}{ ex_inst }</div>
                    
                    
                    { p_exp }
        </div>

        {isExpand ? children : null}

        { (isExpand && GOT_CH) ? <div style={{width: '100%', height: '8px'}}></div> : null }

        </>
    );
}


//-------------


export function SmSubItem( {tit, code, dis, isCur, ind, onClick} )
{
    const ref         = React.useRef(null);
    const ripples     = useRipple(ref, '#FFFFFF');


    let cla = 'sm_sub_item';

    if (isCur) cla += ' cur';
    if (dis) cla += ' dis';

    function cb_clk()
    {
        onClick( code );
    }

    const AN = { height: ['2px', '32px'], opacity: [0.0, 1.0], marginLeft: ['4px', '0px'] };
    const TR = { times: [0.0, 0.3], ease: 'easeOut' };


        //  <div className="dd"></div>
    return ( <motion.div ref={ref} className={cla}  onClick={cb_clk}  animate={AN} transition={TR}   >
                {ripples}
        {tit}
        </motion.div>  );
}

export function SmDiv()
{
    return <div className="sm_div"></div>;
}



export function SmUser()
{
    const hk        = useGlobalShit();
    const gsDsp     = useGlobalShitDispatch();

    function cb_clcl()
    {
        gsDsp( {type: 'val', k: 'showPopLogin', v: true} );
    }


    return <div className="sm_link_btn" onClick={cb_clcl}>
                <IoPersonCircle size='18px' />
                <span>{hk.userName}</span>
            </div>;
}

export function SmClub()
{
    const hk = useGlobalShit();

    const gsDsp     = useGlobalShitDispatch();

    const GOT_CLUB = hk.clubId > 0;

    let cl = "sm_link_btn";
    if (!GOT_CLUB) cl += ' err';
    
    const S = GOT_CLUB ? hk.clubName : 'клуб не выбран';

    function cb_clcl()
    {
        gsDsp( {type: 'val', k: 'showPopClub', v: true} );
    }

    return (<div className={cl} onClick={cb_clcl} >
                <FaLocationDot size='16px'  />
                <span>{S}</span>
                 </div>);
}