import React from 'react';



import * as DEFS from '../defs.js';
import * as COM_DEFS from '../com_defs.mjs';

import * as HLP from '../helpers.js';

import { NuPost2 } from '../api.js';

import {DaIconBtn} from '../misc/icon_btn.js';

import { WinHelpConta, WinHelpElem } from '../misc/win_help.js'

import { OverlayLoading } from '../misc/ovr_loading.js';


import { EvActDispNameCSV } from '../desktop/ev_act.js';

import { DaButton } from '../misc/but.js';
import { BottomButtonsPanel } from '../cust_card/bottom_pan.js';

import { DaTextField } from '../misc/text_field.js';
import { DaNumericField } from '../misc/numeric.js';

import { DaCombo } from '../misc/combo.js';

import { DaCheck } from '../misc/check.js';

import { LayVert2, LayHoriz2 } from '../misc/layout.js';

import { DaCsvField } from '../misc/csv_field.js';


import { CmDaSwitcher } from '../cm_mui.js';

import { Win2 } from '../misc/win2.js';

import {  ShowMsg,  ShowErr, useGlobEvActs } from '../misc_glob.js';

import { TrpHourListEditor } from '../desktop/trp_hours_editor.js';

import IconButton from '@mui/material/IconButton';
import Grow from '@mui/material/Grow';


import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

//------------------------------------------------

function MyEntryImp(pp)
{
    const acts_api = useGlobEvActs();
    
    const my_act_lst = React.useMemo( () => {
        
        const ff = (e) => {
            return /*e.ea_for_abon_logic === pp.logic &&*/ e.ea_use_for_trp > 0;
        };
        
        return acts_api.rows.filter( ff );
        
    }, [pp.logic] );
    
    
	const cb_chg = (k,v) => {
		pp.onChange( pp.ind, k, v );
	};
	
	const cb_chg_cnt = (k,v) => {
		pp.onChange( pp.ind, k, pp.cnt+v );
	};
	
	const cb_clo = () => {
		pp.onClose(pp.ind);
	};
	
	const cb_exit = () => {
		
		//console.log('BAR EXIT GROW !');
		pp.onExit(pp.ind);
	};
	

	const IS_MON = pp.logic === COM_DEFS.ABON_LOGIC_MONTHLY;
	

	return (
			<Grow in={pp.ex===false} appear={pp.ex===false} timeout={90} onExited={cb_exit}  >
				<div style={{ position: 'relative', overflow: 'clip', display: 'grid', gridTemplateColumns: '100px 1fr max-content', gridTemplateRows: '1fr',    alignItems: 'center', width: '100%', gap: '10px', border: '1px solid #70A0E0', background: '#FAFAFF', padding: '10px', borderRadius: '4px'}}>
					
					
					{
						(IS_MON) ?			 ( <CmDaSwitcher name='cnt' value='-'  dis_left={true} dis_right={true} />) :
											 ( <CmDaSwitcher name='cnt' value={pp.cnt} onChange={cb_chg_cnt} dis_left={pp.cnt<=1} /> )
					
					}

                    
                    <DaCsvField label='ДВИЖ' fld_k='ea_id' fld_v='ea_name'  name='acts_csv'
                                    value_csv={pp.acts_csv} error={pp.acts_csv===''} src={my_act_lst} onChange={cb_chg} />
                    
                    
					<IconButton onClick={cb_clo} ><CloseIcon sx={{color: '#A06060'}}/></IconButton>
				</div>
			</Grow> );
}


const MyEntry = React.memo( MyEntryImp );


//-------------------------------------


// TODO..
const gLogicSrc = [
	{k: -1, v: '- НЕ ВЫБРАНО -' },
	{k:  0, v: COM_DEFS.gAbonLogicDisp[0]  },
	{k:  1, v: COM_DEFS.gAbonLogicDisp[1]  },
];

//----------------------------------

function GenState()
{
	return {
		
		logic: -1,
		name:  '',
		price: 0,
        pay_method: 0,
		starter: 0,
        busy: false,
		
		ser: 0,
		
		dct: {},
        
        hours_list: [ ],
	};
}

// hours list by DAY OF WEEK
function __cb_sort(aa,bb)
{
    if (aa[0] < bb[0]) return -1;
    if (aa[0] > bb[0]) return 1;
    return 0;
}

function __reducer(sta, act)
{
	switch(act.type)
	{
		case 'val':
		{
			let nu = { ...sta };
			
			if (act.k === 'logic' && act.v !== sta.logic)
			{
				if (act.v === COM_DEFS.ABON_LOGIC_MONTHLY)
				{
					nu.ser ++;
					nu.dct = {  [nu.ser] : {cnt:0, acts_csv: '', ex: false } };
				}
				else
				{
					nu.dct = {};
				}
			}
			
			nu[act.k] = act.v;
			
			return nu;
		}
		
		case 'ent_val':
		{
			const nu = { ...sta };
			nu.dct[act.i][act.k] = act.v;
			return nu;
		}
		
		case 'ent_add':
		{
			const nu = { ...sta };
			nu.ser ++;
			nu.dct[ nu.ser ] = {cnt:1, acts_csv: '', ex: false };
			return nu;
		}
		
		case 'ent_del':
		{
			const nu = { ...sta };
			delete nu.dct[act.i];
			return nu;
		}
		
		case 'ent_clo':
		{
			const nu = { ...sta };
			nu.dct[act.i].ex = true;
			return nu;
		}	
        
		case 'hour_del':
		{
            const nu = HLP.DeepCopy( sta );
            nu.hours_list.splice(act.i, 1);
			return nu;
		}
        
        case 'hour_add':
        {
            const nu = HLP.DeepCopy( sta );
            nu.hours_list.push( [ act.wd, act.hs, act.he ] );
            nu.hours_list.sort( __cb_sort );
            return nu;
        }
		
		case 'got':
		{
			const e = act.e;
			const pr = e.prod;
			const ass = e.ass;
            
			const nu = { ...sta };
			
			nu.ser = 1;
			
            nu.busy = false;
			nu.logic = pr.pr_logic;
			nu.name  = pr.pr_name;
			nu.price = pr.pr_price;
			nu.starter = pr.pr_is_starter_bonus;
            nu.hours_list = pr.pr_allowed_hours_json;
			
			for (let x = 0; x < ass.length; x ++ )
			{
				const e = ass[x];
                
				nu.dct[ nu.ser ] = { cnt: e.ps_t_count, acts_csv: e.ps_t_acts_csv, ex: false };

				nu.ser ++;
			}
			
			return nu;
		}
	}
	
	return sta;
}

function __FieldsCanSubmit(sta)
{
	if (sta.name.trim() === '') return false;
	
	if (sta.logic === -1) return false;
	
	if (Object.keys(sta.dct).length < 1) return false;
	
	for (let k in sta.dct)
	{
		let e = sta.dct[k];
		if (e.acts_csv === '') return false;
	}

	return true;
}

//--------------------------------------




const gHelp1 = (<WinHelpConta>
                    <WinHelpElem tit='ТИПЫ АБОНЕМЕНТОВ' text={['Обычный: фиксированое количество тренировок.', 'Месячный-групповой: действует в диапазоне дат, по проставленным в календаре событиям.']} />
                    <WinHelpElem tit='ЧАСЫ ДЛЯ ЗАПИСИ' text='На какое время можно забивать эти тренировки.' />
                </WinHelpConta>);
                
                

// Редактор ПРОДУКТА / АБОНЕМЕНТА
export function WinProductEdit( {visible, rid, onClose, onOk} )
{
    const TIT = (rid===-1) ? 'НОВЫЙ ПРОДУКТ / АБОНЕМЕНТ' : 'РЕДАКТИРОВАТЬ ПРОДУКТ / АБОНЕМЕНТ';
    
    return (
    
        <Win2 visible={visible} title={TIT} width={1160} height={500}  onClose={onClose} help={gHelp1} >
            <MyBody rid={rid} onClose={onClose} onOk={onOk} />
        </Win2>
    );
}


function MyBody( {rid, onOk} )
{
	const IS_NEW 	= rid === -1;
	
	const [sta, dispatch] = React.useReducer( __reducer, {}, GenState );
	
	const cb_chg_val = React.useCallback( (k,v) =>
	{
		dispatch( {type: 'val', k:k, v:v} );
	}, [] );
	
	function cb_ent_new()
	{
		dispatch( {type: 'ent_add' } );
	}
	
	const __remove_at = (i) => {
		dispatch( {type: 'ent_del', i:i } );
	};
	
	const cb_on_close = (i) => {
		dispatch( {type: 'ent_clo', i:i } );
	};
	
	const cb_chg_item = React.useCallback( (i,k,v) => {
		dispatch( {type: 'ent_val', i:i, k:k, v:v } );
	}, [] );
    
    
    const cb_on_remove = (i) => {
        dispatch( {type: 'hour_del', i:i } );
    };
    
    const cb_on_new_hour = (wd, hs, he) => {
        dispatch( {type: 'hour_add', wd,hs,he } );
    };
    
    
	React.useEffect(() => {
		
		if (!IS_NEW)
		{
			function __ok(e)
			{
				dispatch( {type: 'got', e:e} );
			}
			
			function __fail(e)
			{
				ShowMsg("FAIL")
			}
            
            dispatch( {type: 'val', k: 'busy', v: true} );
			
			NuPost2( 709, {pr_id: rid}, __ok, __fail);
		}
	}, [rid, IS_NEW] );



	function cb_submit()
	{
		//console.log('SUBMIT ! ', sta);
		
		function __ok(e)
		{
			ShowMsg('СОХРАНЕНО !');
			dispatch( {type: 'val', k: 'busy', v: false} );
			onOk();
		}
		
		function __fail(e)
		{
			ShowErr( e.m );
			dispatch( {type: 'val', k: 'busy', v: false} );
		}
		
		const ents = [];
		for (let k in sta.dct)
		{
			const e = sta.dct[k];
			
			ents.push( {acts: e.acts_csv, cnt: e.cnt} );
		}
		
		dispatch( {type: 'val', k: 'busy', v: true} );
		
		NuPost2( 708, {hours_list: sta.hours_list, logic: sta.logic, ents: ents, name: sta.name, rid: rid, price: sta.price, starter: sta.starter}, __ok, __fail );
	}
	
	
	let p_trains = [];
	
	for (let k in sta.dct)
	{
		const e = sta.dct[k];
		p_trains.push( <MyEntry key={k} acts_csv={e.acts_csv} cnt={e.cnt} ex={e.ex} ind={k} logic={sta.logic} onChange={cb_chg_item} onClose={cb_on_close} onExit={__remove_at} /> );
	}
	
	
	const DIS_SUBMIT = !__FieldsCanSubmit(sta);
	

	const p_right = sta.logic !== -1 ? (<div style={{padding: '16px', width: '100%', height: '100%', overflowY: 'scroll', position: 'absolute', display: 'flex', gap: '10px', flexDirection: 'column' }}>
											<div className="zz_text_secondary fl_center_ho">ТРЕНИРОВКИ:</div>
											
											{ p_trains }
										
											{ (sta.logic !== COM_DEFS.ABON_LOGIC_MONTHLY) ? <div className="fl_center_ho" style={{marginBottom: '50px'}}>
																							<DaIconBtn  icon={AddIcon} help='ДОБАВИТЬ' onClick={cb_ent_new}  />
																						</div> : null }
							
										</div>) : null;
	



    return (
    

        <LayVert2 rows='1fr max-content'>
    
            <LayHoriz2 cols='270px 1fr 260px' >
            
                <LayVert2 rows='max-content max-content max-content max-content 1fr' sx={{padding: '16px', gap: '16px'}}>
                    <DaCombo  fld_k='k' fld_v='v'  label='Тип'  name='logic' value={sta.logic} src={gLogicSrc} onChange={cb_chg_val}  error={sta.logic === -1} />
                
                    <DaTextField    name='name'  value={sta.name}  onChange={cb_chg_val} label='Название' error={sta.name === ''} />
                    <DaNumericField name='price' value={sta.price} min={0} max={99999999} onChange={cb_chg_val} label='Цена' />
                    
                    <DaCheck name='starter' value={sta.starter} onChange={cb_chg_val} label="Добавлять новым клиентам" />
                    
                    <div></div>
                    
                </LayVert2>
        
                
                <div style={{position: 'relative', borderLeft: '1px solid #00000020', borderRight: '1px solid #00000020'}}>
                
                    {p_right}
                    
                    
                </div>
                
                <LayVert2 rows='max-content 1fr' style={{position: 'relative'}}>
                    <div style={{padding: '16px'}}className="zz_text_secondary fl_center_ho">ЧАСЫ ДЛЯ ЗАПИСИ (ВКЛЮЧИТЕЛЬНО):</div>
                    <TrpHourListEditor list={sta.hours_list} onRemove={cb_on_remove} onAdd={cb_on_new_hour} />
                </LayVert2>
            
            </LayHoriz2>
        
			<BottomButtonsPanel>
                <DaButton onClick={cb_submit} title={IS_NEW ? 'СОЗДАТЬ' : 'СОХРАНИТЬ' }       icon={CheckIcon} disabled={DIS_SUBMIT} />
            </BottomButtonsPanel>
        
            <OverlayLoading active={sta.busy} />
        
        </LayVert2>
        
        
        
    
    );
}


