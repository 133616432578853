import React from 'react';

import { Win2 } from '../misc/win2.js';

import { CmComColCellDateTime } from '../cm_table_cell_com.js';

import { LayHoriz2, LayVert2 } from '../misc/layout.js'

import { DaButton } from '../misc/but.js';

import { DaCheck } from '../misc/check.js';

import { BottomButtonsPanel } from '../cust_card/bottom_pan.js';

import { Grid3 } from '../grid3.js';

import {CmComboCustSrcText } from '../cm_cust_src.js';

import CheckIcon from '@mui/icons-material/Check';




//------------------------

const __aa = ['ОЖИДАЕТ', 'ОДОБРЕНА', 'ОТКЛОНЕНА'];
const __cc = ['#3060B0', '#30A010',  '#A02010'];

function MyCellSta(pp)
{
    return (<span style={{color: __cc[pp.value]}}>{__aa[pp.value]}</span>);
}

//---------------------------------

function MyColSrcImp(pp)
{
    return <CmComboCustSrcText value={pp.value} />;
}

const MyColSrc = React.memo( MyColSrcImp );

//------------------------------------

const gColz = [
  { sort: true,   align: 'center',  fld: 'cre_id',          name: 'ID', width: 80 },
  { sort: true,   align: 'center',  fld: 'cre_dt_created',  name: 'Дата', width: 130, renderCell: CmComColCellDateTime},
  { sort: true,   align: 'left',    fld: 'cre_name',        name: 'Имя', width: 220 },
  { sort: true,   align: 'left',    fld: 'cre_phone',       name: 'Телефон', width: 190},
  { sort: true,   align: 'left',    fld: 'cre_orig',        name: 'Источник', width: 270, renderCell: MyColSrc },
  { sort: true,   align: 'center',  fld: 'cre_status',      name: 'Статус', width: 140, renderCell: MyCellSta },
  { sort: false,  align: 'left',    fld: 'cre_comment',     name: 'Коммент', width: 380 },

];


//-------------------------------

// попап - выбор заявки из списка (окно создания клиента)
export function WinCuReqPicker( {visible, onClose, onOk, initVal} )
{

    return (
        
        <Win2 visible={visible} title='ВЫБОР ЗАЯВКИ ДЛЯ СВЯЗИ' onClose={onClose} width={1100} height={600}  >
            <MyBody onOk={onOk} initVal={initVal} />
        </Win2>
    );
}





function MyBody( {onOk} )
{
    const [selected, setSelected] = React.useState( [] );
    const [row, setRow] = React.useState( null );
    const [copyFields, setCopyFields] = React.useState( true );
    
	const cb_chg_sel = (nu, this_row) => {
		setSelected(nu);
		setRow(this_row);
	};
	
    const cb_click = () => {
        onOk(row, copyFields);
    };

    function cb_chg_ff(k,v)
    {
        setCopyFields( v );
    }
    
    const DIS_SUB = selected.length < 1;
    
    return (
    
                <LayVert2 rows='1fr max-content' gap='20px' >
                    
                    <LayVert2 rows='max-content 1fr' padding='10px' gap='20px' >
                        <DaCheck name='copyFields' value={copyFields} label='Копировать ТЕЛЕФОН / ИМЯ из заявки в окно "НОВЫЙ КЛИЕНТ"' onChange={cb_chg_ff} />
                        <Grid3  isNuApi cols={gColz}	uniq='cre_id'   sort_field='cre_dt_created' sort_dir='desc' apiCmd={798} 
                                        selected={selected}  onChangeSel={cb_chg_sel}  />
                    </LayVert2>
                                        
        
                    <BottomButtonsPanel>
                        <DaButton  onClick={cb_click}  disabled={DIS_SUB} title='ВЫБРАТЬ' icon={ CheckIcon } />
                    </BottomButtonsPanel>
            
                </LayVert2>
    
    );
}

