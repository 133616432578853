import React from 'react';

//--------------------------

function __CalcMinMaxHours(ar)
{
    let ma = 0;
    let mi = 99;
    
    for (let x = 0; x < ar.length; x ++ )
    {
        const oo = ar[x].cwh_hour_open;
        const cc = ar[x].cwh_hour_close;
        
        if (oo < mi) mi = oo;
        if (cc > ma) ma = cc;
    }
    
    return [mi, ma];
}




// day ind  0: monday
export function WorkHoursTest(sta, day_ind, h)
{
    const e = sta.workHours[day_ind];
    return h >= e[0] && h < e[1];
}




//----------------------------------

const gMyGlobInit = {

    mode:           'boot',

    au_msg:         '',

    userId:         -1,
    userName:       '???',
    userAbils:      [],

    clubId:         0,
    clubName:       '???',

    workHours:      null,
    workZones:      1,

    workHoursTotMin:  9,
    workHoursTotMax:  10,

    visClubs:           [],

    showPopClub:        false,
    showPopLogin:       false,
    busy:               false,

    menuIsFold:         false,
    
    acts:                [],        // ev

    serial:             0,


};


// reducer helper..
function __moveClubInfo(n, C)
{
    if (C)
    {
        n.clubName   = C.name;
        n.workHours = C.work_hours;
        n.workZones = C.work_zones;
        n.workHoursTotMin = C.tot_min;
        n.workHoursTotMax = C.tot_max;
    }
    else
    {
        n.clubName = '???';
        n.workHours = null;
        n.workZones = 1;
        n.workHoursTotMin = 9;
        n.workHoursTotMax = 22;
    }
}

function __myReducerShit(sta, act)
{
	switch(act.type)
	{
		case 'val':
		{
			return { ...sta, [act.k]: act.v };
		}

        case 'vals':
        {
            const n = { ... sta };

            const dct = act.dct;

            for (let k in dct)
            {
                n[k] = dct[k];
            }

            return n;
        }

        case 'load':
        {
            const ii = act.info;
            const n = { ... sta };

            const U = ii.info_user;
            const C = ii.info_club;
            const G = ii.info_glob;

            console.log('AT LOAD... ', ii.info_user);

            n.userName  = U.u_name;
            n.userId    = U.u_id;
            n.clubId    = U.curClubId;
            n.visClubs  = U.visClubs;
            n.userAbils = U.abils;
            n.acts      = G.acts;

            __moveClubInfo( n, C );

            n.serial = sta.serial + 1;

            return n;
        }

        case 'load_club':
        {
            const ii = act.info;
            const n = { ... sta };

            const C = ii.info_club;

            n.clubId    = ii.ci;

            __moveClubInfo( n, C );

            n.serial = sta.serial + 1;

            return n;
        }
	}
	
	return sta;
}

//----------------------------------------------


export const GlobalShitContext            = React.createContext( null );
export const GlobalShitDispatchContext    = React.createContext( null );


export function GlobalShitProvider( {children} )
{
    const [sta, dispatch] = React.useReducer( __myReducerShit, gMyGlobInit );
    
    const sta_wrp = { ...sta, 
        userHasAbil: (aa) => {
            return sta.userAbils.indexOf(aa) !== -1;
        },
    };

    return (
        <GlobalShitContext.Provider value={sta_wrp} >
            <GlobalShitDispatchContext.Provider value={dispatch} >
                {children}
            </GlobalShitDispatchContext.Provider>
        </GlobalShitContext.Provider>
    );
}
 
 
//-------------------------------

export function useGlobalShit()
{
    return React.useContext( GlobalShitContext );
}


export function useGlobalShitDispatch()
{
    return React.useContext( GlobalShitDispatchContext );
}

