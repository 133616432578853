 
import { createTheme } from '@mui/material/styles';



export const gThemeForMUI = createTheme({
	
	
	palette: {
    type: 'light',
    primary: {
      main: '#777EEa',
    },
    secondary: {
      main: '#f50057',
    },
  },
	
typography: {
    fontFamily: 'body',
  },
	
  components: {
	  
	  MuiListItemButton: {
		  
		  
		  styleOverrides: {
			
			root: {
					
					'&.Mui-selected': { backgroundColor: '#E2E4FF' },
                  },
			  
		  },
		  
	  },



    // Name of the component
    MuiButton: {

	styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          //fontFamily: 'Fira Sans Condensed',
        },
      },
    },
  },
});



/*
	MuiDataGrid: {
		
		  styleOverrides: {
			
			root: { border: '1px solid #00000060', overflow: 'clip', boxShadow: '0px 2px 4px 4px #00204010',
				
					
				 },
			
			columnHeaders: { backgroundColor: '#E0E4F0', color: '#304070', borderBottom: '1px solid #00000040',},
			
			row:{
					"&:hover": { color: '#4070FF', filter: 'brightness(1.05)' },
					
					"&:nth-of-type(2n)":   { backgroundColor: "#F2F5FA", userSelect: 'none' },
					"&:nth-of-type(2n+1)": { backgroundColor: "#FAFAFB", userSelect: 'none' },
					
					'&.Mui-selected':       { backgroundColor: '#D0D8FF', color: '#303070' },
					'&.Mui-selected:hover': { backgroundColor: '#D8E0FF', color: '#4050FF' },
                  },
			  
		  },
		
	},
	  
*/
