import React from 'react';

import { LayVert2, LayHoriz2, LaySpace } from '../misc/layout.js';

import useRipple from "./useRipple.tsx";

import "./tabs.css"

//---------------------------------------------------

function ButImp( {tit, code, disabled, isAct, onClick} )
{
    const ref         = React.useRef(null);
    const ripples     = useRipple(ref);

    function cb_clk()
    {
        onClick( code );
    }

    let CLA = 'but';

    if (isAct) CLA += ' cur';
    if (disabled) CLA += ' dis';

    return ( <div ref={ref} onClick={cb_clk} className={CLA}>{tit}{ripples}</div> );
}


const But = React.memo( ButImp );




//--------------------------------------

export function DaTabs( {items, initCode, arDisCodes, center, children} )
{
    const [curCode, setCurCode] = React.useState( initCode );

    const cb_click = React.useCallback( (kk) => {
        setCurCode( kk );
    }, [curCode] );

    const ar_buts = [];

    for (let x = 0; x < items.length; x ++ )
    {
        const e = items[x];
        const CO = e.c;

        const DIS = arDisCodes && arDisCodes.indexOf(CO) !== -1;

        ar_buts.push( <But key={CO} tit={e.n} disabled={DIS} code={CO} isAct={curCode===CO} onClick={cb_click} /> );
    }

    let ar_ch = [];

    for (let x = 0; x < children.length; x ++ )
    {
        const ch = children[x];
        const CODE = ch.props.tabCode;  // forward prop
        ar_ch.push( <BodyWrap key={CODE} tabCode={CODE} cur={curCode} >{ch}</BodyWrap> );
    }

    return (

        <LayVert2 rows='50px 1fr' className="da_tabs" >

            <div className={ center ? "buts center" : "buts left"  }>
                {ar_buts}
            </div>

            <div style={{position: 'relative', width: '100%', height: '100%'}}>
                {ar_ch}
            </div>

        </LayVert2>

    );
}


//-----------------------------------------------------

function BodyWrapImp( {tabCode, cur, children} )
{
    if (cur !== tabCode) return null;
    return <>{children}</>;
}

const BodyWrap = React.memo( BodyWrapImp );

//------------------------------------------