import React from 'react';


import { NuPost } from '../api.js';
import * as DT from '../dt.js';

import { CmCachedUserLink } from '../desktop/ca_user_link.js';


// короткая инфа о событии в календаре
function EventShortInfo( {rid} )
{
	const [info, setInfo] = React.useState( null );
	
	React.useEffect( () => {
		
		function __ok(e)
		{
			setInfo( e.ro ); 
		}
		
		function __fail(e) 
		{
		}
		
		
		NuPost( 998, {ts_id: rid}, __ok, __fail );
		
	}, [] );
	
	let p_juice;
	
	if (info !== null)
	{
		//console.log('INFO: ', info);
		
		let s_dt = DT.DateTimeStrLocHum( DT.DateTimeParseIso( info.ts_time_start ) );
		 
		p_juice = (<div className="zz_com_border" style={{display: 'flex', flexDirection: 'column', gap: '4px', padding: '10px', margin: '10px'}}>
						<div>Старт: {s_dt}</div>
						<div style={{display: 'flex', gap: '10px'}}>Тренер: <CmCachedUserLink rid={info.ts_user_id} /></div>
						{ info.ts_impo_text !== '' ? (<div className="zz_text_secondary">{info.ts_impo_text}</div>) : null  }
					</div>);
	}
	else
	{
		p_juice = null;
	}
	
	return ( <div>{p_juice}</div> );
}


// окно с результатом РАЗМАЗЫВАНИЯ клиента по месячным событиям
export function MonSpreadResult( {eventIdList} )
{
	const num = eventIdList.length;
	
	const ar = [];
	
	for (let q of eventIdList)
	{
		ar.push( <EventShortInfo key={q} rid={q} /> );
	}
	
	return (
					<div style={{ height: '100%', position: 'relative', display: 'grid',  gridTemplateColumns: '1fr', gridTemplateRows: '1fr' }}>
					
						<div style={{padding: '10px', height: '100%', width: '100%', overflowY: 'scroll', position: 'absolute'}}>
					
							<div style={{color: '#304090'}}>КЛИЕНТ БЫЛ ВПИСАН В СОБЫТИЯ ({num}):</div>
					
							{ar}

							
						</div>
				
					</div>);
}
