import React from 'react';

import {DaToolTip} from '../misc/tooltip.js';

import Draggable from 'react-draggable';

import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import Modal from '@mui/material/Modal';
import Grow from '@mui/material/Grow'

import HelpCenterRoundedIcon from '@mui/icons-material/HelpCenterRounded';

import { BiSolidCaretDownSquare } from "react-icons/bi";


import "./win2.css"



//----------------------------------------------------

const gS1 = {display: 'flex', gap: '10px', alignItems: 'center'};

function MyHeadImp( {title, onClose, onMini, help} )
{
    const prt_mini = onMini ? (<div onClick={onMini} className="zz_win_btn2" style={{display: 'flex', alignItems: 'center', padding: '0px', background: '#FFFFFF', borderRadius: '2px'}}><BiSolidCaretDownSquare style={{ color: 'var(--fl-clr-primary)', width: '26px', height: '26px'}}/></div>) : null;
    

    const prt_close = onClose ? (<div onClick={onClose} className="zz_win_btn2" style={{display: 'flex', alignItems: 'center', padding: '0px', background: '#FFFFFF', borderRadius: '2px'}}><DisabledByDefaultIcon sx={{ color: '#FF4040', width: '26px', height: '26px'}}/></div>) : null;
    
    const prt_info = help ? (
                        <DaToolTip placement='bottom' title={help}>
                        <div className="zz_win_btn2" style={{display: 'flex', alignItems: 'center', padding: '0px', background: '#FFFFFF', borderRadius: '2px'}} >
                            <HelpCenterRoundedIcon sx={{color: '#6070F0'}} />
                        </div>
                        </DaToolTip>) : null;
    
    return (
        <div className='win2_head zalupka' >
            <div style={{paddingBottom: '6px', paddingLeft: '8px'}}>{title}</div>
            <div style={gS1}>
                {prt_info}
                {prt_mini}
                {prt_close}
            </div>
        </div>
    );
}

const MyHead = React.memo( MyHeadImp );

//-----------------------------------------


function __DummyCB(e)
{
    e.preventDefault();
    e.stopPropagation();
}







export function Win2( {visible, title, help, onClose, onMini, width, height, children} )
{
    //        
   // 

    return (

        <Modal open={visible} closeAfterTransition  >

            <Grow in={visible} timeout={200}>

            <div key='www' className="win2_ct"  >


                <Draggable bounds='parent' handle='.zalupka' onStart={__DummyCB} onDrag={__DummyCB} onStop={__DummyCB} >
                    <div className="win2_body" style={{width: width+'px', height: height+'px'}} >
                    

                        <MyHead  title={title} onClose={onClose} onMini={onMini} help={help} />
                        
                        <div style={{overflow: 'clip', position: 'relative', width: '100%', height: '100%'}}>
                            {children}
                        </div>
                    
                    </div>
                    
                </Draggable>


            </div>

                </Grow>

            </Modal>
    )
}
