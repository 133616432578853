import React from 'react';

import { DaCombo } from '../misc/combo.js';


import * as DT from '../dt.js';

// hmin hmax -> int    value: date   onChange(name,date_val)
export function DaComboHour( {name, label, hmin, hmax, value, onChange} )
{
    const lst = React.useMemo( () => {
        
        const r = [];
        for (let h = hmin; h <= hmax; h ++ )
        {
            r.push( {k: h, v: h+':00' } );
        }
        
        return r;
        
    }, [hmin, hmax] );
    
    
    function cb_chg(k,v)
    {
        const nd = DT.DateSetHMS(value, v, 0, 0);
        
        onChange(name, nd);
    }
    
    
    const cur_h = DT.DateTimeGetHours( value );
    
    
    return ( <DaCombo src={lst}  label={label} fld_k='k' fld_v='v'  value={cur_h} onChange={cb_chg} /> );
}
