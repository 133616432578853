import React from 'react';

import * as HLP from './helpers.js';

import * as COM_DEFS from './com_defs.mjs';

import { useGlobalShit } from './v3/hk_global_shit.js';

import * as TOAST from './v3/toast.js';

//-----------------------


// REWIRE for now...
export function ShowMsg( m )
{
    TOAST.ShowMsg( m );
}

export function ShowErr( m )
{
    TOAST.ShowErr( m );
}



    
    
export function useGlobEvActs( )
{
    const gs = useGlobalShit();
    
    
    const ACTS = gs.acts;
    
    function __get_by_id(id)
    {
        return HLP.ArrayOfDictsFindByPropValue(ACTS, 'ea_id', id);
    }

    function __get_by_special(sp)
    {
        return HLP.ArrayOfDictsFindByPropValue(ACTS, 'ea_special', sp);
    }
    

    function __test_can_mod(csv)
    {
        const ar = HLP.CsvSplit(csv);
        for (let x = 0; x < ar.length; x ++ )
        {
            const e = __get_by_id(ar[x]);
            if (!e) continue;
            if (e.ea_allow_mod < 1) return false;
        }
        return true;
    }
        
        

    function __act_fits_trg_act(act, trgAct)
    {
        const e = __get_by_id( act );
        if (!e) return false;

        const my_trgs = e.ea_allowed_dst_evt_csv;
        if (my_trgs.trim() === '')
        {
            return e.ea_id === trgAct;  // no dst list.. equal test
        }

        //console.log(' ACT ', act, ' -- got trg list: ', my_trgs);

        return HLP.CsvTestID(my_trgs, trgAct)
    }
        
    function __test_acts_csv_fits_evt_id(acts_csv, trg_id)
    {
        //console.log('TEST ACTS CSV... ACTS: ', acts_csv, ' -- TRG ', trg_id);

        const ar = HLP.CsvSplit(acts_csv);
        for (let x = 0; x < ar.length; x ++ )
        {
            if (__act_fits_trg_act(ar[x], trg_id))
            {
                //console.log('  ACT ', ar[x], ' -- match ', trg_id);
                return true;
            }
        }
        
        return false;   // no match
    }
        
        

    
    
    return {
        
        rows:       ACTS,
        getById:    __get_by_id,
        testCanMod: __test_can_mod,
        getBySpecial: __get_by_special,
        
        testActsCsvFitsEvtId:   __test_acts_csv_fits_evt_id,
    };
}



//------------

export function CustNameShorten(n)
{
	n = n.trim();
	n = n.replaceAll('  ', ' ');
	n = n.replaceAll('  ', ' ');
	let qq = n.split(' ');
	if (qq.length === 3)
	{
		return qq[0] + ' ' + qq[1][0] + '. ' + qq[2][0] + '.';
	}
	
	if (qq.length === 2)
	{
		return qq[0] + ' ' + qq[1][0] + '.';
	}
	
	
	return n;
}


//------------------------




// TODO: move
export function FmtPayOpBind(ctx,id)
{
    if (ctx < 1) return '';
    

    const e = HLP.ArrayOfDictsFindByPropValue( COM_DEFS.gPayOpsCtxDct, 'k', ctx);
    if (!e) return '???';
    
    if (ctx >= 50)
    {
        return e.v;
    }
    
    return e.v + ' id-' + id;
}






export function CommonReducer(sta, act)
{
	switch(act.type)
	{
		case 'val':
		{
			return { ...sta, [act.k]: act.v };
		}

        case 'vals':
        {
            const n = { ... sta };

            const dct = act.dct;

            for (let k in dct)
            {
                n[k] = dct[k];
            }

            return n;
        }
	}
	
	return sta;
}



