 import React from 'react';

 import { NuPost2 } from '../api';


 //------------------------------------------------

 const DA_DELAY_MS = 50000; //50000;


export function UpdCheckerShit()
{

    const [ initSer, setInitSer ] = React.useState( -1 );
    const [ curSer,  setCurSer ]  = React.useState( -1 );
    
    function cb()
    {
        //console.log('REQ');

        
        function __ok(e)
        {
            if (initSer === -1)
            {
                setInitSer( e.serial );
                setCurSer( e.serial );

                console.log('set initial updater ser: ', e.serial);
            }
            else
            {
                setCurSer( e.serial );

                console.log('set cur: ', e.serial);
            }
        }

        function __fail(e)
        {
        }

        NuPost2( 4108, {}, __ok, __fail );
    }

    React.useEffect(() => {
        const interval = setInterval(() => {
        
            cb();

        }, DA_DELAY_MS)
        return () => clearInterval(interval)
      })
      


      if (initSer === -1 || curSer === -1) return null;

    if (initSer !== curSer)
    {
        function cb_clk()
        {
            window.location.reload( true );
        }

        return <div className="zz_upd_checker" onClick={cb_clk}>
            Была обновлямба! Клик или F5, чтобы перезагрузить страницу!
        </div>;

    }
}