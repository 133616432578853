import React from 'react';


import { NuPost, NuPost2 } from './api.js'; 

import * as HLP from './helpers.js';

import { FixedSizeList } from 'react-window';

import InfiniteLoader from "react-window-infinite-loader";

import AutoSizer from "react-virtualized-auto-sizer";


import { LayVert2, LayHoriz2 } from './misc/layout.js';

import { CommonReducer } from './misc_glob.js';

import {DaIconBtn} from './misc/icon_btn.js'; 

import ReplayIcon from '@mui/icons-material/Replay';


import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';


import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';

import { useUserOpts, useUserOptsDispatch } from './hk_user_opts.js';

import './grid3.css';

//-------------------------------


function __GenNulls(num)
{
    const r = new Array(num);
    r.fill(null, 0);
    return r;
}


function __ArrayResize(arr, newSize)
{
    const originLength = arr.length;
    arr.length = newSize;
    (newSize > originLength) && arr.fill(null, originLength);
}


//------------------------------------


function DOM_GetMeOrParentWithClass(e, cla, eStop)
{
    for(;;)
    {
        if ( typeof(e.className) === 'string' && e.className.indexOf(cla) !== -1) return e;
        e = e.parentElement;
        if (e === eStop) break;
    }

    return null;
}

function DOM_ChildIndex(e_base, e_find)
{
    const ch = e_base.children;
    const le = ch.length;

    for (let x = 0; x < le; x ++ )
    {
        if (ch[x] === e_find) return x;
    }

    return -1;
}

//------------------------


function MyRow( {index, style, data} )
{
    //console.log('INTO ROW: ', data);

    const rows  = data.rows;
    const sel   = data.selected;
    const e     = rows[index];

    let CL = (index & 1) ? 'g3row even' : 'g3row odd';
    
    if (e === null)
    {
        return <div className={CL} style={{...style, width: data.tot_w+'px'}}></div>;
    }
    
    
    const kk = e[data.uniq];
    if ( sel && sel.indexOf(kk) !== -1) CL +=' lite';
    
    
    const cols = data.cols;
    
    let   www = [];
    const eee = [];
    
    let j;
    let rc;
    
    const NNN = cols.length;

    for (let x = 0; x < NNN; x ++ )
    {
        const CC = cols[x];
        const k  = CC.fld;
        
        rc = CC.renderCell;
        
        if (!rc) j = e[k];
        else
        {
            j = React.createElement(rc, {value: e[k], row: e, ex:data.ex} , null);
        }
        
        eee.push( <div className={"g3cell " + CC.align} key={k} >{ j }</div> );
    }
    
    eee.push( <div className="g3cell" key='padpad'></div> );
    
    function cb_clk(x1)
    {


        //console.log('CLICKED INSIDE ROW... ', x1, ' ---- ', x2);
        data.clk_row(kk,index);
    }
    
    function cb_dbl(x1)
    {
        let ci = -1;
        const ct    = x1.currentTarget;
        const trg   = x1.target;

        const e_cell = DOM_GetMeOrParentWithClass( trg, 'g3cell', ct );

        if (e_cell)
        {
            ci = DOM_ChildIndex( ct, e_cell );
        }

        data.clk_dbl(kk,index,ci);
    }
    
    return (
        <div onClick={cb_clk} onDoubleClick={cb_dbl} className={CL} style={{...style, width: data.tot_w+'px', gridTemplateColumns: data.temp_cols}}  >
            {eee}
        </div>
    );
}


function __ColInd(cols,k)
{
    return HLP.ArrayOfDictsFindByPropValueIndex(cols, 'fld', k);
}

function __RecalcTotW(col_widths)
{
    let r = 0;
    for (let x = 0; x < col_widths.length; x ++ )
    {
        r += col_widths[x];
    }
    
    return r;
}



const BATCH_ITEMS_SIZE = 50;

const PAD_COL_SZ = 60;

function __GenMyState( o )
{
    const www = [];

    for (let x = 0; x < o.cols.length; x ++ )
    {
        //const CC = o.cols[x];
        //www.push( CC.width );
        www.push( o.initWidths[x] );
    }
    
    www.push( PAD_COL_SZ );
    
    const tot_w = __RecalcTotW(www);
    
    return {
  
        rows: __GenNulls(BATCH_ITEMS_SIZE),
        sort_field: o.sort_field, 
        sort_dir:   o.sort_dir,
        
        
        off_x:  0,
        
        serial: 0,
        
        
        widths: www,
        tot_w:  tot_w,
    }
}




//------------------------------------

function __MakeWidthKey(code,fld)
{
    return 'grid--'+code+'--'+fld+'--w';
}

function useGridOptSaver( code, cols, widths )
{
    //const u_opts = useUserOpts();
    const u_disp = useUserOptsDispatch();
    
    React.useEffect( () => {
        
        if (!code) return;
        
        
        //console.log('GRID with code.. load settings.. ', code);
        
        return () => {
          
            //console.log('unmount grid with code ', code);  
            
            const ddd = {};
            
            for (let x = 0; x < cols.length; x ++ )
            {
                const CC = cols[x];
                const k = __MakeWidthKey(code, CC.fld);
                ddd[k] = widths[x];
            }
            
            //console.log('DD: ', ddd);
            
            u_disp( {type: 'vals', dct: ddd} );
        };
        
    }, [widths] );
    
    
    return null;
}



function useGridOptLoader( code, cols )
{
    const u_opts = useUserOpts();
    
    const initWidths = [];
    

    for (let x = 0; x < cols.length; x ++ )
    {
        const CC = cols[x];
        
        const k = __MakeWidthKey(code, CC.fld);
        
        let ww = u_opts[k];
        if (ww === undefined || ww < 30)
        {
            //console.log('no width stored for... (use def)', CC.fld);
            ww = CC.width; 
        }
        else
        {
            //console.log('use stored width.. ', ww);
        }
        
        initWidths.push( ww );
    }
    
    
    return initWidths;
}


export function Grid3( props )
{
    const iwiw = useGridOptLoader( props.code, props.cols );
    
    return ( <Grid3Imp {...props} initWidths={iwiw} /> );
}

//------------------------------------

function Grid3Imp( {apiCmd, isNuApi, cols, uniq, sort_field, sort_dir, flt, singleSel, selected, onChangeSel, onDblClick, code, initWidths, ex} )
{

    const [sta, dispatch] = React.useReducer( CommonReducer, {sort_field, sort_dir, cols, initWidths}, __GenMyState );
    
    //console.log('Grid3Imp ');

    const gogo = useGridOptSaver( code, cols, sta.widths );


    function cb_item_key(index, data)
    {
        const it = data.rows[index];
        if (it) return it[uniq];
        return 'load'+index;
    }

    
    function isItemLoaded(i)
    {
        return sta.rows[i] !== null;
    }
    
    async function loadMoreItems(sta_i, stop_i)
    {
        //console.log('load more %d %d', sta_i, stop_i);
        
        function __ok(e)
        {
            //console.log('load more -> ok');
            
            //console.log(e);
            
            
            
            const nnn = Array.from( sta.rows );
            
            
            if (nnn.length !== e.total)
            {
                //console.log('need to resize ! ', e.total);
                __ArrayResize(nnn,e.total);
            }
            
            
            const srows = e.rows;
            
            for (let x = 0; x < srows.length; x ++ )
            {
                nnn[sta_i+x] = srows[x];
            }
            
            dispatch( {type:'val',k:'rows',v:nnn} );
        }
        
        function __fail(e)
        {
            console.warn('load more -> fail');
        }
        
        if (isNuApi)
        {
            NuPost2(apiCmd, {fi: sta_i, nu:stop_i-sta_i+1, o: sta.sort_field, d: sta.sort_dir, flt:flt}, __ok, __fail);
        }
        else
        {
            NuPost(apiCmd, {fi: sta_i, nu:stop_i-sta_i+1, o: sta.sort_field, d: sta.sort_dir, flt:flt}, __ok, __fail);
        }
    }



    
    const www = React.useMemo( () => {
        return sta.widths.join('px ') + 'px';
    }, [sta.widths] );
    
    
    const coco = React.useRef(null);
    

    function cb_sc(e)
    {
        const t1 = e.currentTarget;
        const e1 = t1.querySelector(".zala");
        const vv = e1.scrollLeft;
        dispatch( {type:'val',k:'off_x',v:vv} );
    }
    


    
    React.useEffect(() => {

        const t1 = coco.current;

        if (t1)
        {
            //console.log('install...');
            t1.addEventListener('scroll', cb_sc, true);
        }
    
      return () => {
            
            const t1 = coco.current;
            
            if (t1)
            {
                t1.removeEventListener('scroll', cb_sc, true);
            }
      }
    
  }, []);
  
  
  

  
  
    function cb_resize_col(kk, diff)
    {
        //console.log('R CCC ', cols);
        
        const ci = __ColInd(cols,kk);
        if (ci === -1)
        {
            console.warn('WTF ???');
            return;
        }
        
        const nu_cols = HLP.DeepCopy( sta.widths );
        
        let w = nu_cols[ci] + diff;
        
        if (w < 50) w = 50;
        else if (w > 1000) w = 1000;
        
        nu_cols[ci] = w;
        
        //console.log('resize col.. ind %d to %d', ci, w);
        
        const tot_w = __RecalcTotW(nu_cols);
        
        dispatch( {type: 'val', k: 'widths', v: nu_cols} );
        dispatch( {type: 'val', k: 'tot_w',  v: tot_w} );

    }


    function __intl_refresh()
    {
        const LE = (sta.rows.length < 1) ? BATCH_ITEMS_SIZE : sta.rows.length;
        const t1 = __GenNulls(LE);
        
        //dispatch( {type:'val',k:'rows',     v:t1} );
        //dispatch( {type:'val',k:'serial',   v:sta.serial+1} );

        dispatch( {type:'vals', dct: { rows: t1,
                                      serial: (sta.serial+1)
                                    }  } );
    }

    function cb_refresh()
    {
        __intl_refresh();
    }

    function cb_click_sort(fff, ddd)
    {
        //console.log('BASE SORT.. ', fff, ddd);
        
        __intl_refresh();

        dispatch( {type:'val',k:'sort_field',   v:fff} );
        dispatch( {type:'val',k:'sort_dir',     v:ddd} );
    }
    
    React.useEffect( () => {
        
            //console.log('filter changed... ');
            
            __intl_refresh();
            
    }, [flt] );
    
    const hasMountedRef     = React.useRef(false);
    const infiniteLoaderRef = React.useRef(null);
    
    
    // Each time the sort prop changed we called the method resetloadMoreItemsCache to clear the cache
    React.useEffect(() => {
        
        //console.log('SERIAL CHANGED ', sta.serial);
        
        // We only need to reset cached items when "sortOrder" changes.
        // This effect will run on mount too; there's no need to reset in that case.
        if (hasMountedRef.current)
        {
            if (infiniteLoaderRef.current) {
                //console.log('DO DA SHIT !');
                infiniteLoaderRef.current.resetloadMoreItemsCache(true);
            }
        }
        hasMountedRef.current = true;
        
    }, [sta.serial]);
    
    
    function cb_clk_row_key(kk,ii)
    {
        if (!onChangeSel) return;
        
        const this_row = sta.rows[ii];
        
        let nu_sel;
        
        if (!singleSel)
        {
            nu_sel = HLP.DeepCopy( selected );
            
            const at = selected.indexOf(kk);
            
            if (at === -1)  nu_sel.push( kk );
            else            nu_sel.splice(at, 1);
        }
        else
        {
            nu_sel = [kk];
        }
        
        
        onChangeSel( nu_sel, this_row );
    }
    
    function cb_clk_dbl(kk,ii,ci)
    {
        if (!onDblClick) return;
        const this_row = sta.rows[ii];
        const this_col = cols[ci];  // ---- TODO.. bound check
        onDblClick( kk, this_row, this_col );
    }
    
    const CNT = sta.rows.length;
    
    const my_d = {  rows: sta.rows,
                    cols: cols,
                    temp_cols: www,
                    tot_w: sta.tot_w,
                    selected: selected,
                    uniq: uniq,
                    ex: ex,
                    clk_row: cb_clk_row_key,
                    clk_dbl: cb_clk_dbl};
                    
 
    
    return (

    <LayVert2 rows='max-content 1fr max-content' className="g3base_shit" >
    
        <MyHeadRow cols={cols} temp_cols={www} tot_w={sta.tot_w} off_x={sta.off_x} 
                    sortFld={sta.sort_field} sortDir={sta.sort_dir} 
                    onClickSort={cb_click_sort} onResizeCol={cb_resize_col} />
    
        <div ref={coco} className="wh100perc" >
            <AutoSizer>
            {({ width, height }) => (
        
                <InfiniteLoader
                    ref={infiniteLoaderRef}
                    isItemLoaded={isItemLoaded}
                    itemCount={CNT}
                    loadMoreItems={loadMoreItems}
                    minimumBatchSize={50}
                    threshold={30} >
                
                {({ onItemsRendered, ref }) => (
                    <FixedSizeList  overscanCount={6} className="zala" itemKey={cb_item_key} itemCount={CNT}  itemSize={54}  itemData={my_d} width={width} height={height} 
                    onItemsRendered={onItemsRendered}
                    ref={ref}>
                    {MyRow}
                    </FixedSizeList>
                )}
                
                </InfiniteLoader>
        
        
                )}
            </AutoSizer>
        </div>
        
        <MyFooter numSel={selected?selected.length:0} cnt={CNT} onClickRefresh={cb_refresh} />
        
        

    </LayVert2>

    );

}


// #6A4CD7

//-------------------------------------------


function MyFooterImp( {numSel, cnt, onClickRefresh} )
{
    const s_sel = numSel > 0 ? ('выбрано: '+numSel) : null;
    
    return (
    
        <div className="g3foot_row" >
            <div></div>
            <div className="rt">
                <div>{s_sel}</div>
                <div>строк: {cnt}</div>
            </div>
            <DaIconBtn  icon={ReplayCircleFilledIcon} onClick={onClickRefresh} clr='#F0F0FF'  />
        </div>
    
    );
}

const MyFooter = React.memo( MyFooterImp );



//------------------------------------------------

const gSxIcInact = {opacity: 0.5, pointerEvents: 'none'};
const gSxIcAct = {pointerEvents: 'none'};

function MyHeadCellImp( {name, fld, align, canSort, sortFld, sortDir, onClick, onResizeStart, isResizing} )
{
    let p_ic;
    let my_cl;
    let clk;
    
    if (sortFld === fld)
    {
        my_cl = "hdc cur";
    }
    else
    {
        my_cl = "hdc";
    }
    
    
    if (canSort)
    {
        if (onClick)
        {
            clk = (ee) => {
                //console.log('CLK SORT ', ee);
                
                if (ee.target !== ee.currentTarget) return;
                
                const nu_dir = sortDir === 'asc' ? 'desc' : 'asc';
                onClick( fld, nu_dir );
            };
        }
        
        
        if (sortFld === fld)
        {
            p_ic = p_ic = (sortDir === 'asc') ? <ArrowDropUpIcon sx={gSxIcAct} /> : <ArrowDropDownIcon sx={gSxIcAct}/>;
        }
        else
        {
            p_ic = <UnfoldMoreIcon sx={gSxIcInact}/>;
        }
        
    }
    else
    {
        clk = null;
        p_ic = null;
    }
    
    function cb_my_drag_start(e)
    {
        //if (isResizing) return;
        //console.log('drg sta..', e);
        onResizeStart( e, fld );
    }
    

    
    my_cl += ' ' + align;
    
    return (    <div className={my_cl} onMouseDown={clk} >
                    { name }{p_ic}
                    <div className="rs" onMouseDown={cb_my_drag_start} ></div>
                </div>
    
    );
}

const MyHeadCell = React.memo( MyHeadCellImp );

//----------------------------------------------------

const gStaHead = {
  
    is_drag:    false,
    drag_x:     0,
    drag_k:     null,
    
    drag_initial_x: 0,
};


function MyHeadRow( {cols, temp_cols, tot_w, off_x, sortFld, sortDir, onClickSort, onResizeCol} )
{
    const [sta,dispatch] = React.useReducer( CommonReducer, gStaHead );
    
    const ref = React.useRef( null );
    
    const eee = [];
    
    
    function cb_drag_start(ee, col_k)
    {
        //console.log('drag started ! ', col_k);
        
        const e_par = ref.current;
        
        if (!e_par)
        {
            //console.warn('EMPTY HEAD REAF ???');
            return;
        }
        
        const cord = HLP.DomAbsToLoc(e_par, ee.clientX, ee.clientY);
        
        dispatch( {type:'val', k:'drag_x',          v: cord[0] } );
        dispatch( {type:'val', k:'drag_initial_x',  v: cord[0] } );
        
        
        
        dispatch( {type:'val', k:'is_drag',v:true} );
        dispatch( {type:'val', k:'drag_k',v:col_k} );
    }
    
    function cb_up(ee)
    {
        if (!sta.is_drag)
        {
            //console.log('not dragging !');
            return;
        }
        
        const diff_x = sta.drag_x - sta.drag_initial_x;
        if (diff_x === 0) return;

        onResizeCol( sta.drag_k, diff_x );
        
        dispatch( {type:'val', k:'is_drag',v:false} );
        dispatch( {type:'val', k:'drag_k',v:null} );
    }
    
    function cb_move(ee)
    {
        //console.log('move ! ', ee);
        
        if (!sta.is_drag)
        {
            //console.log(' NO DRAG ???');
            return;
        }
        
        const e_par = ref.current;
        
        if (!e_par)
        {
            //console.warn('EMPTY HEAD REAF ???');
            return;
        }
        
        const cord = HLP.DomAbsToLoc(e_par, ee.clientX, ee.clientY);
        
        //console.log(' X : ', cord[0]);
        
        dispatch( {type:'val', k:'drag_x',v: cord[0] } );
    }
    

    
    for (let x = 0; x < cols.length; x ++ )
    {
        const CC = cols[x];
        const k  = CC.fld;
        
        const IS_RES = sta.is_drag===true && sta.drag_k===k;
        
        
        eee.push( <MyHeadCell   key={k}
                                name={CC.name}
                                onClick={sta.is_drag ? null : onClickSort}
                                onResizeStart={cb_drag_start} isResizing={IS_RES}
                                align={CC.align}
                                fld={k}
                                canSort={CC.sort===true}
                                sortFld={sortFld}
                                sortDir={sortDir} /> );
    }
    
    eee.push( <div key='padpad'></div> );
    
    // '#6A4CD7
    

    
    React.useEffect(() => {
        //console.log('ADD LISTENERS !');
        document.addEventListener('mousemove', cb_move);
        document.addEventListener('mouseup', cb_up);
        return () => {
                //console.log('REMO LISTENERS...');
                document.removeEventListener('mouseup', cb_up);
                document.removeEventListener('mousemove', cb_move);
            }
    }, [sta.is_drag, sta.drag_x]);
    
    
    return (
    
        <>
    
        <div className="g3head_row_conta" ref={ref}>
            <div className="g3head_row" style={{left: (-off_x)+'px', width: tot_w+'px', gridTemplateColumns: temp_cols}}>
             {eee}
             </div>
        </div>
        
        {   sta.is_drag ? 
            <div className="g3dragline" style={{left: sta.drag_x+'px'}}></div>
            : null
        }
        
        </>
    
    );
}
