
import * as HLP from './helpers.js';
import * as DEFS from './defs.js';

 
import { GetBackendBaseURL, GetBackendBaseURL2 } from './api.js';

import { useQuery, useQueries } from 'react-query';




async function MyLittleCallback( qq )
{
	//console.log('CALLED API4 .. ', qq);
	
    let my_data = { c: qq.queryKey[0],
                    o: qq.queryKey[1],
                    t: HLP.CookieGet( DEFS.FIGHTLIFE_SESSION_COOKIE ) };
                    
    // 
    
    const TRG = GetBackendBaseURL() + 'api_j.php';
    
    const res = await fetch(TRG, {
							mode: 'cors',
							cache: 'no-store',
							method: 'POST',
							//credentials: "include",
        
							headers: {'content-type': 'application/json'},

							body: JSON.stringify(my_data),
    });
    
    const j = await res.json();
    
    
    //console.log('GOT JSON: ', j);
    
    if (j.c === 0)
    {
		throw 'API: ' + j.m;
	}
    
    return j;
}


// refetchOnWindowFocus: false,


const STALE_TIME = 30000;
const RETR = 2;

export function useApiQuery( cmd, obj, opts_dct )
{
	return useQuery( [cmd,obj], MyLittleCallback, {  refetchOnWindowFocus: false, staleTime: STALE_TIME, retry: RETR, ...opts_dct  } );
}



export function useApiQueries( arr )
{
	let t = [];
	
	for (let x = 0; x < arr.length; x ++ )
	{
		let e = arr[x];
		t.push( { queryKey: [e[0], e[1]], queryFn: MyLittleCallback, staleTime: STALE_TIME, retry: RETR  } );
	}
	
	return useQueries( t );
}


//--------------




async function MyLittleCallback2( qq )
{

    let my_data = { c: qq.queryKey[0],
                    o: qq.queryKey[1],
                    t: HLP.CookieGet( DEFS.FIGHTLIFE_SESSION_COOKIE ) };
                    
    
	const TRG = GetBackendBaseURL2() + 'apiOfMyDick';
    
    const res = await fetch(TRG, {
							mode: 'cors',
							cache: 'no-store',
							method: 'POST',
							//credentials: "include",
        
							headers: {'content-type': 'application/json'},

							body: JSON.stringify(my_data),
    });
    
    const j = await res.json();
    
    
    //console.log('GOT JSON: ', j);
    
    if (j.c === 0)
    {
		throw 'API: ' + j.m;
	}
    
    return j;
}



export function useApiQuery2( cmd, obj, opts_dct )
{
	return useQuery( [cmd,obj], MyLittleCallback2, {  refetchOnWindowFocus: false, staleTime: STALE_TIME, retry: RETR, ...opts_dct  } );
}



export function useApiQueries2( arr )
{
	let t = [];
	
	for (let x = 0; x < arr.length; x ++ )
	{
		let e = arr[x];
		t.push( { queryKey: [e[0], e[1]], queryFn: MyLittleCallback2, staleTime: STALE_TIME, retry: RETR  } );
	}
	
	return useQueries( t );
}
