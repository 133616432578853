import React from 'react';


import * as DEFS from '../defs.js';
import * as HLP from '../helpers.js';


import { Win2 } from '../misc/win2.js';

import { useGlobalShit } from './hk_global_shit.js';

import { DaButton } from '../misc/but.js';


//-----


function MyJuice(  )
{
    const gs = useGlobalShit(); 

    function cb_yes()
    {
        HLP.CookieSet( DEFS.FIGHTLIFE_SESSION_COOKIE, '' );
        window.location.reload();
    }

    return (
    
        <div style={{padding: '16px', display: 'flex', flexDirection: 'column', gap: '16px'}}>

            <DaButton title='РАЗЛОГИНИТЬСЯ' onClick={cb_yes}/>
        </div>

  
    );
}


export function WinUserLogin( {visible, onClose} )
{   
	return ( <Win2 visible={visible} title='Аккаунт' width={300} height={140}   onClose={onClose} >
                <MyJuice />
			</Win2>
		);
}
