import React from 'react';

import * as DEFS from '../defs.js';
import * as HLP from '../helpers.js';

import { NuPost, NuPost2 } from '../api.js';


import { WinHelpConta, WinHelpElem } from '../misc/win_help.js';


import { ViReturnForm2, useReturnFormSate } from './ret_form2.js';


import { CommonReducer, ShowErr } from '../misc_glob.js';

import { DaIconBtn } from '../misc/icon_btn.js';
import { LayVert2, LayHoriz2, LaySpace } from '../misc/layout.js';
import { OverlayLoading } from '../misc/ovr_loading.js';
import { DaVertScrollFrame, DaVertScrollJuice } from '../misc/vscr.js';
import { Win2 } from '../misc/win2.js';


import { DaButton } from '../misc/but.js';

import Collapse from '@mui/material/Collapse';


import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';

import CloseIcon from '@mui/icons-material/Close';

import { EvActDispNameCSV } from '../desktop/ev_act.js';


import { TrpAllowedHoursInfo } from '../desktop/allowed_hours.js';
//-------------------------------------


// текст - СТАТУС ТРЕНИ в абонементе
function CmTrpStatus(pp)
{
	return <div style={{...pp.sx, color: DEFS.gTrpStatusClr[pp.value]}}>{ DEFS.gTrpStatusDisp[pp.value] }</div>;
}


//----------------------------------------------


function MyListItemImp(pp)
{
	const r = pp.row;

	const AH = r.trp_allowed_hours_json;
	
	return (

		<LayHoriz2 cols='100px 1fr 30px' padding='10px' gap='6px' sx={{position: 'relative', alignItems: 'center', height: '40px'}} >
			<CmTrpStatus value={r.trp_status}/>
			<div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center'}}>
				<EvActDispNameCSV csv={r.trp_acts_csv} />
				<TrpAllowedHoursInfo jsonStr={AH} />
			</div>
			{pp.icon}	
		</LayHoriz2>

	);
}



const MyListItem = React.memo( MyListItemImp );


//---------------------------





// список трень (КУДА)   -- юзается в переносе трень/возвратах
function CmTrpTransferListDstImp(pp)
{
	const rows 				= pp.rows;
	const lstSelectedIds 	= pp.lstSelectedIds;
	const on_rem			= pp.onRemove;
	
	let tt = [];
	
	for (let x = 0; x < rows.length; x ++ )
	{
		const r = rows[x];
		
		const TRP_ID 	= r.trp_id;
		
		const IS_ADDED 	= lstSelectedIds.indexOf( TRP_ID ) !== -1;
		
		
		const clk = () => {
			on_rem(TRP_ID);
		};
	
    
        const p_ic = <DaIconBtn  clr='#A0A0B0' icon={CloseIcon} onClick={clk}  />;
		

		
		tt.push(  <Collapse key={TRP_ID} in={IS_ADDED} timeout={80}  >
							<MyListItem row={r} icon={p_ic} />
					</Collapse> );

	}
	
	return (	
    
                    <DaVertScrollFrame>
                        <DaVertScrollJuice>
                        { tt }
                        </DaVertScrollJuice>
                    </DaVertScrollFrame>
	
	);
}


// список трень (ИСТОЧНИК)    -- юзается в переносе трень/возвратах
function CmTrpTransferListSrcImp(pp)
{
	const rows 				= pp.rows;
	const lstSelectedIds 	= pp.lstSelectedIds;
	const on_move			= pp.onMove;
	
	
	let tt = [];
	
	for (let x = 0; x < rows.length; x ++ )
	{
		const r = rows[x];
		
		const TRP_ID 	= r.trp_id;
		
		const IS_ADDED 	= lstSelectedIds.indexOf( TRP_ID ) !== -1;
		

		
		let p_ic;
		
		if (r.trp_status === DEFS.TRP_STATUS_UNUSED && !IS_ADDED)
		{
			const clk = () => {
				on_move(TRP_ID);
			};
            
            p_ic = <DaIconBtn  clr='#A0A0B0' icon={DoubleArrowIcon} onClick={clk}  />;
			
		}
		else
		{
			p_ic = <div style={{width: '10%'}}></div>;
		}
		
		
		tt.push(  <Collapse key={TRP_ID} in={!IS_ADDED} timeout={80}  >
							<div><MyListItem row={r} icon={p_ic} /></div>
						</Collapse> );
		
	}

	

	return (
                    <DaVertScrollFrame >
                        <DaVertScrollJuice>
                        { tt }
                        </DaVertScrollJuice>
                    </DaVertScrollFrame>
	
	);
}

//-------------------

const CmTrpTransferListDst = React.memo( CmTrpTransferListDstImp );
const CmTrpTransferListSrc = React.memo( CmTrpTransferListSrcImp );

//-----------------------



const gSta = {
  
    busy:     false,
    rows:           [],
    lst_selected_ids: [],

    
};

function MyBody( {src_rid, onOk} )
{
    const [sta, dispatch] = React.useReducer( CommonReducer, gSta );
    

	const hxRt = useReturnFormSate();

    
    React.useEffect( () => {
        
        function __ok(e)
        {
			dispatch( {type: 'vals', dct: {busy: false, rows: e.rows} } );
        }
        
        function __fail(e)
        {
            dispatch( {type: 'val', k:'busy', v:false} );
            ShowErr(e.m);
        }
        
        dispatch( {type: 'val', k:'busy', v:true} );
        
        NuPost(991, {pay_id:src_rid}, __ok, __fail );
        
    }, [] );
    
    
    
	function cb_submit()
	{
		function __ok(e)
		{
			dispatch( {type: 'val', k:'busy', v:false} );
			onOk();
		}
		
		function __fail(e)
		{
			dispatch( {type: 'val', k:'busy', v:false} );
			ShowErr(e.m);
		}
		
		dispatch( {type: 'val', k:'busy', v:true} );


		const pf = hxRt.packSV();

		NuPost2(992, {	pay_id: src_rid,
						trp_ids: sta.lst_selected_ids,
						pf: pf,
						
						}, __ok, __fail);

	}
    
	function onMove(id)
	{
		const nu = HLP.DeepCopy( sta.lst_selected_ids );
		nu.push( id );
        dispatch( {type: 'val', k:'lst_selected_ids', v:nu} );
	}
	
	function onRemove(id)
	{
		const nu = HLP.DeepCopy( sta.lst_selected_ids );
		const at = nu.indexOf(id);
		if (at !== -1) nu.splice(at, 1);
		dispatch( {type: 'val', k:'lst_selected_ids', v:nu} );
	}
    
    
    
    
   
	

	


	const NUM_SEL 	= sta.lst_selected_ids.length;

	const DIS_SUB = NUM_SEL < 1 || hxRt.sta.comm.trim() === '';
    
    return (
        <>
        <LayVert2 rows='1fr max-content' >

            <LayHoriz2 cols='1fr 1fr 1fr' padding='8px' >
                <LayVert2 rows='max-content 1fr' sx={{borderRight: '1px solid #00000020'}}>
                    <div style={{fontWeight: 800, textAlign: 'center'}} className='zz_text_secondary'>СЕЙЧАС В АБОНЕМЕНТЕ:</div>
                    <CmTrpTransferListSrc rows={ sta.rows } lstSelectedIds={sta.lst_selected_ids} onMove={onMove} />
                </LayVert2>
                <LayVert2 rows='max-content 1fr' >
                    <div style={{fontWeight: 800, textAlign: 'center'}} className='zz_text_secondary'>ВОЗВРАЩАЕМ ({NUM_SEL} шт):</div>
                    <CmTrpTransferListDst rows={sta.rows} lstSelectedIds={sta.lst_selected_ids} onRemove={onRemove} />
                    
                </LayVert2>

				<LayVert2 rows='max-content 1fr'>
					<ViReturnForm2 hxState={hxRt} />
					<LaySpace />
					<LayHoriz2 cols='1fr max-content'>
						<LaySpace />
						<DaButton disabled={DIS_SUB} onClick={cb_submit} title='ОФОРМИТЬ'/>
					</LayHoriz2>
				</LayVert2>

            </LayHoriz2>
            
    
                     
        </LayVert2>
        
        <OverlayLoading active={sta.busy} />
        
        </>
    
    );
}





const gHelp1 = (<WinHelpConta>

    <WinHelpElem tit='ВОЗВРАТ ТРЕНИРОВОК ИЗ АБОНЕМЕНТА' text='Выбираем какие трени возвращаем. Пишем комментарий / причину. Указываем как отдаём деньги.' />
</WinHelpConta>);



            
                
// ПОПАП - возврат трень абонемента   (обычный абон)
export function WinPayRets( {visible, src_rid, onClose, onOk} )
{
    return (
        <Win2 visible={visible} help={gHelp1} title='ОФОРМИТЬ ВОЗВРАТ ТРЕНИРОВОК' onClose={onClose} width={1160} height={540}  >
            <MyBody src_rid={src_rid} onOk={onOk} />
        </Win2>
        
        );
}



 
