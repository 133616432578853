

export const PAY_OPS_CTX_NULL           = 0;
export const PAY_OPS_CTX_ABON           = 1;
export const PAY_OPS_CTX_PR_GOOD        = 2;
export const PAY_OPS_CTX_RET_TRP        = 3;
export const PAY_OPS_CTX_BALANCE_DEPO   = 4;
export const PAY_OPS_CTX_MOD_TRP        = 5;

export const PAY_OPS_CTX_STAFF_SALARY   = 50;   // зарплата сотрудника
export const PAY_OPS_CTX_MAINTAIN       = 51;   // 'траты клуба'
export const PAY_OPS_CTX_STAFF_ADVANCE  = 52;   // зп аванс

export const gPayOpsCtxDct = [

    {k: PAY_OPS_CTX_NULL,           v: 'без привязки' },
    {k: PAY_OPS_CTX_ABON,           v: 'абонемент' },
    {k: PAY_OPS_CTX_PR_GOOD,        v: 'товар' },
    {k: PAY_OPS_CTX_RET_TRP,        v: 'возврат трень' },
    {k: PAY_OPS_CTX_MOD_TRP,        v: 'мод трени' },

    {k: PAY_OPS_CTX_STAFF_SALARY,   v: 'зарплата' },
    {k: PAY_OPS_CTX_MAINTAIN,       v: 'траты на клуб' },
    {k: PAY_OPS_CTX_STAFF_ADVANCE,  v: 'аванс' },

];

//-------------------------------------------




// СТАТУС СОБЫТИЯ В КАЛЕНДАРЕ
export const TS_STATUS_OPEN			= 0;	// открыто
export const TS_STATUS_CLOSED 		= 1;	// закрыто
export const TS_STATUS_ABORTED 		= 2;	// отменена



// статус трени из пула
export const TRP_STATUS_UNUSED		= 0;	// незаюзанная
export const TRP_STATUS_BOUND		= 1;	// забита на дату
export const TRP_STATUS_CLOSED		= 2;	// закрыта
export const TRP_STATUS_RETURNED	= 3;	// возврат
export const TRP_STATUS_BURNED      = 4;    // сгорела



//------------------------------------------------------------

export const ABON_LOGIC_NORMAL		= 0;	// купил - потратил
export const ABON_LOGIC_MONTHLY		= 1;	// групповые на месяц

export const gAbonLogicDisp = [ 'ОБЫЧНЫЙ', 'МЕСЯЧНЫЙ' ];

//----------------------


// доп поля к КЛИЕНТАМ
export const CU_EX_FIELD_COMMENT		  = 1;	  // ВАЖНЫЙ коммент админов на юзера
export const CU_EX_FIELD_DESIRE			  = 2;    // пожелания клиента
export const CU_EX_FIELD_SPORT_BG		  = 3;    // опыт в спорте
export const CU_EX_FIELD_MED_RESTR		  = 4;    // мед противопок
export const CU_EX_FIELD_SOC_TG			  = 5;    // соц сети
export const CU_EX_FIELD_SOC_INSTA		  = 6;    // соц сети
export const CU_EX_FIELD_SOC_VK			  = 7;    // соц сети
export const CU_EX_FIELD_HOW_FAR		  = 8;    // где живёт
export const CU_EX_FIELD_FAV_TRAINERS_CSV = 9;	  // любимые тренера (csv user ids)
export const CU_EX_FIELD_SPECIAL_CARE     = 10;   // галочка ОСОБЕННЫЙ ребёнок

export const CU_EX_FIELD_DRPR_BOX		  = 100;	// галочки в анкете - предпочтения   
export const CU_EX_FIELD_DRPR_KICKBOX	  = 101;	// галочки в анкете - предпочтения   
export const CU_EX_FIELD_DRPR_MMA		  = 102;	// галочки в анкете - предпочтения   
export const CU_EX_FIELD_DRPR_THAI		  = 103;	// галочки в анкете - предпочтения   
export const CU_EX_FIELD_DRPR_FUNC		  = 104;	// галочки в анкете - предпочтения   

export const CU_EX_FIELD_PREF_CONTACT_CSV = 110;	// удобные способы связи (CSV)
export const CU_EX_FIELD_CHILDREN_CSV     = 111;    // список детей (CU-ID  CSV)

export const CU_EX_FIELD_DISABLE_RESERVE  = 120;    // запрещена бронь ?


export const USER_ROLES = [ 'SUPER', 'ТРЕНЕР', 'БОТ', 'АДМИН', 'УБОРЩИЦА', 'УПРАВЛЯЮЩИЙ' ];

// роли персонала
export const USER_ROLE_SUPER		= 0;
export const USER_ROLE_TRAINER		= 1;
export const USER_ROLE_BOT			= 2;
export const USER_ROLE_ADMIN		= 3;
export const USER_ROLE_JANITOR		= 4;    // TODO: убрать ?
export const USER_ROLE_TOP_ADMIN    = 5;

export const gVisUserRole = [

    'BOSS',
    'ТРЕНЕР',
    '!!БОТ',
    'АДМИН',
    '!!УБОРЩ',
    'УПРАВЛЯЮЩИЙ',

];



export const TRAINER_SUB_ROLE_NORMAL	= 0;	// постоянный реальный тренер	( нужно выставлять скиллы и играфик )
export const TRAINER_SUB_ROLE_TESTDRIVE	= 1;	// испытательный срок/заглушка	( всегда доступен, все скиллы доступны )
export const TRAINER_SUB_ROLE_DELETED	= 2;	// удалён/неизвестен            ( всегда дост, все скиллы дост.)


export const gTrainerSubRoleVisFull = [
	'ТРЕНЕР',
	'ТРЕНЕР / ТЕСТ-ДРАЙВ',
	'ТРЕНЕР / УДАЛЁН',
];

export const gTrainerSubRoleVisShort = [
	'ОБЫЧНЫЙ',
	'ТЕСТ-ДРАЙВ',
	'УДАЛЁН',
];


export function IsTrainerRole(v)
{
    return v === USER_ROLE_TRAINER;
}



export const gUserTraRoleDctVis = [

	{ k: TRAINER_SUB_ROLE_NORMAL,  			v: gTrainerSubRoleVisShort[0]},
	{ k: TRAINER_SUB_ROLE_TESTDRIVE,  		v: gTrainerSubRoleVisShort[1]},
	{ k: TRAINER_SUB_ROLE_DELETED,  		v: gTrainerSubRoleVisShort[2]},


];






// ЗАЯВКИ НА ЗАПИСЬ - статусы
export const EV_REQ_STATUS_UNK          = 0;
export const EV_REQ_STATUS_GATHER       = 5;    // клиенты собираются (больше 1 человека)
export const EV_REQ_STATUS_PEND_APPROVE = 10;    // все в сборе - висят в окне, ждут одобрения админа
export const EV_REQ_STATUS_RES_DECLINED = 15;    // админ забраковал заявку
export const EV_REQ_STATUS_RES_GOOD     = 16;    // заявка успешно закрыта - клиент(ы) попали в календарь


export const gEvReqStatusVisDct = {
    
    [EV_REQ_STATUS_UNK]:              '???',
    [EV_REQ_STATUS_GATHER]:           'СБОР',
    [EV_REQ_STATUS_PEND_APPROVE]:     'ОЖИДАЕТ',
    [EV_REQ_STATUS_RES_DECLINED]:     'ОТКЛОНЕНА',
    [EV_REQ_STATUS_RES_GOOD]:         'УСПЕХ',
};



export const TSKGEN_PERIOD_INVALID    = 0;
export const TSKGEN_PERIOD_WEEK       = 1;
export const TSKGEN_PERIOD_MONTH      = 2;







export const PIC_TYPE_INVALID        = 0;
export const PIC_TYPE_CUSTOMER_SRC   = 1;
export const PIC_TYPE_CUSTOMER_SQ    = 2;
export const PIC_TYPE_CUSTOMER_MINI  = 3;
export const PIC_TYPE_USER_SQ        = 6;
export const PIC_TYPE_USER_MINI      = 7;


export const PIC_EDIT_CTX_CUST      = 0;
export const PIC_EDIT_CTX_USER      = 1;

//---------------------------------------------

export const LOG_TAG_CTX_CUST        = 1;
export const LOG_TAG_CTX_USER        = 2;

export const LOG_TAG_TY_ACTION       = 0;
export const LOG_TAG_TY_AFFECTED     = 1;

export const LOG_AUTH_LOG_PASS       = 10;
export const LOG_AUTH_CHANGE_CLUB    = 11;
export const LOG_UMSG_NEW            = 50;
export const LOG_UMSG_DEL            = 51;
export const LOG_UMSG_EDIT           = 52;
export const LOG_USER_CHG_PASS       = 120;
export const LOG_USER_LOSE_ACCESS    = 121;
export const LOG_PIC_UPLOAD_CUST     = 250;
export const LOG_PIC_UPLOAD_USER     = 251;

export const LOG_TRA_REWARD_SAVE     = 310;

export const LOG_BONUS_DEPOSIT       = 410;
export const LOG_BONUS_ENT_DEL       = 411;

export const LOG_TG_BOT_DEL_ACCESS   = 550;


export const gLogVisDct = {
  
    [LOG_AUTH_LOG_PASS]:        'Юзер ввёл логин/пасс',
    [LOG_AUTH_CHANGE_CLUB]:     'Смена текущ клуба',
    
    [LOG_UMSG_NEW]:             'Новое сообщение юзера',
    [LOG_UMSG_DEL]:             'Удалено сообщение юзера',
    [LOG_UMSG_EDIT]:            'Редакт сообщение юзера',
    
    [LOG_USER_CHG_PASS]:        'Юзер -> смена пароля',
    [LOG_USER_LOSE_ACCESS]:     'Юзер -> убрать пароль',
    
    [LOG_PIC_UPLOAD_CUST]:    'Картинка клиента загр',
    [LOG_PIC_UPLOAD_USER]:    'Картинка юзера загр',
    
    [LOG_TRA_REWARD_SAVE]:     'Расчёт тренеров - конфиг сохр',
    
    [LOG_BONUS_DEPOSIT]:        'Бонусы -> депозит',
    [LOG_BONUS_ENT_DEL]:        'Бонусы -> депозит удалён',
    
    [LOG_TG_BOT_DEL_ACCESS]:    'TG БОТ - клиент лишён доступа',
    
    
};

