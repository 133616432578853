import React from 'react';

import { motion } from "framer-motion";

import { CommonReducer } from '../misc_glob';

import { NuPost2 } from '../api';

import { ShowMsg } from '../v3/toast.js';

import { MeNotifyTasks } from '../desktop/notify_tasks.js';
import { MeNotifyCureq } from '../desktop/notify_cureq.js';

import { LayVert2, LaySpace, LayHoriz2 } from '../misc/layout';

import { SmItem, SmTit, SmDiv, SmSubItem, SmUser, SmClub } from './items.js';

import { CuCaMgrPanel } from './cuca_mgr.js';

import { useGlobalShit, useGlobalShitDispatch } from '../v3/hk_global_shit.js';

import { WinClubSelector } from '../v3/win_club_selector';

import { WinUserLogin } from '../v3/win_user_login';

import { STO_InvaTaskNotify, STO_InvaCuReqNotify } from '../store.js';

import { DaVertScrollFrame, DaVertScrollJuice } from '../misc/vscr.js';


import { MdOutlineKeyboardDoubleArrowLeft } from "react-icons/md";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";


//--------------------------------------------------


//-------------------------------------------------

function __BuildMenuNodes( mm, MC, cbNeedClubSel, isClubSel, gs )
{
    const ar = [];

    const curCode = MC.curCode;
    const onClick = MC.setCode;

    for (let x = 0; x < mm.length; x ++ )
    {
        const e     = mm[x];
        const CC    = e.c;
        
        const needAbil = e.needAbil;
        if (needAbil !== undefined)
        {
            // no ability -> dont show menu item
            if ( ! gs.userHasAbil( needAbil ) ) continue;
        }

        

        if (e.s === undefined)
        {
            const IS_DIS = cbNeedClubSel( CC ) && !isClubSel;

            const IS_CUR = CC === curCode;
            ar.push( <SmItem key={CC} ex={e.ex} ic={e.ic} code={CC} dis={IS_DIS} tit={e.n} isCur={IS_CUR} isExpand={false} onClick={onClick}  /> );
        }
        else
        {
            let IS_EXP = MC.isExpand( CC );

            const mm_ch = e.s;

            const p_ch = [];

            let gotCurCh = false;

            for (let i = 0; i < mm_ch.length; i ++)
            {
                const qq = mm_ch[i];

                const needAbil2 = qq.needAbil;
                if (needAbil2 !== undefined)
                {
                    // no ability -> dont show menu item
                    if ( ! gs.userHasAbil( needAbil2 ) ) continue;
                }

                const isCurCh = qq.c === curCode;

                if (isCurCh) gotCurCh = true;

                const IS_DIS = cbNeedClubSel( qq.c ) && !isClubSel;

                p_ch.push( <SmSubItem key={qq.c} dis={IS_DIS}  code={qq.c} tit={qq.n} isCur={isCurCh} onClick={onClick} ind={i} />  );
            }

            //if (gotCurCh) IS_EXP = true;    // force expand

            ar.push( <SmItem key={CC} code={CC} tit={e.n} ic={e.ic} isCur={gotCurCh && !IS_EXP} isExpand={IS_EXP} onClick={MC.toggleExpand} >{p_ch}</SmItem> );
        }
    }

    return ar;
}


//------------

const gMenuInitState = {

    curCode: 'start',
};

export function useMainMenuControl()
{
    const [sta, dispatch] = React.useReducer( CommonReducer, gMenuInitState );


    return {

        curCode: sta.curCode,

        setCode: (c) => {
            if (c.curCode === c) return;
            dispatch( {type: 'val', k: 'curCode', v:c} );
        },

        isExpand: (c) => {
            return sta['exp_'+c] === true;
        },

        toggleExpand: (c) => {
            const NOW = sta['exp_'+c] === true;
            dispatch( {type: 'val', k: 'exp_'+c, v:!NOW} );
        },
    };
}


//-----


function MinimizeButton( {onClick} )
{
    return (
                <MdOutlineKeyboardDoubleArrowLeft className="zz_hover_gray_to_white" onClick={onClick} style={{position: 'absolute', top: '6px', right: '2px', fontSize: '22px'}}/>

    );
}


function UnfoldButton( {onClick} )
{
    return (<div className="zz_hover_gray_to_white"   style={{height: '100%'}} onClick={onClick}  >
                <MdOutlineKeyboardDoubleArrowRight  style={{position: 'absolute', top: '6px', right: '2px', fontSize: '22px'}}/>
                </div>
    );
}



export function SideMenu2( {menuCtl, defs, isClubSel, cbNeedClubSel}  )
{
    const gs        = useGlobalShit();
    const gsDsp     = useGlobalShitDispatch();

    function cb_fold_tog()
    {
        gsDsp( {type: 'val', k: 'menuIsFold', v: !gs.menuIsFold} );
    }

    function cb_selected_club(cc)
    {
        function __ok(e)
        {
            gsDsp( {type: 'load_club', info: e} );
            gsDsp( {type: 'val', k: 'busy', v: false} );

            STO_InvaTaskNotify();
            STO_InvaCuReqNotify();

            //console.log(e);

            ShowMsg('СМЕНА КЛУБА');

        }
        
        function __fail(e)
        {
            
            gsDsp( {type: 'val', k: 'busy', v: false} );
        }

        gsDsp( {type: 'val', k: 'showPopClub', v: false} );
        gsDsp( {type: 'val', k: 'busy', v: true} );

        NuPost2( 700, {ci:cc}, __ok, __fail );
    }

    function cb_close()
    {
        gsDsp( {type: 'val', k: 'showPopClub', v: false} );
    }

    function cb_close_lo()
    {
        gsDsp( {type: 'val', k: 'showPopLogin', v: false} );
    }


    const p_mmm = __BuildMenuNodes( defs, menuCtl, cbNeedClubSel, isClubSel, gs );

    const IS_FOLD = gs.menuIsFold;

    return (
        <>

        <div className={'sm_root ' + (IS_FOLD ? ' mini' : ' vis') }>

            {  IS_FOLD ? <UnfoldButton onClick={cb_fold_tog} /> : <MinimizeButton onClick={cb_fold_tog}/>  }

            { !IS_FOLD ? (
            <LayVert2 rows=' 20px max-content  max-content   max-content  1fr  20px max-content 20px' >
                
                <LaySpace />

                <SmUser /> 
                <SmClub />
                <SmDiv />

                <DaVertScrollFrame dark faders>
                    <DaVertScrollJuice paddingTop='20px' paddingBottom='20px'>
                        {p_mmm}
                    </DaVertScrollJuice>
                </DaVertScrollFrame>

                <LaySpace />
                <CuCaMgrPanel />
                <LaySpace />
            </LayVert2>
            ) : (<div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '40px', position: 'absolute', top: '100px', left: 0, width: '100%', height: '200px'}}>
                    <MeNotifyTasks isVert/>
                    <MeNotifyCureq />
            </div>) }


        </div>

        <WinClubSelector visible={gs.showPopClub} onOk={cb_selected_club} onClose={cb_close} />

        <WinUserLogin visible={gs.showPopLogin} onClose={cb_close_lo} />


        </>

    );
}




export function SideMenu3( {menuCtl, defs, isClubSel, cbNeedClubSel} )
{
    const gs        = useGlobalShit();
    const gsDsp     = useGlobalShitDispatch();

    

    const p_mmm = __BuildMenuNodes( defs, menuCtl, cbNeedClubSel, isClubSel, gs );




    function cb_selected_club(cc)
    {
        function __ok(e)
        {
            gsDsp( {type: 'load_club', info: e} );
            gsDsp( {type: 'val', k: 'busy', v: false} );

            STO_InvaTaskNotify();
            STO_InvaCuReqNotify();

            //console.log(e);

            ShowMsg('СМЕНА КЛУБА');

        }
        
        function __fail(e)
        {
            
            gsDsp( {type: 'val', k: 'busy', v: false} );
        }

        gsDsp( {type: 'val', k: 'showPopClub', v: false} );
        gsDsp( {type: 'val', k: 'busy', v: true} );

        NuPost2( 700, {ci:cc}, __ok, __fail );
    }

    function cb_close()
    {
        gsDsp( {type: 'val', k: 'showPopClub', v: false} );
    }

    function cb_close_lo()
    {
        gsDsp( {type: 'val', k: 'showPopLogin', v: false} );
    }

    function cb_fold_tog()
    {
        gsDsp( {type: 'val', k: 'menuIsFold', v: !gs.menuIsFold} );

        
    }


    const variants = {
        show: {
            marginLeft: '0px',
            transition: { times: [0.0, 0.3], ease: 'easeOut' },
        },
        hide: {
          marginLeft: '-170px',
          transition: { times: [0.0, 0.3], ease: 'easeOut' },
        }
      }


    const IS_FOLD = gs.menuIsFold;


    let ppp;

    if (IS_FOLD)
    {
        ppp = (
            <UnfoldButton onClick={cb_fold_tog}/>
        );
    }
    else
    {
        ppp = (<>
            <MinimizeButton onClick={cb_fold_tog}/>

            <LayVert2 rows=' 18px max-content  max-content 18px max-content 1fr max-content max-content  20px' >
           
                <LaySpace />


                <SmUser />
       

                <SmClub />

                <LaySpace />

                <SmDiv />
                <div style={{paddingTop: '10px', paddingBottom: '30px', overflowY: 'scroll', overflowX: 'clip', width: '100%', height: '100%', position: 'relative'}}>
                {p_mmm}
                </div>
                <SmDiv />

                <CuCaMgrPanel />

  
                <LaySpace />

            </LayVert2>

            </>

        );
    }


    // 

    return (
        <>

        <motion.div className="sm_root"  variants={variants}  animate={ IS_FOLD ? 'hide' : 'show' }  >


            {ppp}


        </motion.div >

        <WinClubSelector visible={gs.showPopClub} onOk={cb_selected_club} onClose={cb_close} />

        <WinUserLogin visible={gs.showPopLogin} onClose={cb_close_lo} />


        </>
    );
}

