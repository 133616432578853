
import React from 'react';

import { NuPost2 } from '../api.js';

import { CsvToggleID, CsvTestID } from '../helpers.js';

import { OverlayLoading } from '../misc/ovr_loading.js';
import { DaButton } from '../misc/but.js';

import { ShowErr, ShowMsg, CommonReducer  } from '../misc_glob.js';

import { LayVert2, LayHoriz2 } from '../misc/layout.js';

import { DaOutlinedShit } from '../misc/outlined.js';

import { DaList2 } from '../misc/list2.js';

import { DaIconBtn } from '../misc/icon_btn.js';

import { WinCustomerSearch } from './win_cust_search.js';


import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

//-------------------------------------


const gStSt = {

    busy:       false,
    lst:        [],
    selected:   [],

    pop:        0,



};


// список клиентов  
// кнопки добавить/убрать
// valCsv --- cuId list
export function CustomerCsvList( {name, label, valCsv, onChange} )
{
    const [sta, dispatch] = React.useReducer( CommonReducer, gStSt );
    

    React.useEffect( () => {
        
        function __ok(e)
        {
            dispatch( {type: 'vals', dct: {busy: false, lst: e.rows} } );
        }
        
        function __fail(e)
        {
            ShowErr(e.m);
            dispatch( {type: 'vals', dct: {busy: false, lst: []} } );
        }

        dispatch( {type: 'vals', dct: {busy: true} } );
        
        NuPost2( 4220, {csv:valCsv}, __ok, __fail );
     
    }, [valCsv] );
    
    
    const cb_chg = React.useCallback( (k,v) => {
        dispatch( {type: 'vals', dct: {selected: v} } );
    }, [valCsv, name] );
    
    function cb_pop()
    {
        dispatch( {type: 'vals', dct: {pop:1} } );
    }

    function cb_rem()
    {
        const iii = sta.selected;

        const nu = CsvToggleID(valCsv, iii );
        dispatch( {type: 'vals', dct: {selected: []} } );
        onChange( name, nu );
    }

    function cb_clo(  )
    {
        dispatch( {type: 'vals', dct: {pop:0} } );
    }

    function cb_choose_dude( cuId )
    {
        dispatch( {type: 'vals', dct: {pop:0} } );

        if ( CsvTestID(valCsv, cuId ) )
        {
            ShowErr('Уже в списке!');
            return;
        }

        const nu = CsvToggleID(valCsv, cuId );
        //console.log('NEW CSV: ', nu);
        onChange( name, nu );
    }

    

    const NO_DEL = sta.selected.length < 1;

    const ppp = (

        <LayVert2 rows='30px 1fr' gap='6px' sx={{position: 'relative'}}>

            <div style={{display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'center'}}>
                <DaIconBtn help='ДОБАВИТЬ' icon={AddIcon}    tp_placement='top' onClick={cb_pop}/>
                <DaIconBtn help='УБРАТЬ'   icon={RemoveIcon} tp_placement='top' disabled={NO_DEL} onClick={cb_rem}/>
            </div>
            
            <DaList2 src={sta.lst} fld_k='cu_id' fld_v='cu_name' value={sta.selected} onChange={cb_chg} />


            <OverlayLoading active={sta.busy} />

        </LayVert2>
    );

    return (

            <>

            <DaOutlinedShit labelVal={label} labelIsFloat={true} juice={ppp} />

            <WinCustomerSearch visible={sta.pop === 1} hideNewCust  onOk={cb_choose_dude}  onClose={cb_clo} />

            </>
        );
}
