import React from 'react';


import * as DEFS from '../defs.js';
import * as HLP from '../helpers.js';

import { MdBlock } from "react-icons/md";

import { LayVert2, LayHoriz2, LaySpace } from '../misc/layout.js';
import { OverlayLoading } from '../misc/ovr_loading.js';
import { DaButton } from '../misc/but.js';
import { DaCombo } from '../misc/combo.js';

import { NuPost, NuPost2 } from '../api.js';

import { ClubCsvList } from './club_list_csv.js';

import { DaListCsv } from '../misc/list_csv.js';

import { BottomButtonsPanel } from '../cust_card/bottom_pan.js';

import { DaOutlinedShit } from '../misc/outlined.js';

import {  ShowErr, ShowMsg, CommonReducer } from '../misc_glob.js';

import { DaTextField } from '../misc/text_field.js';

import { DaColorPicker } from '../misc/clr_picker.js';
        
import { Win2 } from '../misc/win2.js';
    
import CheckIcon from '@mui/icons-material/Check';


import { DaTabs } from '../misc/tabz.js';

import { useGlobEvActs } from '../misc_glob.js';

import { useGlobalShit } from '../v3/hk_global_shit.js';
//-----------------------------------------------------

import { ViUserPic } from './vi_user_edit_pic.js';

import { ViUserDetails } from './vi_user_details.js';

//------------------------------------------


//--------------------------------------------


const gStaAA = {
  
    busy:       false,
    pwd:        '',
    got_acc:    false,
    
};

function ViUserAccess( {rid} )
{
    const [sta, dispatch]   = React.useReducer( CommonReducer, gStaAA );
    
    const sss_au            = useGlobalShit();
    
    
    
    const cb_chg = React.useCallback( (k,v) => {
        dispatch( {type: 'val', k, v } );
    }, [] );
    
    
    function cb_chg_pwd()
    {
        if ( !sss_au.userHasAbil(303) )
        {
            return;
        }
        
        function __ok(e)
        {
            dispatch( {type: 'val', k:'pwd', v:'' } );
            dispatch( {type: 'val', k:'busy', v:false } );
            dispatch( {type: 'val', k:'got_acc', v:true } );
            ShowMsg('ПАРОЛЬ УСТАНОВЛЕН !');
        }
        
        function __fail(e)
        {
            dispatch( {type: 'val', k:'busy', v:false } );
            ShowMsg(e.m);
        }
        
        dispatch( {type: 'val', k:'busy', v:true } );
        
        NuPost2(737, {u_id: rid, pa: sta.pwd}, __ok, __fail);
    }
    
    function cb_remove_acc()
    {
        if ( !sss_au.userHasAbil( 303 ) )
        {
            return;
        }
        
        function __ok(e)
        {
            dispatch( {type: 'val', k:'pwd', v:'' } );
            dispatch( {type: 'val', k:'busy', v:false } );
            dispatch( {type: 'val', k:'got_acc', v:false } );
            ShowMsg('ЛИШЁН ДОСТУПА !');
        }
        
        function __fail(e)
        {
            dispatch( {type: 'val', k:'busy', v:false } );
            ShowMsg(e.m);
        }
        
        dispatch( {type: 'val', k:'busy', v:true } );
        
        NuPost2(738, {u_id: rid}, __ok, __fail);
    }
    
    React.useEffect( () => {
        
        if (rid === -1) return;
        
        function __ok(e)
        {
            dispatch( {type: 'val', k:'got_acc', v:e.got_acc } );
            dispatch( {type: 'val', k:'busy', v:false } );
            console.log(e);
        }
        
        function __fail(e)
        {
            ShowErr(e.m);
            dispatch( {type: 'val', k:'busy', v:false } );
        }
        
        dispatch( {type: 'val', k:'busy', v:true } );
        
        NuPost(1072, {rid}, __ok, __fail);
        
    }, [] );
    
    
    const DIS_CHANGE_PWD    = (!sss_au.userHasAbil( 303 ));
    const DIS_SUBMIT_PWD    = sta.pwd.trim() === '' || (!sss_au.userHasAbil( 303 ));
    
    
    const DIS_REMOVE_ACC = !sta.got_acc;
    
    return (
        <LayVert2 rows='max-content max-content max-content 1fr' padding='20px'>
    
            <div style={{borderBottom: '1px solid #00000020', color: sta.got_acc ? '#40A030' : '#606070', height: '40px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 800, fontSize: '16px'}}>
                {sta.got_acc ? 'ЕСТЬ ДОСТУП' : 'ДОСТУП ОТСУТСТВУЕТ'}
            </div>
    
            <LayHoriz2 cols='1fr max-content' gap='10px' padding='20px' sx={{alignItems: 'center'}}>
                <DaTextField disabled={DIS_CHANGE_PWD} name='pwd' value={sta.pwd}  onChange={cb_chg} label='НОВЫЙ ПАРОЛЬ' />
                <DaButton disabled={DIS_SUBMIT_PWD} icon={CheckIcon}  onClick={cb_chg_pwd} title='УСТАНОВИТЬ'/>
            </LayHoriz2>
            
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <DaButton disabled={DIS_REMOVE_ACC} icon={MdBlock}  onClick={cb_remove_acc} title='ЛИШИТЬ ДОСТУПА'/>
            </div>
    
            <LaySpace />
            
            <OverlayLoading active={sta.busy} />
    
        </LayVert2>
    );
}

//------------------------------------


const gStaSS = {
  
    busy:       false,
    init:       false,
    csv:        '',
    
};

function ViUserSkills( {rid} )
{
    const [sta, dispatch]   = React.useReducer( CommonReducer, gStaSS );
    
    const hk_act = useGlobEvActs();
    
    
    const cb_chg = React.useCallback( (k,v) => {
        dispatch( {type: 'val', k, v } );
    }, [] );
    
    
    React.useEffect( () => {
        
        if (rid === -1) return;
        
        function __ok(e)
        {
            dispatch( {type: 'val', k:'init', v:true } );
            dispatch( {type: 'val', k:'csv', v:e.acts_csv } );
            dispatch( {type: 'val', k:'busy', v:false } );
        }
        
        function __fail(e)
        {
            ShowErr(e.m);
            dispatch( {type: 'val', k:'busy', v:false } );
        }
        
        dispatch( {type: 'val', k:'busy', v:true } );
        
        NuPost(1073, {rid}, __ok, __fail);
        
    }, [] );
    
    

    
    
    const my_list = React.useMemo( () => {
        
        return hk_act.rows.filter( __flt_sel );
        
    }, [] );
    
    
    
    
    if (sta.init === false)
    {
        return <OverlayLoading active={true} />;
    }
    
    if (sta.csv === null)
    {
        return <div className="wh100perc" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>НЕ ТРЕНЕР !</div>
    }
    
    
    
    function cb_submit()
    {
        function __ok(e)
        {
            dispatch( {type: 'val', k:'busy', v:false } );
            ShowMsg('СОХРАНЕНО !');
        }
        
        function __fail(e)
        {
            dispatch( {type: 'val', k:'busy', v:false } );
            ShowMsg(e.m);
        }
        
        dispatch( {type: 'val', k:'busy', v:true } );
        
        NuPost(1074, {rid: rid, acts_csv: sta.csv}, __ok, __fail);
    }

    
    
    const ttt = <DaListCsv name='csv' src={my_list} fld_k='ea_id' fld_v='ea_name'  value_csv={sta.csv} onChange={cb_chg} />;
   

    return (
        <LayVert2 rows='1fr max-content' gap='10px'>
    

            <div className='wh100perc' style={{padding: '20px'}}>
                <DaOutlinedShit labelIsFloat={true} labelVal={'ДВИЖ'} juice={ttt} />
            </div>
            
            
            <BottomButtonsPanel>
                <DaButton  onClick={cb_submit}  icon={CheckIcon}  title='СОХРАНИТЬ' />
            </BottomButtonsPanel>
            
            <OverlayLoading active={sta.busy} />
    
        </LayVert2>
    );
}

function __flt_sel(e)
{
    return e.ea_use_for_evt > 0;
}

//--------------------------------------------------------






const gStaCC = {
  
    busy:       false,
    init:       false,
    csv:        '',
    
};

function ViUserClubs( {rid} )
{
    const [sta, dispatch]   = React.useReducer( CommonReducer, gStaCC );
    
    
    const cb_chg = React.useCallback( (k,v) => {
        dispatch( {type: 'val', k, v } );
    }, [] );
    
    
    
    React.useEffect( () => {
        
        if (rid === -1) return;
        
        function __ok(e)
        {
            dispatch( {type: 'val', k:'init', v:true } );
            dispatch( {type: 'val', k:'csv', v:e.clubs_csv } );
            dispatch( {type: 'val', k:'busy', v:false } );
        }
        
        function __fail(e)
        {
            ShowErr(e.m);
            dispatch( {type: 'val', k:'busy', v:false } );
        }
        
        dispatch( {type: 'val', k:'busy', v:true } );
        
        NuPost(1075, {rid}, __ok, __fail);
        
    }, [] );
    


    
    
    
    
    if (sta.init === false)
    {
        return <OverlayLoading active={true} />;
    }
    

    
    
    
    function cb_submit()
    {
        function __ok(e)
        {
            dispatch( {type: 'val', k:'busy', v:false } );
            ShowMsg('УСПЕХ !');
        }
        
        function __fail(e)
        {
            dispatch( {type: 'val', k:'busy', v:false } );
            ShowMsg(e.m);
        }
        
        dispatch( {type: 'val', k:'busy', v:true } );
        
        NuPost(1076, {rid: rid, csv: sta.csv}, __ok, __fail);
    }
    

    
    
    const ttt = <ClubCsvList name='csv' valCsv={sta.csv} onChange={cb_chg} />


    return (
        <LayVert2 rows='1fr max-content' gap='10px'>
    

            <div className='wh100perc' style={{padding: '20px'}}>
                <DaOutlinedShit labelIsFloat={true} labelVal={'В КАКИХ КЛУБАХ РАБОТАЕТ ?'} juice={ttt} />
            </div>
            
            
            <BottomButtonsPanel>
                <DaButton  onClick={cb_submit}  icon={CheckIcon}  title='СОХРАНИТЬ' />
            </BottomButtonsPanel>
            
            <OverlayLoading active={sta.busy} />
    
        </LayVert2>
    );
}


//---------------------------------------




const gTabItemsUser = [

    { c:'main', n:'ОСНОВНОЕ' },
    { c:'skills', n:'НАВЫКИ' },
    { c:'access', n:'ДОСТУП' },
    { c:'clubs', n:'КЛУБЫ' },
    { c:'pic', n:'ФОТО' },
];




//-------------------------------------------------

function MyBody( {rid, onOk, isMaster} )
{


    const disCodes = rid === -1 ? ['access', 'skills', 'clubs', 'pic'] : null;
    
    return (


        <DaTabs items={gTabItemsUser} initCode='main' arDisCodes={disCodes} >

            <ViUserDetails tabCode='main' rid={rid} onOk={onOk} isMaster={isMaster} />
            <ViUserAccess tabCode='access' rid={rid} />
            <ViUserSkills tabCode='skills' rid={rid} />
            <ViUserClubs tabCode='clubs' rid={rid} />
            <ViUserPic tabCode='pic' rid={rid} />

        </DaTabs>
    );
}


export function WinPopUserEdit2( {visible, rid, onClose, onOk, isMaster} )
{
    const TIT = (rid === -1) ? 'НОВЫЙ ЮЗЕР' : `РЕДАКТИРОВАТЬ ЮЗЕРА ${rid}`;
    
    return (
    
        <Win2  visible={visible} title={TIT} width={500} height={480}   onClose={ onClose }    >
            <MyBody rid={rid} onOk={onOk} isMaster={isMaster} />
        </Win2>
    
    );
}

