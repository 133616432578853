import React from 'react';

import { Grid3 } from '../grid3.js';

import {NuPost2 } from '../api.js';

import * as DEFS from '../defs.js';

import * as HLP from '../helpers.js';

import { Win2Confirm } from '../misc/win2_confirm.js';

import { DaButton } from '../misc/but.js';

import { CmComColCellDateTime, CmComColUser } from '../cm_table_cell_com.js';


import { HowReturned } from '../desktop/how_payed.js';


import { LayVert2, LayHoriz2, LaySpace } from '../misc/layout.js';


import {  ShowMsg, ShowErr, CommonReducer } from '../misc_glob.js';

import { CmHelpIcon } from '../misc/help_icon.js';

import RemoveIcon from '@mui/icons-material/Remove';

import { CmCachedCustLink } from '../desktop/ca_cust_link.js';

function MyColCust(pp)
{
    if (pp.value === null)
    {
        return <span style={{color: '#A03030'}}>АБОНЕМЕНТ УДАЛЁН ?</span>;
    }

	return <CmCachedCustLink rid={pp.value}  />
}


function MyColNumTrains(pp)
{
	return <div>{pp.value}</div>;
}

function MyColAmo(pp)
{
    const ROW = pp.row;
    return <HowReturned retId={ROW.par_id}/>;
}


const gCols = [
  { sort: true, align: 'center', 	fld: 'par_id',     		    name: 'ID',   		    width: 70}, 
  { sort: true, align: 'center', 	fld: 'par_dt',     		    name: 'Дата', 		    width: 140, renderCell: CmComColCellDateTime},
  { sort: true, align: 'left', 	    fld: 'pay_cust_id',     	name: 'Клиент',   	    width: 240, renderCell: MyColCust}, 
  { sort: true, align: 'left', 	    fld: 'par_money',     		name: 'Фин Оп',   		width: 120, renderCell: MyColAmo}, 
  { sort: true, align: 'left', 	    fld: 'par_pay_id',     	    name: 'Абонемент',   	width: 170}, 
  { sort: true, align: 'left', 	    fld: 'v_num_trains',     	name: 'Трень списано',   width: 170, renderCell: MyColNumTrains}, 
  { sort: true, align: 'left', 	    fld: 'par_user_id',     	name: 'Юзер', 		    width: 220, renderCell: CmComColUser},
  { sort: true, align: 'left', 	    fld: 'par_comment',     	name: 'Коммент',   	    width: 300}, 
];



const gSt = {
  
    selected:       [],
    busy:           false,
    
    serial:         0,
    
    pop:            0,
    pop_dct_conf:   {},
    
};


// ВОЗВРАТЫ ТРЕНЬ
export function TabPayReturns(pp)
{
    const [sta, dispatch] = React.useReducer( CommonReducer, gSt );
    
	
	const cb_chg_sel = React.useCallback( (nu) => {
        dispatch( { type: 'val', k: 'selected', v: nu } );
	}, [sta.selected] );
    
    function cb_close_pop()
    {
        dispatch( { type: 'val', k: 'pop', v: 0 } );
    }
    
    function cb_del()
    {
        const ID = sta.selected[0];
        
        function __conf()
        {
            function __ok(e)
            {
                ShowMsg('ВОЗВРАТ УДАЛЁН !');
                dispatch( { type: 'val', k: 'busy', v: false } );
                dispatch( { type: 'val', k: 'pop', v: 0 } );
                dispatch( { type: 'val', k: 'selected', v: [] } );
                dispatch( { type: 'val', k: 'serial', v: sta.serial+1 } );
            }
            
            function __fail(e)
            {
                ShowMsg(e.m);
                dispatch( { type: 'val', k: 'busy', v: false } );
                
            }
            
            dispatch( { type: 'val', k: 'busy', v: true } );
            
            NuPost2( 989, {rid:ID}, __ok,  __fail );
        }
        
        const TT = { title: `ТОЧНО УДАЛИТЬ ВОЗВРАТ ? ID: ${ID}`, onClose: cb_close_pop, onOk: __conf };
        
        dispatch( { type: 'val', k: 'pop', v: 1 } );
        dispatch( { type: 'val', k: 'pop_dct_conf', v: TT } );
    }

    const DIS_DEL = sta.selected.length < 1;

    const FLT = React.useMemo( () => {
        return {};
    }, [sta.serial] );

	return (
            <>
            <LayVert2 rows='max-content 1fr' padding='20px' gap='16px' >
				
                            <LayHoriz2 cols='max-content 1fr max-content' >
                                <DaButton onClick={cb_del} disabled={DIS_DEL} title='УДАЛИТЬ' icon={RemoveIcon} />
                                <LaySpace />
                                <CmHelpIcon juice="При удалении возврата - тренировки в абонементе снова станут доступны. Связанные фин. операции будут удалены." />
                            </LayHoriz2>

                            <Grid3  isNuApi flt={FLT} singleSel selected={sta.selected} onChangeSel={cb_chg_sel} cols={gCols}
                                apiCmd={990}  uniq='par_id'  sort_field='par_id' sort_dir='desc' code='pay_rets' />
                            
						
		
            </LayVert2>
            
            <Win2Confirm busy={sta.busy} visible={sta.pop === 1}  {...sta.pop_dct_conf} />
            
            </>);
}


