import React from 'react';

import { CmCachedCustLink } from '../desktop/ca_cust_link.js';

import {DaIconBtn} from '../misc/icon_btn.js';

import { PaymentInfo } from './payment_info.js';

import * as DEFS from '../defs.js';
import * as HLP from '../helpers.js';


import CloseIcon from '@mui/icons-material/Close';

import { EvActDispNameCSV } from '../desktop/ev_act.js';

//------------------------------------------------------------



// плашка с инфой - НЕПРИШЁЛ


export function AbandonEntry( {cu_id, acts, pay_id, comment, guilty, onRemove} )
{
    function cb_rem()
    {
        onRemove( cu_id );
    }

    return (
            <div style={{position: 'relative', border: '1px solid #00000020', margin: '10px', padding: '12px', background: '#FFE0E0', borderRadius: '4px' }}>
                    
                    <div>
                        <CmCachedCustLink  rid={cu_id}  />
                        <div>Был записан на: <b><EvActDispNameCSV csv={acts}/></b></div>
                        
                        <PaymentInfo  pay_id={pay_id} cur_trp_acts={acts} />
                        
                        <div>Причина: <b>{comment}</b></div>
                        <div>Виноват: {guilty ? "ДА" : "НЕТ"}</div>
                        
                    </div>
                    
 
                    <div style={{alignItems: 'center', display: 'flex', gap: '20px', position: 'absolute', right: '16px', top: '8px' }}>
                    
						<DaIconBtn  icon={CloseIcon} help='УБРАТЬ' onClick={cb_rem} clr='#A03030' tp_placement='top'   />
						
                    </div>
                </div>

    );
}
