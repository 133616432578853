import React from 'react';

import {CommonReducer, ShowErr} from '../misc_glob.js';
import * as DEFS from '../defs.js';
import * as HLP from '../helpers.js';

import { NuPost2 } from '../api.js';

import * as DT from '../dt.js';

import { CmCachedCustLink } from '../desktop/ca_cust_link.js';

import { PaymentInfo } from './payment_info.js';


import {DaIconBtn} from '../misc/icon_btn.js'; 

import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';

import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import CloseIcon from '@mui/icons-material/Close';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import HttpsIcon from '@mui/icons-material/Https';


import { useGlobEvActs } from '../misc_glob.js';



function BgLock( {visible} )
{
    if (!visible) return null;

    return (
        <div style={{position: 'absolute', bottom: '10px', right: '26px'}}>
            <HttpsIcon sx={{fontSize: '60px', color: '#00000010'}}/>
        </div>
    );
}

function BgBurn( {visible} )
{
    if (!visible) return null;

    return (
        <div style={{position: 'absolute', bottom: '10px', right: '26px'}}>
            <WhatshotIcon sx={{fontSize: '60px', color: '#E0302020'}}/>
        </div>

    );
}

function BoundDt( {dt} )
{
    if (!dt) return null;

    return (            <div style={{color: '#607080', fontSize: '12px', alignItems: 'center', display: 'flex', gap: '20px', position: 'absolute', right: '16px', top: '40px' }}>
                            дата привязки: { DT.DateTimeStrLoc( DT.DateTimeParseIso( dt )) }
                        </div>);
}


function BtnPanel( {showSwap, showLock, showAban, showBurn, showRemove} )
{
}


//---

// СОБЫТИЕ
// плашка трени клиента в выборе


const gSta = {
  
    busy:   false,
    row:    null,
};




export function WinPopEventDudeTrp(  {trp_id, ts_id, trp_serial, trp_status,
                                on_cancel_burn, on_remove, on_got_row, on_swap, on_toggle_sta, on_abandon, on_burn} )
{
    const [sta, dispatch] = React.useReducer( CommonReducer, gSta );
    
    const hk_act = useGlobEvActs();
    
    React.useEffect( () => {
        
        function __ok(e)
        {
            dispatch( {type: 'vals', dct: {busy: false, row: e.row}  } );
            //dispatch( {type: 'val', k:'row', v:e.row} );

            on_got_row( e.row );
        }
        
        function __fail(e)
        {
            ShowErr( e.m );
            dispatch( {type: 'val', k:'busy', v:false} );
        }
        
        dispatch( {type: 'val', k:'busy', v:true} );
        
        NuPost2( 850, {trp_id: trp_id}, __ok, __fail );
        
    }, [] );

    //--

    function cb_swap()
    {
        on_swap( trp_id, sta.row.trp_customer );
    }
    
    function cb_togg_status()
    {
        on_toggle_sta( trp_id );
    }
    
    function cb_abandon()
    {
        const r = sta.row;
        
        let inf = {};
        inf.cu_id   = r.trp_customer;
        inf.pay_id  = r.trp_payment_id;
        inf.acts    = r.trp_acts_csv;

        on_abandon( inf );
    }
    
    function cb_remove()
    {
        on_remove( trp_id );
    }
    
    function cb_burn()
    {
        on_burn( trp_id );
    }
    
    //---

    const BG_CLR = trp_status === DEFS.TPR_STATUS_BURNED ? '#FFE0E0' : '#F6F6FF';

    const TRP_IS_CLOSED = (trp_status === DEFS.TRP_STATUS_CLOSED);

    let dis_togg = false;
    let dis_burn = false;



    let p_juice = null;

    
    let p_panel = null;



    const SHOW_PANEL = trp_status !== DEFS.TPR_STATUS_BURNED;

    if (sta.row)
    {
        const ACT_RESERVE = hk_act.getBySpecial( 1 );
        if (ACT_RESERVE === null)
        {
            console.warn('wtf ?');
        }
        else
        {
            // Бронь нельзя закрыть / сжечь
             if (HLP.CsvTestID( sta.row.trp_acts_csv, ACT_RESERVE.ea_id ))
             {
                 dis_togg = true;
                 dis_burn = true;
             }
        }

        let p_date_bound = null;
       
        if (sta.row.trsb_dt_bound !== null)
        {
            p_date_bound = (<div style={{color: '#607080', fontSize: '12px', alignItems: 'center', display: 'flex', gap: '20px', position: 'absolute', right: '16px', top: '40px' }}>
                                дата привязки: { DT.DateTimeStrLoc( DT.DateTimeParseIso(sta.row.trsb_dt_bound)) }
                            </div>);
        }




        if (SHOW_PANEL)
        {
            p_panel = (<><div style={{alignItems: 'center', display: 'flex', gap: '16px', position: 'absolute', right: '16px', top: '8px' }}>
               
                    
            {
                on_swap ? (
                    <DaIconBtn  icon={SwapHorizIcon} help='ВЫБРАТЬ ДРУГУЮ ТРЕНЮ' onClick={cb_swap} disabled={TRP_IS_CLOSED}  clr='#80A0FF' tp_placement='top' />)
                        : null
            }
            {  
                TRP_IS_CLOSED ?
                            <DaIconBtn  icon={LockOpenIcon} help='ОТКРЫТЬ' onClick={cb_togg_status}  disabled={dis_togg} tp_placement='top' />
                            :
                            <DaIconBtn  icon={LockIcon} help='ЗАКРЫТЬ' onClick={cb_togg_status}  disabled={dis_togg} tp_placement='top' />
                            
                
            }
            
        
            
            <DaIconBtn  icon={DirectionsRunIcon} help='НЕ ПРИШЁЛ !' onClick={cb_abandon}  disabled={TRP_IS_CLOSED} tp_placement='top' />
            
             { on_burn ? <DaIconBtn  clr='#9F7030' icon={WhatshotIcon} help='СЖЕЧЬ ТРЕНЮ' onClick={cb_burn}  disabled={dis_burn || TRP_IS_CLOSED} tp_placement='top' /> : null }
            
            <DaIconBtn  icon={CloseIcon} help='УБРАТЬ КЛИЕНТА' onClick={cb_remove} clr='#A03030' tp_placement='top'  />

                
            </div>
            {p_date_bound}
            </>);

        }


 
        let xxx = trp_status;
       
        let p_sta = <div style={{fontWeight: 600, color: DEFS.gTrpStatusClr[xxx] }}>
                        {DEFS.gTrpStatusDisp[xxx]}
                    </div>;

        p_juice = (
            <>
            <div><CmCachedCustLink rid={sta.row.trp_customer}  /></div>
            {p_sta}
            <PaymentInfo
                                   pay_id={sta.row.trp_payment_id}
                                   cur_trp_id={trp_id}
                                   cur_trp_status={trp_status}
                                   cur_trp_acts={sta.row.trp_acts_csv}
                                   ts_id={ts_id}
                                   trp_serial={trp_serial}
                                   />
            </>

        );
    }





    // mad skillz
    if (trp_status === DEFS.TPR_STATUS_BURNED)
    {
        function __cancel_burn()
        {
            on_cancel_burn( trp_id );
        }
        p_panel = (<div style={{position: 'absolute', top: '8px', right: '8px'}}>
                    <DaIconBtn  icon={CloseIcon} help='НЕ СГОРЕЛА' onClick={__cancel_burn} clr='#A03030' tp_placement='top'  />
                    </div>);
    }


    return (

        <div style={{background: BG_CLR, padding: '10px', position: 'relative', border: '1px solid #00000020', minHeight: '80px'}}>

            <BgLock visible={sta.row && TRP_IS_CLOSED} />
            
            <BgBurn visible={sta.row && trp_status === DEFS.TPR_STATUS_BURNED} />

           {p_juice}

            {p_panel}


        </div>

    );
}




/*

export function WinPopEventDudeTrp2( {trp_id, ts_id, trp_serial, trp_status, on_remove, on_got_row, on_swap, on_toggle_sta, on_abandon, on_burn} )
{
    const [sta, dispatch] = React.useReducer( CommonReducer, gSta );
    
    const hk_act = useGlobEvActs();
    
    React.useEffect( () => {
        
        function __ok(e)
        {
            dispatch( {type: 'val', k:'busy', v:false} );
            dispatch( {type: 'val', k:'row', v:e.row} );
            
            on_got_row( e.row );
        }
        
        function __fail(e)
        {
            ShowErr( e.m );
            dispatch( {type: 'val', k:'busy', v:false} );
        }
        
        dispatch( {type: 'val', k:'busy', v:true} );
        
        NuPost( 850, {trp_id: trp_id}, __ok, __fail );
        
    }, [] );
    
    
    function cb_swap()
    {
        on_swap( trp_id, sta.row.trp_customer );
    }
    
    function cb_togg_status()
    {
        on_toggle_sta( trp_id );
    }
    
    function cb_abandon()
    {
        const r = sta.row;
        
        let inf = {};
        inf.cu_id   = r.trp_customer;
        inf.pay_id  = r.trp_payment_id;
        inf.acts    = r.trp_acts_csv;

        on_abandon( inf );
    }
    
    function cb_remove()
    {
        on_remove( trp_id );
    }
    
    function cb_burn()
    {
        on_burn( trp_id );
    }
    
   

   let p_content;
   

   if (sta.row)
   {

       let xxx = trp_status;
       
       let p_sta = <div style={{marginTop: '10px', fontWeight: 800, color: DEFS.gTrpStatusClr[xxx] }}>
                    {DEFS.gTrpStatusDisp[xxx]}
                </div>;
       
       
       // minimal={true}
       
       p_content = <div>
                       <div><CmCachedCustLink rid={sta.row.trp_customer}  /></div>
                       {p_sta}
                       <PaymentInfo
                                   pay_id={sta.row.trp_payment_id}
                                   cur_trp_id={trp_id}
                                   cur_trp_status={trp_status}
                                   cur_trp_acts={sta.row.trp_acts_csv}
                                   ts_id={ts_id}
                                   trp_serial={trp_serial}
                                   />
                   </div>
   }
   else
   {
    p_content = <CmSpinner sz='32px' />;
   }
   
   
   
   

   
   
   let trp_is_closed = (trp_status === DEFS.TRP_STATUS_CLOSED);
   
   let dis_togg = false;
   
   if (sta.row)
   {
       const ACT_RESERVE = hk_act.getBySpecial( 1 );
       if (ACT_RESERVE === null)
       {
           console.warn('wtf ?');
       }
       else
       {
           // Бронь нельзя закрыть
            if (HLP.CsvTestID( sta.row.trp_acts_csv, ACT_RESERVE.ea_id ))
            {
                dis_togg = true;
            }
       }
   }
   
   
   
   let p_panel;
   
   if (sta.row)
   {
       let p_date_bound = <BoundDt dt={sta.row.trsb_dt_bound}/>
       


       p_panel = (<><div style={{alignItems: 'center', display: 'flex', gap: '16px', position: 'absolute', right: '16px', top: '8px' }}>
               
                   
                   {
						on_swap ? (
							<DaIconBtn  icon={SwapHorizIcon} help='ВЫБРАТЬ ДРУГУЮ ТРЕНЮ' onClick={cb_swap} disabled={trp_is_closed}  clr='#80A0FF' tp_placement='top' />)
								: null
					}
                   {  
                       trp_is_closed ?
                                   <DaIconBtn  icon={LockOpenIcon} help='ОТКРЫТЬ' onClick={cb_togg_status}  disabled={dis_togg} tp_placement='top' />
                                   :
                                   <DaIconBtn  icon={LockIcon} help='ЗАКРЫТЬ' onClick={cb_togg_status}  disabled={dis_togg} tp_placement='top' />
                                   
                       
                   }
                   
                   
                   
                   <DaIconBtn  icon={DirectionsRunIcon} help='НЕ ПРИШЁЛ !' onClick={cb_abandon}  disabled={trp_is_closed} tp_placement='top' />
                   
                   <DaIconBtn  clr='#8F7030' icon={WhatshotIcon} help='СЖЕЧЬ ТРЕНЮ' onClick={cb_burn}  disabled={trp_is_closed} tp_placement='top' />
                   
                   <DaIconBtn  icon={CloseIcon} help='УБРАТЬ КЛИЕНТА' onClick={cb_remove} clr='#A03030' tp_placement='top'  />

               
               </div>
               {p_date_bound}
               </>);
   }
   else
   {
       p_panel = null;
   }
   

   
   
   return <div style={{position: 'relative', border: '1px solid #00000020', margin: '10px', padding: '12px', background: '#F8F8FF', borderRadius: '4px' }}>
               
               {   trp_is_closed ?
                       <div style={{position: 'absolute', bottom: '19px', right: '26px', opacity: 0.06}}>
                           <div className="zz_big_lock"></div>
                       </div>
                   :
                   null
               }
               
               {p_content}
               {p_panel}
           </div>;
}



*/



