import React from 'react';


function LaySpaceImp( )
{
    return <div style={{width: '100%', height: '100%'}}></div>;
}

function LayHoriz2Imp( {cols, sx, children, className, gap, padding, ac} )
{
    const st = {height: '100%', width: '100%', display: 'grid', gridTemplateColumns: cols, gridTemplateRows: '1fr', ...sx };
    
    if (gap) st.gap = gap;
    if (padding) st.padding = padding;
    if (ac) st.alignItems = 'center';
    
    return (<div className={className} style={st}>
                {children}
            </div>);
}

function LayVert2Imp( {rows, sx, children, className, gap, padding, ac} )
{
    const st = {height: '100%', width: '100%', display: 'grid', gridTemplateColumns: '1fr', gridTemplateRows: rows, ...sx };
    
    if (gap) st.gap = gap;
    if (padding) st.padding = padding;
    if (ac) st.alignItems = 'center';
    
    return (<div className={className} style={st}>
                {children}
            </div>);
}


function LayCenterImp( {sx,children,className} )
{
    return (<div className={className} style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            {children}
            </div>
    
            );
}



export const LaySpace   = React.memo( LaySpaceImp );
export const LayHoriz2  = React.memo( LayHoriz2Imp );
export const LayVert2   = React.memo( LayVert2Imp );
export const LayCenter  = React.memo( LayCenterImp );
