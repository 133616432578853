import React from 'react';

import { CmSpinner2 } from '../cm_base.js';
import {  NuPost2 } from '../api.js';

//--------------------------



export function useCustBonus( cuId )
{
    const [valBonus, setValBonus]   = React.useState( null );
    const [valRankId, setValRankId] = React.useState( 0 );

    //console.log('useCustBonus ', cuId);

    React.useEffect( () => {

        if (cuId >0)
        {
            //console.log(' req...');

            function __ok(e)
            {
                //console.log('use-cust-b.. recv ', e);
                setValBonus( e.info.cbc_bonus_amount );
                setValRankId( e.info.cbc_bonus_rank_id );
            }

            function __fail(e)
            {
                
            }

            NuPost2( 1104, {cu_id:cuId}, __ok, __fail );
        }
        else
        {
            setValBonus( 0 );
            setValRankId( 0 );
        }

    }, [cuId] );


    return {  bonus: valBonus,  rankId: valRankId };
}



// инфа по балансу клиента
export function CustBonusState( {cuId, ser} )
{
    const [row, setRow] = React.useState( null );
    const [rnk, setRnk] = React.useState( null );

    React.useEffect( () => {

        function __ok(e)
        {
            setRow( e.info );
            setRnk( e.rank );
        }

        function __fail(e)
        {
        }

        NuPost2( 1104, {cu_id:cuId}, __ok, __fail );

    }, [cuId, ser] );

	if (row === null) return <CmSpinner2 sz={32} />;

    let p_rank = null;

    if (rnk)
    {
        p_rank = ( <div style={{fontFamily: 'body', padding: '10px', fontSize: '16px', textAlign: 'center', color: 'var(--fl-clr-primary)'}}>
                        <div>УРОВЕНЬ: {rnk.br_name}</div>
                        <div>+ ЗА КАЖДУЮ ТРЕНЮ: {rnk.br_add_per_train}</div>
                </div> );
    }


    return (

    <div > 
        <div style={{fontFamily: 'body', padding: '10px', fontSize: '26px', textAlign: 'center', color: 'var(--fl-clr-primary)'}}>БОНУСОВ: {row.cbc_bonus_amount}</div>
        
        {p_rank}

        <div style={{padding: '10px', fontSize: '17px', textAlign: 'center', color: '#505050'}}>Закрытых трень: {row.cbc_closed_trains}</div>

    </div>



    );
} 


//         <div style={{padding: '10px', fontSize: '17px', textAlign: 'center', color: '#505050'}}>КАССЫ ЗАНЁС: {row.cbc_total_cash}</div>
