 
import React from 'react';

import { NuPost, NuPost2 } from '../../api.js';
import { CommonReducer, ShowErr } from '../../misc_glob.js';
import { DaList2 } from '../../misc/list2.js';
import { OverlayLoading } from '../../misc/ovr_loading.js';

import { ClubListText } from './club_list_text';





function TextHiLiteForSearch( txt, fnd )
{
    const le = fnd.length;
    if (le < 1) return txt;
    
    const i = txt.toLowerCase().indexOf(fnd.toLowerCase());
    if (i === -1) return txt;
    
    const r = [];
    r.push( <span key='1'>{txt.substring(0,i)}</span> );
    r.push( <span key='2' style={{background: '#6A4CD7', color: '#FFFFFF', paddingTop: '2px', paddingBottom: '2px', borderRadius: '4px'}}>{ txt.substring(i,i+le) }</span> );
    r.push( <span key='3'>{txt.substring(i+le)}</span> );
    
    return <span >{r}</span>;
}


//------------------

const gST1 = {

    list:       [],
    busy:       false,

};



//-----------------------------------------------------



function GuideListItemImp( {kk, vv, row, isCur, ext} )
{
    const ar = TextHiLiteForSearch(vv, ext);
    
    const CSV = row.tg_for_clubs_csv;

    return (<div className="gu_list_full" >
                <div className="tit" >{ar}</div>
                <div className={ CSV === '' ? "clu" : "clu many"} ><ClubListText csv={CSV} /></div>
            </div>);
}

export const GuideListItem = React.memo( GuideListItemImp );

//-------------------
 

function GuideListItemPlainImp( {kk, vv, row, isCur, ext} )
{
    const ar = TextHiLiteForSearch(vv, ext);
    return (<div className="gu_list_plain">{ar}</div>);
}

export const GuideListItemPlain = React.memo( GuideListItemPlainImp );


//---------------------------------------------


// список ГАЙДОВ
export function GuideList( {srch, curRid, ser, onSel, showClubs} )
{
	const [sta, dispatch] = React.useReducer( CommonReducer, gST1 );
    
    React.useEffect( () => {
          
        function __ok(e)
        { 
            //console.log('GOT ! ', e);
            dispatch( {type: 'vals', dct: {list: e.rows, busy: false} } );
        }
        
        function __fail(e)
        {
            ShowErr(e.m);
            dispatch( {type: 'vals', dct: {busy: false} } );
        }
        
        dispatch( {type: 'vals', dct: {busy: true} } );
        
        NuPost2( 1024, {srch:srch}, __ok, __fail );
        
    }, [srch, ser] );


    const cb_sel = function(v, v2) {

        if (curRid !== v2)
        {
            onSel( v2 );
        }

    };
    

    return (

        <div style={{position: 'relative', width: '100%', height: '100%', padding: '4px'}}>
             <DaList2 itemCmp={showClubs ? GuideListItem : GuideListItemPlain}  ext={srch} value={curRid}  src={sta.list}  fld_k='tg_id' fld_v='tg_title' onChange={cb_sel}  />
             <OverlayLoading active={sta.busy} />
        </div>

    );
}