import React from 'react';


 


const gState = {
    
    ca_show_time_cols:          false,
	ca_bot_short_names:         false, 
	ca_show_today_border:       false,
	ca_show_impo_in_squares:    false,
	pays_view_no_free:          true,
    
};


function __reducer(sta, act)
{
	switch(act.type)
	{
		case 'val':
		{
            console.log('user-opts VAL... ', act.k, ' == ', act.v);
			return { ...sta, [act.k]: act.v };
		}
        
        case 'vals':
        {
            const dct = act.dct;
            
            const s = { ...sta };

            for (let k in dct)
            {
                s[ k ] = dct[k];
            }

            return s;
        }
        
        case 'load':
        {
            const e = act.e;
            console.log('U REDU.. LOAD ! ', e);
            
            const s = {...sta };

            for (let k in e)
            {
                s[k] = e[k];
            }
            
            return s;
        }
        
	}
	
	return sta;
}




//----------------------------------------

export const UserOptsContext            = React.createContext( null );
export const UserOptsDispatchContext    = React.createContext( null );


export function UserOptsProvider( {children} )
{
    const [sta, dispatch] = React.useReducer( __reducer, gState );
    
    return (
        <UserOptsContext.Provider value={sta} >
            <UserOptsDispatchContext.Provider value={dispatch} >
                {children}
            </UserOptsDispatchContext.Provider>
        </UserOptsContext.Provider>
    );
}
 
 
//-------------------------------

export function useUserOpts()
{
    return React.useContext( UserOptsContext );
}


export function useUserOptsDispatch()
{
    return React.useContext( UserOptsDispatchContext );
}

