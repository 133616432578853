 
import React from 'react';

import { LayVert2, LayHoriz2, LaySpace } from '../../misc/layout.js';
import { NuPost } from '../../api.js';
import { CommonReducer, ShowMsg, ShowErr } from '../../misc_glob.js';
import { OverlayLoading } from '../../misc/ovr_loading.js';
import { STO_InvaGuideList } from '../../store.js';
import { DaTextField } from '../../misc/text_field.js';
import { DaButton } from '../../misc/but.js';

import { WinClubCsv } from '../../desktop/win_club_csv.js';

import * as DT from '../../dt.js';
import * as HLP from '../../helpers.js';
import { CmCachedUserLink } from '../../desktop/ca_user_link.js';

import { ClubListText } from './club_list_text';





function MyClubsFld( {csv, onClick} )
{
    return (<div className="zz_small" onClick={onClick} style={{cursor: 'pointer', textDecoration: 'underline', maxWidth: '300px'}}>КЛУБЫ: <ClubListText csv={csv}></ClubListText></div>);
}

//--------------------------------------------------------

const gST99 = {

    clubs_csv: '',
    tit: '',
    text: '',
    dt_touch: null,
    uid: -1,
    dirty: false,
    pop:    0,
};


// редактор ГАЙДА - заголовок, текст....
export function GuidesTextEditor( {rid, onSaved} )
{
    const [sta, dispatch] = React.useReducer( CommonReducer, gST99 );

    React.useEffect( () => {

        if (rid === -1) return;

        function __ok(e)
        {
            const R = e.row;
            dispatch( {type: 'vals', dct: {dirty: false,
                                                clubs_csv: R.tg_for_clubs_csv,
                                                tit: R.tg_title,
                                                text: R.tg_text,
                                                uid: R.tg_who_user_id,
                                                dt_touch: DT.DateTimeParseIso(R.tg_dt_touch),
                                                busy: false} } );
        }
        
        function __fail(e)
        {
            ShowErr(e.m); 
            dispatch( {type: 'vals', dct: {busy: false} } );
        }
        
        dispatch( {type: 'vals', dct: {busy: true} } );

        NuPost( 1023, {rid: rid}, __ok, __fail );

    }, [rid] );

    const cb_chg_juice = React.useCallback( (k,v) => {
        dispatch( {type: 'vals', dct: { [k]: v, dirty: true}  } );
    }, [] );



    if (rid === -1)
    {
        return <LaySpace />;
    }

    function cb_got_csv(csv)
    {
        dispatch( {type: 'vals', dct: { pop: 0, clubs_csv: csv, dirty: true}  } );
    }

    function cb_click_clubs()
    {
        dispatch( {type: 'vals', dct: { pop: 1}  } );
    }

    function cb_close_pop()
    {
        dispatch( {type: 'vals', dct: { pop: 0}  } );
    }

    function cb_submit()
    {
            function __ok(e)
            {
                ShowMsg('СОХРАНЕНО !');
                dispatch( {type: 'vals', dct: { busy: false, dirty: false}  } );
                
                STO_InvaGuideList();

                onSaved();
            }
            
            function __fail(e)
            {
                ShowErr(e.m);
                dispatch( {type: 'vals', dct: { busy: false}  } );
            }
            
            dispatch( {type: 'vals', dct: { busy: true}  } );
            
            NuPost(1026, {rid:rid, tit:sta.tit, tex:sta.text, clubs:sta.clubs_csv}, __ok, __fail);
    }


    const WARN_TIT = sta.tit.trim() === '';

    const DIS_SUBMIT = !sta.dirty || WARN_TIT;

    return (
        <>
        <LayVert2 rows='max-content 1fr max-content' gap='20px' padding='10px' sx={{position: 'relative'}} >

            <LayHoriz2 cols='400px 1fr' gap='10px' sx={{alignItems: 'center'}}>
                <DaTextField name='tit' value={sta.tit} label='НАЗВАНИЕ' onChange={cb_chg_juice} error={WARN_TIT} />

                <LayVert2 rows='max-content max-content' gap='2px' padding='6px' >
                    <MyClubsFld csv={sta.clubs_csv} onClick={cb_click_clubs} />
                    <div className="zz_small" >ИЗМЕНЕНО: { DT.DateTimeStrLoc(sta.dt_touch) } / <CmCachedUserLink plain rid={sta.uid} /></div>
                </LayVert2>
            </LayHoriz2>

            <DaTextField name='text' value={sta.text} label='ТЕКСТ' onChange={cb_chg_juice} multiline input_sx={{padding: '8px', fontSize: '21px'}}  />

            <LayHoriz2 cols='1fr max-content' gap='10px'>
                <LaySpace />
                <DaButton title='СОХРАНИТЬ' disabled={DIS_SUBMIT} onClick={cb_submit}/>
            </LayHoriz2>

            <OverlayLoading active={sta.busy} />
        </LayVert2>
        
        <WinClubCsv initVal={sta.clubs_csv} visible={sta.pop === 1} onOk={cb_got_csv} onClose={cb_close_pop} title='В КАКИХ КЛУБАХ ОТОБРАЖАТЬ ?' />

        </>
    );
}

