import React from 'react';

import { DaIconBtn } from './icon_btn';
import { DaOutlinedShit } from './outlined';


import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
//----------------------------------

const gSW = {width: '100%', height: '34px', padding: '4px', display: 'flex', justifyContent: 'space-between', alignItems: 'center'};

function DaSwitcherImp( {name, value, label, onChange, disabled, dis_left, dis_right, error})
{
    const cb_left = () =>
	{
		onChange(name, -1);
	};
	
	const cb_right = () =>
	{
		onChange(name, 1);
	};
	
    const jj = ( <div style={gSW}>
                    <DaIconBtn icon={ FaChevronLeft } disabled={disabled||dis_left} onClick={cb_left}/>
                    <div style={{userSelect: 'none'}}>{value}</div>
                    <DaIconBtn icon={ FaChevronRight } disabled={disabled||dis_right} onClick={cb_right} />
                </div>
    );

    return (

        <DaOutlinedShit error={error} borderIsFoc={false} juice={jj} labelIsFloat={true} labelVal={label}/>
    );
}



export const DaSwitcher = React.memo( DaSwitcherImp );