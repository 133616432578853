import React from 'react';


import * as DEFS from '../defs.js';
import * as HLP from  '../helpers.js';

import { NuPost2 } from '../api.js';

import {DaCombo} from '../misc/combo.js';

import { ShowErr, ShowMsg  } from '../misc_glob.js';

//-------------------------------------

const ELEM_ALL = [ {prgc_id:-1, prgc_name: '???'} ];

export function ComboGoodCat( {name, value, disabled, label, error, onChange} )
{
    const [lst, setLst] = React.useState( ELEM_ALL );
    
    
    React.useEffect( () => {
        
        function __ok(e)
        {
            setLst( ELEM_ALL.concat( e.rows) );
        }
        
        function __fail(e)
        {
            ShowErr(e.m);
        }
        
        NuPost2( 951, {}, __ok, __fail );
        
    }, [] );
    
    
    const cb_chg = React.useCallback( (k,v) => {
        onChange( name, v );
    }, [value, name] );
    


	return <DaCombo error={error} disabled={disabled} label={label} value={value} src={lst} fld_k='prgc_id' fld_v='prgc_name' onChange={cb_chg} />;
}
