import React from 'react';

import { useApiQuery2 } from '../api_stuff.js';

import {DaToolTip} from '../misc/tooltip.js';

import * as HLP from '../helpers.js';
import * as DEFS from '../defs.js';

import { CmSpinner2 } from '../cm_base.js';

import { CustNameShorten } from '../misc_glob.js';

import * as DT from '../dt.js';

import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import NoPhotographyIcon from '@mui/icons-material/NoPhotography';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import EditIcon from '@mui/icons-material/Edit';

import PhoneIcon from '@mui/icons-material/Phone';
import { useCuCaMgrState } from '../menu2/hk_cuca_mgr_state.js';

//--------------------------------------


function __CalcNotFilled(r)
{
	const ar = [];

	if (r.cu_contract_dt === null) ar.push('договор не подписан');

	if (r.cu_weight < 1) ar.push('вес');
	if (r.cu_height < 1) ar.push('рост');

	if (DT.DateIsoParseYear(r.cu_dob) <= 1900) ar.push( 'дата рожд' );

	if (r.cu_f_can_record === 3) ar.push('можно снимать?');

	if (r.cu_gender === 2) ar.push('пол');

	if (r.cu_hand_pref == 2) ar.push('рабочая рука');

	if (r.cu_opt_sparring + r.cu_opt_group + r.cu_opt_pairs + r.cu_opt_only_solo < 1) ar.push('формат не указан');

	if ( ar.length < 1) return null;	// no error
	return ar;
}




const gSX33 = {display: 'flex', flexDirection: 'column', fontSize: '14px'};



function NotFilledListImp( {ar} )
{
	let r = [];

	for (let x = 0; x < ar.length; x ++ )
	{
		r.push( <span key={x}>- {ar[x]}</span> );
	}

	return (
		<div style={gSX33}>
			<span>НЕЗАПОЛНЕНА АНКЕТА !</span>
			{r}
		</div>
	);
}

const NotFilledList = React.memo( NotFilledListImp );

//--



const ICON_SZ = '16px';


function __FmtPrefCoStr(csv)
{
	let r = [];

	const sp = HLP.CsvSplit(csv);

	for (let x = 0; x < sp.length; x ++ )
	{
		const Q = HLP.ArrayOfDictsFindByPropValue(DEFS.gSrcCustContactPref, 'k', sp[x]);
		if (Q)
		{
			r.push( Q.v );
		}
	}

	return r.join(', ');
}

function CmCachedCustLinkImp(pp)
{
	const rid = pp.rid;
    
	const CCM = useCuCaMgrState();
    
	
	const { isLoading, isError, data, error } = useApiQuery2( 967, {id:rid}, {enabled: rid !== null} );
	
	if (isLoading) return <div className="yy_cust_link_normal"><CmSpinner2 sz={14} /></div>;
	if (isError) return <div className="yy_error">{error}</div>;
	
	//console.log( data );
    
    if (rid === null) return null;
	
	const row = data.row;
	
	
	
	if (row === null) return <div className="red_shit">{'НЕ СУЩЕСТВУЕТ (' + rid + ')'}</div>;
	
	
   
   
   let p_comm = ( data.v_com && !pp.no_com ) ? <DaToolTip title={<><div className="faint">КОММЕНТ ПО КЛИЕНТУ:</div><div>{data.v_com}</div></>}><PriorityHighIcon size='small'  sx={{fontSize: ICON_SZ, color: '#A05050'}}/></DaToolTip> : null;
        
   let p_fill = null;
   let p_photo = null;
   let p_med = null;
   
   let p_ic_conta = null;

        
   if (pp.extra_icons)
   {


		const not_filled = __CalcNotFilled( row );
		if (not_filled)
		{
			const pp = <NotFilledList ar={not_filled} />
			p_fill = <DaToolTip title={pp}>
						<EditIcon size='small'  sx={gSx99} />
					</DaToolTip>
		}

       
       if (row.cu_f_can_record === 1)
       {
			p_photo = (<DaToolTip title="МОЖНО СНИМАТЬ"><PhotoCameraIcon size='small' sx={{fontSize: ICON_SZ}}/></DaToolTip>);
		}
       else if (row.cu_f_can_record === 0)
       {
			p_photo = (<DaToolTip title="НЕЛЬЗЯ СНИМАТЬ"><NoPhotographyIcon size='small' sx={{fontSize: ICON_SZ}}/></DaToolTip>);
	   }

	   if (data.v_pref_co)
	   {
			const fff = __FmtPrefCoStr(data.v_pref_co);
	   		p_ic_conta = <DaToolTip title={"УДОБНЫЙ СПОСОБ СВЯЗИ: " + fff} ><PhoneIcon size='small' sx={{fontSize: ICON_SZ}}/></DaToolTip>;
	   }

       if (data.v_med && data.v_med !== DEFS.CUST_FIELD_VAL_NONE)
       {
		   const cn = 	<>
						<div className="faint">МЕД ПРОТИВОПОКАЗАНИЯ:</div>
						<div>{data.v_med}</div>
						</>;
		   
		   p_med = 	<DaToolTip offx={20} offy={-20} title={cn}>
						<LocalHospitalIcon size='small' sx={{fontSize: ICON_SZ}}/>
					</DaToolTip>;
	   }

   }
        
        
	
	
    let name_disp;
    
    if (!pp.noname)
    {
		name_disp = row.cu_name;
		if (pp.shorten) name_disp = CustNameShorten(name_disp);
	}
	else name_disp = null;
	
	
	let p_nam;
	
	if (!pp.plain)
	{
		p_nam = <div onClick={ (e) => CCM.tryOpen(rid) } className="te">{pp.bullet ? '• ' : null}{ name_disp }</div>;
	}
	else
	{
		p_nam = <div style={{color: pp.plain_clr ? pp.plain_clr : '#FFFFFF'}}>{pp.bullet ? '• ' : null}{ name_disp }</div>;
	}
	
	
	return <div  className={pp.plain ? "yy_cust_link_plain" : "yy_cust_link_normal"}>
				{p_nam}
				{p_comm}{p_fill}{p_med}{p_photo}{p_ic_conta}
			</div>;
}

export const CmCachedCustLink = React.memo( CmCachedCustLinkImp );


const gSx99 = {fontSize: ICON_SZ, color: '#A05050'};
