 
import React from 'react';


import * as DEFS from '../defs.js';
import * as HLP from '../helpers.js';

import { LayHoriz2, LayVert2, LaySpace } from '../misc/layout.js';

import { NuPost2 } from '../api.js';

import { useGlobalShit, useGlobalShitDispatch } from './hk_global_shit.js';
import { CmSpinner2 } from '../cm_base.js';
import { AuthScreen3 } from './auth3.js';
import { useMainMenuControl, SideMenu2 } from '../menu2/menu.js';


import { CuCaMgrCardWindowsContainer } from '../menu2/cuca_mgr.js';

import { BiCalendar } from "react-icons/bi";
import { BsFillPersonFill } from "react-icons/bs";
import { BiHomeAlt2 } from "react-icons/bi";
import { BiPurchaseTag } from "react-icons/bi";
import { BiPhoneIncoming } from "react-icons/bi";
import { IoIosStats } from "react-icons/io";
import { IoMdHelpCircleOutline } from "react-icons/io";
import { IoWarningOutline } from "react-icons/io5";
import { MdSportsMma } from "react-icons/md";
import { BsBoxes } from "react-icons/bs";
import { FaTasks } from "react-icons/fa";
import { BsPersonWorkspace } from "react-icons/bs";
import { MdGroups } from "react-icons/md";
import { BiCoinStack } from "react-icons/bi";


import { UserOptsMgr } from './opts.js';

// OLD SHIT
import moment from 'moment';
import "moment/locale/ru";

import { ThemeProvider } from '@mui/material/styles';
import { gThemeForMUI } from '../theme.js';


import { QueryClientProvider } from 'react-query'
import { gQueryClient } from '../store.js';


import { TabLogClub, TabLogBOSS } from '../tabs/log.js';
import { TabProductsAbons, TabProductsAbonsBOSS } from '../tabs/prods_abons.js';
import { TabPrGoods, TabPrGoodsBOSS } from '../tabs/prod_goods/main.js';

import { TabPrSell } from '../tabs/prod_goods_sell/main.js';

import { TabPrGoodsIncome } from '../tabs/prod_goods_income/main.js';

import { OverlayLoading } from '../misc/ovr_loading.js';

import { TabPays } from '../tabs/payments.js';

import { TabPayReturns } from '../tabs/pay_returns.js';

import { TabAllTrp } from '../tabs/all_trp.js';

import { MeNotifyCureq } from '../desktop/notify_cureq.js'
import { MeNotifyTasks } from '../desktop/notify_tasks.js'


import { TabBossCustSrc } from '../tabs/b_cust_src.js';
import { TabUserRoleAbility } from '../tabs/b_user_role_ability.js';
import { TabTechs } from '../tabs/techs.js';
import { TabGuidesCLUB, TabGuidesBOSS } from '../tabs/guides/tab.js';
import { TabCustomers } from '../tabs/customers.js';

import { TabUsers, TabMasterUsers } from '../tabs/users.js';
import { TabCustBalanceOps } from '../tabs/cust_balance_ops.js';
import { TabStatsTopPayers } from '../tabs/stat_top_payers.js';
import { TabStatsGraphMonths } from '../tabs/stat_graph_months.js';

import { TabStatOrigsByMonth } from '../tabs/stat_origins_mon.js';
import { TabTrainerRewards } from '../tabs/trainer_rewards.js';
import { TabStatsMoney } from '../tabs/stat_money.js';
import { TabPayOps } from '../tabs/pay_ops.js';
import { TabBroadcast } from '../tabs/broadcast.js';
import { UpdCheckerShit } from './upd_checker.js';


import { ShowErr } from './toast.js';
//--------------------------------------

const TabStatVisitsGraphs   = React.lazy( () => import("../tabs/stat_visits_graphs.js") );
const TabStart              = React.lazy( () => import("../tabs/start.js")  );
const TabCalen4             = React.lazy( () => import("../cm_calen4.js")  );
const TabBonusDefs          = React.lazy( () => import('../tabs/bonus_defs.js') );
const TabBonusHist          = React.lazy( () => import('../tabs/bonus_hist.js') );
const TabBonusRanks         = React.lazy( () => import('../tabs/bonus_ranks.js') );
const TabStatAbonKinds      = React.lazy( () => import("../tabs/stat_abon_kinds.js") );
const TabStatClosedTrains   = React.lazy( () => import("../tabs/stat_closed_trains.js"));
const TabWsched2            = React.lazy( () => import("../tabs/wsched2.js"));
const TabCuReq              = React.lazy( () => import("../tabs/cu_req/main.js"));
const TabTasksNu2           = React.lazy( () => import('../tabs/tasks2/main.js'));
const TabStatOrigsComb      = React.lazy( () => import('../tabs/stat_origins_comb.js'));

const TabDevPanel1          = React.lazy( () => import('../tabs/dev1.js'));
const TabCerts              = React.lazy( () => import('../tabs/certs.js'));
const TabEvActs             = React.lazy( () => import('../tabs/ev_acts.js'));

const TabLog2               = React.lazy( () => import('../tabs/log_new.js'));



const gMenuItemsPRODS = [

    { n: 'Абонементы',          c: 'prods_abons'  },
    { n: 'Товары',          c: 'pr_goods'  },    
    { n: 'Поступления',   c: 'pr_income'  },
];



const gMenuItemsSELLS = [

    { n: 'Абонементы',    c: 'pays2'  },
    { n: 'Товары',        c: 'pr_sell_goods'  },
    { n: 'Возвраты',   c: 'rets'  },
];

const gMenuItemsBONUS = [

    { n: 'Конфиг',              c: 'bonus_defs'  },
    { n: 'Уровни',              c: 'bonus_ranks' },
    { n: 'Лог',                 c: 'bonus_hist' },
    
];



const gMenuItemsMISC = [

    { n: 'Лог (Клуб)',            c: 'log_club' },


	{ n: 'Фин. стата',         c: 'stats_money' },
    { n: 'Фин. операции',      c: 'payops' },
    { n: 'Оп. с балансом',     c: 'balanceops' },
    { n: 'Расчёт тренеров',		c: 'trainer_rewards' },
];

const gMenuItemsADMIN = [

    { n: 'Источники кл.',       c: 'b_cust_src' },
    { n: 'Роли',                c: 'b_uab' },
    { n: 'Техники',             c: 'techs' },
    { n: 'Подсказки ред.',      c: 'guides_b' },
    { n: 'Юзеры (Все)',           c: 'users_m' },
    { n: 'Товары (A)',          c: 'pr_goods_b'  },
    { n: 'Абонементы (A)',      c: 'prods_abons_b'  },



    { n: 'Лог (Общее)',         c: 'log_mst' },

    { n: 'Лог (новый)',         c: 'log2'  },

    { n: 'Сертификаты',         c: 'certs' },



    { n: 'ДВИЖ (test)',                c: 'ev_acts', needAbil: 3002 },

    { n: 'TG / рассылка',  c: 'brd', needAbil: 2002 },

    { n: 'DEV1 (test)',            c: 'dev1', needAbil: 3001 },
    
    
];

const gMenuItemsSTAFF = [

    { n: 'Юзеры (клуб)',     c: 'users' },
    { n: 'Расписание',       c: 'wsched2'  },	

];


const gMenuItemsSTATS = [

    { n: 'Посещения',                   c: 'stats_vg' },
    { n: 'Покупки абонов',              c: 'stats_ak' },

    { n: 'Закрытые трени',              c: 'stat_closed_trains' },

    { n: 'Топ хлебных',                 c: 'stats1' },

    { n: 'Разное по мес.',              c: 'stats2', },

    { n: 'Источники по мес.',		        c: 'stats_om' },

    { n: 'Источники 2',                c: 'stats_orig_comb' },


];



 
const gMenuItemsMAIN = [

    { n: 'Главная',     c: 'start', ic: BiHomeAlt2  },
    { n: 'Календарь',   c: 'calen4', ic: BiCalendar },

    { n: 'Клиенты',     c: 'custo', ic: MdGroups  },

    { n: 'Продукты',     c: 'sub_prods',       s: gMenuItemsPRODS, ic: BsBoxes  },
    { n: 'Продажи',      c: 'sub_sells',       s: gMenuItemsSELLS, ic: BiPurchaseTag },

    { n: 'Тренировки',   c: 'all_trps', ic: MdSportsMma  },
    { n: 'Задачи',      c: 'tasks3', ex: MeNotifyTasks, ic: FaTasks   },

    { n: 'Персонал',    c: 'sub_staff',  s: gMenuItemsSTAFF, ic: BsFillPersonFill },

    { n: 'Заявки',      c: 'cureq',     ic: BiPhoneIncoming,                          needAbil: 2001,  ex: MeNotifyCureq  },

    { n: 'Разное',      c: 'sub_misc',        s: gMenuItemsMISC },


    { n: 'Подсказки', c: 'guides_c', ic: IoMdHelpCircleOutline },

    { n: 'Статы',       c: 'sub_stats',     s: gMenuItemsSTATS,     needAbil: 2000,  ic: IoIosStats },

    { n: 'Бонусы',     c: 'sub_bonus',       s: gMenuItemsBONUS,   needAbil: 2002, ic: BiCoinStack },


    { n: 'Управляющий',  c: 'sub_admin', s: gMenuItemsADMIN, ic: BsPersonWorkspace,  needAbil: 2003  }, 


];


// РАЗДЕЛЫ где должен быть выбран клуб
const gTabsNeedCurClubArr = [
    'calen4', 'log_club', 'start', 'all_trps', 'tasks3', 'custo',
    'users', 'cureq', 'payops', 'stats_money', 'balanceops', 'trainer_rewards',
];

const gScrForCode = {

    stats_ak:   TabStatAbonKinds,
    stat_closed_trains: TabStatClosedTrains,

    start:      TabStart,
    calen4:     TabCalen4,

    bonus_defs: TabBonusDefs,
    bonus_hist: TabBonusHist,
    bonus_ranks: TabBonusRanks,

    log_club    :  TabLogClub,
    log_mst     :  TabLogBOSS,

    log2:       TabLog2,

    stats_vg: TabStatVisitsGraphs,

    prods_abons : TabProductsAbons,
    prods_abons_b : TabProductsAbonsBOSS,


    pr_goods: TabPrGoods,
    pr_goods_b: TabPrGoodsBOSS,

    pr_income: TabPrGoodsIncome,

    pr_sell_goods: TabPrSell,

    pays2: TabPays,

    rets: TabPayReturns,

    all_trps: TabAllTrp,

    tasks3: TabTasksNu2,



    b_uab: TabUserRoleAbility,
    b_cust_src: TabBossCustSrc,
    techs: TabTechs,

    guides_b: TabGuidesBOSS,
	guides_c: TabGuidesCLUB,

    custo: TabCustomers,

	users: TabUsers,
    users_m: TabMasterUsers,

    cureq: TabCuReq,


    stats1:  TabStatsTopPayers,
    stats2: TabStatsGraphMonths,
    stats_om: TabStatOrigsByMonth,
    stats_orig_comb: TabStatOrigsComb,

    trainer_rewards: TabTrainerRewards,

    payops: TabPayOps,
    stats_money: TabStatsMoney,

    balanceops: TabCustBalanceOps,

    wsched2: TabWsched2,


    /*
    trk: TabCustTracking, 

    discounts: TabDiscounts,
    */

    brd: TabBroadcast,

    dev1: TabDevPanel1,

    certs: TabCerts,

    ev_acts: TabEvActs,
    
};



//---------------------------------







function __set_auth_or_login33( dsp )
{
    //console.log('__set_auth_or_login...');
    
    // got token ?
    const co = HLP.CookieGet( DEFS.FIGHTLIFE_SESSION_COOKIE );
    
    if (co === '')
    {
        //console.log('no token ! show auth');
        // show  LOGIN / PASS screen
        dsp( {type: 'val', k:'mode', v:'auth'} );
        return;
    }

    {
        function __ok(e)
        {
            console.log('AUTHORIZED ! ', e);
            
            HLP.CookieSet( DEFS.FIGHTLIFE_SESSION_COOKIE, e.token );

            dsp( {type: 'load', info: e} );
            dsp( {type: 'val', k:'mode', v:'work'} );
        }
        
        function __fail(e)
        {
            console.warn('FAILED TO AUTH...');
            dsp( {type: 'val', k:'mode', v:'auth'} );
            dsp( {type: 'val', k:'au_msg', v:e.m} );
        }
        
        NuPost2( 52, {tok: co}, __ok, __fail);
    
        dsp( {type: 'val', k:'mode', v:'login_wait'} );
    }
}




export function Root3()
{
    const gs    = useGlobalShit();
    const dsp   = useGlobalShitDispatch();



    React.useEffect( () => {

        function __ok(e)
        {
            console.log('GOT BOOT INFO.. ', e);
            console.log('go to auth screen');

            // got boot shit.. 

            __set_auth_or_login33( dsp );

            //dsp( {type: 'val', k:'mode', v:'auth' } );
        }

        function __fail(e)
        {
            // TODO... HANDLE

            dsp( {type: 'val', k:'au_msg', v:e.m} );
        }

        NuPost2( 50, {}, __ok, __fail );
    }, [] );


    if (gs.mode === 'boot' || gs.mode === 'login_wait')
    {
        return <ScrBoot />;
    }
    else if (gs.mode === 'auth')
    {
        function __ok(e)
        {
            console.log('cb GOOD LOGIN !', e);

            // good login !
            //STO_SetAuthInfo(e.info);
            
            //FillGloShit2(e.glo);
            
            //disp_ci( {type: 'load_full', e:e.club_info} );
            
            HLP.CookieSet( DEFS.FIGHTLIFE_SESSION_COOKIE, e.token );

            dsp( {type: 'load', info: e} );

            dsp( {type: 'val', k:'mode', v:'work'} );
        }

        function __fail(e)
        {
            console.log('failed login ! ', e);

            dsp( {type: 'val', k:'au_msg', v:e} );
        }

        return <AuthScreen3 msg={gs.au_msg}  onOk={__ok}  onFail={__fail} />;
    }
    else if (gs.mode === 'work')
    {


        return <ScrWork />;
    }


    return ( <div>UNK STATE</div> );
}

//---------------------------

function ScrBoot()
{
    return (

        <div className="fl_center" style={{background: '#0F0F0F'}}>
            <CmSpinner2 sz='128' />
        </div>
    );
}


function BusySuspend()
{
        return (   
            <div className="fl_center" style={{background: '#FFFFFF'}}>
                <CmSpinner2 sz='128' />
            </div>
        );
    
}



function MsgClubNotSelected()
{
        return (
            <div className="fl_center" style={{flexDirection: 'column', gap: '20px', fontSize: '20px', fontFamily: 'tit', background: '#FFFFFF', color: 'var(--fl-clr-primary)'}}>
                <div><IoWarningOutline style={{fontSize: '70px'}}/></div>
                <div>Для работы раздела нужно выбрать клуб!</div>
            </div>
        );
    
}

//-------------------------


function __MenuItemsNeedsClub( cc )
{
    return gTabsNeedCurClubArr.indexOf( cc ) !== -1;
}


function ScrWork(  )
{
    const gs        = useGlobalShit();

    const menuCtl   = useMainMenuControl();


    //console.log('ScrWork... serial ', gs.serial);

    let juice = null;

    const NEED_CUR_CLUB = __MenuItemsNeedsClub( menuCtl.curCode );

    const GOT_CLUB_SELECTED = gs.clubId > 0;

    if (NEED_CUR_CLUB && !GOT_CLUB_SELECTED)
    {
        juice = <MsgClubNotSelected />;
    }
    else
    {
        const ccc = gScrForCode[ menuCtl.curCode ];
        if (ccc)
        {
            console.log('create elem for ', menuCtl.curCode);
            juice = React.createElement( ccc, {key: gs.serial}  );
        }
    }




    return ( 

        <ThemeProvider theme={gThemeForMUI}> 
  
            <QueryClientProvider client={gQueryClient}>

    
            
            <LayHoriz2 cols='max-content 1fr' sx={gSS1}>

                <SideMenu2 defs={gMenuItemsMAIN} menuCtl={menuCtl} isClubSel={GOT_CLUB_SELECTED} cbNeedClubSel={__MenuItemsNeedsClub} />

                <React.Suspense fallback={ <BusySuspend /> } >

                    {juice}

                </React.Suspense>

                <CuCaMgrCardWindowsContainer />

                <UpdCheckerShit />
                <UserOptsMgr />

                <OverlayLoading active={gs.busy} />

                

            </LayHoriz2> 

                    
    </QueryClientProvider>
      
        </ThemeProvider>
    
    
    );
}



const gSS1 = {position: 'relative'};