 
import React from 'react';

import * as DEFS from '../defs.js';
import * as HLP from '../helpers.js';

import { NuPost, NuPost2 } from '../api.js';



import { DaIconBtn } from '../misc/icon_btn.js'; 


import { OverlayLoading } from '../misc/ovr_loading.js';

import { LayVert2, LayHoriz2 } from '../misc/layout.js';
import {DaVertScrollFrame, DaVertScrollJuice } from '../misc/vscr.js';

import { DaButton } from '../misc/but.js';
import { DaTextField } from '../misc/text_field.js';
import { DaNumericField } from '../misc/numeric.js';
import { CommonReducer, ShowMsg, ShowErr } from '../misc_glob.js';

import { Win2 } from '../misc/win2.js';


import { BottomButtonsPanel } from '../cust_card/bottom_pan.js';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

//-----------------


// TODO: optimize !

function SingleCat( {ind, valId, valName, onChange, onRemove} )
{
    function cb_chg1(k,v)
    {
        onChange( ind, v, valName  );
    }
    
    function cb_chg2(k,v)
    {
        onChange( ind, valId, v  );
    }
    
    function cb_rem()
    {
        onRemove(ind);
    }
    
    const ERR_NAM = valName.trim() === '';
    const ERR_ID = valId < 1;
    
    return <LayHoriz2 cols='70px 1fr max-content' sx={{gap: '10px', padding: '10px'}} >
                <DaNumericField name='id' value={valId} min={1} max={99}  onChange={cb_chg1} error={ERR_ID} />
                <DaTextField name='nam' value={valName} onChange={cb_chg2}  error={ERR_NAM} />
                <DaIconBtn clr='#A04040'  icon={CloseIcon} help='УБРАТЬ' onClick={cb_rem}  />
            </LayHoriz2>
}

//-------------------------------------

// move to LIB !! TODO
function __GotDupKeys(ar, kk)
{
    const t = [];
    for (let x = 0; x < ar.length; x ++ )
    {
        const e = ar[x];
        const cur_k = e[kk];
        if (t.indexOf(cur_k) !== -1) return true;
        t.push(cur_k);
    }
    return false;
}


function MyBody( {onOk} )
{
    const [lst, setLst] = React.useState( [] );
    const [busy, setBusy] = React.useState( false );
    
    React.useEffect( () => {
        
        const cb_ok = (e) => {
            setLst( e.rows );
            setBusy(false);
            console.log(e);
		};
		
		const cb_fail = (e) => {
			setBusy(false);
            setLst( [] );
            ShowErr(e.m);
		};
		
		NuPost2(951, {}, cb_ok, cb_fail);
		
		setBusy(true);
        
    }, [] );
    
    function cb_chg_elem(i,kk,vv)
    {
        const nu = HLP.DeepCopy( lst );
        nu[i] = { prgc_id: kk, prgc_name: vv };
        setLst(nu);
    }
    
    function cb_new_cat()
    {
        const nu = HLP.DeepCopy( lst );
        nu.push( { prgc_id: 0, prgc_name: '' } );
        setLst(nu);
    }
    
    function cb_rem(i)
    {
        const nu = HLP.DeepCopy( lst );
        nu.splice(i,1);
        setLst(nu);
    }
    
    function cb_submit()
    {
		let cb_ok = (e) =>
		{
			ShowMsg('СОХРАНЕНО !');
			setBusy(false);
			if (onOk) onOk();
		};
		
		let cb_fail = (e) =>
		{
			ShowErr(e.m);
			setBusy(false);
		};
        
        const dct = {};
        
        for (let x = 0; x < lst.length; x ++ )
        {
            const ee = lst[x];
            
            dct[ee.prgc_id] = { name: ee.prgc_name };
        }
        
        setBusy(true);
		
		NuPost(952, {dct: dct}, cb_ok, cb_fail);
    }
    
    
    
    const ar = [];
    
    let err_in_fields = false;
    
    for (let x = 0; x < lst.length; x ++ )
    {
        const e = lst[x];
        ar.push( <SingleCat key={x} ind={x} valId={e.prgc_id} valName={e.prgc_name} onRemove={cb_rem} onChange={cb_chg_elem} />);
        
        if (e.prgc_id < 1) err_in_fields = true;
        if (e.prgc_name.trim() === '') err_in_fields = true;
    }
    
    const ERR_DUP_KEYS = __GotDupKeys( lst, 'prgc_id' );
    
    const DIS_SUB = ERR_DUP_KEYS || err_in_fields;
    
    return (
    
        <>
    
        <LayVert2 rows='1fr max-content' >
        
            <DaVertScrollFrame>
                <DaVertScrollJuice>
                
                    {ar}
                    <div className="fl_center" style={{paddingBottom: '30px'}}>
                        <DaIconBtn clr='#40A040'  icon={AddIcon} help='ЕЩЕ КАТЕГОРИЯ' onClick={cb_new_cat}  />
                    </div>
                
                </DaVertScrollJuice>
            </DaVertScrollFrame>
            
            <BottomButtonsPanel>
                <DaButton onClick={cb_submit} disabled={DIS_SUB} icon={CheckIcon}  title='СОХРАНИТЬ' />
            </BottomButtonsPanel>
        
        </LayVert2>
        
        
        <OverlayLoading active={busy} />
        
        </>
    
    );
}



// окно - новый товар / редакт товар
export function WinGoodCats( {visible, onOk, onClose} )
{
    return (
    
        <Win2 visible={visible} title='КАТЕГОРИИ ТОВАРОВ' onClose={onClose} width={400} height={500}  >
            <MyBody onOk={onOk} />
        </Win2>
    
    );
}
