import React from 'react';


import { NuPost2 } from '../api.js';

import * as DT from '../dt.js';

import { DaButton } from '../misc/but.js';

import { StatsDateRangeSelector, MODE_MONTH } from '../misc/stats_date_range_sel.js';

import { WinTrainerRewardEditor } from '../desktop/win_trainer_reward_ed.js';

import { OverlayLoading } from '../misc/ovr_loading.js';
import {DaIconBtn} from '../misc/icon_btn.js'; 

import {DaVertScrollFrame, DaVertScrollJuice} from '../misc/vscr.js'

import { LayVert2, LayHoriz2, LaySpace } from '../misc/layout.js';

import {  ShowErr, ShowMsg, CommonReducer } from '../misc_glob.js';


import { useGlobEvActs } from '../misc_glob.js';


import SettingsIcon from '@mui/icons-material/Settings';

import { useTrainerRewards } from '../desktop/win_trainer_reward_ed.js';

import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

//------------------------------------------





export function TabTrainerRewards(pp)
{
	const [flt, setFlt] = React.useState( {s: DT.DateStrIso(new Date()), e: DT.DateStrIso(new Date())} );
    const [showCfg, setShowCfg] = React.useState( false );
	
    const cb_sel_date = React.useCallback( (f) =>
    {
        setFlt( {s: DT.DateStrIso(f.s), e: DT.DateStrIso(f.e)} );
        
    }, [] );
    
    function cb_show_cfg()
    {
        setShowCfg(true);
    }

    function cb_hide_cfg()
    {
        setShowCfg(false);


    }

	return ( <>
                <LayVert2 rows='max-content 1fr' padding='20px' gap='10px' >

                <div style={{alignItems: 'center', padding: '10px', display: 'flex', justifyContent: 'center', borderBottom: '1px solid #00000020'}}>
                    
                    <StatsDateRangeSelector onChg={cb_sel_date}  />

                    <div style={{width: '50px'}}></div>

                    <DaButton title='НАСТРОИТЬ'  onClick={cb_show_cfg}  icon={SettingsIcon}  />
                </div>

                <ViTrainerStats ss={flt.s} ee={flt.e} />
		
            </LayVert2>

            <WinTrainerRewardEditor visible={showCfg} onClose={cb_hide_cfg} />

            </>
	
	);
}


//----------------------------------


const gSt99 = {
  
        users:  [],
        busy:   false,
};




function ViTrainerStats( {ss, ee} )
{
    const [sta, dispatch]   = React.useReducer( CommonReducer, gSt99 );
    
    
    const hk_reward         = useTrainerRewards();
    
    
    React.useEffect( () => {
        
        function __ok(e)
        {
            dispatch( {type: 'vals', dct: {
                            busy: false, users: e.usrs
                }
            } );
        }
        
        function __fail(e)
        {
            ShowErr(e.m);
            
            dispatch( {type: 'val', k:'busy', v: false} );
        }
        
        dispatch( {type: 'val', k:'busy', v: true} );
        
        NuPost2( 972, {ss:ss,ee:ee}, __ok, __fail);
        
    }, [ss, ee] );
    
    
    let ar = [];
    
    for (let x = 0; x < sta.users.length; x ++ )
    {
        const u = sta.users[x];
        if (u.evts.length < 1) continue;
        ar.push( <UsrBlock key={u.u_id} usr={u} hk_reward={hk_reward}/> );
    }
    
    if (ar.length < 1)
    {
        ar = ( <div  style={{padding: '20px', textAlign: 'center', width: '100%', fontSize: '20px', color: '#A0A0A0', fontFamily: 'body'}}>НЕТ РЕЗУЛЬТАТОВ !</div> );
    }
    
    
    return (
    
        <>
 
            <DaVertScrollFrame>
                <DaVertScrollJuice>
                    <LayHoriz2 cols='1fr 700px 1fr'>
                        <LaySpace />
                <div>{ar}</div>
                <LaySpace />
                  </LayHoriz2>
                </DaVertScrollJuice>
            </DaVertScrollFrame>
            
            
            <OverlayLoading active={sta.busy || hk_reward.isBusy() } />
            
            </>
        
        
        
   
    );
}


function UsrBlock( {usr, hk_reward} )
{
    //console.log('USR ', usr);

    const [showDet, setShowDet] = React.useState( false );      // пока подробную инфу
    
    
    function cb_switch_det()
    {
        setShowDet( !showDet );
    }
    
    const hk_act = useGlobEvActs();
    
    const dct_e = {};
    const ar_e = [];
    
    
    
    let rew_sum = 0;
    let num_free = 0;   // пробники
    
    let p_part;
    
    const NUM_EV = usr.evts.length;
    
    // iter events
    for (let x = 0; x < NUM_EV; x ++ )
    {
        const ee = usr.evts[x];
        
        const act = hk_act.getById( ee.ts_activity );

        const TM = DT.DateTimeStrLocHumSho( DT.DateTimeParseIso(ee.ts_time_start) );

        const REWARD        = hk_reward.get( ee.ts_activity, ee.v_bound );

        
        
        const kk = '' + ee.ts_activity + '-' + ee.v_bound;
        
        let de = dct_e[kk];
        if (de === undefined)
        {
            de = { bound: ee.v_bound, cnt: 0, act: ee.ts_activity, tot_reward: 0, reward_once: REWARD };
            dct_e[kk] = de;
        }
        
        de.cnt ++;
        
   
        de.tot_reward += REWARD;
        
        rew_sum += REWARD;

        

        num_free += ee.ts_is_free_train;
        
        if (showDet)
        {
            ar_e.push( <div key={ee.ts_id} className="trainer_reward_line">
                            <div className="ind">{x+1}</div>
                            <div className="tm">{TM}</div>
                            <div className="act">{act.ea_name}</div>
                            <div className="dudes">{ee.v_bound} чел</div>
                            <div className="nums">{p_part}</div>
                        </div> );
                    
        }
                    
                    

        

        
    }


    //rew_sum += usr.merch;
    
    let ar_d = [];
    
    for (let k in dct_e)
    {
        const t = dct_e[k];
        
        const act = hk_act.getById( t.act );
        
        const sub_bound = t.bound === -1 ? '' : (` (${t.bound} чел)`);
        
        ar_d.push( <div style={gST98} key={k} >
            {act.ea_name}{sub_bound}: <b style={{color: 'var(--fl-clr-primary)', fontSize: '20px'}}>{t.cnt} x {t.reward_once} = {t.tot_reward}</b>
        </div> );
    }


    const EXTRA_PAY_FOR_SPECIAL_CARE = usr.numWithSpecialCare * 200;

    const TO_PAY_FINAL = rew_sum + usr.merch + EXTRA_PAY_FOR_SPECIAL_CARE - usr.advance;
    
    return (
            <div style={gSTY1}>
                <div style={gSTY2}>
                    {usr.u_name}
                    <div style={{position: 'absolute', top: '2px', right: '10px'}}>
                        <DaIconBtn onClick={cb_switch_det} clr='#FFFFFF' icon={showDet ? UnfoldLessIcon : UnfoldMoreIcon} help='ПОКАЗАТЬ / СКРЫТЬ СПИСОК ТРЕНЬ' />
                    </div>
                </div>
                {ar_e}
                <div style={{height: '10px'}}></div>
                {ar_d}
                <BtmBlock tit='Всего тренировок:'    val={NUM_EV} />
                <BtmBlock tit='Из них пробников:'    val={num_free} />
                <BtmBlock tit='Всего клиентов "С ОСОБЕННОСТЯМИ":'    val={usr.numWithSpecialCare} />
                <BtmBlock tit='Тренировки:'          val={rew_sum} />
                <BtmBlock tit='Мерч:'                val={usr.merch} />
                <BtmBlock tit='Плюс за "С ОСОБЕННОСТЯМИ":'                val={EXTRA_PAY_FOR_SPECIAL_CARE} />
                <BtmBlock tit='Аванс:'               val={usr.advance} />

                
                <BtmBlock tit='ЗП:'                  val={TO_PAY_FINAL} />

                
            </div>
            
            
    );
    
}

const gST98 = {paddingLeft: '60px', fontSize: '18px', color: '#202020'};


const gSTY1 = {padding: '10px', fontSize: '18px', width: '100%', marginBottom: '40px'};

const gSTY2 = {position: 'relative', fontFamily: 'body', fontWeight: 600, padding: '6px', background: 'var(--fl-clr-primary)', color: '#FFFFFF'};



//----------------------------------------------------


//-----------------------------------------


function BtmBlockImp( {tit, val} )
{
    return (
    
                <LayHoriz2 cols='1fr 100px' gap='10px'>
                    <div style={{textAlign: 'right'}}>{tit}</div>
                    <div style={{fontWeight: 500, fontSize: '20px', color: 'var(--fl-clr-primary)'}}>{val}</div>
                </LayHoriz2>
    
    );
}

const BtmBlock = React.memo( BtmBlockImp );

//------------------------



export default TabTrainerRewards;
