import React from 'react';


import { DaCombo } from '../misc/combo.js';
import { useApiQuery2 } from '../api_stuff.js';


//---------------------------------------------

// менюшка с МЕСЯЧНЫМИ ПРОДУКТАМИ
function ComboMonthlyProductsImp( {disabled, act, value, name, onChange} )
{
	//console.log('ComboMonthlyProducts ... dir ', pp.dir);
	
	const { isFetching, isLoading, isError, data, error } = useApiQuery2( 993, {act: act}, {staleTime: 5} );
	

	let src = [ { pr_id: 0, pr_name: '-- НЕ ВЫБРАН --'} ];
	let is_lo;
	
	if (isLoading || isFetching)
	{
		is_lo = true;
	}
	else
	{
		src = src.concat( data.rows );
		is_lo = false;
	}


	return (
			<DaCombo label='ПРОДУКТ' fld_k='pr_id' fld_v='pr_name'  disabled={disabled}
				value={is_lo ? 0 : value}  src={src}  isLoading={is_lo}  name={name} onChange={onChange} /> 
				);
}


export const ComboMonthlyProducts = React.memo( ComboMonthlyProductsImp );
