import React from 'react';

import { WinPopEventDudeTrp } from './dude_trp.js';
import { AbandonEntry } from './abandon_entry.js';

import * as HLP from '../helpers.js';

import {  ShowErr, ShowMsg} from '../misc_glob.js';

import { CmSpinner } from '../cm_base.js';

import {DaIconBtn} from '../misc/icon_btn.js';

import { LaySpace } from '../misc/layout.js';

import {  NuPost2 } from '../api.js';

import * as DT from '../dt.js';

import AddIcon from '@mui/icons-material/Add';

import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import * as DEFS from '../defs.js';

import { IsCustInList, IsCustInAban } from './shared.js';

import { WinAbandon } from '../desktop/win_abandon.js';

import { WinMonTrpsBrowserForEvt } from '../desktop/win_mon_trp_brow_for_evt.js';



//------------------------------------------

//---------------------------------------



export function DudeList( {lst_trps, lst_aban, rid, disp, trp_serial, mon_mode, prod_id, start, onSelectedTrp} )
{
	//console.log('DudeList..  SERIAL ', trp_serial);
	
	const [busy, setBusy] = React.useState( false );
	const [showPopAban, setShowPopAban] = React.useState( false );
	const [popAbanDct, setPopAbanDct] = React.useState( {} );

	const [showPopBro, setShowPopBro] = React.useState( false );
	
	React.useEffect( () => {
		
		//console.log('DUDE LIST EFFECT...', mon_mode);

		if (prod_id < 1) return;
		
		if (mon_mode === true && rid === -1)
		{
			// request trps for monthly
			
			function __ok(e)
			{
				//console.log('GOT DUDES ', e);
				
				disp( {type: 'mon_dudes', e:e} );
				
				setBusy(false);
			}
			
			function __fail(e)
			{
                console.log('FAIL... ', e);
				setBusy(false);
                ShowErr( e.m );
			}
			
			setBusy(true);
			
			NuPost2( 994, {prod_id:prod_id, date: DT.DateStrIso( DT.DateTimeParseIso(start) ) }, __ok, __fail );
		}
		
	}, [mon_mode, prod_id] );
	
	
	if (busy)
	{
		return <CmSpinner sz={64} />;
	}
	
    
    function __close_pop()
    {
        disp( {type: 'val', k:'pop', v: 0 } );
    }
    
	
	function cb_rem(trp_id)
	{
		//disp( {type: 'val', k: 'pop', v: 4 } );
		//
        
        const MY_TRP_ID = trp_id;
        
		function __ok()
		{
            // TODO: move to dispatch action !!!
            
			let i = -1;
			let ll = lst_trps;
			for (let x = 0; x < ll.length; x ++ )
			{
				let e = ll[x];
				if (e[0] === MY_TRP_ID)
				{
					i = x;
					break;
				}
			}
			
			if (i === -1)
			{
				console.warn('WTF ? no INDEX !!!');
				return;
			}
			
			ll.splice(i, 1);
			
			let nu = HLP.DeepCopy(ll);
			//this.TryAutoFillTypes( nu );	// !!!
			
			disp( {type: 'val', k:'pop', v: 0 } );
			disp( {type: 'val', k:'tmp_id', v: null } );
			disp( {type: 'val', k:'lst_trps', v: nu } );
		}
        
        const ddd = { title: 'ТОЧНО УБРАТЬ КЛИЕНТА ?', onOk: __ok, onClose: __close_pop  };
        
        disp( {type: 'val', k:'pop', v: 4 } );
        disp( {type: 'val', k:'pop_dct_conf', v: ddd } );
        disp( {type: 'val', k:'tmp_id', v: trp_id } );
        
	}
    
	 
    function cb_add_dude()
    {
		function __ok(cu_id)
		{
			//console.log('SELECTED DUDE: ', cu_id);
			
            const CU_ID = cu_id;
            
			if ( !IsCustInList(lst_trps, cu_id) && !IsCustInAban(lst_aban, cu_id) )
			{
                const ddd = { cu_id: CU_ID,
                                prev_trp_id: null,
                                time_start: start,
                                sched_id: rid,
                                check_oth_trains:false,
                                onClose: __close_pop,
                                onOk: onSelectedTrp };
                
				disp( {type: 'val', k:'pop', v: 2 } );
				//disp( {type: 'val', k:'tmp_cust_id', v: cu_id } );
                disp( {type: 'val', k:'pop_dct_trp_sel', v: ddd} );

			}
			else
			{
                const ddd = { text: 'Этот клиент уже в списке !', title: 'ОШИБОЧКА', onClose: __close_pop  };
                    
                disp( {type: 'val', k:'pop', v: 3 } );
                disp( {type: 'val', k:'pop_dct_alert', v: ddd } );
			}
		}
        
        const ddd = { onOk: __ok, onClose: __close_pop  };
        
        disp( {type: 'val', k:'pop', v: 1 } );
        disp( {type: 'val', k:'pop_dct_search', v: ddd } );
	}
	
	function cb_add_for_mon()
	{
        if (prod_id < 1)
        {
            ShowErr('ПРОДУКТ НЕ ВЫБРАН !');
            return;
        }
	
		function __ok(e)
		{
            console.log('OK.. ', e);
			disp( {type: 'mon_dudes_expand', e:e} );
			disp( {type: 'val', k: 'busy', v: false } );
		}
		
		function __fail(e)
		{
            console.log('failed !');
            
			disp( {type: 'val', k: 'busy', v: false } );
            
            ShowErr( e.m );
		}
		
		
		NuPost2( 994, {prod_id:prod_id, date: DT.DateStrIso( DT.DateTimeParseIso(start) ) }, __ok, __fail );
		
		disp( {type: 'val', k: 'busy', v: true } );
		
		
	}

	
	function cb_rem_aban(e)
	{
		//console.log('REMOVE ABAN ', e);

        const CU_ID = e;


		function __ok()
		{
			let ll = HLP.DeepCopy( lst_aban );
			for (let x = 0; x < ll.length; x ++ )
			{
				if (ll[x][0] === CU_ID)
				{
					ll.splice(x,1);
					break;
				}
			}
			
			disp( {type: 'val', k:'tmp_id', v: null } );
			disp( {type: 'val', k:'tmp_cust_id', v: null } );
			disp( {type: 'val', k:'lst_aban', v: ll} );
			disp( {type: 'val', k:'pop', v: 0 } );
		}
		
        const ddd = { onOk: __ok, onClose: __close_pop, title: 'УБРАТЬ НЕПРИШЕДШЕГО ?' };
       
        disp( {type: 'val', k: 'pop', v: 4 } );
        disp( {type: 'val', k: 'pop_dct_conf', v: ddd } ); 
	}

	function cb_burn( ttt )
	{
		const TRP_ID = ttt;

		function __ok()
		{
			//console.log('BURN ', TRP_ID);

			disp( {type: 'set_burn', trp_id: TRP_ID} );
			disp( {type: 'val', k: 'pop', v: 0 } );
		}



        const ddd = { onOk: __ok, onClose: __close_pop, title: 'СЖЕЧЬ ТРЕНЮ ?' };
       
        disp( {type: 'val', k: 'pop', v: 4 } );
        disp( {type: 'val', k: 'pop_dct_conf', v: ddd } ); 
	}
	
	function cb_aban(e)
	{
		//console.log('ABANDON ! ', e);

        const CU_ID     = e.cu_id;
        const PAY_ID    = e.pay_id;
        const ACTS      = e.acts;
        
		function __clo()
		{
			setShowPopAban(false);
		}

		function __conf( com, guilty )
		{
			const ll = HLP.DeepCopy( lst_aban );
			
			ll.push(  [CU_ID, ACTS, PAY_ID, com, guilty]   );
	
			disp( {type: 'val', k:'pop', v: 0 } );
			disp( {type: 'val', k:'tmp_id', v: null } );
			disp( {type: 'val', k:'tmp_cust_id', v: null } );
			disp( {type: 'val', k:'lst_aban', v: ll } );
			
			disp( {type: 'remove_dude', cu_id: CU_ID} );

			setShowPopAban(false);
		}
		

		setPopAbanDct( {onOk: __conf, onClose: __clo}  );
		setShowPopAban( true );
	
	}
	
       
    function cb_tog(trp_id)
    {
		disp( {type: 'toggle_sta', trp_id: trp_id} );
	}
    
    function cb_cancel_burn(a_trp_id)
    {
        //console.log('cancel burn ', a_trp_id);

		disp( {type: 'cancel_burn', trp_id: a_trp_id} );
    }
	
	function cb_swap(a_trp_id, a_cust_id)
	{
        console.log('ON SWAP: ', a_trp_id, ' -- ', a_cust_id);
        
		//disp( {type: 'val', k: 'tmp_id', v: a_trp_id } );
		//disp( {type: 'val', k: 'tmp_cust_id', v: a_cust_id } );
		disp( {type: 'val', k: 'pop', v: 2 } );
        
        const ddd = { cu_id: a_cust_id,
                                prev_trp_id: a_trp_id,
                                time_start: start,
                                sched_id: rid,
                                check_oth_trains:false,
                                onClose: __close_pop,
                                onOk: onSelectedTrp };
                
		disp( {type: 'val', k:'pop', v: 2 } );
		//disp( {type: 'val', k:'tmp_cust_id', v: cu_id } );
        disp( {type: 'val', k:'pop_dct_trp_sel', v: ddd} );

	}


	function cb_add_mon_sing( ttt, ccc )
	{
		//console.log('QQQ ', ttt, ' ---', ccc);
		disp( {type: 'mon_dudes_sing', e:{ cuId:ccc, trpId: ttt}  } );
		setShowPopBro( false );
	}

	function cb_close_mon_brow()
	{
		setShowPopBro( false );
	}

	function cb_show_mon_sing()
	{
		setShowPopBro( true );
	}
	
	function cb_on_row(e)
	{
		disp( {type: 'got_row', e:e} );
	}
	
	
    let p_dudes1 = [];
	let p_dudes2 = [];
    
    for (let x = 0; x < lst_trps.length; x ++ )
    {
        const e = lst_trps[x];
        
        const trp_id      = e[0];
        const trp_status  = e[1];

		const T = ( <WinPopEventDudeTrp on_toggle_sta={cb_tog}
		on_remove={cb_rem}
		on_swap={mon_mode ? null : cb_swap}
		on_abandon={cb_aban}
        on_cancel_burn={cb_cancel_burn}
		on_burn={mon_mode ? null : cb_burn}
		on_got_row={cb_on_row}
		trp_serial={trp_serial}
  			key={trp_id}
  			ts_id={rid}
  			trp_id={trp_id}
  			trp_status={trp_status}/> );
        
		if (trp_status !== DEFS.TPR_STATUS_BURNED)	p_dudes1.push( T );
		else										p_dudes2.push( T );
        
    }
        
    
    let p_abans = null;
    

    if (lst_aban.length > 0)
    {
        let aaa = [];
    
        for (let x = 0; x < lst_aban.length; x ++ )
        {
            const e = lst_aban[x];
            
            // [cu_id, acts, pay_id, comment]
            
            aaa.push( <AbandonEntry key={e[1]}
                                    cu_id={e[0]} 
                                    acts={e[1]} pay_id={e[2]} comment={e[3]} guilty={e[4]}
                                    onRemove={cb_rem_aban} />
                                    
                                    );
        }
        
        
        p_abans = (<>
                    <div style={{fontWeight: 800, padding: '4px', margin: '4px', color: '#A03030'}}>НЕ ПРИШЛИ / ОТМЕНЫ:</div>
                    {aaa}
                    </>);
    }

	
	

	const DDD = DT.DateStrIso( DT.DateTimeParseIso(start) );


	return (	<>
						<div style={{gap: '10px', paddingTop: '10px', paddingBottom: '10px', width: '100%', display: 'flex', flexDirection: 'column'}}>
                        
                            {p_dudes1}
							{p_dudes2}
                            
                            
                            {p_abans}
                        
                            <div style={{margin: '40px', display: 'flex', justifyContent: 'center'}}>
                            
								{ 
									(!mon_mode) ? 	(<DaIconBtn  icon={AddIcon} help='ДОБАВИТЬ КЛИЕНТА' onClick={ cb_add_dude }/>) :
									
													(
													<>
													<DaIconBtn  icon={PeopleAltIcon} help='Добавить всех(!), у кого куплен месячный абон на эту дату.' onClick={ cb_add_for_mon }/>
													<DaIconBtn  icon={PersonAddIcon} help='Выбрать и вписать клиента, у которого есть подходящая тренировка.' onClick={ cb_show_mon_sing }/>
													</>
													)
									
									
								}
                            
                            </div>
                        
                        </div>


					<WinAbandon visible={showPopAban} {...popAbanDct} />


					<WinMonTrpsBrowserForEvt visible={showPopBro} 
									prodId={prod_id}  dtIso={DDD} onClose={cb_close_mon_brow}  onOk={cb_add_mon_sing} />

					</>
	
	
	);
}




