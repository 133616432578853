import React from 'react';

import * as DEFS from '../defs.js';
import * as HLP from '../helpers.js';


import { useApiQuery2 } from '../api_stuff.js';

//--------------------------------------------------------------------------------

export function HowReturned( {retId} )
{
    const { isLoading, isError, data } = useApiQuery2( 4103, {retId}, {staleTime: 30000} );
    
	if (isError) return '???';
    if (!data) return null;
    
    let p_mon;
    let p_bon;

    if (data.ar_mon.length > 0)
    {
        p_mon = [];

        for (let x = 0; x < data.ar_mon.length; x ++ )
        {
            const QQ = data.ar_mon[x];
            const e = HLP.ArrayOfDictsFindByPropValue( DEFS.PAY_METHOD , 'k', QQ.po_method );
            
            const n = e ? e.n : '???';

            p_mon.push( <div key={x}><b>{-QQ.po_amount}</b>{n}</div> );
        }
    }
    else p_mon = null;



    if (data.ar_bon.length > 0)
    {
        // wtf? тут было  {data.ar_bon[0].cbh2_id} - 
        p_bon = <div style={{color: '#60A060'}}><b>{data.ar_bon[0].cbh2_amount}</b>НА БОНУСЫ</div>;
    }
    else p_bon = null;

    
    return <div className="tx_how_pay">{p_mon}{p_bon}</div>;
}


export function HowPayedInfo( {clubId, bindCtx, bindId} )
{
	const { isLoading, isError, data } = useApiQuery2( 4100, {clubId, bindCtx, bindId}, {staleTime: 30000} );
	
	if (isError) return '???';
    if (!data) return null;

    let p_mon;
    let p_bal;
    let p_bon;

    if (data.ar_mon.length > 0)
    {
        p_mon = [];

        for (let x = 0; x < data.ar_mon.length; x ++ )
        {
            const QQ = data.ar_mon[x];
            const e = HLP.ArrayOfDictsFindByPropValue( DEFS.PAY_METHOD , 'k', QQ.po_method );
            
            const n = e ? e.n : '???';

            p_mon.push( <div key={x}><b>{QQ.po_amount}</b>{n}</div> );
        }
    }
    else p_mon = null;


    if (data.ar_bal.length > 0)
    {
        p_bal = <div style={{color: '#6060A0'}}><b>{-data.ar_bal[0].cbh_amount}</b>С БАЛАНСА</div>;
    }
    else p_bal = null;
    


    if (data.ar_bon.length > 0)
    {
        p_bon = <div style={{color: '#60A060'}}><b>{-data.ar_bon[0].cbh2_amount}</b>БОНУСАМИ</div>;
    }
    else p_bon = null;


    return <div className="tx_how_pay">{p_mon}{p_bal}{p_bon}</div>;
}
