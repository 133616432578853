import React from 'react';

import * as DEFS from '../defs.js';
import * as HLP from '../helpers.js';

import { LayHoriz2, LayVert2, LaySpace } from '../misc/layout.js'

import { NuPost } from '../api.js';

import { OverlayLoading } from '../misc/ovr_loading.js';

import { ShowMsg, ShowErr} from '../misc_glob.js';

import { StatsDateRangeSelector, MODE_DAY, MODE_MONTH } from '../misc/stats_date_range_sel.js';

import * as DT from '../dt.js';

import { CmStartPageBoard } from './start.js'



function NumberWithSpaces(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function TabStatsMoney()
{
    //console.log('STATS MONEY !');
    
	const [dt_s, setDtS] = React.useState( new Date() );
    const [dt_e, setDtE] = React.useState( new Date() );
    const [busy, setBusy] = React.useState( false );
    
    const [data, setData] = React.useState( null );

    const cb_sel_date = React.useCallback( (f) =>
    {
        //console.log('SEL DATE ', f);
        
        setDtS( f.s );
        setDtE( f.e );

    }, [] );
    
    
    React.useEffect( () => {
        
        if (busy) return;
        
        //console.log('money effect... %s %s', dt_s, dt_e);
        
        function __ok(e)
        {
            //console.log('GOT ! ', e);
            setData(e.data);
            setBusy(false);
        }
        
        function __fail(e)
        {
            ShowErr(e.m);
            setBusy(false);
        }
        
        setBusy(true);
        
        //console.log('REQ...');
        
        NuPost( 1050, {dt_s:DT.DateStrIso(dt_s), dt_e:DT.DateStrIso(dt_e)}, __ok, __fail);
        
    }, [dt_s, dt_e] );
    
    
    let jjj = null;
    let jjj2 = null;
    
    
    
    if (data)
    {
        //console.log(data);
        const meth_abons = [];
        const meth_goods = [];
        const meth_depos = [];
        const meth_rets = [];
        const meth_mods = [];
        
        const meth_abons_and_goods_and_mods = [];
        

        for (let x = 0; x < DEFS.PAY_METHOD.length; x ++ )
        {
            meth_abons.push( { k: DEFS.PAY_METHOD[x].n, v: data.meth_abons[x] } );
            meth_goods.push( { k: DEFS.PAY_METHOD[x].n, v: data.meth_goods[x] } );
            meth_depos.push( { k: DEFS.PAY_METHOD[x].n, v: data.meth_depos[x] } );
            meth_rets.push(  { k: DEFS.PAY_METHOD[x].n, v: data.meth_rets[x] } );
            meth_mods.push(  { k: DEFS.PAY_METHOD[x].n, v: data.meth_mods[x] } );
            
            const ccc = parseInt(data.meth_abons[x]) + parseInt(data.meth_goods[x]) + parseInt(data.meth_mods[x]);
            
            meth_abons_and_goods_and_mods.push(  { k: DEFS.PAY_METHOD[x].n, v: ccc} );
        }
        
        

        const sum_nal_pere = meth_abons_and_goods_and_mods[ 1 ].v + meth_abons_and_goods_and_mods[ 4 ].v;

        const sum_nal_card_pere_qr = meth_abons_and_goods_and_mods[ 1 ].v + meth_abons_and_goods_and_mods[ 2 ].v + meth_abons_and_goods_and_mods[ 4 ].v + meth_abons_and_goods_and_mods[ 3 ].v;
        
        meth_abons_and_goods_and_mods.push(  { k: 'НАЛ + ПЕРЕВОД', v: sum_nal_pere} );
        meth_abons_and_goods_and_mods.push(  { k: 'НАЛ + КАРТА + ПЕРЕВОД + QR', v: sum_nal_card_pere_qr} );
        
        meth_goods.push(  { k: 'НАЛ + ПЕРЕВОД', v: parseInt(meth_goods[1].v) + parseInt(meth_goods[4].v) } );
        meth_goods.push(  { k: 'НАЛ + КАРТА + ПЕРЕВОД + QR', v: parseInt(meth_goods[1].v) + parseInt(meth_goods[2].v) + parseInt(meth_goods[3].v)  + parseInt(meth_goods[4].v)} );
        



        const lsls = [
        
           // {k: 'КАССА (ВСЁ)', v: NumberWithSpaces(data.total)},
            {k: 'В КАССЕ (ТОЛЬКО НАЛ)', v: NumberWithSpaces(data.total_cash)},
        
        ];
        
        
        const tot_abons_goods_mods = parseInt(data.sums.v_sum_abons) + parseInt(data.sums.v_sum_goods) + parseInt(data.sums.v_sum_mods);
        
        jjj = (<>
                <CmStartPageBoard busy={busy} delay={400} sx={290} title='КАССА'     items={lsls} />
                <CmStartPageBoard busy={busy} delay={300} sx={290} title={"АБОНЫ + ТОВАРЫ + ДОПЛАТЫ: " + tot_abons_goods_mods}     items={meth_abons_and_goods_and_mods} />
                <CmStartPageBoard busy={busy} delay={200} sx={290} title={"ТОЛЬКО ТОВАРЫ: " + parseInt(data.sums.v_sum_goods)}     items={meth_goods} />

                
                </>
                );


        jjj2 = <>
                        <CmStartPageBoard busy={busy} delay={330} sx={290} title={"ДЕПОЗИТЫ: " + data.sums.v_sum_depos}     items={meth_depos} />
                <CmStartPageBoard busy={busy} delay={450} sx={290} title={"ВОЗВРАТЫ: " + data.sums.v_sum_returns}     items={meth_rets} />

        </>
    }
    
    return (
    
        <LayVert2 rows='max-content max-content max-content 1fr' sx={{position: 'relative'}}>
        
			<div style={{padding: '10px', display: 'flex', justifyContent: 'center'}}>
                <StatsDateRangeSelector onChg={cb_sel_date} initialMode={MODE_DAY}  />
            </div>
            
            <div style={{justifyContent: 'center', display: 'flex', gap: '20px'}}>
                {jjj}
            </div>

            <div style={{justifyContent: 'center', display: 'flex', gap: '20px'}}>
                {jjj2}
            </div>
            
            <LaySpace />
            
            <OverlayLoading active={busy} />
        
        </LayVert2>
    
    );
}


export default TabStatsMoney;
